import { call, put } from "redux-saga/effects";
import {
  postDataWithoutToken,
  deleteDataWithToken,
  postDataWithToken,
  putDataWithToken,
  SIGN_IN_URL,
  SIGN_OUT_URL,
  FORGOT_PASSWORD_URL,
  CHANGE_PASSWORD_URL,
  LGP_SIGN_IN_URL,
  LGP_SIGN_OUT_URL,
  LGP_CHANGE_PASSWORD_URL,
  LAB_SIGN_IN_URL,
  DOCTOR_SIGN_IN_URL
} from "Utils/api";

import {
  storeToken,
  storeCompanyType,
  storeUser,
  storeUserType,
} from "Utils/storage";

import {
  TOAST_ERROR,
  TOAST_SUCCESS,
  SIGN_IN_ERROR,
  IS_SUBMITTING,
  SIGN_IN_SUBMITTING,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_SUBMITTING,
} from "Constants/actionConstants";

import { MENU_LIST } from "Constants/commonConstants";
import { SET_CITY_AND_RESTRICT } from "Constants/actionConstants";

export function* signin(action) {
  const { username, password, userType } = action.data;
  try {
    yield put({
      type: SIGN_IN_SUBMITTING,
      isSubmitting: true,
    });
    let URL = SIGN_IN_URL;
    if(userType === 2) {
      URL = LGP_SIGN_IN_URL;
    } else if(userType === 4) {
      URL = LAB_SIGN_IN_URL;
    } else if(userType === 5) {
      URL = DOCTOR_SIGN_IN_URL;
    }

    const data = yield call(postDataWithoutToken, URL, {
      username,
      password,
      userType,
    });
    const user = data;
    storeToken(data.token);
    storeUserType(data.userType);
    storeUser(user);
    localStorage.setItem("isRestrictCity", data.isRestrictCity);
    localStorage.setItem("city", data.city);
    if (userType === 2) {
      window.location.href = "/orders";
    } else if(userType === 4 || userType === 3) {
      window.location.href = "/orderscanner";
    } else if(userType === 5) {
      window.location.href = "/doctororders";
    } else {
      window.location.href = "/statistics";
    }

    yield put({
      type: SET_CITY_AND_RESTRICT,
      isRestrictCity: data.isRestrictCity,
      city: data.city,
    });

    yield put({
      type: SIGN_IN_SUBMITTING,
      isSubmitting: false,
    });
  } catch (e) {
    yield put({
      type: SIGN_IN_ERROR,
      error: "",
    });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* signOut(action) {
  try {
    yield put({ type: SIGN_IN_SUBMITTING, isSubmitting: true });

    const URL =
      action.data && action.data.userType === 2
        ? LGP_SIGN_OUT_URL
        : SIGN_OUT_URL;
    const data = yield call(deleteDataWithToken, URL, action.data);
    localStorage.removeItem("orderDateFilter");
    window.location.href = "/";

    yield put({ type: SIGN_IN_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: SIGN_IN_ERROR, error: "" });

    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* forgotPassword(action) {
  const { username, companyType } = action.data;
  try {
    yield put({
      type: FORGOT_PASSWORD_SUBMITTING,
    });
    const data = yield call(postDataWithoutToken, FORGOT_PASSWORD_URL, {
      username,
      company_type: companyType,
    });
    yield put({
      type: TOAST_SUCCESS,
      message: (data && data.message) || "Password reset successfully",
    });
    yield put({
      type: FORGOT_PASSWORD_SUCCESS,
      error: "",
    });
  } catch (e) {
    yield put({
      type: FORGOT_PASSWORD_SUCCESS,
      error: "",
    });
    yield put({
      type: TOAST_ERROR,
      message: e && e.message ? e.message : "Oops! Something went wrong",
    });
  }
}

export function* changePassword(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const URL =
      action.data && action.data.userType === 2
        ? LGP_CHANGE_PASSWORD_URL
        : CHANGE_PASSWORD_URL;
    const apiType =
      action.data && action.data.userType === 2
        ? putDataWithToken
        : postDataWithToken;
    const data = yield call(apiType, URL, action.data);
    yield put({ type: IS_SUBMITTING, isSubmitting: false });

    yield put({
      type: TOAST_SUCCESS,
      message:
        data && data.message ? data.message : "Data updated successfully!",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}
