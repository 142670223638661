/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { Fragment } from "react";
import classNames from "classnames";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const PhloeboAutocomplete = ({
  getOptions = () => {},
  onSelect = () => {},
  allOptions = [],
  value = null,
}) => {
  return (
    <Fragment>
      <Autocomplete
        value={allOptions.find((el) => el.phleboId === value) || null}
        options={(allOptions || []).filter((el) => el.firstName !== undefined)}
        getOptionLabel={(option) => {
          return `${option.firstName} ${option.lastName}`;
        }}
        autoHighlight={false}
        autoSelect={false}
        clearOnEscape
        onChange={(event, autocompleteValue) => {
          let value = null;
          if (autocompleteValue) {
            value = autocompleteValue.phleboId || "";
          } else {
            getOptions();
          }
          onSelect(value, "phleboId");
        }}
        renderInput={(params) => (
          <TextField {...params} onChange={(e) => getOptions(e.target.value)} />
        )}
      />
    </Fragment>
  );
};

export default PhloeboAutocomplete;
