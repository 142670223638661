import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './clinics.css';

import DocContainer from './../../containers/DocContainer';
import ActionLoader from '../../components/ActionLoader';
import ImageSlider from '../../components/ImageSlider';

import {
  Select,
  Button,
  Grid,
  TextField,
  Typography,
  Avatar,
  Tabs,
  Tab,
  Box,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
} from '@material-ui/core';

import {
  GET_PLEBOS,
  ADD_PLEBOS,
  UPLOAD_IMAGE,
  GET_ALL_STATES,
  GET_ALL_CONSTANTS,
  TOAST_ERROR,
} from './../../constants/actionConstants';

import { GENDER,  PHLEBO_TYPE } from './../../constants/commonConstants';


import moment from 'moment';

import Geocode from 'react-geocode';


import { DatePicker } from 'rsuite';
import '../RosterPlan/date.css';

Geocode.setApiKey(process.env.REACT_APP_GEO_CODE_API);


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

class PleboAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      tab: 1,
      data: {
        working_status: '',
        isIDAutoGenerate: true,
        phleboId: '',
        firstName: '',
        lastName: '',
        mobileNumber: '',
        alternateMobileNumber: '',
        dob: '',
        age: '',
        city: '',
        state: '',
        zipcode: '',
        address1: '',
        qualification: '',
        maritalStatus: '',
        bloodGroup: '',
        minOrderCommitment: '',
        dmltNo: '',
        phleboType: 0,
        vendorName: '',
        aadharNo: '',
        dlNo: '',
        panNo: '',
        aadharUrl: [],
        panUrl: [],
        dlUrl: [],
        isEdit: true,
        username: '',
        password: '',
        gender:'',
        secondaryEducation: '',
        secondaryEducationPerc: '',
        secondaryEducationUrl: [],
        higherSecondaryEducation: '',
        higherSecondaryEducationPerc: '',
        higherSecondaryEducationUrl: [],
        gradEducation: '',
        gradEducationPerc: '',
        gradEducationUrl: [],
        fatherName: '',
        fatherAadharNo: '',
        fatherAadharUrl: [],
        motherName: '',
        motherAadharNo: '',
        motherAadharUrl: [],
        emergencyContact: '',
        tier: {
          name: '',
          id: 0,
          value: 0,
        },
        experience: { name: '', id: 0, value: 0 },
        hours: { name: '', id: 0, value: 0 },
        zones: { name: '', id: 0, value: 0 },
        service: { name: '', id: 0, value: 0 },
        isPPMC: 0,
        isDMLT: 0,
        bonus: 0,
        gross: 0,
        trainingDate: '',
        offerGeneratedDate: '',
        hrInductionDate: '',
        qaOrientationDate: '',
        departmentSOPDate: '',
        stockIssuedDate: '',
        idCardIssuedDate: '',
        accepts_ppmc: 0,
        accepts_covid: 0,
        accepts_ecg: 0,
        isActive: 0,
        isInHouse: 0,
        yoe: '',
        working_hours_start: '',
        working_hours_end: '',
        working_hours_start1: '',
        working_hours_end1: '',
        zoneName: '',
        is_on_call_vendor: false,
        is_on_call_phlebo: false,
      },
      working_hours: {
        start_error: '',
        end_error: '',
        start1_error: '',
        end1_error: '',
      },
      salary: {
        tier: {
          min: 0,
          max: 0,
          error: false,
        },
        hours: {
          min: 0,
          max: 0,
          error: false,
        },
        experience: {
          min: 0,
          max: 0,
          error: false,
        },
        zones: {
          min: 0,
          max: 0,
          error: false,
        },
        service: {
          min: 0,
          max: 0,
          error: false,
        },
        isPPMC: {
          option: false,
          max: 0,
          error: false,
        },
        isDMLT: {
          option: false,
          max: 0,
          error: false,
        },
      },
      induction: {
        offerGeneratedDate: 0,
        hrInductionDate: 0,
        qaOrientationDate: 0,
        departmentSOPDate: 0,
        stockIssuedDate: 0,
        idCardIssuedDate: 0,
        idCreatedDate: 0,
      },
      disableinduction: {
        offerGeneratedDate: 0,
        idCreatedDate: 0,
        hrInductionDate: 0,
        qaOrientationDate: 0,
        departmentSOPDate: 0,
        stockIssuedDate: 0,
        idCardIssuedDate: 0,
      },
      inductionBy: {
        offerGeneratedDate: '',
        idCreatedDate: '',
        hrInductionDate: '',
        qaOrientationDate: '',
        departmentSOPDate: '',
        stockIssuedDate: '',
        idCardIssuedDate: '',
        trainingBy: '',
      },
      viewImages: [],
    };
  }

  UNSAFE_componentWillMount() {
    const { match } = this.props;
    if (match.params && match.params.id) {
      this.getPlebos(match.params.id);
    }
    this.getAllConstants();
    this.getAllStates();
  }

  getPlebos = (id) => {
    const { dispatch } = this.props;
    let data = {
      phleboId: id,
    };

    dispatch({
      type: GET_PLEBOS,
      data,
      onSuccess: (pleboData) => {
        this.setState({
          data: {
            ...this.state.data,
            ...pleboData,
            accepts_ppmc: pleboData.accepts_ppmc ? 1 : 0,
            accepts_covid: pleboData.accepts_covid ? 1 : 0,
            accepts_ecg: pleboData.accepts_ecg ? 1 : 0,
            isActive: pleboData.isActive ? 1 : 0,
            isInHouse: pleboData.isInHouse ? 1 : 0,
            isIDAutoGenerate: pleboData.isIDAutoGenerate ? 1 : 0,
          },
        });

        // Set Salary

        const { salaries } = this.props;
        const { salary, induction, disableinduction, inductionBy } = this.state;

        //tier
        if (pleboData.tier && pleboData.tier.id) {
          const selected =
            salaries && salaries['tier']
              ? salaries['tier'].filter((el) => el.id === pleboData.tier.id)
              : {};
          if (selected.length) {
            salary['tier'].min = parseInt(selected[0].min);
            salary['tier'].max = parseInt(selected[0].max);
          }
        }

        //experience
        if (pleboData.experience && pleboData.experience.id) {
          const selected =
            salaries && salaries['experience']
              ? salaries['experience'].filter(
                (el) => el.id === pleboData.tier.id
              )
              : {};
          if (selected.length) {
            salary['experience'].min = parseInt(selected[0].min);
            salary['experience'].max = parseInt(selected[0].max);
          }
        }

        //hours
        if (pleboData.hours && pleboData.hours.id) {
          const selected =
            salaries && salaries['hours']
              ? salaries['hours'].filter((el) => el.id === pleboData.tier.id)
              : {};
          if (selected.length) {
            salary['hours'].min = parseInt(selected[0].min);
            salary['hours'].max = parseInt(selected[0].max);
          }
        }

        //zones
        if (pleboData.zones && pleboData.zones.id) {
          const selected =
            salaries && salaries['zones']
              ? salaries['zones'].filter((el) => el.id === pleboData.tier.id)
              : {};
          if (selected.length) {
            salary['zones'].min = parseInt(selected[0].min);
            salary['zones'].max = parseInt(selected[0].max);
          }
        }

        //service
        if (pleboData.service && pleboData.service.id) {
          const selected =
            salaries && salaries['service']
              ? salaries['service'].filter((el) => el.id === pleboData.tier.id)
              : {};
          if (selected.length) {
            salary['service'].min = parseInt(selected[0].min);
            salary['service'].max = parseInt(selected[0].max);
          }
        }
        if (Object.keys(salaries).length) {
          salary['isDMLT'].max = salaries['isDMLT'];
          salary['isPPMC'].max = salaries['isPPMC'];
        }

        if (pleboData.isDMLT !== '') {
          salary['isDMLT'].option = 1;
        }

        if (pleboData.isPPMC !== '') {
          salary['isPPMC'].option = 1;
        }
        this.setState({ salary });

        // induction: {
        induction['offerGeneratedDate'] = pleboData.offerGeneratedDate ? 1 : 0;
        induction['hrInductionDate'] = pleboData.hrInductionDate ? 1 : 0;
        induction['qaOrientationDate'] = pleboData.qaOrientationDate ? 1 : 0;
        induction['departmentSOPDate'] = pleboData.departmentSOPDate ? 1 : 0;
        induction['stockIssuedDate'] = pleboData.stockIssuedDate ? 1 : 0;
        induction['idCardIssuedDate'] = pleboData.idCardIssuedDate ? 1 : 0;
        induction['idCreatedDate'] = pleboData.idCreatedDate ? 1 : 0;
        this.setState({ induction });

        disableinduction['offerGeneratedDate'] = pleboData.offerGeneratedDate
          ? 1
          : 0;
        disableinduction['hrInductionDate'] = pleboData.hrInductionDate ? 1 : 0;
        disableinduction['qaOrientationDate'] = pleboData.qaOrientationDate
          ? 1
          : 0;
        disableinduction['departmentSOPDate'] = pleboData.departmentSOPDate
          ? 1
          : 0;
        disableinduction['stockIssuedDate'] = pleboData.stockIssuedDate ? 1 : 0;
        disableinduction['idCardIssuedDate'] = pleboData.idCardIssuedDate
          ? 1
          : 0;
        disableinduction['idCreatedDate'] = pleboData.idCreatedDate ? 1 : 0;

        this.setState({ disableinduction });

        // induction: {
        inductionBy['offerGeneratedDate'] = pleboData.offerGeneratedBy
          ? pleboData.offerGeneratedBy
          : '';
        inductionBy['hrInductionDate'] = pleboData.hrInductionBy
          ? pleboData.hrInductionBy
          : '';
        inductionBy['qaOrientationDate'] = pleboData.qaOrientationBy
          ? pleboData.qaOrientationBy
          : '';
        inductionBy['departmentSOPDate'] = pleboData.departmentSOPBy
          ? pleboData.departmentSOPBy
          : '';
        inductionBy['stockIssuedDate'] = pleboData.stockIssuedBy
          ? pleboData.stockIssuedBy
          : '';
        inductionBy['idCardIssuedDate'] = pleboData.idCardIssuedBy
          ? pleboData.idCardIssuedBy
          : '';
        inductionBy['idCreatedDate'] = pleboData.idCreatedDateBy
          ? pleboData.idCreatedDateBy
          : '';
        inductionBy['trainingBy'] = pleboData.trainingBy
          ? pleboData.trainingBy
          : '';
        this.setState({ inductionBy });
      },
    });
  };

  getAllConstants = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_ALL_CONSTANTS,
    });
  };

  getAllStates = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_ALL_STATES,
    });
  };

  

  redirectToList = () => {
    const {  history } = this.props;
    if (this.props.isView) {
      this.props.redirectToList();
    } else {
      history.push(`/phlebo`);
    }
  };

  addPlebos = (dataSet) => {
    const { dispatch} = this.props;
    const data = dataSet ? dataSet : this.state.data;
    if (data.phleboId === '' || this.props.errorMessage) {
      data['isEdit'] = false;
      delete data.phleboId;
    } else {
      data['isEdit'] = true;
      data['isIDAutoGenerate'] = true;
    }
    if (
      data &&
      data.servicing_zipcodes &&
      typeof data.servicing_zipcodes === 'string'
    ) {
      data.servicing_zipcodes = (data.servicing_zipcodes || '').split(',');
    }

    if (data) {
      if (data.dob) {
        data.dob = new Date(moment(data.dob).format('YYYY-MM-DD')).getTime();
      }
      if (data.trainingDate) {
        data.trainingDate = new Date(
          moment(data.trainingDate).format('YYYY-MM-DD')
        ).getTime();
      }
      if (data.offerGeneratedDate) {
        data.offerGeneratedDate = new Date(
          moment(data.offerGeneratedDate).format('YYYY-MM-DD')
        ).getTime();
      }
      if (data.hrInductionDate) {
        data.hrInductionDate = new Date(
          moment(data.hrInductionDate).format('YYYY-MM-DD')
        ).getTime();
      }
      if (data.qaOrientationDate) {
        data.qaOrientationDate = new Date(
          moment(data.qaOrientationDate).format('YYYY-MM-DD')
        ).getTime();
      }
      if (data.departmentSOPDate) {
        data.departmentSOPDate = new Date(
          moment(data.departmentSOPDate).format('YYYY-MM-DD')
        ).getTime();
      }
      if (data.stockIssuedDate) {
        data.stockIssuedDate = new Date(
          moment(data.stockIssuedDate).format('YYYY-MM-DD')
        ).getTime();
      }
      if (data.idCardIssuedDate) {
        data.idCardIssuedDate = new Date(
          moment(data.idCardIssuedDate).format('YYYY-MM-DD')
        ).getTime();
      }
      if (data.idCreatedDate) {
        data.idCreatedDate = new Date(
          moment(data.idCreatedDate).format('YYYY-MM-DD')
        ).getTime();
      }
    }

    dispatch({
      type: ADD_PLEBOS,
      data,
      onSuccess: (result) => {
        if (result.phleboId) {
          const { data } = this.state;
          data['phleboId'] = result.phleboId;
          this.setState({ data });
        }
      },
    })
    if (this.props.errorMessage) {
      this.setState({ ...data, isEdit: false, });
    }
  };

  uploadImage = (file, fields, isAdd) => {
    const { dispatch } = this.props;
    dispatch({
      type: UPLOAD_IMAGE,
      file,
      params: { type: 15 },
      onSuccess: (res) => {
        if (res && res.imageUrl) {
          const { data } = this.state;
          data[fields] = [...data[fields], res.imageUrl];
          this.setState({ data });
        }
      },
    });
  };

  handleFormChange = (e, key) => {
    const { data } = this.state;
    if (
      key === 'bonus' ||
      key === 'isIDAutoGenerate' ||
      key === 'accepts_ppmc' ||
      key === 'accepts_covid' ||
      key === 'accepts_ecg' ||
      key === 'isActive' ||
      key === 'isInHouse' ||
      key === 'isPPMC' ||
      key === 'isDMLT'
    ) {
      data[key] = e.target.value !== '' ? parseInt(e.target.value) : '';
    } else {
      if (key === 'is_on_call_vendor' || key === 'is_on_call_phlebo') {
        data[key] = !!parseInt(e.target.value);
      } else {
        data[key] = e.target.value;
      }
    }
    this.setState({ data });
    if (key === 'bonus') {
      this.addGross();
    }
  };

  handleSalaryChange = (e, key) => {
    const { data, salary } = this.state;
    const { salaries } = this.props;
    const selected =
      salaries && salaries[key]
        ? salaries[key].filter((el) => el.id === e.target.value)
        : {};
    data[key].id = e.target.value;
    data[key].name = selected[0].name;
    salary[key].min = parseInt(selected[0].min);
    salary[key].max = parseInt(selected[0].max);
    this.setState({ data, salary });
  };

  handleSalaryForm = (e, key) => {
    const { data } = this.state;
    data[key].value = e.target.value !== '' ? parseInt(e.target.value) : 0;
    this.setState({ data });
    this.addGross();
  };

  addGross = () => {
    const { data } = this.state;

    data['gross'] =
      data.tier.value +
      data.experience.value +
      data.hours.value +
      data.zones.value +
      data.service.value +
      data.isPPMC +
      data.isDMLT +
      data.bonus;

    this.setState({ data });
  };

  validateSalaryForm = (key) => {
    const { data, salary } = this.state;
    if(parseInt(data[key].value) >= parseInt(salary[key].min) && parseInt(data[key].value) <= parseInt(salary[key].max)){
      (salary[key].error = false) 
      this.setState({ isError: false })
    }else{
      (salary[key].error = true)
       this.setState({ isError: true })
    }
    this.setState({ salary });
  };

  validateWorkingHours = (value, errorKey) => {
    const { working_hours } = this.state;
    this.setState({ isError: false });
    working_hours[errorKey] = false;
    this.setState({ working_hours });
    let hours_split = value.split(' ');
    if (hours_split.length === 2) {
      let isInValid = hours_split.find((element) => element.length !== 2);
      if (typeof isInValid === 'undefined') {
        return;
      }
    }
    working_hours[errorKey] = true;
    this.setState({ working_hours });
    this.setState({ isError: true });
  };

  handleSalaryOption = (e, key, maxValue) => {
    const { data, salary } = this.state;
    salary[key].option = parseInt(e.target.value);
    if (e.target.value) {
      salary[key].max = parseInt(maxValue);
    } else {
      salary[key].max = 0;
      data[key] = '';
      this.setState({ data });
      this.addGross();
    }
    this.setState({ salary });
  };

  handleInductionOption = (e, key) => {
    const { data, induction } = this.state;
    induction[key] = parseInt(e.target.value);
    if (!parseInt(e.target.value)) {
      data[key] = '';
      this.setState({ data });
    }
    this.setState({ induction });
  };

  validateSalaryOption = (key) => {
    const { data, salary } = this.state;
    if(parseInt(data[key]) <= parseInt(salary[key].max) && data[key] > 0 ){
      (salary[key].error = false)
      this.setState({ isError: false })
    }else{
      (salary[key].error = true)
      this.setState({ isError: true })
    }
    this.setState({ salary });
  };

  handleDateChange = (date, key) => {
    const { data } = this.state;
    data[key] = date;
    this.setState({ data });
  };

  validateData = () => {
    const { data, tab } = this.state;
    const { dispatch } = this.props;
    if ((data.firstName === ''||
        data.lastName === '' ||
        data.dob === '' ||
        data.mobileNumber === '' ||
        data.username === '' ||
        data.emailId === '' ||
        data.zipcode === '' ||
        data.city === '' ||
        data.state === '' ||
        data.working_hours_start === '' ||
        data.working_hours_end === ''
      ) &&
      tab === 1
    ) {
      this.setState({ isError: true });
    } else if ((data.username === '' || data.password === '' || data.address1 === '') &&
      tab === 5
    ) {
      this.setState({ isError: true });
    } else if ((data.offerGeneratedDate === '' &&
        this.state.induction.offerGeneratedDate === 1) ||
      (data.hrInductionDate === '' &&
        this.state.induction.hrInductionDate === 1) ||
      (data.qaOrientationDate === '' &&
        this.state.induction.qaOrientationDate === 1) ||
      (data.departmentSOPDate === '' &&
        this.state.induction.departmentSOPDate === 1) ||
      (data.stockIssuedDate === '' &&
        this.state.induction.stockIssuedDate === 1) ||
      (data.idCardIssuedDate === '' &&
        this.state.induction.idCardIssuedDate === 1 &&
        tab === 7)) {
      dispatch({
        type: TOAST_ERROR,
        message: 'Please select date',
      });
      this.setState({ isError: true });
    } else {
      this.setState({ isError: false });
      this.addPlebos();
    }
  };

  removeUpload = (fields, src) => {
    const { data } = this.state;
    data[fields] = data[fields].filter((item) => item !== src);
    this.setState({ data });
  };

  renderFormBasic = (data) => {
    const { isError } = this.state;
    return (
      <React.Fragment>
        <Grid container spacing={3} style={{ marginTop: 5 }}>
          <Grid item xs={6}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                First Name<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.firstName}
                onChange={(e) => this.handleFormChange(e, 'firstName')}
                error={isError && data.firstName === ''}
                helperText={
                  isError && data.firstName === ''
                    ? 'Please enter a first name'
                    : ' '
                }
              />
            </h2>
          </Grid>
          <Grid item xs={6}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Last Name<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.lastName}
                onChange={(e) => this.handleFormChange(e, 'lastName')}
                error={isError && data.lastName === ''}
                helperText={
                  isError && data.lastName === ''
                    ? 'Please enter a last name'
                    : ' '
                }
              />
            </h2>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Phone Number<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.mobileNumber}
                error={isError && data.mobileNumber === ''}
                helperText={
                  isError && data.mobileNumber === ''
                    ? 'Please enter phone no.'
                    : ' '
                }
                onChange={(e) => this.handleFormChange(e, 'mobileNumber')}
              />
            </h2>
          </Grid>
          <Grid item xs={6}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Alternate Number
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.alternateMobileNumber}
                onChange={(e) =>
                  this.handleFormChange(e, 'alternateMobileNumber')
                }
              />
            </h2>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <h2 className='mt-0 boxed-date-picker'>
              <FormLabel component='legend' className='text-label'>
                DOB<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
              </FormLabel>
              <DatePicker
                style={{ width: '100%' }}
                value={data.dob}
                ranges={[]}
                dateFormat='MMMM d, yyyy'

                onChange={(date) => {
                  this.handleDateChange(date, 'dob');
                  let years = moment().diff(date, 'years', false);
                  this.setState({
                    data: {
                      ...this.state.data,
                      age: years,
                    },
                  });
                }}

              />
              <p style={{ fontSize: '10px', color: 'red' }}>{isError && data.dob === '' && 'Please enter Dob.'}</p>
            </h2>
          </Grid>
          <Grid item xs={6}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Age
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.age}
                onChange={(e) => this.handleFormChange(e, 'age')}
              />
            </h2>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Qualification
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.qualification}
                onChange={(e) => this.handleFormChange(e, 'qualification')}
              />
            </h2>
          </Grid>
          <Grid item xs={6}>
            <h2 className='mt-0 boxed-select'>
              <FormLabel component='legend' className='text-label'>
                Marital Status
              </FormLabel>
              <Select
                fullWidth
                value={data.maritalStatus}
                onChange={(e) => {
                  this.handleFormChange(e, 'maritalStatus');
                }}
              >
                {[
                  { msId: 'SINGLE', name: 'SINGLE' },
                  { msId: 'MARRIED', name: 'MARRIED' },
                  { msId: 'WIDOWED', name: 'WIDOWED' },
                  { msId: 'SEPARATED', name: 'SEPARATED' },
                  { msId: 'DIVORCED', name: 'DIVORCED' },
                ].map((ms, index) => (
                  <option key={`ms-${ms.msId}`} value={ms.msId}>
                    {' '}
                    {ms.name}{' '}
                  </option>
                ))}
              </Select>
            </h2>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <h2 className='mt-0 boxed-select'>
              <FormLabel component='legend' className='text-label'>
                Gender<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
              </FormLabel>
              <Select
                fullWidth
                value={data.gender || ""}
                error={isError && data.state === ''}
                onChange={(e) => {
                  this.handleFormChange(e, "gender");
                }}
              >
                {(GENDER || []).map((status) => (
                  <option key={`mode-${status.id}`} value={status.id}>
                    {" "}
                    {status.name}{" "}
                  </option>
                ))}
              </Select>
              <p style={{ fontSize: '10px', color: 'red' }}>{isError && data.state === '' && 'Please Select Gender.'}</p>

            </h2>
          </Grid>
          <Grid item xs={6}>
            <h2 className='mt-0 boxed-select'>
              <FormLabel component='legend' className='text-label'>
                Blood group
              </FormLabel>
              <Select
                fullWidth
                value={data.bloodGroup}
                onChange={(e) => {
                  this.handleFormChange(e, 'bloodGroup');
                }}
              >
                {[
                  { bgId: 'A+', name: 'A Positive' },
                  { bgId: 'A-', name: 'A Negative' },
                  { bgId: 'A', name: 'A Unknown' },
                  { bgId: 'B+', name: 'B Positive' },
                  { bgId: 'B-', name: 'B Negative' },
                  { bgId: 'B', name: 'B Unknown' },
                  { bgId: 'AB+', name: 'AB Positive' },
                  { bgId: 'AB-', name: 'AB Negative' },
                  { bgId: 'AB', name: 'AB Unknown' },
                  { bgId: 'O+', name: 'O Positive' },
                  { bgId: 'O-', name: 'O Negative' },
                  { bgId: 'O', name: 'O Unknown' },
                  { bgId: 'U', name: 'Unknown' },
                ].map((bg) => (
                  <option key={`ms-${bg.bgId}`} value={bg.bgId}>
                    {' '}
                    {bg.name}{' '}
                  </option>
                ))}
              </Select>
            </h2>
          </Grid>

        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                DMLT
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.dmltNo}
                onChange={(e) => this.handleFormChange(e, 'dmltNo')}
              />
            </h2>
          </Grid>
          <Grid item xs={6}>
            <h2 className='mt-0 boxed-select'>
              <FormLabel component='legend' className='text-label'>
                Phlebo Type<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
              </FormLabel>
              <Select
                fullWidth
                value={data.phleboType || ""}
                error={isError && data.state === ''}
                onChange={(e) => {
                  this.handleFormChange(e, "phleboType");
                  this.setState({ phleboTypeNum: e.target.value })
                }}
              >
                {(PHLEBO_TYPE || []).map((status, index) => (
                  <option key={`mode-${status.id}`} value={status.id}>
                    {" "}
                    {status.name}{" "}
                  </option>
                ))}
              </Select>
              <p style={{ fontSize: '10px', color: 'red' }}>{isError && data.state === '' && 'Please Select Phlebo Type.'}</p>

            </h2>
          </Grid>
          {this.state.phleboTypeNum === 3 ? <Grid item xs={6}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Vendor Name
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.vendorName}
                onChange={(e) => this.handleFormChange(e, 'vendorName')}
              />
            </h2>
          </Grid> : null}
        </Grid>
        <Grid container spacing={3}>

          <Grid item xs={6}>
            <h2 className='mt-0 mb-0'>
              <FormLabel component='legend' className='text-label'>
                Is Active?
              </FormLabel>
            </h2>
            <RadioGroup
              row
              aria-label='position'
              name='position'
              defaultValue='top'
              value={data.isActive}
              onChange={(e) => this.handleFormChange(e, 'isActive')}
            >
              <FormControlLabel
                value={1}
                control={<Radio color='primary' />}
                label='Yes'
              />
              <FormControlLabel
                value={0}
                control={<Radio color='primary' />}
                label='No'
              />
            </RadioGroup>
          </Grid>

          <Grid item xs={6}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Min order commitment
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.minOrderCommitment}
                onChange={(e) => this.handleFormChange(e, 'minOrderCommitment')}
              />
            </h2>
          </Grid>

        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <h2 className='mt-0'>
                <FormLabel component='legend' className='text-label'>
                  Aadhar number
                </FormLabel>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={data.aadharNo}
                  onChange={(e) => this.handleFormChange(e, 'aadharNo')}
                />
              </h2>
            </Grid>
            <Grid item xs={12}>
              <span className='log-inline-img'>
                <Grid container xs={12}>
                  <Grid item xs={12}>
                    <div className='file-upload'>
                      Add aadhar document
                      <input
                        style={{ fontSize: 'unset', margin: 10 }}
                        type='file'
                        name='aadharUrl'
                        accept='image/*'
                        className='input_file'
                        onChange={(e) => {
                          this.uploadImage(e.target.files, 'aadharUrl', 1);
                        }}
                      />
                    </div>
                  </Grid>
                  {data.aadharUrl &&
                    data.aadharUrl.map((el) => (
                      <Grid
                        item
                        style={{
                          width: 100,
                          position: 'relative',
                          marginTop: 5,
                        }}
                        xs={2}
                      >
                        <Avatar
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.setState({ viewImages: [el] })}
                          src={el}
                          size='small'
                        />{' '}
                        <ImageSlider images={this.state.viewImages} />
                        <div
                          style={{
                            position: 'absolute',
                            left: 45,
                            top: 0,
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {
                            this.removeUpload('aadharUrl', el);
                          }}
                        >
                          X
                        </div>
                      </Grid>
                    ))}
                </Grid>
              </span>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <h2 className='mt-0'>
                <FormLabel component='legend' className='text-label'>
                  PAN Number
                </FormLabel>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={data.panNo}
                  onChange={(e) => this.handleFormChange(e, 'panNo')}
                />
              </h2>
            </Grid>
            <Grid item xs={12}>
              <span className='log-inline-img'>
                <Grid container xs={12}>
                  <Grid item xs={12}>
                    <div className='file-upload'>
                      Add PAN document
                      <input
                        style={{ fontSize: 'unset', margin: 10 }}
                        type='file'
                        name='panUrl'
                        accept='image/*'
                        className='input_file'
                        onChange={(e) => {
                          this.uploadImage(e.target.files, 'panUrl', 1);
                        }}
                      />
                    </div>
                  </Grid>
                  {data.panUrl &&
                    data.panUrl.map((el) => (
                      <Grid
                        item
                        style={{
                          width: 100,
                          position: 'relative',
                          marginTop: 5,
                        }}
                        xs={2}
                      >
                        <Avatar
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.setState({ viewImages: [el] })}
                          src={el}
                          size='small'
                        />{' '}
                        <ImageSlider images={this.state.viewImages} />
                        <div
                          style={{
                            position: 'absolute',
                            left: 45,
                            top: 0,
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {
                            this.removeUpload('panUrl', el);
                          }}
                        >
                          X
                        </div>
                      </Grid>
                    ))}
                </Grid>
              </span>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <h2 className='mt-0'>
                <FormLabel component='legend' className='text-label'>
                  Driving license
                </FormLabel>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={data.dlNo}
                  onChange={(e) => this.handleFormChange(e, 'dlNo')}
                />
              </h2>
            </Grid>
            <Grid item xs={12}>
              <span className='log-inline-img'>
                <Grid container xs={12}>
                  <Grid item xs={12}>
                    <div className='file-upload'>
                      Add Driving license document
                      <input
                        style={{ fontSize: 'unset', margin: 10 }}
                        type='file'
                        name='dlUrl'
                        accept='image/*'
                        className='input_file'
                        onChange={(e) => {
                          this.uploadImage(e.target.files, 'dlUrl', 1);
                        }}
                      />
                    </div>
                  </Grid>
                  {data.dlUrl &&
                    data.dlUrl.map((el) => (
                      <Grid
                        item
                        style={{
                          width: 100,
                          position: 'relative',
                          marginTop: 5,
                        }}
                        xs={2}
                      >
                        <Avatar
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.setState({ viewImages: [el] })}
                          src={el}
                          size='small'
                        />{' '}
                        <ImageSlider images={this.state.viewImages} />
                        <div
                          style={{
                            position: 'absolute',
                            left: 45,
                            top: 0,
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {
                            this.removeUpload('dlUrl', el);
                          }}
                        >
                          X
                        </div>
                      </Grid>
                    ))}
                </Grid>
              </span>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: 5, padding: '10px' }}>
            <Grid item xs={6}>
              <h2 className='mt-0'>
                <FormLabel component='legend' className='text-label'>
                  E-mail ID<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                </FormLabel>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={data.emailId}
                  onChange={(e) => this.handleFormChange(e, 'emailId')}
                  error={isError && data.mobileNumber === ''}
                  helperText={
                    isError && data.mobileNumber === ''
                      ? 'Please enter Email Id.'
                      : ' '
                  }
                />
              </h2>
            </Grid>
            <Grid item xs={6}>
              <h2 className='mt-0'>
                <FormLabel component='legend' className='text-label'>
                  Username<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                </FormLabel>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={data.username}
                  onChange={(e) => this.handleFormChange(e, 'username')}
                  error={isError && data.username === ''}
                  helperText={
                    isError && data.username === ''
                      ? 'Please enter username'
                      : ' '
                  }
                />
              </h2>
            </Grid>
            <Grid item xs={6}>
              <h2 className='mt-0'>
                <FormLabel component='legend' className='text-label'>
                  Password<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                </FormLabel>
                <TextField
                  fullWidth
                  variant='outlined'
                  type='password'
                  value={data.password}
                  onChange={(e) => this.handleFormChange(e, 'password')}
                />
              </h2>
            </Grid>

            <Grid item xs={6}>
              <h2 className='mt-0'>
                <FormLabel component='legend' className='text-label'>
                  Years of Experience
                </FormLabel>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={data.yoe}
                  onChange={(e) => this.handleFormChange(e, 'yoe')}
                />
              </h2>
            </Grid>
            <Grid item xs={3}>
              <h2 className='mt-0'>
                <FormLabel component='legend' className='text-label'>
                  Latitude
                </FormLabel>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={data.latitude}
                  onChange={(e) => this.handleFormChange(e, 'latitude')}
                />
              </h2>
            </Grid>
            <Grid item xs={3}>
              <h2 className='mt-0'>
                <FormLabel component='legend' className='text-label'>
                  Longitude
                </FormLabel>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={data.longitude}
                  onChange={(e) => this.handleFormChange(e, 'longitude')}
                />
              </h2>
            </Grid>

            <Grid item xs={6}>
              <h2 className='mt-0'>
                <FormLabel component='legend' className='text-label'>
                  Address 1<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                </FormLabel>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={data.address1}
                  onChange={(e) => this.handleFormChange(e, 'address1')}
                  error={isError && data.address1 === ''}
                  helperText={
                    isError && data.address1 === '' ? 'Please enter address' : ' '
                  }
                />
              </h2>
            </Grid>
            <Grid item xs={6}>
              <h2 className='mt-0'>
                <FormLabel component='legend' className='text-label'>
                  Address 2
                </FormLabel>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={data.address2}
                  onChange={(e) => this.handleFormChange(e, 'address2')}
                />
              </h2>
            </Grid>
            <Grid item xs={6}>
              <h2 className='mt-0'>
                <FormLabel component='legend' className='text-label'>
                  Landmark
                </FormLabel>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={data.landmark}
                  onChange={(e) => this.handleFormChange(e, 'landmark')}
                />
              </h2>
            </Grid>
            <Grid item xs={6}>
              <h2 className='mt-0 boxed-select'>
                <FormLabel component='legend' className='text-label'>
                  City<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                </FormLabel>
                <Select
                  fullWidth
                  value={data.city}
                  onChange={(e) => {
                    this.handleFormChange(e, 'city');
                  }}
                  error={isError && data.city === ''}
                >
                  {(this.props.cities || []).map((city, index) => (
                    <option key={`city-${index}`} value={city}>
                      {' '}
                      {city}{' '}
                    </option>
                  ))}

                </Select>
                <p style={{ fontSize: '10px', color: 'red' }}>{isError && data.city === '' && 'Please enter city.'}</p>

              </h2>
            </Grid>
            <Grid item xs={6}>
              <h2 className='mt-0 boxed-select'>
                <FormLabel component='legend' className='text-label'>
                  State<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                </FormLabel>
                <Select
                  fullWidth
                  value={data.state}
                  onChange={(e) => {
                    this.handleFormChange(e, 'state');
                  }}
                  error={isError && data.state === ''}
                >
                  {(this.props.states || []).map((state, index) => (
                    <option key={`state-${state.id}`} value={state.name}>
                      {' '}
                      {state.name}{' '}
                    </option>
                  ))}
                </Select>
                <p style={{ fontSize: '10px', color: 'red' }}>{isError && data.state === '' && 'Please enter state.'}</p>
              </h2>
            </Grid>
            <Grid item xs={6}>
              <h2 className='mt-0'>
                <FormLabel component='legend' className='text-label'>
                  Zipcode<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                </FormLabel>
                <TextField
                  fullWidth
                  InputProps={{ inputProps: { minLength: 6, maxLength: 6 } }}
                  variant='outlined'
                  value={data.zipcode}
                  onChange={(e) => this.handleFormChange(e, 'zipcode')}
                  error={isError && data.zipcode === ''}
                  helperText={
                    isError && data.zipcode === '' ? 'Please enter zipcode' : ' '
                  }
                />
              </h2>
            </Grid>
            <Grid item xs={6}>
              <h2 className='mt-0 boxed-select'>
                <FormLabel component='legend' className='text-label'>
                  Working Status<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                </FormLabel>
                <Select
                  style={{ width: 100 }}
                  value={data.working_status || ''}
                  onChange={(e) => {
                    this.handleFormChange(e, "working_status");
                  }}
                  error={isError && data.working_status === ''}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={'Working'}>Working</MenuItem>
                  <MenuItem value={'Resigned'}>Resigned</MenuItem>
                  <MenuItem value={'Terminated'}>Terminated</MenuItem>
                  <MenuItem value={'Abscond'}>Abscond</MenuItem>
                  <MenuItem value={'On-Hold'}>On-Hold</MenuItem>
                </Select>
                <p style={{ fontSize: '10px', color: 'red' }}>{isError && data.working_status === '' && 'Please select working status.'}</p>

              </h2>
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ marginTop: 5, padding: '10px' }}>
            <Grid item xs={3}>
              <h2 className='mt-0 mb-0'>
                <FormLabel component='legend' className='text-label'>
                  Accept PPMC?
                </FormLabel>
              </h2>
              <RadioGroup
                row
                aria-label='position'
                name='position'
                defaultValue='top'
                value={data.accepts_ppmc}
                onChange={(e) => this.handleFormChange(e, 'accepts_ppmc')}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio color='primary' />}
                  label='Yes'
                />
                <FormControlLabel
                  value={0}
                  control={<Radio color='primary' />}
                  label='No'
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
              <h2 className='mt-0 mb-0'>
                <FormLabel component='legend' className='text-label'>
                  Accept Covid?
                </FormLabel>
              </h2>
              <RadioGroup
                row
                aria-label='position'
                name='position'
                defaultValue='top'
                value={data.accepts_covid}
                onChange={(e) => this.handleFormChange(e, 'accepts_covid')}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio color='primary' />}
                  label='Yes'
                />
                <FormControlLabel
                  value={0}
                  control={<Radio color='primary' />}
                  label='No'
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
              <h2 className='mt-0 mb-0'>
                <FormLabel component='legend' className='text-label'>
                  Accept ECG?
                </FormLabel>
              </h2>
              <RadioGroup
                row
                aria-label='position'
                name='position'
                defaultValue='top'
                value={data.accepts_ecg}
                onChange={(e) => this.handleFormChange(e, 'accepts_ecg')}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio color='primary' />}
                  label='Yes'
                />
                <FormControlLabel
                  value={0}
                  control={<Radio color='primary' />}
                  label='No'
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: 10, padding: '10px' }}>
            <Grid item xs={3}>
              <h2 className='mt-0'>
                <FormLabel component='legend' className='text-label'>
                  Working hours Begin (HH MM) - 24 Hours<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                </FormLabel>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={data.working_hours_start}
                  onChange={(e) =>
                    this.handleFormChange(e, 'working_hours_start')
                  }
                  error={(isError && data.working_hours_start === '') || this.state.working_hours.start_error}
                  helperText={
                    this.state.working_hours.start_error
                      ? 'Please enter valid format.'
                      : (isError && data.working_hours_start === '') && 'Please Select Start date'
                  }
                  onBlur={() => {
                    this.validateWorkingHours(
                      data.working_hours_start,
                      'start_error'
                    );
                  }}
                />
              </h2>
            </Grid>
            <Grid item xs={3}>
              <h2 className='mt-0'>
                <FormLabel component='legend' className='text-label'>
                  Working hours End (HH MM) - 24 Hours<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                </FormLabel>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={data.working_hours_end}
                  onChange={(e) => this.handleFormChange(e, 'working_hours_end')}
                  error={(isError && data.working_hours_end === '') || this.state.working_hours.end_error}
                  helperText={
                    this.state.working_hours.end_error
                      ? 'Please enter valid format.'
                      : (isError && data.working_hours_end === '') && 'Please Select End date'
                  }
                  onBlur={() => {
                    this.validateWorkingHours(
                      data.working_hours_end,
                      'end_error'
                    );
                  }}
                />
              </h2>
            </Grid>
            <Grid item xs={3}>
              <h2 className='mt-0'>
                <FormLabel component='legend' className='text-label'>
                  Working hours Begin (HH MM) - 24 Hours
                </FormLabel>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={data.working_hours_start1}
                  onChange={(e) =>
                    this.handleFormChange(e, 'working_hours_start1')
                  }
                  error={this.state.working_hours.start1_error}
                  helperText={
                    this.state.working_hours.start1_error
                      ? 'Please enter valid format.'
                      : ' '
                  }
                  onBlur={() => {
                    this.validateWorkingHours(
                      data.working_hours_start1,
                      'start1_error'
                    );
                  }}
                />
              </h2>
            </Grid>
            <Grid item xs={3}>
              <h2 className='mt-0'>
                <FormLabel component='legend' className='text-label'>
                  Working hours End (HH MM) - 24 Hours
                </FormLabel>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={data.working_hours_end1}
                  onChange={(e) => this.handleFormChange(e, 'working_hours_end1')}
                  error={this.state.working_hours.end1_error}
                  helperText={
                    this.state.working_hours.end1_error
                      ? 'Please enter valid format.'
                      : ' '
                  }
                  onBlur={() => {
                    this.validateWorkingHours(
                      data.working_hours_end1,
                      'end1_error'
                    );
                  }}
                />
              </h2>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginLeft: 0, marginTop: 30, padding: '10px' }}>
            <Grid container item xs={12} style={{ padding: 0 }}>
              <h2 className='mt-0 mb-0'>
                <FormLabel
                  component='legend'
                  className='text-label'
                  style={{ fontSize: 18, color: '#000000' }}
                >
                  Zone Name &nbsp; -
                  <span
                    className='salary-second-label'
                    style={{
                      marginLeft: 6,
                      color: '#00c9aa',
                    }}
                  >
                    {data.zoneName}
                  </span>
                </FormLabel>
              </h2>
            </Grid>
          </Grid>

        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Button
              style={{ backgroundColor: '#00C9AA', marginRight: 10 }}
              onClick={(e) => this.validateData('induction')}
              variant='contained'
              color='primary'
            >
              Save
            </Button>
            <Button
              style={{
                borderColor: '#00C9AA',
                color: '#00C9AA',
                marginRight: 10,
              }}
              onClick={() => {
                this.redirectToList();
              }}
              variant='outlined'
              color='primary'
            >
              Back
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  renderFormStudy = (data) => {
    const { isError } = this.state;
    const { lgps } = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={3} style={{ marginTop: 5 }}>
          <Grid item xs={8}>
            <Grid item xs={12}>
              <h2 className='mt-0'>
                <FormLabel component='legend' className='text-label'>
                  Enter Secondary education
                </FormLabel>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={data.secondaryEducation}
                  onChange={(e) =>
                    this.handleFormChange(e, 'secondaryEducation')
                  }
                />
              </h2>
            </Grid>
            <Grid item xs={12}>
              <span className='log-inline-img'>
                <Grid container xs={12}>
                  <Grid item xs={12}>
                    <div className='file-upload'>
                      Add document
                      <input
                        style={{ fontSize: 'unset', margin: 10 }}
                        type='file'
                        name='secondaryEducationUrl'
                        accept='image/*'
                        className='input_file'
                        onChange={(e) => {
                          this.uploadImage(
                            e.target.files,
                            'secondaryEducationUrl',
                            1
                          );
                        }}
                      />
                    </div>
                  </Grid>
                  {data.secondaryEducationUrl &&
                    data.secondaryEducationUrl.map((el, index) => (
                      <Grid
                        item
                        style={{
                          width: 100,
                          position: 'relative',
                          marginTop: 5,
                        }}
                        xs={2}
                      >
                        <Avatar
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.setState({ viewImages: [el] })}
                          src={el}
                          size='small'
                        />{' '}
                        <ImageSlider images={this.state.viewImages} />
                        <div
                          style={{
                            position: 'absolute',
                            left: 45,
                            top: 0,
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {
                            this.removeUpload('secondaryEducationUrl', el);
                          }}
                        >
                          X
                        </div>
                      </Grid>
                    ))}
                </Grid>
              </span>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Percentage
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.secondaryEducationPerc}
                onChange={(e) =>
                  this.handleFormChange(e, 'secondaryEducationPerc')
                }
              />
            </h2>
          </Grid>

          <Grid item xs={8}>
            <Grid item xs={12}>
              <h2 className='mt-0'>
                <FormLabel component='legend' className='text-label'>
                  Enter Higher Secondary education
                </FormLabel>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={data.higherSecondaryEducation}
                  onChange={(e) =>
                    this.handleFormChange(e, 'higherSecondaryEducation')
                  }
                />
              </h2>
            </Grid>
            <Grid item xs={12}>
              <span className='log-inline-img'>
                <Grid container xs={12}>
                  <Grid item xs={12}>
                    <div className='file-upload'>
                      Add document
                      <input
                        style={{ fontSize: 'unset', margin: 10 }}
                        type='file'
                        name='higherSecondaryEducationUrl'
                        accept='image/*'
                        className='input_file'
                        onChange={(e) => {
                          this.uploadImage(
                            e.target.files,
                            'higherSecondaryEducationUrl',
                            1
                          );
                        }}
                      />
                    </div>
                  </Grid>
                  {data.higherSecondaryEducationUrl &&
                    data.higherSecondaryEducationUrl.map((el, index) => (
                      <Grid
                        item
                        style={{
                          width: 100,
                          position: 'relative',
                          marginTop: 5,
                        }}
                        xs={2}
                      >
                        <Avatar
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.setState({ viewImages: [el] })}
                          src={el}
                          size='small'
                        />{' '}
                        <ImageSlider images={this.state.viewImages} />
                        <div
                          style={{
                            position: 'absolute',
                            left: 45,
                            top: 0,
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {
                            this.removeUpload(
                              'higherSecondaryEducationUrl',
                              el
                            );
                          }}
                        >
                          X
                        </div>
                      </Grid>
                    ))}
                </Grid>
              </span>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Percentage
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.higherSecondaryEducationPerc}
                onChange={(e) =>
                  this.handleFormChange(e, 'higherSecondaryEducationPerc')
                }
              />
            </h2>
          </Grid>

          <Grid item xs={8}>
            <Grid item xs={12}>
              <h2 className='mt-0'>
                <FormLabel component='legend' className='text-label'>
                  Enter Graduation
                </FormLabel>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={data.gradEducation}
                  onChange={(e) => this.handleFormChange(e, 'gradEducation')}
                />
              </h2>
            </Grid>
            <Grid item xs={12}>
              <span className='log-inline-img'>
                <Grid container xs={12}>
                  <Grid item xs={12}>
                    <div className='file-upload'>
                      Add document
                      <input
                        style={{ fontSize: 'unset', margin: 10 }}
                        type='file'
                        name='gradEducationUrl'
                        accept='image/*'
                        className='input_file'
                        onChange={(e) => {
                          this.uploadImage(
                            e.target.files,
                            'gradEducationUrl',
                            1
                          );
                        }}
                      />
                    </div>
                  </Grid>
                  {data.gradEducationUrl &&
                    data.gradEducationUrl.map((el, index) => (
                      <Grid
                        item
                        style={{
                          width: 100,
                          position: 'relative',
                          marginTop: 5,
                        }}
                        xs={2}
                      >
                        <Avatar
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.setState({ viewImages: [el] })}
                          src={el}
                          size='small'
                        />{' '}
                        <ImageSlider images={this.state.viewImages} />
                        <div
                          style={{
                            position: 'absolute',
                            left: 45,
                            top: 0,
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {
                            this.removeUpload('gradEducationUrl', el);
                          }}
                        >
                          X
                        </div>
                      </Grid>
                    ))}
                </Grid>
              </span>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Percentage
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.gradEducationPerc}
                onChange={(e) => this.handleFormChange(e, 'gradEducationPerc')}
              />
            </h2>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            {data.phleboId !== '' && (
              <Button
                style={{ backgroundColor: '#00C9AA', marginRight: 10 }}
                onClick={(e) => this.validateData()}
                variant='contained'
                color='primary'
              >
                Save
              </Button>
            )}
            <Button
              style={{
                borderColor: '#00C9AA',
                color: '#00C9AA',
                marginRight: 10,
              }}
              onClick={() => {
                this.redirectToList();
              }}
              variant='outlined'
              color='primary'
            >
              Back
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  renderFormFamily = (data) => {
    const { isError } = this.state;
    const { lgps } = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={3} style={{ marginTop: 5 }}>
          <Grid item xs={6}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Father name
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.fatherName}
                onChange={(e) => this.handleFormChange(e, 'fatherName')}
              />
            </h2>
          </Grid>
          <Grid item xs={6}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Mother name
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.motherName}
                onChange={(e) => this.handleFormChange(e, 'motherName')}
              />
            </h2>
          </Grid>

          <Grid item xs={6}>
            <Grid item xs={12}>
              <h2 className='mt-0'>
                <FormLabel component='legend' className='text-label'>
                  Father aadhar number
                </FormLabel>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={data.fatherAadharNo}
                  onChange={(e) => this.handleFormChange(e, 'fatherAadharNo')}
                />
              </h2>
            </Grid>
            <Grid item xs={12}>
              <span className='log-inline-img'>
                <Grid container xs={12}>
                  <Grid item xs={12}>
                    <div className='file-upload'>
                      Add document
                      <input
                        style={{ fontSize: 'unset', margin: 10 }}
                        type='file'
                        name='fatherAadharUrl'
                        accept='image/*'
                        className='input_file'
                        onChange={(e) => {
                          this.uploadImage(
                            e.target.files,
                            'fatherAadharUrl',
                            1
                          );
                        }}
                      />
                    </div>
                  </Grid>
                  {data.fatherAadharUrl &&
                    data.fatherAadharUrl.map((el, index) => (
                      <Grid
                        item
                        style={{
                          width: 100,
                          position: 'relative',
                          marginTop: 5,
                        }}
                        xs={2}
                      >
                        <Avatar
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.setState({ viewImages: [el] })}
                          src={el}
                          size='small'
                        />{' '}
                        <ImageSlider images={this.state.viewImages} />
                        <div
                          style={{
                            position: 'absolute',
                            left: 45,
                            top: 0,
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {
                            this.removeUpload('fatherAadharUrl', el);
                          }}
                        >
                          X
                        </div>
                      </Grid>
                    ))}
                </Grid>
              </span>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <h2 className='mt-0'>
                <FormLabel component='legend' className='text-label'>
                  Mother aadhar number
                </FormLabel>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={data.motherAadharNo}
                  onChange={(e) => this.handleFormChange(e, 'motherAadharNo')}
                />
              </h2>
            </Grid>
            <Grid item xs={12}>
              <span className='log-inline-img'>
                <Grid container xs={12}>
                  <Grid item xs={12}>
                    <div className='file-upload'>
                      Add document
                      <input
                        style={{ fontSize: 'unset', margin: 10 }}
                        type='file'
                        name='motherAadharUrl'
                        accept='image/*'
                        className='input_file'
                        onChange={(e) => {
                          this.uploadImage(
                            e.target.files,
                            'motherAadharUrl',
                            1
                          );
                        }}
                      />
                    </div>
                  </Grid>
                  {data.motherAadharUrl &&
                    data.motherAadharUrl.map((el, index) => (
                      <Grid
                        item
                        style={{
                          width: 100,
                          position: 'relative',
                          marginTop: 5,
                        }}
                        xs={2}
                      >
                        <Avatar
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.setState({ viewImages: [el] })}
                          src={el}
                          size='small'
                        />{' '}
                        <ImageSlider images={this.state.viewImages} />
                        <div
                          style={{
                            position: 'absolute',
                            left: 45,
                            top: 0,
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {
                            this.removeUpload('motherAadharUrl', el);
                          }}
                        >
                          X
                        </div>
                      </Grid>
                    ))}
                </Grid>
              </span>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Emergency contact number
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.emergencyContact}
                onChange={(e) => this.handleFormChange(e, 'emergencyContact')}
              />
            </h2>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            {data.phleboId !== '' && (
              <Button
                style={{ backgroundColor: '#00C9AA', marginRight: 10 }}
                onClick={(e) => this.validateData()}
                variant='contained'
                color='primary'
              >
                Save
              </Button>
            )}
            <Button
              style={{
                borderColor: '#00C9AA',
                color: '#00C9AA',
                marginRight: 10,
              }}
              onClick={() => {
                this.redirectToList();
              }}
              variant='outlined'
              color='primary'
            >
              Back
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  renderFormSalary = (data) => {
    const { isError } = this.state;
    const { lgps } = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={3} style={{ marginTop: 5 }}>
          <Grid item xs={6}>
            <h2 className='mt-0 boxed-select'>
              <FormLabel component='legend' className='text-label'>
                City Tier
              </FormLabel>
              <Select
                fullWidth
                defaultValue={data.tier.id}
                value={data.tier.id}
                onChange={(e) => {
                  this.handleSalaryChange(e, 'tier');
                }}
              >
                {(this.props.salaries.tier || []).map((tier, index) => (
                  <option key={`city-${index}`} value={tier.id}>
                    {' '}
                    {tier.name}{' '}
                  </option>
                ))}
              </Select>

              <FormLabel
                component='legend'
                className='salary-first-label text-label'
                style={{ fontSize: 10 }}
              >
                <span style={{ position: 'relative', top: 13 }}>
                  {data.tier.id
                    ? this.state.salary.tier.min +
                    ' - ' +
                    this.state.salary.tier.max
                    : null}
                </span>

                {data.tier.id && (
                  <TextField
                    fullWidth
                    variant='outlined'
                    style={{ width: '25%', padding: 0, marginLeft: 10 }}
                    value={data.tier.value}
                    onChange={(e) => this.handleSalaryForm(e, 'tier')}
                    error={this.state.salary.tier.error}
                    helperText={
                      this.state.salary.tier.error
                        ? 'Please enter number between min and max.'
                        : ' '
                    }
                    onBlur={() => {
                      this.validateSalaryForm('tier');
                    }}
                  />
                )}
              </FormLabel>
            </h2>
          </Grid>
          <Grid item xs={6}>
            <h2 className='mt-0 boxed-select'>
              <FormLabel component='legend' className='text-label'>
                Working hours
              </FormLabel>
              <Select
                fullWidth
                value={data.hours.id}
                onChange={(e) => {
                  this.handleSalaryChange(e, 'hours');
                }}
              >
                {(this.props.salaries.hours || []).map((hours, index) => (
                  <option key={`hours-${index}`} value={hours.id}>
                    {' '}
                    {hours.name}{' '}
                  </option>
                ))}
              </Select>

              <FormLabel
                component='legend'
                className='salary-first-label text-label'
                style={{ fontSize: 10 }}
              >
                <span style={{ position: 'relative', top: 13 }}>
                  {data.hours.id
                    ? this.state.salary.hours.min +
                    ' - ' +
                    this.state.salary.hours.max
                    : null}
                </span>

                {data.hours.id && (
                  <TextField
                    fullWidth
                    variant='outlined'
                    style={{ width: '25%', padding: 0, marginLeft: 10 }}
                    value={data.hours.value}
                    onChange={(e) => this.handleSalaryForm(e, 'hours')}
                    error={this.state.salary.hours.error}
                    helperText={
                      this.state.salary.hours.error
                        ? 'Please enter number between min and max.'
                        : ' '
                    }
                    onBlur={() => {
                      this.validateSalaryForm('hours');
                    }}
                  />
                )}
              </FormLabel>
            </h2>
          </Grid>
          <Grid item xs={6}>
            <h2 className='mt-0 boxed-select'>
              <FormLabel component='legend' className='text-label'>
                Years of experience
              </FormLabel>
              <Select
                fullWidth
                value={data.experience.id}
                onChange={(e) => {
                  this.handleSalaryChange(e, 'experience');
                }}
              >
                {(this.props.salaries.experience || []).map(
                  (experience, index) => (
                    <option key={`experience-${index}`} value={experience.id}>
                      {' '}
                      {experience.name}{' '}
                    </option>
                  )
                )}
              </Select>

              <FormLabel
                component='legend'
                className='salary-first-label text-label'
                style={{ fontSize: 10 }}
              >
                <span style={{ position: 'relative', top: 13 }}>
                  {data.experience.id
                    ? this.state.salary.experience.min +
                    ' - ' +
                    this.state.salary.experience.max
                    : null}
                </span>

                {data.experience.id && (
                  <TextField
                    fullWidth
                    variant='outlined'
                    style={{ width: '25%', padding: 0, marginLeft: 10 }}
                    value={data.experience.value}
                    onChange={(e) => this.handleSalaryForm(e, 'experience')}
                    error={this.state.salary.experience.error}
                    helperText={
                      this.state.salary.experience.error
                        ? 'Please enter number between min and max.'
                        : ' '
                    }
                    onBlur={() => {
                      this.validateSalaryForm('experience');
                    }}
                  />
                )}
              </FormLabel>
            </h2>
          </Grid>
          <Grid item xs={6}>
            <h2 className='mt-0 boxed-select'>
              <FormLabel component='legend' className='text-label'>
                Zone flexibility
              </FormLabel>
              <Select
                fullWidth
                value={data.zones.id}
                onChange={(e) => {
                  this.handleSalaryChange(e, 'zones');
                }}
              >
                {(this.props.salaries.zones || []).map((zones, index) => (
                  <option key={`zones-${index}`} value={zones.id}>
                    {' '}
                    {zones.name}{' '}
                  </option>
                ))}
              </Select>

              <FormLabel
                component='legend'
                className='salary-first-label text-label'
                style={{ fontSize: 10 }}
              >
                <span style={{ position: 'relative', top: 13 }}>
                  {data.zones.id
                    ? this.state.salary.zones.min +
                    ' - ' +
                    this.state.salary.zones.max
                    : null}
                </span>

                {data.zones.id && (
                  <TextField
                    fullWidth
                    variant='outlined'
                    style={{ width: '25%', padding: 0, marginLeft: 10 }}
                    value={data.zones.value}
                    onChange={(e) => this.handleSalaryForm(e, 'zones')}
                    error={this.state.salary.zones.error}
                    helperText={
                      this.state.salary.zones.error
                        ? 'Please enter number between min and max.'
                        : ' '
                    }
                    onBlur={() => {
                      this.validateSalaryForm('zones');
                    }}
                  />
                )}
              </FormLabel>
            </h2>
          </Grid>
          <Grid item xs={6}>
            <h2 className='mt-0 boxed-select'>
              <FormLabel component='legend' className='text-label'>
                Working years in i2H
              </FormLabel>
              <Select
                fullWidth
                value={data.service.id}
                onChange={(e) => {
                  this.handleSalaryChange(e, 'service');
                }}
              >
                {(this.props.salaries.service || []).map((service, index) => (
                  <option key={`service-${index}`} value={service.id}>
                    {' '}
                    {service.name}{' '}
                  </option>
                ))}
              </Select>

              <FormLabel
                component='legend'
                className='salary-first-label text-label'
                style={{ fontSize: 10 }}
              >
                <span style={{ position: 'relative', top: 13 }}>
                  {data.service.id
                    ? this.state.salary.service.min +
                    ' - ' +
                    this.state.salary.service.max
                    : null}
                </span>

                {data.service.id && (
                  <TextField
                    fullWidth
                    variant='outlined'
                    style={{ width: '25%', padding: 0, marginLeft: 10 }}
                    value={data.service.value}
                    onChange={(e) => this.handleSalaryForm(e, 'service')}
                    error={this.state.salary.service.error}
                    helperText={
                      this.state.salary.service.error
                        ? 'Please enter number between min and max.'
                        : ' '
                    }
                    onBlur={() => {
                      this.validateSalaryForm('service');
                    }}
                  />
                )}
              </FormLabel>
            </h2>
          </Grid>
          <Grid container spacing={3} style={{ marginLeft: 0 }}>
            <Grid container xs={6} spacing={3}>
              <Grid item xs={4} style={{ marginLeft: 20, marginTop: 10 }}>
                <h2 className='mt-0 mb-0'>
                  <FormLabel component='legend' className='text-label'>
                    DLMT?
                  </FormLabel>
                </h2>
                <RadioGroup
                  row
                  aria-label='position'
                  name='position'
                  defaultValue='top'
                  value={this.state.salary.isDMLT.option}
                  onChange={(e) =>
                    this.handleSalaryOption(
                      e,
                      'isDMLT',
                      this.props.salaries.isDMLT
                    )
                  }
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio color='primary' />}
                    label='Yes'
                  />
                  <FormControlLabel
                    value={0}
                    control={<Radio color='primary' />}
                    label='No'
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={5} style={{ marginTop: 20 }}>
                <FormLabel
                  component='legend'
                  className='salary-first-label text-label'
                  style={{ fontSize: 10 }}
                >
                  <span style={{ position: 'relative', top: 13 }}>
                    {this.state.salary.isDMLT.option == 1
                      ? this.state.salary.isDMLT.max
                      : null}
                  </span>

                  {this.state.salary.isDMLT.option == 1 && (
                    <TextField
                      fullWidth
                      variant='outlined'
                      style={{ width: '50%', padding: 0, marginLeft: 10 }}
                      value={data.isDMLT}
                      onChange={(e) => this.handleFormChange(e, 'isDMLT')}
                      error={this.state.salary.isDMLT.error}
                      helperText={
                        this.state.salary.isDMLT.error
                          ? 'Please enter number less then value.'
                          : ' '
                      }
                      onBlur={() => {
                        this.validateSalaryOption('isDMLT');
                      }}
                    />
                  )}
                </FormLabel>
              </Grid>
            </Grid>
            <Grid container xs={6} spacing={3}>
              <Grid item xs={4} style={{ marginLeft: 20, marginTop: 10 }}>
                <h2 className='mt-0 mb-0'>
                  <FormLabel component='legend' className='text-label'>
                    PPMC?
                  </FormLabel>
                </h2>
                <RadioGroup
                  row
                  aria-label='position'
                  name='position'
                  defaultValue='top'
                  value={this.state.salary.isPPMC.option}
                  onChange={(e) =>
                    this.handleSalaryOption(
                      e,
                      'isPPMC',
                      this.props.salaries.isPPMC
                    )
                  }
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio color='primary' />}
                    label='Yes'
                  />
                  <FormControlLabel
                    value={0}
                    control={<Radio color='primary' />}
                    label='No'
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={5} style={{ marginTop: 20 }}>
                <FormLabel
                  component='legend'
                  className='salary-first-label text-label'
                  style={{ fontSize: 10 }}
                >
                  <span style={{ position: 'relative', top: 13 }}>
                    {this.state.salary.isPPMC.option == 1
                      ? this.state.salary.isPPMC.max
                      : null}
                  </span>

                  {this.state.salary.isPPMC.option == 1 && (
                    <TextField
                      fullWidth
                      variant='outlined'
                      type='number'
                      style={{ width: '50%', padding: 0, marginLeft: 10 }}
                      value={data.isPPMC}
                      onChange={(e) => this.handleFormChange(e, 'isPPMC')}
                      error={this.state.salary.isPPMC.error}
                      helperText={
                        this.state.salary.isPPMC.error
                          ? 'Please enter number less then value.'
                          : ' '
                      }
                      onBlur={() => {
                        this.validateSalaryOption('isPPMC');
                      }}
                    />
                  )}
                </FormLabel>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <h2 className='mt-0 mb-0'>
                <FormLabel
                  component='legend'
                  className='text-label'
                  style={{ fontSize: 18, color: '#000000' }}
                >
                  <span style={{ position: 'relative', top: 5 }}>
                    Bonus/other Incentives
                  </span>

                  <TextField
                    fullWidth
                    variant='outlined'
                    style={{ width: '10%', padding: 0, marginLeft: 10 }}
                    value={data.bonus}
                    onChange={(e) => this.handleFormChange(e, 'bonus')}
                  />
                  <span
                    className='salary-second-label'
                    style={{
                      marginLeft: 10,
                      fontSize: 18,
                      color: '#000000',
                      position: 'relative',
                      top: 8,
                    }}
                  >
                    INR
                  </span>
                </FormLabel>
              </h2>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <h2 className='mt-0 mb-0'>
                <FormLabel
                  component='legend'
                  className='text-label'
                  style={{ fontSize: 18, color: '#000000' }}
                >
                  Gross Salary -
                  <span
                    className='salary-second-label'
                    style={{
                      marginLeft: 18,
                      color: '#00c9aa',
                    }}
                  >
                    {data.gross}
                  </span>
                  <span
                    className='salary-second-label'
                    style={{ marginLeft: 10, fontSize: 18, color: '#000000' }}
                  >
                    INR
                  </span>
                </FormLabel>
              </h2>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            {data.phleboId !== '' && isError === false ? (
              <Button
                style={{ backgroundColor: '#00C9AA', marginRight: 10 }}
                onClick={(e) => this.validateData()}
                variant='contained'
                color='primary'
              >
                Save
              </Button>
            ) : null}
            <Button
              style={{
                borderColor: '#00C9AA',
                color: '#00C9AA',
                marginRight: 10,
              }}
              onClick={() => {
                this.redirectToList();
              }}
              variant='outlined'
              color='primary'
            >
              Back
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  renderFormAccount = (data) => {
    const { isError } = this.state;
    const { lgps } = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={3} style={{ marginTop: 5 }}>
          <Grid item xs={6}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                E-mail ID*
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.emailId}
                onChange={(e) => this.handleFormChange(e, 'emailId')}
              />
            </h2>
          </Grid>
          <Grid item xs={6}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Username
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.username}
                onChange={(e) => this.handleFormChange(e, 'username')}
                error={isError && data.username === ''}
                helperText={
                  isError && data.username === ''
                    ? 'Please enter username'
                    : ' '
                }
              />
            </h2>
          </Grid>
          <Grid item xs={6}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Password
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                type='password'
                value={data.password}
                onChange={(e) => this.handleFormChange(e, 'password')}
                error={isError && data.password === ''}
                helperText={
                  isError && data.password === ''
                    ? 'Please enter password'
                    : ' '
                }
              />
            </h2>
          </Grid>

          <Grid item xs={6}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Years of Experience
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.yoe}
                onChange={(e) => this.handleFormChange(e, 'yoe')}
              />
            </h2>
          </Grid>
          <Grid item xs={3}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Latitude
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.latitude}
                onChange={(e) => this.handleFormChange(e, 'latitude')}
              />
            </h2>
          </Grid>
          <Grid item xs={3}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Longitude
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.longitude}
                onChange={(e) => this.handleFormChange(e, 'longitude')}
              />
            </h2>
          </Grid>

          <Grid item xs={6}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Address 1
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.address1}
                onChange={(e) => this.handleFormChange(e, 'address1')}
                error={isError && data.address1 === ''}
                helperText={
                  isError && data.address1 === '' ? 'Please enter address' : ' '
                }
              />
            </h2>
          </Grid>
          <Grid item xs={6}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Address 2
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.address2}
                onChange={(e) => this.handleFormChange(e, 'address2')}
              />
            </h2>
          </Grid>
          <Grid item xs={6}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Landmark
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.landmark}
                onChange={(e) => this.handleFormChange(e, 'landmark')}
              />
            </h2>
          </Grid>
          <Grid item xs={6}>
            <h2 className='mt-0 boxed-select'>
              <FormLabel component='legend' className='text-label'>
                City
              </FormLabel>
              <Select
                fullWidth
                value={data.city}
                onChange={(e) => {
                  this.handleFormChange(e, 'city');
                }}
              >
                {(this.props.cities || []).map((city, index) => (
                  <option key={`city-${index}`} value={city}>
                    {' '}
                    {city}{' '}
                  </option>
                ))}
              </Select>
            </h2>
          </Grid>
          <Grid item xs={6}>
            <h2 className='mt-0 boxed-select'>
              <FormLabel component='legend' className='text-label'>
                State
              </FormLabel>
              <Select
                fullWidth
                value={data.state}
                onChange={(e) => {
                  this.handleFormChange(e, 'state');
                }}
              >
                {(this.props.states || []).map((state, index) => (
                  <option key={`state-${state.id}`} value={state.name}>
                    {' '}
                    {state.name}{' '}
                  </option>
                ))}
              </Select>
            </h2>
          </Grid>
          <Grid item xs={6}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Zipcode
              </FormLabel>
              <TextField
                fullWidth
                InputProps={{ inputProps: { minLength: 6, maxLength: 6 } }}
                variant='outlined'
                value={data.zipcode}
                onChange={(e) => this.handleFormChange(e, 'zipcode')}
              />
            </h2>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            {data.phleboId !== '' && (
              <Button
                style={{ backgroundColor: '#00C9AA', marginRight: 10 }}
                onClick={(e) => this.validateData()}
                variant='contained'
                color='primary'
              >
                Save
              </Button>
            )}
            <Button
              style={{
                borderColor: '#00C9AA',
                color: '#00C9AA',
                marginRight: 10,
              }}
              onClick={() => {
                this.redirectToList();
              }}
              variant='outlined'
              color='primary'
            >
              Back
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  renderFormPreferences = (data) => {
    const { isError } = this.state;
    const { lgps } = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={3} style={{ marginTop: 5 }}>
          <Grid item xs={3}>
            <h2 className='mt-0 mb-0'>
              <FormLabel component='legend' className='text-label'>
                Accept PPMC?
              </FormLabel>
            </h2>
            <RadioGroup
              row
              aria-label='position'
              name='position'
              defaultValue='top'
              value={data.accepts_ppmc}
              onChange={(e) => this.handleFormChange(e, 'accepts_ppmc')}
            >
              <FormControlLabel
                value={1}
                control={<Radio color='primary' />}
                label='Yes'
              />
              <FormControlLabel
                value={0}
                control={<Radio color='primary' />}
                label='No'
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={3}>
            <h2 className='mt-0 mb-0'>
              <FormLabel component='legend' className='text-label'>
                Accept Covid?
              </FormLabel>
            </h2>
            <RadioGroup
              row
              aria-label='position'
              name='position'
              defaultValue='top'
              value={data.accepts_covid}
              onChange={(e) => this.handleFormChange(e, 'accepts_covid')}
            >
              <FormControlLabel
                value={1}
                control={<Radio color='primary' />}
                label='Yes'
              />
              <FormControlLabel
                value={0}
                control={<Radio color='primary' />}
                label='No'
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={3}>
            <h2 className='mt-0 mb-0'>
              <FormLabel component='legend' className='text-label'>
                Accept ECG?
              </FormLabel>
            </h2>
            <RadioGroup
              row
              aria-label='position'
              name='position'
              defaultValue='top'
              value={data.accepts_ecg}
              onChange={(e) => this.handleFormChange(e, 'accepts_ecg')}
            >
              <FormControlLabel
                value={1}
                control={<Radio color='primary' />}
                label='Yes'
              />
              <FormControlLabel
                value={0}
                control={<Radio color='primary' />}
                label='No'
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={3}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Working hours Begin (HH MM) - 24 Hours
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.working_hours_start}
                onChange={(e) =>
                  this.handleFormChange(e, 'working_hours_start')
                }
                error={this.state.working_hours.start_error}
                helperText={
                  this.state.working_hours.start_error
                    ? 'Please enter valid format.'
                    : ' '
                }
                onBlur={() => {
                  this.validateWorkingHours(
                    data.working_hours_start,
                    'start_error'
                  );
                }}
              />
            </h2>
          </Grid>
          <Grid item xs={3}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Working hours End (HH MM) - 24 Hours
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.working_hours_end}
                onChange={(e) => this.handleFormChange(e, 'working_hours_end')}
                error={this.state.working_hours.end_error}
                helperText={
                  this.state.working_hours.end_error
                    ? 'Please enter valid format.'
                    : ' '
                }
                onBlur={() => {
                  this.validateWorkingHours(
                    data.working_hours_end,
                    'end_error'
                  );
                }}
              />
            </h2>
          </Grid>
          <Grid item xs={3}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Working hours Begin (HH MM) - 24 Hours
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.working_hours_start1}
                onChange={(e) =>
                  this.handleFormChange(e, 'working_hours_start1')
                }
                error={this.state.working_hours.start1_error}
                helperText={
                  this.state.working_hours.start1_error
                    ? 'Please enter valid format.'
                    : ' '
                }
                onBlur={() => {
                  this.validateWorkingHours(
                    data.working_hours_start1,
                    'start1_error'
                  );
                }}
              />
            </h2>
          </Grid>
          <Grid item xs={3}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Working hours End (HH MM) - 24 Hours
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.working_hours_end1}
                onChange={(e) => this.handleFormChange(e, 'working_hours_end1')}
                error={this.state.working_hours.end1_error}
                helperText={
                  this.state.working_hours.end1_error
                    ? 'Please enter valid format.'
                    : ' '
                }
                onBlur={() => {
                  this.validateWorkingHours(
                    data.working_hours_end1,
                    'end1_error'
                  );
                }}
              />
            </h2>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginLeft: 0, marginTop: 30 }}>
          <Grid container item xs={12} style={{ padding: 0 }}>
            <h2 className='mt-0 mb-0'>
              <FormLabel
                component='legend'
                className='text-label'
                style={{ fontSize: 18, color: '#000000' }}
              >
                Zone Name &nbsp; -
                <span
                  className='salary-second-label'
                  style={{
                    marginLeft: 6,
                    color: '#00c9aa',
                  }}
                >
                  {data.zoneName}
                </span>
              </FormLabel>
            </h2>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            {data.phleboId !== '' && isError === false ? (
              <Button
                style={{ backgroundColor: '#00C9AA', marginRight: 10 }}
                onClick={(e) => this.validateData()}
                variant='contained'
                color='primary'
              >
                Save
              </Button>
            ) : null}
            <Button
              style={{
                borderColor: '#00C9AA',
                color: '#00C9AA',
                marginRight: 10,
              }}
              onClick={() => {
                this.redirectToList();
              }}
              variant='outlined'
              color='primary'
            >
              Back
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  renderFormInduction = (data) => {
    const { isError } = this.state;
    const { lgps } = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={3} style={{ marginLeft: 0, marginTop: 30 }}>
          <Grid item xs={3}>
            <h2 className='mt-0 mb-0'>
              <FormLabel component='legend' className='text-label'>
                Generate offer
              </FormLabel>
            </h2>
          </Grid>
          <Grid item xs={3} style={{ padding: 0 }}>
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label='position'
                name='position'
                defaultValue='top'
                disabled={
                  this.state.induction.offerGeneratedDate === 1 ? true : false
                }
                value={this.state.induction.offerGeneratedDate}
                onChange={(e) =>
                  this.handleInductionOption(e, 'offerGeneratedDate')
                }
              >
                <FormControlLabel
                  value={1}
                  control={<Radio color='primary' />}
                  label='Yes'
                />
                <FormControlLabel
                  disabled={
                    this.state.disableinduction.offerGeneratedDate === 1
                      ? true
                      : false
                  }
                  value={0}
                  control={<Radio color='primary' />}
                  label='No'
                />
              </RadioGroup>
              {this.state.induction.offerGeneratedDate === 1 && (
                <>
                  <DatePicker
                    value={data.offerGeneratedDate}
                    disabled={
                      this.state.inductionBy.offerGeneratedDate !== ''
                        ? true
                        : false
                    }
                    ranges={[]}
                    dateFormat='MMMM d, yyyy'
                    onChange={(date) => {
                      this.handleDateChange(date, 'offerGeneratedDate');
                    }}
                  />
                  {this.state.inductionBy.offerGeneratedDate !== '' && (
                    <h2 className='mt-10 mb-0'>
                      <FormLabel
                        component='legend'
                        className='text-label'
                        style={{ fontSize: 18, color: '#000000' }}
                      >
                        Issued By - {this.state.inductionBy.offerGeneratedDate}
                      </FormLabel>
                    </h2>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginLeft: 0, marginTop: 30 }}>
          <Grid item xs={3}>
            <h2 className='mt-0 mb-0'>
              <FormLabel component='legend' className='text-label'>
                ID creation
              </FormLabel>
            </h2>
          </Grid>
          <Grid item xs={9} style={{ padding: 0 }}>
            <Grid item xs={3} style={{ padding: 0 }}>
              <h2 className='mt-0 mb-0'>
                <RadioGroup
                  row
                  aria-label='position'
                  name='position'
                  defaultValue='top'
                  disabled={
                    this.state.induction.idCreatedDate === 1 ? true : false
                  }
                  value={this.state.induction.idCreatedDate}
                  onChange={(e) =>
                    this.handleInductionOption(e, 'idCreatedDate')
                  }
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio color='primary' />}
                    label='Yes'
                  />
                  <FormControlLabel
                    disabled={
                      this.state.disableinduction.idCreatedDate === 1
                        ? true
                        : false
                    }
                    value={0}
                    control={<Radio color='primary' />}
                    label='No'
                  />
                </RadioGroup>
              </h2>
            </Grid>
            {this.state.induction.idCreatedDate === 1 && (
              <Grid item xs={9} style={{ padding: 0 }}>
                <Grid item xs={6} style={{ marginTop: 10 }}>
                  <FormLabel component='legend' className='text-label'>
                    Date
                  </FormLabel>
                  <DatePicker
                    value={data.idCreatedDate}
                    disabled={
                      this.state.inductionBy.idCreatedDate !== '' ? true : false
                    }
                    ranges={[]}
                    dateFormat='MMMM d, yyyy'
                    onChange={(date) => {
                      this.handleDateChange(date, 'idCreatedDate');
                    }}
                  />
                  {this.state.inductionBy.idCreatedDate !== '' && (
                    <h2 className='mt-10 mb-0'>
                      <FormLabel
                        component='legend'
                        className='text-label'
                        style={{ fontSize: 18, color: '#000000' }}
                      >
                        Issued By - {this.state.inductionBy.idCreatedDate}
                      </FormLabel>
                    </h2>
                  )}
                </Grid>
                <Grid item xs={6} style={{ marginTop: 20 }}>
                  <FormLabel component='legend' className='text-label'>
                    Create Auto Employee Id ?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label='position'
                    name='position'
                    defaultValue='top'
                    value={data.isIDAutoGenerate}
                    disabled={data.isIDAutoGenerate === 1 ? true : false}
                    onChange={(e) =>
                      this.handleFormChange(e, 'isIDAutoGenerate')
                    }
                  >
                    <FormControlLabel
                      disabled={
                        this.state.disableinduction.idCreatedDate === 1
                          ? true
                          : false
                      }
                      value={1}
                      control={<Radio color='primary' />}
                      label='Yes'
                    />
                    <FormControlLabel
                      disabled={
                        this.state.disableinduction.idCreatedDate === 1
                          ? true
                          : false
                      }
                      value={0}
                      control={<Radio color='primary' />}
                      label='No'
                    />
                  </RadioGroup>
                </Grid>
                {data.isIDAutoGenerate === 0 && (
                  <Grid item xs={6} style={{ marginTop: 20 }}>
                    <FormLabel component='legend' className='text-label'>
                      Employee Id
                    </FormLabel>
                    <TextField
                      fullWidth
                      disabled={
                        this.state.inductionBy.idCreatedDate !== ''
                          ? true
                          : false
                      }
                      style={{ width: '88%' }}
                      variant='outlined'
                      value={data.employeeId}
                      onChange={(e) => this.handleFormChange(e, 'employeeId')}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginLeft: 0, marginTop: 30 }}>
          <Grid item xs={3}>
            <h2 className='mt-0 mb-0'>
              <FormLabel component='legend' className='text-label'>
                Date of training
              </FormLabel>
            </h2>
          </Grid>
          <Grid item xs={3} style={{ padding: 0 }}>
            <h2 className='mt-0 boxed-date-picker'>
              <DatePicker
                value={data.trainingDate}
                disabled={
                  this.state.inductionBy.trainingBy !== '' ? true : false
                }
                ranges={[]}
                selected={data.trainingDate}
                dateFormat='MMMM d, yyyy'
                onChange={(date) => {
                  this.handleDateChange(date, 'trainingDate');
                }}
              />
            </h2>
            {this.state.inductionBy.trainingBy !== '' && (
              <h2 className='mt-10 mb-0'>
                <FormLabel
                  component='legend'
                  className='text-label'
                  style={{ fontSize: 18, color: '#000000' }}
                >
                  Issued By - {this.state.inductionBy.trainingDate}
                </FormLabel>
              </h2>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginLeft: 0, marginTop: 30 }}>
          <Grid item xs={3}>
            <h2 className='mt-0 mb-0'>
              <FormLabel component='legend' className='text-label'>
                HR induction
              </FormLabel>
            </h2>
          </Grid>
          <Grid item xs={3} style={{ padding: 0 }}>
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label='position'
                name='position'
                defaultValue='top'
                disabled={
                  this.state.induction.hrInductionDate === 1 ? true : false
                }
                value={this.state.induction.hrInductionDate}
                onChange={(e) =>
                  this.handleInductionOption(e, 'hrInductionDate')
                }
              >
                <FormControlLabel
                  value={1}
                  control={<Radio color='primary' />}
                  label='Yes'
                />
                <FormControlLabel
                  disabled={
                    this.state.disableinduction.hrInductionDate === 1
                      ? true
                      : false
                  }
                  value={0}
                  control={<Radio color='primary' />}
                  label='No'
                />
              </RadioGroup>
              {this.state.induction.hrInductionDate === 1 && (
                <>
                  <DatePicker
                    value={data.hrInductionDate}
                    disabled={
                      this.state.inductionBy.hrInductionDate !== ''
                        ? true
                        : false
                    }
                    ranges={[]}
                    dateFormat='MMMM d, yyyy'
                    onChange={(date) => {
                      this.handleDateChange(date, 'hrInductionDate');
                    }}
                  />
                  {this.state.inductionBy.hrInductionDate !== '' && (
                    <h2 className='mt-10 mb-0'>
                      <FormLabel
                        component='legend'
                        className='text-label'
                        style={{ fontSize: 18, color: '#000000' }}
                      >
                        Issued By - {this.state.inductionBy.hrInductionDate}
                      </FormLabel>
                    </h2>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginLeft: 0, marginTop: 30 }}>
          <Grid item xs={3}>
            <h2 className='mt-0 mb-0'>
              <FormLabel component='legend' className='text-label'>
                QA orientation
              </FormLabel>
            </h2>
          </Grid>
          <Grid item xs={3} style={{ padding: 0 }}>
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label='position'
                name='position'
                defaultValue='top'
                disabled={
                  this.state.induction.qaOrientationDate === 1 ? true : false
                }
                value={this.state.induction.qaOrientationDate}
                onChange={(e) =>
                  this.handleInductionOption(e, 'qaOrientationDate')
                }
              >
                <FormControlLabel
                  value={1}
                  control={<Radio color='primary' />}
                  label='Yes'
                />
                <FormControlLabel
                  disabled={
                    this.state.disableinduction.qaOrientationDate === 1
                      ? true
                      : false
                  }
                  value={0}
                  control={<Radio color='primary' />}
                  label='No'
                />
              </RadioGroup>
              {this.state.induction.qaOrientationDate === 1 && (
                <>
                  <DatePicker
                    value={data.qaOrientationDate}
                    disabled={
                      this.state.inductionBy.qaOrientationDate !== ''
                        ? true
                        : false
                    }
                    ranges={[]}
                    dateFormat='MMMM d, yyyy'
                    onChange={(date) => {
                      this.handleDateChange(date, 'qaOrientationDate');
                    }}
                  />
                  {this.state.inductionBy.qaOrientationDate !== '' && (
                    <h2 className='mt-10 mb-0'>
                      <FormLabel
                        component='legend'
                        className='text-label'
                        style={{ fontSize: 18, color: '#000000' }}
                      >
                        Issued By - {this.state.inductionBy.qaOrientationDate}
                      </FormLabel>
                    </h2>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginLeft: 0, marginTop: 30 }}>
          <Grid item xs={3}>
            <h2 className='mt-0 mb-0'>
              <FormLabel component='legend' className='text-label'>
                Department SOPs
              </FormLabel>
            </h2>
          </Grid>
          <Grid item xs={3} style={{ padding: 0 }}>
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label='position'
                name='position'
                defaultValue='top'
                disabled={
                  this.state.induction.departmentSOPDate === 1 ? true : false
                }
                value={this.state.induction.departmentSOPDate}
                onChange={(e) =>
                  this.handleInductionOption(e, 'departmentSOPDate')
                }
              >
                <FormControlLabel
                  value={1}
                  control={<Radio color='primary' />}
                  label='Yes'
                />
                <FormControlLabel
                  disabled={
                    this.state.disableinduction.departmentSOPDate === 1
                      ? true
                      : false
                  }
                  value={0}
                  control={<Radio color='primary' />}
                  label='No'
                />
              </RadioGroup>
              {this.state.induction.departmentSOPDate === 1 && (
                <>
                  <DatePicker
                    value={data.departmentSOPDate}
                    disabled={
                      this.state.inductionBy.departmentSOPDate !== ''
                        ? true
                        : false
                    }
                    ranges={[]}
                    dateFormat='MMMM d, yyyy'
                    onChange={(date) => {
                      this.handleDateChange(date, 'departmentSOPDate');
                    }}
                  />
                  {this.state.inductionBy.departmentSOPDate !== '' && (
                    <h2 className='mt-10 mb-0'>
                      <FormLabel
                        component='legend'
                        className='text-label'
                        style={{ fontSize: 18, color: '#000000' }}
                      >
                        Issued By - {this.state.inductionBy.departmentSOPDate}
                      </FormLabel>
                    </h2>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginLeft: 0, marginTop: 30 }}>
          <Grid item xs={3}>
            <h2 className='mt-0 mb-0'>
              <FormLabel component='legend' className='text-label'>
                Stock issuence
              </FormLabel>
            </h2>
          </Grid>
          <Grid item xs={3} style={{ padding: 0 }}>
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label='position'
                name='position'
                defaultValue='top'
                disabled={
                  this.state.induction.stockIssuedDate === 1 ? true : false
                }
                value={this.state.induction.stockIssuedDate}
                onChange={(e) =>
                  this.handleInductionOption(e, 'stockIssuedDate')
                }
              >
                <FormControlLabel
                  value={1}
                  control={<Radio color='primary' />}
                  label='Yes'
                />
                <FormControlLabel
                  disabled={
                    this.state.disableinduction.stockIssuedDate === 1
                      ? true
                      : false
                  }
                  value={0}
                  control={<Radio color='primary' />}
                  label='No'
                />
              </RadioGroup>
              {this.state.induction.stockIssuedDate === 1 && (
                <>
                  <DatePicker
                    value={data.stockIssuedDate}
                    disabled={
                      this.state.inductionBy.stockIssuedDate !== ''
                        ? true
                        : false
                    }
                    ranges={[]}
                    dateFormat='MMMM d, yyyy'
                    onChange={(date) => {
                      this.handleDateChange(date, 'stockIssuedDate');
                    }}
                  />
                  {this.state.inductionBy.stockIssuedDate !== '' && (
                    <h2 className='mt-10 mb-0'>
                      <FormLabel
                        component='legend'
                        className='text-label'
                        style={{ fontSize: 18, color: '#000000' }}
                      >
                        Issued By - {this.state.inductionBy.stockIssuedDate}
                      </FormLabel>
                    </h2>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginLeft: 0, marginTop: 30 }}>
          <Grid item xs={3}>
            <h2 className='mt-0 mb-0'>
              <FormLabel component='legend' className='text-label'>
                ID card
              </FormLabel>
            </h2>
          </Grid>
          <Grid item xs={3} style={{ padding: 0 }}>
            <Grid item xs={6}>
              <RadioGroup
                row
                aria-label='position'
                name='position'
                defaultValue='top'
                value={this.state.induction.idCardIssuedDate}
                onChange={(e) =>
                  this.handleInductionOption(e, 'idCardIssuedDate')
                }
              >
                <FormControlLabel
                  value={1}
                  control={<Radio color='primary' />}
                  label='Yes'
                />
                <FormControlLabel
                  disabled={
                    this.state.disableinduction.idCardIssuedDate === 1
                      ? true
                      : false
                  }
                  value={0}
                  control={<Radio color='primary' />}
                  label='No'
                />
              </RadioGroup>
              {this.state.induction.idCardIssuedDate === 1 && (
                <>
                  <DatePicker
                    value={data.idCardIssuedDate}
                    disabled={
                      this.state.inductionBy.idCardIssuedDate !== ''
                        ? true
                        : false
                    }
                    ranges={[]}
                    dateFormat='MMMM d, yyyy'
                    onChange={(date) => {
                      this.handleDateChange(date, 'idCardIssuedDate');
                    }}
                  />
                  {this.state.inductionBy.idCardIssuedDate !== '' && (
                    <h2 className='mt-10 mb-0'>
                      <FormLabel
                        component='legend'
                        className='text-label'
                        style={{ fontSize: 18, color: '#000000' }}
                      >
                        Issued By - {this.state.inductionBy.idCardIssuedDate}
                      </FormLabel>
                    </h2>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            {data.phleboId !== '' ? (
              <Button
                style={{ backgroundColor: '#00C9AA', marginRight: 10 }}
                onClick={(e) => this.validateData()}
                variant='contained'
                color='primary'
              >
                Save
              </Button>
            ) : null}
            <Button
              style={{
                borderColor: '#00C9AA',
                color: '#00C9AA',
                marginRight: 10,
              }}
              onClick={() => {
                this.redirectToList();
              }}
              variant='outlined'
              color='primary'
            >
              Back
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  renderFormFields = (data) => {
    const { isError } = this.state;
    const { lgps } = this.props;
    return (
      <React.Fragment>
        <Tabs
          value={this.state.tab}
          TabIndicatorProps={{
            style: {
              backgroundColor: 'white',
            },
          }}
          textColor='primary'
          onChange={(e, tab) => {
            this.setState({ tab });
          }}
        >
          <Tab label='Basic' value={1} />
          <Tab label='Study' value={2} />
          <Tab label='Family' value={3} />
          <Tab label='Salary' value={4} />
          <Tab label='Induction' value={7} />
        </Tabs>
        <TabPanel
          value={this.state.tab}
          index={1}
          style={{ border: '2px solid #00c9aa' }}
        >
          {this.renderFormBasic(data)}
        </TabPanel>
        <TabPanel
          value={this.state.tab}
          index={2}
          style={{ border: '2px solid #00c9aa', marginTop: 5 }}
        >
          {this.renderFormStudy(data)}
        </TabPanel>
        <TabPanel
          value={this.state.tab}
          index={3}
          style={{ border: '2px solid #00c9aa', marginTop: 5 }}
        >
          {this.renderFormFamily(data)}
        </TabPanel>
        <TabPanel
          value={this.state.tab}
          index={4}
          style={{ border: '2px solid #00c9aa', marginTop: 5 }}
        >
          {this.renderFormSalary(data)}
        </TabPanel>
        <TabPanel
          value={this.state.tab}
          index={5}
          style={{ border: '2px solid #00c9aa', marginTop: 5 }}
        >
          {this.renderFormAccount(data)}
        </TabPanel>
        <TabPanel
          value={this.state.tab}
          index={6}
          style={{ border: '2px solid #00c9aa', marginTop: 5 }}
        >
          {this.renderFormPreferences(data)}
        </TabPanel>
        <TabPanel
          value={this.state.tab}
          index={7}
          style={{ border: '2px solid #00c9aa', marginTop: 5 }}
        >
          {this.renderFormInduction(data)}
        </TabPanel>
      </React.Fragment>
    );
  };

  render() {
    const { isLoading, plebos } = this.props;
    const { data } = this.state;
    const { isView } = this.props;
    return (
      <>
        {isView ? (

          <div className='mr-20 create-form'>
            {isLoading && <ActionLoader />}
            <Button
              style={{
                borderColor: '#00C9AA',
                color: '#00C9AA',
                marginRight: 10,
              }}
              onClick={() => {
                this.redirectToList();
              }}
              variant='outlined'
              color='primary'
            >
              Back
            </Button>
            <h3>Edit Phlebo - {data.employeeId}</h3>

            {this.renderFormFields(data)}
          </div>
        ) : (
          <DocContainer>
            <div className='mr-20 create-form'>
              {isLoading && <ActionLoader />}
              <Button
                style={{
                  borderColor: '#00C9AA',
                  color: '#00C9AA',
                  marginRight: 10,
                }}
                onClick={() => {
                  this.redirectToList();
                }}
                variant='outlined'
                color='primary'
              >
                Back
              </Button>
              <h3>Add Phlebo</h3>

              {this.renderFormFields(data)}
            </div>
          </DocContainer>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ plebos, constants, lgp }) => {
  return {
    plebos: plebos.plebos,
    isLoading: plebos.isSubmitting,
    errorMessage: plebos.pleboMobileError,
    cities: constants.cities,
    states: constants.states,
    salaries: constants.salaries,
  };
};

export default withRouter(connect(mapStateToProps)(PleboAdd));
