/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./clinics.css";

import DocContainer from "./../../containers/DocContainer";
import ActionLoader from "../../components/ActionLoader";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  Paper,
  Button,
  Grid,
  TextField,
  IconButton,
  InputLabel,
  Typography,
  Switch,
  Avatar,
  Tabs,
  Tab,
  Box,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

import {
  GET_LABS,
  GET_LPGS,
  ADD_LGPS,
  DELETE_LGPS,
  GET_CONTRACT,
  ADD_CONTRACT,
  UPLOAD_IMAGE,
} from "./../../constants/actionConstants";

import moment from "moment";
import {
  Edit,
  Clear,
  Visibility,
  ArrowBackOutlined,
  AddCircleOutline,
  RemoveCircleOutline,
} from "@material-ui/icons";

import MER from "./MER";


const selectedClass = { color: "#00C9AA", fontWeight: "bold" };

const SHAREDMODELDATA = {
  modelType: "Shared",
  chargeType: "Direct",
  firstVisitCharge: "",
  secondVisitCharge: "",
  handlingCharge: "",
  consumablesApplicable: true,
  labChargeApplicable: true,
  labId: "",
  //slots: []
};

const rangedAmontObj = { start: "", end: "", charge: "" };

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

class LGP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      searchText: "",
      isError: false,
      isOpen: false,
      tab: 1,
      data: {
        name: "",
        primaryContactPersonName: "",
        primaryContactPersonMobileNumber: "",
        primaryContactAlternatePersonMobileNumber: "",
        emailAddress: "",
        address: "",
        gstin: "",
        cin: "",
        username: "",
        password: "",
        imageUrl: "",
        isActive: false,
        apiKey: "",
        apiSecret: "",
        isAutoAssignment: false,
      },
      sharedModelData: { ...SHAREDMODELDATA },
      ppmcData: {
        selectedValue: -1,
      },
      contractType: "sharedModel",

      contractDetailsUpdated: false,
      openRangedModal: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.getPlebos();
    this.getLabs();
  }
  componentWillReceiveProps(newProps) {
    if (
      !this.state.contractDetailsUpdated &&
      newProps.sharedModelData &&
      newProps.sharedModelData["chargeContractId"] !=
        this.state.sharedModelData["chargeContractId"]
    ) {
      this.setState(
        {
          sharedModelData: newProps.sharedModelData,
          contractDetailsUpdated: true,
        },
        () => {}
      );
    }
  }

  getLabs = () => {
    const { dispatch } = this.props;
    let data = {
      count: 10,
      offset: this.state.offset,
    };

    dispatch({
      type: GET_LABS,
      data,
    });
  };
  resetPopup = () => {
    let { data, sharedModelData, ppmcData } = this.state;
    data = {
      name: "",
      primaryContactPersonName: "",
      primaryContactPersonMobileNumber: "",
      primaryContactAlternatePersonMobileNumber: "",
      emailAddress: "",
      address: "",
      gstin: "",
      cin: "",
      username: "",
      password: "",
      imageUrl: "",
      isActive: false,
      isAutoAssignment: false,
    };

    sharedModelData = { ...SHAREDMODELDATA };
    ppmcData = {
      selectedValue: -1,
    };
    this.setState({ data, sharedModelData, ppmcData, isError: false });
  };

  redirectToDetail = (plebo) => {
    const { match, history } = this.props;
    this.setState({
      offset: 0,
      data: {
        ...plebo,
        isEdit: true,
      },
    });
    history.push(`/lgp/${plebo.lgpId}`, { state: plebo });
  };

  redirectToList = () => {
    const { history } = this.props;
    this.setState({ offset: 0, tab: 1 }, () => {
      this.getPlebos();
    });
    this.resetPopup();
    history.push(`/lgp`);
  };

  getPlebos = (id) => {
    const { dispatch } = this.props;
    let data = {
      count: 10,
      offset: this.state.offset,
    };
    if (id) {
      data = { phleboId: id };
    }
    if (this.state.searchText && this.state.searchText.length > 0) {
      data["text"] = this.state.searchText;
    }

    dispatch({
      type: GET_LPGS,
      data,
    });
  };

  getContract = () => {
    const { dispatch, match } = this.props;
    let data = {
      count: 10,
      offset: this.state.offset,
      lgpId: match.params.id,
    };
    dispatch({
      type: GET_CONTRACT,
      data,
    });
  };

  addPlebos = (dataSet) => {
    const { dispatch, match } = this.props;
    dispatch({
      type: ADD_LGPS,
      data: dataSet ? dataSet : this.state.data,
      onSuccess: () => {
        this.setState({ isOpen: false });
        this.getPlebos();
        if (!match.params.id) {
          this.resetPopup();
        }
      },
    });
  };

  addContract = () => {
    const { dispatch, match } = this.props;
    dispatch({
      type: ADD_CONTRACT,
      data: this.state.sharedModelData,
      onSuccess: () => {
        this.setState({ contractDetailsUpdated: false }, () => {
          this.getContract();
        });
      },
    });
  };

  deletePlebos = (id) => {
    const { dispatch } = this.props;
    dispatch({
      type: DELETE_LGPS,
      data: { lgpId: id },
      onSuccess: () => {
        this.setState({ isDeleteOpen: false, deleteObj: null });
        this.getPlebos();
      },
    });
  };

  uploadImage = (file) => {
    const { dispatch } = this.props;
    dispatch({
      type: UPLOAD_IMAGE,
      file,
      params: { type: 14 },
      onSuccess: (res) => {
        if (res && res.imageUrl) {
          const { data } = this.state;
          data.imageUrl = res.imageUrl;
          this.setState({ data });
        }
      },
    });
  };

  handleFormChange = (e, stateName, key) => {
    let data = this.state[stateName];
    data[key] = e.target.value;
    this.setState({ data });
  };
  handleRangedAmountChange = (e, key, index) => {
    let data = this.state.sharedModelData.slots;

    data[index][key] = e.target.value;
    let sharedModelData = this.state.sharedModelData;
    sharedModelData.slots = [...data];
    this.setState({ sharedModelData });
  };

  validateData = () => {
    const { data, tab, sharedModelData } = this.state;
    if (
      tab == 1 &&
      (data.name === "" ||
        data.primaryContactPersonName === "" ||
        data.primaryContactPersonMobileNumber === "" ||
        data.username === "" ||
        data.password === "" ||
        data.emailAddress === "" ||
        data.address === "")
    ) {
      this.setState({ isError: true });
    } else if (
      tab == 2 &&
      (sharedModelData.secondVisitCharge === "" ||
        sharedModelData.handlingCharge === "" ||
        (sharedModelData.labChargeApplicable && sharedModelData.labId == "") ||
        (sharedModelData.chargeType === "Direct" &&
          sharedModelData.firstVisitCharge === "") ||
        (sharedModelData.chargeType === "VolumeBased" &&
          this.checkSlotsArray(sharedModelData.slots)))
    ) {
      this.setState({ isError: true });
    } else {
      if (tab == 2) {
        this.addContract();
      } else {
        this.addPlebos();
      }
      this.setState({ isError: false });
    }
  };
  checkSlotsArray = (slotsArr) => {
    return slotsArr.find((obj, index) => {
      if (!Object.values(obj).every((y) => y !== "")) {
        this.setState({ isError: true, openRangedModal: true });
        return true;
      } else {
        return false;
      }
    });
  };
  renderPagination = (isKYC) => {
    return (
      <div className="mt-30">
        <Grid container spacing={5}>
          <Grid item xs={9} />
          <Grid item xs={3}>
            <Button
              style={{ marginRight: "20px" }}
              size="medium"
              color="primary"
              variant="outlined"
              disabled={this.state.offset === 0}
              onClick={() => {
                this.setState({ offset: this.state.offset - 10 }, () => {
                  this.getPlebos();
                });
              }}
            >
              {"< Previous"}
            </Button>
            <Button
              size="medium"
              color="primary"
              variant="outlined"
              disabled={!this.props.hasMore}
              onClick={() => {
                this.setState({ offset: this.state.offset + 10 }, () => {
                  this.getPlebos();
                });
              }}
            >
              {"Next >"}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  };

  renderFormFields = (data) => {
    const { isError } = this.state;
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Name"
              value={data.name}
              onChange={(e) => this.handleFormChange(e, "data", "name")}
              error={isError && data.name === ""}
              helperText={
                isError && data.name === "" ? "Please enter a name" : " "
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Primary Person Name"
              value={data.primaryContactPersonName}
              onChange={(e) =>
                this.handleFormChange(e, "data", "primaryContactPersonName")
              }
              error={isError && data.primaryContactPersonName === ""}
              helperText={
                isError && data.primaryContactPersonName === ""
                  ? "Please enter primary contact person name"
                  : " "
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Phone number"
              value={data.primaryContactPersonMobileNumber}
              error={isError && data.primaryContactPersonMobileNumber === ""}
              helperText={
                isError && data.primaryContactPersonMobileNumber === ""
                  ? "Please enter phone no."
                  : " "
              }
              onChange={(e) =>
                this.handleFormChange(
                  e,
                  "data",
                  "primaryContactPersonMobileNumber"
                )
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Alternate Number"
              value={data.primaryContactAlternatePersonMobileNumber}
              onChange={(e) =>
                this.handleFormChange(
                  e,
                  "data",
                  "primaryContactAlternatePersonMobileNumber"
                )
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={11}>
            <TextField
              fullWidth
              value={data.address}
              label="Address"
              onChange={(e) => this.handleFormChange(e, "data", "address")}
              error={isError && data.address === ""}
              helperText={
                isError && data.address === "" ? "Please enter address" : " "
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              value={data.username}
              label="Username"
              onChange={(e) => this.handleFormChange(e, "data", "username")}
              error={isError && data.username === ""}
              helperText={
                isError && data.username === "" ? "Please enter username" : " "
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="password"
              label="Password"
              value={data.password}
              onChange={(e) => this.handleFormChange(e, "data", "password")}
              error={isError && data.password === ""}
              helperText={
                isError && data.password === "" ? "Please enter password" : " "
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              value={data.emailAddress}
              label="Email ID"
              onChange={(e) => this.handleFormChange(e, "data", "emailAddress")}
              error={isError && data.emailAddress === ""}
              helperText={
                isError && data.emailAddress === ""
                  ? "Please enter address"
                  : " "
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="GSTIN"
              value={data.gstin}
              onChange={(e) => this.handleFormChange(e, "data", "gstin")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="CIN"
              value={data.cin}
              onChange={(e) => this.handleFormChange(e, "data", "cin")}
            />
          </Grid>
          <Grid item xs={6}>
            <label>Is Active?</label>
            <Switch
              color="primary"
              name="isActive"
              size="small"
              checked={data.isActive}
              onChange={(e) => {
                const { data } = this.state;
                data.isActive = e.target.checked;
                this.setState({ data });
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="API Key"
              value={data.apiKey}
              onChange={(e) => this.handleFormChange(e, "data", "apiKey")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="API Secret"
              value={data.apiSecret}
              onChange={(e) => this.handleFormChange(e, "data", "apiSecret")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <label>Is Auto Assignment?</label>
            <Switch
              color="primary"
              name="isAutoAssignment"
              size="small"
              checked={data.isAutoAssignment}
              onChange={(e) => {
                const { data } = this.state;
                data.isAutoAssignment = e.target.checked;
                this.setState({ data });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <input
              type="file"
              className="inputFileUpload"
              onChange={(e) => {
                this.uploadImage(e.target.files);
              }}
              ref={(ref) => {
                this.input = ref;
              }}
            />
            {data.imageUrl && data.imageUrl.length > 0 && (
              <a
                href={data.imageUrl}
                style={{ color: "blue", fontSize: "10px" }}
              >
                <Avatar size="small" src={data.imageUrl} alt="icon" />
              </a>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  renderContractFormFields = (data) => {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <RadioGroup
              row
              aria-label="contract"
              name="contract"
              value={this.state.contractType}
              onChange={(e) => this.setState({ contractType: e.target.value })}
            >
              <FormControlLabel
                value="sharedModel"
                control={<Radio color="#00C9AA" />}
                label="Shared Model"
              />
              <FormControlLabel
                value="ppmc"
                control={<Radio color="#00C9AA" />}
                label="PPMC"
              />
            </RadioGroup>
          </Grid>
        </Grid>

        {this.state.contractType === "sharedModel"
          ? this.renderSharedModelView(data.sharedModelData)
          : this.renderPPMCView(data.ppmcData)}
      </React.Fragment>
    );
  };
  renderSharedModelView = (sharedModelData) => {
    let { isError } = this.state;
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={6} className="toggle-center">
            <label style={selectedClass}>Service Charges</label>

            <span className="float-right">
              <label style={{ ...selectedClass }}>Range</label>
              <Switch
                color="primary"
                name="consumablesApplicable"
                size="small"
                checked={sharedModelData.chargeType === "VolumeBased"}
                onChange={(e) => {
                  sharedModelData["chargeType"] = e.target.checked
                    ? "VolumeBased"
                    : "Direct";
                  if (e.target.checked) {
                    sharedModelData["slots"] = [{ ...rangedAmontObj }];

                    delete sharedModelData["firstVisitCharge"];

                    this.setState({ sharedModelData, openRangedModal: true });
                  } else {
                    sharedModelData["firstVisitCharge"] = "";
                    delete sharedModelData["slots"];
                    this.setState({ sharedModelData });
                  }
                }}
              />
            </span>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {sharedModelData.chargeType == "Direct" ? (
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="First Visit"
                value={sharedModelData.firstVisitCharge}
                onChange={(e) =>
                  this.handleFormChange(
                    e,
                    "sharedModelData",
                    "firstVisitCharge"
                  )
                }
                error={isError && sharedModelData.firstVisitCharge === ""}
                helperText={
                  isError && sharedModelData.firstVisitCharge === ""
                    ? "Please enter firt visit charges"
                    : " "
                }
              />
            </Grid>
          ) : (
            <Grid item xs={6}>
              <label className="label-first-visit">First Visit</label>
              <IconButton
                className="label-first-visit float-right"
                edge="end"
                color="inherit"
                size="small"
                onClick={() => {
                  this.setState({ openRangedModal: true });
                }}
              >
                <Edit />
              </IconButton>
              <Grid container>
                <TableContainer component={Paper} className="range-box">
                  <Table size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Start</TableCell>
                        <TableCell>End</TableCell>
                        <TableCell>Charge</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sharedModelData.slots.map((obj, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{obj.start}</TableCell>
                            <TableCell>{obj.end}</TableCell>
                            <TableCell>{obj.charge}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          )}

          <Grid item xs={6} className="toggle-center">
            <TextField
              fullWidth
              label="Second Visit"
              value={sharedModelData.secondVisitCharge}
              onChange={(e) =>
                this.handleFormChange(e, "sharedModelData", "secondVisitCharge")
              }
              error={isError && sharedModelData.secondVisitCharge === ""}
              helperText={
                isError && sharedModelData.secondVisitCharge === ""
                  ? "Please enter second visit charges"
                  : " "
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6} className="toggle-center">
            <label>Consumables?</label>
            <Switch
              color="primary"
              name="consumablesApplicable"
              size="small"
              checked={sharedModelData.consumablesApplicable}
              onChange={(e) => {
                const { sharedModelData } = this.state;
                sharedModelData.consumablesApplicable = e.target.checked;
                this.setState({ sharedModelData });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Handling Charges"
              value={sharedModelData.handlingCharge}
              onChange={(e) =>
                this.handleFormChange(e, "sharedModelData", "handlingCharge")
              }
              error={isError && sharedModelData.handlingCharge === ""}
              helperText={
                isError && sharedModelData.handlingCharge === ""
                  ? "Please enter handling charges"
                  : " "
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6} className="toggle-center">
            <label>Lab Charges?</label>
            <Switch
              color="primary"
              name="labChargeApplicable"
              size="small"
              checked={sharedModelData.labChargeApplicable}
              onChange={(e) => {
                let { sharedModelData } = this.state;
                if (!e.target.checked) {
                  sharedModelData.labId = "";
                  //	delete sharedModelData['labId']
                }
                // else{
                // 	sharedModelData['labId']=''
                // }
                sharedModelData.labChargeApplicable = e.target.checked;
                this.setState({ sharedModelData });
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl
              fullWidth
              error={isError && sharedModelData.labId === ""}
              style={{
                visibility: sharedModelData.labChargeApplicable ? "" : "hidden",
              }}
            >
              <InputLabel>Lab Name</InputLabel>
              <Select
                value={sharedModelData.labId}
                onChange={(e) =>
                  this.handleFormChange(e, "sharedModelData", "labId")
                }
              >
                {(this.props.labs || []).map((obj, index) => (
                  <MenuItem key={`lab-${index}`} value={obj.labId}>
                    {" "}
                    {obj.name}{" "}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText hidden={!isError && sharedModelData.labId != ""}>
                Please select lab name
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </>
    );
  };
  renderRangedAmountPopup = () => {
    const { openRangedModal, data } = this.state;
    return (
      <Dialog
        disableBackdropClick
        open={openRangedModal}
        onClose={() => {
          this.setState({ openRangedModal: false });
        }}
      >
        <DialogTitle id="clinic-popup">{"Range First Visit"}</DialogTitle>
        <DialogContent style={{ width: "600px", height: "180px" }}>
          {this.renderRangedAmountView()}
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            variant="contained"
            color="primary"
            onClick={() => {
              this.setState({ openRangedModal: false });
            }}
          >
            Confirm
          </Button>
          <Button
            style={{ borderColor: "#00C9AA", color: "#00C9AA" }}
            onClick={() => {
              this.setState({ openRangedModal: false });
            }}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  addRangeRow = (index, add) => {
    let arr = this.state.sharedModelData.slots;
    if (add) {
      arr = [...arr, { ...rangedAmontObj }];
    } else {
      arr.splice(index, 1);
    }
    let sharedModelData = this.state.sharedModelData;
    sharedModelData.slots = [...arr];
    this.setState({
      sharedModelData,
    });
  };

  renderRangedAmountView = () => {
    const { isError } = this.state;
    const slots = this.state.sharedModelData.slots;
    return (
      <>
        <Grid container spacing={12} className="range-list">
          {slots.map((obj, index) => {
            return (
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="Start"
                    value={obj.start}
                    onChange={(e) =>
                      this.handleRangedAmountChange(e, "start", index)
                    }
                    error={isError && obj.start === ""}
                    helperText={
                      isError && obj.start === ""
                        ? "Please enter start range"
                        : " "
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="End"
                    value={obj.end}
                    onChange={(e) =>
                      this.handleRangedAmountChange(e, "end", index)
                    }
                    error={isError && obj.end === ""}
                    helperText={
                      isError && obj.end === "" ? "Please enter end range" : " "
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="Charge"
                    value={obj.charge}
                    onChange={(e) =>
                      this.handleRangedAmountChange(e, "charge", index)
                    }
                    error={isError && obj.charge === ""}
                    helperText={
                      isError && obj.charge === "" ? "Please enter charge" : " "
                    }
                  />
                </Grid>
                <Grid item xs={2} style={{ alignSelf: "center" }}>
                  <IconButton
                    edge="end"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      this.addRangeRow(index, slots.length - 1 == index);
                    }}
                  >
                    {slots.length - 1 == index ? (
                      <AddCircleOutline />
                    ) : (
                      <RemoveCircleOutline />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </>
    );
  };
  renderPPMCView = (data) => {
    const { isError } = this.state;
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl fullWidth error={isError}>
              <InputLabel>Package</InputLabel>
              <Select
                value={data.selectedValue}
                onChange={(e) =>
                  this.handleFormChange(e, "ppmcData", "selectedValue")
                }
              >
                <MenuItem value={-1}>Select Package</MenuItem>

                <MenuItem value={500}>Package 1</MenuItem>
                <MenuItem value={750}>Package 2</MenuItem>
                <MenuItem value={1000}>Package 3</MenuItem>
              </Select>
              <FormHelperText hidden={!isError}>Error</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </>
    );
  };

  renderPopup = () => {
    const { isOpen, data } = this.state;
    return (
      <Dialog
        disableBackdropClick
        open={isOpen}
        onClose={() => {
          this.setState({ isOpen: false });
        }}
      >
        <DialogTitle id="clinic-popup">
          {data.isEdit ? "Edit LGP" : "Create New LGP"}
        </DialogTitle>
        <DialogContent style={{ width: "600px", height: "400px" }}>
          {this.renderFormFields(data)}
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            onClick={this.validateData}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button
            style={{ borderColor: "#00C9AA", color: "#00C9AA" }}
            onClick={() => {
              this.setState({ isOpen: false });
              this.resetPopup();
            }}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderDeletePopup = () => {
    const { isDeleteOpen, data, deleteObj } = this.state;
    return (
      <Dialog
        disableBackdropClick
        open={isDeleteOpen}
        onClose={() => {
          this.setState({ isDeleteOpen: false });
        }}
      >
        <DialogTitle id="clinic-popup">Confirm Delete</DialogTitle>
        <DialogContent>
          {" "}
          Are you sure you want to delete {deleteObj.name} ?{" "}
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            onClick={() => {
              this.deletePlebos(deleteObj.lgpId);
            }}
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
          <Button
            style={{ borderColor: "#00C9AA", color: "#00C9AA" }}
            onClick={() => {
              this.setState({ isDeleteOpen: false, deleteObj: null });
            }}
            variant="outlined"
            color="primary"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderBasicDetails = (isKYC) => {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            {this.state.tab == 1
              ? this.renderFormFields(this.state.data)
              : this.renderContractFormFields(this.state)}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={1}>
            <Button
              style={{ backgroundColor: "#00C9AA" }}
              onClick={this.validateData}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  onChangeTab = (tab) => {
    this.setState({ tab });
    if (tab == 2) {
      if (this.props.match.params.id) {
        let sharedModelData = { ...SHAREDMODELDATA };
        sharedModelData["lgpId"] = this.props.match.params.id;
        this.setState(
          {
            sharedModelData: { ...sharedModelData },
            contractDetailsUpdated: false,
          },
        );
        this.getContract();
      }
    }
  };

  render() {
    const { cities, states, plebos, isLoading, match } = this.props;
    const {
      isDeleteOpen,
      isOpen,
      tab,
      openRangedModal,
      kycDocumentType,
    } = this.state;
    let isKYC = false;
    if (match.params && match.params.id) {
      isKYC = true;
    }

    return (
      <DocContainer>
        <div className="mr-20">
          {isLoading && <ActionLoader />}
          {!isKYC ? (
            <React.Fragment>
              <Grid item xs={12}>
                  <h2 className="mt-0">LGP</h2>
                </Grid>
              <Grid container spacing={3}>
                
                <Grid item xs={4}>
                    <span className="fa fa-search" />
                    <TextField
                      size="small"
                      variant="outlined"
                      placeholder="Search…"
                      value={this.state.searchText}
                      onChange={(e) => {
                        this.setState({ searchText: e.target.value });
                      }}
                      onKeyPress={(e) => {
                        if (e.which == 13 || e.keyCode == 13) {
                          this.getPlebos();
                        }
                      }}
                    />
                    {this.state.searchText.length > 0 && (
                      <span
                        className="cancel-icon"
                        onClick={() => {
                          this.setState({ searchText: "" });
                          this.getPlebos();
                        }}
                      >
                        {" "}
                        x{" "}
                      </span>
                    )}
                </Grid>
                <Grid item xs={4}>
                <Button
                    style={{ backgroundColor: "#0D8DA3" }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.setState({ isOpen: !this.state.isOpen });
                    }}
                  >
                    Add LGP
                  </Button>
                 
                </Grid>
              </Grid>

              <TableContainer component={Paper} className="mt-30">
                <Table size="small" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Image</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Person & Phone number</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Is Active?</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(plebos || []).length <= 0 && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {" "}
                          No LGP found.{" "}
                        </TableCell>
                      </TableRow>
                    )}
                    {(plebos || []).map((plebo) => (
                      <TableRow key={plebo.lgpId}>
                        <TableCell>
                          <a href={plebo.imageUrl} download>
                            <Avatar
                              alt="img"
                              size="small"
                              src={plebo.imageUrl}
                            />
                          </a>
                        </TableCell>
                        <TableCell>{plebo.name}</TableCell>
                        <TableCell>
                          <div>{plebo.primaryContactPersonName}</div>
                          <div>{plebo.primaryContactPersonMobileNumber}</div>
                          <div>
                            {plebo.primaryContactAlternatePersonMobileNumber}
                          </div>
                        </TableCell>
                        <TableCell>{plebo.emailAddress}</TableCell>
                        <TableCell>
                          {moment(plebo.createdTime).format("Do MMM, YYYY")}
                        </TableCell>
                        <TableCell>
                          <Switch
                            size="small"
                            color="primary"
                            name="isActive"
                            checked={plebo.isActive}
                            onChange={(e) => {
                              const data = { ...plebo };
                              data.isActive = e.target.checked;
                              data.isEdit = true;
                              this.addPlebos(data);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <div style={{ width: "75px" }}>
                            <IconButton
                              edge="end"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                this.redirectToDetail(plebo);
                              }}
                            >
                              <Visibility />
                            </IconButton>
                            <IconButton
                              edge="end"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                this.setState({
                                  isOpen: true,
                                  data: { ...plebo, isEdit: true },
                                });
                              }}
                            >
                              <Edit />
                            </IconButton>
                            <IconButton
                              edge="end"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                this.setState({
                                  isDeleteOpen: true,
                                  deleteObj: plebo,
                                });
                              }}
                            >
                              <Clear />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {this.renderPagination()}

              {isOpen && this.renderPopup()}

              {isDeleteOpen && this.renderDeletePopup()}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid container spacing={3}>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => {
                    this.redirectToList();
                  }}
                >
                  <ArrowBackOutlined />
                </IconButton>
                <Grid item xs={4}>
                  {" "}
                  <h2 className="mt-0">LGP DETAILS</h2>{" "}
                </Grid>
              </Grid>
              <Tabs
                value={this.state.tab}
                onChange={(e, tab) => this.onChangeTab(tab)}
              >
                <Tab label="Basic" value={1} />
                <Tab label="Contract" value={2} />
                <Tab label="MER" value={3} />
              </Tabs>
              {tab === 3 ? (
                <TabPanel value={this.state.tab} index={this.state.tab}>
                  {tab === 3 && <MER lgpId={this.props.match.params.id} />}
                </TabPanel>
              ) : (
                <TabPanel value={this.state.tab} index={this.state.tab}>
                  {this.renderBasicDetails(isKYC)}
                </TabPanel>
              )}
              {tab === 2 && openRangedModal && this.renderRangedAmountPopup()}
            </React.Fragment>
          )}
        </div>
      </DocContainer>
    );
  }
}

const mapStateToProps = ({ lgp, labs }) => {
  return {
    isLoading: lgp.isSubmitting,
    plebos: lgp.plebos,
    hasMore: lgp.hasMore,
    sharedModelData: lgp.contract,

    labs: labs.labs,
  };
};

export default withRouter(connect(mapStateToProps)(LGP));
