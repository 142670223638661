import React from "react";
import { connect } from "react-redux";
import "./index.scss";

import { SIGN_IN, FORGOT_PASSWORD } from "Constants/actionConstants";
import { getToken } from "Utils/storage";
import ActionLoader from "../../components/ActionLoader";
import {
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";

import LOGO from "./thinkhealthcarelogo.svg";

import PersonIcon from "@material-ui/icons/Person";
import LockTwoToneIcon from "@material-ui/icons/LockTwoTone";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

const SIGN_IN_FORM = "SIGN_IN_FORM";
const TYPES = [
  { id: 1, name: "Super Admin", value: 1 },
  { id: 2, name: "Partner", value: 2 },
  { id: 4, name: "Lab", value: 4 },
  { id: 5, name: "Doctor", value: 5 },
];

class Signin extends React.Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      userType: 1,
      formType: SIGN_IN_FORM,
      showPassword: false,
      isMobile: false,
    };
  }

  setShowPassword = (status) => {
    this.setState({
      ...this.state,
      showPassword: status,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  signin = (e) => {
    const { dispatch } = this.props;
    const { username, password, userType } = this.state;
    dispatch({
      type: SIGN_IN,
      data: { username, password, userType },
    });
  };

  selectCompanyType = (userType) => {
    this.setState({
      userType,
    });
  };
  UNSAFE_componentWillMount() {
    const token = getToken();
    if (!token) {
      return;
    } else {
    }
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };

  resize() {
    this.setState({ isMobile: window.innerWidth <= 760 });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  forgotPassword = (e) => {
    const { dispatch } = this.props;
    const { username, userType } = this.state;
    dispatch({
      type: FORGOT_PASSWORD,
      data: {
        username,
        userType,
      },
    });
  };
  formSubmit = (e) => {
    e.preventDefault();
    this.signin(e);
  };
  render() {
    const { isSubmitting } = this.props;
    const {  isMobile } = this.state;

    return (
      <div className="login-panel">
        {isSubmitting && <ActionLoader />}
        <div className="app-wrapper min-vh-100 bg-white">
          <div className="app-main min-vh-100">
            <div className="app-content p-0">
              <div className="app-inner-content-layout--main">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div
                    className="bg-composed-wrapper--content"
                    style={{ width: "100%", padding: isMobile?10:0 }}
                  >
                    <Grid
                      container
                      lg={12}
                      xl={12}
                      spacing={isMobile?3:0}
                      className="min-vh-100"
                    >
                      <Grid
                        item
                        lg={7}
                        xl={6}
                        className="d-flex align-items-center"
                      >
                        <Grid item md={10} lg={8} xl={7} className="mx-auto">
                          <div className="py-4">
                            <div className="text-center">
                              <img
                                style={{ width: 250, height: 120 }}
                                alt="Smart Wallet"
                                src={LOGO}
                              />
                            </div>
                            <div className="text-center">
                              <h1
                                className="display-4 mb-20 font-weight-bold"
                                style={{ marginBottom: 30 }}
                              >
                                Login
                              </h1>
                            </div>
                            <div>
                              <div className="mb-4">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  id="username"
                                  name="username"
                                  label="Username"
                                  onChange={this.onChange}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <PersonIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                              <div className="mb-3">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  id="password"
                                  name="password"
                                  label="Password"
                                  type={
                                    this.state.showPassword
                                      ? "text"
                                      : "password"
                                  }
                                  onChange={this.onChange}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <LockTwoToneIcon />
                                      </InputAdornment>
                                    ),
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {this.state.showPassword ? (
                                          <VisibilityIcon
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              this.setShowPassword(
                                                !this.state.showPassword
                                              )
                                            }
                                          />
                                        ) : (
                                          <VisibilityOffIcon
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              this.setShowPassword(
                                                !this.state.showPassword
                                              )
                                            }
                                          />
                                        )}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <div
                                  style={{
                                    display: "inline-flex",
                                    marginTop: 10,
                                  }}
                                >
                                  {TYPES.map((type, index) => {
                                    return (
                                      <div key={index}>
                                        <FormControlLabel
                                          label={type.name}
                                          control={
                                            <Checkbox
                                              fontSize="small"
                                              checked={
                                                this.state.userType === type.id
                                              }
                                              color="primary"
                                              value={type.id}
                                              onChange={(e) => {
                                                this.setState({
                                                  userType: type.id,
                                                });
                                              }}
                                            />
                                          }
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                              <div className="text-center py-4">
                                <Button
                                  style={{
                                    backgroundColor: "#0D8DA3",
                                    padding: 10,
                                  }}
                                  variant="contained"
                                  color="primary"
                                  className="btn-second font-weight-bold w-50 my-2"
                                  onClick={this.formSubmit}
                                >
                                  {isSubmitting ? "Loading..." : "Login"}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item lg={5} xl={6} className="d-flex">
                        <div className="hero-wrapper w-100 bg-composed-wrapper bg-premium-dark min-vh-lg-100">
                          <div className="flex-grow-1 w-100 d-flex align-items-center">
                            <div className="bg-composed-wrapper--image opacity-5" />
                            <div className="bg-composed-wrapper--bg bg-second opacity-6" />
                            <div className="bg-composed-wrapper--bg bg-deep-blue opacity-2" />
                            <div className="bg-composed-wrapper--content text-center p-5">
                              <div className="text-white px-0 px-lg-2 px-xl-4">
                                <h1 className="display-3 mb-4 font-weight-bold">
                                  Think Health Care
                                </h1>
                                <p className="font-size-lg mb-0 opacity-8">
                                  Feel our relation
                                </p>
                                <div className="divider mx-auto border-1 my-5 border-light opacity-2 rounded w-25" />
                                <div>
                                  <p className="font-size-md mb-0 opacity-8">
                                    Innovative Home Health Solutions – An
                                    organised Healthcare Logistic company and a
                                    preferred logistic partner for Healthcare
                                    Ecommerce Companies, Hospitals and
                                    Diagnostics Centers
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authentication }) => {
  return {
    signinError: authentication.signin.error,
    isSubmitting:
      authentication.signin.isSubmitting ||
      authentication.forgotPassword.isSubmitting,
  };
};

export default connect(mapStateToProps)(Signin);
