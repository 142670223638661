import { call, put } from 'redux-saga/effects';

import { 
    postDataWithToken,
    getDataWithToken,
    deleteDataWithToken,

    ADD_LAB_URL,
    GET_LABS_URL,
    GET_LAB_BY_ID_URL,
    EDIT_LAB_URL,
    DEL_LAB_URL
} from 'Utils/api';

import {
    IS_SUBMITTING,
    INVENTORY_LIST,
    

    TOAST_ERROR,
    TOAST_SUCCESS
} from 'Constants/actionConstants';


export function* getInventory(action) {
    try{
        const url = action.data && action.data.id ? GET_LAB_BY_ID_URL : GET_LABS_URL;

        yield put({ type : IS_SUBMITTING, isSubmitting: true });
        const data = []
        yield put({
            type: INVENTORY_LIST,
            data: data && data.labList ? data.labList : [],
            hasMore: data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false
        })

        yield put({ type : IS_SUBMITTING, isSubmitting: false });

        
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* addInventory(action) {
        try{
            const url = (action.data && action.data.isEdit) ? EDIT_LAB_URL : ADD_LAB_URL;
            yield put({ type : IS_SUBMITTING, isSubmitting: true });
          
            const data = {message:'api to be implemented'}
           
            yield put({ type : IS_SUBMITTING, isSubmitting: false });
    
            yield put({
                type: TOAST_SUCCESS,
                message: data && data.message?data.message : "Data updated successfully!"
            })
            
            if (action.onSuccess) {
                action.onSuccess(data);
            }
        } catch(e) {
            yield put({ type : IS_SUBMITTING, isSubmitting: false })
            yield put({
                type: TOAST_ERROR,
                message: e && e.error?e.error:"Oops! Something went wrong"
            })
    }

}

export function* deleteInventory(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = {message:'api to be implemented'}

        yield put({
            type: TOAST_SUCCESS,
            message: data && data.message?data.message : "Data updated successfully!"
        })
        
        if (action.onSuccess) {
            action.onSuccess(data);
        }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

