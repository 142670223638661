import { call, put } from 'redux-saga/effects';

import { 
    postDataWithToken,
    putDataWithToken,
    getDataWithToken,
    deleteDataWithToken,

    GET_REPORT_DELAY_URL,
    UPDATE_REPORT_STATUS_URL,
    GET_REPORT_PROOF_URL,
    GET_TICKET_REPORT_URL,
    UPDATE_TICKET_URL,
    GET_TICKET_TIME_SLOTS_URL,
    GET_FIRST_SLOT_URL,
    DOWNLOAD_EXECUTION_REPORT_URL,
    GET_CLIENT_UPLOAD_REPORTS_URL,
    GET_DAILY_REPORTS_URL
} from 'Utils/api';

import {
    IS_SUBMITTING,
    REPORT_LIST,
    TICKET_REPORT_LIST,
    REORT_SLOTS_LIST,

    TOAST_ERROR,
    TOAST_SUCCESS
} from 'Constants/actionConstants';


export function* getReportDelay(action) {
    try{
        let url = '';
        if (action.reportType===1 || action.reportType===5) {
            url = GET_REPORT_DELAY_URL;
        } else if (action.reportType===2){
            url = GET_REPORT_PROOF_URL;
        } else if (action.reportType===3){
            url = GET_TICKET_REPORT_URL;
        } else if (action.reportType===4) {
            url = GET_FIRST_SLOT_URL;
        }

        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(getDataWithToken, url, action.data);

        yield put({
            type: (action.reportType===3) ? TICKET_REPORT_LIST : REPORT_LIST,
            data: data && data.list ? data.list : [],
            count: data.count || '',
            hasMore: data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false
        });

        if (action.onSuccess) {
            action.onSuccess(data);
        }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* updateReporStatus(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });
        const data = yield call(putDataWithToken, UPDATE_REPORT_STATUS_URL, action.data);
        yield put({ type : IS_SUBMITTING, isSubmitting: false });

        yield put({
            type: TOAST_SUCCESS,
            message: data && data.message?data.message : "Data updated successfully!"
        })
        
        if (action.onSuccess) {
            action.onSuccess(data);
        }
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* updateTicketStatus(action) {
    try {
        yield put({ type : IS_SUBMITTING, isSubmitting: true });
        const data = yield call(putDataWithToken, UPDATE_TICKET_URL, action.data);
        yield put({ type : IS_SUBMITTING, isSubmitting: false });

        yield put({
            type: TOAST_SUCCESS,
            message: data && data.message?data.message : "Data updated successfully!"
        })
        
        if (action.onSuccess) {
            action.onSuccess(data);
        }
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* getReportTicketSlots(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(getDataWithToken, GET_TICKET_TIME_SLOTS_URL, action.data);

        // yield put({
        //     type: REORT_SLOTS_LIST,
        //     data: data && data.list ? data.list : [],
        //     hasMore: data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false
        // });

        if (action.onSuccess) {
            action.onSuccess(data);
        }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* downloadExecutionReport(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(postDataWithToken, DOWNLOAD_EXECUTION_REPORT_URL, action.data);

        if (action.onSuccess) {
            action.onSuccess(data);
        }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* getAllUploadReports(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const URL = GET_CLIENT_UPLOAD_REPORTS_URL;

        const data = yield call(getDataWithToken, URL, action.data);
        if (action.onSuccess) {
            action.onSuccess(data);
        }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        if (action.onFailure) {
            action.onFailure([]);
          }
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* getDailyReports(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const URL = GET_DAILY_REPORTS_URL;

        const data = yield call(getDataWithToken, URL, action.data);
        if (action.onSuccess) {
            action.onSuccess(data);
        }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        if (action.onFailure) {
            action.onFailure([]);
          }
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

