/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { Component } from "react";
import { connect } from "react-redux";
import "./users.css";

import DocContainer from "./../../containers/DocContainer";
import ActionLoader from "../../components/ActionLoader";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  Button,
  Grid,
  TextField,
  IconButton,
} from "@material-ui/core";

import {
  ADD_DEPT,
  GET_DEPT,
  DELETE_DEPT,
} from "../../constants/actionConstants";
import { Edit, Clear} from "@material-ui/icons";

class Department extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      searchText: "",
      isAddDept: false,
      isDeptError: false,
      isOpen: false,
      deptData: {
        name: "",
        subDepartments: "",
        designations: "",
      },
    };
  }

  UNSAFE_componentWillMount() {
    this.getDept();
  }

  resetPopup = () => {
    let { deptData } = this.state;
    deptData = {
      name: "",
      subDepartments: "",
      designations: "",
    };
    this.setState({
      deptData,
      isDeptError: false,
    });
  };

  addDept = (dataSet) => {
    const { dispatch } = this.props;
    let deptData = JSON.parse(JSON.stringify(this.state.deptData));
    deptData.subDepartments = (deptData.subDepartments || "").split(",");
    deptData.designations = (deptData.designations || "").split(",");

    dispatch({
      type: ADD_DEPT,
      data: deptData,
      onSuccess: () => {
        this.setState({ isAddDept: false });
        this.resetPopup();
        this.getDept();
      },
    });
  };

  getDept = (id) => {
    const { dispatch } = this.props;
    let data = {
      count: 10,
      offset: this.state.offset,
    };
    if (id) {
      data = { roleId: id };
    }
    if (this.state.searchText && this.state.searchText.length > 0) {
      data["text"] = this.state.searchText;
    }

    dispatch({
      type: GET_DEPT,
      data,
    });
  };

  deleteDept = (id) => {
    const { dispatch } = this.props;
    dispatch({
      type: DELETE_DEPT,
      data: { departmentId: id },
      onSuccess: () => {
        this.setState({ isDeleteDeptOpen: false, deleteDeptObj: null });
        this.getDept();
      },
    });
  };

  handleDeptFormChange = (e, key) => {
    const { deptData } = this.state;
    deptData[key] = e.target.value;
    this.setState({ deptData });
  };

  validateDeptData = () => {
    const { deptData } = this.state;
    if (
      deptData.name === "" ||
      deptData.subDepartments === "" ||
      deptData.designations === ""
    ) {
      this.setState({ isDeptError: true });
    } else {
      this.setState({ isDeptError: false });
      this.addDept();
    }
  };

  renderPagination = () => {
    return (
      <div className="mt-30">
        <Grid container spacing={5}>
          <Grid item xs={9} />
          <Grid item xs={3}>
            <Button
              style={{ marginRight: "20px" }}
              size="medium"
              color="primary"
              variant="outlined"
              disabled={this.state.offset === 0}
              onClick={() => {
                this.setState({ offset: this.state.offset - 10 }, () => {
                  this.getDept();
                });
              }}
            >
              {"< Previous"}
            </Button>
            <Button
              size="medium"
              color="primary"
              variant="outlined"
              disabled={!this.props.hasMore}
              onClick={() => {
                this.setState({ offset: this.state.offset + 10 }, () => {
                  this.getDept();
                });
              }}
            >
              {"Next >"}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  };

  renderDeptPopup = () => {
    const { isAddDept, isDeptError, deptData } = this.state;
    return (
      <Dialog
        disableBackdropClick
        open={isAddDept}
        onClose={() => {
          this.setState({ isAddDept: false });
        }}
      >
        <DialogTitle id="clinic-popup">
          {deptData.isEdit ? "Edit Department" : "Create New Department"}
        </DialogTitle>
        <DialogContent style={{ width: "600px", height: "400px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Department"
                value={deptData.name}
                onChange={(e) => this.handleDeptFormChange(e, "name")}
                error={isDeptError && deptData.name === ""}
                helperText={
                  isDeptError && deptData.name === ""
                    ? "Please enter Department"
                    : " "
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Sub Department (comma seperated for multiple)"
                value={deptData.subDepartments}
                onChange={(e) => this.handleDeptFormChange(e, "subDepartments")}
                error={isDeptError && deptData.subDepartments === ""}
                helperText={
                  isDeptError && deptData.subDepartments === ""
                    ? "Please enter Sub Department"
                    : " "
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Designation (comma seperated for multiple)"
                value={deptData.designations}
                onChange={(e) => this.handleDeptFormChange(e, "designations")}
                error={isDeptError && deptData.designations === ""}
                helperText={
                  isDeptError && deptData.designations === ""
                    ? "Please enter Designation"
                    : " "
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            onClick={this.validateDeptData}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button
            style={{ borderColor: "#00C9AA", color: "#00C9AA" }}
            onClick={() => {
              this.setState({ isAddDept: false });
              this.resetPopup();
            }}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderDeptDeletePopup = () => {
    const { isDeleteDeptOpen, data, deleteDeptObj } = this.state;
    return (
      <Dialog
        disableBackdropClick
        open={isDeleteDeptOpen}
        onClose={() => {
          this.setState({ isDeleteObjOpen: false });
        }}
      >
        <DialogTitle id="clinic-popup">Confirm Delete</DialogTitle>
        <DialogContent>
          {" "}
          Are you sure you want to delete {deleteDeptObj.name} ?{" "}
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            onClick={() => {
              this.deleteDept(deleteDeptObj.departmentId);
            }}
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
          <Button
            style={{ borderColor: "#00C9AA", color: "#00C9AA" }}
            onClick={() => {
              this.setState({ isDeleteDeptOpen: false, deleteDeptObj: null });
            }}
            variant="outlined"
            color="primary"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  formatDept = (data) => {
    let deptData = JSON.parse(JSON.stringify(data));
    deptData.designations = (deptData.designations || []).join(",");
    deptData.subDepartments = (deptData.subDepartments || []).join(",");
    return deptData;
  };

  renderDeptTable = () => {
    const { dept } = this.props;
    const {
      isDeleteDeptOpen,
    } = this.state;
    return (
      <>
      <TableContainer component={Paper} className="mt-30">
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Department</TableCell>
              <TableCell>Sub Departments</TableCell>
              <TableCell>Designations</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(dept || []).length <= 0 && (
              <TableRow>
                <TableCell component="th" scope="row">
                  {" "}
                  No Dept found.{" "}
                </TableCell>
              </TableRow>
            )}
            {(dept || []).map((el, index) => (
              <TableRow key={`kyc-${el.departmentId}-${index}`}>
                <TableCell>{el.name}</TableCell>
                <TableCell>
                  <ul>
                    {(el.subDepartments || []).map((sd) => (
                      <li>{sd.trim()}</li>
                    ))}
                  </ul>
                </TableCell>
                <TableCell>
                  <ul>
                    {(el.designations || []).map((ds) => (
                      <li>{ds.trim()}</li>
                    ))}
                  </ul>
                </TableCell>
                <TableCell>
                  <IconButton
                    edge="end"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      this.setState({
                        isAddDept: true,
                        deptData: { ...this.formatDept(el), isEdit: true },
                      });
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => {
                      this.setState({
                        isDeleteDeptOpen: true,
                        deleteDeptObj: el,
                      });
                    }}
                  >
                    <Clear />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isDeleteDeptOpen && this.renderDeptDeletePopup()}
      </>
    );
  };

  renderDeptTab = () => {
    return (
      <React.Fragment>
        <Grid item xs={6}>
            <h2 className="mt-0">Departments</h2>
          </Grid>
        <Grid container spacing={2}>
       
          <Grid item xs={6}>
            <Button
              style={{ backgroundColor: "#0D8DA3" }}
              variant="contained"
              color="primary"
              onClick={() => {
                this.setState({ isAddDept: !this.state.isAddDept });
              }}
            >
              Add Department
            </Button>
          </Grid>
        </Grid>
        {this.state.isAddDept && this.renderDeptPopup()}
        {/* {this.renderPagination()} */}

        {this.renderDeptTable()}

        {this.renderPagination()}
      </React.Fragment>
    );
  };

  render() {
    const { isLoading, match } = this.props;
    const { isDeleteDeptOpen } = this.state;

    return (
      <DocContainer>
        <div className="mr-20">
          {isLoading && <ActionLoader />}
          {isDeleteDeptOpen && this.renderDeptDeletePopup()}
          {this.renderDeptTab()}
        </div>
      </DocContainer>
    );
  }
}

const mapStateToProps = ({ dept }) => {
  return {
    isLoading: dept.isSubmitting,
    hasMore: dept.hasMore,
    dept: dept.dept,
  };
};

export default connect(mapStateToProps)(Department);
