/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { Fragment } from "react";
import classNames from "classnames";
import "./toast.css";

const Toast = ({ message, type, showClose = false, onClose }) => {
  return (
    <Fragment>
      <div
        className={classNames("toast toastClasses transition-bottom", {
          "bg-success": type === "SUCCESS",
          "bg-error": type === "ERROR",
          active: type && message,
        })}
      >
        <div style={{ width: showClose ? "80%" : "" }}>{message}</div>
        {showClose ? (
          <div style={{ width: "20%" }}>
            <span onClick={onClose} style={{ cursor: "pointer" }}>
              X
            </span>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default Toast;
