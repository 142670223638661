/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DocContainer from "./../../containers/DocContainer";
import ActionLoader from "../../components/ActionLoader";
import {
  Grid,
  Button,
  FormLabel,
  Paper,
  InputBase,
  IconButton,
  makeStyles,
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import Select, { components } from "react-select";
import {
  Add,
  ArrowDropDownOutlined,
  Search,
  Refresh,
} from "@material-ui/icons";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { DatePicker } from "rsuite";
import RosterMapping from "../../components/RosterMapping";

import "./index.css";
import "./date.css";

import {
  GET_ALL_CONSTANTS,
  GET_ALL_ZONES,
  GET_ALL_ROSTERS,
  GET_ROSTER_CONFIRM,
  UPDATE_ROSTER_CONFIRM,
  GET_ROSTERS_INSIGHTS,
  GET_ROSTERS_WORK_STATUS,
} from "./../../constants/actionConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "275px",
    marginTop: 18,
    height: "38px",
    border: "none",
  },
  rootPhlebo: {
    display: "flex",
    height: "450px",
    border: "none",
    marginTop: 3,
    boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.1)",
  },
  rootPhlebo1: {
    display: "flex",
    height: "auto",
    border: "none",
    marginTop: 3,
    boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.1)",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  cardRoot: {
    maxWidth: 350,
    maxHeight: 200,
  },
  pos: {
    marginBottom: 12,
  },
  button: {
    "&:hover": {
      backgroundColor: "#3c52b2",
      color: "#3c52b2",
    },
  },
  filter: {
    width: "100%",
  },
}));

const options = [
  { value: 0, label: "All" },
  { value: 1, label: "Working" },
  { value: 2, label: "Off" },
  { value: 10, label: "Unknown" },
];

const RosterPlan = (props) => {
  const { cities, rosters, zones, history } = props;
  const classes = useStyles();

  const [allCities, setAllCities] = useState([]);
  const [rostersCity, setRostersCity] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [allZones, setAllZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState({});

  const [isModalOpen, setModalOpen] = useState(false);
  const [showLeaveOpen, setShowLeaveOpen] = useState(false);
  const [date, setDate] = useState(new Date());
  const [rosterConfirm, setRosterConfirm] = useState({});
  const [allRosterPlans, setAllRosterPlans] = useState([]);
  const [rostersInsights, setRostersInsights] = useState({});
  const [search, setSearch] = useState("");
  const [rostersWorkStatus, setRostersWorkStatus] = useState([]);
  const [fiterStatus, setFiterStatus] = useState(0);

  useEffect(() => {
    setAllRosterPlans(rosters);
  }, [rosters]);

  useEffect(() => {
    if (selectedZone.zoneId) {
      getAllRosters();
    }
  }, [selectedZone]);

  useEffect(() => {
    if (allZones.length > 0) {
      setSelectedZone(allZones[0]);
    }
  }, [allZones]);

  useEffect(() => {
    setAllZones(zones);
  }, [zones]);

  useEffect(() => {
    getAllConstants();
  }, []);

  useEffect(() => {
    setSelectedZone({});
    if (rostersCity) {
      getAllZones(rostersCity);
      getRosterConfirm(rostersCity);
      getRostersInsights(rostersCity);
      getRostersStatus(rostersCity);
    } else {
      setAllZones([]);
    }
  }, [rostersCity]);

  const getAllZones = (city) => {
    const { dispatch } = props;
    const data = {
      city,
      count: 100,
      offset: 0,
      text: "",
    };
    dispatch({
      type: GET_ALL_ZONES,
      data,
    });
  };

  const getRosterConfirm = (city) => {
    setIsLoading(true);
    const { dispatch } = props;
    const data = {
      city,
      ts: date.getTime(),
    };
    dispatch({
      type: GET_ROSTER_CONFIRM,
      data,
      onSuccess: (res) => {
        setIsLoading(false);
        setRosterConfirm(res);
      },
    });
  };

  const getRostersInsights = (city) => {
    const { dispatch } = props;
    const data = {
      city,
      ts: date.getTime(),
    };
    dispatch({
      type: GET_ROSTERS_INSIGHTS,
      data,
      onSuccess: (res) => {
        setRostersInsights(res);
      },
    });
  };

  const getRostersStatus = (city) => {
    const { dispatch } = props;
    const data = {
      city,
      ts: date.getTime(),
      text: search,
    };
    dispatch({
      type: GET_ROSTERS_WORK_STATUS,
      data,
      text: search,
      onSuccess: (res) => {
        setRostersWorkStatus(res.list || []);
      },
    });
  };

  useEffect(() => {
    setAllCities(
      cities.map((city) => {
        return { value: city, label: city };
      })
    );
  }, [cities]);

  useEffect(() => {
    getRosterConfirm(rostersCity);
    getRostersInsights(rostersCity);
    getRostersStatus(rostersCity);
  }, [date]);

  useEffect(() => {
    getRostersStatus(rostersCity);
  }, [search]);

  useEffect(() => {
    if (allCities.length > 0) {
      setRostersCity("Bangalore");
    }
  }, [allCities]);

  const getAllConstants = () => {
    const { dispatch } = props;
    dispatch({
      type: GET_ALL_CONSTANTS,
    });
  };

  const getAllRosters = () => {
    setIsLoading(true);
    const { dispatch } = props;
    const data = {
      count: 100,
      offset: 0,
      text: "",
    };

    if (rostersCity) {
      data.city = rostersCity;
    }

    if (selectedZone.zoneId) {
      data.zoneId = selectedZone.zoneId;
    }

    dispatch({
      type: GET_ALL_ROSTERS,
      data,
    });
    setIsLoading(false);
  };

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      fontSize: 12,
      border: "none",
      boxShadow:
        "0 4px 8px 0 rgb(255 255 255), 0 6px 8px 0 rgb(117 109 109 / 30%)",
    }),
    option: (base) => ({
      ...base,
      fontSize: 12,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#9F9F9F",
    }),
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownOutlined />
      </components.DropdownIndicator>
    );
  };

  const confirmPlan = () => {
    const { dispatch } = props;
    const data = {
      city: rostersCity,
    };
    dispatch({
      type: UPDATE_ROSTER_CONFIRM,
      data,
      onSuccess: (res) => {
        setModalOpen(false);
        getRosterConfirm(rostersCity);
      },
    });
  };

  const renderPopup = () => {
    return (
      <Dialog
        disableBackdropClick
        open={isModalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <DialogTitle id="zone-popup">Confirm Roster Plan</DialogTitle>
        <DialogActions>
          <Button
            style={{
              borderColor: "#D7D7D7",
              color: "#616161",
              borderRadius: "4px",
            }}
            onClick={() => {
              setModalOpen(false);
            }}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => confirmPlan()}
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const goTo = (route, data) => {
    history.push(`/${route}`, { ...data });
  };

  const getStatus = (status) => {
    switch (status) {
      case 1:
        return <span style={{ color: "#3DED97" }}>Working</span>;
      case 2:
        return <span style={{ color: "#f50057" }}>Off</span>;
      case 10:
        return <span style={{ color: "#f50057" }}>Unknown</span>;
      default:
        return "-";
    }
  };

  const getDateTime = (time) => {
    const createdDate = new Date(time);
    const dd = String(createdDate.getDate()).padStart(2, "0");
    const mm = String(createdDate.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = createdDate.getFullYear();
    let hours = createdDate.getHours();
    let minutes = createdDate.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return `${dd}-${mm}-${yyyy} ${hours}:${minutes}${ampm}`;
  };

  return (
    <DocContainer transparent={true}>
      <div className="mr-20 pd-t-20">
        {isLoading && <ActionLoader />}
        {isModalOpen && renderPopup()}
        <React.Fragment>
          <Grid container spacing={1} style={{ paddingTop: 5 }}>
            <Grid item md={6}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <h2 className="mt-0">
                    <FormLabel component="legend" className="text-label">
                      Select City
                    </FormLabel>
                    <Select
                      options={allCities}
                      styles={selectStyles}
                      value={
                        allCities[
                          allCities.findIndex(
                            (key) => key.value === rostersCity
                          )
                        ]
                      }
                      placeholder={"City"}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator,
                      }}
                      onChange={(val) => {
                        setRostersCity(val ? val.value : null);
                      }}
                    />
                  </h2>
                </Grid>

                <Grid item xs={6}>
                  <Button
                    // className="add-button"
                    style={{backgroundColor:'#0D8DA3',
                padding: '8px 16px',
                marginTop: '18px',}}
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      goTo("rosterplan/update", {
                        type: "A",
                        city: rostersCity,
                      })
                    }
                  >
                    <Add className="add-icon" />
                    <span className="button-text">Create a roster plan</span>
                  </Button>
                </Grid>
              </Grid>
              <Card style={{ marginTop: 30 }}>
                <CardContent className="un-zip-code-content">
                  <Typography
                    variant="h5"
                    component="h2"
                    className="un-zip-code-title"
                  >
                    Statistics
                  </Typography>
                  <Grid container spacing={1} style={{ paddingTop: 5 }}>
                    <Grid item md={4}>
                      <div
                        style={{
                          background: "#F5F6FA",
                          display: "grid",
                          padding: 10,
                        }}
                      >
                        <span style={{ color: "#878787", fontWeight: 400 }}>
                          Number of Phlebos
                        </span>
                        <span
                          style={{
                            color: "#616161",
                            fontWeight: 600,
                            textAlign: "right",
                            fontSize: 30,
                          }}
                        >
                          {rostersInsights.totalPhlebos
                            ? rostersInsights.totalPhlebos
                            : 0}
                        </span>
                      </div>
                    </Grid>
                    <Grid item md={4}>
                      <div
                        style={{
                          background: "#F5F6FA",
                          display: "grid",
                          padding: 10,
                        }}
                      >
                        <span style={{ color: "#878787", fontWeight: 400 }}>
                          Changes requested
                        </span>
                        <span
                          style={{
                            color: "#616161",
                            fontWeight: 600,
                            textAlign: "right",
                            fontSize: 30,
                          }}
                        >
                          {rostersInsights.totalPhlebosMapped
                            ? rostersInsights.totalPhlebosMapped
                            : 0}
                        </span>
                      </div>
                    </Grid>
                    <Grid item md={4}>
                      <div
                        style={{
                          background: "#F5F6FA",
                          display: "grid",
                          padding: 10,
                        }}
                      >
                        <span style={{ color: "#878787", fontWeight: 400 }}>
                          Requests approved
                        </span>
                        <span
                          style={{
                            color: "#616161",
                            fontWeight: 600,
                            textAlign: "right",
                            fontSize: 30,
                          }}
                        >
                          {rostersInsights.totalPhlebosRostered
                            ? rostersInsights.totalPhlebosRostered
                            : 0}
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} style={{ paddingTop: 5 }}>
                    <Grid item md={6}>
                      <div
                        style={{
                          padding: 10,
                          display: "grid",
                        }}
                      >
                        <span
                          style={{
                            color: "#333333",
                            fontWeight: 400,
                            fontSize: 16,
                            marginBottom: 5,
                          }}
                        >
                          Phlebos Working(
                          {rostersInsights.totalPhlebosWorking
                            ? rostersInsights.totalPhlebosWorking
                            : 0}
                          )
                        </span>
                        <span
                          style={{
                            color: "#333333",
                            fontWeight: 400,
                            fontSize: 16,
                            marginBottom: 5,
                          }}
                        >
                          Phlebos Mapped(
                          {rostersInsights.totalPhlebosMapped
                            ? rostersInsights.totalPhlebosMapped
                            : 0}
                          )
                        </span>
                        <span
                          style={{
                            color: "#333333",
                            fontWeight: 400,
                            fontSize: 16,
                            marginBottom: 5,
                          }}
                        >
                          Phlebos Rostered(
                          {rostersInsights.totalPhlebosRostered
                            ? rostersInsights.totalPhlebosRostered
                            : 0}
                          )
                        </span>
                        <span
                          style={{
                            color: "#333333",
                            fontWeight: 400,
                            fontSize: 16,
                          }}
                        >
                          Phlebos Off(
                          {rostersInsights.totalPhlebosOff
                            ? rostersInsights.totalPhlebosOff
                            : 0}
                          )
                        </span>
                      </div>
                    </Grid>
                    <Grid item md={4}>
                      <div
                        style={{
                          padding: 10,
                        }}
                      >
                        <span
                          onClick={() => setShowLeaveOpen(!showLeaveOpen)}
                          style={{
                            color: "#333333",
                            fontWeight: 400,
                            fontSize: 16,
                            display: "flex",
                          }}
                        >
                          Phlebos on leave(
                          {rostersInsights.totalPhlebosOnLeave
                            ? rostersInsights.totalPhlebosOnLeave
                            : 0}
                          ) {showLeaveOpen ? <ExpandLess /> : <ExpandMore />}
                        </span>
                        <Collapse
                          in={showLeaveOpen}
                          timeout="auto"
                          unmountOnExit
                        >
                          <div style={{ display: "grid", marginTop: 5 }}>
                            <span
                              style={{
                                color: "#878787",
                                fontWeight: 400,
                                fontSize: 12,
                              }}
                            >
                              Planned -{" "}
                              {rostersInsights.leaveType1
                                ? rostersInsights.leaveType1
                                : 0}
                            </span>
                            <span
                              style={{
                                color: "#878787",
                                fontWeight: 400,
                                fontSize: 12,
                              }}
                            >
                              24 to 72 Hours -{" "}
                              {rostersInsights.leaveType2
                                ? rostersInsights.leaveType2
                                : 0}
                            </span>
                            <span
                              style={{
                                color: "#878787",
                                fontWeight: 400,
                                fontSize: 12,
                              }}
                            >
                              12 to 24 Hours -{" "}
                              {rostersInsights.leaveType3
                                ? rostersInsights.leaveType3
                                : 0}
                            </span>
                            <span
                              style={{
                                color: "#878787",
                                fontWeight: 400,
                                fontSize: 12,
                              }}
                            >
                              Adhoc -{" "}
                              {rostersInsights.leaveType4
                                ? rostersInsights.leaveType4
                                : 0}
                            </span>
                          </div>
                        </Collapse>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card style={{ marginTop: 10 }}>
                <CardContent className="un-zip-code-content">
                  <DatePicker inline onSelect={(date) => setDate(date)} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Paper component="form" className={classes.rootPhlebo1}>
                <div className="" style={{ width: "100%" }}>
                  <div className="headerView" style={{padding: 10}}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <InfoOutlinedIcon color="secondary" />
                      <Typography
                        style={{
                          fontSize: 14,
                          marginLeft: 20,
                          color: "#252733",
                        }}
                        display="block"
                      >
                        {rosterConfirm.hasConfirmed
                          ? `Roster plan confirmed by ${
                              rosterConfirm.confirmedBy
                            } at ${getDateTime(rosterConfirm.confirmedAt)}`
                          : "Roster plan confirmation pending"}
                      </Typography>
                    </div>
                    {!rosterConfirm.hasConfirmed ? (
                      <div style={{ margin: "auto" }}>
                        <Button
                          // className="confirm-button"
                          variant="contained"
                          style={{backgroundColor:'#0D8DA3',
                marginTop: '18px',}}
                          color="primary"
                          onClick={() => {
                            setModalOpen(true);
                          }}
                        >
                          <span className="button-text">Confirm</span>
                        </Button>
                        <IconButton
                          edge="end"
                          color="inherit"
                          size="small"
                          style={{ fontSize: "12px", margin: "5px" }}
                          onClick={() => {
                            getRosterConfirm(rostersCity);
                          }}
                        >
                          <Refresh /> Refresh
                        </IconButton>
                      </div>
                    ) : null}
                  </div>
                  <div className="headerView" style={{padding: 10}}>
                    <Grid container spacing={1}>
                      <Grid item xs={4} md={6}>
                        <Paper component="form" className={classes.filter}>
                          <InputBase
                            className={classes.input}
                            placeholder="Search"
                            inputProps={{ "aria-label": "search" }}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <IconButton
                            type="submit"
                            className={classes.iconButton}
                            aria-label="search"
                          >
                            <Search style={{ color: "#ABABAB" }} />
                          </IconButton>
                        </Paper>
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <Paper component="form" className={classes.filter}>
                          <Select
                            options={options}
                            styles={selectStyles}
                            value={
                              options[
                                options.findIndex(
                                  (key) => key.value === fiterStatus
                                )
                              ]
                            }
                            placeholder={"Status"}
                            components={{
                              IndicatorSeparator: () => null,
                              DropdownIndicator,
                            }}
                            onChange={(val) => {
                              setFiterStatus(val ? val.value : null);
                            }}
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="tableView">
                    <table className="styled-table">
                      <thead className="tableHead">
                        <tr className="border_bottom">
                          <th>S.no</th>
                          <th>Username</th>
                          <th>Phone number</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody className="tableBody">
                        {(rostersWorkStatus || [])
                          .filter(
                            (elem) =>
                              elem.status === fiterStatus || fiterStatus === 0
                          )
                          .map((el, i) => (
                            <tr className="row">
                              <td  style={{textAlign: 'center'}} className="cell" data-title="S.no">
                                {i + 1}
                              </td>
                              <td style={{textAlign: 'center'}} className="cell" data-title="Phlebo">
                                {el.name}
                              </td>
                              <td style={{textAlign: 'center'}} className="cell" data-title="Phone number">
                                {el.contact}
                              </td>
                              <td style={{textAlign: 'center'}} className="cell" data-title="city">
                                {getStatus(el.status)}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Paper>
            </Grid>

            <Grid
              container
              spacing={1}
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <Grid item xs={12} style={{ display: "flex" }}>
                {allZones.map((el, i) => (
                  <Button
                    disableRipple
                    key={`ZONES_${i}`}
                    onClick={() => {
                      setSelectedZone(el);
                    }}
                    className={`${classes.button} zone-list-button ${
                      el.zoneId === selectedZone.zoneId
                        ? "phlebo-selected"
                        : null
                    }`}
                    variant="contained"
                  >
                    {el.name}
                  </Button>
                ))}
              </Grid>
            </Grid>

            {allRosterPlans && allRosterPlans.length > 0 ? (
              <Grid container xs={12}>
                <Paper
                  component="form"
                  className={classes.rootPhlebo}
                  style={{ width: "100%", height: "auto", marginBottom: 50 }}
                >
                  <div className="" style={{ width: "100%" }}>
                    <Grid container xs={12}>
                      <Grid item xs={12}>
                        <div className="" style={{ width: "100%" }}>
                          <div className="tableView" style={{height: 'auto'}}>
                            <table className="styled-table">
                              <thead className="tableHead">
                                <tr className="border_bottom">
                                  <th>S.no</th>
                                  <th>From</th>
                                  <th>To</th>
                                  <th>Month</th>
                                  <th>Year</th>
                                  <th>#</th>
                                </tr>
                              </thead>
                              <tbody className="tableBody">
                                {(allRosterPlans || []).map((el, i) => (
                                  <tr className="row" key={`PHL_${i}`}>
                                    <td style={{textAlign: 'center'}} className="cell" data-title="S.no">
                                      {i + 1}
                                    </td>
                                    <td style={{textAlign: 'center'}} className="cell" data-title="From">
                                      {el.from}
                                    </td>
                                    <td style={{textAlign: 'center'}} className="cell" data-title="To">
                                      {el.to}
                                    </td>
                                    <td style={{textAlign: 'center'}} className="cell" data-title="To">
                                      {el.month}
                                    </td>
                                    <td style={{textAlign: 'center'}} className="cell" data-title="Year">
                                      {el.year}
                                    </td>
                                    <td style={{textAlign: 'center'}}>
                                      <Button
                                        color="primary"
                                        onClick={() =>
                                          goTo("rosterplan/update", {
                                            type: "E",
                                            city: rostersCity,
                                            rosterId: el.rosterId,
                                          })
                                        }
                                        variant="contained"
                                      >
                                        Edit Plan
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </Grid>
            ) : (
              <Typography
                style={{
                  fontSize: 14,
                  marginLeft: 20,
                  color: "#252733",
                }}
                display="block"
              >
                No Plans Found.
              </Typography>
            )}
          </Grid>
          <RosterMapping city={rostersCity} />
        </React.Fragment>
      </div>
    </DocContainer>
  );
};

const mapStateToProps = ({ constants, zones, rosters }) => {
  return {
    cities: constants.cities,
    rosters: rosters.rosters,
    zones: zones.zones,
  };
};

export default withRouter(connect(mapStateToProps)(RosterPlan));
