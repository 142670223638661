/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DocContainer from "./../../containers/DocContainer";
import ActionLoader from "../../components/ActionLoader";
import {
  Grid,
  Button,
  FormLabel,
  Paper,
  InputBase,
  IconButton,
  makeStyles,
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@material-ui/core";
import Select, { components } from "react-select";
import { Add, ArrowDropDownOutlined, Search } from "@material-ui/icons";

import {
  GET_ALL_CONSTANTS,
  GET_ALL_ZONES,
  GET_UNPLANNED_ZIPCODES,
  GET_ALL_ZIPCODES,
  GET_UNMAPPED_PHLEBOS,
  ADD_ZONES,
  UPDATE_ZONES,
  GET_PLEBOS,
  REMOVE_PHLEBO,
} from "./../../constants/actionConstants";

import "./index.css";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "275px",
    marginTop: 18,
    height: "38px",
    border: "none",
  },
  rootPhlebo: {
    display: "flex",
    height: "450px",
    border: "none",
    marginTop: 3,
    boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.1)",
    borderRadius: "12px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  cardRoot: {
    maxWidth: 500,
    maxHeight: 200,
  },
  pos: {
    marginBottom: 12,
  },
  button: {
    "&:hover": {
      backgroundColor: "#3c52b2",
      color: "#3c52b2",
    },
  },
}));

const Zone = (props) => {
  const { isLoading, cities, zones, phlebos } = props;
  const classes = useStyles();

  const [isModalOpen, setModalOpen] = useState(false);
  const [zoneName, setZoneName] = useState("");
  const [pincodeSelected, setPincodeSelected] = useState([]);

  const [phleboSelected, setPhleboSelected] = useState([]);

  const [allCities, setAllCities] = useState([]);
  const [city, setCity] = useState("");
  const [allZones, setAllZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState({});
  const [unmappedZipcodes, setUnmappedZipcodes] = useState([]);
  const [unmappedPhlebos, setUnmappedPhlebos] = useState([]);
  const [allPhlebos, setAllPhlebos] = useState([]);
  const [search, setSearch] = useState("");
  const [searchNotMapped, setSearchNotMapped] = useState("");
  const [searchZipcodes, setSearchZipcodes] = useState("");
  const [pincode, setPincode] = useState("");
  const [allZipcodes, setAllZipcodes] = useState([]);
  const [zoneCity, setZoneCity] = useState("");
  const [searchPhlebos, setSearchPhlebos] = useState("");
  useEffect(() => {
    getAllConstants();
  }, []);

  useEffect(() => {
    setAllCities(
      cities.map((city) => {
        return { value: city, label: city };
      })
    );
  }, [cities]);

  useEffect(() => {
    if (allCities.length > 0) {
      setCity("Bangalore");
    }
  }, [allCities]);

  useEffect(() => {
    if (allZones.length > 0) {
      setSelectedZone(allZones[0]);
    }
  }, [allZones]);

  useEffect(() => {
    setAllZones(zones);
  }, [zones]);

  useEffect(() => {
    if (city) {
      getAllZones(city);
    }
  }, [search]);

  useEffect(() => {
    setSelectedZone({});
    if (city) {
      getUnMappedData();
    } else {
      setUnmappedZipcodes([]);
      setUnmappedPhlebos([]);
      setAllZones([]);
    }
  }, [city]);

  const getUnMappedData = () => {
    getUnmappedZipcodes(city);
    getUnmappedPhlebos(city);
    getAllPhlebos(city);
    getAllZones(city);
    getAllZipcodes(city);
  };

  const getAllPhlebos = (city) => {
    const { dispatch } = props;
    let data = {
      count: 200,
      offset: 0,
    };
    if (city) {
      data["text"] = city;
    }

    dispatch({
      type: GET_PLEBOS,
      data,
    });
  };

  useEffect(() => {
    setAllPhlebos(phlebos);
  }, [phlebos]);

  const getUnmappedPhlebos = (city) => {
    const { dispatch } = props;
    const data = {
      city,
    };
    dispatch({
      type: GET_UNMAPPED_PHLEBOS,
      data,
      onSuccess: (res) => {
        setUnmappedPhlebos(res && res.list ? res.list : []);
      },
    });
  };

  const getUnmappedZipcodes = (city) => {
    const { dispatch } = props;
    const data = {
      city,
    };
    dispatch({
      type: GET_UNPLANNED_ZIPCODES,
      data,
      onSuccess: (res) => {
        setUnmappedZipcodes(res && res.list ? res.list : []);
      },
    });
  };

  const getAllZipcodes = (city) => {
    const { dispatch } = props;
    const data = {
      city,
      count: 100,
      offset: 0,
      text: pincode,
    };
    dispatch({
      type: GET_ALL_ZIPCODES,
      data,
      onSuccess: (res) => {
        setAllZipcodes(res && res.routes ? res.routes : []);
        console.log(setAllZipcodes);
      },
    });
  };

  const getAllZones = (city) => {
    const { dispatch } = props;
    const data = {
      city,
      count: 100,
      offset: 0,
      text: search,
    };
    dispatch({
      type: GET_ALL_ZONES,
      data,
    });
  };

  const getAllConstants = () => {
    const { dispatch } = props;
    dispatch({
      type: GET_ALL_CONSTANTS,
    });
  };

  const onClickPhlebo = (phlebo) => {
    if (phleboSelected.some((e) => e.phleboId === phlebo.phleboId)) {
      const phleboValue = phleboSelected.filter(
        (item) => item.phleboId !== phlebo.phleboId
      );
      setPhleboSelected(phleboValue);
    } else {
      setPhleboSelected([...phleboSelected, phlebo]);
    }
  };

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      fontSize: 12,
      border: "none",
      boxShadow:
        "0 4px 8px 0 rgb(255 255 255), 0 6px 8px 0 rgb(117 109 109 / 30%)",
    }),
    option: (base) => ({
      ...base,
      fontSize: 12,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#9F9F9F",
    }),
  };

  const multiSelectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      fontSize: 12,
    }),
    option: (base) => ({
      ...base,
      fontSize: 12,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#9F9F9F",
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      color: "#fff",
      backgroundColor: "#6FC5C4",
      borderRadius: 0,
      "&:hover": {
        backgroundColor: "#6FC5C4",
        color: "#fff",
      },
    }),
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownOutlined />
      </components.DropdownIndicator>
    );
  };

  const validateData = () => {
    if (!zoneName) {
      return false;
    } else {
      return true;
    }
  };

  const submitData = () => {
    if (zoneName) {
      if (selectedZone.isEdit) {
        // update data
        updateData();
      } else {
        // add data
        createData();
      }
      setModalOpen(false);
    }
  };

  const createData = () => {
    const { dispatch } = props;
    const data = {
      city: zoneCity,
      name: zoneName,
      phlebos: phleboSelected.map((el) => {
        return {
          name: el.name,
          contact: el.mobileNumber,
          phleboId: el.phleboId,
        };
      }),
      zipcodes: pincodeSelected,
    };
    dispatch({
      type: ADD_ZONES,
      data,
      onSuccess: (res) => {
        resetValue();
        getUnMappedData();
      },
    });
  };

  const updateData = () => {
    const { dispatch } = props;
    const data = {
      city,
      zoneId: selectedZone.zoneId,
      name: zoneName,
      phlebos: phleboSelected.map((el) => {
        return {
          name: el.name,
          contact: el.contact,
          phleboId: el.phleboId,
        };
      }),
      zipcodes: pincodeSelected,
    };
    dispatch({
      type: UPDATE_ZONES,
      data,
      onSuccess: (res) => {
        resetValue();
        getUnMappedData();
      },
    });
  };

  const resetValue = () => {
    setZoneName("");
    setPincodeSelected([]);
    setPhleboSelected([]);
  };

  const getClassName = (phleboId) => {
    const ph = phleboSelected.find(
      (e) => e.phleboId === phleboId
    );
    if(ph) {
      return !ph.isDisable?'remove-phlebos':'disable-phlebos'
    } else {
      return "add-phlebos";
    }
  }

  const isEnabled = (phleboId) => {
    const ph = phleboSelected.find(
      (e) => e.phleboId === phleboId
    );
    if(ph) {
      return !ph.isDisable?true:false
    } else {
      return true;
    }
  }

  const onRemovePhlebo = (data) => {
    const { dispatch } = props;
    dispatch({
      type: REMOVE_PHLEBO,
      data,
      onSuccess: (res) => {
        getUnMappedData();
      },
    });
  }

  const renderFormFields = (data) => {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid container item xs={12}>
            <Grid item xs={12} style={{}}>
              <Grid item xs={10}>
                <h2 className="mt-0">
                  <FormLabel component="legend" className="text-label">
                    {selectedZone.isEdit ? "Selected City" : "Select City"}
                  </FormLabel>
                  {selectedZone.isEdit ? (
                    <Grid item xs={6}>
                      {city}
                    </Grid>
                  ) : (
                    <Select
                      options={allCities}
                      styles={selectStyles}
                      placeholder={"City"}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator,
                      }}
                      onChange={(val) => {
                        setZoneCity(val ? val.value : null);
                      }}
                    />
                  )}
                </h2>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 30 }}>
                <h2 className="mt-0">
                  <FormLabel component="legend" className="text-label">
                    Name of the zone
                  </FormLabel>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={zoneName}
                    onChange={(e) => setZoneName(e.target.value)}
                  />
                </h2>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <h2 className="mt-0">
                  <FormLabel component="legend" className="text-label">
                    Select Zipcode
                  </FormLabel>
                  <Select
                    options={allZipcodes.map((city) => {
                      return { value: city.zipcode, label: city.zipcode };
                    })}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator,
                    }}
                    isClearable={false}
                    value={allZipcodes
                      .filter((key) => pincodeSelected.includes(key.zipcode))
                      .map((city) => {
                        return { value: city.zipcode, label: city.zipcode };
                      })}
                    isMulti
                    onChange={(val) => {
                      setPincodeSelected(val.map((a) => a.value));
                    }}
                    placeholder={"zip code"}
                    styles={multiSelectStyles}
                    style={{ width: "100%" }}
                  />
                </h2>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <h2 className="mt-0">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormLabel
                      component="legend"
                      className="text-label"
                      style={{ marginRight: 10 }}
                    >
                      Select Phlebos
                    </FormLabel>
                    <TextField
                      placeholder="Search Phlebos..."
                      variant="outlined"
                      style={{ width: "50%" }}
                      value={searchPhlebos}
                      onChange={(e) => setSearchPhlebos(e.target.value)}
                    />
                  </div>
                  <div
                    className="tableView autoheight"
                    style={{ border: "1px solid #DADADA", borderRadius: 6 }}
                  >
                    <table className="styled-table" style={{ marginTop: 10 }}>
                      <thead className="tableHead">
                        <tr className="border_bottom">
                          <th>S.no</th>
                          <th>Username</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="tableBody">
                        {allPhlebos
                          .filter(
                            (elem) =>
                              elem.firstName
                                .toLowerCase()
                                .includes(searchPhlebos.toLowerCase()) ||
                              searchPhlebos === ""
                          )
                          .map((el, index) => (
                            <tr className="row" key={`AUM_${index}`}>
                              <td className="cell" data-title="S.no">
                                {index + 1}
                              </td>
                              <td className="cell" data-title="Phlebo">
                                <div style={{ display: "grid" }}>
                                  <span>
                                    {el.firstName} {el.lastName}
                                  </span>
                                  {el.zoneId &&
                                  el.zoneId !== selectedZone.zoneId ? (
                                    <span
                                      style={{
                                        fontSize: 10,
                                        fontWeight: "normal",
                                        color: "red",
                                      }}
                                    >{`Phlebo already mapped with ${el.zoneName}`}</span>
                                  ) : null}
                                </div>
                              </td>
                              <td className="cell" data-title="Username">
                                <Button
                                  className={getClassName(el.phleboId)}
                                  variant="contained"
                                  onClick={() => {
                                    if(!isEnabled(el.phleboId)) {
                                      return;
                                    }
                                    onClickPhlebo({
                                      ...el,
                                      contact: el.mobileNumber,
                                      name: `${el.firstName} ${el.lastName}`,
                                    });
                                  }}
                                >
                                  <span className="button-text">
                                    {!phleboSelected.some(
                                      (e) => e.phleboId === el.phleboId
                                    )
                                      ? "Add"
                                      : "Remove"}
                                  </span>
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </h2>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const renderPopup = () => {
    return (
      <Dialog
        disableBackdropClick
        open={isModalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle id="zone-popup">
          {selectedZone.isEdit ? "Edit Zone" : "Add Zone"}
        </DialogTitle>
        <DialogContent style={{ minHeight: "400px" }}>
          {renderFormFields(selectedZone)}
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              borderColor: "#D7D7D7",
              color: "#616161",
              borderRadius: "4px",
            }}
            onClick={() => {
              setModalOpen(false);
            }}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: validateData() ? "#00C9AA" : "#EAECEE",
              borderRadius: "4px",
            }}
            onClick={() => submitData()}
            variant="contained"
            color="primary"
          >
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <DocContainer transparent={true}>
      <div className="mr-20 pd-t-20">
        {isLoading && <ActionLoader />}
        {isModalOpen && renderPopup()}
        <React.Fragment>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <h2 className="mt-0">
                <FormLabel component="legend" className="text-label">
                  Select City
                </FormLabel>
                <Select
                  options={allCities}
                  styles={selectStyles}
                  isClearable={true}
                  placeholder={"City"}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator,
                  }}
                  value={
                    allCities[allCities.findIndex((key) => key.value === city)]
                  }
                  onChange={(val) => {
                    setCity(val ? val.value : null);
                  }}
                />
              </h2>
            </Grid>

            <Grid item xs={3}>
              <Button
                // className={`add-button`}
                variant="contained"
                color="primary"
                style={{backgroundColor:'#0D8DA3',
                padding: '8px 16px',
                marginTop: '18px',}}
                onClick={() => {
                  setModalOpen(true);
                  setZoneName("");
                  setPincodeSelected([]);
                  setPhleboSelected([]);
                  setSelectedZone({ ...selectedZone, isEdit: false });
                }}
              >
                <Add className="add-icon" />
                <span className="button-text">Add Zone</span>
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Paper component="form" className={classes.root}>
                <InputBase
                  className={classes.input}
                  placeholder="Search"
                  onChange={(e) => setSearch(e.target.value)}
                  inputProps={{ "aria-label": "search" }}
                />
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <Search style={{ color: "#ABABAB" }} />
                </IconButton>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ paddingTop: 17 }}>
            <Grid item container xs={12} spacing={1} style={{ display: "flex" }}>
              {allZones.map((el, i) => (
                <Grid item key={`ZONES_${i}`} xs={2}>
                <Button
                  disableRipple
                  onClick={() => {
                    setSelectedZone(el);
                  }}
                  className={`${classes.button} zone-list-button ${
                    el.zoneId === selectedZone.zoneId ? "phlebo-selected" : null
                  }`}
                  variant="contained"
                >
                  {el.name}
                </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ paddingTop: 17 }}>
            <Grid item xs={6}>
              <Paper component="form" className={classes.rootPhlebo} style={{height: 'auto'}}>
                <div className="phlebo-block1">
                  <div className="headerView">
                  <Grid container item xs={8}>
                    {selectedZone &&
                      selectedZone.zipcodes &&
                      selectedZone.zipcodes.map((el, i) => (
                        <Grid item key={`SELZIP_${i}`} xs={4}>
                        <Typography
                          variant="button"
                          display="block"
                          gutterBottom
                          className={`phlebo-button`}
                        >
                          {el}
                        </Typography>
                        </Grid>
                      ))}
                      </Grid>
                    {selectedZone && Object.keys(selectedZone).length > 0 ? (
                      <Typography
                        variant="button"
                        display="block"
                        gutterBottom
                        style={{ color: "white" }}
                        className={`phlebo-button edit-zone`}
                        onClick={() => {
                          setModalOpen(true);
                          setZoneName(selectedZone.name);
                          setPincodeSelected(selectedZone.zipcodes || []);
                          setPhleboSelected(
                            selectedZone.phlebos.map((el) =>
                              true ? { ...el, isDisable: true } : { ...el }
                            )
                          );
                          setSelectedZone({ ...selectedZone, isEdit: true });
                        }}
                      >
                        Edit Zone
                      </Typography>
                    ) : null}
                  </div>
                  <div className="tableView" style={{maxHeight: 450}}>
                    <table className="styled-table">
                      <thead className="tableHead">
                        <tr className="border_bottom">
                          <th>S.no</th>
                          <th>Phlebo</th>
                          <th>Phone number</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="tableBody">
                        {selectedZone &&
                          selectedZone.phlebos &&
                          selectedZone.phlebos.map((el, i) => (
                            <tr className="row" key={`UMP_${i}`}>
                              <td className="cell" data-title="S.no">
                                {i + 1}
                              </td>
                              <td className="cell" data-title="Phlebo">
                                {el.name}
                              </td>
                              <td className="cell" data-title="Phone number">
                                {el.contact}
                              </td>
                              <td className="cell" data-title="Username">
                                <Button
                                  className={'remove-phlebos'}
                                  variant="contained"
                                  onClick={() => {
                                    onRemovePhlebo({
                                      zoneId: selectedZone.zoneId,
                                      zoneName: selectedZone.name,
                                      phleboId: el.phleboId,
                                    });
                                  }}
                                >
                                  <span className="button-text">
                                    Remove
                                  </span>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        {!selectedZone || !selectedZone.phlebos ? (
                          <tr className="row">
                            <td className="cell" data-title="S.no">
                              No Phlebos
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ paddingTop: 17 }}>
            <Grid item xs={6}>
              <Paper component="form" className={classes.rootPhlebo}>
                <div className="" style={{ width: "100%" }}>
                  <div className="headerView" style={{justifyContent: 'space-around'}}>
                    <Typography
                      variant="button"
                      display="block"
                      gutterBottom
                      className={`phlebo-header`}
                    >
                      Phlebos not mapped to zone
                    </Typography>
                    <TextField
                      style={{marginLeft: 10}}
                      placeholder="Search Phlebos..."
                      variant="outlined"
                      value={searchNotMapped}
                      onChange={(e) => setSearchNotMapped(e.target.value)}
                    />
                  </div>
                  <div className="tableView" style={{maxHeight: 350}}>
                    <table className="styled-table">
                      <thead className="tableHead">
                        <tr className="border_bottom">
                          <th>S.no</th>
                          <th>Phlebo</th>
                          <th>Phone number</th>
                        </tr>
                      </thead>
                      <tbody className="tableBody">
                        {unmappedPhlebos.filter((el) => (el.firstName || '').toLowerCase().includes(searchNotMapped.toLowerCase())).map((el, index) => (
                          <tr className="row">
                            <td className="cell" data-title="S.no">
                              {index + 1}
                            </td>
                            <td className="cell" data-title="Phlebo">
                              {el.firstName} {el.lastName}
                            </td>
                            <td className="cell" data-title="Phone number">
                              {el.mobileNumber}
                            </td>
                          </tr>
                        ))}
                        {unmappedPhlebos.length === 0 ? (
                          <tr className="row">
                            <td className="cell" data-title="S.no">
                              No Phlebos
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Card className={classes.cardRoot}>
                <CardContent className="un-zip-code-content">
                  <div style={{display: 'flex'}}>
                  <Typography
                    variant="h5"
                    component="h2"
                    className="un-zip-code-title"
                  >
                    Unplanned zipcodes
                  </Typography>
                  <TextField
                      style={{marginLeft: 10}}
                      placeholder="Search Zipcodes..."
                      variant="outlined"
                      value={searchZipcodes}
                      onChange={(e) => setSearchZipcodes(e.target.value)}
                    />
                    </div>
                  <Typography className={classes.pos} color="textSecondary">
                    <ol className="zip-code-ol-list">
                      {unmappedZipcodes.filter((el) => (el.zipcode || '').toLowerCase().includes(searchZipcodes.toLowerCase()) || (el.areaName || '').toLowerCase().includes(searchZipcodes.toLowerCase())).map((el, index) => (
                        <li className="zip-code-list" key={`UMZ_${index}`}>
                          {el.zipcode}{" "}
                          <span className="zip-code-span">({el.areaName})</span>
                        </li>
                      ))}
                      {unmappedZipcodes.length === 0 ? "No Zipcodes." : ""}
                    </ol>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </React.Fragment>
      </div>
    </DocContainer>
  );
};

const mapStateToProps = ({ constants, zones, plebos }) => {
  return {
    cities: constants.cities,
    zones: zones.zones,
    phlebos: plebos.plebos,
  };
};

export default withRouter(connect(mapStateToProps)(Zone));
