/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { makeStyles} from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import ActionLoader from "../../components/ActionLoader";

import { getUser } from "Utils/storage";
import "./clinics.css";

import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import saveAs from "save-as";

import { GET_COMPRESSED } from "./../../constants/actionConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  report: {
    padding: 10,
    background: "#E0E0E0",
    borderRadius: 5,
  },
}));

const Download = (props) => {
  const classes = useStyles();
  const [files, setFiles] = useState({
    report1: [],
    report2: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (props.orderId) {
      getCompressed();
    }
  }, []);

  const getCompressed = () => {
    const data = {};
    const { dispatch } = props;
    data["orderId"] = props.orderId;
    dispatch({
      type: GET_COMPRESSED,
      isPartner: getUser().userType === 2,
      data,
      onSuccess: (data) => {
        if (data) {
          let report1 = [];
          let report2 = [];
          if (data.mer) {
            report1 = report1.concat(data.mer);
          }
          if (data.ppmc_id) {
            report1 = report1.concat(data.ppmc_id);
          }
          if (data.ppmc_live) {
            report1 = report1.concat(data.ppmc_live);
          }
          if (data.ppmc_namechange) {
            report1 = report1.concat(data.ppmc_namechange);
          }
          if (data.report) {
            report1 = report1.concat(data.report);
          }
          if (data.ppmc_mer) {
            report2 = report2.concat(data.ppmc_mer);
          }
          setFiles({
            report1,
            report2,
          });
        }
      },
    });
  };

  const handleDownload = (urls = []) => {
    setIsLoading(true);
    const zip = new JSZip();
    let count = 0;
    const zipFilename = "evidence.zip";
    urls.forEach(async function(url) {
      const urlArr = url.split("/");
      const filename = urlArr[urlArr.length - 1];
      try {
        const file = await JSZipUtils.getBinaryContent(url);
        zip.file(filename, file, { binary: true });
        count++;
        if (count === urls.length) {
          zip.generateAsync({ type: "blob" }).then(function(content) {
            setIsLoading(false);
            saveAs(content, zipFilename);
          });
        }
      } catch (err) {
        setIsLoading(false);
      }
    });
  };
  return (
    <React.Fragment>
      {isLoading && <ActionLoader />}
      <Grid container spacing={2}>
        <Grid container item xs={12}>
          <Grid item xs={3} className={classes.report}>
            <div>
              <span style={{ fontSiz: 18, fontWeight: "bold" }}>
                MER, KYC, REPORT, ECG, PHOTO
              </span>
            </div>
            <div>
              <span>File Type: Zip file</span>
            </div>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            onClick={() => handleDownload(files.report1)}
            variant="contained"
            color="primary"
          >
            Download
          </Button>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={3} className={classes.report}>
            <div>
              <span style={{ fontSiz: 18, fontWeight: "bold" }}>
                Video Call Recording
              </span>
            </div>
            <div>
              <span>File Type: MP4</span>
            </div>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            onClick={() => handleDownload(files.report2)}
            variant="contained"
            color="primary"
          >
            Download
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = ({ orders }) => {
  return {
    isLoading: orders.isSubmitting,
  };
};

export default withRouter(connect(mapStateToProps)(Download));
