import {
    GET_TRIPS,
    SET_TRIPS
} from 'Constants/actionConstants';


const trips = (state={
trips: [],
stops:[]
}, action)=> {

switch (action.type) {

  case GET_TRIPS:
    return {
      ...state,
      trips: action.data,
    };

  case SET_TRIPS:
    return {
      ...state,
      stops:action.data
    }  

  default:
    return state;
}

}

export default trips;
