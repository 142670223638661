/* eslint-disable jsx-control-statements/jsx-use-if-tag */
/* eslint-disable no-loop-func */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DocContainer from "./../../containers/DocContainer";
import ActionLoader from "../../components/ActionLoader";
import {
  Grid,
  Button,
  FormLabel,
  Paper,
  IconButton,
  makeStyles,
  Typography,
  Switch,
  TextareaAutosize,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import Select, { components } from "react-select";
import {  ArrowDropDownOutlined } from "@material-ui/icons";
import { ArrowBackOutlined } from "@material-ui/icons";

import { DateRangePicker } from "rsuite";
import moment from "moment";

import "./index.css";
import "./date.css";

import {
  GET_ALL_CONSTANTS,
  GET_ALL_ZONES,
  GET_ROSTER_DETAILS_BY_ID,
  ADD_ROSTERS,
  UPDATE_ROSTERS,
} from "./../../constants/actionConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "275px",
    marginTop: 18,
    height: "38px",
    border: "none",
  },
  rootPhlebo: {
    display: "flex",
    height: "450px",
    border: "none",
    marginTop: 3,
    boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.1)",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  cardRoot: {
    maxWidth: 350,
    maxHeight: 200,
  },
  pos: {
    marginBottom: 12,
  },
  button: {
    "&:hover": {
      backgroundColor: "#3c52b2",
      color: "#3c52b2",
    },
  },
}));

const CreateRosterPlan = (props) => {
  const { cities, zones, history } = props;
  console.log(props);
  const classes = useStyles();
  const { type, rosterId } = history.location.state;
  const [allCities, setAllCities] = useState([]);
  const [tableFilters, setTableFilters] = useState("");
  const [allPhlebos, setAllPhlebos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setModalOpen] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [allZones, setAllZones] = useState([]);
  const [planData, setPlanData] = useState({
    rostersCity: "",
    startDate: "",
    endDate: "",
  });

  const [createdZones, setCreatedZones] = useState([]);

  useEffect(() => {
    getAllConstants();
  }, []);

  useEffect(() => {
    if (type === "E") {
      getRosterDetailsById(rosterId);
    }
  }, [type]);

  useEffect(() => {
    if (
      tableFilters.rostersCity &&
      tableFilters.zoneId &&
      tableFilters.date &&
      tableFilters.date.startDate &&
      tableFilters.date.endDate
    ) {
      if (type === "A") {
        const days = [];

        for (
          var i = tableFilters.date.startDay;
          i < tableFilters.date.endDay + 1;
          i++
        ) {
          days.push(i);
        }
        const phlebos = [];
        (allPhlebos || []).forEach((el) => {
          phlebos.push({
            phleboId: el.phleboId,
            contact: el.contact,
            name: el.name,
            year: tableFilters.date.year,
            month: tableFilters.date.month,
            days: days,
            off: [],
          });
        });
        setPlanData({
          ...planData,
          phlebos,
          ...tableFilters,
        });
      }
    }
  }, [tableFilters]);

  useEffect(() => {
    setAllZones(zones);
  }, [zones]);

  useEffect(() => {
    setAllCities(
      cities.map((city) => {
        return { value: city, label: city };
      })
    );
  }, [cities]);

  useEffect(() => {
    if (allCities.length > 0) {
      setTableFilters({
        ...tableFilters,
        rostersCity: "Bangalore",
      });
    }
  }, [allCities]);

  useEffect(() => {
    if (tableFilters.rostersCity) {
      getAllZones(tableFilters.rostersCity);
    } else {
      setAllZones([]);
    }
  }, [tableFilters.rostersCity]);

  const getRosterDetailsById = (rosterId) => {
    const { dispatch } = props;
    const data = {
      rosterId,
    };
    dispatch({
      type: GET_ROSTER_DETAILS_BY_ID,
      data,
      onSuccess: (res) => {
        setPlanData({ ...res, rostersCity: res.city });
        setTableFilters({
          rostersCity: res.city,
          date: {
            oriStartDate: new Date(`${res.year}-${res.month}-${res.from}`),
            startDate: new Date(
              `${res.year}-${res.month}-${res.from}`
            ).getTime(),
            oriEndDate: new Date(`${res.year}-${res.month}-${res.to}`),
            endDate: new Date(`${res.year}-${res.month}-${res.to}`).getTime(),
            startDay: res.from,
            endDay: res.to,
            year: res.year,
            month: res.month,
          },
          year: res.year,
          zoneId: res.zoneId,
          zoneName: res.zoneName,
          rosterId: res.rosterId,
        });
      },
    });
  };

  const getAllZones = (city) => {
    const { dispatch } = props;
    const data = {
      city,
      count: 100,
      offset: 0,
      text: "",
    };
    dispatch({
      type: GET_ALL_ZONES,
      data,
    });
  };

  const getAllConstants = () => {
    const { dispatch } = props;
    dispatch({
      type: GET_ALL_CONSTANTS,
    });
  };

  const handleTableFiler = (key, value) => {
    setTableFilters({
      ...tableFilters,
      [key]: value,
    });
  };


  const handleSwitchChange = (day, index, isChecked, min, max) => {
    let planDataCopy = JSON.parse(JSON.stringify(planData));
    const allDays = betweenWeekDays(min, max, day);
    if (isChecked) {
      planDataCopy["phlebos"][index]["days"] = planDataCopy["phlebos"][index]["days"].concat(allDays);
      planDataCopy["phlebos"][index]["off"] = planDataCopy["phlebos"][index][
        "off"
      ].filter((item) => !allDays.includes(item));
    } else {
      planDataCopy["phlebos"][index]["off"] = allDays;
      planDataCopy["phlebos"][index]["days"] = planDataCopy["phlebos"][index][
        "days"
      ].filter((item) => !allDays.includes(item));
    }

    if (type === "E") {
      updateRosters(planDataCopy.rosterId, planDataCopy["phlebos"][index]);
    }

    setPlanData(planDataCopy);
  };

  const handleSubmitComment = () => {
    setModalOpen(false);
    handleSwitchChange(
      updateData.name,
      updateData.index,
      updateData.isChecked,
      updateData.min,
      updateData.max
    );
  };

  const betweenWeekDays = (min, max, day) => {
    const days = [];
    // getting next days
    for(var j=day;j<=max;j=j+7) {
      days.push(j);
    }
    // getting previous days
    for(var k=day-7;k>=min;k=k-7) {
      days.push(k);
    }
    return days;
  };

  const updateRosters = (rosterId, rosterDetails) => {
    setIsLoading(true);
    const { dispatch } = props;
    const data = {
      rosterId,
      phleboId: rosterDetails.phleboId,
      days: rosterDetails.days,
      off: rosterDetails.off,
      comments: updateData.comments,
    };
    dispatch({
      type: UPDATE_ROSTERS,
      data,
      onSuccess: (res) => {
        setIsLoading(false);
        setUpdateData({});
      },
    });
  };

  const selectSimpleStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      fontSize: 12,
    }),
    option: (base) => ({
      ...base,
      fontSize: 12,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#9F9F9F",
    }),
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownOutlined />
      </components.DropdownIndicator>
    );
  };

  const handleSubmit = () => {
    const { dispatch } = props;
    if (type === "A") {
      // add
      const data = {
        city: planData.rostersCity,
        from: planData.date.startDay,
        to: planData.date.endDay,
        month: planData.date.month,
        year: planData.date.year,
        zoneName: planData.zoneName,
        zoneId: planData.zoneId,
        phlebos: planData.phlebos,
      };
      dispatch({
        type: ADD_ROSTERS,
        data,
        
      });
    }
  };

  const getHeaders = (min, max, month,year) => {
    var headers = [];
    for (var i = min; i < max + 1; i++) {
      headers.push(
        <th>
          {i} {month} {moment(`${year}-${month}-${i}`).format('dddd').substring(0,3)}
        </th>
      );
    }
    return headers;
  };

  const getData = (min, max, days = [], index) => {
    var data = [];
    for (var i = min; i < max + 1; i++) {
      data.push(
        <td className="cell" data-title="Username">
          <Switch
            className={i}
            checked={days.includes(i)}
            name={i}
            onChange={(e) => {
              if (type === "E") {
                setUpdateData({
                  name: parseInt(e.target.name),
                  index,
                  isChecked: e.target.checked,
                  min,
                  max,
                });
                setModalOpen(true);
              } else {
                handleSwitchChange(
                  parseInt(e.target.name),
                  index,
                  e.target.checked,
                  min,
                  max
                );
              }
            }}
            color="primary"
            inputProps={{
              "aria-label": "primary checkbox",
            }}
          />
        </td>
      );
    }
    return data;
  };

  const renderPopup = () => {
    return (
      <Dialog
        disableBackdropClick
        open={isModalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <DialogContent>
          <div
            className="headerView"
            style={{ marginLeft: "0px", marginTop: 0, flexDirection: "column" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "left",
                paddingTop: 5,
                marginBottom: 15,
              }}
            >
              <Typography
                style={{
                  fontSize: 24,
                  marginLeft: 12,
                  color: "#252733",
                  fontWeight: "600",
                }}
                display="block"
              >
                Comment
              </Typography>
            </div>
            <div>
              <TextareaAutosize
                style={{ width: "100%", height: "250px", padding: 10 }}
                rows={10}
                aria-label="maximum height"
                placeholder="Enter the comment here"
                className="text-area"
                onChange={(e) =>
                  setUpdateData({
                    ...updateData,
                    comments: e.target.value,
                  })
                }
              />
            </div>
            <div className="action-div" style={{ marginTop: 10 }}>
              <Button
                className="white-button"
                variant="contained"
                color="primary"
                onClick={() => setModalOpen(false)}
                style={{ marginRight: 10 }}
              >
                <span>Cancel</span>
              </Button>
              <Button
                className="green-button"
                variant="contained"
                color="primary"
                onClick={() => handleSubmitComment()}
                style={{ marginRight: 10 }}
              >
                <span>Submit</span>
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <DocContainer transparent={true}>
      <div className="mr-20 pd-t-20">
        {isLoading && <ActionLoader />}
        {isModalOpen && renderPopup()}
        <React.Fragment>
          <Grid container spacing={1} style={{ alignItems: "center" }}>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => {
                history.goBack();
              }}
            >
              <ArrowBackOutlined />
            </IconButton>
            <Grid item xs={4}>
              {" "}
              <h2 className="mt-0">Roster Details</h2>{" "}
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ paddingTop: 5 }}>
            <Grid container xs={12}>
              <Paper
                component="form"
                className={classes.rootPhlebo}
                style={{ width: "100%", height: "auto" }}
              >
                <div className="" style={{ width: "100%" }}>
                  <Grid container xs={12}>
                    <Grid item xs={4} style={{ padding: 20 }}>
                      <h2 className="mt-0">
                        <FormLabel component="legend" className="text-label">
                          Select City
                        </FormLabel>
                        <Select
                          options={allCities}
                          styles={selectSimpleStyles}
                          isDisabled={type === "E"}
                          value={
                            allCities[
                              allCities.findIndex(
                                (key) => key.value === tableFilters.rostersCity
                              )
                            ]
                          }
                          placeholder={"City"}
                          components={{
                            IndicatorSeparator: () => null,
                            DropdownIndicator,
                          }}
                          onChange={(val) => {
                            handleTableFiler(
                              "rostersCity",
                              val ? val.value : null
                            );
                          }}
                        />
                      </h2>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 20 }}>
                      <div
                        style={{ marginTop: 25 }}
                        className="range-datepicker"
                      >
                        <DateRangePicker
                          showOneCalendar
                          disabled={type === "E"}
                          value={
                            tableFilters.date
                              ? [
                                  tableFilters.date.oriStartDate,
                                  tableFilters.date.oriEndDate,
                                ]
                              : []
                          }
                          onOk={(val) =>
                            handleTableFiler("date", {
                              oriStartDate: val[0],
                              startDate: new Date(val[0]).getTime(),
                              startDay: new Date(val[0]).getDate(),
                              oriEndDate: val[1],
                              endDate: new Date(val[1]).getTime(),
                              endDay: new Date(val[1]).getDate(),
                              month: new Date(val[1]).getMonth()+1,
                              year: new Date(val[1]).getFullYear(),
                            })
                          }
                          onClean={() => handleTableFiler("date", {})}
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        display: "flex",
                        padding: 20,
                        alignItems: "center",
                      }}
                    >
                      <h4 className="mt-0">
                        <FormLabel component="legend" className="text-label">
                          Zone Name
                        </FormLabel>
                        {tableFilters.zoneName}
                      </h4>
                    </Grid>
                    {type === "A" ? (
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", padding: 20 }}
                      >
                        {allZones.map((el, i) => (
                          <Button
                            disableRipple
                            key={`ZONES_${i}`}
                            onClick={() => {
                              if (type === "A") {
                                setTableFilters({
                                  ...tableFilters,
                                  zoneId: el.zoneId,
                                  zoneName: el.name,
                                });
                                setAllPhlebos(el.phlebos || []);
                              }
                            }}
                            className={`${classes.button} zone-list-button ${
                              el.zoneId === tableFilters.zoneId
                                ? "phlebo-selected"
                                : null
                            }`}
                            variant="contained"
                          >
                            {el.name}
                          </Button>
                        ))}
                        {tableFilters &&
                          tableFilters.date &&
                          tableFilters.date.startDay &&
                          planData.rostersCity &&
                          planData.zoneId &&
                          type === "A" && (
                            <div className="action-div">
                              <Button
                                className="green-button"
                                variant="contained"
                                color="primary"
                                onClick={() => handleSubmit()}
                                style={{ marginRight: 10 }}
                              >
                                <span>Create Roster</span>
                              </Button>
                            </div>
                          )}
                      </Grid>
                    ) : null}
                    <Grid item xs={12}>
                      <div className="" style={{ width: "100%" ,overflowX:'scroll'}}>
                        <div  style={{height:'600px'}}>
                          <table className="styled-table">
                            <thead className="tableHead">
                              <tr className="border_bottom">
                                {tableFilters &&
                                tableFilters.date &&
                                tableFilters.date.startDay &&
                                planData.rostersCity &&
                                planData.zoneId ? (
                                  <>
                                    <th>S.no</th>
                                    <th>Phlebo Name</th>
                                    {getHeaders(
                                      tableFilters.date.startDay,
                                      tableFilters.date.endDay,
                                     moment(
                                        tableFilters.date.month,
                                        "MM"
                                      ).format("MMM"),tableFilters.date.year)
                                    }
                                  </>
                                ) : null}
                              </tr>
                            </thead>
                            <tbody className="tableBody">
                              {tableFilters &&
                                tableFilters.date &&
                                tableFilters.date.startDay &&
                                planData.rostersCity &&
                                planData.zoneId &&
                                (planData.phlebos || []).map((el, i) => (
                                  <tr className="row" key={`PHL_${i}`}>
                                    <td className="cell" data-title="S.no">
                                      {i + 1}
                                    </td>
                                    <td className="cell" data-title="Phlebo">
                                      {el.name}
                                    </td>
                                    {getData(
                                      tableFilters.date.startDay,
                                      tableFilters.date.endDay,
                                      el.days,
                                      i
                                    )}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </React.Fragment>
      </div>
    </DocContainer>
  );
};

const mapStateToProps = ({ constants, zones }) => {
  return {
    cities: constants.cities,
    rosters: zones.rosters,
    zones: zones.zones,
  };
};

export default withRouter(connect(mapStateToProps)(CreateRosterPlan));
