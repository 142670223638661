import React from 'react';
import './actionloader.css';
import CircularProgress from '@material-ui/core/CircularProgress';

const ActionLoader = ({fixed, className, color})=> {
    return (
        <div className={`pre-loader ${className}`} id="pre-loader" style={{
            position: fixed?'fixed': 'absolute'
        }}>
            <CircularProgress color={color} />
        </div>
    )
};

export default ActionLoader;