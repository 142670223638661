import {  VIDEO_MER_TOKEN } from 'Constants/actionConstants';

const vmer = (state = {

  videoToken: {},
 
}, action) => {
    switch (action.type) {
        case VIDEO_MER_TOKEN:
            return {
              ...state,
              videoToken: action.data,
            };
            
    default:
        return state;
    }
  
  }
  
  export default vmer;