/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DocContainer from "./../../containers/DocContainer";
import ActionLoader from "../../components/ActionLoader";
import moment from "moment";
import MapContainer from "../../components/map/map2";
import { Grid, Select, Chip, TextField } from "@material-ui/core";
import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Refresh } from "@material-ui/icons";
import {
  GET_ALL_CONSTANTS,
  GET_LPGS
} from "../../constants/actionConstants";
import { GET_PLEBOS } from "Constants/actionConstants";
import { GET_LOCATIONS } from "Constants/actionConstants";

const useStyles = makeStyles((theme) => ({
  selected: {
    background: "#ff0037",
    color: "white",
  },
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      // Default transform is "translate(14px, 20px) scale(1)""
      // This lines up the label with the initial cursor position in the input
      // after changing its padding-left.
      transform: "translate(34px, 20px) scale(1);"
    }
  },
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingBottom:40,
      transform: "translateY(-8px);"
    },
  }
}));


const latLang = {
  Ahmedabad: { lat: 23.0204975, lng: 72.4393114},
  Bangalore: { lat: 12.9545163, lng: 77.3500497},
  Lucknow: { lat: 26.848929, lng: 80.8020818},
  Chennai: { lat: 13.0480431, lng: 79.9281219},
  Delhi: { lat: 28.6472785, lng: 76.8123783},
  Hyderabad: { lat: 17.4126272, lng: 78.2676139},
  Kolkata: { lat: 22.6763846, lng: 88.0488414},
  Mumbai: { lat: 19.0825221, lng: 72.7407546},
  Pune: { lat: 18.5248902, lng: 73.7225358},
  Surat: { lat: 21.1594624, lng: 72.6818649},
  ALL: { lat: 20.706188, lng: 79.004195},
}

const Track = (props) => {
  const { allcities, allplebos } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [city, setCity] = useState("");
  const [cityMap, setCityMap] = useState("");
  const [cities, setCities] = useState([]);
  const [filterType, setFilterType] = useState("P");
  const [filterTypeMap, setFilterTypeMap] = useState("P");
  const [search, setSearch] = useState("");
  const [sockKey, setSockKey] = useState("Bangalore_1");
  const [phlebos, setPhlebos] = useState([]);
  const [phleboLocations, setPhleboLocations] = useState([]);
  const [phleboNames, setPhleboNames] = useState([]);
  const [phleboId, setPhleboId] = useState(undefined);
  const [reload, setReload] = useState(localStorage.getItem('lastReload'));
  const [positionId, setPositionId] = useState(undefined);
  const [lgp, setLgp] = useState(undefined);
  const [lgps, setLgps] = useState(undefined);
  const [searchField, setSearchField] = useState('');
  const [userType, setUserType] = useState(1);

  const handleCityMapFilter = (e) => {
    setCityMap(e.target.value);
  };
  const handleCityFilter = (e) => {
    setPhleboNames([])
    setCity(e.target.value);
    setCityMap(e.target.value);
  };


  useEffect(() => {
    if (cityMap && filterTypeMap) {
      setSockKey(`${cityMap}_${filterTypeMap === "P" ? 1 : 2}`);
    } else {
      setSockKey("");
    }
  }, [cityMap]);

  useEffect(() => {
    if (filterTypeMap && cityMap) {
      setSockKey(`${cityMap}_${filterTypeMap === "P" ? 1 : 2}`);
    } else {
      setSockKey("");
    }
  }, [filterTypeMap]);

 

  

  useEffect(() => {
    getAllConstants();
    getLGPs()
  }, []);

  useEffect(() => {
    setCities(allcities);
    setIsLoading(false);
  }, [allcities]);

  useEffect(() => {
    setPhlebos(allplebos);
    setIsLoading(false);
  }, [allplebos]);

  const getAllConstants = () => {
    const { dispatch } = props;
    dispatch({
      type: GET_ALL_CONSTANTS,
    });
  };

  const getDateTime = (time) => {
    const createdDate = new Date(time);
    const dd = String(createdDate.getDate()).padStart(2, "0");
    const mm = String(createdDate.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = createdDate.getFullYear();
    let hours = createdDate.getHours();
    let minutes = createdDate.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return `${dd}-${mm}-${yyyy} ${hours}:${minutes}${ampm}`;
  };

  useEffect(() => {
    if(city){
    getPlebos();
    getPlebos2()
    }
  }, [city]);

  useEffect(() => {
    setPhleboNames(props.plebos)
    setPhleboLocations(props.locations)
 
  }, [props.plebos,props.locations]);


  useEffect(()=>{
    if(phleboId || phleboId == null){
      getPlebos()
    }
  },[phleboId])

  const getPlebos2 = (id) => {
    const { dispatch } = props;
    let data = {
      count: 200,
      offset: 0,
    };
 

    if (city && city.length > 0) {
      data['city'] = city;
    }

    dispatch({
      type: GET_PLEBOS,
      data,
    });
    setPhleboNames(props.plebos)
  };

 const getPlebos = (id) => {
    const { dispatch } = props;
    let data = {
     position:positionId?positionId:undefined,
     userType:filterType === "P" ? 1 : 2
    };
    if (id) {
      data = { phleboId: id };
    }
    if (phleboId) {
      data['phleboId'] = phleboId ;
    }

    if (city && city.length > 0) {
      data['city'] = city;
    }
    if(lgp) {
      data['lgp'] = lgp;
    }

    if(searchField){
      data['text'] = searchField;
    }

    dispatch({
      type: GET_LOCATIONS,
      data,
    });
  };

  const handlePhelboId = (e) => {
    setPhleboId(e ? e.phleboId:null);
  }

  const onReload = () => {
    getPlebos();
    localStorage.setItem('lastReload',moment(Date.now()).format("h:mm A"))
    setReload(localStorage.getItem('lastReload'));
  }

  const position = [
    {name:"All", id:1},
    {name:"Moving",id:2},
    {name:"Stationary",id:3}
  ]

  const orders = [
    {name:"Pending Orders - No update", id:1},
    {name:"Orders Completed",id:2},
    {name:"Pending Orders - stationary",id:3}
  ]

  useEffect(()=>{
    if(positionId || positionId == null){
    getPlebos();
    }
  },[positionId])

  useEffect(()=>{
    if(lgp || lgp == null){
    getPlebos();
    }
  },[lgp])

  useEffect(()=>{
    if(searchField.length >= 0){
    getPlebos();
    }
  },[searchField])

  const handlePosition = (value) => {
    setPositionId(value ? value.id : null)
  }

  const handleLgp = (value) => {
    setLgp(value ? value.lgpId : null)
  }

  const getLGPs = () => {
    const { dispatch } = props;
    dispatch({
      type: GET_LPGS,
      data: {
        count: 200,
        offset: 0,
      },
    });
  };

  useEffect(()=>{
    setLgps(props.lgps)
  },[props.lgps.length>0])

  useEffect(()=>{
    getPlebos();
  },[filterType])

  const handleSearch=(e) => {
    setSearchField(e.target.value)
  }
 

  return (
    <DocContainer>
      <div className="mr-20">
        {isLoading && <ActionLoader />}
        <React.Fragment>
          <Grid container spacing={3} style={{ marginBottom: 5 }}>
            <Grid item md="2">
              <h2 className="mt-0">Control Tower</h2>
            </Grid>
           
            <Grid item style={{ width: 150}}>
              <div className="dateLabel">Select city to zoom: </div>
              <Select
                size="small"
                style={{ width: "130px" }} //fullWidth
                value={cityMap}
                onChange={(e) => handleCityMapFilter(e)}
              >
                {(cities || []).map((city, index) => (
                  <option key={`city-${index}`} value={city}>
                    {" "}
                    {city}{" "}
                  </option>
                ))}
              </Select>
              {cityMap && (
                <span
                  onClick={() => {
                    setCityMap("");
                    setPhleboLocations([]);
                  }}
                >
                  X
                </span>
              )}
            </Grid>

            <Grid item style={{ width: 150, marginRight: 3 }}>
              <div className="dateLabel"> LGP: </div>
              <Autocomplete
                    id="combo-box-demo"
                    value={lgp}
                    classes={classes}
                    // onChange={(e) => handlePhelboId(e)}
                    onChange={(event, newValue) => {
                      handleLgp(newValue);
                    }}
                    options={lgps}
                    getOptionLabel={(option) => `${option.name}`}
                    style={{ width: 150 }}
                    renderInput={(params) => <TextField {...params}  variant="outlined" />}
                  />
                {search.length > 0 && (
                  <span
                    className="cancel-icon"
                    onClick={() => {
                      setLgp("");
                    }}
                  >
                    {" "}
                    y{" "}
                  </span>
                )}
            </Grid>

            <Grid item style={{ width: 150, marginRight: 3 }}>
              <div className="dateLabel"> Position: </div>
              <Autocomplete
                    id="combo-box-demo"
                    value={positionId}
                    classes={classes}
                    // onChange={(e) => handlePhelboId(e)}
                    onChange={(event, newValue) => {
                      handlePosition(newValue);
                    }}
                    options={position}
                    getOptionLabel={(option) => `${option.name}`}
                    style={{ width: 150 }}
                    renderInput={(params) => <TextField {...params}  variant="outlined" />}
                  />
                {search.length > 0 && (
                  <span
                    className="cancel-icon"
                    onClick={() => {
                      setPositionId('');
                    }}
                  >
                    {" "}
                    x{" "}
                  </span>
                )}
            </Grid>

            <Grid item style={{ width: 150, marginRight: 3 }}>
              <div className="dateLabel"> More Filters: </div>
              <Autocomplete
                    id="combo-box-demo"
                    value={positionId}
                    classes={classes}
                    onChange={(event, newValue) => {
                      handlePosition(newValue);
                    }}
                    options={orders}
                    getOptionLabel={(option) => `${option.name}`}
                    style={{ width: 150 }}
                    renderInput={(params) => <TextField {...params}  variant="outlined" />}
                  />
                {search.length > 0 && (
                  <span
                    className="cancel-icon"
                    onClick={() => {
                      setPositionId('');
                    }}
                  >
                    {" "}
                    x{" "}
                  </span>
                )}
            </Grid>
            
            <Grid item style={{ width: 200,marginTop:"12px" ,marginRight: 3 }}>
            <TextField
              size="small"
              label="Search By Name"
              variant="outlined"
              value={searchField}
              onChange={(e) => handleSearch(e)}
            />
            </Grid>
              <div>
                   <p style={{cursor:'pointer',display:"flex", alignItems:'center'}} onClick={onReload}><Refresh />Reload</p>
                   <p style={{fontSize:'12px'}}>Last Reload at {reload}</p>
              </div>
         
          </Grid>
          <Grid container spacing={3} style={{ marginBottom: 5 }}>
            <Grid item md="2" style={{ width: "95%", padding: 0 }}>
              <Grid item style={{ marginBottom: 5 }}>
                <ButtonGroup
                  color="secondary"
                  aria-label="outlined secondary button group"
                  style={{ justifyContent: "center", width: "95%" }}
                >
                  <Button
                    style={{ width: "100%" }}
                    className={filterType === "P" ? classes.selected : ""}
                    onClick={() => {
                      setUserType(1)
                      setFilterType("P")}
                    }
                  >
                    Phlebo
                  </Button>
                  <Button
                    style={{ width: "100%" }}
                    className={filterType === "R" ? classes.selected : ""}
                    onClick={() =>{
                      setUserType(1)
                      setFilterType("R")
                    }}
                  >
                    Runner
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid item style={{ width: "95%", marginBottom: 5 }}>
                <div className="dateLabel"> Filter City: </div>
                <Select
                  size="small"
                  style={{ width: "94%" }} //fullWidth
                  value={city}
                  onChange={(e) => handleCityFilter(e)}
                >
                  {(cities || []).map((city, index) => (
                    <option key={`city-${index}`} value={city}>
                      {" "}
                      {city}{" "}
                    </option>
                  ))}
                </Select>
                {city && (
                  <span
                    onClick={() => {
                      setCity("");
                      setPhleboLocations([]);
                      setPhleboId([])
                      setPhleboNames([])
                    }}
                  >
                    X
                  </span>
                )}
              </Grid>
              <div className="search-clinic search-orders pr">
                <span className="fa fa-search" />
                <Autocomplete
                    id="combo-box-demo"
                    value={phleboNames[0]}
                    classes={classes}
                    onChange={(event, newValue) => {
                      handlePhelboId(newValue);
                    }}
                    options={phleboNames}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    style={{ width: 180, marginTop:'20px' }}
                    renderInput={(params) => <TextField {...params} label="Select Phelbo" variant="outlined" />}
                  />
                {search.length > 0 && (
                  <span
                    className="cancel-icon"
                    onClick={() => {
                      setSearch("");
                      setPhleboLocations([]);
                    }}
                  >
                    {" "}
                    x{" "}
                  </span>
                )}
              </div>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
                style={{
                  maxHeight: 500,
                  overflowY: "auto",
                }}
              >
                {(phlebos || []).map((el, index) => (
                  <>
                    <ListItem key={index} alignItems="flex-start">
                      <ListItemText
                        primary={el.pname}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              Lat: {el.latitude}, Lng: {el.longitude}, PNo:{" "}
                              {el.pno}, Position: {el.position}, Add:{" "}
                              {el.address}, Time: {getDateTime(el.time)}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider component="li" />
                  </>
                ))}
                {phlebos.length === 0 ? (
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      No Data Found
                    </Typography>
                  </React.Fragment>
                ) : null}
              </List>
            </Grid>
            
            <Grid item md="10">
              <MapContainer phleboLocations={phleboLocations} zoom={10} sockKey={sockKey} latLang={latLang[cityMap]?latLang[cityMap]:{ lat: 12.9716, lng: 77.5946}} width={"100%"} />
            </Grid>
          </Grid>
        </React.Fragment>
      </div>
    </DocContainer>
  );
};

const mapStateToProps = ({ runner, plebos, constants, lgp }) => {
  return {
    allplebos: plebos.plebostracking,
    allcities: constants.cities,
    lgps: lgp.plebos,
    allrunners: runner.runner,
    plebos: plebos.plebos,
    locations:plebos.locations
  };
};

export default withRouter(connect(mapStateToProps)(Track));
