import { call, put } from 'redux-saga/effects';

import {
    postDataWithToken,
    getDataWithToken,
    deleteDataWithToken,

    ADD_PLEBO_URL,
    GET_PLEBO_URL,
    GET_PLEBO_TRACKING_URL,
    GET_PLEBO_BY_ID_URL,
    EDIT_PLEBO_URL,
    DEL_PLEBO_URL,

    GET_PLEBO_KYC_URL,
    POST_PlEBO_KYC_URL,
    DELETE_PLEBO_KYC_URL,
    UPDATE_PHLEBO_AREA_URL,
    GET_PHLEBO_AREA_URL,
    ADD_ATTENDANCE_URL,
    GET_ATTENDANCE_URL,
    REMOVE_PHLEBO_MAPPING_URL,
    GET_LOCATION_URL
} from 'Utils/api';

import {
    IS_SUBMITTING,
    PLEBO_LIST,
    PLEBO_TRACKING_LIST,
    PLEBO_KYC_LIST,
    ATTENDANCE_LIST,
    UPDATE_PHLEBO_AREA,
    GET_PHLEBO_MAPPING_DATA,
    PHLEBO_MAPPING_DATA,
    REMOVE_PHLEBO_MAPPING,
    TOAST_ERROR,
    TOAST_SUCCESS
} from 'Constants/actionConstants';
import { useDispatch } from 'react-redux';
import { ADD_PHELBE_MOBILE_ERROR } from 'Constants/actionConstants';
import { LOCATION_LIST } from 'Constants/actionConstants';

export function* getPlebos(action) {
    try{
        const url = action.data && action.data.phleboId ? GET_PLEBO_BY_ID_URL : GET_PLEBO_URL;
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(getDataWithToken, url, action.data);

        yield put({
            type: PLEBO_LIST,
            data: data && data.phlebos ? data.phlebos : [],
            hasMore: data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false
        })

        yield put({ type : IS_SUBMITTING, isSubmitting: false });

        if (action.onSuccess) {
            action.onSuccess(data);
        }

    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* getlocations(action) {
    if(action.data.city){
    try{
        const url = action.data.city?GET_LOCATION_URL:'';
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(getDataWithToken, url, action.data);

        yield put({
            type: LOCATION_LIST,
            data: data && data.list ? data.list : [],
            hasMore: data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false
        })

        yield put({ type : IS_SUBMITTING, isSubmitting: false });

        if (action.onSuccess) {
            action.onSuccess(data);
        }

    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}
}

export function* getPlebosTracking(action) {
    try{
        const url = GET_PLEBO_TRACKING_URL;
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(getDataWithToken, url, action.data);

        yield put({
            type: PLEBO_TRACKING_LIST,
            data: data && data.list ? data.list : [],
            hasMore: data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false
        })

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}


export function* addPlebos(action) {
    try{
        const url = ADD_PLEBO_URL;
        yield put({ type : IS_SUBMITTING, isSubmitting: true });
        const data = yield call(postDataWithToken, url, action.data);
        yield put({ type : IS_SUBMITTING, isSubmitting: false });

        yield put({
            type: TOAST_SUCCESS,
            message: data && data.message?data.message : "Data updated successfully!"
        })

        if (action.onSuccess) {
            action.onSuccess(data);
        }
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
        yield put({
            type:ADD_PHELBE_MOBILE_ERROR,
            data:e && e.error
        })
    }
}

export function* deletePlebos(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(deleteDataWithToken, DEL_PLEBO_URL, action.data);

        yield put({
            type: TOAST_SUCCESS,
            message: data && data.message?data.message : "Data updated successfully!"
        })

        if (action.onSuccess) {
            action.onSuccess(data);
        }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* getPlebosKYC(action) {
    try{
        const url = GET_PLEBO_KYC_URL;
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(getDataWithToken, url, action.data);

        yield put({
            type: PLEBO_KYC_LIST,
            data: data && data.documents ? data.documents : [],
            hasMore: data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false
        })

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* addPlebosKYC(action) {
    try{
        const url = POST_PlEBO_KYC_URL;
        yield put({ type : IS_SUBMITTING, isSubmitting: true });
        const data = yield call(postDataWithToken, url, action.data);
        yield put({ type : IS_SUBMITTING, isSubmitting: false });

        yield put({
            type: TOAST_SUCCESS,
            message: data && data.message?data.message : "Data updated successfully!"
        })

        if (action.onSuccess) {
            action.onSuccess(data);
        }
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* deletePlebosKYC(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(deleteDataWithToken, DELETE_PLEBO_KYC_URL, action.data);

        yield put({
            type: TOAST_SUCCESS,
            message: data && data.message?data.message : "Data updated successfully!"
        })

        if (action.onSuccess) {
            action.onSuccess(data);
        }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}



export function* addAttendance(action) {
    try{
        const url = ADD_ATTENDANCE_URL;
        yield put({ type : IS_SUBMITTING, isSubmitting: true });
        const data = yield call(postDataWithToken, url, action.data);
        yield put({ type : IS_SUBMITTING, isSubmitting: false });

        yield put({
            type: TOAST_SUCCESS,
            message: data && data.message?data.message : "Data updated successfully!"
        })

        if (action.onSuccess) {
            action.onSuccess(data);
        }
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}


export function* updatePhleboArea(action) {
    try{
        const url = UPDATE_PHLEBO_AREA_URL;
        yield put({ type : IS_SUBMITTING, isSubmitting: true });
        const data = yield call(postDataWithToken, url, action.data);
        yield put({ type : IS_SUBMITTING, isSubmitting: false });

        yield put({
            type: TOAST_SUCCESS,
            message: data && data.message?data.message : "Data updated successfully!"
        })

        if (action.onSuccess) {
            action.onSuccess(data);
        }
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* getAttendance(action) {
    try{
        const url = GET_ATTENDANCE_URL;
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(getDataWithToken, url, action.data);

        yield put({
            type: ATTENDANCE_LIST,
            data: data && data.list ? data.list : [],
            hasMore: data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false
        })

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}


export function* getPhleboMappingData(action) {
    try{
        const url = GET_PHLEBO_AREA_URL;
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(getDataWithToken, url, action.data);

        yield put({
            type: PHLEBO_MAPPING_DATA,
            data: data && data.list ? data.list : []
        })

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}


export function* removePhleboMapping(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(deleteDataWithToken, REMOVE_PHLEBO_MAPPING_URL, action.data);

        yield put({
            type: TOAST_SUCCESS,
            message: data && data.message?data.message : "Data updated successfully!"
        })

        if (action.onSuccess) {
            action.onSuccess(data);
        }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        console.error(e);
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}