/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './clinics.css';

import DocContainer from './../../containers/DocContainer';
import ActionLoader from "../../components/ActionLoader";
import LabTests  from './labTests'
import LabUsers  from './labUsers'

import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	Dialog, DialogTitle, DialogContent, DialogActions,
	Select,
	Paper,
	Button,
	Grid,
	TextField,
	IconButton,
	InputLabel,
	Typography,
	Tabs,
	Tab,
	Box,
	MenuItem,
	FormControl,
	FormHelperText
} from '@material-ui/core';

import {
	GET_LABS,
	ADD_LAB,
	DELETE_LAB,

	GET_ALL_STATES,
	GET_ALL_CONSTANTS,

} from '../../constants/actionConstants';
import { Edit, Clear, Visibility, ArrowBackOutlined } from '@material-ui/icons';
import { GET_LAB_TEST_BY_LABID } from 'Constants/actionConstants';



function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`nav-tabpanel-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</Typography>
	);
}


class Labs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			offset: 0,
			searchText: '',
			isError: false,
			isModalOpen: false,

			tab: 1,
			data: {
				//labId: "",
				name: "",
				contactPersonName: "",
				contactPersonMobile: "",
				contactPersonEmail: "",
				city: "",
				branchName: "",
				state: ""
			}
		};
	}

	UNSAFE_componentWillMount() {
	
		if(this.props.match.params.labId){

			this.getLabTests()
			
		}
		else{
			this.getLabs();
	
		}
		this.getAllConstants();
		this.getAllStates();

	}
	getAllConstants = () => {
		const { dispatch } = this.props;
		dispatch({
			type: GET_ALL_CONSTANTS
		});
	}

	getAllStates = () => {
		const { dispatch } = this.props;
		dispatch({
			type: GET_ALL_STATES
		});
	}

	resetPopup = () => {
		let { data } = this.state;
		data = {
			//	labId: "",
			name: "",
			contactPersonName: "",
			contactPersonMobile: "",
			contactPersonEmail: "",
			city: "",
			branchName: "",
			state: ""
		}
		this.setState({ data, isError: false });
	}



	

	getLabs = (id) => {
		const { dispatch } = this.props;
		let data = {
			count: 10,
			offset: this.state.offset
		};
		if (id) {
			data = { labId: id };
		}
		if (this.state.searchText && this.state.searchText.length > 0) {
			data['text'] = this.state.searchText;
		}

		dispatch({
			type: GET_LABS,
			data,
		});

	}

	addLabs = (dataSet) => {
		const { dispatch, match } = this.props;
		dispatch({
			type: ADD_LAB,
			data: dataSet ? dataSet : { ...this.state.data, },
			onSuccess: (() => {
				this.setState({ isModalOpen: false });
				if (!match.params.id) {
					this.resetPopup();
					this.getLabs();
				}
			})
		});
	}

	deleteLab = (id) => {
		const { dispatch } = this.props;
		dispatch({
			type: DELETE_LAB,
			data: { labId: id },
			onSuccess: (() => {
				this.getLabs();
				this.setState({ isDeleteOpen: false, deleteObj: null })
			})
		});
		
	}




	handleFormChange = (e, key) => {
		const { data } = this.state;
		data[key] = e.target.value;
		this.setState({ data });
	}

	validateData = () => {
		const { data } = this.state;
		if (data.name === '' || data.contactPersonName === '' || data.contactPersonMobile === '' || data.contactPersonEmail === '' || data.city === ''
			|| data.branchName === '' || data.state === '') {
			this.setState({ isError: true });
		} else {
			this.addLabs();
			this.setState({ isError: false });

		}
	}

	renderPagination = (isLabTest) => {
		return (
			<div className="mt-30">
				<Grid container spacing={5}>
					<Grid item xs={9} />
					<Grid item xs={3}>
						<Button
							style={{ marginRight: '20px' }}
							size="medium"
							color="primary"
							variant="outlined"
							disabled={this.state.offset === 0}
							onClick={() => {
								this.setState({ offset: this.state.offset - 10 }, () => {

									if (isLabTest) {
										this.getLabTests();
									} else {
										this.getLabs();
									}

								})
							}}
						>
							{'< Previous'}
						</Button>
						<Button
							size="medium"
							color="primary"
							variant="outlined"
							disabled={!this.props.hasMore}
							onClick={() => {
								this.setState({ offset: this.state.offset + 10 }, () => {

									if (isLabTest) {
										this.getLabTests();
									} else {
										this.getLabs();
									}
								})
							}}
						>
							{'Next >'}
						</Button>
					</Grid>
				</Grid>
			</div>
		)
	}

	renderFormFields = (data) => {
		const { isError } = this.state;
		return (
			<React.Fragment>
				<Grid container spacing={3}>
					<Grid item xs={6}>
						<TextField fullWidth label="Lab Name"
							value={data.name}
							onChange={(e) => this.handleFormChange(e, 'name')}
							error={isError && data.name === ""}
							helperText={(isError && data.name === "") ? 'Please enter a lab name' : ' '}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField fullWidth label="Contact Person Name"
							value={data.contactPersonName}
							onChange={(e) => this.handleFormChange(e, 'contactPersonName')}
							error={isError && data.contactPersonName === ""}
							helperText={(isError && data.contactPersonName === "") ? 'Please enter a contact person name' : ' '}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={3}>
					<Grid item xs={6}>
						<TextField fullWidth label="Contact Person Email"
							value={data.contactPersonEmail}
							onChange={(e) => this.handleFormChange(e, 'contactPersonEmail')}
							error={isError && data.contactPersonEmail === ""}
							helperText={(isError && data.contactPersonEmail === "") ? 'Please enter a contact person email' : ' '}
						/>
					</Grid>

					<Grid item xs={6}>
						<TextField fullWidth label="Contact Person Mobile"
							value={data.contactPersonMobile}
							onChange={(e) => this.handleFormChange(e, 'contactPersonMobile')}
							error={isError && data.contactPersonMobile === ""}
							helperText={(isError && data.contactPersonMobile === "") ? 'Please enter a contact person mobile' : ' '}
						/>
					</Grid>

				</Grid>

				<Grid container spacing={3}>

					<Grid item xs={6}>

						<FormControl fullWidth error={isError && data.city === ''}>
							<InputLabel >City</InputLabel>
							<Select

								value={data.city}
								onChange={(e) => { this.handleFormChange(e, 'city') }}

							>

								{(this.props.cities || []).map((city, index) => (
									<MenuItem key={`city-${index}`} value={city}> {city} </MenuItem>
								))}

							</Select>
							<FormHelperText hidden={!(isError && data.city != '')}>Please select a city</FormHelperText>
						</FormControl>




					</Grid>

					<Grid item xs={6}>


						<FormControl fullWidth error={isError && data.state === ''}>
							<InputLabel >State</InputLabel>
							<Select

								value={data.state}
								onChange={(e) => { this.handleFormChange(e, 'state') }}

							>

								{(this.props.states || []).map((state, index) => (
									<MenuItem key={`state-${state.id}`} value={state.name}> {state.name} </MenuItem>
								))}

							</Select>
							<FormHelperText hidden={!(isError && data.state != '')}>Please select a state</FormHelperText>
						</FormControl>



					</Grid>
				</Grid>

				<Grid container spacing={3}>

					<Grid item xs={6}>
						<TextField fullWidth label="Branch Name"
							value={data.branchName}
							onChange={(e) => this.handleFormChange(e, 'branchName')}
							error={isError && data.branchName === ""}
							helperText={(isError && data.branchName === "") ? 'Please enter a branch name' : ' '}
						/>
					</Grid>

				</Grid>
			</React.Fragment>
		)
	}

	renderPopup = () => {
		const { isModalOpen, data } = this.state
		return (
			<Dialog disableBackdropClick open={isModalOpen} onClose={() => { this.setState({ isModalOpen: false }) }}>
				<DialogTitle id="clinic-popup">{data.isEdit ? 'Edit Lab' : 'Add New Lab'}</DialogTitle>
				<DialogContent style={{ width: '600px', height: '260px' }}>
					{this.renderFormFields(data)}
				</DialogContent>
				<DialogActions>
					<Button style={{ backgroundColor: "#00C9AA" }} onClick={this.validateData} variant="contained" color="primary">Save</Button>
					<Button style={{ borderColor: "#00C9AA", color: "#00C9AA" }} onClick={() => { this.setState({ isModalOpen: false }); this.resetPopup() }} variant="outlined" color="primary">Close</Button>
				</DialogActions>
			</Dialog>
		)
	}
	

	redirectToDetail = (obj) => {
		const { match, history } = this.props;
		this.setState({ 
			offset: 0, 
			data: {
				...obj, 
				isEdit: true
			} 
		}, () => {
			this.getLabTests();
		})
		history.push(`/labs/${obj.labId}`, { state: obj });
	}

	getLabTests = () => {
		const { dispatch, match } = this.props;
		let data = {
			count: 10,
			offset: this.state.offset,
			labId: match.params.labId
		};

		dispatch({
			type: GET_LAB_TEST_BY_LABID,
			data,
		});
	}




	renderDeletePopup = () => {
		const { isDeleteOpen, data, deleteObj } = this.state
		return (
			<Dialog disableBackdropClick open={isDeleteOpen} onClose={() => { this.setState({ isDeleteOpen: false }) }}>
				<DialogTitle id="clinic-popup">Confirm Delete</DialogTitle>
				<DialogContent > Are you sure you want to delete {deleteObj.name} ? </DialogContent>
				<DialogActions>
					<Button style={{ backgroundColor: "#00C9AA" }} onClick={() => { this.deleteLab(deleteObj.labId) }} variant="contained" color="primary">Yes</Button>
					<Button style={{ borderColor: "#00C9AA", color: "#00C9AA" }} onClick={() => { this.setState({ isDeleteOpen: false, deleteObj: null }) }} variant="outlined" color="primary">No</Button>
				</DialogActions>
			</Dialog>
		)
	}

	
	redirectToList = () => {
		const { match, history } = this.props;
		this.setState({ offset: 0 }, () => {
			this.getLabs();
		})
		this.resetPopup();
		history.push(`/labs`);
	}

	
	renderTabContainer = (labId,isLabTest) => {

		return (
			<LabTests labTests={this.props.labTests} renderPagination={this.renderPagination} 
			labId={labId} getLabTests={this.getLabTests} {...this.props}/>
		)
	}

	renderLabUsersTabContainer = (labId) => {

		return (
			<LabUsers renderPagination={this.renderPagination} 
			labId={labId} {...this.props}/>
		)
	}
	
	render() {
		const { labs, isLoading, match } = this.props;
		const { isDeleteOpen, isOpen, isModalOpen, } = this.state;
		let showLabDetails = false;
		if (match.params && match.params.labId) {
			showLabDetails = true;
		}
		
		return (
			<DocContainer>
				<div className="mr-20">
					{isLoading && <ActionLoader />}
					{!showLabDetails ?
					<React.Fragment>
						<Grid item xs={2}>
								<h2 className="mt-0">Labs</h2>
							</Grid>
						<Grid container spacing={2}>
							
							<Grid item xs={4}>
							<span className="fa fa-search" />
									<TextField
									fullWidth
										size="small"
										variant="outlined"
										placeholder="Search…"
										value={this.state.searchText}
										onChange={(e) => { this.setState({ searchText: e.target.value }) }}
										onKeyPress={(e) => {
											if ((e.which == 13 || e.keyCode == 13)) {
												this.getLabs();
											}
										}}
									/>
									{this.state.searchText.length > 0 &&
										<span className="cancel-icon"
											onClick={() => { this.setState({ searchText: '' }); this.getLabs(); }}> x </span>
									}
							
							</Grid>
							{/* <Grid item xs={6} /> */}
							<Grid item xs={4}>
							<Button style={{ backgroundColor: "#0D8DA3" }} variant="contained" color="primary" onClick={() => { this.setState({ isModalOpen: !this.state.isModalOpen }) }}>
									Add Lab
							  </Button>
							</Grid>
						</Grid>

						<TableContainer component={Paper} className="mt-30">
							<Table size="small" aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>Name</TableCell>
										<TableCell>Contact Person Name</TableCell>
										<TableCell>Contact Person Mobile</TableCell>
										<TableCell>Contact Person Email</TableCell>
										<TableCell>City</TableCell>
										<TableCell>Branch Name</TableCell>
										<TableCell>State</TableCell>
										<TableCell>Action</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{((labs || []).length <= 0) &&
										<TableRow>
											<TableCell component="th" scope="row"> No Labs found. </TableCell>
										</TableRow>
									}
									{(labs || []).map(obj => (
										<TableRow key={obj.labId}>

											<TableCell>{obj.name}</TableCell>
											<TableCell>{obj.contactPersonName}</TableCell>
											<TableCell>{obj.contactPersonMobile}</TableCell>
											<TableCell>{obj.contactPersonEmail}</TableCell>
											<TableCell>{obj.city}</TableCell>
											<TableCell>{obj.branchName}</TableCell>
											<TableCell>{obj.state}</TableCell>

										

											<TableCell>
												<div style={{ width: '75px' }}>
												<IconButton edge="end" color="inherit" size="small" onClick={() => { this.redirectToDetail(obj) }}>
															<Visibility />
														</IconButton>
													<IconButton edge="end" color="inherit" size="small" onClick={() => { this.setState({ isModalOpen: true, data: { ...obj, isEdit: true } }) }}>
														<Edit />
													</IconButton>
													<IconButton edge="end" color="inherit" size="small" onClick={() => { this.setState({ isDeleteOpen: true, deleteObj: obj }) }}>
														<Clear />
													</IconButton>
												</div>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>

						{this.renderPagination()}

						{isModalOpen && this.renderPopup()}

						{isDeleteOpen && this.renderDeletePopup()}
					</React.Fragment>

:
<React.Fragment>
	<Grid container spacing={3}>
		<IconButton edge="end" color="inherit" onClick={() => { this.redirectToList() }}>
			<ArrowBackOutlined />
		</IconButton>
		<Grid item xs={4}> <h2 className="mt-0">LAB DETAILS</h2> </Grid>
	</Grid>
	<Tabs value={this.state.tab} onChange={(e, tab) => this.setState({ tab })}>
		<Tab label='Lab Tests' value={1} />
		<Tab label='Users' value={2} />
	</Tabs>
	<TabPanel value={this.state.tab} index={1}>
		{this.renderTabContainer(match.params.labId,true)}
	</TabPanel>
	<TabPanel value={this.state.tab} index={2}>
		{this.renderLabUsersTabContainer(match.params.labId,true)}
	</TabPanel>
</React.Fragment>
	}

				</div>
			</DocContainer>
		);
	}
}

const mapStateToProps = ({ labs, constants }) => {
	return {
		isLoading: labs.isSubmitting,
		labs: labs.labs,
		hasMore: labs.hasMore,
		labTests:labs.labTests,
		cities: constants.cities,
		states: constants.states,

	}
};

export default withRouter(connect(mapStateToProps)(Labs));
