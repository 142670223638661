import { 
  ROLES_LIST,

  IS_SUBMITTING,
} from 'Constants/actionConstants';


const roles = (state={
  
  isSubmitting: false,
  roles: [],

}, action)=> {

  switch (action.type) {
    
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };

    case ROLES_LIST:
      return {
        ...state,
        roles: action.data,
        hasMore: action.hasMore,
      };

    default:
      return state;
  }

}

export default roles;
