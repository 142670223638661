import { 
    INVENTORY_LIST,
  
    IS_SUBMITTING,
  } from 'Constants/actionConstants';
  
  
  const inventory = (state={
    
    isSubmitting: false,
    inventory: [],
  
  }, action)=> {
  
    switch (action.type) {
      
      case IS_SUBMITTING:
        return {
          ...state,
          isSubmitting: action.isSubmitting,
        };
  
      case INVENTORY_LIST:
      return {
          ...state,
          inventory: action.data,
          hasMore: action.hasMore,
        };
  
      default:
        return state;
    }
  
  }
  
  export default inventory;
  