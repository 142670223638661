import { combineReducers } from 'redux';

import constants from 'Reducers/constants';
import common from 'Reducers/common';
import authentication from 'Reducers/authentication';
import plebos from 'Reducers/plebos';
import tRoutes from 'Reducers/tRoutes';
import runner from 'Reducers/runner';
import lgp from 'Reducers/lgp';
import orders from 'Reducers/orders';
import labs from 'Reducers/labs';
import inventory from 'Reducers/inventory';
import reports from 'Reducers/reports';
import zones from 'Reducers/zones';
import rosters from 'Reducers/rosters';
import users from 'Reducers/users';
import roles from 'Reducers/roles';
import dept from 'Reducers/dept';
import trips from 'Reducers/trips';
import vmer from 'Reducers/vmer';



const rootReducer = combineReducers({
  constants,
  common,
  authentication,
  plebos,
  tRoutes,
  runner,
  lgp,
  orders,
  vmer,
  labs,
  inventory,
  reports,
  zones,
  rosters,
  dept,
  roles,
  users,
  trips,
});

export default rootReducer;
