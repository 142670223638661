/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DocContainer from "./../../containers/DocContainer";
import {
  Select,
  Button,
  MenuItem,
  Chip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@material-ui/core";
import {
  Refresh,
} from "@material-ui/icons";

import "./trip.css";
import TripTable from "./TripTable";
import { GET_TRIPS, SET_TRIPS } from "./../../constants/actionConstants";
import { getUser } from "Utils/storage";

import {
  GET_ALL_CONSTANTS,
  GET_PLEBOS_ATTENDANCE,
  GET_ALL_ZONES,
  GET_ALL_TRIPS,
  GET_ALL_TRIPS_BY_NUM,
  GET_ALL_REVIEW_ORDERS,
  ASSIGN_PLEBOS_ROUTE,
  TOAST_ERROR,
  TOAST_SUCCESS,
  UPDATE_ORDER,
  GET_ORDER_DATA,
  SUFFLE_ORDERS,
  CREATE_ROUTE,
} from "../../constants/actionConstants";

import DatePicker from "react-datepicker";

import moment from "moment";
import RosterMapping from "../../components/RosterMapping";

const Trips = (props) => {
  const [tab, setTab] = useState(1);
  const [stops, setStops] = useState([]);

  const [reorderDetails, setReorderDetails] = useState(null);
  const [showConfirmReorder, setShowConfirmReorder] = useState(false);

  const [showPublishOpen, setShowPublishOpen] = useState(false);
  const [summary, setSummary] = useState({
    totalRoutes: 0,
    publishedRoutes: 0,
    assignedRoutes: 0,
    leaveRequestCount: 0,
    unknownCount: 0,
    totalCount: 0,
    workOffCount: 0,
    workingCount: 0,
  });

  const [allTrips, setAllTrips] = useState([]);
  const [allReviewOrders, setAllReviewOrders] = useState([]);
  const [assignFilter, setAssignFilter] = useState(1);
  const [publishFilter, setPublishFilter] = useState(1);
  const [numberType, setNumberType] = useState(0);

  const [city, setCity] = useState(
    localStorage.getItem("routePlanCity")
      ? localStorage.getItem("routePlanCity")
      : ""
  );
  const [dateFilter, setDateFilter] = useState(
    localStorage.getItem("routePlanDateFilter")
      ? new Date(JSON.parse(localStorage.getItem("routePlanDateFilter")))
      : new Date()
  );
  const [filterZone, setFilterZone] = useState(
    JSON.parse(localStorage.getItem("routePlanFilterZone")) || ""
  );
  const [vendorId, setVendorId] = useState("");
  const [customerContact, setCustomerCoantact] = useState("");
  const [orderSeq, setOrderSeq] = useState("");
  const [filterPhleobs, setFilterPhleobs] = useState(
    JSON.parse(localStorage.getItem("routePlanFilterPhlebos")) || ""
  );
  const [allPhlebos, setAllPhlebos] = useState([]);


  useEffect(() => {
    getAllConstants();
  }, []);

  useEffect(() => {
    localStorage.removeItem("routePlanFilterPhlebos");
    setFilterPhleobs("");
    localStorage.removeItem("routePlanFilterZone");
    setFilterZone("");
    getAllZones();
    getPlebos();
  }, [city]);

  useEffect(() => {
    localStorage.removeItem("routePlanFilterPhlebos");
    setFilterPhleobs("");
    getPlebos();
  }, [filterZone]);

  useEffect(() => {
    localStorage.removeItem("routePlanFilterPhlebos");
    setFilterPhleobs("");
  }, [dateFilter]);

  useEffect(() => {
    const data = {
      city: "Bangalore",
      month: 12,
      date: 14,
      year: 2021,
    };
    props.dispatch({
      type: GET_TRIPS,
      data,
    });
  }, []);

   const getAllConstants = () => {
    const { dispatch } = props;
    dispatch({
      type: GET_ALL_CONSTANTS,
    });
  };

  const getAllZones = () => {
    const { dispatch } = props;
    const data = {
      city: city,
      count: 100,
      offset: 0,
      text: "",
    };
    dispatch({
      type: GET_ALL_ZONES,
      data,
    });
  };

  const getPlebos = (filterData = false) => {
    const { dispatch } = props;
    let data = {
      ts: dateFilter.getTime(),
      date: moment(dateFilter).date(),
      month: 1 + moment(dateFilter).month(),
      year: moment(dateFilter).year(),
    };

    if (city) {
      data["city"] = city;
    }

    if (filterZone) {
      data["zoneId"] = filterZone.join(",");
    }

    if (filterData) {
      data = { ...data, ...filterData };
    }

    dispatch({
      type: GET_PLEBOS_ATTENDANCE,
      data,
      onSuccess: (res) => {
        setAllPhlebos(res.list || []);
        setSummary({
          ...summary,
          leaveRequestCount: res.leaveRequestCount,
          unknownCount: res.unknownCount,
          totalCount: res.totalCount,
          workOffCount: res.workOffCount,
          workingCount: res.workingCount,
        });
      },
      onFailure: (res) => {
        //
      },
    });
  };

  const getAllTrips = (filter = {}) => {
    const { dispatch } = props;

    let data = {
      offset: 0,
      count: 1000,
      isReview: false,
    };

    if (filter.date || dateFilter) {
      data["month"] = moment(filter.date || dateFilter).month() + 1;
      data["date"] = moment(filter.date || dateFilter).date();
      data["year"] = moment(filter.date || dateFilter).year();
    }

    if (filter.city || city) {
      data["city"] = filter.city || city;
    }

    if (filter.zoneId || filterZone) {
      data["zoneId"] = filter.zoneId || filterZone;
    }

    if (filter.phlebos || filterPhleobs) {
      data["phlebos"] = (filter.phlebos || filterPhleobs).join(",");
    }

    if (filter.assign || assignFilter) {
      data["assign"] = filter.assign || assignFilter;
    }

    if (filter.publish || publishFilter) {
      data["publish"] = filter.publish || publishFilter;
    }

    dispatch({
      type: GET_ALL_TRIPS,
      isPartner: getUser().userType === 2,
      data,
      onSuccess: (data) => {
        setAllTrips(data.list || []);
        setSummary({
          ...summary,
          assignedRoutes: data.assignedRoutes || 0,
          publishedRoutes: data.publishedRoutes || 0,
          totalRoutes: data.totalRoutes || 0,
        });
      },
    });
  };


  const getAllTripsByNum = (filter = {}) => {
    const { dispatch } = props;

    let data = {
      offset: 0,
      count: 1000,
      isReview: false,
    };

    if (filter.date || dateFilter) {
      data["month"] = moment(filter.date || dateFilter).month() + 1;
      data["date"] = moment(filter.date || dateFilter).date();
      data["year"] = moment(filter.date || dateFilter).year();
    }

    if (filter.city || city) {
      data["city"] = filter.city || city;
    }

    if (filter.customerContact || customerContact) {
      data["customerContact"] = filter.customerContact || customerContact;
    }

    if (filter.vendorId || vendorId) {
      data["vendorId"] = filter.vendorId || vendorId;
    }

    if (filter.orderSeq || orderSeq) {
      data["orderSeq"] = filter.orderSeq || orderSeq;
    }

    dispatch({
      type: GET_ALL_TRIPS_BY_NUM,
      isPartner: getUser().userType === 2,
      data,
      onSuccess: (data) => {
        setAllTrips(data.list || []);
        setSummary({
          ...summary,
          assignedRoutes: data.assignedRoutes || 0,
          publishedRoutes: data.publishedRoutes || 0,
          totalRoutes: data.totalRoutes || 0,
        });
      },
    });
  };


  const getAllReviewOrders = (filter = {}) => {
    const { dispatch } = props;

    let data = {
      offset: 0,
      count: 1000,
    };

    if (filter.date || dateFilter) {
      data["month"] = moment(filter.date || dateFilter).month() + 1;
      data["date"] = moment(filter.date || dateFilter).date();
      data["year"] = moment(filter.date || dateFilter).year();
    }

    if (filter.city || city) {
      data["city"] = filter.city || city;
    }

    if (filter.zoneId || filterZone) {
      data["zoneId"] = filter.zoneId || filterZone;
    }

    if (filter.assign || assignFilter) {
      data["assign"] = filter.assign || assignFilter;
    }

    if (filter.publish || publishFilter) {
      data["publish"] = filter.publish || publishFilter;
    }

    dispatch({
      type: GET_ALL_REVIEW_ORDERS,
      isPartner: getUser().userType === 2,
      data,
      onSuccess: (data) => {
        setAllReviewOrders(data.list || []);
      },
    });
  };

  const updatePlebo = (data) => {
    const { dispatch } = props;
    if (!city) {
      dispatch({
        type: TOAST_ERROR,
        message: "Please select city",
      });
      return;
    }
    const pleboDetails = allPhlebos.find((el) => el.phleboId === data.phleboId);
    assignPleboRoute({
      routeId: data.id,
      type: 1,
      phleboId: data.phleboId || "",
      firstName: pleboDetails.firstName || "",
      lastName: pleboDetails.lastName || "",
      mobileNumber: pleboDetails.mobileNumber || "",
    });
  };

  const unAssignPlebo = (data) => {
    const { dispatch } = props;
    if (!city) {
      dispatch({
        type: TOAST_ERROR,
        message: "Please select city",
      });
      return;
    }
    assignPleboRoute({
      routeId: data.id,
      phleboId: data.phleboId,
      type: 2,
    });
  };

  const getOrderById = (orderId) => {
    return new Promise((res, rej) => {
      const { dispatch } = props;
      dispatch({
        type: GET_ORDER_DATA,
        data: {
          orderId,
        },
        isPartner: getUser().userType === 2,
        onSuccess: (data) => {
          res(data);
        },
        onFailure: () => {
          dispatch({
            type: TOAST_ERROR,
            message: "Order not found",
          });
          rej(null);
        },
      });
    });
  };

  const updateOrder = (data) => {
    const { dispatch } = props;
    const dataParams = {
      ...data,
    };
    dispatch({
      type: UPDATE_ORDER,
      data: dataParams,
      onSuccess: () => {
        dispatch({
          type: TOAST_SUCCESS,
          message: "Order update Success",
        });
        refreshData();
      },
    });
  };

  const updateOrderedData = (data) => {
    if (dateFilter) {
      data["month"] = moment(dateFilter).month() + 1;
      data["date"] = moment(dateFilter).date();
      data["year"] = moment(dateFilter).year();
    }

    if (city) {
      data["city"] = city;
    }

    if (data.isAssignInReviewOrder) {
      confirmReorder(data);
    } else {
      setReorderDetails(data);
      setShowConfirmReorder(true);
    }
  };

  const confirmReorder = (data = false) => {
    const { dispatch } = props;
    const reorderDetailsCopy = JSON.parse(
      JSON.stringify(data ? data : reorderDetails)
    );
    delete reorderDetailsCopy.history;
    dispatch({
      type: SUFFLE_ORDERS,
      isPartner: getUser().userType === 2,
      data: reorderDetailsCopy,
      onSuccess: (data) => {
        dispatch({
          type: TOAST_SUCCESS,
          message: "Success",
        });
        refreshData();
      },
      onFailure: () => {
        dispatch({
          type: TOAST_ERROR,
          message: "Success",
        });
        getAllTrips();
      },
    });
  };

  const createRoute = (data) => {
    const { dispatch } = props;
    data.totalRoutes = allTrips.length;
    dispatch({
      type: CREATE_ROUTE,
      isPartner: getUser().userType === 2,
      data,
      onSuccess: (data) => {
        dispatch({
          type: TOAST_SUCCESS,
          message: "Success",
        });
        refreshData();
      },
      onFailure: () => {
        dispatch({
          type: TOAST_ERROR,
          message: "Success",
        });
        getAllTrips();
      },
    });
  };

  const removeOrder = (routeId, orderId) => {
    const { dispatch } = props;
    if (!orderId) {
      dispatch({
        type: TOAST_ERROR,
        message: "Order id missing",
      });
      return;
    }
    assignPleboRoute({ routeId, type: 3, orderId });
  };

  const publishRoute = (routeId, isAllRoutes = true) => {
    const { dispatch } = props;
    if (!city) {
      dispatch({
        type: TOAST_ERROR,
        message: "Please select city",
      });
      return;
    }
    assignPleboRoute({ routeId, type: 4, isAllRoutes });
  };

  const assignPleboRoute = (data = {}) => {
    const { dispatch } = props;

    if (dateFilter) {
      data["month"] = moment(dateFilter).month() + 1;
      data["date"] = moment(dateFilter).date();
      data["year"] = moment(dateFilter).year();
    }

    if (city) {
      data["city"] = city;
    }

    if (filterZone) {
      data["zoneId"] = filterZone;
    }

    dispatch({
      type: ASSIGN_PLEBOS_ROUTE,
      isPartner: getUser().userType === 2,
      data,
      onSuccess: (data) => {
        dispatch({
          type: TOAST_SUCCESS,
          message: "Success",
        });
        refreshData();
      },
    });
  };

  const refreshData = (data) => {

    if (numberType > 0) {
      getAllTripsByNum(data);
    }else{
      getAllTrips(data);
      getAllReviewOrders(data);
    }
  };

  const VerticalDividerMiddle = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Chip
          style={{ marginRight: "10px" }}
          label="Total Orders : 65"
          color="primary"
        />
        <Chip
          style={{ marginRight: "10px" }}
          label="Completed : 30"
          color="primary"
        />
        <Chip
          style={{ marginRight: "10px" }}
          label="pending : 20"
          color="primary"
        />
        <Chip
          style={{ marginRight: "10px" }}
          label="Cancelled by Phelbo : 5"
          color="primary"
        />
        <Chip label="Cancelled by Customer : 10" color="primary" />
      </div>
    );
  };

  const handleChangeNumber = (e) => {
    if (numberType === 2) {
      localStorage.setItem("routePlanVendorNo", e.target.value);
      setVendorId(e.target.value);
    } else if (numberType === 3) {
      localStorage.setItem("routePlanCustomerNo", e.target.value);
      setCustomerCoantact(e.target.value);
    } else {
      localStorage.setItem("routePlanOrderNo", e.target.value);
      setOrderSeq(e.target.value);
    }

  }

  const renderFilters = () => {
    return (
      <React.Fragment>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "top",
            marginRight: "20px",
            padding: "5px 0",
          }}
        >
          <div className="ordersDate dateFilters">
            <span className="dateLabel mb-10 mr-10">Date: </span>
            <DatePicker
              selected={dateFilter}
              onChange={(date) => {
                localStorage.setItem(
                  "routePlanDateFilter",
                  new Date(date).getTime()
                );
                setDateFilter(date);
                refreshData({ date: date });
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "top",
            marginRight: "20px",
          }}
        >
          <div
            className="dateLabel"
            style={{
              display: "inline-block",
              verticalAlign: "top",
              marginRight: "10px",
              padding: "8px 0",
            }}
          >
            {" "}
            Filter City:{" "}
          </div>
          <div
            style={{
              display: "inline-block",
              verticalAlign: "top",
              minWidth: "120px",
            }}
          >
            <Select
              size="small"
              fullWidth
              value={city}
              onChange={(e) => {
                localStorage.setItem("routePlanCity", e.target.value);
                setCity(e.target.value);
                refreshData({ city: e.target.value });
              }}
            >
              {(props.cities || []).map((city, index) => (
                <MenuItem key={`city-${index}`} value={city}>
                  {city}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "top",
            marginRight: "20px",
            float: "right",
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            size="small"
            style={{ fontSize: "12px" }}
            onClick={() => {
              refreshData();
            }}
          >
            <Refresh /> Refresh
          </IconButton>
        </div>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "top",
            marginRight: "20px",
          }}
        >
          <div
            className="dateLabel"
            style={{
              display: "inline-block",
              verticalAlign: "top",
              marginRight: "10px",
              padding: "8px 0",
            }}
          >
            Filter Zones:
          </div>
          <div
            style={{
              display: "inline-block",
              verticalAlign: "top",
              minWidth: "120px",
            }}
          >
            <Select
              size="small"
              fullWidth
              multiple
              value={filterZone || []}
              onChange={(e) => {
                localStorage.setItem(
                  "routePlanFilterZone",
                  JSON.stringify(e.target.value)
                );
                setFilterZone(e.target.value);
                refreshData({ zone: e.target.value });
              }}
            >
              {(props.zones || []).map((zone, index) => (
                <MenuItem key={`city-${index}`} value={zone.zoneId}>
                  {zone.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div
            className="dateLabel"
            onClick={() => {
              setFilterZone([]);
              refreshData({ zone: [] });
            }}
            style={{
              display: "inline-block",
              verticalAlign: "top",
              marginLeft: "10px",
              padding: "8px 0",
              cursor: "pointer",
            }}
          >
            X
          </div>
        </div>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "top",
            marginRight: "20px",
          }}
        >
          <div
            className="dateLabel"
            style={{
              display: "inline-block",
              verticalAlign: "top",
              marginRight: "10px",
              padding: "8px 0",
            }}
          >
            {" "}
            Filter Phlebos:{" "}
          </div>
          <div
            style={{
              display: "inline-block",
              verticalAlign: "top",
              minWidth: "120px",
            }}
          >
            <Select
              size="small"
              fullWidth
              multiple
              value={filterPhleobs || []}
              onChange={(e) => {
                localStorage.setItem(
                  "routePlanFilterPhlebos",
                  JSON.stringify(e.target.value)
                );
                setFilterPhleobs(e.target.value);
                refreshData({ phlebos: e.target.value });
              }}
            >
              {(allPhlebos || []).map((ph, index) => (
                <MenuItem key={`ph-${index}`} value={ph.phleboId}>
                  {`${ph.firstName} (${ph.response}) (${ph.roster
                    }) (${ph.work_timings || "-"})`}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div
            className="dateLabel"
            onClick={() => {
              setFilterPhleobs([]);
              refreshData({ phlebos: [] });
            }}
            style={{
              display: "inline-block",
              verticalAlign: "top",
              marginLeft: "10px",
              padding: "8px 0",
              cursor: "pointer",
            }}
          >
            X
          </div>
        </div>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "top",
            minWidth: "120px",
            marginRight: 10,
          }}
        >
          <Select
            size="small"
            fullWidth
            value={assignFilter}
            onChange={(e) => {
              setAssignFilter(e.target.value);
              refreshData({ assign: e.target.value });
            }}
          >
            <MenuItem value={1}>All</MenuItem>
            <MenuItem value={2}>Assigned</MenuItem>
            <MenuItem value={3}>Unassigned</MenuItem>
            <MenuItem value={4}>Delay</MenuItem>
          </Select>
        </div>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "top",
            minWidth: "120px",
            marginRight: 10,
          }}
        >
          <Select
            size="small"
            fullWidth
            value={publishFilter}
            onChange={(e) => {
              setPublishFilter(e.target.value);
              refreshData({ publish: e.target.value });
            }}
          >
            <MenuItem value={1}>All</MenuItem>
            <MenuItem value={2}>Published</MenuItem>
            <MenuItem value={3}>Unpublished</MenuItem>
          </Select>
        </div>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "top",
            minWidth: "120px",
            marginRight: 10,
          }}
        >
          <Select
            size="small"
            fullWidth
            value={numberType}
            // placeholder="Select any"
            onChange={(e) => {
              setNumberType(e.target.value);
              // refreshData({ publish: e.target.value });
            }}
          >
            <MenuItem value={0}>Select any</MenuItem>
            <MenuItem value={1}>Order Id No</MenuItem>
            <MenuItem value={2}>Vendor Id No</MenuItem>
            <MenuItem value={3}>Customer Mobile No</MenuItem>
          </Select>
        </div>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "top",
            minWidth: "120px",
            marginRight: 10,
          }} >
          {numberType === 0 ? <TextField
            fullWidth
            disabled
            size="small"
            variant="outlined"
            placeholder="Type here"
          /> : <TextField
            fullWidth
            autoFocus
            required
            size="small"
            variant="outlined"
            placeholder={numberType === 1 ? "Order Id No" : numberType === 2 ? "Vendor Id No" : "Customer Mobile No"}
            value={numberType === 1 ? orderSeq : numberType === 2 ? vendorId : customerContact}
            onChange={handleChangeNumber}
          />}

        </div>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "top",
            marginRight: 20,
          }}
        >
          <Button
            variant="contained"
            component="span"
            style={{ fontSize: "12px", backgroundColor:'#0D8DA3', color:'#ffff' }}
            onClick={() => {
              refreshData();
            }}
          >
            Load
          </Button>
        </div>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "top",
            marginRight: 20,
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            style={{ fontSize: "12px", backgroundColor:"#81CD0A" }}
            onClick={() => setShowPublishOpen(true)}
          >
            Publish All Route
          </Button>
        </div>
      </React.Fragment>
    );
  };

  const renderPublishPopup = () => {
    return (
      <Dialog disableBackdropClick open={showPublishOpen} onClose={() => { }}>
        <DialogTitle id="clinic-popup">Publish All Route</DialogTitle>
        <DialogContent style={{ width: "600px", height: "50px" }}>
          Are you sure to publish all routes?
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            onClick={() => {
              setShowPublishOpen(false);
              publishRoute(0, true);
            }}
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
          <Button
            style={{ borderColor: "#00C9AA", color: "#00C9AA" }}
            onClick={() => {
              setShowPublishOpen(false);
            }}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderReorderPopup = () => {
    return (
      <Dialog disableBackdropClick open={showConfirmReorder} onClose={() => { }}>
        <DialogTitle id="clinic-popup">Confirm Shuffle</DialogTitle>
        <DialogContent style={{ width: "600px", height: "200px" }}>
          <div style={{ marginBottom: 10 }}>
            Are you sure to Shuffle this order?
          </div>
          <div>
            Order Id: <span>{reorderDetails.history.order.orderId}</span>
          </div>
          <div>
            Order Sequence:{" "}
            <span>{reorderDetails.history.order.order_seq}</span>
          </div>
          <div>
            From: <span>{reorderDetails.history.from}</span>
          </div>
          <div>
            To: <span>{reorderDetails.history.to}</span>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            onClick={() => {
              setShowConfirmReorder(false);
              confirmReorder();
            }}
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
          <Button
            style={{ borderColor: "#00C9AA", color: "#00C9AA" }}
            onClick={() => {
              setShowConfirmReorder(false);
              getAllTrips();
            }}
            variant="outlined"
            color="primary"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <DocContainer transparent={true}>
      <React.Fragment>
        <h2>Trips</h2>
      {renderFilters()}
      <div
        style={{
          display: "inline-block",
          verticalAlign: "middle",
        }}
      >
        <div>
          <span style={{ fontSize: 12, fontWeight: "bold" }}>
            Total Routes: {summary.totalRoutes} | Assigned:{" "}
            {summary.assignedRoutes} | Published: {summary.publishedRoutes} |
            Total: {summary.totalCount} | Leave Request:{" "}
            {summary.leaveRequestCount} | Unknown: {summary.unknownCount} |
            Workoff: {summary.workOffCount} | Working: {summary.workingCount}
          </span>
        </div>
      </div>
      <div>
        <TripTable
          data={allTrips}
          allReviewOrders={allReviewOrders}
          publishRoute={publishRoute}
          allPhlebos={allPhlebos}
          updatePlebo={updatePlebo}
          unAssignPlebo={unAssignPlebo}
          getOrderById={getOrderById}
          updateOrder={updateOrder}
          removeOrder={removeOrder}
          dateFilter={dateFilter}
          updateOrderedData={updateOrderedData}
          getPlebos={getPlebos}
          createRoute={createRoute}
          zones={props.zones}
        />
      </div>
      {showPublishOpen && renderPublishPopup()}
      {showConfirmReorder && renderReorderPopup()}
      <RosterMapping date={dateFilter} city={city} />
      </React.Fragment>
    </DocContainer>
  );
};

const mapStateToProps = ({
  constants,
  zones,
  roosters,
  trips,
  plebos,
  orders,
}) => {
  return {
    cities: constants.cities,
    // roosters: roosters.roosters,
    plebos: plebos.plebos,
    zones: zones.zones,
    trips: trips.trips,
    stops: trips.stops,
    orders: orders.orders,
    routePlans: orders.routePlans,
    routeCount: orders.routeCount,
  };
};

export default withRouter(connect(mapStateToProps)(Trips));
