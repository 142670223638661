/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DocContainer from "./../../containers/DocContainer";
import ActionLoader from "../../components/ActionLoader";
import {
  Grid,
  Button,
  FormLabel,
  makeStyles,
  IconButton,
} from "@material-ui/core";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select, { components } from "react-select";
import { ArrowDropDownOutlined, Refresh } from "@material-ui/icons";
import { DatePicker } from "rsuite";
import RosterMapping from "../../components/RosterMapping";

import {
  GET_ALL_CONSTANTS,
  GET_ALL_ZONES,
  CREATE_AUTOASSIGN,
  GET_AUTO_ROUTE_PLANS,
} from "./../../constants/actionConstants";
import moment from "moment";

import "./index.css";



const multiSelectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    fontSize: 12,
  }),
  option: (base) => ({
    ...base,
    fontSize: 12,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#9F9F9F",
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    color: "#fff",
    backgroundColor: "#6FC5C4",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#6FC5C4",
      color: "#fff",
    },
  }),
};

const AutoAssign = (props) => {
  const { cities, zones } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [allCities, setAllCities] = useState([]);
  const [citiesSelected, setCitiesSelected] = useState([]);

  const [allZones, setAllZones] = useState([]);
  const [versions] = useState([
    { value: "v1", label: "V1" },
    { value: "v2", label: "V2" },
  ]);
  const [version, setVersion] = useState("v1");

  const [zonesSelected, setZonesSelected] = useState([]);
  const [allPlans, setAllPlans] = useState([]);
  const [planConfig, setPlanConfig] = useState({
    panIndia: false,
    isAllCity: false,
    isAllZone: false,
    isMultiCity: false,
  });

  const [date, setDate] = useState(new Date());
  const [planDate, setPlanDate] = useState(new Date());
  useEffect(() => {
    getAllConstants();
  }, []);

  useEffect(() => {
    setAllCities(
      cities.map((city) => {
        return { value: city, label: city };
      })
    );
  }, [cities]);

  useEffect(() => {
    setAllZones(
      zones.map((zone) => {
        return { value: zone.zoneId, label: zone.name, ...zone };
      })
    );
  }, [zones]);

  useEffect(() => {
    if (planConfig.panIndia) {
      setPlanConfig({
        ...planConfig,
        isAllCity: true,
        isAllZone: true,
        isMultiCity: false,
      });
    } else {
      setPlanConfig({
        ...planConfig,
        isAllCity: false,
        isAllZone: false,
        isMultiCity: false,
      });
    }
  }, [planConfig.panIndia]);

  useEffect(() => {
    if (citiesSelected.length > 1) {
      setPlanConfig({
        ...planConfig,
        isAllZone: true,
        isMultiCity: true,
      });
    } else {
      setPlanConfig({
        ...planConfig,
        isAllZone: false,
        isMultiCity: false,
      });
      if (citiesSelected.length > 0) {
        getAllZones(citiesSelected[0]);
      }
    }
  }, [citiesSelected]);

  useEffect(() => {
    setPlanConfig({
      ...planConfig,
      isAllZone: planConfig.isAllCity,
    });
  }, [planConfig.isAllCity]);

  useEffect(() => {
    getAllRoutePlans();
  }, [date]);

  const getAllRoutePlans = () => {
    setIsLoading(true);
    const { dispatch } = props;
    const data = {
      count: 100,
      offset: 0,
      text: "",
      ts: date.getTime(),
    };
    dispatch({
      type: GET_AUTO_ROUTE_PLANS,
      data,
      onSuccess: (res) => {
        setIsLoading(false);
        setAllPlans(res.list || []);
      },
    });
  };

  const getAllConstants = () => {
    const { dispatch } = props;
    dispatch({
      type: GET_ALL_CONSTANTS,
    });
  };

  const getAllZones = (city) => {
    const { dispatch } = props;
    const data = {
      city,
      count: 100,
      offset: 0,
      text: "",
    };
    dispatch({
      type: GET_ALL_ZONES,
      data,
    });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const { dispatch } = props;
    const selectedZones = [];  
    allZones.forEach((key) => {
      if (zonesSelected.includes(key.zoneId)) {
        selectedZones.push({ id: key.zoneId, name: key.name });
      }
    });
    const data = {
      ...planConfig,
      city: citiesSelected,
      zone: selectedZones,
      date: moment(planDate).date(),
      month: 1 + moment(planDate).month(),
      year: moment(planDate).year(),
      version
    };
    dispatch({
      type: CREATE_AUTOASSIGN,
      data,
      onSuccess: (res) => {
        setIsLoading(false);
        setPlanConfig({
          panIndia: false,
          isAllCity: false,
          isAllZone: false,
          isMultiCity: false,
        });
        setCitiesSelected([]);
        setZonesSelected([]);
        getAllRoutePlans();
      },
      onFailure: () => {
        setIsLoading(false);
      },
    });
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownOutlined />
      </components.DropdownIndicator>
    );
  };

  const handleCheckboxChange = (e) => {
    setPlanConfig({
      ...planConfig,
      [e.target.name]: e.target.checked,
    });
  };

  const getZoneNames = (zones) => {
    return zones
      .map(function (el) {
        return el.name;
      })
      .join(", ");
  };

  return (
    <DocContainer transparent={true}>
      <div className="mr-20 pd-t-20">
        {isLoading && <ActionLoader />}
        <React.Fragment>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <h2 className="mt-0">
                <FormControlLabel
                  value="panIndia"
                  control={
                    <Checkbox
                      checked={planConfig.panIndia}
                      onChange={handleCheckboxChange}
                      name="panIndia"
                      color="primary"
                    />
                  }
                  label="Pan India"
                  labelPlacement="end"
                />
              </h2>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <h2 className="mt-10">
                <FormLabel component="legend" className="text-label">
                  Select City
                </FormLabel>
                <Select
                  options={allCities}
                  isDisabled={planConfig.panIndia}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator,
                  }}
                  isClearable={false}
                  value={allCities.filter((key) =>
                    citiesSelected.includes(key.value)
                  )}
                  isMulti
                  onChange={(val) => {
                    setCitiesSelected(val.map((a) => a.value));
                  }}
                  placeholder={"City"}
                  styles={multiSelectStyles}
                  style={{ width: "80%" }}
                />
              </h2>
            </Grid>
            <Grid item xs={2}>
              <h2 className="mt-10">
                <FormLabel component="legend" className="text-label">
                  Select Zone
                </FormLabel>
                <Select
                  options={allZones}
                  isDisabled={planConfig.panIndia || planConfig.isAllZone}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator,
                  }}
                  isClearable={false}
                  value={allZones.filter((key) =>
                    zonesSelected.includes(key.value)
                  )}
                  isMulti
                  onChange={(val) => {
                    setZonesSelected(val.map((a) => a.value));
                  }}
                  placeholder={"Zone"}
                  styles={multiSelectStyles}
                  style={{ width: "80%" }}
                />
                <FormControlLabel
                  value="isAllZone"
                  control={
                    <Checkbox
                      disabled={planConfig.panIndia}
                      checked={planConfig.isAllZone}
                      onChange={handleCheckboxChange}
                      name="isAllZone"
                      color="primary"
                    />
                  }
                  label="All Zones"
                  labelPlacement="end"
                />
              </h2>
            </Grid>
            <Grid item xs={2}>
              <h2 className="mt-10">
                <FormLabel component="legend" className="text-label">
                  Select Version
                </FormLabel>
                <Select
                  options={versions}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator,
                  }}
                  value={versions.find((el) => el.value === version)}
                  onChange={(val) => {
                    setVersion(val.value);
                  }}
                  placeholder={"Version"}
                  styles={multiSelectStyles}
                  style={{ width: "80%" }}
                />
              </h2>
            </Grid>
            <Grid item xs={2}>
              <div style={{ marginTop: 20 }}>
                <FormLabel component="legend" className="text-label">
                  Select Date
                </FormLabel>
                <DatePicker
                  value={planDate}
                  onOk={(date) => setPlanDate(date)}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <Button
                style={{
                  backgroundColor: "#0D8DA3",
                  padding: '8px 16px',
                  marginTop: '40px',
                }}  
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                <span className="button-text1">Create</span>
              </Button>
            </Grid>
          </Grid>
          <div style={{ borderTop: "1px solid #808080" }} />
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <div style={{ marginTop: 10 }}>
                <FormLabel component="legend" className="text-label">
                  Select Date
                </FormLabel>
                <DatePicker value={date} onOk={(date) => setDate(date)} />
              </div>
            </Grid>
            <Grid item xs={3}>
              <IconButton
                edge="end"
                color="inherit"
                size="small"
                style={{ fontSize: "12px", marginTop: "30px" }}
                onClick={() => {
                  getAllRoutePlans();
                }}
              >
                <Refresh /> Refresh
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <div className="tableView">
                <table className="styled-table">
                  <thead className="tableHead">
                    <tr className="border_bottom">
                      <th>S.no</th>
                      <th>Date</th>
                      <th>Is Pan India</th>
                      <th>Cities</th>
                      <th>Zones</th>
                      <th>Tot</th>
                      <th>Acc</th>
                      <th>Rej</th>
                      <th>Routes</th>
                      <th>Created By</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody className="tableBody">
                    {(allPlans || []).map((el, i) => (
                      <tr className="row">
                        <td className="cell" data-title="S.no">
                          {i + 1}
                        </td>
                        <td className="cell" data-title="Date">
                          {moment(el.createdDate).format("DD-MM-YYYY h:mm A")}
                        </td>
                        <td className="cell" data-title="Pan India">
                          {el.isAllCity ? "Yes" : "No"}
                        </td>
                        <td className="cell" data-title="Cities">
                          {el.isAllCity
                            ? "All Cities"
                            : el.city.length > 0
                              ? el.city.join(", ")
                              : "-"}
                        </td>
                        <td className="cell" data-title="Zones">
                          {el.isAllZone ? "All Zones" : getZoneNames(el.zone)}
                        </td>
                        <td className="cell" data-title="Total">
                          {el.total}
                        </td>
                        <td className="cell" data-title="Accepted">
                          {el.accepted}
                        </td>
                        <td className="cell" data-title="Rejected">
                          {el.rejected}
                        </td>
                        <td className="cell" data-title="Total Routes">
                          {el.totalRoutes}
                        </td>
                        <td className="cell" data-title="Created By">
                          {el.createdBy}
                        </td>
                        <td className="cell" data-title="Status">
                          {el.status}
                        </td>
                      </tr>
                    ))}
                    {allPlans.length === 0 && (
                      <tr className="row">
                        <td className="cell" data-title="S.no">
                          No Plans
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Grid>
          </Grid>
          <RosterMapping date={planDate} city={citiesSelected[0]} />
        </React.Fragment>
      </div>
    </DocContainer>
  );
};

const mapStateToProps = ({ constants, zones }) => {
  return {
    cities: constants.cities,
    zones: zones.zones,
  };
};

export default withRouter(connect(mapStateToProps)(AutoAssign));
