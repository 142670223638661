import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DocContainer from './../../containers/DocContainer';
import GoogleMapContainer from '../../components/googleMap/map';
import {
	Grid, Select, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
	Button,
	TextField,
	Paper,
	Avatar,
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import './manage-tower.css';
import {
	GET_ALL_CONSTANTS,
	GET_PLEBOS,
	UPDATE_PHLEBO_AREA,
	GET_PHLEBO_MAPPING_DATA,
	REMOVE_PHLEBO_MAPPING
} from './../../constants/actionConstants';
import { CITY_MAP_URL } from 'Constants/constants';
class ManageTower extends Component {
	constructor(props) {
		super(props);
		this.state = {
			phlebosList: [],
			selectedPhlebos:[],
			offset: 0,
			isModalOpen: false,
			isError: false,
			showAllPhlebos:false,
			city: "",
			phleboId: '',
			firstName: '',
			lastName: '',
			mapData: {
				area: "",
				phlebosIds: [],
				location: [],
				city:""
			}
		};
	}

	UNSAFE_componentWillMount() {
		this.getAllConstants();
	}


	getPlebos = () => {
		const { dispatch } = this.props;
		const {city} = this.state;
		let data = {
			count: 100,
			offset: this.state.offset,
			text: city
		};
		dispatch({
			type: GET_PLEBOS,
			data,
		});
	}


	getAllConstants = () => {
		const { dispatch } = this.props;
		dispatch({
			type: GET_ALL_CONSTANTS
		});
	}

	getPhleboMappingData = () => {
		const { dispatch } = this.props;
		const { city } = this.state;
		let data = {

		};
		if (city) {
			data.city = city;
		}

		dispatch({
			type: GET_PHLEBO_MAPPING_DATA,
			data
		});
	}


	

	onPolygonComplete = (polygon) => {
		const { mapData } = this.state;
		const _tempArea = [];
		const vertices = polygon.getPath();
		for (let i = 0; i < vertices.getLength(); i++) {
			const xy = vertices.getAt(i);
			const loction = [
				xy.lng(),
				xy.lat()
			]
			_tempArea.push(
				loction
			)
		}
		_tempArea.push(_tempArea[0]);
		mapData.location = _tempArea;
		this.setState({ mapData });

		this.setState({ isModalOpen: true }, () => {
			this.getPlebos()
		});
	}


	handleFormChange = (e, key) => {
		const { mapData } = this.state;
		
		 const city = e.target.value;
		 mapData.city = e.target.value;
		 debugger;
		this.setState(
			{
				city,
				mapData
			}, () => {
			this.getPhleboMappingData()
		});
	}


	handleFormInputChange = (e, key) => {
		const { mapData } = this.state;
		mapData[key] = e.target.value;
		this.setState({ mapData });
	}

	assignPhlebos = () => {
		const { mapData,showAllPhlebos } = this.state;
		this.setState({showAllPhlebos:true})
	}


	assignArea = () => {
		const { plebos } = this.props;
		const { selectedPhlebos, mapData, city } = this.state;
		const tempArr = [];
		let selectedPhlebosArr = selectedPhlebos.map((item) => {
			return plebos.filter((selectedItem) =>
			{
				return selectedItem.phleboId == item
			})
		})
		selectedPhlebosArr.forEach((item) => {
			tempArr.push({
				"phleboId": item[0].phleboId,
				"name":item[0].firstName + ' '+ item[0].lastName ,
			})
		})
		mapData.phlebosIds = tempArr;
		mapData.city = city;
		this.setState({ mapData });
		this.updatePhleboArea();
	}


	resetData = () => {
		let {
			mapData
		} = this.state
		const data = {
			area: "",
			phlebosIds: [],
			location: [],
			city:""
		}
		data.city = city;
		mapData = data;
		this.setState({ mapData })
		this.setState({phlebosList:[]})
		this.setState({selectedPhlebos:[]})
		this.setState({showAllPhlebos:false})
		this.setState({isModalOpen:false})
		};


		updatePhleboArea = () => {
			const { dispatch, match } = this.props;
			const { mapData } = this.state;
			const dataParams = mapData
			dispatch({
				type: UPDATE_PHLEBO_AREA,
				data:dataParams,
				onSuccess: ((res) => {
					this.resetData();
					this.getPhleboMappingData()
				})
			})
		}


	deleteArea = (data) => {
		const { dispatch } = this.props;
			dispatch({
				type: REMOVE_PHLEBO_MAPPING,
				data: {mappingId: data.mappingId},
				onSuccess: ((res) => {
					this.getPhleboMappingData()
				})
			});
		}


	renderPlebosTable = (data) => {
		const { plebos } = this.props;
		const { city,selectedPhlebos } = this.state;
		let plebosList = plebos
		if (plebosList.length > 1 && city) {
			plebosList = plebosList.filter((item) => {
				item.name = item.firstName + ' ' + item.lastName;
				return item.city.toLowerCase() == city.toLowerCase();
			})
		}
		return (
			<TableContainer component={Paper} className="mb-30">
				<Table size="large" aria-label="simple table">
					<TableHead>
							<TableRow>
							<TableCell></TableCell>
							<TableCell>Image</TableCell>
							<TableCell>Name</TableCell>
							<TableCell>Phone number</TableCell>
							<TableCell>City</TableCell>
							<TableCell>Address</TableCell>
						</TableRow>
					</TableHead>
				<TableBody>
					{((plebosList || []).length <= 0) &&
						<TableRow>
							<TableCell component="th" scope="row"> No phlebos found. </TableCell>
						</TableRow>
					}
					{(plebosList || []).map(plebo => (
						<TableRow key={plebo.phleboId}>
								<TableCell>
										<Checkbox size="small" color="primary"
											checked={selectedPhlebos.includes(plebo.phleboId)}
											onChange={(e) => {
												const { selectedPhlebos } = this.state;
												if (selectedPhlebos.includes(plebo.phleboId)) {
													selectedPhlebos.splice(selectedPhlebos.indexOf(plebo.phleboId), 1);
												} else {
													selectedPhlebos.push(plebo.phleboId);
												}
												this.setState({ selectedPhlebos })
											}}
										/>
									</TableCell>
							<TableCell>
								<a href={plebo.imageUrl} download>
									<Avatar alt='img' size="small" src={plebo.imageUrl} />
								</a>
							</TableCell>
							<TableCell>{plebo.firstName} {plebo.lastName}</TableCell>
							<TableCell>{plebo.mobileNumber}</TableCell>
							<TableCell>{plebo.city}</TableCell>
							<TableCell>{plebo.address1} {plebo.address2}</TableCell>
						</TableRow>
					))}
					</TableBody>
					</Table>
				</TableContainer>
		);
	}

	renderPopup = () => {

		const { isModalOpen, data, mapData, isError, showAllPhlebos, selectedPhlebos } = this.state;

		return (
			<Dialog fullWidth maxWidth="lg" disableBackdropClick open={isModalOpen} onClose={() => { this.setState({ isModalOpen: false }) }}>
				<DialogTitle id="clinic-popup">Add Details</DialogTitle>
				<DialogContent style={{height: '400px' }}>
				<Grid container spacing={3}>
					<Grid item xs={6}>
						<TextField fullWidth label="Enter Area Name"
							value={mapData.area}
							onChange={(e) => this.handleFormInputChange(e, 'area')}
							error={isError && mapData.area === ""}
							helperText={(isError && mapData.area === "") ? 'Please enter area name' : ' '}
						/>
						</Grid>
						<Grid item xs={6}>
						<Button style={{ backgroundColor: "#00C9AA" }}  variant="contained" onClick={() => { this.assignPhlebos() }} color="primary">Save</Button>
							{selectedPhlebos && selectedPhlebos.length > 0 && <Button style={{ backgroundColor: "#00C9AA", marginLeft: '20px' }} variant="contained" onClick={() => { this.assignArea() }} color="primary">Assign area</Button>}
						</Grid>
					</Grid>
					{showAllPhlebos && this.renderPlebosTable()}
				</DialogContent>
				<DialogActions>

					<Button style={{ borderColor: "#00C9AA", color: "#00C9AA" }} onClick={() => { this.setState({ isModalOpen: false }); }} variant="outlined" color="primary">Close</Button>
				</DialogActions>
			</Dialog>
		)
	}


	_renderAreaTable = () => {
		const { areaDetails } = this.props;

		return (
			<div style={{maxWidth:'1200px'}}>
			<TableContainer component={Paper} className="mb-30">
				<Table  size="small" aria-label="simple table">
					<TableHead>
							<TableRow>
							<TableCell>Mapping Id</TableCell>
							<TableCell>City</TableCell>
							<TableCell>Area</TableCell>
							<TableCell>Action</TableCell>
						</TableRow>
					</TableHead>
				<TableBody>
					{((areaDetails || []).length <= 0) &&
						<TableRow>
							<TableCell component="th" scope="row"> No phlebos found. </TableCell>
						</TableRow>
					}
					{(areaDetails || []).map(pleboArea => (
						<TableRow key={pleboArea.mappingId}>
							<TableCell>{pleboArea.mappingId}</TableCell>
							<TableCell>{pleboArea.city}</TableCell>
							<TableCell>{pleboArea.area}</TableCell>
							<TableCell>
								<Button style={{ backgroundColor: "#00C9AA" }}  variant="contained" onClick={() => { this.deleteArea(pleboArea) }} color="primary">Delete</Button>
							</TableCell>
						</TableRow>
					))}
					</TableBody>
					</Table>
				</TableContainer>
				</div>
		);
	}


	render() {
		const { city, isModalOpen,mapData } = this.state;
		const { areaDetails } = this.props;
		return (
			<DocContainer>
				<div className="city-conatiner">
					<label> City </label>
					<Select fullWidth
						value={city}
						onChange={(e) => { this.handleFormChange(e, 'city');}}
					>
						{(this.props.cities || []).map((city, index) => (
							<option key={`city-${index}`} value={city}> {city} </option>
						))}
					</Select>
				</div>
				<div className="mr-20 map-container">
					{city && <GoogleMapContainer
						label={mapData && mapData.area}
						showMarker={mapData && mapData.area ? true :false}
						isMarkerShown={mapData && mapData.area ? true : false}
						areaDetails={areaDetails}
						infoData={mapData && mapData.area ? { lat: mapData.location[1][0], lng: mapData.location[1][1], area: mapData.area } : null}
							googleMapURL={CITY_MAP_URL}
							loadingElement={<div style={{ height: `100%` }} />}
							containerElement={<div style={{ height: `400px` }} />}
							mapElement={<div style={{ height: `100%` }} />}
							onPolygonComplete={this.onPolygonComplete}

						/>}
				</div>
				<div className="mt-20 map-container"

				></div>
				{isModalOpen && this.renderPopup()}
				{areaDetails && areaDetails.length > 0 && city && this._renderAreaTable()}
			</DocContainer>
		);
	}
}

const mapStateToProps = ({plebos, constants, lgp}) => {
	return {
	  isLoading: plebos.isSubmitting,
	  plebos: plebos.plebos,
	  areaDetails:plebos.plebosMapArea,
	  cities: constants.cities,
	  states: constants.states
	}
  };


export default withRouter(connect(mapStateToProps)(ManageTower));
