import { call, put } from 'redux-saga/effects';

import { 
    postDataWithToken,
    getDataWithToken,
    deleteDataWithToken,

    ADD_ROUTE_URL,
    GET_ALL_ROUTE_URL,
    GET_ROUTE_BY_ID_URL,
    EDIT_ROUTE_URL,
    DELETE_ROUTE_URL,
    SEARCH_ROUTE_URL,
    DOWNLOAD_ROUTE_URL
} from 'Utils/api';

import {
    IS_SUBMITTING,
    ROUTE_LIST,

    TOAST_ERROR,
    TOAST_SUCCESS
} from 'Constants/actionConstants';


export function* getRoutes(action) {
    try{
        const url = action.data && action.data.id ? GET_ROUTE_BY_ID_URL : GET_ALL_ROUTE_URL;
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(getDataWithToken, url, action.data);

        yield put({
            type: ROUTE_LIST,
            data: data && data.routes ? data.routes : [],
            hasMore: data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false
        })

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* downloadRoutes(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(getDataWithToken, DOWNLOAD_ROUTE_URL, action.data);

        if (action.onSuccess) {
            action.onSuccess(data);
        }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* addRoutes(action) {
    try{
        const url = (action.data && action.data.isEdit) ? EDIT_ROUTE_URL : ADD_ROUTE_URL;
        yield put({ type : IS_SUBMITTING, isSubmitting: true });
        const data = yield call(postDataWithToken, url, action.data);
        yield put({ type : IS_SUBMITTING, isSubmitting: false });

        yield put({
            type: TOAST_SUCCESS,
            message: data && data.message?data.message : "Data updated successfully!"
        })
        
        if (action.onSuccess) {
            action.onSuccess(data);
        }
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* deleteRoutes(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(deleteDataWithToken, DELETE_ROUTE_URL, action.data);

        yield put({
            type: TOAST_SUCCESS,
            message: data && data.message?data.message : "Data updated successfully!"
        })
        
        if (action.onSuccess) {
            action.onSuccess(data);
        }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* searchRoutes(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(getDataWithToken, SEARCH_ROUTE_URL, action.data);

        yield put({
            type: ROUTE_LIST,
            data: data && data.routes ? data.routes : [],
            hasMore: data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false
        })

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}