import { call, put, fork, } from 'redux-saga/effects';

import { 
    getDataWithToken,
    TRIPS_URL
} from 'Utils/api';

import {
    ALL_ROSTERS,
    IS_SUBMITTING,
    TOAST_ERROR,
    GET_TRIPS,
    TOAST_SUCCESS
} from 'Constants/actionConstants';
import { SET_TRIPS } from 'Constants/actionConstants';


export function* getTrips(action) {
    if(action.data.city){
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const URL = action.data.city ? TRIPS_URL:'';

        const data = yield call(getDataWithToken, URL, action.data);
        yield put({
            type: GET_TRIPS,
            data: data && data.list ? data.list : [],
            hasMore: data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false
        })
        if (action.onSuccess) {
            action.onSuccess(data);
        }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}
}

function* setTrips1(action) {
        yield put({
            type: SET_TRIPS,
            data: action && action.data ? action.data : [],
        })   
}

export const setTrips = [fork(setTrips1)];

