import { call, put } from 'redux-saga/effects';

import { 
    getDataWithToken,
    putDataWithToken,
    postDataWithToken,
    deleteDataWithToken,
    uploadFile,

    GET_ALL_ZONES_URL,
    GET_UNMAPPED_PHLEBOS_URL,
    GET_UNPLANNED_ZIPCODES_URL,
    GET_ALL_ZIPCODES_URL,
    ADD_ZONES_URL,
    UPDATE_ZONES_URL,
    REMOVE_PHLEBO_URL
} from 'Utils/api';

import {
    ALL_ZONES,
    IS_SUBMITTING,
    TOAST_ERROR,
    TOAST_SUCCESS
} from 'Constants/actionConstants';


export function* getAllZones(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const URL = GET_ALL_ZONES_URL;

        const data = yield call(getDataWithToken, URL, action.data);
        yield put({
            type: ALL_ZONES,
            data: data && data.list ? data.list : [],
            hasMore: data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false
        })
        if (action.onSuccess) {
            action.onSuccess(data);
        }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}


export function* addZones(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(postDataWithToken, ADD_ZONES_URL, action.data);
       	
       	yield put({
            type: TOAST_SUCCESS,
            message: data && data.message ? data.message : "Created successfully!"
        })
        
        if (action.onSuccess) {
            action.onSuccess(data);
        }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}


export function* updateZones(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(putDataWithToken, UPDATE_ZONES_URL, action.data);
       	
        yield put({
         type: TOAST_SUCCESS,
         message: data && data.message ? data.message : "Data updated successfully!"
     })
     
     if (action.onSuccess) {
         action.onSuccess(data);
     }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* removeZonePhlebo(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(putDataWithToken, REMOVE_PHLEBO_URL, action.data);
       	
        yield put({
         type: TOAST_SUCCESS,
         message: data && data.message ? data.message : "Data updated successfully!"
     })
     
     if (action.onSuccess) {
         action.onSuccess(data);
     }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* getUnplannedZipcodes(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const URL = GET_UNPLANNED_ZIPCODES_URL;

        const data = yield call(getDataWithToken, URL, action.data);
        if (action.onSuccess) {
            action.onSuccess(data);
        }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}


export function* getAllZipcodes(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const URL = GET_ALL_ZIPCODES_URL;

        const data = yield call(getDataWithToken, URL, action.data);
        if (action.onSuccess) {
            action.onSuccess(data);
        }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}


export function* getUnmappedPhlebos(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const URL = GET_UNMAPPED_PHLEBOS_URL;

        const data = yield call(getDataWithToken, URL, action.data);
        if (action.onSuccess) {
            action.onSuccess(data);
        }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}