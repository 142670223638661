import { SET_CITY_AND_RESTRICT } from "Constants/actionConstants";
import {
  SIGN_IN_ERROR,
  SIGN_IN_SUBMITTING,
  FORGOT_PASSWORD_SUBMITTING,
  FORGOT_PASSWORD_SUCCESS,
  IS_SUBMITTING,
} from "Constants/actionConstants";
const authentication = (
  state = {
    signin: {
      error: "",
      isSubmitting: false,
    },
    forgotPassword: {
      error: "",
      isSubmitting: false,
    },
    isSubmitting: false,
    isRestrictCity: null,
    city: "",
  },
  action
) => {
  let newState;
  if (action.type === SIGN_IN_ERROR) {
    newState = { ...state };
    newState.signin = {
      error: action.error,
      isSubmitting: false,
    };
  } else if (action.type === SIGN_IN_SUBMITTING) {
    newState = { ...state };
    newState.signin = {
      error: "",
      isSubmitting: action.isSubmitting,
    };
  } else if (action.type === FORGOT_PASSWORD_SUCCESS) {
    newState = { ...state };
    newState.forgotPassword = {
      isSubmitting: false,
    };
  } else if (action.type === FORGOT_PASSWORD_SUBMITTING) {
    newState = { ...state };
    newState.forgotPassword = {
      isSubmitting: true,
    };
  } else if (action.type === IS_SUBMITTING) {
    newState = { ...state };
    newState.isSubmitting = action.isSubmitting;
  } else if (action.type === SET_CITY_AND_RESTRICT) {
    newState = { ...state };

    newState.isRestrictCity = action.isRestrictCity;
    newState.city = action.city;
  }

  return newState || state;
};

export default authentication;
