import { call, put } from "redux-saga/effects";

import {
  postDataWithToken,
  putDataWithToken,
  getDataWithToken,
  deleteDataWithToken,
  GET_LGP_URL,
  GET_LGP_BY_ID_URL,
  POST_LGP_URL,
  EDIT_LGP_URL,
  DELETE_LGP_URL,
  GET_CONTRACT_BY_LGPID_URL,
  ADD_CONTRACT_URL,
  EDIT_CONTRACT_URL,
  GET_DOCTORS_URL,
  GET_LGP_LAB_LIST_URL,
  ADD_MER_QUESTIONS_URL,
  GET_MER_QUESTIONS_URL,
  ADD_DOCTOR_URL,
  GET_DOCTOR_URL,
  GET_MER_ORDERS_URL,
  UPDATE_MER_ORDERS_URL,
  GET_MER_ORDERS_DASHBOARD_URL
} from "Utils/api";

import {
  IS_SUBMITTING,
  PLEBO_LIST,
  PLEBO_KYC_LIST,
  LGP_LIST,
  CONTRACT_DATA,
  DOCTORS_LIST_DATA,
  TOAST_ERROR,
  TOAST_SUCCESS
} from "Constants/actionConstants";

export function* getLPGs(action) {
  try {
    const url = action.data && action.data.id ? GET_LGP_BY_ID_URL : GET_LGP_URL;
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(getDataWithToken, url, action.data);

    yield put({
      type: LGP_LIST,
      data: data && data.lgpList ? data.lgpList : [],
      hasMore:
        data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false
    });

    if (action.onSuccess) {
      action.onSuccess(data.lgpList || []);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong"
    });
  }
}

export function* addLGPs(action) {
  try {
    const url = action.data && action.data.isEdit ? EDIT_LGP_URL : POST_LGP_URL;
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(postDataWithToken, url, action.data);
    yield put({ type: IS_SUBMITTING, isSubmitting: false });

    yield put({
      type: TOAST_SUCCESS,
      message:
        data && data.message ? data.message : "Data updated successfully!"
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong"
    });
  }
}

export function* deleteLGPs(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(deleteDataWithToken, DELETE_LGP_URL, action.data);

    yield put({
      type: TOAST_SUCCESS,
      message:
        data && data.message ? data.message : "Data updated successfully!"
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong"
    });
  }
}
export function* getContractData(action) {
  try {
    const url = GET_CONTRACT_BY_LGPID_URL;
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(getDataWithToken, url, action.data);
    yield put({
      type: CONTRACT_DATA,
      data: data ? data : {},
      hasMore:
        data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false
    });

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong"
    });
  }
}

export function* addContract(action) {
  try {
    const url =
      action.data && action.data.chargeContractId
        ? EDIT_CONTRACT_URL
        : ADD_CONTRACT_URL;
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(postDataWithToken, url, action.data);
    yield put({ type: IS_SUBMITTING, isSubmitting: false });

    yield put({
      type: TOAST_SUCCESS,
      message:
        data && data.message ? data.message : "Data updated successfully!"
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong"
    });
  }
}

export function* getDoctorsList(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(getDataWithToken, GET_DOCTORS_URL, action.data);
    yield put({
      type: DOCTORS_LIST_DATA,
      data: data && data.list ? data.list : []
    });
    if (action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong"
    });
  }
}

export function* getLabList(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(
      getDataWithToken,
      GET_LGP_LAB_LIST_URL,
      action.data
    );

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong"
    });
  }
}

export function* addMerQuestions(action) {
  try {
    const url =
      action.data && action.data.isEdit ? EDIT_LGP_URL : ADD_MER_QUESTIONS_URL;
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(
      action.data && action.data.formId ? putDataWithToken : postDataWithToken,
      url,
      action.data
    );
    yield put({ type: IS_SUBMITTING, isSubmitting: false });

    yield put({
      type: TOAST_SUCCESS,
      message:
        data && data.message ? data.message : "Data updated successfully!"
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong"
    });
  }
}

export function* getMerQuestions(action) {
  try {
    const url =
      action.data && action.data.id ? GET_LGP_BY_ID_URL : GET_MER_QUESTIONS_URL;
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(getDataWithToken, url, action.data);

    yield put({
      type: LGP_LIST,
      data: data && data.lgpList ? data.lgpList : [],
      hasMore:
        data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false
    });

    if (action.onSuccess) {
      action.onSuccess(data || null);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong"
    });
  }
}

export function* updateMerOrders(action) {
  try {
    const url = UPDATE_MER_ORDERS_URL;
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(
      action.data && action.data.doctorId
        ? putDataWithToken
        : postDataWithToken,
      url,
      action.data
    );
    yield put({ type: IS_SUBMITTING, isSubmitting: false });

    yield put({
      type: TOAST_SUCCESS,
      message:
        data && data.message ? data.message : "Data updated successfully!"
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong"
    });
  }
}

export function* getMerOrders(action) {
  try {
    const url = GET_MER_ORDERS_URL;
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(getDataWithToken, url, action.data);

    yield put({
      type: LGP_LIST,
      data: data && data.list ? data.list : [],
      hasMore:
        data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false
    });

    if (action.onSuccess) {
      action.onSuccess(data || null);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong"
    });
  }
}

export function* getMerOrdersDashboard(action) {
  try {
    const url = GET_MER_ORDERS_DASHBOARD_URL;
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(getDataWithToken, url, action.data);

    yield put({
      type: LGP_LIST,
      data: data && data.list ? data.list : [],
      hasMore:
        data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false
    });

    if (action.onSuccess) {
      action.onSuccess(data || null);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong"
    });
  }
}