import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import Routes from './Routes';
import configureStore from './store/ConfigureStore';

const isProd = process.env.NODE_ENV === 'production';

const store = configureStore();

import 'Sagas/';

const THEME = createMuiTheme({
   typography: {
    "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
    "fontSize": 12,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
   }
});

const renderRoot = () => {
  ReactDOM.render(
    <ThemeProvider theme={THEME}>
      <Provider store={store}>
        <BrowserRouter history={history} context={{}}>
          <Routes />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>,
    document.getElementById('root')
  );
};

renderRoot();

if (!isProd && module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('./Routes', () => {
    renderRoot();
  });
}
