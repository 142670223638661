import { 
  RUNNER_LIST,
  RUNNER_KYC_LIST,

  IS_SUBMITTING,
} from 'Constants/actionConstants';


const runner = (state={
  
  isSubmitting: false,
  runnerKYC: [],
  runner: [],

}, action)=> {

  switch (action.type) {
    
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };

    case RUNNER_LIST:
      return {
        ...state,
        runner: action.data,
        hasMore: action.hasMore,
      };

    case RUNNER_KYC_LIST:
      return {
        ...state,
        runnerKYC: action.data,
        hasMore: action.hasMore,
      };

    default:
      return state;
  }

}

export default runner;
