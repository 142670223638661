import React, { Component } from 'react';
import { connect } from 'react-redux';
import './clinics.css';

import DocContainer from './../../containers/DocContainer';
import ActionLoader from "../../components/ActionLoader";
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	Dialog, DialogTitle, DialogContent,  DialogActions,
	Paper,
	Button,
	Grid,
	TextField,
	IconButton,
	Switch,
	InputAdornment,
} from '@material-ui/core';

import {
	GET_ROUTES,
	ADD_ROUTES,
	DELETE_ROUTES,
	SEARCH_ROUTES,
	GET_ALL_CONSTANTS
} from './../../constants/actionConstants';
import { Edit, Clear } from '@material-ui/icons';
import { DOWNLOAD_ROUTES_DATA_URL } from 'Constants/actionConstants';


class TRoutes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			offset: 0,
			searchText: '',
			isError: false,
			isOpen: false,
			cityFilter: 'all',
			city: '',
			data: {
				areaName: '',
				city: '',
				zipcode: '',
				state: '',
				isActive: false
			}
		};
	}

	UNSAFE_componentWillMount() {
		this.getRoutes();
		this.getAllConstants();
	}

	

	resetPopup = () => {
		let { data } = this.state;
		data = {
			areaName: '',
			city: '',
			zipcode: '',
			state: '',
			isActive: false
		};
		this.setState({ data, isError: false });
	}

	getRoutes = () => {
		const { dispatch } = this.props;
		let data = {
			count: 10,
			offset: this.state.offset,
			city: this.state.city ? this.state.city : undefined
		};

		dispatch({
			type: GET_ROUTES,
			data,
		});
	}

	searchRoutes = (searchText) => {
		const { dispatch } = this.props;
		let data = {
			text: searchText,
			count: 100,
			offset: 0,
		}

		dispatch({
			type: SEARCH_ROUTES,
			data,
		});
	}

	addRoutes = (quickEditItems) => {
		const { dispatch } = this.props;

		dispatch({
			type: ADD_ROUTES,
			data: Object.keys(quickEditItems || {}).length > 0 ? quickEditItems : this.state.data,
			onSuccess: (() => {
				this.setState({ isOpen: false });
				this.resetPopup();
				this.getRoutes();
			})
		});
	}

	deleteRoutes = (id) => {
		const { dispatch } = this.props;
		dispatch({
			type: DELETE_ROUTES,
			data: { routeId: id },
			onSuccess: (() => {
				this.setState({ isDeleteOpen: false, deleteObj: null })
				this.getRoutes();
			})
		});
	}

	handleFormChange = (e, key) => {
		const { data } = this.state;
		data[key] = e.target.value;
		this.setState({ data });
	}

	validateData = () => {
		const { data } = this.state;
		if (data.areaName === '' || data.city === '' || data.zipcode === '' || data.state === '') {
			this.setState({ isError: true });
		} else {
			this.setState({ isError: false });
			this.addRoutes();
		}
	}

	renderPagination = () => {
		return (
			<div className="mt-30">
				<Grid container spacing={5}>
					<Grid item xs={9} />
					<Grid item xs={3}>
						<Button
							style={{ marginRight: '20px' }}
							size="medium"
							color="primary"
							variant="outlined"
							disabled={this.state.offset === 0}
							onClick={() => {
								this.setState({ offset: this.state.offset - 10 }, () => {
									this.getRoutes();
								})
							}}
						>
							{'< Previous'}
						</Button>
						<Button
							size="medium"
							color="primary"
							variant="outlined"
							disabled={!this.props.hasMore}
							onClick={() => {
								this.setState({ offset: this.state.offset + 10 }, () => {
									this.getRoutes();
								})
							}}
						>
							{'Next >'}
						</Button>
					</Grid>
				</Grid>
			</div>
		)
	}

	renderDeletePopup = () => {
		const { isDeleteOpen, data, deleteObj } = this.state
		return (
			<Dialog disableBackdropClick open={isDeleteOpen} onClose={() => { this.setState({ isDeleteOpen: false }) }}>
				<DialogTitle id="clinic-popup">Confirm Delete</DialogTitle>
				<DialogContent > Are you sure you want to delete {deleteObj.firstName} {deleteObj.lastName} ? </DialogContent>
				<DialogActions>
					<Button style={{ backgroundColor: "#00C9AA" }} onClick={() => { this.deleteRoutes(deleteObj.routeId) }} variant="contained" color="primary">Yes</Button>
					<Button style={{ borderColor: "#00C9AA", color: "#00C9AA" }} onClick={() => { this.setState({ isDeleteOpen: false, deleteObj: null }) }} variant="outlined" color="primary">No</Button>
				</DialogActions>
			</Dialog>
		)
	}

	renderPopup = () => {
		const { isOpen, data, isError } = this.state
		return (
			<Dialog disableBackdropClick open={isOpen} onClose={() => { this.setState({ isOpen: false }) }}>
				<DialogTitle id="clinic-popup">Create New Route</DialogTitle>
				<DialogContent style={{ width: '600px', height: '400px' }}>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<TextField fullWidth label="Area Name"
								value={data.areaName}
								onChange={(e) => this.handleFormChange(e, 'areaName')}
								error={isError && data.areaName === ""}
								helperText={(isError && data.areaName === "") ? 'Please enter a area name' : ' '}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField fullWidth label="City"
								value={data.city}
								onChange={(e) => this.handleFormChange(e, 'city')}
								error={isError && data.city === ""}
								helperText={(isError && data.city === "") ? 'Please enter a city' : ' '}
							/>
						</Grid>
					</Grid>

					<Grid container spacing={3}>
						<Grid item xs={6}>
							<TextField fullWidth label="Zipcode"
								value={data.zipcode}
								error={isError && data.zipcode === ""}
								helperText={(isError && data.zipcode === "") ? 'Please enter zipcode' : ' '}
								onChange={(e) => this.handleFormChange(e, 'zipcode')}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField fullWidth label="State"
								value={data.state}
								onChange={(e) => this.handleFormChange(e, 'state')}
							/>
						</Grid>
					</Grid>

					<Grid container spacing={3}>
						<Grid item xs={6}>
							<label>Is Active?</label>
							<Switch color="primary" name="isActive"
								checked={data.isActive}
								onChange={(e) => {
									const { data } = this.state;
									data.isActive = e.target.checked;
									this.setState({ data });
								}}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button style={{ backgroundColor: "#00C9AA" }} onClick={this.validateData} variant="contained" color="primary">Save</Button>
					<Button style={{ borderColor: "#00C9AA", color: "#00C9AA" }} onClick={() => { this.setState({ isOpen: false }); this.resetPopup() }} variant="outlined" color="primary">Close</Button>
				</DialogActions>
			</Dialog>
		)
	}

	handleCity = (e) => {
		this.setState({ city: e }, () => {
			this.getRoutes()
		});
	}

	getAllConstants = () => {
		const { dispatch } = this.props;
		dispatch({
			type: GET_ALL_CONSTANTS,
		});
	};
	downloadReport = () => {
		const { dispatch } = this.props;
		let data = {
			city: this.state.city ? this.state.city : undefined
		};
		dispatch({
			type: DOWNLOAD_ROUTES_DATA_URL,
			data,
			onSuccess: (res) => {
				if (res && res.link) {
					const element = document.createElement("a");
					element.setAttribute("href", res.link);
					element.setAttribute("download", true);
					element.click();
				}
			},
		});
	};

	render() {
		const { cities, states, tRoutes, isLoading } = this.props;
		const { isOpen, isDeleteOpen, data } = this.state;
			return (
			<DocContainer>
				<div className="mr-20">
					{isLoading && <ActionLoader />}
					<Grid item xs={1}>
						<h2 className="mt-0">Routes</h2>
					</Grid>

					<Grid container spacing={2}>
						<Grid item xs={3}>
							<span className="fa fa-search" />
							<TextField
								size="small"
								variant="outlined"
								placeholder="Search…"
								value={this.state.searchText}
								onChange={(e) => {
									this.setState({ searchText: e.target.value }, () => {
										this.getRoutes();
									});

								}}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											{this.state.searchText ? <IconButton
												onClick={() => {
													this.setState({ searchText: "" }, () => {
														this.getRoutes();
													});
												}}
											>
												<Clear />
											</IconButton> : null}
										</InputAdornment>
									),
								}}
							/>
							{/* </div> */}
						</Grid>

						<Grid item xs={3}>
							<div className="dateLabel"> City: </div>
							<Autocomplete
								id="combo-box-demo"
								value={this.state.city}
								onChange={(event, newValue) => {
									this.handleCity(newValue);
								}}
								options={cities}
								style={{ width: 200 }}
								renderInput={(params) => <TextField {...params} />}
							/>
						</Grid>

						<Grid item xs={3}>
							<Button style={{ backgroundColor: "#0D8DA3" }} variant="contained" color="primary" onClick={() => this.downloadReport()}>
								Download
							</Button>
						</Grid>
						<Grid item xs={3}>
							<Button style={{ backgroundColor: "#0D8DA3" }} variant="contained" color="primary" onClick={() => { this.setState({ isOpen: !this.state.isOpen }) }}>
								Add Route
							</Button>

						</Grid>
					</Grid>

					<TableContainer component={Paper} className="mt-30">
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell>Area Name</TableCell>
									<TableCell>City</TableCell>
									<TableCell>Zipcode</TableCell>
									<TableCell>State</TableCell>
									<TableCell>Is Active?</TableCell>
									<TableCell>Action</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{((tRoutes || []).length <= 0) &&
									<TableRow>
										<TableCell component="th" scope="row"> No routes found. </TableCell>
									</TableRow>
								}
								{(tRoutes || []).map(route => (
									<TableRow key={route.routeId}>
										<TableCell>{route.areaName}</TableCell>
										<TableCell>{route.city}</TableCell>
										<TableCell>{route.zipcode}</TableCell>
										<TableCell>{route.state}</TableCell>
										<TableCell>
											<Switch color="primary" name="isActive" size="small"
												checked={route.isActive}
												onChange={(e) => {
													this.addRoutes({ routeId: route.routeId, isActive: e.target.checked, isEdit: true });
												}} />
										</TableCell>
										<TableCell>
											<IconButton edge="end" color="inherit" size="small" onClick={() => { this.setState({ isOpen: true, data: { ...route, isEdit: true } }) }}>
												<Edit />
											</IconButton>
											<IconButton edge="end" color="inherit" size="small" onClick={() => { this.setState({ isDeleteOpen: true, deleteObj: route }) }}>
												<Clear />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					{this.renderPagination()}

					{isOpen && this.renderPopup()}

					{isDeleteOpen && this.renderDeletePopup()}
				</div>
			</DocContainer>
		);
	}
}

const mapStateToProps = ({ constants, tRoutes }) => {
	return {
		isLoading: tRoutes.isSubmitting,
		cities: constants.cities,
		tRoutes: tRoutes.routes,
		hasMore: tRoutes.hasMore,
	}
};

export default connect(mapStateToProps)(TRoutes);
