import { 
  DEPT_LIST,

  IS_SUBMITTING,
} from 'Constants/actionConstants';


const dept = (state={
  
  isSubmitting: false,
  dept: [],

}, action)=> {

  switch (action.type) {
    
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };

    case DEPT_LIST:
      return {
        ...state,
        dept: action.data,
        hasMore: action.hasMore,
      };

    default:
      return state;
  }

}

export default dept;
