import {
  	IS_SUBMITTING,
    ALL_ZONES
} from 'Constants/actionConstants';


const zones = (state={
  
  isSubmitting: false,
  zones: [],
  hasMore: false,
}, action)=> {

  switch (action.type) {
    
    case IS_SUBMITTING:
      	return {
	        ...state,
	        isSubmitting: action.isSubmitting,
	    };

    case ALL_ZONES:
      return {
        ...state,
        zones: action.data,
        hasMore: action.hasMore
      };

    default:
      return state;
  }

}

export default zones;
