/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './clinics.css';

import DocContainer from './../../containers/DocContainer';
import ActionLoader from '../../components/ActionLoader';
import ImageSlider from '../../components/ImageSlider';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  Paper,
  Button,
  Grid,
  TextField,
  IconButton,
  Typography,
  Switch,
  Avatar,
  Tabs,
  Tab,
  Box,
  MenuItem
} from '@material-ui/core';

import {
  GET_PLEBOS,
  ADD_PLEBOS,
  DELETE_PLEBOS,
  GET_PLEBOS_KYC,
  ADD_PLEBOS_KYC,
  DELETE_PLEBOS_KYC,
  UPLOAD_IMAGE,
  GET_ALL_STATES,
  GET_ALL_CONSTANTS,
  GET_LPGS,
  GET_ATTENDANCE_LIST,
  ADD_ATTENDANCE_LIST,
} from './../../constants/actionConstants';

import { LEAVE_TYPES } from './../../constants/commonConstants';

import {
  Clear,
  Visibility,
  ArrowBackOutlined,
} from '@material-ui/icons';

import moment from 'moment';

import Geocode from 'react-geocode';

import AddPlebo from '../Plebo/pleboadd';

import DatePicker, { registerLocale } from 'react-datepicker';
import el from 'date-fns/locale/el';
registerLocale('el', el);

Geocode.setApiKey(process.env.REACT_APP_GEO_CODE_API);


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

class Plebo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      searchText: '',
      isError: false,
      isPleboOpen: false,
      kycUrl: '',
      kycDocumentType: '',
      tab: 1,
      formTab: 1,
      data: {
        firstName: '',
        lastName: '',
        mobileNumber: '',
        alternateMobileNumber: '',
        emailId: '',
        imageUrl: '',
        isActive: true,
        username: '',
        password: '',
        isInHouse: true,
        is_dedicated: false,
        dedicatedLgpId: '',
        address1: '',
        address2: '',
        landmark: '',
        city: '',
        state: '',
        zipcode: '',
        latitude: 0,
        longitude: 0,
        accepts_ppmc: false,
        accepts_covid: false,
        servicing_zipcodes: '',
        working_hours_start: '',
        working_hours_end: '',
        aadharUrl: [],
        panUrl: [],
        dlUrl: [],
        phleboType:'',
      },
      viewImages: [],
      startTime: '',
      endTime: '',
      comments: '',
      leaveType: '',
      city: '',
      status:'Working',
    };
  }

  UNSAFE_componentWillMount() {
    this.getPlebos();
    this.getAllConstants();
    this.getAllStates();
    this.getLGPs();
  }

  resetPopup = () => {
    let { data } = this.state;
    data = {
      firstName: '',
      lastName: '',
      mobileNumber: '',
      alternateMobileNumber: '',
      emailId: '',
      imageUrl: '',
      isActive: true,
      username: '',
      password: '',
      isInHouse: true,
      is_dedicated: false,
      dedicatedLgpId: '',
      address1: '',
      address2: '',
      landmark: '',
      city: '',
      state: '',
      zipcode: '',
      latitude: null,
      longitude: null,
      accepts_ppmc: false,
      accepts_covid: false,
      servicing_zipcodes: '',
      working_hours_start: '',
      working_hours_end: '',
    };
    this.setState({ data, isError: false });
  };

  getAllConstants = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_ALL_CONSTANTS,
    });
  };

  getAllStates = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_ALL_STATES,
    });
  };

  getAttendance = () => {
    const { dispatch, match } = this.props;
    dispatch({
      type: GET_ATTENDANCE_LIST,
      data: {
        count: 10,
        userType: 'PHLEBO',
        userId: match.params.id,
        offset: this.state.offset,
      },
    });
  };

  generateLatLong = () => {
    const { data } = this.state;
    const address = `${data.address1} ${data.address2 || ''} ${data.city} ${
      data.state
    } ${data.zipcode}`;
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        data.latitude = lat;
        data.longitude = lng;
        this.setState({ data });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  addAttendances = () => {
    const { dispatch, match } = this.props;
    dispatch({
      type: ADD_ATTENDANCE_LIST,
      data: {
        userId: match.params.id,
        userType: 'PHLEBO',
        status: 'ABSENT',
        leaveType: this.state.leaveType,
        startTime: new Date(this.state.startTime).getTime(),
        endTime: new Date(this.state.endTime).getTime(),
        comments: this.state.comments,
      },
      onSuccess: (data) => {
        this.setState({
          startTime: '',
          endTime: '',
          comments: '',
          isAttendAdd: false,
          leaveType: '',
        });
        this.getAttendance();
      },
    });
  };

  addEditPlebo = (phleboId) => {
    const { history } = this.props;
    if (phleboId) {
      history.push(`/phleboAdd/${phleboId}`);
    } else {
      history.push(`/phleboAdd`);
    }
  };

  redirectToDetail = (plebo) => {
    const { match, history } = this.props;
    this.setState(
      {
        offset: 0,
        data: {
          ...plebo,
          isEdit: true,
        },
      },
      () => {
        this.getPlebosKYC();
      }
    );
    history.push(`/phlebo/${plebo.phleboId}`, { state: plebo });
  };

  redirectToList = () => {
    const { match, history } = this.props;
    this.setState({ offset: 0 }, () => {
      this.getPlebos();
    });
    this.resetPopup();
    history.push(`/phlebo`);
  };

  getPlebos = (id) => {
    const { dispatch } = this.props;
    let data = {
      count: 10,
      offset: this.state.offset,
    };
    if (id) {
      data = { phleboId: id };
    }
    if (this.state.searchText && this.state.searchText.length > 0) {
      data['text'] = this.state.searchText;
    }

    if (this.state.city && this.state.city.length > 0) {
      data['city'] = this.state.city;
    }
    if (this.state.status && this.state.status.length > 0) {
      data['status'] = this.state.status;
    }

    dispatch({
      type: GET_PLEBOS,
      data,
    });
  };

  addPlebos = (dataSet) => {
    const { dispatch, match } = this.props;
    const data = dataSet ? dataSet : this.state.data;

    if (
      data &&
      data.servicing_zipcodes &&
      typeof data.servicing_zipcodes === 'string'
    ) {
      data.servicing_zipcodes = (data.servicing_zipcodes || '').split(',');
    }

    dispatch({
      type: ADD_PLEBOS,
      data,
      onSuccess: () => {
        this.setState({ isPleboOpen: false });
        if (!match.params.id) {
          this.resetPopup();
          this.getPlebos();
        }
      },
    });
  };

  deletePlebos = (id) => {
    const { dispatch } = this.props;
    dispatch({
      type: DELETE_PLEBOS,
      data: { phleboId: id },
      onSuccess: () => {
        this.getPlebos();
        this.setState({ isDeleteOpen: false, deleteObj: null });
      },
    });
  };

  getPlebosKYC = () => {
    const { dispatch, match } = this.props;
    let data = {
      count: 10,
      offset: this.state.offset,
      phleboId: match.params.id,
    };

    dispatch({
      type: GET_PLEBOS_KYC,
      data,
    });
  };

  addPlebosKYC = () => {
    const { dispatch, match } = this.props;
    const { kycUrl, kycDocumentType } = this.state;
    dispatch({
      type: ADD_PLEBOS_KYC,
      data: {
        url: kycUrl,
        phleboId: match.params.id,
        documentType: kycDocumentType,
      },
      onSuccess: () => {
        this.setState({ kycUrl: '', kycDocumentType: '' });
        this.getPlebosKYC();
      },
    });
  };

  deletePlebosKYC = (id) => {
    const { dispatch, match } = this.props;
    dispatch({
      type: DELETE_PLEBOS_KYC,
      data: { documentId: id, phleboId: match.params.id },
      onSuccess: () => {
        this.getPlebosKYC();
      },
    });
  };

  getLGPs = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_LPGS,
      data: {
        count: 200,
        offset: 0,
      },
    });
  };

  uploadImage = (file, fields, isAdd) => {
    const { dispatch } = this.props;
    dispatch({
      type: UPLOAD_IMAGE,
      file,
      params: { type: 15 },
      onSuccess: (res) => {
        if (res && res.imageUrl) {
          const { data } = this.state;
          data[fields] = [...data[fields], res.imageUrl];
          this.setState({ data });
        }
      },
    });
  };

  handleFormChange = (e, key) => {
    const { data } = this.state;
    data[key] = e.target.value;
    this.setState({ data });
  };

  handleDateChange = (date, key) => {
    const { data } = this.state;
    data[key] = date;
    this.setState({ data });
  };

  validateData = (currentTab, nextTab) => {
    const { data } = this.state;
    if (nextTab) {
      this.setState({ formTab: nextTab });
    }
  };

  
  renderPagination = (isKYC) => {
    return (
      <div className='mt-30'>
        <Grid container spacing={5}>
          <Grid item xs={9} />
          <Grid item xs={3}>
            <Button
              style={{ marginRight: '20px' }}
              size='medium'
              color='primary'
              variant='outlined'
              disabled={this.state.offset === 0}
              onClick={() => {
                this.setState({ offset: this.state.offset - 10 }, () => {
                  if (isKYC) {
                    this.getPlebosKYC();
                  } else {
                    this.getPlebos();
                  }
                });
              }}
            >
              {'< Previous'}
            </Button>
            <Button
              size='medium'
              color='primary'
              variant='outlined'
              disabled={!this.props.hasMore}
              onClick={() => {
                this.setState({ offset: this.state.offset + 10 }, () => {
                  if (isKYC) {
                    this.getPlebosKYC();
                  } else {
                    this.getPlebos();
                  }
                });
              }}
            >
              {'Next >'}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  };

  renderDeletePopup = () => {
    const { isDeleteOpen, data, deleteObj } = this.state;
    return (
      <Dialog
        disableBackdropClick
        open={isDeleteOpen}
        onClose={() => {
          this.setState({ isDeleteOpen: false });
        }}
      >
        <DialogTitle id='clinic-popup'>Confirm Delete</DialogTitle>
        <DialogContent>
          {' '}
          Are you sure you want to delete {deleteObj.firstName}{' '}
          {deleteObj.lastName} ?{' '}
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: '#00C9AA' }}
            onClick={() => {
              this.deletePlebos(deleteObj.phleboId);
            }}
            variant='contained'
            color='primary'
          >
            Yes
          </Button>
          <Button
            style={{ borderColor: '#00C9AA', color: '#00C9AA' }}
            onClick={() => {
              this.setState({ isDeleteOpen: false, deleteObj: null });
            }}
            variant='outlined'
            color='primary'
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderKYCTable = () => {
    const { plebosKYC } = this.props;
    return (
      <TableContainer component={Paper} className='mb-30'>
        <Table size='small' aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Link</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(plebosKYC || []).length <= 0 && (
              <TableRow>
                <TableCell component='th' scope='row'>
                  {' '}
                  No Documents found.{' '}
                </TableCell>
              </TableRow>
            )}
            {(plebosKYC || []).map((plebo, index) => (
              <TableRow key={`kyc-${plebo.documentId}-${index}`}>
                <TableCell>{plebo.documentType}</TableCell>
                <TableCell>
                  <a
                    href={plebo.url}
                    download
                    style={{ fontSize: '12px', color: 'blue' }}
                  >
                    <Avatar alt='img' size='small' src={plebo.url} />
                  </a>
                </TableCell>
                <TableCell>
                  <IconButton
                    edge='end'
                    color='inherit'
                    onClick={() => {
                      this.deletePlebosKYC(plebo.documentId);
                    }}
                  >
                    <Clear />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  renderKYCTab = (isKYC) => {
    const { isAddDoc, kycDocumentType, kycUrl } = this.state;

    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <h2 className='mt-0'>KYC DOCUMENTS</h2>
          </Grid>
          <Grid item>
            <Button
              style={{ backgroundColor: '#00C9AA' }}
              variant='contained'
              color='primary'
              onClick={() => {
                this.setState({ isAddDoc: !isAddDoc });
              }}
            >
              Add Document
            </Button>
          </Grid>
        </Grid>
        {isAddDoc && (
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label='Type'
                value={kycDocumentType}
                onChange={(e) => {
                  this.setState({ kycDocumentType: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={4}>
              <input
                type='file'
                className='inputFileUpload'
                onChange={(e) => {
                  this.uploadImage(e.target.files);
                }}
                ref={(ref) => {
                  this.input = ref;
                }}
              />
              {kycUrl && kycUrl.length > 0 && (
                <a href={kycUrl} style={{ color: 'blue', fontSize: '10px' }}>
                  <Avatar src={kycUrl} size='small' alt='doc' />
                </a>
              )}
            </Grid>
            <Grid item xs={3}>
              <Button
                style={{ backgroundColor: '#00C9AA' }}
                onClick={this.addPlebosKYC}
                variant='contained'
                color='primary'
              >
                Save
              </Button>
              <Button
                style={{ borderColor: '#00C9AA', color: '#00C9AA' }}
                onClick={() => {
                  this.setState({
                    kycUrl: '',
                    kycDocumentType: '',
                    isAddDoc: false,
                  });
                }}
                variant='outlined'
                color='primary'
              >
                Close
              </Button>
            </Grid>
          </Grid>
        )}

        {this.renderPagination(isKYC)}

        {this.renderKYCTable()}

        {this.renderPagination(isKYC)}
      </React.Fragment>
    );
  };

  renderAddAttendancePopup = () => {
    const { isAttendAdd } = this.state;
    return (
      <Dialog
        disableBackdropClick
        open={isAttendAdd}
        onClose={() => {
          this.setState({ isAttendAdd: false });
        }}
      >
        <DialogTitle id='clinic-popup'>{'Create Attendance'}</DialogTitle>
        <DialogContent style={{ width: '600px', height: '400px' }}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <div className='dateFilters'>
                <div className='dateLabel'>From Date</div>
                <DatePicker
                  selected={this.state.startTime}
                  onChange={(date) => {
                    this.setState({ startTime: date });
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className='dateFilters'>
                <div className='dateLabel'>To Date</div>
                <DatePicker
                  selected={this.state.endTime}
                  onChange={(date) => {
                    this.setState({ endTime: date });
                  }}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <label> Leave Type </label>
              <Select
                fullWidth
                value={this.state.leaveType}
                onChange={(e) => {
                  this.setState({ leaveType: e.target.value });
                }}
              >
                {(LEAVE_TYPES || []).map((leave, index) => (
                  <option key={`leave-${index + 1}`} value={leave}>
                    {' '}
                    {leave}{' '}
                  </option>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <label> Comments </label>
              <TextField
                fullWidth
                // label="Comments"
                value={this.state.comments}
                onChange={(e) => {
                  this.setState({ comments: e.target.value });
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: '#00C9AA' }}
            onClick={this.addAttendances}
            variant='contained'
            color='primary'
          >
            Save
          </Button>
          <Button
            style={{ borderColor: '#00C9AA', color: '#00C9AA' }}
            onClick={() => {
              this.setState({
                isAttendAdd: false,
                comments: '',
                startTime: '',
                endTime: '',
                leaveType: '',
              });
            }}
            variant='outlined'
            color='primary'
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderAttendance = () => {
    const { attendance } = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <h2 className='mt-0'>Attendance</h2>
          </Grid>
          <Grid item>
            <Button
              style={{ backgroundColor: '#00C9AA' }}
              variant='contained'
              color='primary'
              onClick={() => {
                this.setState({ isAttendAdd: !this.state.isAttendAdd });
              }}
            >
              Add Attendance
            </Button>
          </Grid>
        </Grid>
        <TableContainer component={Paper} className='mb-30'>
          <Table size='small' aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>Start Date - End Date</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Comments</TableCell>
                <TableCell>Created</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(attendance || []).length <= 0 && (
                <TableRow>
                  <TableCell component='th' scope='row'>
                    {' '}
                    No leaves applied.{' '}
                  </TableCell>
                </TableRow>
              )}
              {(attendance || []).map((att, index) => (
                <TableRow key={`kyc-${att.attendanceId}`}>
                  <TableCell>
                    {' '}
                    {moment(att.startTime).format('Do MMM, YYYY')} -{' '}
                    {moment(att.endTime).format('Do MMM, YYYY')}{' '}
                  </TableCell>
                  <TableCell> {att.leaveType} </TableCell>
                  <TableCell> {att.comments} </TableCell>
                  <TableCell>
                    {' '}
                    {moment(att.createdDate).format('Do MMM, YYYY h:mm A')}{' '}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    );
  };

  render() {
    const { cities, states, plebos, isLoading, match } = this.props;
    const {
      isDeleteOpen,
      isOpen,
      isPleboOpen,
      data,
      kycUrl,
      kycDocumentType,
    } = this.state;
    let isKYC = false;
    if (match.params && match.params.id) {
      isKYC = true;
    }

    return (
      <DocContainer>
        <div className='mr-20'>
          {isLoading && <ActionLoader />}
          {!isKYC ? (
            <React.Fragment>
               <Grid item xs={12}>
                  <h2 className='mt-0'>Phlebo</h2>
                </Grid>
              <Grid container spacing={2}>
               
                <Grid item xs={3}>
                <span className='fa fa-search' />
                    <TextField
                      size='small'
                      variant='outlined'
                      placeholder='Search…'
                      value={this.state.searchText}
                      onChange={(e) => {
                        this.setState({ searchText: e.target.value });
                      }}
                      onKeyPress={(e) => {
                        if (e.which == 13 || e.keyCode == 13) {
                          this.getPlebos();
                        }
                      }}
                    />
                    {this.state.searchText.length > 0 && (
                      <span
                        className='cancel-icon'
                        onClick={() => {
                          this.setState({ searchText: '' }, () => {
                            this.getPlebos();
                          });
                        }}
                      >
                        {' '}
                        x{' '}
                      </span>
                    )}
                  
                </Grid>
                {/* <Grid item xs={3} /> */}
                <Grid item xs={3} style={{ width: 130}}>
                  <div
                    className="cityLabel"
                    style={{
                      display: "inline-block",
                      verticalAlign: "top",
                      marginRight: "10px",
                      padding: "8px 0",
                      fontSize: "15px",
                    }}
                  >
                    {" "}
                    City:{" "}
                  </div>
                  <Select
                    size="small"
                    style={{ width: "130px" }} //fullWidth
                    value={this.state.city}
                    label="City"
                    onChange={(e) => {
                      this.setState(
                        {
                          city: e.target.value,
                        },
                        () => {
                          this.getPlebos();
                        }
                      );
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {(this.props.cities || []).map((city, index) => (
                      <MenuItem key={`city-${index}`} value={city}>
                        {" "}
                        {city}{" "}
                      </MenuItem>
                    ))}
                  </Select>
                  {this.state.city && (
                    <span
                      onClick={() => {
                        this.setState(
                          { city: "" },
                          () => {
                            this.getPlebos();
                          }
                        );
                      }}
                      style={{cursor:'pointer'}}
                    >
                      X
                    </span>
                  )}
                </Grid>
                <Grid item xs={3} style={{ width: 130 }}>
                  <div
                    className="cityLabel"
                    style={{
                      display: "inline-block",
                      verticalAlign: "top",
                      marginRight: "10px",
                      padding: "8px 0",
                      fontSize: "15px",
                    }}
                  >
                    {" "}
                    Working Status:{" "}
                  </div>
                  <Select
                    style={{ width: 100 }}
                    value={this.state.status}
                    onChange={(e) => {
                      this.setState(
                        {
                          status: e.target.value,
                        },
                        () => {
                          this.getPlebos();
                        }
                      );
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'Working'}>Working</MenuItem>
                    <MenuItem value={'Resigned'}>Resigned</MenuItem>
                    <MenuItem value={'Terminated'}>Terminated</MenuItem>
                    <MenuItem value={'Abscond'}>Abscond</MenuItem>
                    <MenuItem value={'On-Hold'}>On-Hold</MenuItem>
                  </Select>
                  {this.state.status && (
                    <span
                      onClick={() => {
                        this.setState(
                          { status: "" },
                          () => {
                            this.getPlebos();
                          }
                        );
                      }}
                      style={{cursor:'pointer'}}
                    >
                      X
                    </span>
                  )}
                </Grid>
                <Grid item xs={3} >
                <Button
                    style={{ backgroundColor: '#0D8DA3' }}
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      this.addEditPlebo();
                    }}
                  >
                    Add Phlebo
                  </Button> 
                </Grid>
              </Grid>

              {/* {this.renderPagination()} */}

              <TableContainer component={Paper} className='mt-30'>
                <Table size='small' aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Image</TableCell>
                      <TableCell>Emp ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Phone No</TableCell>
                      <TableCell>Username</TableCell>
                      <TableCell>City</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>Working Status</TableCell>
                      <TableCell>Is On Call Vendor?</TableCell>
                      <TableCell>Is Active?</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(plebos || []).length <= 0 && (
                      <TableRow>
                        <TableCell component='th' scope='row'>
                          {' '}
                          No phlebos found.{' '}
                        </TableCell>
                      </TableRow>
                    )}
                    {(plebos || []).map((plebo) => (
                      <TableRow key={plebo.phleboId}>
                        <TableCell>
                          <a href={plebo.imageUrl} download>
                            <Avatar
                              alt='img'
                              size='small'
                              src={plebo.imageUrl}
                            />
                          </a>
                        </TableCell>
                        <TableCell>
                          {plebo.employeeId}
                        </TableCell>
                        <TableCell>
                          {plebo.firstName} {plebo.lastName}
                        </TableCell>
                        <TableCell>{plebo.mobileNumber}</TableCell>
                        <TableCell>{plebo.username}</TableCell>
                        <TableCell>{plebo.city}</TableCell>
                        <TableCell>
                          {plebo.address1} {plebo.address2}
                        </TableCell>
                        <TableCell>
                        <Select
                            style={{width: 100}}
                            value={plebo.working_status || ""}
                            onChange={(e) => {
                              const data = { phleboId: plebo.phleboId };
                              data.working_status = e.target.value;
                              data.isEditWorkingStatus = true;
                              data.isEdit = true;
                              this.addPlebos(data);
                            }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={'Working'}>Working</MenuItem>
                            <MenuItem value={'Resigned'}>Resigned</MenuItem>
                            <MenuItem value={'Terminated'}>Terminated</MenuItem>
                            <MenuItem value={'Abscond'}>Abscond</MenuItem>
                            <MenuItem value={'On-Hold'}>On-Hold</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell>{plebo.is_on_call_vendor?'Yes':'No'}</TableCell>
                        <TableCell>
                          <Switch
                            size='small'
                            color='primary'
                            name='isActive'
                            checked={plebo.isActive}
                            onChange={(e) => {
                              const data = { ...plebo };
                              data.isActive = e.target.checked;
                              data.isEdit = true;
                              this.addPlebos(data);
                            }}
                          />
                        </TableCell>
                       <TableCell>
                          <div style={{ width: '75px' }}>
                            <IconButton
                              edge='end'
                              color='inherit'
                              size='small'
                              onClick={() => {
                                this.redirectToDetail(plebo);
                              }}
                            >
                              <Visibility />
                            </IconButton>
                            
                            <IconButton
                              edge='end'
                              color='inherit'
                              size='small'
                              onClick={() => {
                                this.setState({
                                  isDeleteOpen: true,
                                  deleteObj: plebo,
                                });
                              }}
                            >
                              <Clear />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {this.renderPagination()}

              {isPleboOpen && this.renderPopup()}

              {isDeleteOpen && this.renderDeletePopup()}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid container spacing={3}>
                <IconButton
                  edge='end'
                  color='inherit'
                  onClick={() => {
                    this.redirectToList();
                  }}
                >
                  <ArrowBackOutlined />
                </IconButton>
                <Grid item xs={4}>
                  {' '}
                  <h2 className='mt-0'>PHLEBO DETAILS</h2>{' '}
                </Grid>
              </Grid>
              <Tabs
                value={this.state.tab}
                onChange={(e, tab) =>
                  this.setState({ tab }, () => {
                    if (tab === 3) {
                      this.getAttendance();
                    }
                  })
                }
              >
                <Tab label='Basic' value={1} />
                <Tab label='KYC Documents' value={2} />
                <Tab label='Attendance' value={3} />
              </Tabs>
              <TabPanel value={this.state.tab} index={1}>
                <>
                  <AddPlebo phleboId={match.params.id} isView={true} redirectToList={this.redirectToList}/>
                </>
              </TabPanel>
              <TabPanel value={this.state.tab} index={2}>
                {this.renderKYCTab(isKYC)}
              </TabPanel>
              <TabPanel value={this.state.tab} index={3}>
                {this.renderAttendance()}
                {this.state.isAttendAdd && this.renderAddAttendancePopup()}
              </TabPanel>
            </React.Fragment>
          )}
        </div>
      </DocContainer>
    );
  }
}

const mapStateToProps = ({ plebos, constants, lgp }) => {
  return {
    isLoading: plebos.isSubmitting,
    plebos: plebos.plebos,
    hasMore: plebos.hasMore,
    plebosKYC: plebos.plebosKYC,
    attendance: plebos.attendance,
    lgps: lgp.plebos,

    cities: constants.cities,
    states: constants.states,
  };
};

export default withRouter(connect(mapStateToProps)(Plebo));
