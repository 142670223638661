/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { Component } from "react";
import { connect } from "react-redux";
import "./users.css";
import DocContainer from "./../../containers/DocContainer";
import ActionLoader from "../../components/ActionLoader";
import FormControl from '@material-ui/core/FormControl';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  Paper,
  Button,
  Grid,
  TextField,
  IconButton,
  Switch,
  Avatar,
  MenuItem,
  FormHelperText,
  FormLabel,
  InputAdornment,
} from "@material-ui/core";
import { USER_TYPE } from "Constants/commonConstants";

import {
  GET_USERS,
  GET_ALL_USERS,
  GET_DROPDOWN_USERS,
  ADD_USERS,
  DELETE_USERS,
  UPLOAD_IMAGE,
  GET_ALL_STATES,
  GET_ALL_CONSTANTS,
  GET_ROLES,
  GET_DEPT,
} from "../../constants/actionConstants";
import { Edit, Clear } from "@material-ui/icons";

import DatePicker, { registerLocale } from "react-datepicker";
import el from "date-fns/locale/el";
registerLocale("el", el);

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      searchText: "",
      isError: false,
      isOpen: false,
      subDepartments: [],
      designations: [],
      selectDesignation: '',
      selectDepartment: '',
      data: {
        isIDAutoGenerate: true,
        firstName: "",
        lastName: "",
        contact: "",
        altContact: "",
        landmark: "",
        emailId: "",
        profilePic: "",
        username: "",
        password: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        pincode: "",
        dob: "",
        departmentName: "",
        subDepartmentName: "",
        designationName: "",
        managerName: "",
        roleId: "",
        roleName: "",
        departmentId: "",
        isActive: "",
        isRestrictCity: false,
        userType: 0,
        working_status: '',
      },
      updatedData: {

      },
    };
  }

  UNSAFE_componentWillMount() {
    this.getUsers();
    this.getRoles();
    this.getDept();
    this.getAllConstants();
    this.getAllStates();
    this.getUsersOption();
  }

  resetPopup = () => {
    let { data } = this.state;
    data = {
      isIDAutoGenerate: true,
      firstName: "",
      lastName: "",
      contact: "",
      altContact: "",
      landmark: "",
      emailId: "",
      profilePic: "",
      username: "",
      password: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      pincode: "",
      dob: "",
      departmentName: "",
      subDepartmentName: "",
      designationName: "",
      managerName: "",
      roleName: "",
      roleId: "",
      isActive: "",
      isRestrictCity: false,
      departmentId: "",
    };
    this.setState({
      data,
      updatedData: {},
      isError: false,
    });
  };

  getAllConstants = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_ALL_CONSTANTS,
    });
  };

  getAllStates = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_ALL_STATES,
    });
  };

  getRoles = (id) => {
    const { dispatch } = this.props;
    let data = {
      count: 100,
      offset: this.state.offset,
    };
    if (id) {
      data = { roleId: id };
    }
    if (this.state.searchText && this.state.searchText.length > 0) {
      data["text"] = this.state.searchText;
    }

    dispatch({
      type: GET_ROLES,
      data,
    });
  };

  getDept = (id) => {
    const { dispatch } = this.props;
    let data = {
      count: 100,
      offset: this.state.offset,
    };
    if (id) {
      data = { deptId: id };
    }
    if (this.state.searchText && this.state.searchText.length > 0) {
      data["text"] = this.state.searchText;
    }

    dispatch({
      type: GET_DEPT,
      data,
    });
  };

  getUsers = (id) => {
    const { dispatch, match } = this.props;
    let data = {
      count: 10,
      offset: this.state.offset,
    };
    if (id) {
      data = { userId: id };
    }
    if (this.state.selectDesignation) {
      data['designationName'] = this.state.selectDesignation
    }
    if (this.state.selectDepartment) {
      data['departmentName'] = this.state.selectDepartment
    }
    if (match.params.id) {
      data = { userId: match.params.id };
    }

    if (this.state.searchText && this.state.searchText.length > 0) {
      data["text"] = this.state.searchText;
    }

    dispatch({
      type: GET_USERS,
      data,
    });
  };

  getDropDownUsers = (text, id = null) => {
    const { dispatch } = this.props;
    let payload = {
      count: 50,
      offset: this.state.offset,
    };
    if (text) {
      payload.text = text.split(' ')[0];
    }
    if (id) {
      payload.id = id;
    }
    dispatch({
      type: GET_ALL_USERS,
      data: payload,
      onSuccess: (data) => {
        this.setState({ allusers: data.list || [] })
      },
      onFailure: (data) => {
      },
    });
  }

  getUsersOption = (text, id = null) => {
    const { dispatch } = this.props;
    let payload = {
      count: 100,
      offset: this.state.offset,
    };
    if (text) {
      payload.text = text.split(' ')[0];
    }
    if (id) {
      payload.id = id;
    }
    dispatch({
      type: GET_DROPDOWN_USERS,
      data: payload,
      onSuccess: (data) => {
        this.setState({ allusers: data.list || [] })
      },
      onFailure: (data) => {
      },
    });
  }

  addUsers = (isEdit = false) => {
    const { dispatch, match } = this.props;
    const payload = JSON.parse(JSON.stringify(isEdit ? this.state.updatedData : this.state.data));
   
    if (isEdit) {
      payload.userId = this.state.data.userId
      payload.isEdit = true
    }

    if (payload.departmentName) {
      const res = (this.props.dept || []).find(
        (el) => el.name === payload.departmentName
      );
      payload.departmentId = res && res.departmentId ? res.departmentId : "";
    }


    if (payload.roleName) {
      const res = (this.props.roles || []).find(
        (el) => el.name === payload.roleName
      );
      payload.roleId = res && res.roleId ? res.roleId : "";
    }

    if (payload.city) {
      payload.city =
        typeof payload.city === "object" ? payload.city.join(",") : payload.city;
    }
    dispatch({
      type: ADD_USERS,
      data: payload,
      onSuccess: () => {
        this.setState({ isOpen: false });
        this.resetPopup();
        this.getUsers();
      },
    });
  };

  deleteUsers = (id) => {
    const { dispatch } = this.props;
    dispatch({
      type: DELETE_USERS,
      data: { userId: id },
      onSuccess: () => {
        this.setState({ isDeleteOpen: false, deleteObj: null });
        this.getUsers();
      },
    });
  };

  uploadImage = (file, isAdd) => {
    const { dispatch } = this.props;
    dispatch({
      type: UPLOAD_IMAGE,
      file,
      params: { type: 17 },
      onSuccess: (res) => {
        if (res && res.imageUrl) {
          if (isAdd) {
            const { data, updatedData } = this.state;
            data.profilePic = res.imageUrl;
            updatedData.profilePic = res.imageUrl;
            this.setState({ data, updatedData });
          }
        }
      },
    });
  };

  handleSelectChange = (event, key) => {
    const {
      target: { value },
    } = event;
    const { data, updatedData } = this.state;
    data[key] = typeof value === "string" ? value.split(",") : value;
    updatedData[key] = typeof value === "string" ? value.split(",") : value;
    this.setState({ data, updatedData });
  };

  handleAutoCompleteChange = (value, key) => {
    const { data, updatedData } = this.state;
    data[key] = value;
    updatedData[key] = value;
    this.setState({ data, updatedData });
  };

  handleFormChange = (e, key) => {
    const { data, updatedData } = this.state;
    data[key] = e.target.value;
   if (key !== "departmentName") {
      updatedData[key] = e.target.value;
      this.setState({ data, updatedData });
    } else {
      this.setState({ data }, () => {
        const res = (this.props.dept || []).find(
          (el) => el.name === this.state.data.departmentName
        );
        if (res) {
          updatedData['departmentName'] = e.target.value;
          updatedData['departmentId'] = res.departmentId || "";
          data["departmentId"] = res.departmentId || "";
          this.setState({
            data,
            updatedData,
            designations: res.designations,
            subDepartments: res.subDepartments,
          });
        }
      });
    }
  };

  handleDateChange = (date) => {
    const { data, updatedData } = this.state;
    data["dob"] = date;
    updatedData["dob"] = date;
    this.setState({ data, updatedData });
  };

  validateData = (isEdit = false) => {
    const { data } = this.state;
    if (
      data.firstName === "" ||
      data.lastName === "" ||
      data.contact === "" ||
      data.emailId === "" ||
      data.address1 === "" ||
      data.username === "" ||
      data.password === "" ||
      data.city === "" ||
      data.state === "" ||
      data.pincode === "" ||
      data.dob === "" ||
      data.departmentName === "" ||
      data.designationName === "" ||
      data.roleName === "" ||
      data.managerId === ""
    ) {
      this.setState({ isError: true });
    } else {
      this.setState({ isError: false });
      this.addUsers(isEdit);
    }
  };

  renderPagination = () => {
    return (
      <div className="mb-30">
        <Grid container spacing={5}>
          <Grid item xs={9} />
          <Grid item xs={3}>
            <Button
              style={{ marginRight: "20px" }}
              size="medium"
              color="primary"
              variant="outlined"
              disabled={this.state.offset === 0}
              onClick={() => {
                this.setState({ offset: this.state.offset - 10 }, () => {
                  this.getUsers();
                });
              }}
            >
              {"< Previous"}
            </Button>
            <Button
              size="medium"
              color="primary"
              variant="outlined"
              disabled={!this.props.hasMore}
              onClick={() => {
                this.setState({ offset: this.state.offset + 10 }, () => {
                  this.getUsers();
                });
              }}
            >
              {"Next >"}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  };

  renderFormFields = (data) => {
    const { isError } = this.state;
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="First Name *"
              value={data.firstName}
              onChange={(e) => this.handleFormChange(e, "firstName")}
              error={isError && data.firstName === ""}
              helperText={
                isError && data.firstName === ""
                  ? "Please enter a first name"
                  : " "
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Last Name *"
              value={data.lastName}
              onChange={(e) => this.handleFormChange(e, "lastName")}
              error={isError && data.lastName === ""}
              helperText={
                isError && data.lastName === ""
                  ? "Please enter a last name"
                  : " "
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Phone number *"
              value={data.contact}
              error={isError && data.contact === ""}
              helperText={
                isError && data.contact === "" ? "Please enter phone no." : " "
              }
              onChange={(e) => this.handleFormChange(e, "contact")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Alternate Number"
              value={data.altContact}
              onChange={(e) => this.handleFormChange(e, "altContact")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              value={data.emailId}
              label="Email ID *"
              error={isError && data.emailId === ""}
              helperText={
                isError && data.emailId === "" ? "Please enter Email." : " "
              }
              onChange={(e) => this.handleFormChange(e, "emailId")}
            />
          </Grid>
          <Grid item xs={6}>
            <label> Profile Image</label>
            <input
              type="file"
              className="inputFileUpload"
              onChange={(e) => {
                this.uploadImage(e.target.files, true);
              }}
              ref={(ref) => {
                this.input = ref;
              }}
            />
            {data.profilePic && data.profilePic.length > 0 && (
              <a
                href={data.profilePic}
                style={{ color: "blue", fontSize: "10px" }}
              >
                <Avatar size="small" src={data.profilePic} alt="icon" />
              </a>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              value={data.username}
              label="Username *"
              onChange={(e) => this.handleFormChange(e, "username")}
              error={isError && data.username === ""}
              helperText={
                isError && data.username === "" ? "Please enter username" : " "
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="password"
              label="Password *"
              value={data.password}
              onChange={(e) => this.handleFormChange(e, "password")}
              error={isError && data.password === ""}
              helperText={
                isError && data.password === "" ? "Please enter password" : " "
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Adress 1 *"
              value={data.address1}
              onChange={(e) => this.handleFormChange(e, "address1")}
              error={isError && data.address1 === ""}
              helperText={
                isError && data.address1 === "" ? "Please enter address" : " "
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Address 2"
              value={data.address2}
              onChange={(e) => this.handleFormChange(e, "address2")}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Landmark"
              value={data.landmark}
              onChange={(e) => this.handleFormChange(e, "landmark")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              InputProps={{ inputProps: { minLength: 6, maxLength: 6 } }}
              label="Zipcode *"
              value={data.pincode}
              error={isError && data.pincode === ""}
              helperText={
                isError && data.pincode === "" ? "Please enter Pincode" : " "
              }
              onChange={(e) => this.handleFormChange(e, "pincode")}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <label> City * </label>
            <Select
              fullWidth
              value={typeof data.city === "object" ? data.city : data.city && data.city !== "" ? data.city.split(',') : []}
              onChange={(e) => {
                this.handleSelectChange(e, "city");
              }}
            >
              {(this.props.cities || []).map((city, index) => (
                <MenuItem key={city} value={city}>
                  {city}
                </MenuItem>
              ))}
            </Select>
            {isError && data.city === "" ? (
              <div
                style={{ color: "red", letterSpacing: "1px", fontSize: "10px" }}
              >
                Please Select City
              </div>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={6}>
            <label> State *</label>
            <Select
              fullWidth
              value={data.state}
              onChange={(e) => {
                this.handleFormChange(e, "state");
              }}
            >
              {(this.props.states || []).map((state, index) => (
                <MenuItem key={`state-${state.id}`} value={state.name}>
                  {" "}
                  {state.name}{" "}
                </MenuItem>
              ))}
            </Select>
            {isError && data.state === "" ? (
              <div
                style={{ color: "red", letterSpacing: "1px", fontSize: "10px" }}
              >
                Please Select State
              </div>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <div className="dateFilters">
              <div className="dateLabel">DOB *</div>
              <DatePicker
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                placeholderText="Select a date"
                ateFormat="yyyy/MM/dd"
                selected={data.dob ? new Date(data.dob) : ''}
                onChange={(date) => {
                  this.handleDateChange(date, "dob");
                }}
              />
              <p style={{ color: 'red' }}>{isError && !data.dob && 'Please select Dob'}</p>
            </div>
          </Grid>
          <Grid item xs={6}>
            <label> Department *</label>
            <Select
              fullWidth
              value={data.departmentName}
              onChange={(e) => {
                this.handleFormChange(e, "departmentName");
              }}
            >
              {(this.props.dept || []).map((state, index) => (
                <MenuItem key={`state-${state.id}`} value={state.name}>
                  {" "}
                  {state.name}{" "}
                </MenuItem>
              ))}
            </Select>
            {isError && data.departmentName === "" ? (
              <div
                style={{ color: "red", letterSpacing: "1px", fontSize: "10px" }}
              >
                Please Select Department
              </div>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <label> Sub Department</label>
            <Select
              fullWidth
              value={data.subDepartmentName}
              onChange={(e) => {
                this.handleFormChange(e, "subDepartmentName");
              }}
            >
              {(this.state.subDepartments || []).map((state, index) => (
                <MenuItem key={`state-${state}`} value={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <label> Designation *</label>
            <Select
              fullWidth
              value={data.designationName}
              onChange={(e) => {
                this.handleFormChange(e, "designationName");
              }}
            >
              {(this.state.designations || []).map((state, index) => (
                <MenuItem key={`state-${state}`} value={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
            {isError && data.designationName === "" ? (
              <div
                style={{ color: "red", letterSpacing: "1px", fontSize: "10px" }}
              >
                Please Select Designation
              </div>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <label> Role *</label>
            <Select
              fullWidth
              value={data.roleName}
              onChange={(e) => {
                this.handleFormChange(e, "roleName");
              }}
            >
              {(this.props.roles || []).map((state, index) => (
                <MenuItem key={`state-${state.id}`} value={state.name}>
                  {" "}
                  {state.name}{" "}
                </MenuItem>
              ))}
            </Select>
            {isError && data.roleName === "" ? (
              <div
                style={{ color: "red", letterSpacing: "1px", fontSize: "10px" }}
              >
                Please Select Role
              </div>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={6}>
            <label> Reporting Manager *</label>
            <Select
              fullWidth
              value={data.managerName}
              onChange={(e) => {
                this.handleFormChange(e, "managerName");
              }}
            >
               {(this.state.allusers || []).map((state, index) => (
                  <option key={`mode-${state.id}`} value={`${state.firstName} ${state.lastName}`}>
                    {" "}
                    {state.firstName}{" "}{state.lastName}
                  </option>
                ))}
            </Select>
            {isError && data.managerName === "" ? (
              <div
                style={{ color: "red", letterSpacing: "1px", fontSize: "10px" }}
              >
                Please Select Reporting Manager
              </div>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={6}>
          
            {isError && data.managerId === "" ? (
              <div
                style={{ color: "red", letterSpacing: "1px", fontSize: "10px" }}
              >
                Please Select Reporting
              </div>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <h2 className='mt-0 boxed-select'>
              <FormLabel component='legend' className='text-label'>
                User Type<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
              </FormLabel>
              <Select
                fullWidth
                value={data.userType || ""}
                error={isError && data.state === ''}
                onChange={(e) => {
                  this.handleFormChange(e, "userType");
                  this.setState({ userTypeNum: e.target.value })
                }}
              >
                {(USER_TYPE || []).map((status, index) => (
                  <option key={`mode-${status.id}`} value={status.id}>
                    {" "}
                    {status.name}{" "}
                  </option>
                ))}
              </Select>
              <p style={{ fontSize: '10px', color: 'red' }}>{isError && data.state === '' && 'Please Select Doctor Type.'}</p>
            </h2>
          </Grid>
          {this.state.userTypeNum === 3 ? <Grid item xs={6}>
            <h2 className='mt-0'>
              <FormLabel component='legend' className='text-label'>
                Vendor Name
              </FormLabel>
              <TextField
                fullWidth
                variant='outlined'
                value={data.vendorName}
                onChange={(e) => this.handleFormChange(e, 'vendorName')}
              />
            </h2>
          </Grid> : null}
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <h2 className='mt-0 boxed-select'>
              <FormLabel component='legend' className='text-label'>
                Working Status<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
              </FormLabel>
              <Select
                style={{ width: 100 }}
                value={data.working_status || ''}
                onChange={(e) => {
                  this.handleFormChange(e, "working_status");
                }}
                error={isError && data.working_status === ''}
              >
                <MenuItem value=''>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={'Working'}>Working</MenuItem>
                <MenuItem value={'Resigned'}>Resigned</MenuItem>
                <MenuItem value={'Terminated'}>Terminated</MenuItem>
                <MenuItem value={'Abscond'}>Abscond</MenuItem>
                <MenuItem value={'On-Hold'}>On-Hold</MenuItem>
              </Select>
              <p style={{ fontSize: '10px', color: 'red' }}>{isError && data.working_status === '' && 'Please select working status.'}</p>

            </h2>
          </Grid>
          <Grid item xs={4}>
            <label>Is Active?</label>
            <Switch
              color="primary"
              name="isActive"
              size="small"
              checked={data.isActive}
              onChange={(e) => {
                const { data, updatedData } = this.state;
                data.isActive = e.target.checked;
                updatedData.isActive = e.target.checked;
                this.setState({ data });
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <label>Is Restrict City?</label>
            <Switch
              color="primary"
              name="isRestrictCity"
              size="small"
              checked={data.isRestrictCity}
              onChange={(e) => {
                const { data, updatedData } = this.state;
                data.isRestrictCity = e.target.checked;
                updatedData.isRestrictCity = e.target.checked;
                this.setState({ data });
              }}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  renderPopup = () => {
    const { isOpen, data } = this.state;
    return (
      <Dialog
        disableBackdropClick
        open={isOpen}
        fullWidth
        maxWidth="md"
        onClose={() => {
          this.setState({ isOpen: false });
        }}
      >
        <DialogTitle id="clinic-popup">
          {data.isEdit ? `Edit User - ${data.employeeId}` : "Create New User"}
        </DialogTitle>
        <DialogContent>{this.renderFormFields(data)}</DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            onClick={() => this.validateData(data.isEdit)}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button
            style={{ borderColor: "#00C9AA", color: "#00C9AA" }}
            onClick={() => {
              this.setState({ isOpen: false });
              this.resetPopup();
            }}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderDeletePopup = () => {
    const { isDeleteOpen, data, deleteObj } = this.state;
    return (
      <Dialog
        disableBackdropClick
        open={isDeleteOpen}
        onClose={() => {
          this.setState({ isDeleteOpen: false });
        }}
      >
        <DialogTitle id="clinic-popup">Confirm Delete</DialogTitle>
        <DialogContent>
          {" "}
          Are you sure you want to delete {deleteObj.firstName} ?{" "}
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            onClick={() => {
              this.deleteUsers(deleteObj.userId);
            }}
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
          <Button
            style={{ borderColor: "#00C9AA", color: "#00C9AA" }}
            onClick={() => {
              this.setState({ isDeleteOpen: false, deleteObj: null });
            }}
            variant="outlined"
            color="primary"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderBasicDetails = () => {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            {this.renderFormFields(this.state.data)}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={1}>
            <Button
              style={{ backgroundColor: "#00C9AA" }}
              onClick={() => this.validateData(false)}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  formatCity = (value) => {
    return typeof value === "object" ? value.join(",") : value;
  };

  changeDepartment = (e) => {
    this.setState({ selectDepartment: e.target.value })
  }

  changeDesignation = (e) => {
    this.setState({ selectDesignation: e.target.value })
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.selectDesignation !== this.state.selectDesignation) {
      this.getUsers();
    }
    if (prevState.selectDepartment !== this.state.selectDepartment) {
      this.getUsers();
    }

  }

  render() {
    const { users, isLoading, match } = this.props;
    const {
      isOpen,
      data,
      isDeleteOpen,
      isDeleteRoleOpen,
      isDeleteDeptOpen,
    } = this.state;
    let isView = false;
    if (match.params && match.params.id) {
      isView = true;
    }

    const departments = (users || []).filter((x) => x.departmentName !== undefined).map((x) => x.departmentName);
    const designations = (users || []).filter((x) => x.designationName !== undefined).map((x) => x.designationName);


    return (
      <DocContainer>
        <div className="mr-20">
          {isLoading && <ActionLoader />}
          <React.Fragment>
            <Grid item xs={12}>
              <h2 className="mt-0">Users</h2>
            </Grid>
            <Grid container spacing={2}>

              <Grid item xs={3}>
                {/* <div className="search-clinic pr"> */}
                <span className="fa fa-search" />
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  placeholder="Search…"
                  value={this.state.searchText}
                  onChange={(e) => {
                    this.setState({ searchText: e.target.value }, () => {
                      this.getUsers();
                    });
                    
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                       {this.state.searchText ? <IconButton
                           onClick={() => {
                            this.setState({ searchText: "" }, () => {
                              this.getUsers();
                            });
                          }}
                        >
                        <Clear/>
                        </IconButton> : null }
                      </InputAdornment>
                    ),
                  }}
                />
               
                
                {/* </div> */}
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    // value={data.designationName}
                    value={this.state.selectDepartment}
                    onChange={(e) => {
                      this.changeDepartment(e)
                    }}
                    variant="outlined"
                  >
                    {departments.map((state, index) => (
                      <MenuItem key={`state-${index}`} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText style={{ fontSize: "13px" }}>Department</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth>

                  <Select
                    fullWidth
                    // value={data.designationName}
                    value={this.state.selectDesignation}
                    onChange={(e) => {
                      this.changeDesignation(e)
                    }}
                    variant="outlined"
                  >
                    {designations.map((state, index) => (
                      <MenuItem key={`state-${index}`} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText style={{ fontSize: "13px" }}>Designation</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={3} >
                <Button
                  style={{ backgroundColor: "#0D8DA3" }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.setState({ isOpen: !this.state.isOpen });
                  }}
                >
                  Add User
                </Button>
              </Grid>
            </Grid>

            {/* {this.renderPagination()} */}

            <TableContainer component={Paper} className="mt-30">
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Emp Id</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Phone number</TableCell>
                    <TableCell>Department</TableCell>
                    <TableCell>Sub Department</TableCell>
                    <TableCell>Designation</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Manager</TableCell>
                    <TableCell>City Responsible</TableCell>
                    <TableCell>Is Active</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(users || []).length <= 0 && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {" "}
                        No User found.{" "}
                      </TableCell>
                    </TableRow>
                  )}
                  {(users || []).map((run) => (
                    <TableRow key={run.userId}>
                      <TableCell>
                        <a href={run.profilePic} download>
                          <Avatar src={run.profilePic} alt="img" size="small" />
                        </a>
                      </TableCell>
                      <TableCell>{run.employeeId}</TableCell>
                      <TableCell>
                        {run.firstName} {run.lastName}
                      </TableCell>
                      <TableCell>{run.contact}</TableCell>
                      <TableCell>{run.departmentName}</TableCell>
                      <TableCell>{run.subDepartmentName}</TableCell>
                      <TableCell>{run.designationName}</TableCell>
                      <TableCell>{run.roleName}</TableCell>
                      <TableCell>{run.managerName}</TableCell>
                      <TableCell>{this.formatCity(run.city)}</TableCell>
                      <TableCell>
                        <Switch
                          size="small"
                          color="primary"
                          name="isActive"
                          checked={run.isActive}
                          onChange={(e) => {
                            const data = { ...run };
                            data.isActive = e.target.checked;
                            data.isEdit = true;
                            this.setState({ data, updatedData: data }, () => this.addUsers());
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <div style={{ width: "75px" }}>
                          <IconButton
                            edge="end"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              this.setState(
                                {
                                  isOpen: true,
                                  updatedData: { ...run, isEdit: true },
                                  data: { ...run, isEdit: true },
                                },
                                () => {
                                  const res = (this.props.dept || []).find(
                                    (el) =>
                                      el.name === this.state.data.departmentName
                                  );
                                  if (res) {
                                    this.setState({
                                      designations: res.designations,
                                      subDepartments: res.subDepartments,
                                    });
                                  }
                                }
                              );
                            }}
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            edge="end"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              this.setState({
                                isDeleteOpen: true,
                                deleteObj: run,
                              });
                            }}
                          >
                            <Clear />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {this.renderPagination()}

            {isOpen && this.renderPopup()}

            {isDeleteOpen && this.renderDeletePopup()}
          </React.Fragment>
        </div>
      </DocContainer>
    );
  }
}

const mapStateToProps = ({ users, roles, dept, constants }) => {
  return {
    isLoading: users.isSubmitting,
    hasMore: users.hasMore,
    users: users.users,
    roles: roles.roles,
    dept: dept.dept,
    cities: constants.cities,
    states: constants.states,
  };
};

export default connect(mapStateToProps)(Users);
