import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';

import DocContainer from './../../containers/DocContainer';
import ActionLoader from "../../components/ActionLoader";
import { getUser } from 'Utils/storage';

import {
	Tabs, Tab, Grid, Button, TextField, Typography, Box, Select, IconButton, Switch,
	TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper,
	Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, FormControlLabel,
	Card, CardContent, CardActions, Chip, Avatar, InputAdornment,
} from '@material-ui/core';
import { Clear} from "@material-ui/icons";
import { GET_REPORT_DELAY, GET_ALL_CONSTANTS, UPDATE_REPORT_STATUS, UPDATE_TICKET, GET_REPORT_SLOTS, UNASSIGN_ORDER, GET_PLEBOS, ASSIGN_ORDER, ACCEPTREJECT_ORDER } from './../../constants/actionConstants';

import { ORDER_CONSTANTS } from './../../constants/commonConstants';

import { SettingsBackupRestore, Edit, PublishOutlined } from '@material-ui/icons';

import moment from 'moment';

import DatePicker, { registerLocale } from "react-datepicker";
import el from "date-fns/locale/el";
import { GET_LPGS } from 'Constants/actionConstants';
registerLocale("el", el);



const REJECT_REASON = [
	{ name: 'Cancelled by Customer', value: 1 },
	{ name: 'Postponed by Customer', value: 2 },
	{ name: 'Cancelled by Client Dispatcher', value: 3 },
	{ name: 'Rejected by I2H', value: 4 },
	{ name: 'Completed', value: 5 },
	{ name: 'Cancelled by customer due to phlebo mistake', value: 6 },
	{ name: 'Postponed by customer due to phlebo mistake', value: 7 },
];

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`nav-tabpanel-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</Typography>
	);
}


class Reports extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tab: 1,
			text: '',
			type: 4,
			city: localStorage.getItem('reportCity') ? localStorage.getItem('reportCity') : 'Bangalore',
			dateFilter: new Date().getTime(),
			remarks: {},
			comments: {},
			postponedObj: {},
			slots: {},
			selectedOrders: [],
			searchOrderSeq: '',
			searchPhelbo: '',
			assignUpload: { name: '', purpose: '', email: '' },
			rejectType: null,
			orderCancelProof: '',
			orderCancelVideoProof: '',
			orderCancelAudioProof: '',
			isReschedule: false,
			slotFrom: new Date(),
			slotTo: new Date(),
			lgp: undefined,
			timings: undefined,
			timeValue: this.timings[0].name
		};
	}

	UNSAFE_componentWillMount() {
		this.getDelayReports(true);
		// this.getDelayReports(3);
		this.getAllConstants();
		this.getLGPs();
	}

	getDelayReports = (isFirstLoad, tabId) => {
		const { dispatch } = this.props;
		const data = {
			pdsType: 1,
			cdsType: 1,
			type: this.state.type,
		}
		if (this.state.lgp) {
			data['lgp'] = this.state.lgp
		}
		if (this.state.city) {
			data['city'] = this.state.city;
		}
		if (this.state.timings) {
			data['slot'] = Array.prototype.map.call(this.state.timings, function (item) { return item.id; }).join(",");
		}
		if (this.state.dateFilter) {
			data['month'] = moment(this.state.dateFilter).month() + 1;
			data['date'] = moment(this.state.dateFilter).date();
			data['year'] = moment(this.state.dateFilter).year();
		}
		if (this.state.text) {
			data['text'] = this.state.text;
		}
		if (this.state.searchOrderSeq) {
			data['orderSeq'] = this.state.searchOrderSeq;
		}
		dispatch({
			type: GET_REPORT_DELAY,
			reportType: tabId ? tabId : this.state.tab,
			data,
			onSuccess: (() => {
				if (isFirstLoad) {
					this.getDelayReports(false, 3);
				} else {
					setTimeout(() => {
						this.getDelayReports(false, 3)
					}, 5 * 60 * 1000);
				}
			})
		})
	}

	getAllConstants = () => {
		const { dispatch } = this.props;
		dispatch({
			type: GET_ALL_CONSTANTS
		});
	}

	getTimeSlots = (order) => {
		const { dispatch } = this.props;
		dispatch({
			type: GET_REPORT_SLOTS,
			data: { orderId: order.orderId },
			onSuccess: ((data) => {
				const { slots } = this.state;
				slots[order.orderId] = data.list || [];
				this.setState({
					slots
				});
			})
		});
	}

	getPlebos = (id) => {
		const { dispatch } = this.props;

		let data = {
			count: 100,
			offset: 0 //this.state.offset
		};
		if (id) {
			data = { phleboId: id };
		}
		if (this.state.searchPhelbo && this.state.searchPhelbo.length > 0) {
			data['text'] = this.state.searchPhelbo;
		}

		dispatch({
			type: GET_PLEBOS,
			data,
		});
	}

	updateTicket = (obj, type) => {
		const { dispatch } = this.props;
		const { postponedObj } = this.state;
		const data = {
			orderId: obj.orderId,
			optionType: type,
			earliest_pickup_date: postponedObj && postponedObj[obj.orderId] ? postponedObj[obj.orderId].earliest_pickup_date : obj.earliest_pickup_date,
			latest_pickup_date: postponedObj && postponedObj[obj.orderId] ? postponedObj[obj.orderId].latest_pickup_date : obj.latest_pickup_date,
			comments: this.state.comments[obj.orderId]
		};
		dispatch({
			type: UPDATE_TICKET,
			data,
			onSuccess: (() => {
				this.setState({ comments: {}, postponedObj: {}, slots: {} }, () => {
					this.getDelayReports();
				});
			})
		})
	}

	updateReportStatus = (obj, type) => {
		const { dispatch } = this.props;
		let data = {};
		if (type === 'delay') {
			data = {
				phleboId: obj.phleboId,
				orderId: obj.orderId,
				delay: this.state.delay
			};
		} else {
			data = {
				orderId: obj.orderId,
				csc_comments: this.state.remarks[obj.orderId],
			};
		}
		dispatch({
			type: UPDATE_REPORT_STATUS,
			data,
			onSuccess: (() => {
				if (type === 'delay') {
					this.setState({
						isEdit: false,
						editObj: null,
						delay: ''
					}, () => {
						this.getDelayReports();
					});
				} else {
					this.setState({ remarks: {} }, () => {
						this.getDelayReports();
					});
				}
			})
		})
	}

	unassignOrder = (order) => {
		const { dispatch } = this.props;
		dispatch({
			type: UNASSIGN_ORDER,
			data: { orderId: order.orderId, phleboId: order.phleboId, type: this.state.type },
			onSuccess: (() => {
				this.getDelayReports();
				this.setState({ isDeleteOpen: false });
			})
		})
	}

	assignOrder = () => {
		const { dispatch } = this.props;
		dispatch({
			type: ASSIGN_ORDER,
			data: {
				orderId: this.state.selectedOrders,
				phleboId: this.state.phleboId,
				firstName: this.state.firstName,
				lastName: this.state.lastName,
				slotFrom: new Date(this.state.slotFrom).getTime(),
				slotTo: new Date(this.state.slotTo).getTime(),
				isOverride: this.state.isOverride,
				version: this.state.version,
				sub_version: this.state.sub_version,
				type: this.state.type,
			},
			onSuccess: ((data) => {
				this.getDelayReports();
				this.setState({
					isOrderAssign: false,
					selectedOrders: [],
					phleboId: '',
					firstName: '',
					lastName: '',
					slotFrom: '',
					slotTo: '',
					isOverride: '',
				})
			})
		})
	}

	acceptRejectOrder = (status) => {
		const { dispatch } = this.props;
		const data = { orderId: this.state.selectedOrders, orderStatus: status };

		if (status === 3) {
			const { orderCancelProof, orderCancelVideoProof, orderCancelAudioProof, isReschedule, slotFrom, slotTo } = this.state;
			data['isReschedule'] = isReschedule;

			if (this.state.rejectComments) {
				data['rejectComments'] = this.state.rejectComments;
			}
			if (this.state.rejectType) {
				data['rejectType'] = this.state.rejectType;
			}
			if (orderCancelProof) {
				data['orderCancelProof'] = orderCancelProof;
			}
			if (orderCancelVideoProof) {
				data['orderCancelVideoProof'] = orderCancelVideoProof;
			}
			if (orderCancelAudioProof) {
				data['orderCancelAudioProof'] = orderCancelAudioProof;
			}
			data['slotFrom'] = new Date(slotFrom).getTime();
			data['slotTo'] = new Date(slotTo).getTime();
		}
		dispatch({
			type: ACCEPTREJECT_ORDER,
			data,
			onSuccess: (() => {
				this.getDelayReports();
				this.setState({ selectedOrders: [] });
			})
		})
	}

	renderPopup = () => {
		const { isEdit, data, editObj, delay } = this.state
		return (
			<Dialog disableBackdropClick open={isEdit} onClose={() => { this.setState({ isEdit: false }) }}>
				<DialogTitle>Update Delay</DialogTitle>
				<DialogContent>
					<TextField size="small" placeholder="Delay"
						type="number"
						value={delay || ''}
						onChange={e => this.setState({ delay: Number(e.target.value) })}
					/>
				</DialogContent>
				<DialogActions>
					<Button style={{ backgroundColor: "#00C9AA" }} onClick={() => { this.updateReportStatus(editObj, 'delay'); }} variant="contained" color="primary">Save</Button>
					<Button style={{ borderColor: "#00C9AA", color: "#00C9AA" }} onClick={() => { this.setState({ isEdit: false, editObj: null }) }} variant="outlined" color="secondary">Cancel</Button>
				</DialogActions>
			</Dialog>
		)
	}


	renderDeletePopup = () => {
		const { isDeleteOpen, deleteObj } = this.state
		return (
			<Dialog disableBackdropClick open={isDeleteOpen} onClose={() => { this.setState({ isDeleteOpen: false }) }}>
				<DialogTitle id="clinic-popup">Confirm Unassign Order</DialogTitle>
				<DialogContent > Are you sure you want to unassign order {deleteObj.orderSequence} for phlebo {deleteObj.phleboName} ? </DialogContent>
				<DialogActions>
					<Button style={{ backgroundColor: "#00C9AA" }} onClick={() => { this.unassignOrder(deleteObj) }} variant="contained" color="primary">Yes</Button>
					<Button style={{ borderColor: "#00C9AA", color: "#00C9AA" }} onClick={() => { this.setState({ isDeleteOpen: false, deleteObj: null }) }} variant="outlined" color="primary">No</Button>
				</DialogActions>
			</Dialog>
		)
	}

	getLGPs = () => {
		const { dispatch } = this.props;
		dispatch({
			type: GET_LPGS,
			data: {
				count: 200,
				offset: 0,
			},
		});
	};

	renderRejectPopup = () => {
		const { isReject, rejectType, orderCancelProof, orderCancelVideoProof, orderCancelAudioProof } = this.state
		return (
			<Dialog disableBackdropClick open={isReject} onClose={() => {
				this.setState({
					isReject: false,
					rejectComments: null,
					slotFrom: new Date(),
					slotTo: new Date(),
					isReschedule: false,
					orderCancelProof: "",
					orderCancelVideoProof: "",
					orderCancelAudioProof: ""
				})
			}}>
				<DialogTitle id="clinic-popup">Reason for rejecting</DialogTitle>
				<DialogContent>
					<div className="mb-20">
						<label> Reason </label>
						<Select fullWidth
							value={rejectType}
							onChange={(e) => { this.setState({ rejectType: e.target.value }) }}
						>
							{(REJECT_REASON || []).map((reason, index) => (
								<option key={`reason-${reason.value}`} value={reason.value}> {reason.name} </option>
							))}
						</Select>
					</div>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<label> Reject Reason </label>
							<TextField
								fullWidth
								size="small"
								variant="outlined"
								placeholder="Rejection Reason"
								value={this.state.rejectComments}
								onChange={(e) => { this.setState({ rejectComments: e.target.value }) }}
							/>
						</Grid>

						<Grid item xs={6}>
							<label>Order Cancel Proof</label>
							<input type="file" //style={{ display: 'none' }}
								className="inputFileUpload"
								onChange={(e) => { this.uploadRejectionImage(e, 'orderCancelProof') }}
								ref={(ref) => { this.orderCancelProof = ref; }}
							/>
							{orderCancelProof && orderCancelProof.length > 0 &&
								<a href={orderCancelProof} style={{ color: 'blue', fontSize: '10px' }}>
									<Avatar size="small" src={orderCancelProof} alt="icon" />
								</a>
							}
						</Grid>

						<Grid item xs={6}>
							<label>Order Cancel Video Proof</label>
							<input type="file" //style={{ display: 'none' }}
								className="inputFileUpload"
								onChange={(e) => { this.uploadRejectionImage(e, 'orderCancelVideoProof') }}
								ref={(ref) => { this.orderCancelVideoProof = ref; }}
							/>
							{orderCancelVideoProof && orderCancelVideoProof.length > 0 &&
								<a href={orderCancelVideoProof} style={{ color: 'blue', fontSize: '10px' }}>
									<Avatar size="small" src={orderCancelVideoProof} alt="icon" />
								</a>
							}
						</Grid>

						<Grid item xs={6}>
							<label>Order Cancel Audio Proof</label>
							<input type="file" //style={{ display: 'none' }}
								className="inputFileUpload"
								onChange={(e) => { this.uploadRejectionImage(e, 'orderCancelAudioProof') }}
								ref={(ref) => { this.orderCancelAudioProof = ref; }}
							/>
							{orderCancelAudioProof && orderCancelAudioProof.length > 0 &&
								<a href={orderCancelAudioProof} style={{ color: 'blue', fontSize: '10px' }}>
									<Avatar size="small" src={orderCancelAudioProof} alt="icon" />
								</a>
							}
						</Grid>

						<Grid item xs={6}>
							<div className="dateFilters">
								<div className="dateLabel">Slot From</div>
								<DatePicker
									showTimeSelect
									timeFormat="HH:mm"
									timeIntervals={15}
									timeCaption="time"
									dateFormat="MMMM d, yyyy h:mm aa"
									placeholderText="Select a date"
									selected={this.state.slotFrom}
									onChange={(date) => {
										this.setState({ slotFrom: new Date(date).getTime() });
									}}
								/>
							</div>
						</Grid>
						<Grid item xs={6}>
							<div className="dateFilters">
								<div className="dateLabel">Slot To</div>
								<DatePicker
									showTimeSelect
									timeFormat="HH:mm"
									timeIntervals={15}
									timeCaption="time"
									dateFormat="MMMM d, yyyy h:mm aa"
									placeholderText="Select a date"
									selected={this.state.slotTo}
									onChange={(date) => {
										this.setState({ slotTo: new Date(date).getTime() });
									}}
								/>
							</div>
						</Grid>

						<Grid item xs={6}>
							<FormControlLabel
								label="Is Reschedule?"
								control={
									<Checkbox size="small" color="primary"
										checked={this.state.isReschedule}
										onChange={(e) => {
											this.setState({ isReschedule: e.target.checked })
										}}
									/>
								}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button style={{ backgroundColor: "#00C9AA" }} onClick={() => {
						this.acceptRejectOrder(3);
						this.setState({
							isReject: false,
							rejectComments: null,
							isReschedule: false,
							slotFrom: new Date(),
							slotTo: new Date(),
							orderCancelProof: "",
							orderCancelVideoProof: "",
							orderCancelAudioProof: ""
						})
					}} variant="contained" color="primary">Reject</Button>
					<Button style={{ borderColor: "#00C9AA", color: "#00C9AA" }} onClick={() => {
						this.setState({
							isReject: false,
							rejectComments: null,
							slotFrom: new Date(),
							slotTo: new Date(),
							isReschedule: false,
							orderCancelProof: "",
							orderCancelVideoProof: "",
							orderCancelAudioProof: ""
						})
					}} variant="outlined" color="primary">Cancel</Button>
				</DialogActions>
			</Dialog>
		)
	}

	renderAssignPopup = () => {
		const { isOrderAssign, data, phleboId, assignUpload } = this.state;
		const { plebos } = this.props;

		const selectedtype = ORDER_CONSTANTS.find(el => { return el.id === 4 });
		const isPartner = getUser().userType === 2;

		return (
			<Dialog disableBackdropClick open={isOrderAssign} onClose={() => { this.setState({ isOrderAssign: false }) }}>
				<DialogTitle id="clinic-popup">Assign Phlebo</DialogTitle>
				<DialogContent style={{ width: '600px', height: '400px' }}>
					<div style={{ marginBottom: '20px', fontSize: '13px' }}>
						<Grid container spacing={1}>
							<Grid item xs={3}>
								<label> Name </label>
								<TextField fullWidth size="small" style={{ fontSize: '13px' }}
									value={assignUpload.name}
									onChange={(e) => {
										const { assignUpload } = this.state;
										assignUpload.name = e.target.value;
										this.setState({ assignUpload });
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<label> Email </label>
								<TextField fullWidth size="small" style={{ fontSize: '13px' }}
									value={assignUpload.email}
									onChange={(e) => {
										const { assignUpload } = this.state;
										assignUpload.email = e.target.value;
										this.setState({ assignUpload });
									}}
								/>
							</Grid>
							<Grid item xs={4}>
								<label> Purpose </label>
								<TextField fullWidth size="small" style={{ fontSize: '13px' }}
									value={assignUpload.purpose}
									onChange={(e) => {
										const { assignUpload } = this.state;
										assignUpload.purpose = e.target.value;
										this.setState({ assignUpload });
									}}
								/>
							</Grid>
							<Grid item xs={2}>
								<IconButton edge="end" color="inherit" size="small" onClick={() => { this.uploadAssignLink.click() }}>
									<PublishOutlined /> Upload
								</IconButton>
								<input type="file" style={{ display: 'none' }}
									className="inputFileUpload"
									onChange={(e) => { this.uploadAssignedOrders(e) }}
									ref={(ref) => { this.uploadAssignLink = ref; }}
								/>
							</Grid>
						</Grid>
					</div>
					{Object.keys(selectedtype || {}).length > 0 && selectedtype.hasAssign &&
						(this.state.selectedOrders || []).length > 0 && !isPartner &&
						<React.Fragment>
							<div className="search-clinic search-orders pr mb-30">
								<span className="fa fa-search" />
								<TextField
									fullWidth
									size="small"
									variant="outlined"
									placeholder="Search Phlebo…"
									value={this.state.searchPhelbo}
									onChange={(e) => { this.setState({ searchPhelbo: e.target.value }) }}
									onKeyPress={(e) => {
										if ((e.which == 13 || e.keyCode == 13)) {
											this.getPlebos();
										}
									}}
								/>
								{this.state.searchPhelbo.length > 0 &&
									<span className="cancel-icon"
										onClick={() => { this.setState({ searchPhelbo: '' }, () => { this.getPlebos(); }); }}> x </span>
								}
							</div>

							<TableContainer component={Paper} className="mb-30">
								<Table size="small" aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell />
											<TableCell>Image</TableCell>
											<TableCell>Name</TableCell>
											<TableCell>Phone number</TableCell>
											<TableCell>Address</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{((plebos || []).length <= 0) &&
											<TableRow>
												<TableCell component="th" scope="row"> No plebos found. </TableCell>
											</TableRow>
										}
										{(plebos || []).map(plebo => (
											<TableRow key={plebo.phleboId}>
												<TableCell>
													<Checkbox size="small" color="primary"
														checked={phleboId === plebo.phleboId}
														onClick={() => {
															this.setState({
																phleboId: plebo.phleboId,
																firstName: plebo.firstName,
																lastName: plebo.lastName
															})
														}}
													/>
												</TableCell>
												<TableCell>
													<a href={plebo.imageUrl} download>
														<Avatar alt='img' size="small" src={plebo.imageUrl} />
													</a>
												</TableCell>
												<TableCell>{plebo.firstName} {plebo.lastName}</TableCell>
												<TableCell>{plebo.mobileNumber}</TableCell>
												<TableCell>{plebo.addressField1} {plebo.address2}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>

							<Grid container spacing={2}>
								<Grid item xs={6}>
									<div className="dateFilters">
										<div className="dateLabel">Slot From</div>
										<DatePicker
											showTimeSelect
											timeFormat="HH:mm"
											timeIntervals={15}
											timeCaption="time"
											dateFormat="MMMM d, yyyy h:mm aa"
											placeholderText="Select a date"
											selected={this.state.slotFrom}
											onChange={(date) => {
												this.setState({ slotFrom: new Date(date).getTime() });
											}}
										/>
									</div>
								</Grid>
								<Grid item xs={6}>
									<div className="dateFilters">
										<div className="dateLabel">Slot To</div>
										<DatePicker
											showTimeSelect
											timeFormat="HH:mm"
											timeIntervals={15}
											timeCaption="time"
											dateFormat="MMMM d, yyyy h:mm aa"
											placeholderText="Select a date"
											selected={this.state.slotTo}
											onChange={(date) => {
												this.setState({ slotTo: new Date(date).getTime() });
											}}
										/>
									</div>
								</Grid>
								<Grid item xs={6}>
									<div>
										<label>Override Auto Assignment?</label>
										<Switch color="primary" name="isActive" size="small"
											checked={this.state.isOverride}
											onChange={(e) => { this.setState({ isOverride: e.target.checked }) }}
										/>
									</div>
								</Grid>
							</Grid>
						</React.Fragment>
					}

					<Grid container spacing={2}>
						<Grid item xs={3}>
							<FormControlLabel
								control={<Checkbox size="small" color="primary"
									checked={this.state.version === 1}
									onChange={(e) => { this.setState({ version: 1 }) }}
								/>}
								label="Radius Based"
							/>
						</Grid>
						<Grid item xs={3}>
							<FormControlLabel
								control={<Checkbox size="small" color="primary"
									checked={this.state.version === 2}
									onChange={(e) => { this.setState({ version: 2 }) }}
								/>}
								label="Area Based"
							/>
						</Grid>
						<Grid item xs={4}>
							<FormControlLabel
								control={<Checkbox size="small" color="primary"
									checked={this.state.version === 3}
									onChange={(e) => { this.setState({ version: 3 }) }}
								/>}
								label="Zipcode Based"
							/>
						</Grid>
						<Grid item xs={2}>
							<FormControlLabel
								control={<Checkbox size="small" color="primary"
									checked={this.state.version === 4}
									onChange={(e) => { this.setState({ version: 4, sub_version: 0 }) }}
								/>}
								label="Manual"
							/>
						</Grid>
						{
							this.state.version !== 4 &&
							<React.Fragment>
								<Grid item xs={5}>
									<FormControlLabel
										control={<Checkbox size="small" color="primary"
											checked={this.state.sub_version === 1}
											onChange={(e) => { this.setState({ sub_version: 1 }) }}
										/>}
										label="Get Recommendations"
									/>
								</Grid>
								<Grid item xs={4}>
									<FormControlLabel
										control={<Checkbox size="small" color="primary"
											checked={this.state.sub_version === 2}
											onChange={(e) => { this.setState({ sub_version: 2 }) }}
										/>}
										label="Get New Slots"
									/>
								</Grid>
							</React.Fragment>
						}
					</Grid>
				</DialogContent>
				<DialogActions>
					{selectedtype && selectedtype.hasAssign && this.state.selectedOrders.length > 0 && !isPartner &&
						<Button onClick={this.assignOrder} variant="contained" color="primary">Save</Button>}
					<Button onClick={() => { this.setState({ isOrderAssign: false, phleboId: '', firstName: '', lastName: '' }); }} variant="outlined" color="primary">Close</Button>
				</DialogActions>
			</Dialog>
		)
	}

	renderFirstSlot = () => {
		const { reports } = this.props;
		return (
			<TableContainer className="mb-30">
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell> Name </TableCell>
							<TableCell> Time </TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{((reports || []).length <= 0) &&
							<TableRow>
								<TableCell component="th" scope="row"> No reports found. </TableCell>
							</TableRow>
						}
						{(reports || []).map((st, index) => {
							return (
								<TableRow key={`status-${(index + 1)}`}>
									<TableCell> {st.name} </TableCell>
									<TableCell> {moment(el.time).format('Do MMM YY, hh:mm A')} </TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		)
	}

	renderDelayTable = () => {
		const { reports } = this.props;
		const isAdmin = getUser().userType === 1;

		return (
			<div>
				<div style={{ textAlign: 'right', marginTop: '-40px', marginBottom: '40px' }}>
					<div style={{ margin: '10px', display: 'inline' }}> <div style={{ background: 'white', width: '20px', height: '20px', border: '1px solid', marginRight: '5px', display: 'inline' }}> &nbsp;&nbsp;&nbsp;&nbsp; </div> <span> Not Started </span> </div>
					<div style={{ margin: '10px', display: 'inline' }}> <div style={{ background: 'orange', width: '20px', height: '20px', border: '1px solid', marginRight: '5px', display: 'inline' }}> &nbsp;&nbsp;&nbsp;&nbsp; </div> <span> Started </span> </div>
					<div style={{ margin: '10px', display: 'inline' }}> <div style={{ background: 'lightgreen', width: '20px', height: '20px', border: '1px solid', marginRight: '5px', display: 'inline' }}> &nbsp;&nbsp;&nbsp;&nbsp; </div> <span> Reached </span> </div>
				</div>

				<TableContainer className="mb-30" style={{ padding: 0 }}>
					<Table size="small">
						<TableHead>
							<TableRow>
								{isAdmin && this.state.tab === 1 && <TableCell />}
								<TableCell> Order ID </TableCell>
								<TableCell> Vendor ID </TableCell>
								<TableCell> LGP </TableCell>
								<TableCell> Phlebo </TableCell>
								{
									// this.state.tab===5 && <TableCell> Timings </TableCell>
								}
								<TableCell> Slot </TableCell>
								<TableCell> i2H Time </TableCell>
								<TableCell> ETA </TableCell>
								<TableCell> Trip Time </TableCell>
								<TableCell> Phlebo Delay </TableCell>
								<TableCell> Customer Delay </TableCell>
								<TableCell> Status </TableCell>
								<TableCell />
							</TableRow>
						</TableHead>
						<TableBody>
							{((reports || []).length <= 0) &&
								<TableRow>
									<TableCell component="th" scope="row"> No reports found. </TableCell>
								</TableRow>
							}
							{(reports || []).map((st, index) => {
								let bg = '';
								if (st.tripStartTime) {
									bg = 'orange';
								}
								if (st.reachedCustomerLocationTime) {
									bg = 'lightgreen';
								}
								return (
									<React.Fragment>
										<TableRow key={st.orderId} style={{ background: bg }}>
											{isAdmin && this.state.tab === 1 && <TableCell>
												<Checkbox size="small" color="primary"
													checked={(this.state.selectedOrders || []).includes(st.orderId)}
													onChange={(e) => {
														const { selectedOrders } = this.state;
														if (selectedOrders.includes(st.orderId)) {
															selectedOrders.splice(selectedOrders.indexOf(st.orderId), 1);
														} else {
															selectedOrders.push(st.orderId);
														}
														this.setState({ selectedOrders })
													}}
												/>
											</TableCell>}
											<TableCell>
												<div style={{ color: 'blue', cursor: 'pointer' }}
													onClick={() => {
														this.props.history.push(`/orders/${st.orderId}`, { state: st });
													}}> {st.orderSequence}</div>
											</TableCell>
											<TableCell>
												<div>{st.vendorOrderNumber}</div>
												{st.customerName && <div> Name: {st.customerName}</div>}
												{st.customerContact && <div> No: {st.customerContact}</div>}
											</TableCell>
											<TableCell> {st.lgpName} </TableCell>
											<TableCell>
												<div> {st.phleboName}
													{isAdmin && this.state.tab === 1 &&
														<span className="cross-icon" onClick={() => {
															this.setState({ deleteObj: { ...st }, isDeleteOpen: true });
														}}> X </span>
													}
												</div>
												<div> {st.mobileNumber}</div>
											</TableCell>
											<TableCell> {moment(st.pickupStart).format('hh:mm')} - {moment(st.pickupEnd).format('hh:mm')} </TableCell>
											<TableCell> {moment(st.i2h_assigned_time).format('hh:mm')} </TableCell>
											<TableCell> {moment(st.eta).format('hh:mm')} </TableCell>
											<TableCell>
												{st.tripStartTime ? <span>{moment(st.tripStartTime).format('hh:mm')}</span> : null}
												{st.tripStartTime && st.reachedCustomerLocationTime ?
													<span> - </span> : null}
												{st.reachedCustomerLocationTime ? <span>{moment(st.reachedCustomerLocationTime).format('hh:mm')}</span> : null}
											</TableCell>
											<TableCell>
												{st.delay_by_phlebo ? <div style={{
													fontSize: '14px',
													fontWeight: '900',
													color: (Number(st.delay_by_phlebo) && Number(st.delay_by_phlebo) >= 15 ? 'red' : 'orange')
												}}
												>  {st.delay_by_phlebo} </div> : <div> 0 </div>}
											</TableCell>
											<TableCell>
												{st.delay_for_customer ? <div style={{
													fontSize: '14px',
													fontWeight: '900',
													color: (Number(st.delay_for_customer) && Number(st.delay_for_customer) >= 15 ? 'red' : 'orange')
												}}
												> {st.delay_for_customer} </div> : <div> 0 </div>}
											</TableCell>
											<TableCell> <div className="mb-10">{st.csc_comments}</div>
												<TextField fullWidth size="small" value={this.state.remarks[st.orderId] || ''}
													placeholder="Remarks"
													onChange={(e) => {
														const { remarks } = this.state;
														remarks[st.orderId] = e.target.value;
														this.setState({ remarks });
													}}
												/>
												{this.state.remarks[st.orderId] ?
													<div onClick={() => this.updateReportStatus(st)}
														style={{ color: 'blue', cursor: 'pointer' }}
													> Save </div> : null
												}
											</TableCell>
											<TableCell>
												<IconButton size="small" edge="end" color="inherit"
													onClick={() => { this.setState({ isEdit: true, editObj: { ...st } }) }}
												>
													<Edit />
												</IconButton>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell colSpan={1} />
											<TableCell colSpan={3}> <div> Sample Time: {st.sampleCollectedTime ? moment(st.sampleCollectedTime).format('hh:mm') : '-'} </div> </TableCell>
											<TableCell colSpan={4}> <div> Sign-off Time: {st.customerSignoffTime ? moment(st.customerSignoffTime).format('hh:mm') : '-'} </div> </TableCell>
											<TableCell colSpan={3}> <div> Phlebo depart Time: {st.phleboDepartedFromCustomerTime ? moment(st.phleboDepartedFromCustomerTime).format('hh:mm') : '-'} </div> </TableCell>
											<TableCell colSpan={2} />
										</TableRow>
									</React.Fragment>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
				{this.state.isEdit && this.renderPopup()}
			</div>
		)
	}
	renderProofTable = () => {
		const { reports } = this.props;
		return (
			<div>
				<TableContainer className="mb-30">
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell> Name </TableCell>
								<TableCell> TRF Pending Count </TableCell>
								<TableCell> TRF Pending IDS </TableCell>
								<TableCell> Cancel Pending Count </TableCell>
								<TableCell> Cancel Pending Order IDS </TableCell>
								<TableCell> Payment Pending Count </TableCell>
								<TableCell> Payment Pending IDS </TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{((reports || []).length <= 0) &&
								<TableRow>
									<TableCell component="th" scope="row"> No reports found. </TableCell>
								</TableRow>
							}
							{(reports || []).map((st, index) => {
								return (
									<TableRow key={`status-${(index + 1)}`}>
										<TableCell> {st.name} </TableCell>
										<TableCell> {st.trfPendingCount} </TableCell>
										<TableCell> {(st.trfPendingOrderIds || []).map(el => { return <div> {el} </div> })} </TableCell>
										<TableCell> {st.cancelPendingCount} </TableCell>
										<TableCell> {(st.cancelPendingOrderIds || []).map(el => { return <div> {el} </div> })} </TableCell>
										<TableCell> {st.paymentPendingCount} </TableCell>
										<TableCell> {(st.paymentPendingOrderIds || []).map(el => { return <div> {el} </div> })} </TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		)
	}

	renderTicketTable = () => {
		const { ticketReports } = this.props;
		const { slots } = this.state;
		return (
			<div>
				<Grid container spacing={1}>
					{(ticketReports || []).map((st, index) => {
						return (
							<Grid item xs={5}>
								<Card variant="outlined">
									<CardContent>
										<Grid container spacing={1}>
											<Grid item xs={6}>
												<Typography variant="body2" component="p">
													Customer Name: {st.customer_name}
												</Typography>
												<Typography variant="body2" component="p">
													Customer Contact: {st.customerContact}
												</Typography>
												<Typography variant="body2" component="p">
													LGP: {st.lgpName}
												</Typography>
												<Typography variant="body2" component="p">
													Order Sequence: {st.orderSequence}
												</Typography>
												<Typography variant="body2" component="p">
													Vendor Order ID: {st.vendorOrderNumber}
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography variant="body2" component="p">
													Test Time: {moment(st.pickupStart).format('hh:mm')} - {moment(st.pickupEnd).format('hh:mm')}
												</Typography>
												<Typography variant="body2" component="p">
													ETA: {moment(st.eta).format('hh:mm')}
												</Typography>
												{(st.phleboName) &&
													<Typography variant="body2" component="p">
														Phlebo: {st.phleboName}
													</Typography>}
												<Typography variant="body2" component="p">
													Mobile No: {st.mobileNumber || '-'}
												</Typography>
											</Grid>
										</Grid>
										<div>
											<Typography variant="body2" component="p"> Comments:  </Typography>
											<TextField fullWidth size="small" placeholder="Comments"
												value={this.state.comments[st.orderId] || ''}
												onChange={e => {
													const { comments } = this.state;
													comments[st.orderId] = e.target.value
													this.setState({ comments });
												}}
											/>
										</div>
										{Object.keys(slots || {}).length > 0 ?
											<div>
												{(slots[st.orderId] || []).map((el, index) => {
													return (
														<Chip clickable key={`time-${index}`}
															style={{ marginRight: '10px', marginTop: '10px' }}
															label={`${moment(el.earliest_pickup_date).format('Do MMM YY,')} ${moment(el.earliest_pickup_date).format('hh:mm')} - ${moment(el.latest_pickup_date).format('hh:mm')} `}
															onClick={() => {
																const { postponedObj } = this.state;
																postponedObj[st.orderId] = el;
																this.setState({ postponedObj }, () => {
																	this.updateTicket(st, 2);
																});
															}}
														/>
													)
												})}
											</div> : null
										}
									</CardContent>
									<CardActions>
										<Button size="small" onClick={() => this.updateTicket(st, 1)}>Confirm</Button>
										<Button size="small"
											onClick={() => {
												this.getTimeSlots(st);
												// this.updateTicket(st, 2);
											}}
										> Postpone </Button>
										<Button size="small" onClick={() => this.updateTicket(st, 3)}>Cancel</Button>
									</CardActions>
								</Card>
							</Grid>
						)
					})}
				</Grid>
			</div>
		)
	}
	handleLgp = (value) => {
		this.setState({ lgp: value ? value.lgpId : null }, () => {
			this.getDelayReports();
		})
	}

	handleTimings = (e) => {
		this.setState({
			timings: e
		}, () => {
			this.getDelayReports();
		});
	}
	timings = [
		{ name: '5 - 6', id: 1 },
		{ name: '6 - 7', id: 2 },
		{ name: '7 - 8', id: 3 },
		{ name: '8 - 9', id: 4 },
		{ name: '9 - 10', id: 5 },
		{ name: '10 - 11', id: 6 },
		{ name: '11 - 12', id: 7 },
		{ name: '12 +', id: 8 },
	]

	render() {
		const { tab, city, dateFilter, selectedOrders } = this.state;
		const { count, ticketCount, reports } = this.props;
		const user = getUser();

		// const isPartner = user.userType===2;

		return (
			<DocContainer>
				<div className="mr-20">
					{this.props.isLoading && <ActionLoader />}
					<Grid item xs={12}>
						<h2>Reports</h2>
					</Grid>
					<Grid container spacing={1}>
						<Grid item xs={3}>
							<div className="dateFilters">
								<div className="dateLabel">Date</div>
								<DatePicker placeholderText="Select a date"
									selected={dateFilter}
									onChange={(date) => {
										this.setState({
											dateFilter: new Date(date).getTime()
										}, () => {
											this.getDelayReports()
										});
									}}
								/>
							</div>
						</Grid>
						<Grid item xs={3}>
							<div className="dateLabel"> Filter City: </div>
							<Select fullWidth
								value={city}
								onChange={(e) => {
									localStorage.setItem('reportCity', e.target.value);
									this.setState({
										city: e.target.value
									}, () => {
										this.getDelayReports();
									});
								}}
							>
								{(this.props.cities || []).map((city, index) => (
									<option key={`city-${index}`} value={city}> {city} </option>
								))}
							</Select>
						</Grid>
						<Grid item xs={3} style={{ width: 200 }}>
							<div className="dateLabel"> LGP: </div>
							<Autocomplete
								id="combo-box-demo"
								value={this.state.lgp}
								onChange={(event, newValue) => {
									this.handleLgp(newValue);
								}}
								options={this.props.lgps}
								getOptionLabel={(option) => `${option.name}`}
								style={{ width: 180 }}
								renderInput={(params) => <TextField fullWidth {...params} />}
							/>
						</Grid>

						<Grid item xs={3} style={{ width: 200 }}>
							<div className="dateLabel"> Slots: </div>
							<Autocomplete
								multiple
								id="combo-box-demo"

								onChange={(event, newValue) => {
									this.handleTimings(newValue);
								}}
								options={this.timings}
								getOptionLabel={(option) => `${option.name}`}
								style={{ width: 180 }}
								renderInput={(params) => <TextField {...params} />}
							/>
						</Grid>
						<Grid item xs={3}>
								<span className="fa fa-search" />
								<TextField
									fullWidth
									size="small"
									variant="outlined"
									placeholder="Search by ID…"
									value={this.state.searchOrderSeq}
									onChange={(e) => {
										this.setState({ searchOrderSeq: e.target.value }, () => {
											this.getDelayReports()
										});
										
									  }}
									  InputProps={{
										endAdornment: (
										  <InputAdornment position="end">
										   {this.state.searchOrderSeq ? <IconButton
											   onClick={() => {
												this.setState({ searchOrderSeq: "" }, () => {
													this.getDelayReports()
												});
											  }}
											>
											<Clear/>
											</IconButton> : null }
										  </InputAdornment>
										),
									  }}
								/>
						</Grid>
						<Grid item xs={3}>
								<span className="fa fa-search" />
								<TextField
									fullWidth
									size="small"
									variant="outlined"
									placeholder="Search…"
									value={this.state.text}
									onChange={(e) => {
										this.setState({ text: e.target.value }, () => {
											this.getDelayReports()
										});
										
									  }}
									  InputProps={{
										endAdornment: (
										  <InputAdornment position="end">
										   {this.state.text ? <IconButton
											   onClick={() => {
												this.setState({ text: "" }, () => {
													this.getDelayReports()
												});
											  }}
											>
											<Clear/>
											</IconButton> : null }
										  </InputAdornment>
										),
									  }}
								/>
						</Grid>
						{user.userType === 1 && tab === 1 &&
							<Grid item xs={2}>
								<Button
									size="small"
									style={{ backgroundColor: "#0D8DA3" }}
									color="primary"
									variant="contained"
									onClick={() => {
										let start = '';
										let end = '';
										if (selectedOrders.length > 0) {
											start = (reports.find(el => { return el.orderId === selectedOrders[0] }) || {}).pickupStart || '';
											end = (reports.find(el => { return el.orderId === selectedOrders[0] }) || {}).pickupEnd || '';
										}
										this.setState({
											slotFrom: new Date(start).getTime(),
											slotTo: new Date(end).getTime(),
											isOrderAssign: true
										});
									}}
								>
									Assign
								</Button>
							</Grid>
						}
						{this.state.selectedOrders.length > 0 && user.userType === 1 && tab === 1 &&
							<Grid item xs={2}>
								<Button //style={{ marginRight: '20px' }}
									size="small"
									color="primary"
									variant="contained"
									onClick={() => { this.setState({ isReject: true }) }}
								>
									Reject
								</Button>
							</Grid>
						}
						<Grid item xs={1}>
							<IconButton edge="end" color="inherit" onClick={() => { this.getDelayReports(true); }} >
								<SettingsBackupRestore />
							</IconButton>
						</Grid>
					</Grid>
					<div>
						<Tabs value={tab} onChange={(e, tab) => this.setState({ tab, type: (tab === 5 ? 5 : 4) }, this.getDelayReports)}>
							<Tab label={`Delay ${tab === 1 && count ? `(${count})` : ''}`} value={1} />
							<Tab label={`Proof ${tab === 2 && count ? `(${count})` : ''}`} value={2} />
							<Tab label={`Ticket (${ticketCount || 0})`} value={3} />
							<Tab label={`First Slot ${tab === 4 && count ? `(${count})` : ''}`} value={4} />
							<Tab label={`Completed ${tab === 5 && count ? `(${count})` : ''}`} value={5} />
						</Tabs>
					</div>
					<TabPanel value={tab} index={1}>
						{this.renderDelayTable()}
						{this.state.isDeleteOpen && this.renderDeletePopup()}
						{this.state.isOrderAssign && this.renderAssignPopup()}
						{this.state.isReject && this.renderRejectPopup()}
					</TabPanel>
					<TabPanel value={tab} index={2}>
						{this.renderProofTable()}
					</TabPanel>
					<TabPanel value={tab} index={3}>
						{this.renderTicketTable()}
					</TabPanel>
					<TabPanel value={tab} index={4}>
						{this.renderFirstSlot()}
					</TabPanel>
					<TabPanel value={tab} index={5}>
						{this.renderDelayTable()}
					</TabPanel>
				</div>
			</DocContainer>
		);
	}
}


const mapStateToProps = ({ reports, constants, plebos, lgp }) => {
	return {
		isLoading: reports.isSubmitting,
		reports: reports.reports,
		ticketReports: reports.ticketReports,
		lgps: lgp.plebos,
		count: reports.count,
		ticketCount: reports.ticketCount,
		cities: constants.cities,
		plebos: plebos.plebos,
	}
};

export default withRouter(connect(mapStateToProps)(Reports));
