const getAllCookies = () => {
    const pairs = document.cookie.split(";");
    let cookies = {};
    for (let i=0; i<pairs.length; i++){
        let pair = pairs[i].split("=");
        cookies[(pair[0]+'').trim()] = unescape(pair[1]);
    }
    return cookies;
};
export const setCookie = (cname, cvalue, exdays)=> {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};
const getCookie = (cname)=> {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};
const deleteCookie = (name)=> {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}
export const storeToken = (token)=> {
    setCookie('ls-token', token, 200);
}

export const storePhleboToken = (phleboToken) => {
    setCookie('p-token', phleboToken, 200);
}

export const getPhleboToken = () => {
    return getCookie('p-token') || "";
}
export const storeUserType = (type) => {
    setCookie('userType', type, 200);
}
export const getToken = ()=> {
    return getCookie('ls-token') || "";
}
export const storeCompanyType = (companyType, companyId)=> {
    setCookie('ls-company-type', companyType, 200);
}
export const getUserType = ()=> {
    return getCookie('userType') || "";
}

export const getMenuDetails = () => {
    return getCookie('tabs') || null;
}

export const getLgpId = ()=> {
    return getCookie('lgpId') || "";
}

export const storeUser = (user)=> {
    try {
        setCookie('ls-user', JSON.stringify(user), 200);
    } catch(e) {
        console.log('USER', e)
    }
}
export const getUser = ()=> {
    try {
        return JSON.parse(getCookie('ls-user')) || {}
    } catch(e) {
        return {};
    }
}

export const getCompanyType = ()=> {
    try {
        return parseInt(getCookie('ls-company-type'));
    } catch(e) {
        return ""
    }
}

export const deleteToken = ()=> {
    deleteCookie('csrftoken');
    deleteCookie('ls-token');
    deleteCookie('ls-company-type');
}
