/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";

export default function ImageSlider({ images = [] }) {
  const [open, setOpen] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [src, setSrc] = React.useState(null);
  const [rotate, setRotate] = React.useState(0);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (images.length > 0) {
      setOpen(true);
      setSrc(images[0]);
    } else {
      setOpen(false);
    }
  }, [images]);

  React.useEffect(() => {
    if (images.length > 0) {
      setSrc(images[currentIndex]);
    }
  }, [currentIndex]);

  const handleNextClick = () => {
    setCurrentIndex(currentIndex + 1);
  };
  const handlePrevClick = () => {
    setCurrentIndex(currentIndex - 1);
  };

  const handleRotateClick = (dir) => {
    let newRotation = rotate + 45;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    setRotate(newRotation);
  };

  return (
    <div>
      <Dialog open={open} onClose={() => handleClose()}>
        <DialogTitle id="clinic-popup">
          {"Images"}
          <span
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => handleClose()}
          >
            X
          </span>
        </DialogTitle>
        {images.length > 0 ? (
          <>
            <DialogContent style={{ width: "600px", height: "400px" }}>
              <img
                style={{
                  width: "100%",
                  height: "80%",
                  objectFit: "contain",
                  transform: `rotate(${rotate}deg)`,
                }}
                src={src}
                alt="Logo"
              />
              ;
            </DialogContent>
            <DialogActions>
              {currentIndex > 0 ? (
                <Button
                  style={{ backgroundColor: "#00C9AA" }}
                  onClick={() => handlePrevClick()}
                  variant="contained"
                  color="primary"
                >
                  Previous
                </Button>
              ) : null}
              {currentIndex + 1 < images.length ? (
                <Button
                  style={{ backgroundColor: "#00C9AA" }}
                  onClick={() => handleNextClick()}
                  variant="contained"
                  color="primary"
                >
                  Next
                </Button>
              ) : null}
              <div
                rel="noopener noreferrer"
                onClick={() => handleRotateClick()}
                style={{ cursor: "pointer" }}
              >
                Rotate Image
              </div>
              <a
                href={src}
                target="_blank"
                rel="noopener noreferrer"
                download="image.jpeg"
              >
                Download Image
              </a>
            </DialogActions>
          </>
        ) : (
          <DialogContent style={{ width: "600px", height: "400px" }}>
            No Images
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
}
