import { call, put } from 'redux-saga/effects';

import { 
    getDataWithToken,
    putDataWithToken,
    postDataWithToken,
    deleteDataWithToken,
    uploadFile,

    GET_ALL_ROSTERS_URL,
    ADD_ROSTERS_URL,
    UPDATE_ROSTERS_URL,
    GET_ROSTERS_DETAILS_BY_ID_URL,
    GET_ROSTERS_CONFIRM_URL,
    UPDATE_ROSTERS_CONFIRM_URL,
    GET_ROSTERS_INSIGHTS_URL,
    GET_ROSTERS_WORK_STATUS_URL,
    GET_ALL_ROSTER_MAPPING_URL
} from 'Utils/api';

import {
    ALL_ROSTERS,
    IS_SUBMITTING,
    TOAST_ERROR,
    TOAST_SUCCESS
} from 'Constants/actionConstants';


export function* getAllRosters(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const URL = GET_ALL_ROSTERS_URL;

        const data = yield call(getDataWithToken, URL, action.data);
        yield put({
            type: ALL_ROSTERS,
            data: data && data.list ? data.list : [],
            hasMore: data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false
        })
        if (action.onSuccess) {
            action.onSuccess(data);
        }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}


export function* addRosters(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(postDataWithToken, ADD_ROSTERS_URL, action.data);
       	
       	yield put({
            type: TOAST_SUCCESS,
            message: data && data.message ? data.message : "Created successfully!"
        })
        
        if (action.onSuccess) {
            action.onSuccess(data);
        }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}


export function* updateRosters(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(putDataWithToken, UPDATE_ROSTERS_URL, action.data);
       	
        yield put({
         type: TOAST_SUCCESS,
         message: data && data.message ? data.message : "Data updated successfully!"
     })
     
     if (action.onSuccess) {
         action.onSuccess(data);
     }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* getRosterDetailsById(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });
        const data = yield call(getDataWithToken, GET_ROSTERS_DETAILS_BY_ID_URL, action.data);
     
     if (action.onSuccess) {
         action.onSuccess(data);
     }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* getRostersMapping(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });
        const data = yield call(getDataWithToken, GET_ALL_ROSTER_MAPPING_URL, action.data);
     
     if (action.onSuccess) {
         action.onSuccess(data);
     }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        if(action.onFailure) {
            action.onFailure({});
        }
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* getRosterConfirm(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });
        const data = yield call(getDataWithToken, GET_ROSTERS_CONFIRM_URL, action.data);
     
     if (action.onSuccess) {
         action.onSuccess(data);
     }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* updateRosterConfirm(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(postDataWithToken, UPDATE_ROSTERS_CONFIRM_URL, action.data);
       	
        yield put({
         type: TOAST_SUCCESS,
         message: data && data.message ? data.message : "Data updated successfully!"
     })
     
     if (action.onSuccess) {
         action.onSuccess(data);
     }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* getRostersInsights(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });
        const data = yield call(getDataWithToken, GET_ROSTERS_INSIGHTS_URL, action.data);
     
     if (action.onSuccess) {
         action.onSuccess(data);
     }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* getRostersStatus(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });
        const data = yield call(getDataWithToken, GET_ROSTERS_WORK_STATUS_URL, action.data);
     
     if (action.onSuccess) {
         action.onSuccess(data);
     }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}