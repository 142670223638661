import React from "react";
import { connect } from "react-redux";
import { getToken, getUser } from "Utils/storage";
import "./navbar.css";

import { SIGN_OUT } from "./../../constants/actionConstants";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Badge,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu
} from "@material-ui/core";

import LOGO from "./thinkhealthcarelogo.svg";
import MenuIcon from "@material-ui/icons/Menu";
import { AccountCircle, ExitToApp, VpnKey } from "@material-ui/icons";
import { Avatar } from "@material-ui/core";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
   
  }
  logout = () => {
    const { dispatch } = this.props;
    const user = getUser();
    localStorage.clear();
    dispatch({
      type: SIGN_OUT,
      data: { token: getToken(), userType: user.userType },
    });
  };



  render() {
    const user = getUser();
    return (
      <div>
        <AppBar color="default">
          <Toolbar variant="dense">
            <div className="logoImg inlineBlock">
              <img src={LOGO} alt="LifeSpan" />
            </div>
            <div className="logoutImg">
              <IconButton edge="end" color="inherit">
                <AccountCircle />{" "}
                <span className="username">{user.username}</span>
              </IconButton>
              <IconButton edge="end" onClick={this.logout} color="inherit">
                <ExitToApp />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = ({ authentication }) => {
  return {};
};

export default connect(mapStateToProps)(Navbar);
