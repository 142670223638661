import React, { Component } from 'react';
import './clinics.css';

import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	Dialog, DialogTitle, DialogContent,  DialogActions,
	Paper,
	Button,
	Grid,
	TextField,
	IconButton,
	
} from '@material-ui/core';

import {
	ADD_LAB,
	DELETE_LAB,
} from '../../constants/actionConstants';
import { Edit, Clear } from '@material-ui/icons';

class LabTests extends Component {
	constructor(props) {
		super(props);
		this.state = {
			offset: 0,
			searchText: '',
			isError: false,
			isModalOpen: false,

			data: {
				name: "",
				description: "",
				mrp: "",
				sellingPrice: "",
				
			}
		};
	}
    validateData = () => {
		const { data } = this.state;
		if (data.name === '' || data.description === '' || data.mrp === '' || data.sellingPrice === '' ) {
			this.setState({ isError: true });
		} else {
			this.addLabTest();
			this.setState({ isError: false });

		}
    }
    
    addLabTest = () => {
        const { dispatch } = this.props;
        
		dispatch({
			type: ADD_LAB,
			data:  { ...this.state.data,labId:this.props.labId },
			onSuccess: (() => {
				this.setState({ isModalOpen: false });
				
				this.resetPopup();
				this.props.getLabTests();
				
			})
		});
	}
    renderPopup = () => {
        const { isModalOpen, data } = this.state
		return (
			<Dialog disableBackdropClick open={isModalOpen} onClose={() => { this.setState({ isModalOpen: false }) }}>
				<DialogTitle id="clinic-popup">{data.isEdit ? 'Edit Lab Test' : 'Add New Lab Test'}</DialogTitle>
				<DialogContent style={{ width: '600px', height: '180px' }}>
					{this.renderFormFields({...data})}
				</DialogContent>
				<DialogActions>
					<Button style={{ backgroundColor: "#00C9AA" }} onClick={this.validateData} variant="contained" color="primary">Save</Button>
					<Button style={{ borderColor: "#00C9AA", color: "#00C9AA" }} onClick={() => { this.setState({ isModalOpen: false }); this.resetPopup() }} variant="outlined" color="primary">Close</Button>
				</DialogActions>
			</Dialog>
		)
	}

	resetPopup = () => {
		let { data } = this.state;
		data = {
			//	labId: "",
			name: "",
				description: "",
				mrp: "",
				sellingPrice: ""
		}
		this.setState({ data, isError: false });
	}

	handleFormChange = (e, key) => {
		const { data } = this.state;
		data[key] = e.target.value;
		this.setState({ data });
	}
	renderFormFields = (data) => {
        
		const { isError } = this.state;
		return (
			<React.Fragment>
				<Grid container spacing={3}>
					<Grid item xs={6}>
						<TextField fullWidth label="Lab Test Name"
							value={data.name}
							onChange={(e) => this.handleFormChange(e, 'name')}
							error={isError && data.name === ""}
							helperText={(isError && data.name === "") ? 'Please enter a lab test name' : ' '}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField fullWidth label="Descripion"
							value={data.description}
							onChange={(e) => this.handleFormChange(e, 'description')}
							error={isError && data.description === ""}
							helperText={(isError && data.description === "") ? 'Please enter a description' : ' '}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={3}>
					<Grid item xs={6}>
						<TextField fullWidth label="MRP"
							value={data.mrp}
							onChange={(e) => this.handleFormChange(e, 'mrp')}
							error={isError && data.mrp === ""}
							helperText={(isError && data.mrp === "") ? 'Please enter a mrp' : ' '}
						/>
					</Grid>

					<Grid item xs={6}>
						<TextField fullWidth label="Selling Price"
							value={data.sellingPrice}
							onChange={(e) => this.handleFormChange(e, 'sellingPrice')}
							error={isError && data.sellingPrice === ""}
							helperText={(isError && data.sellingPrice === "") ? 'Please enter a selling price' : ' '}
						/>
					</Grid>

				</Grid>


			
			</React.Fragment>
		)
	}
	deleteLabTest = (id) => {
		const { dispatch } = this.props;
		dispatch({
			type: DELETE_LAB,
			data: { labTestId: id },
			onSuccess: (() => {
				this.props.getLabTests()
				this.setState({ isDeleteOpen: false, deleteObj: null })
			})
		});
		// alert('api call')
		// this.setState({ isDeleteOpen: false, deleteObj: null })
	}
	renderDeletePopup = () => {
		const { isDeleteOpen, data, deleteObj } = this.state
		return (
			<Dialog disableBackdropClick open={isDeleteOpen} onClose={() => { this.setState({ isDeleteOpen: false }) }}>
				<DialogTitle id="clinic-popup">Confirm Delete</DialogTitle>
				<DialogContent > Are you sure you want to delete {deleteObj.name} ? </DialogContent>
				<DialogActions>
					<Button style={{ backgroundColor: "#00C9AA" }} onClick={() => { this.deleteLabTest(deleteObj.labTestId) }} variant="contained" color="primary">Yes</Button>
					<Button style={{ borderColor: "#00C9AA", color: "#00C9AA" }} onClick={() => { this.setState({ isDeleteOpen: false, deleteObj: null }) }} variant="outlined" color="primary">No</Button>
				</DialogActions>
			</Dialog>
		)
	}
    renderLabTestTable=(labTests)=>{
        return (
			<TableContainer component={Paper} className="mb-30">
				<Table size="small" aria-label="simple table">
				<TableHead>
				  <TableRow>
				    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
				    <TableCell>MRP</TableCell>
                    <TableCell>Selling Price</TableCell>
					<TableCell>Action</TableCell>
				  </TableRow>
				</TableHead>
				<TableBody>
					{((labTests || []).length <= 0) &&
						<TableRow>
				      		<TableCell component="th" scope="row"> No Lab Tests found. </TableCell>
				      	</TableRow>
					}
				  {(labTests || []).map((obj, index) => (
				    <TableRow key={`labtest-${obj.labTestId}-${index}`}>
				      <TableCell>{obj.name}</TableCell>
				      <TableCell>{obj.description}</TableCell>
				      <TableCell>{obj.mrp}</TableCell>
                      <TableCell>{obj.sellingPrice}</TableCell>
				     
				      <TableCell>
                      <div style={{ width: '75px' }}>

                      <IconButton edge="end" color="inherit" size="small" onClick={() => { this.setState({ isModalOpen: true, data: { ...obj, isEdit: true } }) }}>
														<Edit />
													</IconButton>
													<IconButton edge="end" color="inherit" size="small" onClick={() => { this.setState({ isDeleteOpen: true, deleteObj: obj }) }}>
														<Clear />
													</IconButton>
                    </div>
				      </TableCell>
				    </TableRow>
				  ))}
				</TableBody>
				</Table>
			</TableContainer>
        )
    }
	

	render() {
		
		return (
            <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                  <h2 className="mt-0">LAB TESTS</h2>
                </Grid>
                <Grid item>
                  <Button style={{ backgroundColor:"#00C9AA" }} variant="contained" color="primary" onClick={() => {this.setState({ isModalOpen: !this.state.isModalOpen}) }}>
                    Add LAB TEST
                  </Button>
                </Grid>
            </Grid>
            
            {this.props.renderPagination(true)}
            {this.state.isModalOpen && this.renderPopup()}
            {this.state.isDeleteOpen && this.renderDeletePopup()}

            {this.renderLabTestTable(this.props.labTests)}

            {this.props.renderPagination(true)}
        </React.Fragment>
		);
	}
}


export default LabTests;
