import { call, put } from 'redux-saga/effects';

import { 
    postDataWithToken,
    getDataWithToken,
    deleteDataWithToken,

    GET_ALL_RUNNERS_URL,
    GET_ALL_RUNNERS_BY_ID_URL,
    ADD_RUNNER_URL,
    EDIT_RUNNER_URL,
    DEL_RUNNER_URL,

    GET_RUNNER_KYC_URL,
    POST_RUNNER_KYC_URL,
    DELETE_RUNNER_KYC_URL,
} from 'Utils/api';

import {
    IS_SUBMITTING,
    RUNNER_LIST,
    RUNNER_KYC_LIST,

    TOAST_ERROR,
    TOAST_SUCCESS
} from 'Constants/actionConstants';


export function* getRunners(action) {
    try{
        const url = action.data && action.data.id ? GET_ALL_RUNNERS_BY_ID_URL : GET_ALL_RUNNERS_URL;
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(getDataWithToken, url, action.data);

        yield put({
            type: RUNNER_LIST,
            data: data && data.runners ? data.runners : [],
            hasMore: data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false
        })

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* addRunners(action) {
    try{
        const url = (action.data && action.data.isEdit) ? EDIT_RUNNER_URL : ADD_RUNNER_URL;
        yield put({ type : IS_SUBMITTING, isSubmitting: true });
        const data = yield call(postDataWithToken, url, action.data);
        yield put({ type : IS_SUBMITTING, isSubmitting: false });

        yield put({
            type: TOAST_SUCCESS,
            message: data && data.message?data.message : "Data updated successfully!"
        })
        
        if (action.onSuccess) {
            action.onSuccess(data);
        }
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* deleteRunners(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(deleteDataWithToken, DEL_RUNNER_URL, action.data);

        yield put({
            type: TOAST_SUCCESS,
            message: data && data.message?data.message : "Data updated successfully!"
        })
        
        if (action.onSuccess) {
            action.onSuccess(data);
        }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}


export function* getRunnersKYC(action) {
    try{
        const url = GET_RUNNER_KYC_URL;
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(getDataWithToken, url, action.data);

        yield put({
            type: RUNNER_KYC_LIST,
            data: data && data.documents ? data.documents : [],
            hasMore: data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false
        })

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* addRunnersKYC(action) {
    try{
        const url = POST_RUNNER_KYC_URL;
        yield put({ type : IS_SUBMITTING, isSubmitting: true });
        const data = yield call(postDataWithToken, url, action.data);
        yield put({ type : IS_SUBMITTING, isSubmitting: false });

        yield put({
            type: TOAST_SUCCESS,
            message: data && data.message?data.message : "Data updated successfully!"
        })
        
        if (action.onSuccess) {
            action.onSuccess(data);
        }
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}

export function* deleteRunnersKYC(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(deleteDataWithToken, DELETE_RUNNER_KYC_URL, action.data);

        yield put({
            type: TOAST_SUCCESS,
            message: data && data.message?data.message : "Data updated successfully!"
        })
        
        if (action.onSuccess) {
            action.onSuccess(data);
        }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}
