/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import DocContainer from "./../../containers/DocContainer";
import ActionLoader from "../../components/ActionLoader";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import Select, { components } from "react-select";
import { ArrowDropDownOutlined, Edit } from "@material-ui/icons";
import DatePicker from "../../components/Datepicker/DatePicker";
import moment from "moment";
import RosterMapping from "../../components/RosterMapping";

import "./index.css";

import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Typography,
  FormLabel,
  TextField,
  Grid,
  IconButton,
  Dialog,
  DialogContent,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
import {
  GET_ALL_CONSTANTS,
  GET_PLEBOS_ATTENDANCE,
  GET_PLEBOS_PROOF_SYNC,
  GET_PLEBOS_LOCATION_STATUS,
  GET_PLEBOS_FIRST_SLOTS,
  UPDATE_PLEBOS_ATTENDANCE,
  GET_ALL_ZONES,
} from "../../constants/actionConstants";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  paper: {
    width: "90%",
    padding: 10,
  },
  rootPhlebo: {
    display: "flex",
    height: "auto",
    border: "none",
    marginTop: 3,
    boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.1)",
    borderRadius: "12px",
  },
}));

const statusOptions = [
  {
    value: "",
    label: "All",
  },
  {
    value: "WORKING",
    label: "Working",
  },
  {
    value: "NA",
    label: "NA",
  },
  {
    value: "WEEK_OFF",
    label: "Week Off",
  },
  {
    value: "LEAVE_REQUEST",
    label: "Leave request",
  },
];

const actionOptions = [
  {
    value: "WORKING",
    label: "Working",
  },
  {
    value: "NA",
    label: "NA",
  },
  {
    value: "WEEK_OFF",
    label: "Week Off",
  },
  {
    value: "LEAVE_REQUEST",
    label: "Leave request",
  },
];

const multiSelectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    fontSize: 12,
  }),
  option: (base) => ({
    ...base,
    fontSize: 12,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#9F9F9F",
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    color: "#fff",
    backgroundColor: "#6FC5C4",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#6FC5C4",
      color: "#fff",
    },
  }),
};

const PhleboAttendance = (props) => {
  const { cities, zones } = props;
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const [date, setDate] = useState(new Date());
  const [allCities, setAllCities] = useState([]);
  const [city, setCity] = useState("");
  const [workStatus, setWorkStatus] = useState("");
  const [allPhlebos, setAllPhlebos] = useState([]);
  const [searchPhleos, setSearchPhleos] = useState("");
  const [summary, setSummary] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [activetab, setActiveTab] = useState(1);
  const [filterZone, setFilterZone] = useState("");
  const [allZones, setAllZones] = useState([]);
  const [approveDialogOpen, setApproveDialogOpen] = useState(false);
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [approveRemarks, setApproveRemarks] = useState('');
  const [status, setStaus] =useState('');

  useEffect(() => {
    getAllConstants();
  }, []);

  useEffect(() => {
    setAllZones(
      zones.map((zone) => {
        return { value: zone.zoneId, label: zone.name, ...zone };
      })
    );
  }, [zones]);

  useEffect(() => {
    setAllCities(
      cities.map((city) => {
        return { value: city, label: city };
      })
    );
  }, [cities]);

  useEffect(() => {
    if (allCities.length > 0) {
      setCity("Bangalore");
    }
  }, [allCities]);

  useEffect(() => {
    setAllPhlebos([]);
    setSearchPhleos("");
    if (activetab === 1) {
      getAllReports();
    } else if (activetab === 2) {
      getAllFirstSlot();
    } else if (activetab === 3) {
      getAllProofSync();
    } else if (activetab === 4) {
      getAllLocationStatus();
    }
  }, [date, city, activetab, filterZone]);

  useEffect(() => {
    getAllZones();
  }, [city])

  const getAllConstants = () => {
    const { dispatch } = props;
    dispatch({
      type: GET_ALL_CONSTANTS,
    });
  };

  const getAllZones = () => {
    const { dispatch } = props;
    const data = {
      city: city,
      count: 100,
      offset: 0,
      text: "",
    };
    dispatch({
      type: GET_ALL_ZONES,
      data,
    });
  };

  const getAllProofSync = () => {
    setIsLoading(true);
    const { dispatch } = props;
    dispatch({
      type: GET_PLEBOS_PROOF_SYNC,
      data: {
        ts: date.getTime(),
        date: moment(date).date(),
        month: 1 + moment(date).month(),
        year: moment(date).year(),
        city,
      },
      onSuccess: (res) => {
        setIsLoading(false);
        setAllPhlebos(res.list || []);
        setSummary(res || {});
      },
      onFailure: (res) => {
        setIsLoading(false);
      },
    });
  };

  const getAllLocationStatus = () => {
    setIsLoading(true);
    const { dispatch } = props;
    dispatch({
      type: GET_PLEBOS_LOCATION_STATUS,
      data: {
        ts: date.getTime(),
        date: moment(date).date(),
        month: 1 + moment(date).month(),
        year: moment(date).year(),
      
        city,
      },
      onSuccess: (res) => {
        setIsLoading(false);
        setAllPhlebos(res.list || []);
        setSummary(res || {});
      },
      onFailure: (res) => {
        setIsLoading(false);
      },
    });
  };

  const getAllReports = () => {
    setIsLoading(true);
    const { dispatch } = props;

    const data = {
      ts: date.getTime(),
      date: moment(date).date(),
      month: 1 + moment(date).month(),
      year: moment(date).year(),
      city,
      
    };
    if (filterZone) {
      data["zoneId"] = filterZone.join(",");
    }

    dispatch({
      type: GET_PLEBOS_ATTENDANCE,
      data,
      onSuccess: (res) => {
        setIsLoading(false);
        setAllPhlebos(res.list || []);
        setSummary(res || {});
      },
      onFailure: (res) => {
        setIsLoading(false);
      },
    });
  };

  const getAllFirstSlot = () => {
    setIsLoading(true);
    const { dispatch } = props;
    dispatch({
      type: GET_PLEBOS_FIRST_SLOTS,
      data: {
        ts: date.getTime(),
        date: moment(date).date(),
        month: 1 + moment(date).month(),
        year: moment(date).year(),
        city,
      },
      onSuccess: (res) => {
        setIsLoading(false);
        setAllPhlebos(res.list || []);
      },
      onFailure: (res) => {
        setIsLoading(false);
      },
    });
  };

  const changeAttendance = (data) => {
    setDialogOpen(true);
    setEditData(data);
  };

  const handleChange = (val) => {
    setEditData({
      ...editData,
      response: val,
    });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const { dispatch } = props;
    dispatch({
      type: UPDATE_PLEBOS_ATTENDANCE,
      data: {
        ...editData,
        date: moment(date).date(),
        month: 1 + moment(date).month(),
        year: moment(date).year(),
        city,
      },
      onSuccess: (res) => {
        setIsLoading(false);
        setAllPhlebos(res.list || []);
        setSummary(res || {});
      },
      onFailure: () => {
        setIsLoading(false);
      },
    });
  };

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      fontSize: 12,
      border: "none",
      boxShadow:
        "0 4px 8px 0 rgb(255 255 255), 0 6px 8px 0 rgb(117 109 109 / 30%)",
    }),
    option: (base) => ({
      ...base,
      fontSize: 12,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#9F9F9F",
    }),
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownOutlined />
      </components.DropdownIndicator>
    );
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  };

  const approveDialogueOpen =(data) => {
    setStaus("APPROVED")
    setApproveDialogOpen(true);
    setEditData(data);
  }

  const rejectedDialogueOpen =(data) => {
    setStaus("REJECTED")
    setRejectDialogOpen(true);
    setEditData(data);
  }

  const setApproversData = () => {
    handleSubmit();
    setApproveDialogOpen(false);
    setRejectDialogOpen(false);
  }

  const ApproveDialogue = () => {
    return (
      <Dialog
        disableBackdropClick
        open={approveDialogOpen}
        onClose={() => {
          setApproveDialogOpen(false);
          setEditData({});
        }}
      >
        <DialogContent>
          <h3>Are you sure you want to Approve?</h3>
          <div style={{height:'150px'}}>
          <textarea 
          id="noter-text-area" 
          name="textarea" 
          rows="6" 
          cols="65"
          placeholder="Remarks"
          value={approveRemarks} 
          onChange={(e)=>{
            setApproveRemarks(e.target.value)
            setEditData({
              ...editData,
              status:'APPROVED',
              remarks:e.target.value
            });
          } 
        }
          />
        </div>
            

            <div className="action-div" style={{ marginTop: 10 }}>
              <Button
                className="white-button"
                variant="contained"
                color="primary"
                onClick={() => {
                  setApproveDialogOpen(false);
                  setEditData({});
                }}
                style={{ marginRight: 10 }}
              >
                <span>Cancel</span>
              </Button>
              <Button
                className="green-button"
                variant="contained"
                color="primary"
                onClick={() => setApproversData() }
                style={{ marginRight: 10 }}
              >
                <span>Submit</span>
              </Button>
            </div>
        </DialogContent>
      </Dialog>
    );
  };

  const RejectDialogue = () => {
    return (
      <Dialog
        disableBackdropClick
        open={rejectDialogOpen}
        onClose={() => {
          setRejectDialogOpen(false);
          setEditData({});
        }}
      >
        <DialogContent>
          <h3>Are you sure you want to Reject?</h3>
          <div style={{height:'150px'}}>
          <textarea 
          id="noter-text-area" 
          name="textarea" 
          rows="6" 
          cols="65"
          placeholder="Remarks"
          value={approveRemarks} 
          onChange={(e)=>{
            setApproveRemarks(e.target.value)
            setEditData({
              ...editData,
              status:'REJECTED',
              remarks:e.target.value
            });
          }} 
          />
        </div>
            

            <div className="action-div" style={{ marginTop: 10 }}>
              <Button
                className="white-button"
                variant="contained"
                color="primary"
                onClick={() => {
                  setRejectDialogOpen(false);
                  setEditData(false);
                }}
                style={{ marginRight: 10 }}
              >
                <span>Cancel</span>
              </Button>
              <Button
                className="green-button"
                variant="contained"
                color="primary"
                onClick={() =>{
                setApproversData()
              }}
                style={{ marginRight: 10 }}
              >
                <span>Submit</span>
              </Button>
            </div>
        </DialogContent>
      </Dialog>
    );
  };

  const renderPopup = () => {
    return (
      <Dialog
        disableBackdropClick
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          setEditData({});
        }}
      >
        <DialogContent>
          <div
            className="headerView"
            style={{ marginLeft: "0px", marginTop: 0, flexDirection: "column" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "left",
                paddingTop: 5,
                marginBottom: 15,
              }}
            >
              <Typography
                style={{
                  fontSize: 24,
                  marginLeft: 12,
                  color: "#252733",
                  fontWeight: "600",
                }}
                display="block"
              >
                Attendance Status - {editData.firstName} {editData.lastName}
              </Typography>
            </div>
            <div style={{ height: 200 }}>
              <Select
                style={{ marginBottom: 10 }}
                options={actionOptions}
                styles={selectStyles}
                isClearable={true}
                placeholder={"Status"}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                }}
                value={
                  actionOptions[
                    actionOptions.findIndex(
                      (key) => key.value === editData.response
                    )
                  ]
                }
                onChange={(val) => {
                  handleChange(val ? val.value : null);
                }}
              />
            </div>
            <div className="action-div" style={{ marginTop: 10 }}>
              <Button
                className="white-button"
                variant="contained"
                color="primary"
                onClick={() => {
                  setDialogOpen(false);
                  setEditData(false);
                }}
                style={{ marginRight: 10 }}
              >
                <span>Cancel</span>
              </Button>
              <Button
                className="green-button"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
                style={{ marginRight: 10 }}
              >
                <span>Submit</span>
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const locationStatus = () => {
    return (
      <>
        <div className="headerView">
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography
                variant="button"
                display="block"
                gutterBottom
                className={`phlebo-header`}
              >
                Location Status
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Paper component="form" className={classes.rootPhlebo}>
          <div className="phlebo-block1">
            <div className="tableView">
              <table className="styled-table">
                <thead className="tableHead">
                  <tr className="border_bottom">
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody className="tableBody">
                  {allPhlebos
                    .filter((el) =>
                      (el.firstName || "")
                        .toLowerCase()
                        .includes(searchPhleos.toLowerCase())
                    )
                    .filter((el) => el.response === workStatus || !workStatus)
                    .map((el, i) => (
                      <tr className="row" key={`UMP_${i}`}>
                        <td className="cell" data-title="Phlebo">
                          {el.firstName}
                        </td>
                        <td className="cell" data-title="Phone number">
                          {el.lastName}
                        </td>
                        <td className="cell" data-title="Phone number">
                          {el.status &&
                          el.status.map((el)=>(
                            <>
                            <p>{el.status}&nbsp; &nbsp; {moment(el.time).format("h:mm A")}</p>
                            </>
                          ))
                           
                           }
                        </td>
                        
                      </tr>
                    ))}
                  {!allPhlebos.length ? (
                    <tr className="row">
                      <td className="cell" data-title="S.no">
                        No Phlebos
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Paper>
      </>
    );
  };

  const proofSync = () => {
    return (
      <>
        <div className="headerView">
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography
                variant="button"
                display="block"
                gutterBottom
                className={`phlebo-header`}
              >
                Proof Sync
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Paper component="form" className={classes.rootPhlebo}>
          <div className="phlebo-block1">
            <div className="tableView">
              <table className="styled-table">
                <thead className="tableHead">
                  <tr className="border_bottom">
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody className="tableBody">
                  {allPhlebos
                    .filter((el) =>
                      (el.firstName || "")
                        .toLowerCase()
                        .includes(searchPhleos.toLowerCase())
                    )
                    .filter((el) => el.response === workStatus || !workStatus)
                    .map((el, i) => (
                      <tr className="row" key={`UMP_${i}`}>
                        <td className="cell" data-title="Phlebo">
                          {el.firstName}
                        </td>
                        <td className="cell" data-title="Phone number">
                          {el.lastName}
                        </td>
                        <td className="cell" data-title="Phone number">
                          {el.orderIds &&
                            el.orderIds.filter((order)=>order.ids.length>0).map((order)=>(
                              <>
                              <p>
                                {order.ids && order.ids.map((order)=>(
                                 <>Id: {order}</>
                                )) 
                                }
                               &nbsp; &nbsp;
                               {order.ids.length >0 && 'Type:'}
                               {order.ids.length >0 && order.type}</p> 
                               </>
                            ))
                          }
                        </td>
                      </tr>
                    ))}
                  {!allPhlebos.length ? (
                    <tr className="row">
                      <td className="cell" data-title="S.no">
                        No Phlebos
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Paper>
      </>
    );
  };

  const firstSlot = () => {
    return (
      <>
        <div className="headerView">
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography
                variant="button"
                display="block"
                gutterBottom
                className={`phlebo-header`}
              >
                First Slot
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Paper component="form" className={classes.rootPhlebo}>
          <div className="phlebo-block1">
            <div className="tableView">
              <table className="styled-table">
                <thead className="tableHead">
                  <tr className="border_bottom">
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Slot From</th>
                    <th>Slot To</th>
                    <th>Trip Started</th>
                    <th>Location React Time</th>
                  </tr>
                </thead>
                <tbody className="tableBody">
                  {allPhlebos
                    .filter((el) =>
                      (el.firstName || "")
                        .toLowerCase()
                        .includes(searchPhleos.toLowerCase())
                    )
                    .filter((el) => el.response === workStatus || !workStatus)
                    .map((el, i) => (
                      <tr className="row" key={`UMP_${i}`}>
                        <td className="cell" data-title="Phlebo">
                          {el.firstName}
                        </td>
                        <td className="cell" data-title="Phone number">
                          {el.lastName}
                        </td>
                        <td className="cell" data-title="Phone number">
                          {el.slotFrom || ""}
                        </td>
                        <td className="cell" data-title="Phone number">
                          {el.slotTo}
                        </td>
                        <td className="cell" data-title="Phone number">
                          {el.tripStarted}
                        </td>
                        <td className="cell" data-title="Phone number">
                          {moment(el.locationReachTime).format("H:SS A")}
                        </td>
                      </tr>
                    ))}
                  {!allPhlebos.length ? (
                    <tr className="row">
                      <td className="cell" data-title="S.no">
                        No Phlebos
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Paper>
      </>
    );
  };

  const attendance = () => {
    return (
      <>
        <div className="headerView">
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography
                variant="button"
                display="block"
                gutterBottom
                className={`phlebo-header`}
              >
                Phlebos Attendance
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ marginBottom: 10 }}>
              <Select
                style={{ marginBottom: 10 }}
                options={statusOptions}
                styles={multiSelectStyles}
                isClearable={true}
                placeholder={"Status"}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                }}
                value={
                  statusOptions[
                    statusOptions.findIndex((key) => key.value === workStatus)
                  ]
                }
                onChange={(val) => {
                  setWorkStatus(val ? val.value : null);
                }}
              />
            </Grid>
          </Grid>
        </div>
        <Paper component="form" className={classes.rootPhlebo}>
          <div className="phlebo-block1">
            <div className="tableView">
              <table className="styled-table">
                <thead className="tableHead">
                  <tr className="border_bottom">
                    <th style={{width:'100px'}}>First Name</th>
                    <th style={{width:'100px'}}>Last Name</th>
                    <th>Mobile</th>
                    <th>Work Timings</th>
                    <th>Roster</th>
                    <th style={{width:'100px'}}>Response</th>
                    <th style={{ transform: workStatus === 'LEAVE_REQUEST' && 'translateX(100px)'}}>Action</th>
                  </tr>
                </thead>
                <tbody className="tableBody">
                  {allPhlebos
                    .filter((el) =>
                      (el.firstName || "")
                        .toLowerCase()
                        .includes(searchPhleos.toLowerCase())
                    )
                    .filter((el) => el.response === workStatus || !workStatus)
                    .map((el, i) => (
                      <tr className="row" key={`UMP_${i}`}>
                        <td className="cell" data-title="Phone number">
                          {el.firstName}
                        </td>
                        <td className="cell" data-title="Phone number">
                          {el.lastName}
                        </td>
                        <td className="cell" data-title="Phone number">
                          {el.mobileNumber || ""}
                        </td>
                        <td className="cell" data-title="Phone number">
                          {el.work_timings || ""}
                        </td>
                        <td className="cell" data-title="Phone number">
                          {el.roster || ""}
                        </td>
                        <td className="cell" data-title="Phone number">
                          {el.response}
                        </td>
                        <td className="cell" style={{ width:workStatus ==='LEAVE_REQUEST' &&'242px'}} data-title="Phone number">
                          <IconButton
                            edge="end"
                            color="inherit"
                            size="small"
                            
                            onClick={() => changeAttendance(el)}
                          >
                            <Edit />
                          </IconButton>
                          {workStatus === 'LEAVE_REQUEST' &&
                          <>
                          <Button  onClick={()=>approveDialogueOpen(el)} style={{marginLeft:'10px'}} variant="contained" color="primary">
                            Approve
                          </Button>
                          <Button onClick={()=>rejectedDialogueOpen(el)} style={{marginLeft:'10px'}} variant="contained" color="secondary">
                            Reject
                          </Button>
                          </>
                        }
                        </td>
                      </tr>
                    ))}
                  {!allPhlebos.length ? (
                    <tr className="row">
                      <td className="cell" data-title="S.no">
                        No Phlebos
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Paper>
      </>
    );
  };

  return (
    <DocContainer>
      {isLoading && <ActionLoader />}
      <Card style={{ border: "none", boxShadow: "none", overflow: "unset" }}>
        <CardHeader title="Phlebo  Attendance" />
        <CardContent>
          <Grid container spacing={1} style={{ marginBottom: 15 }}>
            <Grid item xs={2} style={{ marginTop: 15 }}>
              <FormLabel component="legend" className="text-label">
                Select City
              </FormLabel>
              <Select
                options={allCities}
                isClearable={true}
                placeholder={"City"}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                }}
                value={
                  allCities[allCities.findIndex((key) => key.value === city)]
                }
                onChange={(val) => {
                  setCity(val ? val.value : null);
                }}
                styles={multiSelectStyles}
              />
            </Grid>
            <Grid item xs={2} style={{ marginTop: 15 }}>
              <FormLabel component="legend" className="text-label">
                Select Zone
              </FormLabel>
              <Select
                  options={allZones}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator,
                  }}
                  value={allZones.filter((key) =>
                    filterZone.includes(key.value)
                  )}
                  isMulti
                  onChange={(val) => {
                    setFilterZone(val.map((a) => a.value));
                  }}
                  placeholder={"Zone"}
                  styles={multiSelectStyles}
                  style={{ width: "80%" }}
                />
            </Grid>
            <Grid
              item
              xs={6}
              style={{ paddingLeft: 20, paddingRight: 20, zIndex: 0 }}
            >
              <DatePicker
                endDate={90}
                selectDate={date}
                getSelectedDay={(date) => setDate(date)}
                color={"#81CD0A"}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={10} style={{display: 'flex', alignItems: 'center'}}>
            <Tabs
              value={activetab}
              TabIndicatorProps={{}}
              textColor="primary"
              onChange={(e, tab) => {
                setActiveTab(tab);
              }}
            >
              <Tab label="Attendance" value={1} />
              <Tab label="First Slot" value={2} />
              <Tab label="Proof Sync" value={3} />
              <Tab label="Location Status" value={4} />
            </Tabs>
            <TextField
                placeholder="Search Phlebos..."
                variant="outlined"
                value={searchPhleos}
                onChange={(e) => setSearchPhleos(e.target.value)}
              />
            </Grid>
            <Grid item xs={10}>
              <TabPanel value={activetab} index={1}>
                <Grid container spacing={1}>
                  <Grid item xs={1} style={{ marginTop: 15, marginLeft: 10 }}>
                    <Typography
                      variant="button"
                      display="block"
                      gutterBottom
                      className={`phlebo-header`}
                    >
                      Total:
                    </Typography>
                    <Typography
                      variant="button"
                      display="block"
                      gutterBottom
                      className={`phlebo-header`}
                    >
                      {summary.totalCount || 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} style={{ marginTop: 15 }}>
                    <Typography
                      variant="button"
                      display="block"
                      gutterBottom
                      className={`phlebo-header`}
                    >
                      Unknown:
                    </Typography>
                    <Typography
                      variant="button"
                      display="block"
                      gutterBottom
                      className={`phlebo-header`}
                    >
                      {summary.unknownCount || 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} style={{ marginTop: 15 }}>
                    <Typography
                      variant="button"
                      display="block"
                      gutterBottom
                      className={`phlebo-header`}
                    >
                      Work Off:
                    </Typography>
                    <Typography
                      variant="button"
                      display="block"
                      gutterBottom
                      className={`phlebo-header`}
                    >
                      {summary.workOffCount || 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} style={{ marginTop: 15 }}>
                    <Typography
                      variant="button"
                      display="block"
                      gutterBottom
                      className={`phlebo-header`}
                    >
                      Working:
                    </Typography>
                    <Typography
                      variant="button"
                      display="block"
                      gutterBottom
                      className={`phlebo-header`}
                    >
                      {summary.workingCount || 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} style={{ marginTop: 15 }}>
                    <Typography
                      variant="button"
                      display="block"
                      gutterBottom
                      className={`phlebo-header`}
                    >
                      Leave Request:
                    </Typography>
                    <Typography
                      variant="button"
                      display="block"
                      gutterBottom
                      className={`phlebo-header`}
                    >
                      {summary.leaveRequestCount || 0}
                    </Typography>
                  </Grid>
                </Grid>
                {attendance()}
              </TabPanel>
              <TabPanel value={activetab} index={2}>
                {firstSlot()}
              </TabPanel>
              <TabPanel value={activetab} index={3}>
                {proofSync()}
              </TabPanel>
              <TabPanel value={activetab} index={4}>
                {locationStatus()}
              </TabPanel>
            </Grid>
          </Grid>
        </CardContent>
        {dialogOpen && renderPopup()}
        {ApproveDialogue()}
        {RejectDialogue()}
        <RosterMapping date={date} city={city} />
      </Card>
    </DocContainer>
  );
};

const mapStateToProps = ({ constants, zones }) => {
  return {
    cities: constants.cities,
    zones: zones.zones,
  };
};

export default withRouter(connect(mapStateToProps)(PhleboAttendance));
