import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./clinics.css";
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper,
  Button,
  Grid,
  TextField,
  IconButton,
  InputLabel,
  Input,
  Select,
  Switch,
} from "@material-ui/core";

import {
  GET_LABS,
  GET_LPGS,
  GET_PLEBOS,
  GET_ALL_STATES,
  GET_ALL_CONSTANTS,
  GET_DOCTOR_LIST,
  GET_ORDER_DATA,
  GET_LGP_LAB_LIST,
} from "./../../constants/actionConstants";

import {
  ORDER_PRIORITY,
  PAYMENT_MODE,
  ORDER_TYPE,
  VISIT_TYPE,
} from "./../../constants/commonConstants";

import { getUser } from "Utils/storage";
import {
  Clear,
  Close,
} from "@material-ui/icons";

import DatePicker, { registerLocale } from "react-datepicker";
import el from "date-fns/locale/el";
registerLocale("el", el);

class CreateOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      data: {
        order_type: 1,
        visit_type: 1,
        isCardio: false,
        test_details: [],
      },
      availableTestDetails: [],
      copyId: "",
    };
  }

  componentDidMount = () => {
    if (this.props.cityFilterDisable) {
      this.setState({
        data: {
          ship_to_city: this.props.city,
        },
      });
    }
  };

  UNSAFE_componentWillMount() {
    this.getLGPs();
    this.getAllStates();
    this.getAllConstants();
    if (getUser().isShowDoctors) {
      this.getDoctorsList();
      if (Object.keys(this.state.data || {}).length > 0) {
        const { data } = this.state;
        data["packageName"] = "Cardic Screening package";
        data["isCardio"] = false;
        this.setState({ data });
      }
    }
  }

  componentWillUnmount() {
    this.resetPopup();
  }

  getPlebos = (val) => {
    const { dispatch } = this.props;
    const { data } = this.state;

    let params = {
      count: 100,
      offset: this.state.offset,
    };

    if (data.ship_to_city && (data.ship_to_city || "").length > 0) {
      params["text"] = val || data.ship_to_city;
    }

    dispatch({
      type: GET_PLEBOS,
      data: params,
    });
  };

  getDoctorsList = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_DOCTOR_LIST,
    });
  };

  getAllStates = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_ALL_STATES,
    });
  };

  getAllConstants = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_ALL_CONSTANTS,
    });
  };

  getLGPs = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_LPGS,
      data: {
        count: 200,
        offset: 0,
      },
      onSuccess: (res) => {
        if ((res || []).length > 0) {
          const { data } = this.state;
          data["lgpId"] = res[0].lgpId;
          data["lgpName"] = res[0].name;
          this.setState({ data });
        }
      },
    });
  };

  resetPopup = () => {
    const data = {
      test_details: [],
      barcodes: [],
    };
    this.setState({ data });
  };

  uploadImage = (file) => {
    const { dispatch } = this.props;
    dispatch({
      type: UPLOAD_IMAGE,
      file,
      onSuccess: (res) => {
        if (res && res.imageUrl) {
          const { data } = this.state;
          data.imageUrl = res.imageUrl;
          this.setState({ data });
        }
      },
    });
  };

  handleFormChange = (e, key) => {
    const { data } = this.state;
    data[key] = e.target.value;
    this.setState({ data });
  };

  handleFormTestDetailsChange = (e, key, index) => {
    const { data } = this.state;
    if (data && (data.test_details || []).length > 0) {
      data.test_details[index][key] =
        key === "test_id" || key === "quantity" || key === "test_sequence"
          ? Number(e.target.value)
          : e.target.value;
      this.setState({ data }, () => this.updateAmount());
    }
  };

  updateAmount = () => {
    const { data } = this.state;
    let totalAmount = 0;
    (data.test_details || []).filter(i => i.isAdd).forEach((el) => {
      if (el.unit_price && el.quantity) {
        totalAmount = totalAmount + (el.unit_price * el.quantity);
      }
    })
    data.ori_amount_to_collect = data.ori_amount_to_collect ? data.ori_amount_to_collect : data.amount_to_collect;
    data.amount_to_collect = parseInt(data.ori_amount_to_collect) + parseInt(totalAmount);
    this.setState({ data });
  }

  subAmount = (index, dataCopy) => {
    let testDetails = (dataCopy.test_details && dataCopy.test_details[index]) || {};
    const { data } = this.state;
    if (!testDetails || !testDetails.isAdd) {
      return;
    }
    let totalAmount = 0;
    if (testDetails.unit_price && testDetails.quantity) {
      totalAmount = testDetails.unit_price * testDetails.quantity;
    }
    data.amount_to_collect = parseInt(data.amount_to_collect) - totalAmount
    this.setState({ data });
  }

  getOrderById = (order) => {
    if (!this.state.copyId) {
      return;
    }
    const { dispatch } = this.props;
    dispatch({
      type: GET_ORDER_DATA,
      data: {
        orderId: this.state.copyId,
        type: 4,
        isI2HID: true,
      },
      isPartner: getUser().userType === 2,
      onSuccess: (data) => {
        console.log(data);
        this.setState(
          {
            ...this.state.data,
            data,
            
          }
          , () => {
            if (data.lgpId && data.labId) {
              this.getAllLabList(data.lgpId, data.labId);
            }
            this.getPlebos(data.ship_to_city);
          });
      },
    });
  };

  getAllLabList = (lgpId, labId) => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_LGP_LAB_LIST,
      data: { lgpId, labId },
      isPartner: getUser().userType === 2,
      onSuccess: (data) => {
        this.setState(
          {
            availableTestDetails: data.list,
          });
      },
    });
  };

  handleAutoCompleteChange = (value, key, index) => {
    const { data } = this.state;
    if (data && data.test_details && data.test_details.length > 0) {
      data.test_details[index][key] = value;
      const obj = this.state.availableTestDetails.find(o => o.item_name === value);
      if (obj) {
        data.test_details[index]['test_id'] = obj.item_id;
        data.test_details[index]['unit_price'] = obj.item_price;
      }
      this.setState({ data }, () => this.updateAmount());
    }
  };

  handleFormBarcodeChange = (e, index) => {
    const { data } = this.state;
    if (data && data.barcodes && data.barcodes.length > 0) {
      data.barcodes[index] = e.target.value;
      this.setState({ data });
    }
  };

  renderFormFields = (isPartner) => {
    const { data, isError } = this.state;
    const { doctorsList } = this.props;
    const user = getUser();

    return (
      <React.Fragment>
        <Grid container spacing={1}>
        {!isPartner && (
         <Grid item xs={3}>
         <InputLabel htmlFor="component-add1">
            Order Priority{" "}
          </InputLabel>
          <Select
            fullWidth
            value={data.orderPriority || ""}
            onChange={(e) => {
              this.handleFormChange(e, "orderPriority");
            }}
          >
            {(ORDER_PRIORITY || []).map((priority, index) => (
              <option key={`mode-${priority.id}`} value={priority.name}>
                {" "}
                {priority.name}{" "}
              </option>
            ))}
          </Select>
        </Grid>


        )}
       
          <Grid item xs={3}>
            <label>
              {" "}
              Vendor Order No{" "}
              {!user.isShowDoctors && (
                <span style={{ color: "red" }}>*</span>
              )}{" "}
            </label>
            <Input
              htmlFor="component-name"
              fullWidth //label="Vendor Order No *"
              size="small"
              value={data.vendor_order_number || ""}
              onChange={(e) => this.handleFormChange(e, "vendor_order_number")}
            />
          </Grid>
          <Grid item xs={3}>
            <label>
              {" "}
              Order Type{" "}
              {!user.isShowDoctors && (
                <span style={{ color: "red" }}>*</span>
              )}{" "}
            </label>

            <Select
              fullWidth
              placeholderText="Diagnostics"
              value={data.order_type || 1}
              onChange={(e) => {
                this.handleFormChange(e, "order_type");
              }}
            >
              {(ORDER_TYPE || []).map((ord, index) => (
                <option key={`ord-${ord.id}`} value={ord.id}>
                  {" "}
                  {ord.name}{" "}
                </option>
              ))}
            </Select>
          </Grid>
          <Grid item xs={3}>
            <label>
              {" "}
              Visit Type{" "}
              {!user.isShowDoctors && (
                <span style={{ color: "red" }}>*</span>
              )}{" "}
            </label>
            <Select
              fullWidth
              value={data.visit_type || 1}
              onChange={(e) => {
                this.handleFormChange(e, "visit_type");
              }}
            >
              {(VISIT_TYPE || []).map((visit, index) => (
                <option key={`visit-${visit.id}`} value={visit.id}>
                  {" "}
                  {visit.name}{" "}
                </option>
              ))}
            </Select>
          </Grid>
        </Grid>

        <h2 style={user.isShowDoctors ? { color: "Blue" } : null}>
          {" "}
          Customer Details{" "}
        </h2>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <InputLabel htmlFor="component-customer-name">
              Customer Name <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <Input
              fullWidth //label="Customer Name* "
              htmlFor="component-customer-name"
              size="small"
              value={data.customer_name}
              onChange={(e) => this.handleFormChange(e, "customer_name")}
              error={isError && data.customer_name === ""}
              helperText={
                isError && data.customer_name === ""
                  ? "Please enter a first name"
                  : " "
              }
            />
          </Grid>
          <Grid item xs={3}>
            <InputLabel htmlFor="component-customer-contact">
              Customer Contact <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <Input
              fullWidth //label="Customer Contact* "
              htmlFor="component-customer-contact"
              size="small"
              value={data.customer_contact}
              onChange={(e) => this.handleFormChange(e, "customer_contact")}
              error={isError && data.customer_contact === ""}
              helperText={
                isError && data.customer_contact === ""
                  ? "Please enter a last name"
                  : " "
              }
            />
          </Grid>
          <Grid item xs={3}>
            <InputLabel htmlFor="component-customer_email">
              Customer Email
            </InputLabel>
            <Input
              fullWidth
              htmlFor="component-customer_email"
              size="small"
              value={data.customer_email}
              error={isError && data.customer_email === ""}
              helperText={
                isError && data.customer_email === ""
                  ? "Please enter email"
                  : " "
              }
              onChange={(e) => this.handleFormChange(e, "customer_email")}
            />
          </Grid>
          <Grid item xs={1}>
            <InputLabel htmlFor="component-gender">
              Gender{" "}
              {user.isShowDoctors && <span style={{ color: "red" }}>*</span>}
            </InputLabel>
            <Input
              fullWidth
              label={user.isShowDoctors ? null : "Gender"}
              htmlFor="component-gender"
              size="small"
              value={data.gender}
              onChange={(e) => this.handleFormChange(e, "gender")}
            />
          </Grid>
          <Grid item xs={1}>
            <InputLabel htmlFor="component-age">Age</InputLabel>
            <Input
              fullWidth
              label={user.isShowDoctors ? null : "Age"}
              htmlFor="component-age"
              size="small"
              required={false}
              value={data.age}
              onChange={(e) => this.handleFormChange(e, "age")}
            />
          </Grid>
        </Grid>

        {user.isShowDoctors && (
          <React.Fragment>
            <h2 style={user.isShowDoctors ? { color: "Blue" } : null}>
              {" "}
              Doctor Details{" "}
            </h2>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <InputLabel htmlFor="component-doc-name">
                  Doctor Name<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Select
                  fullWidth
                  value={data.doctorId || ""}
                  onChange={(e) => {
                    this.handleFormChange(e, "doctorId");
                  }}
                >
                  {(doctorsList || []).map((doc, index) => (
                    <option key={`doc-${doc.id}`} value={doc.id}>
                      {" "}
                      {doc.name}{" "}
                    </option>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={3}>
                <InputLabel htmlFor="component-pat-uhid">
                  Patient UHID
                </InputLabel>
                <Input
                  fullWidth //label="Patient UHID"
                  htmlFor="component-pat-uhid"
                  size="small"
                  value={data.patientUHID}
                  error={isError && data.patientUHID === ""}
                  helperText={
                    isError && data.patientUHID === ""
                      ? "Please enter patient UH id"
                      : " "
                  }
                  onChange={(e) => this.handleFormChange(e, "patientUHID")}
                />
              </Grid>
              <Grid item xs={3}>
                <div>
                  Is Cardio? <span style={{ color: "red" }}>*</span>
                </div>

                <Switch
                  color="primary"
                  name="isCardio"
                  size="small"
                  checked={data.isCardio}
                   onChange={(e) => {
                    const { data } = this.state;
                    data["isCardio"] = e.target.checked;
                    this.setState({ data });
                  }}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        )}

        <h2> Address Details </h2>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <InputLabel htmlFor="component-labName">
              Lab Name{" "}
              {!user.isShowDoctors && <span style={{ color: "red" }}>*</span>}
            </InputLabel>
            <Input
              fullWidth
              htmlFor="component-labName"
              size="small"
              value={data.labName}
              label="Lab Name *"
              onChange={(e) => this.handleFormChange(e, "labName")}
            />
          </Grid>
          <Grid item xs={3}>
            <label>
              {" "}
              LGP{" "}
              {!user.isShowDoctors && (
                <span style={{ color: "red" }}>*</span>
              )}{" "}
            </label>
            <Select
              fullWidth
              value={
                (this.props.lgps || []).find((el) => el.lgpId === data.lgpId) ||
                ""
              }
              onChange={(e) => {
                const { data } = this.state;
                const value = e.target.value;
                data["lgpId"] = value.lgpId;
                data["lgpName"] = value.name;
                this.setState({ data });
                  }}
            >
              {(this.props.lgps || []).map((lgp, index) => (
                <option key={`lgp-${lgp.lgpId}`} value={lgp}>
                  {" "}
                  {lgp.name}{" "}
                </option>
              ))}
            </Select>
          </Grid>

          <Grid item xs={3}>
            <label>
              {" "}
              Earliest Pickup{" "}
              {!user.isShowDoctors && (
                <span style={{ color: "red" }}>*</span>
              )}{" "}
            </label>
            <DatePicker
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
              placeholderText="Select a date"
              selected={data.earliest_pickup_date}
              onChange={(date) => {
                const { data } = this.state;
                data.earliest_pickup_date = new Date(date).getTime();
                this.setState({ data });
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <label>
              {" "}
              Latest Pickup{" "}
              {!user.isShowDoctors && (
                <span style={{ color: "red" }}>*</span>
              )}{" "}
            </label>
            <DatePicker
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
              placeholderText="Select a date"
              selected={data.latest_pickup_date}
              onChange={(date) => {
                const { data } = this.state;
                data.latest_pickup_date = new Date(date).getTime();
                this.setState({ data });
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <InputLabel htmlFor="component-add1">
              Address 1{" "}
              {!user.isShowDoctors && <span style={{ color: "red" }}>*</span>}
            </InputLabel>
            <Input
              fullWidth
              htmlFor="component-add1"
              size="small"
              value={data.ship_to_address1}
              onChange={(e) => this.handleFormChange(e, "ship_to_address1")}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              size="small"
              label="Address 2"
              value={data.ship_to_address2}
              onChange={(e) => this.handleFormChange(e, "ship_to_address2")}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              size="small"
              label="Landmark"
              value={data.landmark}
              onChange={(e) => this.handleFormChange(e, "landmark")}
            />
          </Grid>
          <Grid item xs={3}>
            <label>
              {" "}
              City{" "}
              {!user.isShowDoctors && (
                <span style={{ color: "red" }}>*</span>
              )} :{" "}
            </label>
            <Select
              fullWidth
              value={data.ship_to_city || ""}
              disabled={this.props.cityFilterDisable}
              onChange={(e) => {
                this.handleFormChange(e, "ship_to_city");
                this.getPlebos(e.target.value);
              }}
            >
              {(this.props.cities || []).map((city, index) => (
                <option key={`city-${index}`} value={city}>
                  {" "}
                  {city}{" "}
                </option>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <label>
              {" "}
              State{" "}
              {!user.isShowDoctors && (
                <span style={{ color: "red" }}>*</span>
              )} :{" "}
            </label>
            <Select
              fullWidth
              value={data.ship_to_state || ""}
              onChange={(e) => {
                this.handleFormChange(e, "ship_to_state");
              }}
            >
              {(this.props.states || []).map((state, index) => (
                <option key={`state-${state.id}`} value={state.name}>
                  {" "}
                  {state.name}{" "}
                </option>
              ))}
            </Select>
          </Grid>
          <Grid item xs={3}>
            <InputLabel htmlFor="component-zipcode">
              Zipcode{" "}
              {!user.isShowDoctors && <span style={{ color: "red" }}>*</span>}
            </InputLabel>
            <Input
              fullWidth
              htmlFor="component-add2"
              size="small"
              // label="Zipcode* "
              value={data.ship_to_zip_code}
              onChange={(e) => this.handleFormChange(e, "ship_to_zip_code")}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              size="small"
              type="number"
              label="Latitude"
              value={data.latitude}
              onChange={(e) => this.handleFormChange(e, "latitude")}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              size="small"
              type="number"
              label="Longitude"
              value={data.longitude}
              onChange={(e) => this.handleFormChange(e, "longitude")}
            />
          </Grid>
        </Grid>

        <h2> Payment Details </h2>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <label> Payment Mode </label>
            <Select
              fullWidth
              value={data.payment_mode || ""}
              onChange={(e) => {
                this.handleFormChange(e, "payment_mode");
              }}
            >
              {(PAYMENT_MODE || []).map((mode, index) => (
                <option key={`mode-${mode.id}`} value={mode.id}>
                  {" "}
                  {mode.name}{" "}
                </option>
              ))}
            </Select>
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Prepaid Amount"
              value={data.prepaid_amount}
              onChange={(e) => this.handleFormChange(e, "prepaid_amount")}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Amount To Collect"
              value={data.amount_to_collect}
              onChange={(e) => this.handleFormChange(e, "amount_to_collect")}
            />
          </Grid>
        </Grid>

        <h2>
          {" "}
          Test Details{" "}
          {!user.isShowDoctors && <span style={{ color: "red" }}>*</span>}{" "}
        </h2>
        <TableContainer component={Paper} className="mb-30">
          <Table size="small" aria-label="order table">
            <TableHead>
              <TableRow>
                <TableCell> Seq # </TableCell>
                <TableCell> ID </TableCell>
                <TableCell> Name </TableCell>
                <TableCell> Qty </TableCell>
                <TableCell> Description </TableCell>
                <TableCell> Tube </TableCell>
                <TableCell> Price </TableCell>
                <TableCell> Comments </TableCell>
                {/*<TableCell />*/}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data.test_details || []).length <= 0 && (
                <TableRow>
                  <TableCell component="th" scope="row">
                    {" "}
                    No order found.{" "}
                  </TableCell>
                </TableRow>
              )}
              {(data.test_details || []).map((order, index) => {
                return (
                  <TableRow key={`test-${index + 1}`}>
                    <TableCell>
                      <TextField
                        fullWidth
                        type="number"
                        style={{ width: 20 }}
                        // label="Prepaid Amount"
                        value={order.test_sequence}
                        onChange={(e) =>
                          this.handleFormTestDetailsChange(
                            e,
                            "test_sequence",
                            index
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        type="number"
                        style={{ width: 20 }}
                        value={order.test_id}
                        onChange={(e) =>
                          this.handleFormTestDetailsChange(e, "test_id", index)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Autocomplete
                        freeSolo
                        style={{ width: 250 }}
                        disableClearable
                        inputValue={order.test_name}
                        onChange={(e, value) => {
                          this.handleAutoCompleteChange(
                            value,
                            "test_name",
                            index
                          )
                        }
                        }
                        options={this.state.availableTestDetails.map((option) => option.item_name)}
                        renderInput={(params) => (
                          <TextField
                            {
                            ...params
                            }
                            fullWidth

                            onChange={(e) =>
                              this.handleFormTestDetailsChange(
                                e,
                                "test_name",
                                index
                              )
                            }
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        style={{ width: "50px" }}
                        type="number"
                        value={order.quantity}
                        onChange={(e) =>
                          this.handleFormTestDetailsChange(e, "quantity", index)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={order.test_description}
                        onChange={(e) =>
                          this.handleFormTestDetailsChange(
                            e,
                            "test_description",
                            index
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={order.test_tube}
                        onChange={(e) =>
                          this.handleFormTestDetailsChange(
                            e,
                            "test_tube",
                            index
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={order.unit_price}
                        onChange={(e) =>
                          this.handleFormTestDetailsChange(
                            e,
                            "unit_price",
                            index
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={order.comments}
                        onChange={(e) =>
                          this.handleFormTestDetailsChange(e, "comments", index)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        edge="end"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          const { data } = this.state;
                          const dataCopy = JSON.parse(JSON.stringify(data));
                          (data.test_details || []).splice(index, 1);
                          this.setState({ data }, () => this.subAmount(index, dataCopy));
                        }}
                      >
                        <Clear />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            const { data } = this.state;
            if (!data.test_details) {
              data.test_details = [];
            }
            (data.test_details || []).push({
              quantity: 1,
              test_description: "",
              test_name: "",
              test_tube: "",
              unit_price: "",
              isAdd: true,
            });
            this.setState({ data }, () => this.updateAmount());
          }}
        >
          Add
        </Button>
        <h2> Barcodes </h2>
        <TableContainer component={Paper} className="mb-30">
          <Table size="small" aria-label="order table">
            <TableHead>
              <TableRow>
                <TableCell> Barcode </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data.barcodes || []).length <= 0 && (
                <TableRow>
                  <TableCell component="th" scope="row">
                    {" "}
                    No Barcode found.{" "}
                  </TableCell>
                </TableRow>
              )}
              {(data.barcodes || []).map((barcode, index) => {
                return (
                  <TableRow key={`test-${index + 1}`}>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={barcode}
                        onChange={(e) =>
                          this.handleFormBarcodeChange(e, index)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        edge="end"
                        color="inherit"
                        style={{ width: 20 }}
                        size="small"
                        onClick={() => {
                          const { data } = this.state;
                          (data.barcodes || []).splice(index, 1);
                          this.setState({ data });
                        }}
                      >
                        <Clear />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            const { data } = this.state;
            let barcodesArray = data.barcodes || [];
            barcodesArray.push('');
            this.setState({ data: { ...data, barcodes: barcodesArray } });
          }}
        >
          Add
        </Button>
        <h2> Package Details </h2>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Package Name"
              value={data.packageName}
              onChange={(e) => this.handleFormChange(e, "packageName")}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Package Description"
              value={data.packageDescription}
              onChange={(e) => this.handleFormChange(e, "packageDescription")}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth
              label="comments"
              value={data.comments}
              onChange={(e) => this.handleFormChange(e, "comments")}
            />
          </Grid>
        </Grid>

        {data.consumablesList &&
          data.consumablesList.length > 0 && ( //user.userType===1 &&
            <div>
              <h2> Consumables </h2>
              <TableContainer component={Paper} className="mb-30">
                <Table size="small" aria-label="order table">
                  <TableHead>
                    <TableRow>
                      <TableCell> Name </TableCell>
                      <TableCell> Qty </TableCell>
                      <TableCell> Bill Price </TableCell>
                      <TableCell> Bill Price GST </TableCell>
                      <TableCell> Landing Price </TableCell>
                      <TableCell> Landing Price GST </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(data.consumablesList || []).length <= 0 && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {" "}
                          No consumables found.{" "}
                        </TableCell>
                      </TableRow>
                    )}
                    {(data.consumablesList || []).map((order, index) => {
                      return (
                        <TableRow key={`consumable-${index + 1}`}>
                          <TableCell> {order.name} </TableCell>
                          <TableCell> {order.quantity} </TableCell>
                          <TableCell> {order.billingPrice} </TableCell>
                          <TableCell> {order.billingPriceGST} </TableCell>
                          <TableCell> {order.landingPrice} </TableCell>
                          <TableCell> {order.landingPriceGST} </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
      </React.Fragment>
    );
  };

  render() {
    const { isPartner } = this.props;
    return (
      <Dialog
        disableBackdropClick
        open={true}
        onClose={() => {
          this.resetPopup();
          this.props.closePopup();
        }}
        fullScreen
      >
        <DialogTitle id="clinic-popup">
          <div className="flex">
            Create Order
            <div className="search-clinic search-orders pr w-300 ml-10">
              <span className="fa fa-search" />
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                placeholder="Copy by ID…"
                value={this.state.copyId}
                onChange={(e) => {
                  this.setState({ copyId: e.target.value });
                }}
              />
              {this.state.copyId.length > 0 && (
                <span
                  className="cancel-icon"
                  onClick={() => this.setState({ copyId: '' })}
                >
                  {" "}
                  x{" "}
                </span>
              )}
            </div>
            <div className="ml-10">
              <Button
                onClick={() => {
                  this.getOrderById()
                }}
                variant="contained"
                color="primary"
              >
                Load
              </Button>
            </div>
            <div className="close-icon">
              <IconButton edge="end" color="secondary" size="small" onClick={() => {
                this.resetPopup();
                this.props.closePopup();
              }}>
                <Close fontSize={'large'} />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>{this.renderFormFields(isPartner)}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.props.saveNewOrder(this.state.data);
            }}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button
            onClick={() => {
              this.resetPopup();
              this.props.closePopup();
            }}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ lgp, constants, plebos }) => {
  return {
    isLoading: lgp.isSubmitting,
    doctorsList: lgp.doctorsList,
    lgps: lgp.plebos,
    cities: constants.cities,
    states: constants.states,
    plebos: plebos.plebos,
  };
};

export default withRouter(connect(mapStateToProps)(CreateOrder));
