/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ActionLoader from "../../components/ActionLoader";
import {
  Grid,
  Button,
  makeStyles,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import  { components } from "react-select";
import {
  ArrowDropDownOutlined,
  Visibility,
  Edit,
} from "@material-ui/icons";

import {
  GET_ALL_CONSTANTS,
  GET_MER_QUESTIONS,
} from "../../constants/actionConstants";
import moment from "moment";

import MERForm from "./MERForm";

import "./index.css";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "275px",
    marginTop: 18,
    height: "38px",
    border: "none",
  },
  reportCard: {
    background: "#FFFFFF",
    borderRadius: 5,
    padding: 20,
    alignItems: "center",
    margin: 10,
  },
  section: {
    padding: 10,
    width: "100%",
  },
}));


function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


const MER = (props) => {
  const { lgpId } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [forms, setForms] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [offset, setOffset] = useState(0);
  const [merData, setMerData] = useState(null);
  const [previewModal, setPreviewModal] = useState(false);
  useEffect(() => {
    getAllConstants();
  }, []);

  useEffect(() => {
    if (!isAdd) {
      setMerData(null);
      getMers();
    }
  }, [isAdd, offset]);

  const getMers = () => {
    const { dispatch } = props;
    let data = {
      count: 10,
      offset,
      lgpId,
    };

    dispatch({
      type: GET_MER_QUESTIONS,
      data,
      onSuccess: (res) => {
        setForms(res.list || []);
        setHasMore(
          res && res.areMoreItemsAvailable ? res.areMoreItemsAvailable : false
        );
      },
    });
  };

  const getAllConstants = () => {
    const { dispatch } = props;
    dispatch({
      type: GET_ALL_CONSTANTS,
    });
  };

  const handleEdit = (data) => {
    setMerData(data);
    setIsAdd(true);
  };

  const renderPagination = () => {
    return (
      <div className="mb-30">
        <Grid container spacing={5}>
          <Grid item xs={9} />
          <Grid item xs={3}>
            <Button
              style={{ marginRight: "20px" }}
              size="medium"
              color="primary"
              variant="outlined"
              disabled={offset === 0}
              onClick={() => {
                setOffset(offset - 10);
              }}
            >
              {"< Previous"}
            </Button>
            <Button
              size="medium"
              color="primary"
              variant="outlined"
              disabled={!hasMore}
              onClick={() => {
                setOffset(offset + 10);
              }}
            >
              {"Next >"}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderPreviewPopup = () => {
    return (
      <Dialog
        disableBackdropClick
        open={previewModal}
        maxWidth={"md"}
        fullWidth
        onClose={() => {
          setPreviewModal(false);
          setMerData(null);
        }}
      >
        <DialogTitle>{"MER Form Preview"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} className="align-left">
              <div style={{ marginTop: 10 }}>Name: {merData.name || ""}</div>
              <div style={{ marginTop: 10 }}>
                Created By: {merData.user || ""}
              </div>
            </Grid>
            {merData &&
              merData.mer.map((el, sIndex) => (
                <Grid
                  container
                  item
                  key={sIndex}
                  xs={12}
                  className="align-left"
                >
                  <Paper elevation={3} className={classes.section}>
                    <Grid item xs={12} className="align-left">
                      <div style={{ marginTop: 10 }}>
                        Section Title: {el.title || ""}
                      </div>
                      <div style={{ marginTop: 10 }}>
                        Section Description: {el.description || ""}
                      </div>
                    </Grid>
                    {el.questions &&
                      el.questions.map((ques, qIndex) => (
                        <Paper
                          key={qIndex}
                          elevation={3}
                          className={classes.section}
                          style={{ marginTop: 10 }}
                        >
                          <Grid item xs={12} className="align-left">
                            <div style={{ marginTop: 10 }}>
                              Question: {ques.name || ""}
                            </div>
                            <div style={{ marginTop: 10 }}>
                              Is Mandatory:{" "}
                              {!!ques.isMandatory ? "Yes" : "No" || false}
                            </div>
                            <div style={{ marginTop: 10 }}>
                              Type: {capitalizeFirstLetter(ques.type) || ""}
                            </div>
                            {ques.options &&
                              ques.options.map((option, index) => (
                                <div key={index} style={{ marginTop: 10 }}>
                                  Option {index + 1}: {option.name || ""}
                                </div>
                              ))}
                          </Grid>
                        </Paper>
                      ))}
                  </Paper>
                </Grid>
              ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ borderColor: "#00C9AA", color: "#00C9AA" }}
            onClick={() => {
              setPreviewModal(false);
              setMerData(null);
            }}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div>
      {isLoading && <ActionLoader />}
      <React.Fragment>
        {isAdd ? (
          <MERForm lgpId={lgpId} merData={merData} setIsAdd={setIsAdd} />
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12} className="align-right">
              <Button
                style={{ marginRight: "20px" }}
                size="medium"
                color="primary"
                variant="outlined"
                disabled={false}
                onClick={() => setIsAdd(true)}
              >
                {"Add Form"}
              </Button>
            </Grid>
            <Grid item xs={12} className="align-left mb-10">
              <TableContainer component={Paper} className="mb-30">
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell> Name </TableCell>
                      <TableCell> Created By </TableCell>
                      <TableCell> Created On </TableCell>
                      <TableCell> Action </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(forms || []).length <= 0 && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {" "}
                          No order found.{" "}
                        </TableCell>
                      </TableRow>
                    )}
                    {(forms || []).map((order) => (
                      <React.Fragment key={order.formId}>
                        <TableRow>
                          <TableCell>{order.name}</TableCell>
                          <TableCell>{order.user}</TableCell>
                          <TableCell>{moment(order.createdDate).format(
                              "D MMM, YY h:mm A"
                            )}</TableCell>
                          <TableCell>
                            <IconButton
                              edge="end"
                              color="inherit"
                              style={{ marginRight: 10 }}
                              size="small"
                              onClick={() => {
                                setMerData(order);
                                setPreviewModal(true);
                              }}
                            >
                              <Visibility />
                            </IconButton>
                            <IconButton
                              edge="end"
                              color="inherit"
                              style={{ marginRight: 10 }}
                              size="small"
                              onClick={() => handleEdit(order)}
                            >
                              <Edit />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {renderPagination()}
              {previewModal && renderPreviewPopup()}
            </Grid>
          </Grid>
        )}
      </React.Fragment>
    </div>
  );
};

export default withRouter(connect(null)(MER));
