import { 
  ROUTE_LIST,

  IS_SUBMITTING,
} from 'Constants/actionConstants';

const tRoutes = (state={
  
  isSubmitting: false,
  routes: [],

}, action)=> {

  switch (action.type) {
    
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };

    case ROUTE_LIST:
      return {
        ...state,
        routes: action.data,
        hasMore: action.hasMore,
      };

    default:
      return state;
  }

}

export default tRoutes;
