export const TOAST_HIDE_TIME = 2;

export const MENU_LIST = [
  {
    id: 20,
    name: "Dashboard",
    route: "/statistics",
    img: "dashboardwhite",
    key: "dashboard",
    isPartner: true,
    isCommon: true,
  },
  {
    id: 1,
    name: "Phlebo",
    key: "phlebo",
    route: "/phlebo",
    img: "phlebotomistwhite",
  },
  { id: 2, name: "Runner", key: "runner", route: "/runner", img: "runwhite" },
  {
    id: 28,
    name: "Doctors",
    key: "ticket_dashboard",
    route: "/doctors",
    img: "Doctors",
    // isPartner: true,
  },
  { id: 3, name: "Routes", key: "routes", route: "/routes", img: "waywhite" },
 

  {
    id: 3,
    name: "Config",
    route: "/users",
    img: "config",
    key: "config",
    child: [
      { id: 1, name: "Users", route: "/users", img: "user" },
      { id: 2, name: "Department", route: "/department", img: "phwhite" },
      { id: 3, name: "Roles", route: "/roles", img: "phlebotomistwhite" },
    ],
  },

  { id: 4, name: "LGP", key: "lgp", route: "/lgp", img: "partnerswhite" },

  {
    id: 5,
    name: "Orders",
    route: "/orders",
    key: "orders",
    img: "phwhite",
    isPartner: true,
    isCommon: true,
  },
  {
    id: 6,
    name: "Reports",
    route: "/reports",
    key: "reports",
    img: "reportwhite",
    isPartner: true,
    isCommon: true,
  },
  {
    id: 8,
    name: "Track",
    route: "/Track",
    key: "track",
    img: "pinwhite",
    isPartner: true,
    isCommon: true,
  },
  {
    id: 10,
    name: "Inventory",
    key: "inventory",
    route: "/inventory",
    img: "Inventory",
  },
  { id: 11, name: "Labs", key: "labs", route: "/labs", img: "Labs" },
  { id: 15, name: "Zones", key: "zones", route: "/zone", img: "Zones" },
  {
    id: 16,
    name: "Roster Plan",
    key: "roster_plan",
    route: "/rosterplan",
    img: "RosterPlan",
  },
  {
    id: 17,
    name: "Auto Assign",
    key: "auto_assign",
    route: "/autoassign",
    img: "AutoAssigning",
  },
  { id: 22, name: "Trips", key: "trips", route: "/trips", img: "Trips" },
  {
    id: 18,
    name: "Execution Reports",
    route: "/excecutionreports",
    key: "execution_reports",
    img: "ExecutionReport",
  },
  {
    id: 19,
    name: "Order Upload",
    route: "/clientupload",
    img: "orderuploadwhite",
    key: "order_upload",
    isPartner: true,
  },
  {
    id: 21,
    name: "Phlebo Execution",
    key: "phlebo_execution",
    route: "/attendance",
    img: "PhleboExecution",
  },
  {
    id: 23,
    name: "Slot Booking",
    key: "slot",
    route: "/slotbooking",
    img: "SlotBooking",
  },  
  {
    id: 31,
    name: "My Orders",
    key: "ticket_dashboard",
    route: "/doctororders",
    img: "phwhite",
    isDoctor: true,
  },
  {
    id:32,
    name:"Settings",
    key:'settings',
    route:'/doctorsettings',
    img:'settingswhite',
    isDoctor:true,
  },
  {
    id: 9,
    name: "Settings",
    route: "/settings",
    img: "settingswhite",
    key: "settings",
    isPartner: true,
    isCommon: true,
  }
];

export const ORDER_CONSTANTS = [
{ name: "API", id: 20, hasAssign: false, hasAccept: false },
{ name: "NEW", id: 1, hasAssign: true, hasAccept: true },
{ name: "ACCEPTED", id: 2, hasAssign: true, hasAccept: true },
{ name: "REJECTED", id: 3, hasAssign: false, hasAccept: false },
{ name: "ASSIGNED", id: 4, hasAssign: true, hasAccept: true },
{ name: "PENDING LAB HANDOVER", id: 5, hasAssign: false, hasAccept: false },
{ name: "COMPLETED", id: 7, hasAssign: false, hasAccept: false },
{ name: "IN REVIEW", id: 11, hasAssign: true, hasAccept: true },
{ name: "CR", id: 70, hasAssign: true, hasAccept: true },
{ name: "PARTNER CANCELLED", id: 8, hasAssign: false, hasAccept: false },
{ name: "CANCELLED BY CUSTOMER", id: 6, hasAssign: false, hasAccept: true },{
  name: "CANCELLED BY CUSTOMER DUE TO PHLEBO",
  id: 9,
  hasAssign: false,
  hasAccept: true,
},
{
  name: "RESCHEDULE BY CUSTOMER",
  id: 30,
  hasAssign: false,
  hasAccept: true,
},
{
  name: "RESCHEDULE DUE TO PHLEBO MISTAKE",
  id: 31,
  hasAssign: false,
  hasAccept: true,
},
{
  name: "ZIPCODE NOT SERVICEABLE",
  id: 55,
  hasAssign: false,
  hasAccept: false,
},
{ name: "DUPLICATE", id: 60, hasAssign: false, hasAccept: false },
];

export const DOCTOR_ORDER_CONSTANTS = [
  { name: "ASSIGNED", id: 4, hasAssign: true, hasAccept: true },
  { name: "COMPLETED", id: 7, hasAssign: false, hasAccept: false },
 ]

export const PAST_TABS = [
  { name: "ASSIGNED", id: 4, hasAssign: true, hasAccept: true },
  { name: "PENDING LAB HANDOVER", id: 5, hasAssign: true, hasAccept: true },
  { name: "IN REVIEW", id: 11, hasAssign: true, hasAccept: true },
  { name: "UNPLANNED", id: 50, hasAssign: true, hasAccept: true },
  { name: "COMPLETED", id: 7, hasAssign: true, hasAccept: true },
  { name: "CR", id: 70, hasAssign: true, hasAccept: true },

  { name: "PARTNER CANCELLED", id: 8, hasAssign: true, hasAccept: true },
  { name: "CANCELLED BY CUSTOMER", id: 6, hasAssign: true, hasAccept: true },
  {
    name: "CANCELLED BY CUSTOMER DUE TO PHLEBO",
    id: 9,
    hasAssign: true,
    hasAccept: true,
  },
  { name: "RESCHEDULE", id: 30, hasAssign: true, hasAccept: true },
  {
    name: "RESCHEDULE DUE TO CUSTOMER REQUEST",
    id: 31,
    hasAssign: true,
    hasAccept: true,
  },
  { name: "REJECTED", id: 3, hasAssign: true, hasAccept: true },
  { name: "ZIPCODE NOT SERVICEABLE", id: 55, hasAssign: true, hasAccept: true },
  { name: "DUPLICATE", id: 60, hasAssign: true, hasAccept: true },
];

export const PAYMENT_MODE = [
  { id: 1, name: "PREPAID/PARTNER" },
  { id: 2, name: "CASH/I2H" },
  { id: 3, name: "ONLINE/I2H" },
  { id: 4, name: "ONLINE/PARTNER" },
];

export const ORDER_EXECUTION_TYPE = [
  { id: 1, name: "Phlebotomy Model" },
  { id: 2, name: "End to End execution" },
  { id: 3, name: "Stand alone" },
];

export const VISIT_TYPE = [
  { id: 1, name: "1 Visits" },
  { id: 2, name: "2 Visits" },
  { id: 3, name: "Add On" },
];

export const ORDER_TYPE = [
  { id: 1, name: "Diagnostics" },
  { id: 2, name: "PPMC" },
  { id: 3, name: "Covid" },
  { id: 4, name: "ECG" },
];

export const FEEDBACK_TYPES = [
  { id: 1, name: "Good" },
  { id: 2, name: "Okay" },
  { id: 3, name: "Bad" },
];

export const ORDER_CANCEL_CODE = [
  { name: "CUSTOMER_NOT_AVAILABLE", id: 1 },
  { name: "INCORRECT_ADDRESS", id: 2 },
  { name: "CUSTOMER_REFUSED_COLLECTION", id: 3 },
  { name: "CANCELLED_BY_CUSTOMER", id: 4 },
  { name: "CANCELLED_AT_CUSTOMER_POINT", id: 5 },
  { name: "CUSTOMER_NOT_FASTING", id: 6 },
  { name: "PHLEBO_COULD_NOT_CONTACT_CUSTOMER", id: 7 },
  { name: "OTHERS", id: 8 },
];

export const ORDER_ASSIGNED_STATUS = [
  { name: "PHLEBO_DETAILS", id: 1 },
  { name: "REJECTED_BY_I2H", id: 2 },
  { name: "TRIP_START", id: 3 },
  { name: "REACHED_CUSTOMER_LOCATION", id: 4 },
  { name: "SAMPLE_COLLECTED", id: 5 },
  { name: "CUSTOMER_SIGNOFF", id: 6 },
  { name: "PHLEBO_LEFT_CUSTOMER", id: 7 },
  { name: "CANCELLED_ORDER", id: 8 },
  { name: "LAB_ACCEPTED_SAMPLE", id: 9 },
  { name: "LAB_REJECTED_SAMPLE", id: 10 },
  { name: "ORDER_RESCHEDULED", id: 14 },
  { name: "PARTNER_CANCELED", id: 16 },
  { name: "ZIPCODE_NON_SERVICE", id: 17 },
  { name: "DUPLICATE_ORDER", id: 18 },
  { name: "IN_REVIEW", id: 19 },
];

export const ORDER_PRIORITY = [
  { id: 1, name: "None" },
  { id: 2, name: "Escalation" },
  { id: 3, name: "VIP" },
  { id: 4, name: "Age" },
]

export const FASTING_STATUS = [
  { id: 1, name: "Fasting" },
  { id: 2, name: "Non Fasting" },
  { id: 3, name: "NA" },
]

export const DOCTOR_TYPE = [
  {id:1, name:"THC Employee"},
  {id:2, name:"On-Call Doctor"},
  {id:3, name:"On-Call Vendor"}
]
export const PHLEBO_TYPE = [
  {id:1, name:"THC Employee"},
  {id:2, name:"On-Call Phlebo"},
  {id:3, name:"On-Call Vendor"}
]
export const GENDER = [
  {id:"Male", name:"Male"},
  {id:"Female", name:"Female"},
  {id:"Other", name:"Other"}
]
export const RUNNER_TYPE = [
  {id:1, name:"THC Employee"},
  {id:2, name:"On-Call Runner"},
  {id:3, name:"On-Call Vendor"}
]
export const USER_TYPE = [
  {id:1, name:"THC Employee"},
  {id:2, name:"On-Call User"},
  {id:3, name:"On-Call Vendor"}
]


export const WEEKDAYS = [
  {id:1, name:"Monday"},
  {id:2, name:"Tuesday"},
  {id:3, name:"Wednesday"},
  {id:4, name:"Thursday"},
  {id:5, name:"Friday"},
  {id:6, name:"Saturday"},
  {id:7, name:"Sunday"},
]

export const LEAVE_TYPES = ["SICK", "CASUAL", "BEREAVEMENT"];

export function getTimerBits() {
  let no = "";
  for (let i = 0; i < 96; i++) {
    no = no + "0";
  }
  return no;
}

export const PERMISSIONS = {
  dashboard: {
    name: "Dashboard",
    key: "dashboard",
    permissions: {
      isAccess: false,
      isListSearch: false,
    },
  },
  phlebo: {
    name: "Phlebo",
    key: "phlebo",
    permissions: {
      isAccess: false,
      isCreate: false,
      isListSearch: false,
      isUpdateEdit: false,
      isDelete: false,
    },
  },
  runner: {
    name: "Runner",
    key: "runner",
    permissions: {
      isAccess: false,
      isCreate: false,
      isListSearch: false,
      isUpdateEdit: false,
      isDelete: false,
    },
  },
  routes: {
    name: "Routes",
    key: "routes",
    permissions: {
      isAccess: false,
      isCreate: false,
      isListSearch: false,
      isUpdateEdit: false,
      isDelete: false,
    },
  },
  config: {
    name: "Config",
    key: "config",
    permissions: {
      isAccess: false,
      isCreate: false,
      isListSearch: false,
      isUpdateEdit: false,
      isDelete: false,
    },
  },
  user:{
    name:'User',
    key:'user',
    permissions:{
      isAccess: false,
      isCreate: false,
      isListSearch: false,
      isUpdateEdit: false,
      isDelete: false, 
    }
  },
  department:{
    name:'Department',
    key:'department',
    permissions:{
      isAccess: false,
      isCreate: false,
      isListSearch: false,
      isUpdateEdit: false,
      isDelete: false, 
    }
  },
  roles:{
    name:'Roles',
    key:'roles',
    permissions:{
      isAccess: false,
      isCreate: false,
      isListSearch: false,
      isUpdateEdit: false,
      isDelete: false, 
    }
  },
  slots:{
    name:'Slots',
    key:'slots',
    permissions:{
      isAccess: false,
      isCreate: false,
      isListSearch: false,
      isUpdateEdit: false,
      isDelete: false, 
    }
  },
  lgp: {
    name: "LGP",
    key: "lgp",
    permissions: {
      isAccess: false,
      isCreate: false,
      isListSearch: false,
      isUpdateEdit: false,
      isDelete: false,
    },
  },
  orders: {
    name: "Orders",
    key: "orders",
    permissions: {
      isAccess: false,
      isCreate: false,
      isListSearch: false,
      isSearchFilter: false,
      isAcceptOrder: false,
      isRejectOrder: false,
      isRescheduleOrder: false,
      isAssignOrder: false,
      isUpdateEdit: false,
      isDelete: false,
      isUploadOrders: false,
      isDownloadOrders: false,
      isDownloadReports: false,
    },
  },
  reports: {
    name: "Reports",
    key: "reports",
    permissions: {
      isAccess: false,
      isListSearch: false,
    },
  },
  track: {
    name: "Track",
    key: "track",
    permissions: {
      isAccess: false,
      isListSearch: false,
    },
  },
  settings: {
    name: "Settings",
    key: "settings",
    permissions: {
      isAccess: false,
      isListSearch: false,
    },
  },
  inventory: {
    name: "Inventory",
    key: "inventory",
    permissions: {
      isAccess: false,
      isCreate: false,
      isListSearch: false,
      isUpdateEdit: false,
      isDelete: false,
    },
  },
  labs: {
    name: "Labs",
    key: "labs",
    permissions: {
      isAccess: false,
      isCreate: false,
      isListSearch: false,
      isUpdateEdit: false,
      isDelete: false,
    },
  },
  route_plan: {
    name: "Route Plan",
    key: "route_plan",
    permissions: {
      isAccess: false,
      isListSearch: false,
      isDnDAssign: false,
    },
  },
  zones: {
    name: "Zones",
    key: "zones",
    permissions: {
      isAccess: false,
      isCreate: false,
      isListSearch: false,
      isUpdateEdit: false,
      isDelete: false,
    },
  },
  roster_plan: {
    name: "Roster Plan",
    key: "roster_plan",
    permissions: {
      isAccess: false,
      isCreate: false,
      isListSearch: false,
      isUpdateEdit: false,
      isDelete: false,
    },
  },
  auto_assign: {
    name: "Auto Assign",
    key: "auto_assign",
    permissions: {
      isAccess: false,
      isCreate: false,
      isListSearch: false,
    },
  },
  execution_reports: {
    name: "Execution Reports",
    key: "execution_reports",
    permissions: {
      isAccess: false,
      isCreate: false,
    },
  },
  phlebo_execution: {
    name: "Phlebo Execution",
    key: "phlebo_execution",
    permissions: {
      isAccess: false,
      isListSearch: false,
      isUpdateEdit: false,
    },
  },
  trips: {
    name: "Trips",
    key: "trips",
    permissions: {
      isAccess: false,
      isCreate: false,
    },
  },
  slot: {
    name: "Slot",
    key: "slot",
    permissions: {
      isAccess: false,
      isCreate: false,
      isListSearch: false,
      isUpdateEdit: false,
      isDelete: false,
    },
  },
  ticket: {
    name: "Ticketing",
    key: "ticket",
    permissions: {
      isAccess: false,
      isCreate: false,
      isListSearch: false,
      isUpdateEdit: false,
      isDelete: false,
    },
  },
  ticket_dashboard: {
    name: "Ticket Dashboard",
    key: "ticket_dashboard",
    permissions: {
      isAccess: false,
      isCreate: false,
      isListSearch: false,
      isUpdateEdit: false,
      isDelete: false,
    },
  },
  orderupload: {
    name: "Order Upload",
    key: "orderupload",
    permissions: {
      isAccess: false,
      isCreate: false,
      isListSearch: false,
      isUpdateEdit: false,
      isDelete: false,
    },
  },
  orderscanner: {
    name: "Order Scanner",
    key: "orderscanner",
    permissions: {
      isAccess: false,
      isCreate: false,
      isListSearch: false,
      isUpdateEdit: false,
      isDelete: false,
    },
  },
};
