import DocContainer from 'Containers/DocContainer/index';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./clinics.css";
import {
    Button,
    Tabs,
    Tab,
    Typography,
    Grid,
    TextField,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Box,
    Select,
    Avatar,
    MenuItem,
    IconButton,
    Chip,
} from '@material-ui/core';
import '../RosterPlan/date.css';
import { GET_ALL_CONSTANTS, GET_ALL_STATES, ADD_DOCTORS, TOAST_ERROR, UPLOAD_IMAGE, UPDATE_DOCTORS, GET_ALL_DOCTORS } from 'Constants/actionConstants';
import { DOCTOR_TYPE, WEEKDAYS } from 'Constants/commonConstants';
import ActionLoader from '../../components/ActionLoader';
import RSelect, { components } from "react-select";
import { ArrowDropDownOutlined } from "@material-ui/icons";
import ImageSlider from '../../components/ImageSlider';

import {
    Clear
} from '@material-ui/icons';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component='div'
            role='tabpanel'
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

class AddDoctor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            tab: 1,
            data: {
                doctorId: '',
                isDocIdAutoGenerate: true,
                firstName: '',
                lastName: '',
                contactNumber: '',
                alternateContactNumber: '',
                emailId: '',
                qualification: '',
                specialization: '',
                experience: '',
                certificates: [],
                imageUrl: '',
                address1: '',
                address2: '',
                landmark: '',
                city: '',
                state: '',
                zipcode: '',
                latitude: '',
                longitude: '',
                doctorType: 0,
                accepts_vmer: 1 ? true : false,
                accepts_consultation: 1 ? true : false,
                username: '',
                password: '',
                languages_known: [],
                is_Active: 1 ? true : false,
                workingTime: [{
                    day: '',
                    working_hours_start: '',
                    working_hours_end: '',
                    working_hours_start1: '',
                    working_hours_end1: '',
                }],
                kyc_documents: [],
                working_status: ''
            },
            viewImages: [],
            doctorTypeNum: 0,
            startTime: "",
            endTime: "",
            startTime1: "",
            endTime1: "",
            langList: [
                { value: 'English', label: 'English' },
                { value: 'Tamil', label: 'Tamil' },
                { value: 'Hindi', label: 'Hindi' },
                { value: 'Telugu', label: 'Telugu' },
                { value: 'Marathi', label: 'Marathi' },
                { value: 'Malayalam', label: 'Malayalam' }
            ],
            selectedLang: {},
            zipcodeNum: '',
            isAddTime: false,
            updatedData:{

            }
        }
    }

    multiSelectStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "white",
            fontSize: 12,
        }),
        option: (base) => ({
            ...base,
            fontSize: 12,
        }),
        dropdownIndicator: (base) => ({
            ...base,
            color: "#9F9F9F",
        }),
        multiValueRemove: (base, state) => ({
            ...base,
            color: "#fff",
            backgroundColor: "#6FC5C4",
            borderRadius: 0,
            "&:hover": {
                backgroundColor: "#6FC5C4",
                color: "#fff",
            },
        }),
    };

    UNSAFE_componentWillMount() {
        const { match, location } = this.props;
        let doctorData = location.state;
        if (match.params && match.params.id) {
            this.getAllDoctors(match.params.id);
        }
        this.getAllConstants();
        this.getAllStates();
    }

    getAllDoctors = (id) => {
        const { dispatch } = this.props;
        let data = {
            doctorId: id,
        };

        dispatch({
            type: GET_ALL_DOCTORS,
            data,
            onSuccess: (doctorData) => {
                const time = doctorData.workingTime;
                this.setState({
                    data: {
                        ...this.state.data,
                        ...doctorData
                    },
                    updatedData:{
                        ...doctorData
                    }
                })
                {
                    time.map((item) => {
                        this.setState({
                            startTime: item.working_hours_start,
                            endTime:item.working_hours_end,
                            startTime1: item.working_hours_start1,
                            endTime1: item.working_hours_end1
                        })
                    })
                }
            }
        })
        this.languageList();

    };

    languageList = () => {
        const { data } = this.state;
        for (let i = 0; i < data.languages_known.length; i++) {
            this.setState({ selectedLang: { ...{ value: data.languages_known[i], label: data.languages_known[i] } } })
        }
    }

    getAllConstants = () => {
        const { dispatch } = this.props;
        dispatch({
            type: GET_ALL_CONSTANTS,
        });
    };

    getAllStates = () => {
        const { dispatch } = this.props;
        dispatch({
            type: GET_ALL_STATES,
        });
    };

    generateLatLong = () => {
        const { data } = this.state;
        const address = `${data.address1} ${data.address2 || ''} ${data.city} ${data.state
            } ${data.zipcode}`;
        Geocode.fromAddress(address).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                data.latitude = lat;
                data.longitude = lng;
                this.setState({ data });
            },
            (error) => {
                console.error(error);
            }
        );
    };


    handleFormChange = (e, key) => {
        const { data, updatedData } = this.state;
        if (
            key === 'is_Active' ||
            key === 'accepts_vmer' ||
            key === 'accepts_consultation'
        ) {
            data[key] = e.target.value !== '' ? parseInt(e.target.value) : '';
            updatedData[key] = e.target.value !== ''? parseInt(e.target.value) : '';
        } else {
            if (key === 'accepts_vmer' || key === 'accepts_consultation' || key === 'is_Active') {
                data[key] = !!parseInt(e.target.value);
            } else {
                data[key] = e.target.value;
                updatedData[key] = e.target.value;
            }
        }
        this.setState({ data, updatedData });
        if (key === 'bonus') {
            this.addGross();
        }
    };

    handleFormTimeChange = (e, key, index) => {
        const { data, updatedData } = this.state;
        if (data && (data.workingTime || []).length > 0) {
            data.workingTime[index][key] = e.target.value;
            this.setState({ data });
        }
        if(updatedData && (updatedData.workingTime || []).length > 0){
            updatedData.workingTime[index][key] = e.target.value;
            this.setState({updatedData});
        }
    };

    handleDateChange = (date, key) => {
        const { data , updatedData} = this.state;
        data[key] = date;
        updatedData[key] = date;
        this.setState({ data , updatedData});
    };

    uploadKycCert = (file, fields, isAdd) => {
        const { dispatch } = this.props;
        dispatch({
            type: UPLOAD_IMAGE,
            file,
            params: { type: 16 },
            onSuccess: (res) => {
                if (res && res.imageUrl) {
                    const { data , updatedData} = this.state;
                    data[fields] = [...data[fields], res.imageUrl];
                    updatedData[fields] = [...updatedData[fields] , res.imageUrl];
                    this.setState({ data, updatedData });
                }
            },
        });
    };

    uploadImage = (file, isAdd) => {
        const { dispatch } = this.props;
        dispatch({
            type: UPLOAD_IMAGE,
            file,
            params: { type: 16 },
            onSuccess: ((res) => {
                if (res && res.imageUrl) {
                    if (isAdd) {
                        const { data } = this.state;
                        data.imageUrl = res.imageUrl;
                        this.setState({ data });
                    } else {
                        this.setState({ kycUrl: res.imageUrl });
                    }
                }
            })
        })
    }


    removeUpload = (fields, src) => {
        const { data } = this.state;
        data[fields] = data[fields].filter((item) => item !== src);
        this.setState({ data });
    };

    removeLang = (lang, label) => {
        const { data } = this.state;
        data[lang] = data[lang].filter((item) => item !== label);
        this.setState({ data });
    };

    redirectToList = () => {
        const { match, history } = this.props;
        if (this.props.isView) {
            this.props.redirectToList();
        } else {
            history.push(`/doctors`);
        }
    };


    addDoctors = (dataSet) => {
        const { dispatch, match } = this.props;
        const data = dataSet ? dataSet : this.state.data;
        if (data.doctorId === '' || this.props.errorMessage) {
            data['isEdit'] = false;
            delete data.doctorId;
        } else {
            data['isEdit'] = true;
        }
        if (
            data &&
            data.servicing_zipcodes &&
            typeof data.servicing_zipcodes === 'string'
        ) {
            data.servicing_zipcodes = (data.servicing_zipcodes || '').split(',');
        }

        dispatch({
            type: ADD_DOCTORS,
            data: { ...data, languages_known: this.state.langSelected },
            onSuccess: (result) => {
                if (result.doctorId) {
                    const { data } = this.state;
                    data['doctorId'] = result.doctorId;
                    this.setState({ data });
                }
                this.redirectToList();
            },
        })
        if (this.props.errorMessage) {
            this.setState({ ...data, isEdit: false, });
        }
    };

    updateDoctors = () => {
        const {updatedData} = this.state;
        const { dispatch, match, errorMessage, doctor } = this.props;
        const data = updatedData;

        dispatch({
            type: UPDATE_DOCTORS,
            data,
           
        })
       
    };


    validateData = (isEdit = false) => {
        const { data, tab } = this.state;
        const { dispatch } = this.props;
        if (
            (data.firstName === '' ||
                data.lastName === '' ||
                data.mobileNumber === '' ||
                data.username === '' ||
                data.emailId === '' ||
                data.zipcode === '' ||
                data.city === '' ||
                data.state === '' ||
                data.experience === ''
            ) &&
            tab === 1
        ) {
            this.setState({ isError: true });
            dispatch({
                type: TOAST_ERROR,
                message: 'Please enter the details',
            });
        } else if (data.doctorId) {
            this.setState({ isError: false, tab: 2, data: data });

        } else {
            this.setState({ isError: false, tab: 2, data: data });
        }
    };

    validateTimeData = () => {
        const { data, tab, startTime, endTime, startTime1, endTime1 } = this.state;
        const { dispatch, history, doctor } = this.props;
        const time = data.workingTime;
        const replaceStart = startTime.replace(/:/g, ".");
        const replaceEnd = endTime.replace(/:/g, ".");
        const replaceStart1 = startTime1.replace(/:/g, ".");
        const replaceEnd1 = endTime1.replace(/:/g, ".");
        const startInt = parseFloat(replaceStart);
        const endInt = parseFloat(replaceEnd);
        const startInt1 = parseFloat(replaceStart1);
        const endInt1 = parseFloat(replaceEnd1);

        if (
            (data.firstName === '' ||
                data.lastName === '' ||
                data.mobileNumber === '' ||
                data.username === '' ||
                data.emailId === '' ||
                data.zipcode === '' ||
                data.city === '' ||
                data.state === ''
            ) &&
            tab === 2
        ) {
            this.setState({ isError: true });
            dispatch({
                type: TOAST_ERROR,
                message: 'Please enter the details',
            });
            this.setState({ isError: true });
        } else if ((
            startInt >= endInt || startInt >= startInt1 || startInt >= endInt1 || endInt >= startInt1 || endInt >= endInt1 || startInt1 >= endInt1
        ) && tab === 2) {
            this.setState({ isError: true });
            dispatch({
                type: TOAST_ERROR,
                message: 'Please select valid time',
            });
            this.setState({ isError: true });
        } else if (data.doctorId) {
            this.updateDoctors(doctor);
            this.redirectToList();

        } else {
            this.setState({ isError: false });
            this.addDoctors();
        }


    }

    validateAddTimeData = () => {
        const { data, tab, startTime, endTime, startTime1, endTime1 } = this.state;
        const { dispatch, history } = this.props;
        const time = data.workingTime;
        const replaceStart = startTime.replace(/:/g, ".");
        const replaceEnd = endTime.replace(/:/g, ".");
        const replaceStart1 = startTime1.replace(/:/g, ".");
        const replaceEnd1 = endTime1.replace(/:/g, ".");
        const startInt = parseFloat(replaceStart);
        const endInt = parseFloat(replaceEnd);
        const startInt1 = parseFloat(replaceStart1);
        const endInt1 = parseFloat(replaceEnd1);
        if (startInt >= endInt || startInt >= startInt1 || startInt >= endInt1 || endInt >= startInt1 || endInt >= endInt1 || startInt1 >= endInt1) {
            this.setState({ isError: true });
            dispatch({
                type: TOAST_ERROR,
                message: 'Please select valid time',
            });
            this.setState({ isError: true });
        } else {
            (data.workingTime || []).push({
                day: "",
                working_hours_start: "",
                working_hours_end: "",
                working_hours_start1: "",
                working_hours_end1: "",
                isAdd: true,
            });
            this.setState({ data })
        }


    }

    handleClick = () => {
        console.info('You clicked the Chip.');
    };

    handleDelete = () => {
        console.info('You clicked the delete icon.');
    };

    renderFormBasic = (data) => {
        const { isError, langSelected, zipcodeNum } = this.state;
       

        const DropdownIndicator = (props) => {
            return (
                <components.DropdownIndicator {...props}>
                    <ArrowDropDownOutlined />
                </components.DropdownIndicator>
            );
        };

        return (
            <React.Fragment>
                <Grid container spacing={3} style={{ marginTop: 5 }}>
                    <Grid item xs={6}>
                        <h2 className='mt-0'>
                            <FormLabel component='legend' className='text-label'>
                                First Name<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                            </FormLabel>
                            <TextField
                                fullWidth
                                variant='outlined'
                                value={data.firstName}
                                onChange={(e) => this.handleFormChange(e, 'firstName')}
                                error={isError && data.firstName === ''}
                                helperText={
                                    isError && data.firstName === ''
                                        ? 'Please enter a first name'
                                        : ' '
                                }
                            />
                        </h2>
                    </Grid>
                    <Grid item xs={6}>
                        <h2 className='mt-0'>
                            <FormLabel component='legend' className='text-label'>
                                Last Name<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                            </FormLabel>
                            <TextField
                                required
                                fullWidth
                                variant='outlined'
                                value={data.lastName}
                                onChange={(e) => this.handleFormChange(e, 'lastName')}
                                error={isError && data.lastName === ''}
                                helperText={
                                    isError && data.lastName === ''
                                        ? 'Please enter a last name'
                                        : ' '
                                }
                            />
                        </h2>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <h2 className='mt-0'>
                            <FormLabel component='legend' className='text-label'>
                                Phone Number<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                            </FormLabel>
                            <TextField
                                fullWidth
                                variant='outlined'
                                value={data.contactNumber}
                                error={isError && data.contactNumber === ''}
                                helperText={
                                    isError && data.contactNumber === ''
                                        ? 'Please enter phone no.'
                                        : ' '
                                }
                                onChange={(e) => this.handleFormChange(e, 'contactNumber')}
                            />
                        </h2>
                    </Grid>
                    <Grid item xs={6}>
                        <h2 className='mt-0'>
                            <FormLabel component='legend' className='text-label'>
                                Alternate Number
                            </FormLabel>
                            <TextField
                                fullWidth
                                variant='outlined'
                                value={data.alternateMobileNumber}
                                onChange={(e) =>
                                    this.handleFormChange(e, 'alternateMobileNumber')
                                }
                            />
                        </h2>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <h2 className='mt-0'>
                            <FormLabel component='legend' className='text-label'>
                                Qualification<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                            </FormLabel>
                            <TextField
                                fullWidth
                                variant='outlined'
                                value={data.qualification}
                                error={isError && data.qualification === ''}
                                helperText={
                                    isError && data.qualification === ''
                                        ? 'Please enter Qualification'
                                        : ' '
                                }
                                onChange={(e) => this.handleFormChange(e, 'qualification')}
                            />
                        </h2>
                    </Grid>
                    <Grid item xs={6}>
                        <h2 className='mt-0'>
                            <FormLabel component='legend' className='text-label'>
                                Specialization<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                            </FormLabel>
                            <TextField
                                fullWidth
                                variant='outlined'
                                value={data.specialization}
                                error={isError && data.specialization === ''}
                                helperText={
                                    isError && data.specialization === ''
                                        ? 'Please enter specialization.'
                                        : ' '
                                }
                                onChange={(e) => this.handleFormChange(e, 'specialization')}
                            />
                        </h2>
                    </Grid>
                    <Grid item xs={6}>
                        <h2 className='mt-0 boxed-select'>
                            <FormLabel component='legend' className='text-label'>
                                Doctor Type<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                            </FormLabel>
                            <Select
                                fullWidth
                                value={data.doctorType || ""}
                                error={isError && data.state === ''}
                                onChange={(e) => {
                                    this.handleFormChange(e, "doctorType");
                                    this.setState({ doctorTypeNum: e.target.value })
                                }}
                            >
                                {(DOCTOR_TYPE || []).map((status, index) => (
                                    <option key={`mode-${status.id}`} value={status.id}>
                                        {" "}
                                        {status.name}{" "}
                                    </option>
                                ))}
                            </Select>
                            <p style={{ fontSize: '10px', color: 'red' }}>{isError && data.state === '' && 'Please Select Doctor Type.'}</p>

                        </h2>
                    </Grid>
                    {this.state.doctorTypeNum === 3 ? <Grid item xs={6}>
                        <h2 className='mt-0'>
                            <FormLabel component='legend' className='text-label'>
                                Vendor Name
                            </FormLabel>
                            <TextField
                                fullWidth
                                variant='outlined'
                                value={data.vendorName}
                                onChange={(e) => this.handleFormChange(e, 'vendorName')}
                            />
                        </h2>
                    </Grid> : null}
                    <Grid item xs={6}>
                        <h2 className='mt-0'>
                            <FormLabel component='legend' className='text-label'>
                                Years of Experience<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                            </FormLabel>
                            <TextField
                                fullWidth
                                variant='outlined'
                                value={data.experience}
                                onChange={(e) => this.handleFormChange(e, 'experience')}
                            />
                        </h2>
                    </Grid>

                </Grid>
                <Grid container spacing={3}>

                    <Grid item xs={3}>
                        <h2 className='mt-0 boxed-select'>
                            <FormLabel component='legend' className='text-label'>
                                Working Status<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                            </FormLabel>
                            <Select
                                style={{ width: 100 }}
                                value={data.working_status || ''}
                                onChange={(e) => {
                                    this.handleFormChange(e, "working_status");
                                }}
                                error={isError && data.working_status === ''}
                            >
                                <MenuItem value=''>
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={'Working'}>Working</MenuItem>
                                <MenuItem value={'Resigned'}>Resigned</MenuItem>
                                <MenuItem value={'Terminated'}>Terminated</MenuItem>
                                <MenuItem value={'Abscond'}>Abscond</MenuItem>
                                <MenuItem value={'On-Hold'}>On-Hold</MenuItem>
                            </Select>
                            <p style={{ fontSize: '10px', color: 'red' }}>{isError && data.working_status === '' && 'Please select working status.'}</p>

                        </h2>
                    </Grid>

                    <Grid item xs={3}>
                        <h2 className='mt-0 mb-0'>
                            <FormLabel component='legend' className='text-label'>
                                Is Active?
                            </FormLabel>
                        </h2>
                        <RadioGroup
                            row
                            aria-label='position'
                            name='position'
                            defaultValue={1}
                            value={data.is_Active === true ? 1 : 0}
                            error={isError && data.is_Active === ''}
                            onChange={(e) => this.handleFormChange(e, 'is_Active')}
                        >
                            <FormControlLabel
                                value={1}
                                control={<Radio color='primary' />}
                                label='Yes'
                            />
                            <FormControlLabel
                                value={0}
                                control={<Radio color='primary' />}
                                label='No'
                            />
                        </RadioGroup>
                        <p style={{ fontSize: '10px', color: 'red' }}>{isError && data.is_Active === '' && 'Please select working status.'}</p>
                    </Grid>

                    <Grid item xs={3}>
                        <h2 className='mt-0 mb-0'>
                            <FormLabel component='legend' className='text-label'>
                                Accept VMER?
                            </FormLabel>
                        </h2>
                        <RadioGroup
                            row
                            aria-label='position'
                            name='position'
                            defaultValue={0}
                            value={data.accepts_vmer === true ? 1 : 0}
                            onChange={(e) => this.handleFormChange(e, 'accepts_vmer')}
                        >
                            <FormControlLabel
                                value={1}
                                control={<Radio color='primary' />}
                                label='Yes'
                            />
                            <FormControlLabel
                                value={0}
                                control={<Radio color='primary' />}
                                label='No'
                            />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={3}>
                        <h2 className='mt-0 mb-0'>
                            <FormLabel component='legend' className='text-label'>
                                Accept Consultations?
                            </FormLabel>
                        </h2>
                        <RadioGroup
                            row
                            aria-label='position'
                            name='position'
                            defaultValue={0}
                            value={data.accepts_consultation === true ? 1 : 0}
                            onChange={(e) => this.handleFormChange(e, 'accepts_consultation')}
                        >
                            <FormControlLabel
                                value={1}
                                control={<Radio color='primary' />}
                                label='Yes'
                            />
                            <FormControlLabel
                                value={0}
                                control={<Radio color='primary' />}
                                label='No'
                            />
                        </RadioGroup>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>

                    <Grid container spacing={3} style={{ marginTop: 5, padding: '10px' }}>
                        <Grid item xs={6}>
                            <h2 className='mt-0'>
                                <FormLabel component='legend' className='text-label'>
                                    E-mail ID<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                                </FormLabel>
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    value={data.emailId}
                                    onChange={(e) => this.handleFormChange(e, 'emailId')}
                                    error={isError && data.mobileNumber === ''}
                                    helperText={
                                        isError && data.mobileNumber === ''
                                            ? 'Please enter Email Id.'
                                            : ' '
                                    }
                                />
                            </h2>
                        </Grid>
                        <Grid item xs={6}>
                            <h2 className='mt-0'>
                                <FormLabel component='legend' className='text-label'>
                                    Username<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                                </FormLabel>
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    value={data.username}
                                    onChange={(e) => this.handleFormChange(e, 'username')}
                                    error={isError && data.username === ''}
                                    helperText={
                                        isError && data.username === ''
                                            ? 'Please enter username'
                                            : ' '
                                    }
                                />
                            </h2>
                        </Grid>
                        <Grid item xs={6}>
                            <h2 className='mt-0'>
                                <FormLabel component='legend' className='text-label'>
                                    Password<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                                </FormLabel>

                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='password'
                                    value={data.password}
                                    onChange={(e) => this.handleFormChange(e, 'password')}
                                    error={isError && data.password === ''}
                                    helperText={
                                        isError && data.password === ''
                                            ? 'Please enter password'
                                            : ' '
                                    }
                                />
                            </h2>
                        </Grid>


                        <Grid item xs={3}>
                            <h2 className='mt-0'>
                                <FormLabel component='legend' className='text-label'>
                                    Latitude
                                </FormLabel>
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    value={data.latitude}
                                    onChange={(e) => this.handleFormChange(e, 'latitude')}
                                />
                            </h2>
                        </Grid>
                        <Grid item xs={3}>
                            <h2 className='mt-0'>
                                <FormLabel component='legend' className='text-label'>
                                    Longitude
                                </FormLabel>
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    value={data.longitude}
                                    onChange={(e) => this.handleFormChange(e, 'longitude')}
                                />
                            </h2>
                        </Grid>

                        <Grid item xs={6}>
                            <h2 className='mt-0'>
                                <FormLabel component='legend' className='text-label'>
                                    Address 1<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                                </FormLabel>
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    value={data.address1}
                                    onChange={(e) => this.handleFormChange(e, 'address1')}
                                    error={isError && data.address1 === ''}
                                    helperText={
                                        isError && data.address1 === '' ? 'Please enter address' : ' '
                                    }
                                />
                            </h2>
                        </Grid>
                        <Grid item xs={6}>
                            <h2 className='mt-0'>
                                <FormLabel component='legend' className='text-label'>
                                    Address 2
                                </FormLabel>
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    value={data.address2}
                                    onChange={(e) => this.handleFormChange(e, 'address2')}
                                />
                            </h2>
                        </Grid>
                        <Grid item xs={6}>
                            <h2 className='mt-0'>
                                <FormLabel component='legend' className='text-label'>
                                    Landmark
                                </FormLabel>
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    value={data.landmark}
                                    onChange={(e) => this.handleFormChange(e, 'landmark')}
                                />
                            </h2>
                        </Grid>
                        <Grid item xs={6}>
                            <h2 className='mt-0 boxed-select'>
                                <FormLabel component='legend' className='text-label'>
                                    City<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                                </FormLabel>
                                <Select
                                    fullWidth
                                    value={data.city}
                                    onChange={(e) => {
                                        this.handleFormChange(e, 'city');
                                    }}
                                    error={isError && data.city === ''}
                                >
                                    {(this.props.cities || []).map((city, index) => (
                                        <option key={`city-${index}`} value={city}>
                                            {' '}
                                            {city}{' '}
                                        </option>
                                    ))}

                                </Select>
                                <p style={{ fontSize: '10px', color: 'red' }}>{isError && data.city === '' && 'Please enter city.'}</p>

                            </h2>
                        </Grid>
                        <Grid item xs={6}>
                            <h2 className='mt-0 boxed-select'>
                                <FormLabel component='legend' className='text-label'>
                                    State<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                                </FormLabel>
                                <Select
                                    fullWidth
                                    value={data.state}
                                    onChange={(e) => {
                                        this.handleFormChange(e, 'state');
                                    }}
                                    error={isError && data.state === ''}
                                >
                                    {(this.props.states || []).map((state, index) => (
                                        <option key={`state-${state.id}`} value={state.name}>
                                            {' '}
                                            {state.name}{' '}
                                        </option>
                                    ))}
                                </Select>
                                <p style={{ fontSize: '10px', color: 'red' }}>{isError && data.state === '' && 'Please select state.'}</p>
                            </h2>
                        </Grid>
                        <Grid item xs={6}>
                            <h2 className='mt-0'>
                                <FormLabel component='legend' className='text-label'>
                                    Zipcode<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                                </FormLabel>
                                <TextField
                                    fullWidth
                                    InputProps={{ inputProps: { minLength: 6, maxLength: 6 } }}
                                    variant='outlined'
                                    value={data.zipcode}
                                    onChange={(e) => this.handleFormChange(e, 'zipcode')}
                                    error={isError && data.zipcode === ''}
                                    helperText={
                                        isError && data.zipcode === '' ? 'Please enter zipcode' : ' '
                                    }
                                />
                            </h2>
                        </Grid>
                        <Grid item xs={6}>
                            <h2 className='mt-0'>
                                <FormLabel component='legend' className='text-label'>
                                    Languages Known<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
                                </FormLabel>

                                {data.languages_known && data.languages_known.map((list) => (
                                    <Chip
                                        style={{ width: '120px' }}
                                        label={list}
                                        onDelete={this.handleDelete()}
                                        icon={<IconButton style={{ width: '30px', height: '30px' }} onClick={(e) => {
                                            this.removeLang('languages_known', list);
                                        }}><Clear /></IconButton>}
                                    />
                                ))}

                                <RSelect
                                    style={{ marginTop: '1em', width: "80%" }}
                                    options={this.state.langList}
                                    selected={data.languages_known}
                                    components={{
                                        IndicatorSeparator: () => null,
                                        DropdownIndicator,
                                    }}
                                    isClearable={false}
                                   isMulti
                                    onChange={(val) => {
                                        this.setState({ langSelected: val.map((a) => a.value) })

                                    }}
                                   
                                    styles={this.multiSelectStyles}
                                />
                            </h2>
                        </Grid>
                        <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div className='file-upload'>
                                Add Certificates
                                <input
                                    style={{ fontSize: 'unset', margin: 10 }}
                                    type='file'
                                    name='certificates'
                                    accept='image/*'
                                    className='input_file'
                                    onChange={(e) => {
                                        this.uploadKycCert(e.target.files, 'certificates', 1);
                                    }}
                                />
                            </div>
                            {data.certificates &&
                                data.certificates.map((el, index) => (
                                    <Grid
                                        item
                                        style={{
                                            width: 100,
                                            position: 'relative',
                                            marginTop: 15,
                                        }}
                                        xs={6}
                                    >
                                        <Avatar
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => this.setState({ viewImages: [el] })}
                                            src={el}
                                            size='small'
                                        />{' '}
                                        <ImageSlider images={this.state.viewImages} />
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: 45,
                                                top: 0,
                                                cursor: 'pointer',
                                            }}
                                            onClick={(e) => {
                                                this.removeUpload('certificates', el);
                                            }}
                                        >
                                            X
                                        </div>
                                    </Grid>
                                ))}
                        </Grid>
                        <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div className='file-upload'>
                                Add KYC Documents
                                <input
                                    style={{ fontSize: 'unset', margin: 10 }}
                                    type='file'
                                    name='kyc_documents'
                                    accept='image/*'
                                    className='input_file'
                                    onChange={(e) => {
                                        this.uploadKycCert(e.target.files, 'kyc_documents', 1);
                                    }}
                                />
                            </div>
                            {data.kyc_documents &&
                                data.kyc_documents.map((el, index) => (
                                    <Grid
                                        item
                                        style={{
                                            width: 100,
                                            position: 'relative',
                                            marginTop: 15,
                                        }}
                                        xs={6}
                                    >
                                        <Avatar
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => this.setState({ viewImages: [el] })}
                                            src={el}
                                            size='small'
                                        />{' '}
                                        <ImageSlider images={this.state.viewImages} />
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: 45,
                                                top: 0,
                                                cursor: 'pointer',
                                            }}
                                            onClick={(e) => {
                                                this.removeUpload('kyc_documents', el);
                                            }}
                                        >
                                            X
                                        </div>
                                    </Grid>
                                ))}
                        </Grid>
                        <Grid item xs={6}>
                            <FormLabel>Profile Picture</FormLabel>
                            <input type="file"
                                className="inputFileUpload"
                                name='imageUrl'
                                onChange={(e) => { this.uploadImage(e.target.files, 'imageUrl', 1) }}
                                ref={(ref) => { this.input = ref; }}
                            />
                            {
                                data.imageUrl && data.imageUrl.length > 0 &&
                                <a href={data.imageUrl} style={{ color: 'blue', fontSize: '10px' }}>
                                    <Avatar size="small" src={data.imageUrl} alt="icon" />
                                </a>
                            }
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} style={{ marginTop: 5, padding: '10px' }}>

                    </Grid>


                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <Button
                            style={{ backgroundColor: '#0D8DA3', marginRight: 10 }}
                            onClick={() => this.validateData(data.isEdit)}
                            variant='contained'
                            color='primary'
                        >
                            Save & Next
                        </Button>
                        <Button
                            style={{
                                borderColor: '#0D8DA3',
                                color: '#0D8DA3',
                                marginRight: 10,
                            }}
                            onClick={() => {
                                this.redirectToList();
                            }}
                            variant='outlined'
                            color='primary'
                        >
                            Back
                        </Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    renderFormTiming = (data) => {
        return (
            <React.Fragment>
                {(data.workingTime).map((time, index) => (
                    <Grid container spacing={1} key={`time-${index + 1}`}>
                        <Grid item xs={2}>
                            <FormLabel>Day</FormLabel>
                            <Select
                                fullWidth
                                value={time.day}
                                onChange={(e) => {
                                    this.handleFormTimeChange(e, "day", index);
                                }}
                            >
                                {(WEEKDAYS || []).map((status, index) => (
                                    <option key={`mode-${status.id}`} value={status.name}>
                                        {" "}
                                        {status.name}{" "}
                                    </option>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={2}>
                            <FormLabel>From</FormLabel><br />
                            <TextField
                                fullWidth
                                type='time'
                                variant='outlined'
                                value={time.working_hours_start}
                                onChange={(e) => {
                                    this.handleFormTimeChange(e, "working_hours_start", index);
                                    this.setState({ startTime: time.working_hours_start ? time.working_hours_start : e.target.value })
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormLabel> To</FormLabel><br />
                            <TextField
                                fullWidth
                                type='time'
                                variant='outlined'
                                value={time.working_hours_end}
                                onChange={(e) => {
                                    this.handleFormTimeChange(e, "working_hours_end", index);
                                    this.setState({ endTime: time.working_hours_end ? time.working_hours_end : e.target.value })
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormLabel> From</FormLabel><br />
                            <TextField
                                fullWidth
                                type='time'
                                variant='outlined'
                                value={time.working_hours_start1}
                                onChange={(e) => {
                                    this.handleFormTimeChange(e, "working_hours_start1", index);
                                    this.setState({ startTime1: e.target.value })
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormLabel> To</FormLabel><br />
                            <TextField
                                fullWidth
                                type='time'
                                variant='outlined'
                                value={time.working_hours_end1}
                                onChange={(e) => {
                                    this.handleFormTimeChange(e, "working_hours_end1", index);
                                    this.setState({ endTime1: e.target.value })
                                }}
                            />
                        </Grid>
                        <Grid item xs={2} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', marginTop: '2em' }}>
                            {data.workingTime.length === 1 ? null : <IconButton
                                edge="end"
                                color="inherit"
                                style={{ width: 20 }}
                                size="small"
                                onClick={() => {
                                    const { data } = this.state;
                                    (data.workingTime || []).splice(index, 1);
                                    this.setState({ data });
                                }}
                            >
                                <Clear />
                            </IconButton>}
                        </Grid>

                    </Grid>

                ))}

                {data.workingTime.length === 7 ? null : <Grid item xs={12}>
                    <Button
                        style={{ marginRight: 10, marginTop: 10 }}
                        size="small"
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            this.validateAddTimeData();
                            const { data } = this.state;
                            if (!data.workingTime) {
                                data.workingTime = [];
                            }
                        

                            this.setState({ data })

                        }}
                    >
                        Add
                    </Button>
                </Grid>}

                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <Button
                            style={{ backgroundColor: '#0D8DA3', marginRight: 10 }}
                            onClick={(e) => this.validateTimeData()}
                            variant='contained'
                            color='primary'
                        >
                            Save
                        </Button>
                        <Button
                            style={{
                                borderColor: '#0D8DA3',
                                color: '#0D8DA3',
                                marginRight: 10,
                            }}
                            onClick={() => {
                                this.redirectToList();
                            }}
                            variant='outlined'
                            color='primary'
                        >
                            Back
                        </Button>
                    </Grid>
                </Grid>

            </React.Fragment>
        )

    };

    renderFormFields = (data) => {
        const { isError } = this.state;
        const { lgps } = this.props;
        return (
            <React.Fragment>
                <Tabs
                    value={this.state.tab}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: 'white',
                        },
                    }}
                    textColor='primary'
                    onChange={(e, tab) => {
                        this.setState({ tab });
                    }}
                >
                    <Tab label='Basic' value={1} />
                    <Tab label='Timing' value={2} onClick={() => {
                        const { data } = this.state;
                        if (!data.workingTime) {
                            data.workingTime = [];
                        }
                    }} />
                </Tabs>
                <TabPanel
                    value={this.state.tab}
                    index={1}
                    style={{ border: '2px solid #0D8DA3' }}
                >
                    {this.renderFormBasic(data)}
                </TabPanel>
                <TabPanel
                    value={this.state.tab}
                    index={2}
                    style={{ border: '2px solid #0D8DA3' }}
                >
                    {this.renderFormTiming(data)}
                </TabPanel>

            </React.Fragment>
        );
    };

    render() {
        const { isLoading, plebos } = this.props;
        const { data } = this.state;
        const { isView } = this.props;
        return (
            <>
                {isView ? (

                    <div className='mr-20 create-form'>
                        {isLoading && <ActionLoader />}
                        <Button
                            style={{
                                borderColor: '#0D8DA3',
                                color: '#0D8DA3',
                                marginRight: 10,
                            }}
                            onClick={() => {
                                this.redirectToList();
                            }}
                            variant='outlined'
                            color='primary'
                        >
                            Back
                        </Button>
                        <h3>{data.doctorId === '' ? 'Add Doctor' : 'Edit Doctor'}</h3>

                        {this.renderFormFields(data)}
                    </div>
                ) : (
                    <DocContainer>
                        <div className='mr-20 create-form'>
                            {isLoading && <ActionLoader />}
                            <Button
                                style={{
                                    borderColor: '#0D8DA3',
                                    color: '#0D8DA3',
                                    marginRight: 10,
                                }}
                                onClick={() => {
                                    this.redirectToList();
                                }}
                                variant='outlined'
                                color='primary'
                            >
                                Back
                            </Button>
                            <h3>Add Doctor</h3>

                            {this.renderFormFields(data)}
                        </div>
                    </DocContainer>
                )}
            </>
        );
    }
}

const mapStateToProps = ({ plebos, constants, lgp }) => {
    return {
        plebos: plebos.plebos,
        isLoading: plebos.isSubmitting,
        cities: constants.cities,
        states: constants.states,
        salaries: constants.salaries,
    };
};

export default withRouter(connect(mapStateToProps)(AddDoctor));