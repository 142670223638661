import { 
  USERS_LIST,

  IS_SUBMITTING,
} from 'Constants/actionConstants';


const users = (state={
  
  isSubmitting: false,
  users: [],

}, action)=> {

  switch (action.type) {
    
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };

    case USERS_LIST:
      return {
        ...state,
        users: action.data,
        hasMore: action.hasMore,
      };

    default:
      return state;
  }

}

export default users;
