import { call, put } from "redux-saga/effects";

import {
  postDataWithToken,
  getDataWithToken,
  deleteDataWithToken,
  putDataWithToken,
  GET_ALL_ROLES_URL,
  GET_ALL_ROLES_BY_ID_URL,
  ADD_ROLES_URL,
  EDIT_ROLES_URL,
  DEL_ROLES_URL,
} from "Utils/api";

import {
  IS_SUBMITTING,
  ROLES_LIST,
  TOAST_ERROR,
  TOAST_SUCCESS,
} from "Constants/actionConstants";

export function* getRoles(action) {
  try {
    const url =
      action.data && action.data.id
        ? GET_ALL_ROLES_BY_ID_URL
        : GET_ALL_ROLES_URL;
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(getDataWithToken, url, action.data);

    yield put({
      type: ROLES_LIST,
      data: data && data.list ? data.list : [],
      hasMore:
        data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false,
    });

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* addRoles(action) {
  try {
    const isEdit = action.data && action.data.isEdit;
    const url = isEdit ? EDIT_ROLES_URL : ADD_ROLES_URL;
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(
      isEdit ? putDataWithToken : postDataWithToken,
      url,
      action.data
    );
    yield put({ type: IS_SUBMITTING, isSubmitting: false });

    yield put({
      type: TOAST_SUCCESS,
      message:
        data && data.message ? data.message : "Data updated successfully!",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* deleteRoles(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(deleteDataWithToken, DEL_ROLES_URL, action.data);

    yield put({
      type: TOAST_SUCCESS,
      message:
        data && data.message ? data.message : "Data updated successfully!",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}
