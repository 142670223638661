import {
  	IS_SUBMITTING,

  	UPLOADED_IMG,
  	ALL_CONSTANTS,
    ALL_STATES,
} from 'Constants/actionConstants';


const constants = (state={
  
  isSubmitting: false,
  link: '',
  cities: [],
  states: [],
  salaries: {}
}, action)=> {

  switch (action.type) {
    
    case IS_SUBMITTING:
      	return {
	        ...state,
	        isSubmitting: action.isSubmitting,
	    };

    case UPLOADED_IMG:
    	return {
    		...state,
    		link: action.data,
    	};

    case ALL_CONSTANTS:
      return {
        ...state,
        cities: action.data.cities,
        salaries: action.data.salaries
      };

    case ALL_STATES:
      return {
        ...state,
        states: action.data
      };

    default:
      return state;
  }

}

export default constants;
