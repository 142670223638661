import React, { useEffect, useState } from 'react';
import {
    Grid, OutlinedInput,
    FormControl,
    FormHelperText,
    Card,
    Typography,
    InputAdornment,
    Button,
    Select,
    MenuItem,
} from '@material-ui/core';
import { Dialog, DialogContent, DialogContentText,  DialogActions } from "@material-ui/core";
import BloodPressure from "./VitalsIcons/blood-pressure.png";
import Pulse from "./VitalsIcons/pulse.png";
import Height from "./VitalsIcons/height.png";
import Weight from "./VitalsIcons/weight-scale.png";
import Abdomen from "./VitalsIcons/stomach.png";
import Heart from "./VitalsIcons/heart.png";
import Waist from "./VitalsIcons/waist.png";
import { useForm, Controller } from "react-hook-form";
import { CREATE_VMER_VITALS } from 'Constants/actionConstants';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { storePhleboToken } from 'Utils/storage';
import Toast from 'Components/Toast/index';

const VitalsForm = (props) => {
    const [openSuccess, setOpenSuccess]= useState(false);

    const { register, control, handleSubmit, formState: { errors } } = useForm();

    useEffect(() => {
        const { match } = props;
        const phleboToken = match.params.token;
        storePhleboToken(phleboToken);

    }, [])

    const handleVitals = (data) => {
        const hipWaistRatio = data.waist/data.hip;
        const rounded = hipWaistRatio.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
        const { dispatch } = props;
        const { match } = props;
        const orderId = match.params.orderId;

        dispatch({
            type: CREATE_VMER_VITALS,
            data: { ...data, hipWaistRatio: rounded, orderId: orderId },
            onSuccess: (res) => {
                setOpenSuccess(true)
                Toast.success('Vitals Submitted Successfully')
              },
        })
    }
    
    const handleClose = () => {
        setOpenSuccess(false);
    }


    return (
        <React.Fragment>
              <Card className="p-5">
                <Grid item xs={12} >
                    <Typography variant='h6'>Please enter patients vitals</Typography>
                    <form onSubmit={handleSubmit(handleVitals)}>
                        <FormControl fullWidth >
                            <FormHelperText id="standard-weight-helper-text">Blood Pressure(Reading-1)</FormHelperText>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <OutlinedInput
                                        required
                                        id="standard-adornment-weight"
                                        {...register("bp1_s")}
                                        startAdornment={<InputAdornment position="start"><img src={BloodPressure} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                        endAdornment={<InputAdornment position="end">mmHg</InputAdornment>}
                                        aria-describedby="standard-weight-helper-text"
                                        variant="outlined"
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <OutlinedInput
                                        required
                                        id="standard-adornment-weight"
                                        {...register("bp1_d")}
                                        startAdornment={<InputAdornment position="start"><img src={BloodPressure} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                        endAdornment={<InputAdornment position="end">mmHg</InputAdornment>}
                                        aria-describedby="standard-weight-helper-text"
                                        variant="outlined"
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />

                                </Grid>
                            </Grid>
                        </FormControl>
                        <FormControl fullWidth >
                            <FormHelperText id="standard-weight-helper-text">Blood Pressure(Reading-2)</FormHelperText>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <OutlinedInput
                                        required
                                        id="standard-adornment-weight"
                                        {...register("bp2_s")}
                                        startAdornment={<InputAdornment position="start"><img src={BloodPressure} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                        endAdornment={<InputAdornment position="end">mmHg</InputAdornment>}
                                        aria-describedby="standard-weight-helper-text"
                                        variant="outlined"
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <OutlinedInput
                                        required
                                        id="standard-adornment-weight"
                                        {...register("bp2_d")}
                                        startAdornment={<InputAdornment position="start"><img src={BloodPressure} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                        endAdornment={<InputAdornment position="end">mmHg</InputAdornment>}
                                        aria-describedby="standard-weight-helper-text"
                                        variant="outlined"
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />
                                </Grid>
                            </Grid>

                        </FormControl>
                        <FormControl fullWidth >
                            <FormHelperText id="standard-weight-helper-text">Blood Pressure(Reading-3)</FormHelperText>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <OutlinedInput
                                        required
                                        id="standard-adornment-weight"
                                        {...register("bp3_s")}
                                        startAdornment={<InputAdornment position="start"><img src={BloodPressure} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                        endAdornment={<InputAdornment position="end">mmHg</InputAdornment>}
                                        aria-describedby="standard-weight-helper-text"
                                        variant="outlined"
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <OutlinedInput
                                        required
                                        id="standard-adornment-weight"
                                        {...register("bp3_d")}
                                        startAdornment={<InputAdornment position="start"><img src={BloodPressure} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                        endAdornment={<InputAdornment position="end">mmHg</InputAdornment>}
                                        aria-describedby="standard-weight-helper-text"
                                        variant="outlined"
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />
                                </Grid>
                            </Grid>

                        </FormControl>
                        <FormControl fullWidth >
                            <FormHelperText id="standard-weight-helper-text">Height</FormHelperText>
                            <OutlinedInput
                                required
                                id="standard-adornment-weight"
                                {...register("height")}
                                startAdornment={<InputAdornment position="start"><img src={Height} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                                aria-describedby="standard-weight-helper-text"
                                variant="outlined"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth >
                            <FormHelperText id="standard-weight-helper-text">Weight</FormHelperText>
                            <OutlinedInput
                                required
                                id="standard-adornment-weight"
                                {...register("weight")}
                                startAdornment={<InputAdornment position="start"><img src={Weight} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                endAdornment={<InputAdornment position="end">kgs</InputAdornment>}
                                aria-describedby="standard-weight-helper-text"
                                variant="outlined"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth >
                            <FormHelperText id="standard-weight-helper-text">Chest Expiration</FormHelperText>
                            <OutlinedInput
                                required
                                id="standard-adornment-weight"
                                {...register("chestExpiration")}
                                startAdornment={<InputAdornment position="start"><img src={Heart} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                                aria-describedby="standard-weight-helper-text"
                                variant="outlined"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                            />
                        </FormControl>

                        <FormControl fullWidth >
                            <FormHelperText id="standard-weight-helper-text">Chest Inspiration</FormHelperText>
                            <OutlinedInput
                                required
                                id="standard-adornment-weight"
                                {...register("chestInspiration")}
                                startAdornment={<InputAdornment position="start"><img src={Heart} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                                aria-describedby="standard-weight-helper-text"
                                variant="outlined"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                            />

                        </FormControl>
                        <FormControl fullWidth  >
                            <FormHelperText id="standard-weight-helper-text">Abdomen Girth</FormHelperText>
                            <OutlinedInput
                                required
                                id="standard-adornment-weight"
                                {...register("abdomenGirth")}
                                startAdornment={<InputAdornment position="start"><img src={Abdomen} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                                aria-describedby="standard-weight-helper-text"
                                variant="outlined"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth  >
                            <FormHelperText id="standard-weight-helper-text">Hip</FormHelperText>
                            <OutlinedInput
                                required
                                id="standard-adornment-weight"
                                {...register("hip")}
                                onChange={handleChangeRatio}
                                startAdornment={<InputAdornment position="start"><img src={Waist} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                                aria-describedby="standard-weight-helper-text"
                                variant="outlined"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth  >
                            <FormHelperText id="standard-weight-helper-text">Waist</FormHelperText>
                            <OutlinedInput
                                required
                                id="standard-adornment-weight"
                                {...register("waist")}
                                onChange={handleChangeRatio}
                                startAdornment={<InputAdornment position="start"><img src={Waist} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                                aria-describedby="standard-weight-helper-text"
                                variant="outlined"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth >
                            <FormHelperText id="standard-weight-helper-text">Pulse</FormHelperText>
                            <OutlinedInput
                                required
                                id="standard-adornment-weight"
                                {...register("pulse")}
                                startAdornment={<InputAdornment position="start"><img src={Pulse} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                endAdornment={<InputAdornment position="end">bpm</InputAdornment>}
                                aria-describedby="standard-weight-helper-text"
                                variant="outlined"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                            />
                        </FormControl>
                        <Controller
                            rules={{ required: true }}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <FormControl fullWidth>
                                    <FormHelperText>Fasting Status</FormHelperText>
                                    <Select
                                        id={'fastingStatus'}
                                        value={value}
                                        defaultValue={'NA'}
                                        onChange={onChange}>
                                        <MenuItem value={'NA'}>NA</MenuItem>
                                        <MenuItem value={'Fasting'}>Fasting</MenuItem>
                                        <MenuItem value={'Non Fasting'}>Non Fasting</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                            name='fastingStatus'
                            control={control}
                        />
                        <Grid item xs={12} style={{ marginTop: '1em' }}>
                            <Button type='submit' variant='contained' style={{ width: '100%', backgroundColor: '#0D8DA3', color: '#ffffff' }} >SAVE</Button>
                        </Grid>
                    </form>
                </Grid>


            </Card>
            <Dialog
        open={openSuccess}
        onClose={handleClose}
        maxWidth={'xs'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Vitals Saved Successfully
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant={'contained'}>Ok</Button>
        
        </DialogActions>
      </Dialog>
        </React.Fragment>
    )
}

const mapStateToProps = ({ constants, zones }) => {
    return {
        cities: constants.cities,
        zones: zones.zones,
    };
};

export default withRouter(connect(mapStateToProps)(VitalsForm));