import React, { useEffect, useState } from "react";
import {
  AgoraVideoPlayer,
  createClient,
  createMicrophoneAndCameraTracks,
} from "agora-rtc-react";

import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import CallEndIcon from "@material-ui/icons/CallEnd";
import { Button } from "@material-ui/core";
import "./vmer.css";

const config = {
  mode: "rtc",
  codec: "vp8",
};

const appId = "db030539178f4ec591667e10e7b6eb9b"; //ENTER APP ID HERE
const uid = "128801"

const PhleboVMer = (props) => {
  const {token} = props;
  const {cname} = props;
  const { channel = "" } = props;
  const [channelName, setChannelName] = useState(cname);
  const [inCall, setInCall] = useState(channel ? true : false);



  return (
    <>
      {inCall ? (
        <VideoCall
          setInCall={setInCall}
          channelName={channelName}
          token={token}
        />
      ) : (
        <ChannelForm setInCall={setInCall} setChannelName={setChannelName} />
      )}
    </>
  );
};

// the create methods in the wrapper return a hook
// the create method should be called outside the parent component
// this hook can be used the get the client/stream in any component
const useClient = createClient(config);
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

const VideoCall = (props) => {
  const { setInCall, channelName, setIsJoined, token } = props;
  const [users, setUsers] = useState([]);
  const [start, setStart] = useState(false);
  // using the hook to get access to the client object
  const client = useClient();
  // ready is a state variable, which returns true when the local tracks are initialized, untill then tracks variable is null
  const { ready, tracks } = useMicrophoneAndCameraTracks();

  useEffect(() => {
    // function to initialise the SDK
    let init = async (name) => {
      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        if (mediaType === "video") {
          setUsers((prevUsers) => {
            return [...prevUsers, user];
          });
        }
        if (mediaType === "audio") {
          if (user && user.audioTrack) {
            user.audioTrack.play();
          }
        }
      });

      client.on("user-unpublished", (user, type) => {

        if (type === "audio") {
          if (user && user.audioTrack) {
            user.audioTrack.stop();
          }
        }
        if (type === "video") {
          setUsers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== user.uid);
          });
        }

      });

      client.on("user-left", (user) => {
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });

      });

      await client.join(appId, name, token, uid);
      if (tracks) await client.publish([tracks[0], tracks[1]]);
      setStart(true);
    };

    if (ready && tracks) {
      init(channelName);
    }
  }, [channelName, client, ready, tracks]);

  return (
    <div>
      {start && tracks && (
        <Videos
          ready={ready}
          setStart={setStart}
          setInCall={setInCall}
          users={users}
          tracks={tracks}
          token={token}
        />
      )}
    </div>
  );
};

const Videos = (props) => {
  const { users, ready, tracks, setInCall, setStart, setIsJoined, token } = props;

  return (
    <div id="videos">
      {ready && tracks && (
        <Controls
          tracks={tracks}
          setStart={setStart}
          setInCall={setInCall}
          users={users}
          setIsJoined={setIsJoined}
          token={token}
        />
      )}
      {/* AgoraVideoPlayer component takes in the video track to render the stream,
            you can pass in other props that get passed to the rendered div */}
      <AgoraVideoPlayer
        className={users.length > 0 ? "vidSmall" : "vid"}
        videoTrack={tracks[1]}
      />
      {users.length > 0 &&
        users.map((user) => {
          if (user.videoTrack) {
            return (
              <AgoraVideoPlayer
                className="vid"
                videoTrack={user.videoTrack}
                key={user.uid}
              />
            );
          } else return null;
        })}
    </div>
  );
};

export const Controls = (props) => {
  const client = useClient();
  const { tracks, setStart, setInCall, users } = props;
  const [trackState, setTrackState] = useState({ video: true, audio: true });


  const mute = async (type) => {
    if (type === "audio") {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === "video") {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState((ps) => {
        return { ...ps, video: !ps.video };
      });
    }
  };

  const leaveChannel = async () => {
    await client.leave();
    client.removeAllListeners();
    // we close the tracks to perform cleanup
    tracks[0].close();
    tracks[1].close();
    setStart(false);
    setInCall(false);
  };

  return (
    <div className="controls">
      <p className={trackState.audio ? "on" : ""} onClick={() => mute("audio")}>
        {trackState.audio ? <MicIcon /> : <MicOffIcon />}
      </p>
      <p className={trackState.video ? "on" : ""} onClick={() => mute("video")}>
        {trackState.video ? <VideocamIcon /> : <VideocamOffIcon />}
      </p>
      <p onClick={() => leaveChannel()}>
        <CallEndIcon />
      </p>
    </div>
  );
};

const ChannelForm = (props) => {
  const { setInCall, setChannelName } = props;

  return (
    <form className="join">
      {appId === "" && (
        <p style={{ color: "red" }}>
          Please enter your Agora App ID in App.tsx and refresh the page
        </p>
      )}
      <Button
        onClick={(e) => {
          e.preventDefault();
          setInCall(true);
        }}
      >
        Join
      </Button>
    </form>
  );
};

export default PhleboVMer;
