/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useState } from "react";
import { connect } from "react-redux";
import MapContainer from "../../components/map/map3";
import Paper from "@material-ui/core/Paper";
import { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Select,
  MenuItem,
  Grid,
  InputLabel,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from "moment";
import { Edit, Add } from "@material-ui/icons";
import { TOAST_ERROR } from "../../constants/actionConstants";
import "./trip.css";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  // background: isDragging ? "rgb(227, 252, 239)" : "",

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "rgb(255, 235, 230)" : "",
  width: 85,
  padding: "0px !important",
  minHeight: 100,
});

const TripMap = (props) => {
  const {
    allReviewOrders = [],
    locations = [],
    allRouteTrips,
    dateFilter,
    updateOrderedData,
    dispatch,
    setOrderModalOpen,
    setReviewOrderDetails,
    createRoute,
    getOrderType,
    getBackgroundColor,
    getColor,
    dateTimeSlot,
  } = props;

  const classes = useStyles();
  const [latlng, setLatLng] = useState({});
  const [reivewOrders, setReviewOrders] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [selectedRoute, setSelectedRoute] = useState({
    routeId: "",
  });
  const [routes, setRoutes] = useState([]);
  const [routeModalOpen, setRouteModalOpen] = useState(false);
  const [routeDetails, setRouteDetails] = useState(null);
  const [zone, setZone] = useState(null);

  useEffect(() => {
    if (selectedRoute.routeId) {
      const stops = allRouteTrips.find(
        (el) => el.routeId === selectedRoute.routeId
      ).stops;
      setSelectedRoute({
        ...selectedRoute,
        routeStops: stops,
      });
    } else {
      setSelectedRoute({
        routeId: "",
      });
    }
  }, [selectedRoute.routeId]);

  useEffect(() => {
    setRoutes(getRoutes(allRouteTrips));
  }, [allRouteTrips]);

  const getRoutes = (routesTrips) => {
    const routesArray = [];
    routesTrips.map((el) => {
      routesArray.push({
        routeId: el.routeId,
        name: el.name,
        zoneName: el.zoneName,
      });
    });
    return routesArray;
  };

  useEffect(() => {
    setReviewOrders(allReviewOrders || []);
  }, [allReviewOrders]);

  const getDateTime = (earliest_pickup_date) => {
    const hr = new Date(earliest_pickup_date).getHours();
    let dt = "";
    if (hr >= 14) {
      dt = "14-18";
    } else {
      dt = `${hr}-${hr + 1}`;
    }
    return dt;
  };

  const getAllTrips = (stops, time) => {
    if (stops[time]) {
      return stops[time];
    }
    return [];
  };

  const onDragEnd = (result, list, routeId) => {
    const { source, destination, draggableId } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    const sSlot = source.droppableId;
    const dSlot = destination.droppableId;

    const sInd = source.index;
    const dInd = destination.index;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    let sourceData = JSON.parse(JSON.stringify(list[sSlot] ? list[sSlot] : []));
    let destinationData = JSON.parse(
      JSON.stringify(list[dSlot] ? list[dSlot] : [])
    );

    if (sSlot === dSlot) {
      const updateData = sourceData[sInd];
      const tempData = destinationData[dInd];
      destinationData[dInd] = updateData;
      destinationData[sInd] = tempData;
    } else {
      destinationData.splice(dInd, 0, orderDetails);
    }

    list[dSlot] = destinationData;

    const date = dSlot || "";
    let startSlot = "";
    let startEnd = "";
    if (date) {
      const slotArray = date.split("-");
      if (slotArray.length > 0) {
        startSlot = parseInt(
          moment(
            `${moment(dateFilter).format("YYYY-MM-DD")} ${slotArray[0]}:00:00`
          ).format("x")
        );

        const end = slotArray[1]
          ? slotArray[1] !== "+"
            ? slotArray[1] + ":00:00"
            : "18:00:00"
          : "";

        startEnd = parseInt(
          moment(`${moment(dateFilter).format("YYYY-MM-DD")} ${end}`).format(
            "x"
          )
        );
      }
    }

    const payload = {
      orderId: draggableId || "",
      routeId,
      earliest_pickup_date: startSlot,
      latest_pickup_date: startEnd,
      newSequence: formatOrderedData(list),
      isAssignInReviewOrder: true,
    };
    updateOrderedData(payload);
    setIsModalOpen(false);
    setSelectedRoute({
      routeId: "",
    });
  };

  const formatOrderedData = (data = {}) => {
    const list = [];
    dateTimeSlot.map((key) => {
      (data[key] || []).map((el) => {
        list.push(el.orderId);
      });
    });
    return list;
  };

  const formatReviewOrder = (order) => {
    return {
      orderSequence: order.order_seq,
      labName: order.bill_to_name,
      customerName: order.customer_name,
      customerContact: order.customer_contact,
      zoneName: order.zoneName,
      address1: order.ship_to_address1,
      zipcode: order.ship_to_zip_code,
      orderId: order.orderId,
      pickupSeq: order.pickupSeq,
      ship_to_latitude: order.ship_to_latitude,
      ship_to_longitude: order.ship_to_longitude,
      isReview: true,
    };
  };

  const getFilteredRoutes = (routesList) => {
    if (zone) {
      return routesList.filter((el) => el.zoneName === zone);
    }
    return routesList;
  };

  const renderRoutePopup = () => {
    return (
      <Dialog
        size="md"
        fullWidth
        disableBackdropClick
        open={routeModalOpen}
        onClose={() => {}}
      >
        <DialogTitle id="clinic-popup">Create Route</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="standard-required"
                label="Enter Route Name"
                onChange={(e) =>
                  setRouteDetails({
                    ...routeDetails,
                    routeName: e.target.value,
                  })
                }
                value={(routeDetails && routeDetails.routeName) || ""}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            onClick={() => {
              setRouteModalOpen(false);
              createRoute(routeDetails);
              setRouteDetails(null);
            }}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button
            style={{ borderColor: "#00C9AA", color: "#00C9AA" }}
            onClick={() => {
              setRouteModalOpen(false);
              setRouteDetails(null);
            }}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderPopup = () => {
    return (
      <Dialog
        size="md"
        classes={{ paperWidthSm: "assignpopup" }}
        fullWidth
        disableBackdropClick
        open={isModalOpen}
        onClose={() => {}}
      >
        <DialogTitle id="clinic-popup">
          Order - {orderDetails.order_seq}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Select Route
                </InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  value={(selectedRoute && selectedRoute.routeId) || null}
                  onChange={(e) => {
                    setSelectedRoute({
                      ...selectedRoute,
                      routeId: e.target.value,
                    });
                  }}
                >
                  {getFilteredRoutes(routes || []).map((el, i) => (
                    <MenuItem key={i} value={el.routeId}>
                      {el.name} ({el.zoneName})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                  marginRight: "20px",
                  marginTop: 20,
                }}
              >
                <div
                  className="dateLabel"
                  style={{
                    display: "inline-block",
                    verticalAlign: "top",
                    marginRight: "10px",
                    padding: "8px 0",
                  }}
                >
                  Filter Zones:
                </div>
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "top",
                    minWidth: "120px",
                  }}
                >
                  <Select
                    size="small"
                    fullWidth
                    value={zone || null}
                    onChange={(e) => {
                      setZone(e.target.value);
                    }}
                  >
                    {(props.zones || []).map((zone, index) => (
                      <MenuItem key={`city-${index}`} value={zone.name}>
                        {zone.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div
                  className="dateLabel"
                  onClick={() => setZone(null)}
                  style={{
                    display: "inline-block",
                    verticalAlign: "top",
                    marginLeft: "10px",
                    padding: "8px 0",
                    cursor: "pointer",
                  }}
                >
                  X
                </div>
              </div>
            </Grid>
            {selectedRoute && selectedRoute.routeStops ? (
              <DragDropContext
                onDragEnd={(e) =>
                  onDragEnd(e, selectedRoute.routeStops, selectedRoute.routeId)
                }
              >
                <Grid item xs={1}>
                  <div className="route-heading" style={{ marginBottom: 10 }}>
                    In Review Order
                  </div>
                  <Droppable key={`unassign`} droppableId={`unassign`}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                      >
                        <div className="">
                          <Draggable
                            key={orderDetails.orderId}
                            draggableId={orderDetails.orderId}
                            index={0}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <div
                                  key={`unassign_0`}
                                  className="table-card"
                                  style={{
                                    backgroundColor: orderDetails.is_beyond_slot_time
                                      ? "#FCE5E2"
                                      : "#D0FFA6",
                                    marginBottom: 5,
                                  }}
                                >
                                  <HtmlTooltip
                                    title={
                                      <React.Fragment>
                                        <Typography color="inherit">
                                          Zipcode:{" "}
                                          {orderDetails.ship_to_zip_code}
                                        </Typography>
                                        <Typography color="inherit">
                                          Address:{" "}
                                          {orderDetails.ship_to_address1}
                                        </Typography>
                                        <Typography color="inherit">
                                          EPD:{" "}
                                          {moment(
                                            orderDetails.earliest_pickup_date
                                          ).format("h:mm A")}
                                        </Typography>
                                        <Typography color="inherit">
                                          LPD:{" "}
                                          {moment(
                                            orderDetails.latest_pickup_date
                                          ).format("h:mm A")}
                                        </Typography>
                                        <Typography color="inherit">
                                          Gender:{" "}
                                          {orderDetails.customer_gender || ""}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        padding: "5px",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {orderDetails.order_seq}
                                    </div>
                                  </HtmlTooltip>

                                  <div className="table-card-data">
                                    <div> {orderDetails.eta} </div>
                                    <div>{orderDetails.dpo} KM</div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        </div>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Grid>
                <Grid item xs={10}>
                  <Table
                    style={{
                      borderCollapse: "separate",
                      borderSpacing: "0px 5px",
                    }}
                    size="small"
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        {dateTimeSlot.map((dt, index) => (
                          <TableCell
                            style={{
                              width: "100px",
                              borderBottom: "none",
                              textAlign: "center",
                            }}
                          >
                            {" "}
                            {dt}{" "}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        style={{
                          backgroundColor: "#fff",
                          height: "93px",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 0px 29px 0px",
                        }}
                      >
                        {dateTimeSlot.map((row, index) => (
                          <TableCell
                            style={{
                              borderBottom: "none",
                              height: "83px",
                              verticalAlign: "top",
                            }}
                          >
                            <Droppable
                              key={`${row}_${index}`}
                              droppableId={`${row}`}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  style={getListStyle(snapshot.isDraggingOver)}
                                  {...provided.droppableProps}
                                >
                                  <div className="">
                                    {getAllTrips(
                                      selectedRoute.routeStops || {},
                                      row
                                    ).map((el, tIndex) => (
                                      <Draggable
                                        key={el.orderId}
                                        draggableId={el.orderId}
                                        index={tIndex}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            <div
                                              key={`TRIP_${tIndex}`}
                                              className="table-card"
                                              style={{
                                                backgroundColor: el.is_beyond_slot_time
                                                  ? "#FCE5E2"
                                                  : getBackgroundColor(
                                                      el.orderStatus || ""
                                                    ),
                                                marginBottom: 5,
                                                color: getColor(
                                                  el.orderStatus || ""
                                                ),
                                              }}
                                            >
                                              <HtmlTooltip
                                                title={
                                                  <React.Fragment>
                                                    <Typography color="inherit">
                                                      Zipcode:{" "}
                                                      {el.ship_to_zip_code}
                                                    </Typography>
                                                    <Typography color="inherit">
                                                      Address:{" "}
                                                      {el.ship_to_address1}
                                                    </Typography>
                                                    <Typography color="inherit">
                                                      EPD:{" "}
                                                      {moment(
                                                        el.earliest_pickup_date
                                                      ).format("h:mm A")}
                                                    </Typography>
                                                    <Typography color="inherit">
                                                      LPD:{" "}
                                                      {moment(
                                                        el.latest_pickup_date
                                                      ).format("h:mm A")}
                                                    </Typography>
                                                    <Typography color="inherit">
                                                      Gender:{" "}
                                                      {el.customer_gender || ""}
                                                    </Typography>
                                                  </React.Fragment>
                                                }
                                              >
                                                <div
                                                  style={{
                                                    fontSize: "12px",
                                                    padding: "5px",
                                                    fontWeight: "bold",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  <div>{el.order_seq}</div>
                                                  <div style={{ fontSize: 10 }}>
                                                    {el.pickupSeq}
                                                  </div>
                                                </div>
                                              </HtmlTooltip>
                                              <div
                                                className="table-card-data"
                                                style={{
                                                  color: getColor(
                                                    el.orderStatus || ""
                                                  ),
                                                }}
                                              >
                                                <div> {el.eta} </div>
                                                <div>{el.dpo} KM</div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                  </div>
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </DragDropContext>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ borderColor: "#00C9AA", color: "#00C9AA" }}
            onClick={() => {
              setIsModalOpen(false);
              setSelectedRoute({
                routeId: "",
              });
            }}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div style={{ marginTop: 5 }}>
      <Paper
        style={{
          overflowX: "scroll",
          paddingLeft: "10px",
          paddingRight: "10px",
          height: "300px",
          width: "100%",
          marginBottom: "10px",
        }}
        component="form"
      >
        <h3>In-Review Orders</h3>
        <table className="styled-table">
          <thead className="tableHead">
            <tr className="border_bottom">
              <th>Order No.</th>
              <th>LGP</th>
              <th>Zone name</th>
              <th>Pickup time</th>
              <th>Zip code</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="tableBody">
            {reivewOrders.length > 0 ? (
              <>
                {reivewOrders.map((el, i) => (
                  <>
                    <tr className="row" key={i}>
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <Typography color="inherit">
                              Zipcode: {el.ship_to_zip_code}
                            </Typography>
                            <Typography color="inherit">
                              Address: {el.ship_to_address1}
                            </Typography>
                            <Typography color="inherit">
                              EPD:{" "}
                              {moment(el.earliest_pickup_date).format("h:mm A")}
                            </Typography>
                            <Typography color="inherit">
                              LPD:{" "}
                              {moment(el.latest_pickup_date).format("h:mm A")}
                            </Typography>
                            <Typography color="inherit">
                              Gender: {el.customer_gender || ""}
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <td
                          className="cell"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (!el.ship_to_latitude || !el.ship_to_longitude) {
                              dispatch({
                                type: TOAST_ERROR,
                                message: "Location not found for this order.",
                              });
                              return;
                            }
                            setIsModalOpen(true);
                            setOrderDetails(el);
                          }}
                          data-title="Order Sequence"
                        >
                          {el.order_seq} ({getOrderType(el.order_type)}) (
                          {el.customer_gender || ""})
                        </td>
                      </HtmlTooltip>
                      <td className="cell" data-title="LGP">
                        {el.bill_to_name}
                      </td>
                      <td className="cell" data-title="Zone">
                        {el.zoneName}
                      </td>
                      <td className="cell" data-title="Phlebo">
                        {getDateTime(el.earliest_pickup_date)}
                      </td>
                      <td className="cell" data-title="Customer Name">
                        {el.ship_to_zip_code}
                      </td>
                      <td className="cell" style={{ display: "flex" }}>
                        <IconButton
                          edge="end"
                          size="small"
                          style={{ fontSize: 10, marginRight: 20 }}
                          onClick={() => {
                            setReviewOrderDetails(formatReviewOrder(el));
                            setOrderModalOpen(true);
                          }}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          edge="end"
                          size="small"
                          style={{ fontSize: 10, marginRight: 20 }}
                          onClick={() => {
                            setRouteDetails({
                              ...routeDetails,
                              orderId: el.orderId,
                              earliest_pickup_date: el.earliest_pickup_date,
                              latest_pickup_date: el.latest_pickup_date,
                            });
                            setRouteModalOpen(true);
                          }}
                        >
                          <Add />
                        </IconButton>
                      </td>
                    </tr>
                    {el.errorMessage && (
                      <tr>
                        <td
                          style={{
                            padding: 0,
                            paddingLeft: 10,
                            color: "red",
                            fontSize: 12,
                          }}
                          className="cell"
                          colSpan="6"
                          data-title="Customer Name"
                        >
                          {el.errorMessage}
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </>
            ) : (
              <tr className="row">
                <td className="cell" data-title="Phlebo">
                  No Orders
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Paper>
      <div>
        <MapContainer markerData={props.stops} locations={locations} />
      </div>
      {isModalOpen && renderPopup()}
      {routeModalOpen && renderRoutePopup()}
    </div>
  );
};

const mapStateToProps = ({ authentication }) => {
  return {
    isLoading: authentication.isSubmitting,
  };
};
export default connect(mapStateToProps)(TripMap);
