import { LOCATION_LIST } from 'Constants/actionConstants';
import { ADD_PHELBE_MOBILE_ERROR } from 'Constants/actionConstants';
import {
  PLEBO_LIST,
  PLEBO_KYC_LIST,
  ATTENDANCE_LIST,
  UPDATE_PHLEBO_AREA,
  PHLEBO_MAPPING_DATA,
  IS_SUBMITTING,
  REMOVE_PHLEBO_MAPPING,
  PLEBO_TRACKING_LIST
} from 'Constants/actionConstants';


const plebos = (state={

  isSubmitting: false,
  plebosKYC: [],
  plebos: [],
  attendance: [],
  plebosArea:[],
  plebosMapArea: [],
  removedPhelbo:[],
  plebostracking: [],
  pleboMobileError:'',
  locations:[]

}, action)=> {

  switch (action.type) {

    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };

    case PLEBO_LIST:
      return {
        ...state,
        plebos: action.data,
        hasMore: action.hasMore,
      };
      case LOCATION_LIST:
        return {
          ...state,
          locations: action.data,
          // hasMore: action.hasMore,
        };
    case PLEBO_TRACKING_LIST:
        return {
          ...state,
          plebostracking: action.data,
          hasMore: action.hasMore,
        };

    case PLEBO_KYC_LIST:
      return {
        ...state,
        plebosKYC: action.data,
        hasMore: action.hasMore,
      };

    case ATTENDANCE_LIST:
      return {
        ...state,
        attendance: action.data,
        hasMore: action.hasMore,
      }

    case UPDATE_PHLEBO_AREA:
        return {
          ...state,
          plebosArea: action.data,
          hasMore: action.hasMore,
        }

    case PHLEBO_MAPPING_DATA:
        return {
          ...state,
          plebosMapArea: action.data,
        }

    case REMOVE_PHLEBO_MAPPING:
        return {
          ...state,
          removedPhelbo: action.data,
          hasMore: action.hasMore,
        }
    case ADD_PHELBE_MOBILE_ERROR:
        return {
          ...state,
          pleboMobileError:action.data
        }    

    default:
      return state;
  }

}

export default plebos;
