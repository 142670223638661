import { takeLatest, all } from "redux-saga/effects";
import sagasManager from "Utils/sagasManager";

import {
  signin,
  signOut,
  forgotPassword,
  changePassword,
} from "Sagas/authentication";

import {
  uploadImage,
  uploadCmsImage,
  getAllConstants,
  getAllStates,
  uploadVideo,
  uploadAudio,
  uploadReports,
} from "Sagas/constants";

// import {
//     getPromocode,
//     searchPromocodes,
//     addPromocode,
// } from "Sagas/promocode";

import {
  getLPGs,
  addLGPs,
  deleteLGPs,
  getContractData,
  addContract,
  getDoctorsList,
  getLabList,
  addMerQuestions,
  getMerQuestions,
  getMerOrders,
  updateMerOrders,
  getMerOrdersDashboard
} from "Sagas/lgp";

import {
  addDoctors,
  getAllDoctors,
  updateDoctors,
  deleteDoctors,
  getDoctorOrders,
  getDoctorOrdersCount,
} from "Sagas/doctor";

import {
  getPlebos,
  getPlebosTracking,
  addPlebos,
  deletePlebos,
  getPlebosKYC,
  addPlebosKYC,
  deletePlebosKYC,
  getAttendance,
  addAttendance,
  updatePhleboArea,
  getPhleboMappingData,
  removePhleboMapping,
  getlocations,
} from "Sagas/plebo";

import {
  getRoutes,
  addRoutes,
  deleteRoutes,
  searchRoutes,
  downloadRoutes,
} from "Sagas/tRoutes";

import {
  getVideoMerToken
} from "Sagas/vmer";

import {
  getRunners,
  addRunners,
  deleteRunners,
  getRunnersKYC,
  addRunnersKYC,
  deleteRunnersKYC,
} from "Sagas/runner";


import {
  getRoles,
  addRoles,
  deleteRoles,
} from "Sagas/roles";

import {
  getUsers,
  addUsers,
  deleteUsers,
  getAllUsers,
  getDropDownUsers,
} from "Sagas/users";

import {
  getDept,
  addDept,
  deleteDept,
} from "Sagas/dept";

import {
  getReportDelay,
  updateReporStatus,
  updateTicketStatus,
  getReportTicketSlots,
} from "Sagas/reports";

import {
  getAllOrders,
  getOrderData,
  getVitalsData,
  getOrderDataByBarcode,
  assignOrderData,
  assignDoctor,
  autoAssignOrderData,
  unassignOrder,
  unassignDoctor,
  acceptRejectOrder,
  updateOrder,
  createNewOrder,
  createVmerVitals,
  getOrderDownloadLink,
  uploadOrders,
  deleteLabReport,
  getAllLabReports,
  addLabReports,
  uploadAssignedOrders,
  getUploadAssignStatus,
  getRoutePlan,
  getRoutePlanCount,
  getDownloadReportsLink,
  getDownloadClientOrder,
  uploadProofs,
  uploadPpmcMer,
  getClientOrderDownloadLink,
  getClientOrdersDetails,
  getPhlebosAttendance,
  getPhlebosFirstSlot,
  getPhlebosProofSync,
  getPhlebosLocationStatus,
  updatePhlebosAttendance,
  uploadClientOrders,
  getAllTrips,
  getAllTripsByNum,
  getAllReviewOrders,
  shuffleOrders,
  createRoute,
  getLifecycle,
  getCompressed,
  assignPlebosRoute,
  getVideoToken,
  getVideoMer,
  downloadVideoMer,
  generatePresignedUrl,
  getPresignedUrl,
} from "Sagas/orders";

import {
  getAllZones,
  getUnplannedZipcodes,
  getAllZipcodes,
  getUnmappedPhlebos,
  addZones,
  updateZones,
  removeZonePhlebo,
} from "Sagas/zones";

import {
  getAllRosters,
  addRosters,
  updateRosters,
  getRosterDetailsById,
  getRosterConfirm,
  updateRosterConfirm,
  getRostersInsights,
  getRostersMapping,
  getRostersStatus,

} from "Sagas/rosters";

import {getTrips, setTrips} from "Sagas/trips";

import { createAutoRoutePlan, getAllAutoRoutePlan, getAllSlotBookings, updateConfigSlot, getBoAttendance, submitBoAttendance, createSlots, slotStatus, getAllSlotStatus, getTechUsers } from "Sagas/autoassign";

import { downloadExecutionReport, getAllUploadReports, getDailyReports } from "Sagas/reports";

import {
  SIGN_IN,
  SIGN_OUT,
  FORGOT_PASSWORD,
  UPLOAD_IMAGE,
  UPLOAD_CMS_IMAGE_URL,
  GET_ALL_CONSTANTS,

  // GET_PROMOCODE,
  // SEARCH_PROMOCODE,
  // ADD_PROMOCODE,
  GET_PLEBOS,
  GET_PLEBOS_TRACKING,
  ADD_PLEBOS,
  DELETE_PLEBOS,
  GET_LABS,
  ADD_LAB,
  DELETE_LAB,
  GET_LAB_TEST_BY_LABID,
  ADD_LAB_USERS,
  DELETE_LAB_USERS,
  GET_LAB_USERS_BY_LABID,
  GET_INVENTORY,
  ADD_INVENTORY,
  DELETE_INVENTORY,
  GET_PLEBOS_KYC,
  ADD_PLEBOS_KYC,
  DELETE_PLEBOS_KYC,
  GET_ROUTES,
  ADD_ROUTES,
  DELETE_ROUTES,
  SEARCH_ROUTES,
  GET_RUNNERS,
  ADD_RUNNERS,
  DELETE_RUNNERS,
  GET_RUNNERS_KYC,
  ADD_RUNNERS_KYC,
  DELETE_RUNNERS_KYC,
  GET_ALL_STATES,
  GET_LPGS,
  ADD_LGPS,
  DELETE_LGPS,
  ADD_MER_QUESTIONS,
  GET_MER_QUESTIONS,
  GET_MER_ORDERS,
  UPDATE_MER_ORDERS,
  GET_MER_ORDERS_DASHBOARD,
  ADD_DOCTORS,
  GET_ALL_DOCTORS,
  UPDATE_DOCTORS,
  DELETE_DOCTORS,
  GET_CONTRACT,
  ADD_CONTRACT,
  CHANGE_PASSWORD,
  GET_ALL_ORDERS,
  GET_ORDER_DATA,
  GET_ORDER_DATA_BY_BARCODE,
  ASSIGN_ORDER,
  UPDATE_ORDER,
  UNASSIGN_ORDER,
  ACCEPTREJECT_ORDER,
  GET_ORDER_DOWNLOAD_LINK,
  UPLOAD_ORDERS,
  GET_CLIENT_ORDER_DOWNLOAD_LINK,
  GET_CLIENT_ORDER_DETAILS,
  GET_PLEBOS_ATTENDANCE,
  GET_PLEBOS_PROOF_SYNC,
  GET_PLEBOS_LOCATION_STATUS,
  GET_PLEBOS_FIRST_SLOTS,
  UPDATE_PLEBOS_ATTENDANCE,
  CLIENT_UPLOAD_ORDERS,
  DELETE_LABREPORT,
  GET_ALL_LAB_REPORTS,
  ADD_LAB_REPORTS,
  UPLOAD_ASSIGNED_ORDERS,
  GET_UPLOAD_ASSIGN_STATUS,
  GET_ATTENDANCE_LIST,
  ADD_ATTENDANCE_LIST,
  GET_ROUTE_PLANS,
  GET_ROUTE_PLAN_COUNT,
  DOWNLOAD_REPORTS,
  UPLOAD_VIDEO,
  UPLOAD_AUDIO,
  UPLOAD_REPORTS,
  CREATE_ORDER,
  CREATE_VMER_VITALS,
  GET_DOWNLOAD_CLIENT_ORDER,
  UPDATE_PHLEBO_AREA,
  GET_PHLEBO_MAPPING_DATA,
  GET_REPORT_DELAY,
  UPDATE_REPORT_STATUS,
  REMOVE_PHLEBO_MAPPING,
  UPDATE_TICKET,
  GET_REPORT_SLOTS,
  GET_DOCTOR_LIST,
  GET_DOCTOR_ORDERS,
  GET_LGP_LAB_LIST,
  UPLOAD_PROOFS,
  GET_ALL_ZONES,
  GET_ROSTER_DETAILS_BY_ID,
  GET_UNPLANNED_ZIPCODES,
  GET_ALL_ZIPCODES,
  GET_UNMAPPED_PHLEBOS,
  ADD_ZONES,
  UPDATE_ZONES,
  REMOVE_PHLEBO,
  GET_ALL_ROSTERS,
  ADD_ROSTERS,
  UPDATE_ROSTERS,
  GET_ROSTER_CONFIRM,
  UPDATE_ROSTER_CONFIRM,
  GET_ROSTERS_INSIGHTS,
  GET_ROSTERS_WORK_STATUS,
  CREATE_AUTOASSIGN,
  GET_AUTO_ROUTE_PLANS,
  DOWNLOAD_EXECUTION_REPORT,
  GET_DAILY_REPORTS,
  GET_CLIENT_UPLOAD_REPORTS,
  AUTO_ASSIGN_ORDER,
  GET_ALL_ROSTER_MAPPING,
  ADD_ROLES,
  GET_ROLES,
  DELETE_ROLES,
  ADD_USERS,
  GET_USERS,
  GET_ALL_USERS,
  GET_DROPDOWN_USERS,
  GET_TECH_USERS,
  DELETE_USERS,
  ADD_DEPT,
  GET_DEPT,
  DELETE_DEPT,
  GET_TRIPS,
  SET_TRIPS,
  DOWNLOAD_ROUTES_DATA_URL,
  GET_ALL_TRIPS,
  GET_ALL_TRIPS_BY_NUM,
  GET_ALL_REVIEW_ORDERS,
  ASSIGN_PLEBOS_ROUTE,
  SUFFLE_ORDERS,
  CREATE_ROUTE,
  GET_LIFECYCLE,
  GET_COMPRESSED,
  GET_ALL_SLOT_BOOKINGS,
  CREATE_SLOTS,
  SLOTS_STATUS,
  GET_ALL_SLOTS_STATUS,
  UPDATE_CONFIG_SLOTS,
  GET_BO_ATTENDANCE,
  SUBMIT_BO_ATTENDANCE,
  GET_VITALS_DATA,
  GET_VIDEO_TOKEN,
  GET_VIDEO_MER_TOKEN,
  GET_VIDEO_MER,
  DOWNLOAD_VIDEO_MER,
  GENERATE_PRESIGNED_URL,
  ASSIGN_DOCTOR,
  UNASSIGN_DOCTOR,
  UPLOAD_PPMC_MER,
  GET_DOCTOR_ORDERS_COUNT,
  GET_PRESIGNEDURL,
} from "Constants/actionConstants";
import { getLabs, addLab, deleteLab, getLabTests, addLabUsers, deleteLabUsers, getLabUsers  } from "./labs";
import { getInventory, addInventory, deleteInventory } from "./inventory";
import { GET_LOCATIONS } from "Constants/actionConstants";

sagasManager.addSagaToRoot(function* rootSaga() {
  yield all([
    takeLatest(SIGN_IN, signin),
    takeLatest(SIGN_OUT, signOut),
    takeLatest(FORGOT_PASSWORD, forgotPassword),
    takeLatest(CHANGE_PASSWORD, changePassword),

    takeLatest(UPLOAD_IMAGE, uploadImage),
    takeLatest(UPLOAD_CMS_IMAGE_URL, uploadCmsImage),

    takeLatest(GET_ALL_CONSTANTS, getAllConstants),
    takeLatest(GET_ALL_STATES, getAllStates),

    // takeLatest(GET_PROMOCODE, getPromocode),
    // takeLatest(SEARCH_PROMOCODE, searchPromocodes),
    // takeLatest(ADD_PROMOCODE, addPromocode),

    takeLatest(GET_PLEBOS, getPlebos),
    takeLatest(GET_LOCATIONS, getlocations),
    takeLatest(GET_PLEBOS_TRACKING, getPlebosTracking),
    takeLatest(ADD_PLEBOS, addPlebos),
    takeLatest(DELETE_PLEBOS, deletePlebos),
    takeLatest(GET_TRIPS, getTrips),
    takeLatest(SET_TRIPS, setTrips),
    
    // takeLatest(GET_PHLEBO_POLYGONS, getPhleboMappingData),

    takeLatest(GET_LABS, getLabs),
    takeLatest(ADD_LAB, addLab),
    takeLatest(DELETE_LAB, deleteLab),

    takeLatest(GET_LAB_TEST_BY_LABID, getLabTests),


    takeLatest(ADD_LAB_USERS, addLabUsers),
    takeLatest(DELETE_LAB_USERS, deleteLabUsers),
    takeLatest(GET_LAB_USERS_BY_LABID, getLabUsers),

    takeLatest(GET_INVENTORY, getInventory),
    takeLatest(ADD_INVENTORY, addInventory),
    takeLatest(DELETE_INVENTORY, deleteInventory),

    takeLatest(GET_PLEBOS_KYC, getPlebosKYC),
    takeLatest(ADD_PLEBOS_KYC, addPlebosKYC),
    takeLatest(DELETE_PLEBOS_KYC, deletePlebosKYC),

    takeLatest(GET_ROUTES, getRoutes),
    takeLatest(ADD_ROUTES, addRoutes),
    takeLatest(DELETE_ROUTES, deleteRoutes),
    takeLatest(SEARCH_ROUTES, searchRoutes),

    takeLatest(GET_RUNNERS, getRunners),
    takeLatest(ADD_RUNNERS, addRunners),
    takeLatest(DELETE_RUNNERS, deleteRunners),

    takeLatest(GET_RUNNERS_KYC, getRunnersKYC),
    takeLatest(ADD_RUNNERS_KYC, addRunnersKYC),
    takeLatest(DELETE_RUNNERS_KYC, deleteRunnersKYC),

    takeLatest(GET_LPGS, getLPGs),
    takeLatest(ADD_LGPS, addLGPs),
    takeLatest(DELETE_LGPS, deleteLGPs),

    takeLatest(ADD_MER_QUESTIONS, addMerQuestions),
    takeLatest(GET_MER_QUESTIONS, getMerQuestions),
    takeLatest(GET_MER_ORDERS, getMerOrders),
    takeLatest(UPDATE_MER_ORDERS, updateMerOrders),
    takeLatest(GET_MER_ORDERS_DASHBOARD, getMerOrdersDashboard),

    takeLatest(ADD_DOCTORS, addDoctors),
    takeLatest(GET_ALL_DOCTORS, getAllDoctors),
    takeLatest(UPDATE_DOCTORS, updateDoctors),
    takeLatest(DELETE_DOCTORS, deleteDoctors),

    takeLatest(GET_CONTRACT, getContractData),

    takeLatest(ADD_CONTRACT, addContract),

    takeLatest(GET_ALL_ORDERS, getAllOrders),
    takeLatest(GET_ORDER_DATA, getOrderData),
    takeLatest(GET_VITALS_DATA, getVitalsData),
    takeLatest(GET_VIDEO_TOKEN, getVideoToken),
    takeLatest(GENERATE_PRESIGNED_URL, generatePresignedUrl),
    takeLatest(GET_VIDEO_MER_TOKEN, getVideoMerToken),
    takeLatest(GET_VIDEO_MER, getVideoMer),
    takeLatest(DOWNLOAD_VIDEO_MER, downloadVideoMer),
    takeLatest(GET_ORDER_DATA_BY_BARCODE, getOrderData),
    takeLatest(ASSIGN_ORDER, assignOrderData),
    takeLatest(UNASSIGN_ORDER, unassignOrder),
    takeLatest(AUTO_ASSIGN_ORDER, autoAssignOrderData),
    takeLatest(ASSIGN_DOCTOR, assignDoctor),
    takeLatest(UNASSIGN_DOCTOR, unassignDoctor),
    
    takeLatest(ACCEPTREJECT_ORDER, acceptRejectOrder),

    takeLatest(UPDATE_ORDER, updateOrder),
    takeLatest(GET_ORDER_DOWNLOAD_LINK, getOrderDownloadLink),
    takeLatest(UPLOAD_ORDERS, uploadOrders),

    takeLatest(DELETE_LABREPORT, deleteLabReport),
    takeLatest(GET_ALL_LAB_REPORTS, getAllLabReports),
    takeLatest(ADD_LAB_REPORTS, addLabReports),

    takeLatest(UPLOAD_ASSIGNED_ORDERS, uploadAssignedOrders),
    takeLatest(GET_UPLOAD_ASSIGN_STATUS, getUploadAssignStatus),

    takeLatest(GET_ATTENDANCE_LIST, getAttendance),
    takeLatest(ADD_ATTENDANCE_LIST, addAttendance),

    takeLatest(GET_ROUTE_PLANS, getRoutePlan),
    takeLatest(GET_ROUTE_PLAN_COUNT, getRoutePlanCount),
    takeLatest(DOWNLOAD_REPORTS, getDownloadReportsLink),

    takeLatest(UPLOAD_VIDEO, uploadVideo),
    takeLatest(UPLOAD_AUDIO, uploadAudio),
    takeLatest(UPLOAD_REPORTS, uploadReports),

    takeLatest(CREATE_ORDER, createNewOrder),
    takeLatest(CREATE_VMER_VITALS, createVmerVitals),
    takeLatest(GET_DOWNLOAD_CLIENT_ORDER, getDownloadClientOrder),

    takeLatest(GET_PHLEBO_MAPPING_DATA, getPhleboMappingData),
    takeLatest(UPDATE_PHLEBO_AREA, updatePhleboArea),
    takeLatest(REMOVE_PHLEBO_MAPPING, removePhleboMapping),

    takeLatest(GET_REPORT_DELAY, getReportDelay),
    takeLatest(UPDATE_REPORT_STATUS, updateReporStatus),

    takeLatest(UPDATE_TICKET, updateTicketStatus),
    takeLatest(GET_REPORT_SLOTS, getReportTicketSlots),

    takeLatest(GET_DOCTOR_LIST, getDoctorsList),
    takeLatest(GET_LGP_LAB_LIST, getLabList),
    takeLatest(UPLOAD_PROOFS, uploadProofs),
    takeLatest(UPLOAD_PPMC_MER, uploadPpmcMer),

    takeLatest(GET_DOCTOR_ORDERS, getDoctorOrders),
    takeLatest(GET_DOCTOR_ORDERS_COUNT, getDoctorOrdersCount),

    takeLatest(GET_ALL_ZONES, getAllZones),
    takeLatest(ADD_ZONES, addZones),
    takeLatest(UPDATE_ZONES, updateZones),
    takeLatest(REMOVE_PHLEBO, removeZonePhlebo),

    
    takeLatest(GET_UNPLANNED_ZIPCODES, getUnplannedZipcodes),
    takeLatest(GET_ALL_ZIPCODES, getAllZipcodes),
    takeLatest(GET_UNMAPPED_PHLEBOS, getUnmappedPhlebos),
    takeLatest(DOWNLOAD_ROUTES_DATA_URL, downloadRoutes),

    takeLatest(GET_ALL_ROSTERS, getAllRosters),
    takeLatest(ADD_ROSTERS, addRosters),
    takeLatest(UPDATE_ROSTERS, updateRosters),
    takeLatest(GET_ROSTER_DETAILS_BY_ID, getRosterDetailsById),
    takeLatest(GET_ROSTER_CONFIRM, getRosterConfirm),
    takeLatest(UPDATE_ROSTER_CONFIRM, updateRosterConfirm),
    takeLatest(GET_ROSTERS_INSIGHTS, getRostersInsights),
    takeLatest(GET_ALL_ROSTER_MAPPING, getRostersMapping),
    takeLatest(GET_ROSTERS_WORK_STATUS, getRostersStatus),
    takeLatest(CREATE_AUTOASSIGN, createAutoRoutePlan),
    takeLatest(GET_AUTO_ROUTE_PLANS, getAllAutoRoutePlan),
    takeLatest(GET_ALL_SLOT_BOOKINGS, getAllSlotBookings),
    takeLatest(CREATE_SLOTS, createSlots),
    takeLatest(SLOTS_STATUS, slotStatus),
    takeLatest(GET_ALL_SLOTS_STATUS, getAllSlotStatus),
    takeLatest(UPDATE_CONFIG_SLOTS, updateConfigSlot),
    takeLatest(GET_BO_ATTENDANCE, getBoAttendance),
    takeLatest(SUBMIT_BO_ATTENDANCE, submitBoAttendance),

    takeLatest(DOWNLOAD_EXECUTION_REPORT, downloadExecutionReport),
    takeLatest(GET_DAILY_REPORTS, getDailyReports),
    
    takeLatest(GET_CLIENT_UPLOAD_REPORTS, getAllUploadReports),
    takeLatest(GET_CLIENT_ORDER_DOWNLOAD_LINK, getClientOrderDownloadLink),
    takeLatest(CLIENT_UPLOAD_ORDERS, uploadClientOrders),
    takeLatest(GET_CLIENT_ORDER_DETAILS, getClientOrdersDetails),
    takeLatest(GET_PLEBOS_ATTENDANCE, getPhlebosAttendance),
    takeLatest(GET_PLEBOS_FIRST_SLOTS, getPhlebosFirstSlot),
    takeLatest(UPDATE_PLEBOS_ATTENDANCE, updatePhlebosAttendance),
    takeLatest(GET_PLEBOS_PROOF_SYNC, getPhlebosProofSync),
    takeLatest(GET_PLEBOS_LOCATION_STATUS, getPhlebosLocationStatus),
    takeLatest(GET_ALL_TRIPS, getAllTrips),
    takeLatest(GET_ALL_TRIPS_BY_NUM, getAllTripsByNum),
    takeLatest(GET_ALL_REVIEW_ORDERS, getAllReviewOrders),
    takeLatest(SUFFLE_ORDERS, shuffleOrders),
    takeLatest(CREATE_ROUTE, createRoute),
    takeLatest(GET_LIFECYCLE, getLifecycle),
    takeLatest(GET_COMPRESSED, getCompressed),
    takeLatest(ASSIGN_PLEBOS_ROUTE, assignPlebosRoute),    
    takeLatest(GET_ROLES, getRoles),
    takeLatest(ADD_ROLES, addRoles),
    takeLatest(DELETE_ROLES, deleteRoles),
    takeLatest(GET_USERS, getUsers),
    takeLatest(GET_ALL_USERS, getAllUsers),
    takeLatest(GET_DROPDOWN_USERS, getDropDownUsers),
    takeLatest(GET_TECH_USERS, getTechUsers),
    takeLatest(ADD_USERS, addUsers),
    takeLatest(DELETE_USERS, deleteUsers),
    takeLatest(GET_DEPT, getDept),
    takeLatest(ADD_DEPT, addDept),
    takeLatest(DELETE_DEPT, deleteDept),
    takeLatest(GET_PRESIGNEDURL, getPresignedUrl),
  ]);
});
