import PhleboVMer from 'Components/PhleboVMer/index';
import React, { Component } from 'react';
import { GET_VIDEO_TOKEN, CREATE_VMER_VITALS } from 'Constants/actionConstants';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid } from '@material-ui/core';
import VitalsForm from './VitalsForm';
import './index.css';

class VMer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoToken: {}
    }
  };


  postVmerVitals = () => {
    const {dispatch} = this.props;

    dispatch({
      type:CREATE_VMER_VITALS,
      data:{
        bp1_s: 123,
        bp1_d: 212,
        bp2_s: 123,
        bp2_d: 80,
        bp3_s: 121,
        bp3_d: 70,
        height: 167,
        weight: 60,
        chestExpiration: 23,
        chestInspiration: 32,
        abdomenGirth: 32,
        hip: 34,
        waist: 34,
        hipWaistRatio: 1,
        pulse: 78,
       fastingStatus: '', 
       orderId:'4F589A6B6DFA447C9219BDEC3924AC30'
      }
    })
  }


  getVideoTokenByOrderId = () => {
    const { dispatch } = this.props;
    const { match } = this.props;
    const cname = match.params.id;

    dispatch({
      type: GET_VIDEO_TOKEN,
      data: {
        channelName: cname,
      },
      onSuccess: (data) => {
        this.setState(
          {
            videoToken: data,
          });
      }
    });
  };


  componentDidMount = () => {
    this.getVideoTokenByOrderId();
  }

  render() {
    const { match } = this.props;
    const { videoToken } = this.state;
    const cname = match.params.id;
    return (
      <React.Fragment>
        <div className='page_center'>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <VitalsForm orderId={cname}/>
               </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <PhleboVMer cname={cname} token={videoToken.token} />
            </Grid>
          </Grid>
        </div>
      </React.Fragment>

    );
  }
}

const mapStateToProps = ({ constants, zones }) => {
  return {
    cities: constants.cities,
    zones: zones.zones,
  };
};

export default withRouter(connect(mapStateToProps)(VMer));