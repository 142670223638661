import React, { Component } from "react";
import { connect } from "react-redux";

import DocContainer from "./../../containers/DocContainer";
import ActionLoader from "../../components/ActionLoader";
import { getUser } from "Utils/storage";

import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Button,
  TextField,
  Typography,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import {
  CHANGE_PASSWORD,
  GET_ALL_CONSTANTS,
  GET_BO_ATTENDANCE,
  SUBMIT_BO_ATTENDANCE,
} from "./../../constants/actionConstants";
import moment from "moment";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      isError: false,
      activeTab: 1,
      date: moment(new Date()).date(),
      month: 1 + moment(new Date()).month(),
      year: moment(new Date()).year(),
      ciiesSelected: [],
      attendance: [],
      isLoading: false,
    };
  }

  

  submitPassword = () => {
    this.setState({ isLoading: true });
    const { dispatch } = this.props;
    const { currentPassword, newPassword, confirmPassword } = this.state;
    const user = getUser();
    dispatch({
      type: CHANGE_PASSWORD,
      data: {
        currentPassword,
        newPassword,
        confirmPassword,
        userType: user.userType,
      },
      onSuccess: () => {
        this.setState({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
          isError: false,
          isLoading: false,
        });
      },
    });
  };

  UNSAFE_componentWillMount() {
    this.getAllConstants();
  }

  getAllConstants = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_ALL_CONSTANTS,
    });
  };

  validateData = () => {
    const { currentPassword, newPassword, confirmPassword } = this.state;
    if (
      currentPassword === "" ||
      newPassword === "" ||
      confirmPassword === ""
    ) {
      this.setState({ isError: true });
    } else {
      this.setState({ isError: false });
      this.submitPassword();
    }
  };

  getBoReports = () => {
    this.setState({ isLoading: true });
    const { dispatch } = this.props;
    const { date, month, year } = this.state;
    dispatch({
      type: GET_BO_ATTENDANCE,
      data: {
        date,
        month,
        year,
        group: "ops",
      },
      onSuccess: (data) => {
        this.setState({
          attendance: this.formatAttendance(data.list || []),
          isLoading: false,
        });
      },
    });
  };

  formatAttendance = (data) => {
    let newData = [];
    data.map((el) => {
      (el.city || []).map((ele) => {
        newData.push({
          city: ele,
          firstName: el.firstName,
          lastName: el.lastName,
          username: el.username,
          roleName: el.roleName,
        });
      });
    });
    return newData;
  };

  submitAttendance = () => {
    this.setState({ isLoading: true });
    const { dispatch } = this.props;
    const { date, month, year, citiesSelected } = this.state;
    dispatch({
      type: SUBMIT_BO_ATTENDANCE,
      data: {
        date,
        month,
        year,
        group: "ops",
        city: citiesSelected,
      },
      onSuccess: () => {
        this.getBoReports();
        this.setState({ isLoading: false });
      },
    });
  };

  renderPasswordDetails = () => {
    const {
      currentPassword,
      newPassword,
      confirmPassword,
      isError,
    } = this.state;
    return (
      <Card style={{ border: "none", boxShadow: "none" }}>
        <CardHeader title="Change Password" />
        <CardContent>
          <div className="mb-20">
            <TextField
              label="Current Password"
              fullwidth
              required
              type="password"
              value={currentPassword}
              error={isError && currentPassword === ""}
              onChange={(e) => {
                this.setState({ currentPassword: e.target.value });
              }}
            />
          </div>
          <div className="mb-20">
            <TextField
              label="New Password"
              fullwidth
              required
              type="password"
              value={newPassword}
              error={isError && newPassword === ""}
              onChange={(e) => {
                this.setState({ newPassword: e.target.value });
              }}
            />
          </div>
          <div className="mb-20">
            <TextField
              label="Confirm Password"
              fullwidth
              required
              type="password"
              value={confirmPassword}
              error={isError && confirmPassword === ""}
              onChange={(e) => {
                this.setState({ confirmPassword: e.target.value });
              }}
            />
          </div>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            size="small"
            color="primary"
            variant="contained"
            onClick={this.validateData}
          >
            Submit
          </Button>
        </CardContent>
      </Card>
    );
  };

  getChecked = (city) => {
    const { citiesSelected = [], attendance } = this.state;
    const exis = attendance.find((el) => el.city === city);
    if (exis || citiesSelected.includes(city)) {
      return true;
    }
    return false;
  };

  handleCitySelect = (city) => {
    const { citiesSelected = [] } = this.state;
    let citiesSelectedCopy = JSON.parse(JSON.stringify(citiesSelected));
    const index = citiesSelectedCopy.indexOf(city);
    if (index > -1) {
      citiesSelectedCopy.splice(index, 1);
    } else {
      citiesSelectedCopy.push(city);
    }
    this.setState({ citiesSelected: citiesSelectedCopy });
  };

  renderCities = (city) => {
    const { citiesSelected = [], attendance } = this.state;
    const exis = attendance.find((el) => el.city === city);

    if (exis) {
      return (
        <>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={true}
                  disabled={true}
                  onChange={() => () => {}}
                  name={city}
                />
              }
              label={city}
            />
          </Grid>
          <Grid item xs={8}>
            Already Selected by {exis.username || ""}
          </Grid>
        </>
      );
    } else {
      return (
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={citiesSelected.includes(city)}
                onChange={() => this.handleCitySelect(city)}
                name={city}
              />
            }
            label={city}
          />
        </Grid>
      );
    }
  };

  renderAttendanceDetails = () => {
    const { cities = [] } = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          {cities
            .filter((el) => el !== "ALL")
            .map((el, i) => {
              return (
                <Grid xs={12} item container spacing={3}>
                  {this.renderCities(el)}
                </Grid>
              );
            })}
          <Grid xs={12} item>
            <Button
              style={{ backgroundColor: "#00C9AA" }}
              size="small"
              color="primary"
              variant="contained"
              onClick={this.submitAttendance}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  render() {
    const user = getUser();
    return (
      <DocContainer>
        {this.state.isLoading && <ActionLoader />}
        <Tabs
          value={this.state.activeTab}
          onChange={(e, tab) => {
            this.setState({ activeTab: tab }, () => {
              if (tab === 2) {
                this.getBoReports();
              }
            });
          }}
        >
          <Tab label="Password" value={1} />
          {user.userType != 2 && <Tab label="BO Attendance" value={2} />}
        </Tabs>
        <TabPanel value={this.state.activeTab} index={1}>
          {this.renderPasswordDetails()}
        </TabPanel>
        <TabPanel value={this.state.activeTab} index={2}>
          {this.renderAttendanceDetails()}
        </TabPanel>
      </DocContainer>
    );
  }
}

const mapStateToProps = ({ constants }) => {
  return {
    cities: constants.cities,
  };
};

export default connect(mapStateToProps)(Settings);
