/* /* eslint-disable default-case */
/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./clinics.css";

import DocContainer from "./../../containers/DocContainer";
import ActionLoader from "../../components/ActionLoader";
import ImageSlider from "../../components/ImageSlider";
import PhloeboAutocomplete from "../../components/PhloeboAutocomplete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import BloodPressure from "./VitalsIcons/blood-pressure.png";
import Pulse from "./VitalsIcons/pulse.png";
import Height from "./VitalsIcons/height.png";
import Weight from "./VitalsIcons/weight-scale.png";
import Abdomen from "./VitalsIcons/stomach.png";
import Heart from "./VitalsIcons/heart.png";
import Waist from "./VitalsIcons/waist.png";


import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  Tooltip,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  Paper,
  Button,
  Grid,
  TextField,
  IconButton,
  InputLabel,
  InputAdornment,
  Typography,
  Switch,
  Avatar,
  Tabs,
  Tab,
  Box,
  OutlinedInput,
  Input,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Card,
} from "@material-ui/core";


import {
  GET_ALL_ORDERS,
  GET_ORDER_DATA,
  ASSIGN_ORDER,
  GET_PLEBOS,
  UNASSIGN_ORDER,
  ACCEPTREJECT_ORDER,
  UPDATE_ORDER,
  CREATE_ORDER,
  GET_ORDER_DOWNLOAD_LINK,
  UPLOAD_ORDERS,
  DELETE_LABREPORT,
  GET_ALL_LAB_REPORTS,
  ADD_LAB_REPORTS,
  TOAST_ERROR,
  TOAST_SUCCESS,
  UPLOAD_ASSIGNED_ORDERS,
  GET_UPLOAD_ASSIGN_STATUS,
  UPLOAD_IMAGE,
  UPLOAD_VIDEO,
  UPLOAD_AUDIO,
  UPLOAD_REPORTS,
  DOWNLOAD_REPORTS,
  GET_DOWNLOAD_CLIENT_ORDER,
  GET_ALL_CONSTANTS,
  GET_DOCTOR_LIST,
  GET_LGP_LAB_LIST,
  UPLOAD_PROOFS,
  GET_LPGS,
  GET_VITALS_DATA,
  GET_VIDEO_TOKEN,
  GET_VIDEO_MER,
  DOWNLOAD_VIDEO_MER,
  ASSIGN_DOCTOR,
  GET_ALL_DOCTORS,
  UNASSIGN_DOCTOR,
  GET_PRESIGNEDURL,
} from "./../../constants/actionConstants";

import moment from "moment";
import { getUser, getUserType } from "Utils/storage";

import CreateOrder from "./createOrder";

import StatusIcon from "./battery-with-four-bars.svg";

import DatePicker, { registerLocale } from "react-datepicker";
import el from "date-fns/locale/el";

import {
  ORDER_CONSTANTS,
  PAYMENT_MODE,
  ORDER_PRIORITY,
  ORDER_EXECUTION_TYPE,
  ORDER_TYPE,
  ORDER_ASSIGNED_STATUS,
  FEEDBACK_TYPES,
  ORDER_CANCEL_CODE,
  PAST_TABS,
} from "./../../constants/commonConstants";

import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";

import axios from "axios";

import {
  baseUrl,
  ORDER_CANCEL_ACTION,
} from "../../utils/api";

import {
  Clear,
  Visibility,
  ArrowBackOutlined,
  GetAppOutlined,
  PublishOutlined,
  Refresh,
  Phone,
  Star,
  Close
} from "@material-ui/icons";
import { SET_TYPE_VALUE } from "Constants/actionConstants";
import { GET_CLIENT_ORDER_DETAILS } from "Constants/actionConstants";
import Lifecycle from "./LifeCycle";
import Download from "./Download";
import Agora from "Components/Agora/index";
import withStyles from "@material-ui/core/styles/withStyles";
import { Alert } from "@material-ui/lab";

registerLocale("el", el);



const useStyles = () => ({
  margin: {
    margin: 20
  }
})

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

const REJECT_REASON = [
  { name: "Cancelled By Customer", value: 1 },
  { name: "Cancelled By Customer Due To Phlebo Mistake", value: 6 },

  { name: "Postponed By Customer", value: 2 },
  { name: "Postponed By Customer Due To Phlebo Mistake", value: 7 },

  { name: "Cancelled By Client Dispatcher", value: 3 },
  { name: "Rejected By I2H", value: 4 },
  { name: "Completed By Phlebo", value: 5 },

  { name: "Zipcode Not Serviceable", value: 55 },
  { name: "Duplicate Order", value: 60 },
  { name: "In-Review", value: 11 },
];

const PHLEBO_REJECT_REASON = [
  { name: "Cancelled By Customer Due To Phlebo Mistake", value: 1 },
  { name: "Cancelled By Customer", value: 2 },

  { name: "Postponed By Customer Due To Phlebo Mistake", value: 3 },
  { name: "Postponed By Customer", value: 4 },
];

const ORDER_CANCEL_CODEE = [
  { name: "CUSTOMER_NOT_AVAILABLE", id: 1 },
  { name: "INCORRECT_ADDRESS", id: 2 },
  { name: "CUSTOMER_REFUSED_COLLECTION", id: 3 },
  { name: "CANCELLED_BY_CUSTOMER", id: 4 },
  { name: "CANCELLED_AT_CUSTOMER_POINT", id: 5 },
  { name: "CUSTOMER_NOT_FASTING", id: 6 },
  { name: "PHLEBO_COULD_NOT_CONTACT_CUSTOMER", id: 7 },
  { name: "OTHERS", id: 8 },
];

const timings = [
  { name: "5 - 6", id: 1 },
  { name: "6 - 7", id: 2 },
  { name: "7 - 8", id: 3 },
  { name: "8 - 9", id: 4 },
  { name: "9 - 10", id: 5 },
  { name: "10 - 11", id: 6 },
  { name: "11 - 12", id: 7 },
  { name: "12 +", id: 8 },
];

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeChip: "Open",
      offset: 0,
      open: null,
      searchText: "",
      vendorNumber: "",
      searchPhelbo: "",
      searchDoctor:"",
      isError: false,
      isOpen: false,
      tab: 1,
      type: ORDER_CONSTANTS[0].id,
      selectedOrders: [],
      phleboId: "",
      firstName: "",
      lastName: "",
      mobileNumber: "",
      data: {},
      updatedData: {},
      labReport: {},
      addReport: false,
      isReportDelete: false,
      uploadError: [],
      isUploadError: false,
      assignUpload: { name: "", purpose: "", email: "" },
      slotFrom: new Date(),
      slotTo: new Date(),
      isOverride: false,
      searchOrderSeq: "",
      customerName: "",
      customerContact: "",
      rejectType: null,
      orderCancelProof: "",
      orderCancelVideoProof: "",
      orderCancelAudioProof: "",
      actualCancelReason: "1",
      reasonRemark: "",
      version: 4,
      sub_version: 0,
      isReschedule: false,
      isDeleteOpen: false,
      isShowOpen: false,
      deleteObj: {},
      showObj: {},
      isPast: false,
      isAllNew: false,
      newOrderObj: {},
      isNewConfirm: false,
      filteredPhleboId: "",
      newOrderDate: new Date().getTime(),
      city: "Bangalore",
      dateFilter: localStorage.getItem("orderDateFilter")
        ? JSON.parse(localStorage.getItem("orderDateFilter"))
        : new Date().getTime(),
      endDateFilter: localStorage.getItem("endDateFilter")
        ? JSON.parse(localStorage.getItem("endDateFilter"))
        : 0, //new Date().getTime(),
      cityFilterDisable: false,
      availableTestDetails: [],
      proofImages: [],
      isPenalise: false,
      allReports: [],
      phlebos: "",
      lgp: undefined,
      allOrders: ORDER_CONSTANTS,
      timings: undefined,
      timeValue: timings[0].name,
      isMobile: false,
      vitals: {},
      videoToken: {},
      videoMer: {},
      fileName: "",
      downloadMer: [],
      numbers: "",
      openVitals:false,
      openAlert:false,
      join:false,
      isDoctorAssign:false,
      doctorId:'',
      unassignDocOpen:false,
      setUrl:'',
      presignedUrl:'',
    };
  }



  UNSAFE_componentWillMount() {
    if (this.props.dateValue) {
      const date = new Date(this.props.dateValue);
      this.setState({ dateFilter: date.getTime() });
    }
    if (this.props.cityValue) {
      this.setState({ city: this.props.cityValue });
    };
    const { match } = this.props;
    if (match.params && match.params.id) {
      this.getOrderById({
        orderId: match.params.id,
        orderStatus: match.params.status,
      });
    } else {
      if (!this.props.typeValue) {
        this.getOrders();
      } else {
        this.setState({ type: this.props.typeValue });
      }
    }
    this.getAllConstants();
    this.getPlebosForFilter();
    if (getUser().isShowDoctors) {
      this.getDoctorsList();
    }
    this.getLGPs();
    const { fileName } = this.state;
    if (fileName !== "") {
      this.getVideoMerByFileName();
    } 

  }

 
  componentDidMount = () => {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.getAllReports();
    const city =
      localStorage.getItem("city") && localStorage.getItem("city") != "null"
        ? localStorage.getItem("city")
        : this.props.cityValue
          ? this.props.cityValue
          : "";
    const isRestrictCity =
      localStorage.getItem("isRestrictCity") == "true" ? true : false;

    

    if (isRestrictCity) {
      this.setState({
        city: city,
        cityFilterDisable: true,
      });
    } else {
      this.setState({
        city: city,
        cityFilterDisable: false,
      });
      if(getUser().userType !== 2){
        this.getAllDoctors();
      }
      
    }

  };

  resize() {
    this.setState({ isMobile: window.innerWidth <= 760 });
  }

  // componentWillUnmount() {
  //   window.removeEventListener("resize", this.resize.bind(this));
  // }

  getLGPs = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_LPGS,
      data: {
        count: 200,
        offset: 0,
      },
    });
  };

  getRejectionReason = (type) => {
    const data = REJECT_REASON.find((o) => o.value === type);
    if (data && data.name) {
      return data.name;
    }
    return "";
  };

  getPhleboRejectionReason = (type) => {
    const data = PHLEBO_REJECT_REASON.find((o) => o.value === type);
    if (data && data.name) {
      return data.name;
    }
    return "";
  };

  getPhleboRejectionReasonType = (type) => {
    const data = ORDER_CANCEL_CODEE.find((o) => o.value === type);
    if (data && data.name) {
      return data.name;
    }
    return "";
  };

  resetPopup = () => {
    this.setState({ data: {}, updatedData: {}, isError: false });
  };

  getAllConstants = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_ALL_CONSTANTS,
    });
  };

  getDoctorsList = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_DOCTOR_LIST,
    });
  };

  getAllStates = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_ALL_STATES,
    });
  };

  redirectToDetail = (order) => {
    this.setState(
      {
        offset: 0,
      },
      () => {
        this.getOrderById(order, true);
      }
    );
  };

  redirectToList = () => {
    const { match, history } = this.props;
    this.setState({ offset: 0 }, () => {
      this.getOrders();
    });
    this.resetPopup();
    history.goBack();
    this.setState({ tab: 1 })
  };

  getOrders = (id) => {
    const { dispatch } = this.props;

    let data = {
      count: 10,
      isPast: this.state.isPast,
      offset: this.state.offset,
    };
    if (id) {
      data = { phleboId: id };
    }

    if (this.state.searchText && this.state.searchText.length > 0) {
      data["text"] = this.state.searchText;
    }
    if (this.state.vendorNumber && this.state.vendorNumber.length > 0) {
      data["vendorId"] = this.state.vendorNumber;
    }
    if (this.state.searchOrderSeq && this.state.searchOrderSeq.length > 0) {
      data["orderSeq"] = this.state.searchOrderSeq;
    }
    if (this.state.customerName && this.state.customerName.length > 0) {
      data["customerName"] = this.state.customerName;
    }
    if (this.state.customerContact && this.state.customerContact.length > 0) {
      data["customerContact"] = this.state.customerContact;
    }
    if (this.state.lgpId) {
      data["lgpId"] = this.state.lgpId;
    }
    if (this.state.lgp) {
      data["lgp"] = this.state.lgp;
    }
    if (this.state.timings) {
      data["slot"] = Array.prototype.map
        .call(this.state.timings, function (item) {
          return item.id;
        })
        .join(",");
    }
    if (this.state.type) {
      data["type"] = this.state.type;
    }
    if (this.state.dateFilter) {
      data["month"] = moment(this.state.dateFilter).month() + 1;
      data["date"] = moment(this.state.dateFilter).date();
      data["year"] = moment(this.state.dateFilter).year();
    }

    if (this.state.endDateFilter) {
      data["endMonth"] = moment(this.state.endDateFilter).month() + 1;
      data["endDate"] = moment(this.state.endDateFilter).date();
      data["endYear"] = moment(this.state.endDateFilter).year();
    }

 
    data["city"] = this.state.city ? this.state.city : "Bangalore";
   

    if (this.state.filteredPhleboId) {
      data["phleboId"] = this.state.filteredPhleboId;
    }
    if (this.state.open && this.state.type === 20) {
      data["open"] = this.state.open;
    }

    data["isPartner"] = getUser().userType === 2;
    if (getUser().userType === 2) {
      data["lgpId"] = getUser().lgpId;
    }

    dispatch({
      type: GET_ALL_ORDERS,
      isPartner: getUser().userType === 2,
      data,
      onSuccess: (data) => {
        if (data.type) {
          this.setState({
            isPast: data.isPast !== undefined ? data.isPast : this.state.isPast,
            type: data.type || this.state.type,
          });
        }
      },
    });
    this.getAllReports();
  };

  getOrderById = (order, isRedirect) => {
    const { dispatch } = this.props;

    dispatch({
      type: GET_ORDER_DATA,
      data: {
        orderId: order.orderId,
        type: order.orderStatus,
        isPast: this.state.isPast,
      },
      isPartner: getUser().userType === 2,
      onSuccess: (data) => {
        this.setState(
          {
            data,
            updatedData: {},
          },
          () => {
            if (isRedirect) {
              this.props.history.push(
                `/orders/${order.orderId}/${order.orderStatus}`
              );
            }
            if (data.lgpId && data.labId) {
              this.getAllLabList(data.lgpId, data.labId);
            }
          }
        );
      },
    });
  };

  getVitalsByOrderId = () => {
    const { dispatch } = this.props;
    const { match } = this.props;
    const { vitals } = this.state;

    dispatch({
      type: GET_VITALS_DATA,
      data: {
        orderId: match.params.id,
      },
      onSuccess: (data) => {
        this.setState(
          {
            vitals: data,
          });
      }
    });
  };

  getVideoTokenByOrderId = () => {
    const { dispatch } = this.props;
    const { data } = this.state;

    dispatch({
      type: GET_VIDEO_TOKEN,
      data: {
        channelName: data.order_seq,
      },
      onSuccess: (data) => {
        this.setState(
          {
            videoToken: data,
          });
      }
    });
  };

  callBackFunction = (fileData) => {
    if(fileData){
      const { dispatch } = this.props;
      const { data } = this.state;
  
      dispatch({
        type: GET_VIDEO_MER,
        data: {
          fileName: fileData,
          orderId: data.order_seq,
        },
        onSuccess: (data) => {
          this.setState(
            {
              videoMer: data,
            });
        }
      });
    }

    this.setState({ fileName: fileData,  });
  }

  callBackJoinStatus = (joinStatus) => {
    this.setState({join:joinStatus });

  }

  getVideoMerByFileName = () => {
    const { dispatch } = this.props;
    const { fileName } = this.state;
    const { data } = this.state;

    dispatch({
      type: GET_VIDEO_MER,
      data: {
        fileName: fileName,
        orderId: data.order_seq,
      },
      onSuccess: (data) => {
        this.setState(
          {
            videoMer: data,
          });
      }
    });
  };

  downloadVideoMer = () => {
    const { dispatch } = this.props;
    const { data } = this.state;

    dispatch({
      type: DOWNLOAD_VIDEO_MER,
      data: {
        orderId: data.order_seq,
      },
      onSuccess: (data) => {
        this.setState(
          {
            downloadMer: data.list,
          });
      }
    });

  }

  downloadClientOrder = () => {
    const { dispatch } = this.props;
    const { dateFilter } = this.state;
    const data = {};
    if (dateFilter) {
      data["month"] = moment(dateFilter).month() + 1;
      data["date"] = moment(dateFilter).date();
      data["year"] = moment(dateFilter).year();
      data["type"] = this.state.type;
    }
    if (this.state.endDateFilter) {
      data["endMonth"] = moment(this.state.endDateFilter).month() + 1;
      data["endDate"] = moment(this.state.endDateFilter).date();
      data["endYear"] = moment(this.state.endDateFilter).year();
    }
    if (this.state.filteredPhleboId) {
      data["phleboId"] = this.state.filteredPhleboId;
    }

    dispatch({
      type: GET_DOWNLOAD_CLIENT_ORDER,
      data,
      onSuccess: (data) => {
        if (data.link) {
          let a = document.createElement("a");
          a.href = data.link;
          a.download = true;
          a.click();
        }
      },
    });
  };

  downloadOrder = () => {
    const { dispatch } = this.props;
    let data = {};
    if (this.state.searchText && this.state.searchText.length > 0) {
      data["text"] = this.state.searchText;
    }
    if (this.state.vendorNumber && this.state.vendorNumber.length > 0) {
      data["vendorId"] = this.state.vendorNumber;
    }
    if (this.state.searchOrderSeq && this.state.searchOrderSeq.length > 0) {
      data["orderSeq"] = this.state.searchOrderSeq;
    }
    if (this.state.type) {
      data["type"] = this.state.type;
    }
    if (this.state.dateFilter) {
      data["month"] = moment(this.state.dateFilter).month() + 1;
      data["date"] = moment(this.state.dateFilter).date();
      data["year"] = moment(this.state.dateFilter).year();
    }
    if (this.state.endDateFilter) {
      data["endMonth"] = moment(this.state.endDateFilter).month() + 1;
      data["endDate"] = moment(this.state.endDateFilter).date();
      data["endYear"] = moment(this.state.endDateFilter).year();
    }
    if (this.state.city) {
      data["city"] = this.state.city;
    }
    if (this.state.filteredPhleboId) {
      data["phleboId"] = this.state.filteredPhleboId;
    }

    dispatch({
      type: GET_ORDER_DOWNLOAD_LINK,
      data,
      onSuccess: (data) => {
        if (data.link) {
          this.setState(
            {
              downloadLink: data.link,
            },
            () => {
              this.downloadBtn.click();
            }
          );
        }
      },
    });
  };

  uploadOrder = (e, type) => {
    const { dispatch } = this.props;

    dispatch({
      type: UPLOAD_ORDERS,
      file: e.target.files,
      onSuccess: (data) => {
        if (data && data.message && data.status === 400) {
          this.setState({
            uploadError: data.data && data.data.list ? data.data.list : [],
            isUploadError: true,
          });
        } else {
          if (this.state.uploadError.length > 0) {
            this.setState({ uploadError: [] });
          }
        }
        this.getOrders();
      },
    });
    if (this.uploadLink) {
      this.uploadLink.value = null;
    }
  };



  uploadAssignedOrders = (e) => {
    const { dispatch } = this.props;
    const { assignUpload } = this.state;
    dispatch({
      type: UPLOAD_ASSIGNED_ORDERS,
      file: e.target.files,
      data: {
        name: assignUpload.name,
        email: assignUpload.email,
        purpose: assignUpload.purpose,
        version: this.state.version,
        sub_version: this.state.sub_version,
      },
      onSuccess: (data) => {
        if (data && data.message && data.status === 400) {
          this.setState({
            uploadError: data.data && data.data.list ? data.data.list : [],
            isUploadError: true,
          });
        } else {
          if (this.state.uploadError.length > 0) {
            this.setState({ uploadError: [] });
          }
        }
        this.setState({
          assignUpload: { name: "", email: "", purpose: "" },
          isOrderAssign: false,
        });
        this.getOrders();
        this.getAssignStatus();
      },
    });
    if (this.uploadAssignLink) {
      this.uploadAssignLink.value = null;
    }
  };

  getAssignStatus = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_UPLOAD_ASSIGN_STATUS,
      onSuccess: (data) => {
        this.setState({
          statusData: data && (data.list || []).length > 0 ? data.list : [],
          isAssignStatus: true,
        });
      },
    });
  };

  assignOrder = () => {
    const { dispatch } = this.props;
    dispatch({
      type: ASSIGN_ORDER,
      data: {
        orderId: this.state.selectedOrders,
        phleboId: this.state.phleboId,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        slotFrom: new Date(this.state.slotFrom).getTime(),
        slotTo: new Date(this.state.slotTo).getTime(),
        isOverride: this.state.isOverride,
        version: this.state.version,
        sub_version: this.state.sub_version,
        type: this.state.type,
        isPast: this.state.isPast,
      },
      onSuccess: (data) => {
        this.getOrders();
        this.getPlebosForFilter();
        this.setState({
          isOrderAssign: false,
          selectedOrders: [],
          phleboId: "",
          firstName: "",
          lastName: "",
          slotFrom: "",
          slotTo: "",
          isOverride: "",
        });
      },
    });
  };

  unassignOrder = (order) => {
    const { dispatch } = this.props;
    dispatch({
      type: UNASSIGN_ORDER,
      data: {
        orderId: order.orderId,
        phleboId: order.phleboId,
        type: this.state.type,
        isPast: this.state.isPast,
      },
      onSuccess: () => {
        this.getOrders();
        this.setState({ isDeleteOpen: false });
      },
    });
  };

  unassignDoctor = (order) => {
    const { dispatch } = this.props;
    dispatch({
      type: UNASSIGN_DOCTOR,
      data: {
        orderId: order.orderId,
        doctorId: order.doctorId,
        type: this.state.type,
        isPast: this.state.isPast,
      },
      onSuccess: () => {
        this.getOrders();
        this.setState({ unassignDocOpen: false });
      },
    });
  };

  acceptRejectOrder = (status, orderObj) => {
    const { dispatch } = this.props;
    const { isAllNew } = this.state;
    let data = {};

    if (isAllNew) {
      data = { orderStatus: 4, isAllNew: true };
      if (this.state.dateFilter) {
        data["month"] = moment(this.state.dateFilter).month() + 1;
        data["date"] = moment(this.state.dateFilter).date();
        data["year"] = moment(this.state.dateFilter).year();
      }
      if (this.state.city) {
        data["city"] = this.state.city;
      }
    } else {
      data = {
        orderId: status === 4 ? [orderObj.orderId] : this.state.selectedOrders,
        orderStatus: status === 4 ? orderObj.orderStatus : status,
      };
    }

    if (this.state.filteredPhleboId) {
      data["phleboId"] = this.state.filteredPhleboId;
    }

    if (status === 3) {
      const {
        orderCancelProof,
        orderCancelVideoProof,
        orderCancelAudioProof,
        isReschedule,
        slotFrom,
        slotTo,
      } = this.state;
      data["isReschedule"] = isReschedule;

      if (this.state.rejectComments) {
        data["rejectComments"] = this.state.rejectComments;
      }
      if (this.state.rejectType) {
        data["rejectType"] = this.state.rejectType;
      }
      if (orderCancelProof) {
        data["orderCancelProof"] = orderCancelProof;
      }
      if (orderCancelVideoProof) {
        data["orderCancelVideoProof"] = orderCancelVideoProof;
      }
      if (orderCancelAudioProof) {
        data["orderCancelAudioProof"] = orderCancelAudioProof;
      }
      data["slotFrom"] = new Date(slotFrom).getTime();
      data["slotTo"] = new Date(slotTo).getTime();
    }
    if (status === 4) {
      data["isNew"] = true;
      data["newOrderDate"] = this.state.newOrderDate;
    }
    dispatch({
      type: ACCEPTREJECT_ORDER,
      data,
      onSuccess: () => {
        this.getOrders();
        this.setState({
          selectedOrders: [],
          isAllNew: false,
          newOrderDate: new Date().getTime(),
        });
        if (orderObj) {
          this.setState({ isNewConfirm: false, newOrderObj: {} });
        }
      },
    });
  };

  assignDoctor = () => {
    const { dispatch } = this.props;
    dispatch({
      type: ASSIGN_DOCTOR,
      data: {
        orderId: this.state.selectedOrders,
        doctorId: this.state.doctorId,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
      },
      onSuccess: (data) => {
        this.getOrders();
        this.setState({
          isDoctorAssign:false,
          selectedOrders: [],
          doctorId: "",
          firstName: "",
          lastName: ""
        });
      },
    });
  };

  getPlebos = (id) => {
    const { dispatch } = this.props;

    let data = {
      count: 100,
      offset: 0, 
    };
    if (id) {
      data = { phleboId: id };
    }
    if (this.state.searchPhelbo && this.state.searchPhelbo.length > 0) {
      data["text"] = this.state.searchPhelbo;
    }

    dispatch({
      type: GET_PLEBOS,
      data,
    });
  };

  getAllDoctors = (id) => {
    const { dispatch } = this.props;
    let data = {
      count: 10,
      offset: this.state.offset,
      text: ''
    };
    if (id) {
      data = { doctorId: id };
    }

    if (this.state.searchDoctor && this.state.searchDoctor.length > 0) {
      data['text'] = this.state.searchDoctor;
    }
    dispatch({
      type: GET_ALL_DOCTORS,
      data,
      onSuccess: ((data) => {
        this.setState({ doctors: data.doctors })
      })
    })
  };

  getPlebosForFilter = (search = null) => {
    const { dispatch } = this.props;

    const data = {
      count: 100,
      offset: 0,
      text: this.state.city,
    };

    if (this.state.lgp) {
      data["lgp"] = this.state.lgp;
    }
    if (this.state.timings) {
      data["slot"] = Array.prototype.map
        .call(this.state.timings, function (item) {
          return item.id;
        })
        .join(",");
    }

    if (search) {
      data.search = search;
    }
    dispatch({
      type: GET_PLEBOS,
      data,
    });
  };

  addNewOrder = (items) => {
    const { dispatch } = this.props;

    dispatch({
      type: CREATE_ORDER,
      data: { ...items },
      onSuccess: () => {
        this.setState({ addOrder: false, type: 1 });
        this.getOrders();
      },
    });
  };

  updateOrder = () => {
    const { dispatch, match } = this.props;
    const { data, updatedData, labReport } = this.state;
    const dataParams = {
      orderId: data.orderId,
      lgpId: data.lgpId,
      orderSeq: data.order_seq,
      ...updatedData,
    };
    if (Object.keys(labReport).length > 0) {
      const report = {
        ...labReport,
        created: new Date().getTime(),
      };
      dataParams["lab_reports"] = [{ ...report }];
    }
    dispatch({
      type: UPDATE_ORDER,
      data: dataParams,
      onSuccess: () => {
        this.setState({ addReport: false, updatedData: {}, labReport: {} });
        this.getOrderById({
          orderId: match.params.id,
          orderStatus: match.params.status,
        });
      },
    });
  };

  deleteReport = (obj) => {
    const { dispatch, match } = this.props;
    dispatch({
      type: DELETE_LABREPORT,
      data: { reportId: obj.reportId },
      onSuccess: () => {
        this.setState({
          isReportDelete: false,
          updatedData: {},
          deleteObj: null,
        });
        this.getReports();
      },
    });
  };

  addNewReport = (obj) => {
    const { dispatch, match } = this.props;
    const { labReport, presignedUrl, data } = this.state;
    console.log(presignedUrl);
    console.log(data.customerName);
    if (Object.keys(labReport).length > 0) {
      const data = {
        ...labReport,
        pre_url:presignedUrl,
        orderId: match.params.id,
        created: new Date().getTime(),
      };
      dispatch({
        type: ADD_LAB_REPORTS,
        data,
        onSuccess: () => {
          this.setState({ addReport: false, updatedData: {}, labReport: {} });
          this.getReports();
        },
      });
    } else {
      dispatch({
        type: TOAST_ERROR,
        message: "Please enter report details.",
      });
    }
  };


  getReports = () => {
    const { dispatch, match } = this.props;
    dispatch({
      type: GET_ALL_LAB_REPORTS,
      data: { orderId: match.params.id },
    });
  };

  



  downloadReports = () => {
    const { dispatch } = this.props;

    let data = {};
    if (this.state.searchText && this.state.searchText.length > 0) {
      data["text"] = this.state.searchText;
    }
    if (this.state.vendorNumber && this.state.vendorNumber.length > 0) {
      data["vendorId"] = this.state.vendorNumber;
    }
    if (this.state.searchOrderSeq && this.state.searchOrderSeq.length > 0) {
      data["orderSeq"] = this.state.searchOrderSeq;
    }
    if (this.state.searchText && this.state.searchText.length > 0) {
      data["text"] = this.state.searchText;
    }
    if (this.state.type) {
      data["type"] = this.state.type;
    }
    if (this.state.dateFilter) {
      data["month"] = moment(this.state.dateFilter).month() + 1;
      data["date"] = moment(this.state.dateFilter).date();
      data["year"] = moment(this.state.dateFilter).year();
    }

    if (this.state.endDateFilter) {
      data["endMonth"] = moment(this.state.endDateFilter).month() + 1;
      data["endDate"] = moment(this.state.endDateFilter).date();
      data["endYear"] = moment(this.state.endDateFilter).year();
    }
    if (this.state.city) {
      data["city"] = this.state.city;
    }
    if (this.state.filteredPhleboId) {
      data["phleboId"] = this.state.filteredPhleboId;
    }

    if (this.state.lgp) {
      data["lgp"] = this.state.lgp;
    }
    if (this.state.timings) {
      data["slot"] = Array.prototype.map
        .call(this.state.timings, function (item) {
          return item.id;
        })
        .join(",");
    }

    dispatch({
      type: DOWNLOAD_REPORTS,
      data,
      onSuccess: (data) => {
        if (data.link) {
          this.setState(
            {
              downloadReportLink: data.link,
            },
            () => {
              this.downloadReportBtn.click();
            }
          );
        }
      },
    });
  };

  uploadImage = (file, isAdd) => {
    const { dispatch } = this.props;
    const { data } = this.state;
    dispatch({
      type: UPLOAD_IMAGE,
      file,
      params: { type: 10, orderNo: data.order_seq || 0 },
      onSuccess: (res) => {
        if (res && res.imageUrl) {
          const { labReport } = this.state;
          labReport.url = res.imageUrl;
          this.setState({ labReport });
          this.setState({setUrl:res.imageUrl});
        }
       
      },
      
    });

    setTimeout(()=>{
      this.getPresignedUrl();
    }, 1000); 
   
  };

  getPresignedUrl = () => {
    const {dispatch} = this.props;
    const {presignedUrl, setUrl} = this.state;
    console.log(setUrl);
    const data = {
      fileName:setUrl,
    }
    // console.log(fileName);
    dispatch({
      type: GET_PRESIGNEDURL,
      data,
      onSuccess: (res) =>{
        console.log(res);  
        this.setState({presignedUrl: res.message});
        console.log(presignedUrl);      
      },
    });
  };


  getType = (type) => {
    switch (type) {
      case "finalPaymentProof":
        return 1;
      case "trfFormProof":
        return 4;
      case "labProof":
        return 2;
      case "orderCancelAudioProof":
        return 5;
      case "orderCancelVideoProof":
        return 6;
      case "orderCancelProof":
        return 3;
        case "customerSignature":
          return 7;
      default:
        return 0;
    }
  };

  uploadProofImage = (file, name, type) => {
    const { dispatch, match, orderData } = this.props;
    const { data } = this.state;
    dispatch({
      type: UPLOAD_IMAGE,
      file,
      params: { type: this.getType(type), orderNo: data.order_seq || 0 },
      data: { type },
      onSuccess: (res) => {
        if (res && res.imageUrl) {
          dispatch({
            type: UPLOAD_PROOFS,
            data: { type, orderId: match.params.id, url: res.imageUrl },
            onSuccess: (resdata) => {
              if (resdata) {
                orderData[name] = res.imageUrl;
                this.setState({ orderData });
              }
            },
          });
        }
      },
    });
  };

  uploadRejectionImage = (e, type) => {
    const { dispatch } = this.props;
    let dispType = UPLOAD_REPORTS;

    if (type === "orderCancelProof") {
      dispType = UPLOAD_REPORTS;
    }
    if (type === "orderCancelVideoProof") {
      dispType = UPLOAD_VIDEO;
    }
    if (type === "orderCancelAudioProof") {
      dispType = UPLOAD_AUDIO;
    }

    dispatch({
      type: dispType,
      file: e.target.files,
      onSuccess: (res) => {
        if (res && res.imageUrl) {
          this.setState({ [type]: res.imageUrl });
        }
      },
    });
  };

  handleFormChange = (e, key) => {
    const { data, updatedData } = this.state;
    updatedData[key] = e.target.value;
    data[key] = e.target.value;
    this.setState({ updatedData, data });
  };

  handleFormTestDetailsChange = (e, key, index) => {
    const { data, updatedData } = this.state;
    if (data && data.test_details && data.test_details.length > 0) {
      data.test_details[index][key] = e.target.value;
      updatedData.test_details = data.test_details;
      this.setState({ data, updatedData }, () => this.updateAmount());
    }
  };

  handleAutoCompleteChange = (value, key, index) => {
    const { data, updatedData } = this.state;
    if (data && data.test_details && data.test_details.length > 0) {
      data.test_details[index][key] = value;
      updatedData.test_details = data.test_details;
      const obj = this.state.availableTestDetails.find(
        (o) => o.item_name === value
      );
      if (obj) {
        data.test_details[index]["test_id"] = obj.item_id;
        data.test_details[index]["unit_price"] = obj.item_price;
        data.test_details[index]["test_type"] = obj.item_type;
        updatedData.test_details = data.test_details;
      }
      this.setState({ data, updatedData }, () => this.updateAmount());
    }
  };

  handleFormBarcodeChange = (e, index) => {
    const { data, updatedData } = this.state;
    if (data && data.barcodes && data.barcodes.length > 0) {
      data.barcodes[index].barcode = e.target.value;
      updatedData.barcodes = data.barcodes;
      this.setState({ data, updatedData });
    }
  };

  handleFormBarcodeTypeChange = (e, index) => {
    const { data, updatedData } = this.state;
    if (data && data.barcodes && data.barcodes.length > 0) {
      data.barcodes[index].barcodeType = e.target.value;
      updatedData.barcodes = data.barcodes;
      this.setState({ data, updatedData });
    }
  };

  updateAmount = () => {
    const { data, updatedData } = this.state;
    let totalAmount = 0;
    (data.test_details || [])
      .filter((i) => i.isAdd)
      .map((el) => {
        if (el.unit_price && el.quantity) {
          totalAmount = totalAmount + el.unit_price * el.quantity;
        }
      });
    const oriAmount = data.ori_amount_to_collect
      ? data.ori_amount_to_collect
      : data.amount_to_collect;
    data.ori_amount_to_collect = oriAmount;
    updatedData.ori_amount_to_collect = oriAmount;
    const collectAmount =
      parseInt(data.ori_amount_to_collect) + parseInt(totalAmount);
    data.amount_to_collect = collectAmount;
    updatedData.amount_to_collect = collectAmount;
    this.setState({ data, updatedData });
  };

  subAmount = (index, dataCopy) => {
    let testDetails =
      (dataCopy.test_details && dataCopy.test_details[index]) || {};
    const { data, updatedData } = this.state;
    if (!testDetails || !testDetails.isAdd) {
      return;
    }
    let totalAmount = 0;
    if (testDetails.unit_price && testDetails.quantity) {
      totalAmount = testDetails.unit_price * testDetails.quantity;
    }
    const amount_to_collect = parseInt(data.amount_to_collect) - totalAmount;
    data.amount_to_collect = amount_to_collect;
    updatedData.amount_to_collect = amount_to_collect;
    this.setState({ data, updatedData });
  };

  getAllLabList = (lgpId, labId) => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_LGP_LAB_LIST,
      data: {
        lgpId,
        labId,
      },
      isPartner: getUser().userType === 2,
      onSuccess: (data) => {
        this.setState({
          availableTestDetails: data.list,
        });
      },
    });
  };

  validateData = () => {
    this.updateOrder();
  };

  getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2)
      return parts
        .pop()
        .split(";")
        .shift();
  };

  AcceptOrder = async (obj) => {
    let data = {
      orderId: obj.orderId,
      isAccept: true,
      isPenalise: this.state.isPenalise,
    };
    const { dispatch } = this.props;
    let token = this.getCookie("ls-token");
    let userType = this.getCookie("userType");
    const headers = {
      "Content-Type": "application/json",
      deviceTypeId: 4,
      token: token,
      userType: userType,
    };
    try {
      let res = await axios.post(`${baseUrl}${ORDER_CANCEL_ACTION}`, data, {
        headers: headers,
      });
      this.getOrders();
    } catch (e) {
      dispatch({
        type: TOAST_ERROR,
        message: "Unable to Accept.",
      });
    }
    this.setState({
      type: 70,
      isAcceptOpen: false,
      acceptObj: null,
      isPenalise: false,
    });
  };

  RejectCROrder = async (obj) => {
    const { dispatch } = this.props;
    let data = {
      orderId: obj.orderId,
      isAccept: false,
      actualCancelReason: this.state.actualCancelReason,
      orderCancelComments: this.state.reasonRemark,
      isPenalise: this.state.isPenalise,
    };

    let token = this.getCookie("ls-token");
    let userType = this.getCookie("userType");
    const headers = {
      "Content-Type": "application/json",
      deviceTypeId: 4,
      token: token,
      userType: userType,
    };
    try {
      let res = await axios.post(`${baseUrl}${ORDER_CANCEL_ACTION}`, data, {
        headers: headers,
      });
      this.getOrders();
    } catch (e) {
      dispatch({
        type: TOAST_ERROR,
        message: "Unable to Reject.",
      });
    }
    this.setState({
      type: 70,
      isRejectOpen: false,
      acceptObj: null,
      reasonRemark: "",
      actualCancelReason: "1",
      isPenalise: false,
    });
  };

  renderAcceptPopup = () => {
    const { isAcceptOpen, data, acceptObj } = this.state;
    return (
      <Dialog
        disableBackdropClick
        open={isAcceptOpen}
        onClose={() => {
          this.setState({ isAcceptOpen: false });
        }}
      >
        <DialogTitle id="clinic-popup">
          Are you sure want to accept ?
        </DialogTitle>
        <DialogContent>
          <FormControlLabel
            label="Is Penalise?"
            control={
              <Checkbox
                size="small"
                color="primary"
                checked={this.state.isPenalise}
                onChange={(e) => {
                  this.setState({ isPenalise: e.target.checked });
                }}
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            onClick={() => this.AcceptOrder(acceptObj)}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
          <Button
            style={{ backgroundColor: "#f50057", color: "white" }}
            onClick={() => {
              this.setState({
                isAcceptOpen: false,
                isPenalise: false,
                acceptObj: null,
              });
            }}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderRejectOrderPopup = () => {
    const { isRejectOpen, data, acceptObj } = this.state;
    return (
      <Dialog
        disableBackdropClick
        open={isRejectOpen}
        onClose={() => {
          this.setState({ isRejectOpen: false });
        }}
      >
        <DialogTitle id="clinic-popup">
          Are you sure want to Reject ?
        </DialogTitle>
        <DialogContent style={{ width: "600px" }}>
          <div className="form-group">
            <label className="inputLabel">Actual Cancel Type</label>
            <br />
            <Select
              style={{ width: "500px" }}
              value={this.state.actualCancelReason}
              onChange={(e) => {
                this.setState({ actualCancelReason: e.target.value });
              }}
            >
              <option value="1">Cancel by Phlebo</option>
              <option value="2">Cancel by Customer</option>
              <option value="3">Reschedule by Phlebo</option>
              <option value="4">Reschedule by Customer</option>
              <option value="6">Duplicate Order</option>
              <option value="5">
                Re-assign (Same/Different Phlebo) Goes to In-Review Bucket
              </option>
            </Select>
            <br />
            <Grid item xs={12}>
              <TextField
                id="reasonRemark"
                name="reasonRemark"
                label="Actual Cancel Remarks"
                onChange={(e) => {
                  this.setState({ reasonRemark: e.target.value });
                }}
                fullWidth
              />
            </Grid>
          </div>
          <FormControlLabel
            label="Is Penalise?"
            control={
              <Checkbox 
                size="small"
                color="primary"
                checked={this.state.isPenalise}
                onChange={(e) => {
                  this.setState({ isPenalise: e.target.checked });
                }}
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            onClick={() => this.RejectCROrder(acceptObj)}
            variant="contained"
            color="primary"
          >
            Ok
          </Button>
          <Button
            style={{ backgroundColor: "#f50057", color: "white" }}
            onClick={() => {
              this.setState({
                isRejectOpen: false,
                isPenalise: false,
                acceptObj: null,
              });
            }}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderPagination = (isKYC) => {
    return (
      <div className="mb-30">
        <Grid container spacing={5}>
          <Grid item xs={9} />
          <Grid item xs={3}>
            <Button
              style={{ marginRight: "20px" }}
              size="medium"
              color="primary"
              variant="outlined"
              disabled={this.state.offset === 0}
              onClick={() => {
                this.setState({ offset: this.state.offset - 10 }, () => {
                  this.getOrders();
                });
              }}
            >
              {"< Previous"}
            </Button>
            <Button
              size="medium"
              color="primary"
              variant="outlined"
              disabled={!this.props.hasMore}
              onClick={() => {
                this.setState({ offset: this.state.offset + 10 }, () => {
                  this.getOrders();
                });
              }}
            >
              {"Next >"}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  };

  renderFormFields = (isPartner) => {
    const { isError } = this.state;
    const { data, isMobile } = this.state;
    const user = getUser();
    const mode = PAYMENT_MODE.filter((el) => el.id === data.paymentMode);
    const orderType = ORDER_TYPE.filter((el) => {
      return el.id === data.order_type;
    });
    const orderExecType = ORDER_EXECUTION_TYPE.filter((el) => {
      return el.id === data.order_execution_type;
    });
    const assignedStatus = ORDER_ASSIGNED_STATUS.filter(
      (el) => el.id === data.orderAssignedStatus
    );

    if (Object.keys(data).length === 0) return;

    return (
      <React.Fragment>
        <Grid container spacing={1}>
          <Grid item xs={isMobile ? 6 : 3}>
            <div className="mb-20">Order No: {data.order_seq}</div>
            <div className="mb-20">
              Vendor Order No: {data.vendor_order_number}
            </div>
          </Grid>
          <Grid item xs={isMobile ? 6 : 2}>
            <div className="mb-20"> Visit Type: {data.visit_type} </div>
            <div className="mb-20">
              {" "}
              Is Dedicated: {data.is_dedicated ? "Yes" : "No"}{" "}
            </div>
          </Grid>
          <Grid item xs={isMobile ? 6 : 4}>
            <div className="mb-20">
              Order Type: {orderType.length > 0 ? orderType[0].name : "-"}
            </div>
            <div className="mb-20">
              Order Execution Type:{" "}
              {orderExecType.length > 0 ? orderExecType[0].name : "-"}
            </div>
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <div className="mb-20">
              Created: {moment(data.createdTime).format("D MMM, YY h:mm A")}
            </div>
            {assignedStatus.length > 0 && (
              <div className="mb-20">
                <span className="status-box">
                  {assignedStatus[0].name.replace(/_/g, " ")}{" "}
                </span>{" "}
              </div>
            )}
          </Grid>
        </Grid>
        {!isPartner && <> <h2 style={user.isShowDoctors ? { color: "Blue" } : null}>
          {" "}
          Order Priority{" "}
        </h2> <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Order Priority</InputLabel>
                <Select
                  fullWidth
                  value={data.orderPriority}
                  onChange={(e) => {
                    this.handleFormChange(e, "orderPriority");
                  }}
                >
                  {(ORDER_PRIORITY || []).map((priority, index) => (
                    <option key={`mode-${priority.id}`} value={priority.name}>
                      {" "}
                      {priority.name}{" "}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>

          </Grid></>}
        <h2 style={user.isShowDoctors ? { color: "Blue" } : null}>
          {" "}
          Customer Details{" "}
        </h2>
        <Grid container spacing={1}>
          <Grid item xs={isMobile ? 6 : 3}>
            <TextField
              fullWidth
              label="Customer Name"
              size="small"
              value={data.customer_name}
              onChange={(e) => this.handleFormChange(e, "customer_name")}
              error={isError && data.customer_name === ""}
              helperText={
                isError && data.customer_name === ""
                  ? "Please enter a first name"
                  : " "
              }
            />
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <TextField
              fullWidth
              label="Customer Contact"
              size="small"
              value={data.customer_contact}
              onChange={(e) => this.handleFormChange(e, "customer_contact")}
              error={isError && data.customer_contact === ""}
              helperText={
                isError && data.customer_contact === ""
                  ? "Please enter a last name"
                  : " "
              }
            />
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <TextField
              fullWidth
              label="Customer Email"
              size="small"
              value={data.customer_email}
              error={isError && data.customer_email === ""}
              helperText={
                isError && data.customer_email === ""
                  ? "Please enter email"
                  : " "
              }
              onChange={(e) => this.handleFormChange(e, "customer_email")}
            />
          </Grid>
          <Grid item xs={isMobile ? 6 : 1}>
            <TextField
              fullWidth
              label="Gender"
              size="small"
              value={data.gender}
              onChange={(e) => this.handleFormChange(e, "gender")}
            />
          </Grid>
          <Grid item xs={isMobile ? 6 : 1}>
            <TextField
              fullWidth
              label="Age"
              size="small"
              value={data.age}
              onChange={(e) => this.handleFormChange(e, "age")}
            />
          </Grid>
        </Grid>

        {user.isShowDoctors && (
          <React.Fragment>
            <h2 style={user.isShowDoctors ? { color: "Blue" } : null}>
              {" "}
              Doctor Details{" "}
            </h2>
            <Grid container spacing={1}>
              <Grid item xs={isMobile ? 6 : 3}>
                <InputLabel>Doctor Name</InputLabel>
                <Select
                  fullWidth
                  value={data.doctorId || ""}
                  onChange={(e) => {
                    this.handleFormChange(e, "doctorId");
                  }}
                >
                  {(this.props.doctorsList || []).map((doc, index) => (
                    <option key={`doc-${doc.id}`} value={doc.id}>
                      {" "}
                      {doc.name}{" "}
                    </option>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={isMobile ? 6 : 3}>
                <TextField
                  fullWidth
                  label="Patient UHID"
                  size="small"
                  value={data.patientUHID}
                  error={isError && data.patientUHID === ""}
                  helperText={
                    isError && data.patientUHID === ""
                      ? "Please enter patient UH id"
                      : " "
                  }
                  onChange={(e) => this.handleFormChange(e, "patientUHID")}
                />
              </Grid>
              <Grid item xs={isMobile ? 6 : 3}>
                <div>Is Cardio?</div>
                <Switch
                  color="primary"
                  name="isActive"
                  size="small"
                  checked={data.isCardio}
                  onChange={(e) => {
                    const { data, updatedData } = this.state;
                    data["isCardio"] = e.target.checked;
                    updatedData["isCardio"] = e.target.checked;
                    this.setState({ data, updatedData });
                  }}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        )}

        <h2> Address Details </h2>
        <Grid container spacing={3}>
          <Grid item xs={isMobile ? 6 : 3}>
            <TextField
              fullWidth
              size="small"
              value={data.labName}
              label="Lab Name"
              onChange={(e) => this.handleFormChange(e, "labName")}
            />
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <TextField
              disabled
              fullWidth
              label="LGP Name"
              value={data.lgpName}
              onChange={(e) => this.handleFormChange(e, "lgpName")}
            />
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <label> Earliest Pickup </label>
            <DatePicker
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
              placeholderText="Select a date"
              selected={data.earliest_pickup_date}
              onChange={(date) => {
                const { data, updatedData } = this.state;
                data.earliest_pickup_date = new Date(date).getTime();
                updatedData.earliest_pickup_date = new Date(date).getTime();
                this.setState({ data, updatedData });
              }}
            />
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <label> Latest Pickup </label>
            <DatePicker
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
              placeholderText="Select a date"
              selected={data.latest_pickup_date}
              onChange={(date) => {
                const { updatedData } = this.state;
                data.latest_pickup_date = new Date(date).getTime();
                updatedData.latest_pickup_date = new Date(date).getTime();
                this.setState({ data, updatedData });
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={isMobile ? 6 : 3}>
            <TextField
              fullWidth
              size="small"
              label="Address 1"
              value={data.ship_to_address1}
              onChange={(e) => this.handleFormChange(e, "ship_to_address1")}
            />
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <TextField
              fullWidth
              size="small"
              label="Address 2"
              value={data.ship_to_address2}
              onChange={(e) => this.handleFormChange(e, "ship_to_address2")}
            />
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <TextField
              fullWidth
              size="small"
              label="Landmark"
              value={data.landmark}
              onChange={(e) => this.handleFormChange(e, "landmark")}
            />
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <TextField
              fullWidth
              size="small"
              label="City"
              value={data.ship_to_city}
              onChange={(e) => this.handleFormChange(e, "ship_to_city")}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={isMobile ? 6 : 3}>
            <TextField
              fullWidth
              size="small"
              label="State"
              value={data.ship_to_state}
              onChange={(e) => this.handleFormChange(e, "ship_to_state")}
            />
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <TextField
              fullWidth
              InputProps={{ inputProps: { minLength: 6, maxLength: 6 } }}
              size="small"
              label="Zipcode"
              value={data.ship_to_zip_code}
              onChange={(e) => this.handleFormChange(e, "ship_to_zip_code")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={isMobile ? 6 : 3}>
            <TextField
              fullWidth
              size="small"
              type="number"
              label="Latitude"
              value={data.ship_to_latitude}
              onChange={(e) => this.handleFormChange(e, "ship_to_latitude")}
            />
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <TextField
              fullWidth
              size="small"
              type="number"
              label="Longitude"
              value={data.ship_to_longitude}
              onChange={(e) => this.handleFormChange(e, "ship_to_longitude")}
            />
          </Grid>
        </Grid>

        <h2> Payment Details </h2>
        <Grid container spacing={3}>
          <Grid item xs={isMobile ? 6 : 3}>
            <label> Payment Mode </label>
            <Select
              fullWidth
              value={data.payment_mode}
              onChange={(e) => {
                this.handleFormChange(e, "payment_mode");
              }}
            >
              {(PAYMENT_MODE || []).map((mode, index) => (
                <option key={`mode-${mode.id}`} value={mode.id}>
                  {" "}
                  {mode.name}{" "}
                </option>
              ))}
            </Select>
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <TextField
              fullWidth
              label="Prepaid Amount"
              value={data.prepaid_amount}
              onChange={(e) => this.handleFormChange(e, "prepaid_amount")}
            />
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <TextField
              fullWidth
              label="Amount To Collect"
              value={data.amount_to_collect}
              onChange={(e) => this.handleFormChange(e, "amount_to_collect")}
            />
          </Grid>
        </Grid>

        <h2> Test Details </h2>
        <TableContainer component={Paper} className="mb-30">
          <Table size="small" aria-label="order table">
            <TableHead>
              <TableRow>
                <TableCell> Seq # </TableCell>
                <TableCell> ID </TableCell>
                <TableCell> Name </TableCell>
                <TableCell> Pickup Comments </TableCell>
                <TableCell> Qty </TableCell>
                <TableCell> Description </TableCell>
                <TableCell> Tube </TableCell>
                <TableCell> Price </TableCell>
                <TableCell> Comments </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data.test_details || []).length <= 0 && (
                <TableRow>
                  <TableCell component="th" scope="row">
                    {" "}
                    No order found.{" "}
                  </TableCell>
                </TableRow>
              )}
              {(data.test_details || []).map((order, index) => {
                return (
                  <TableRow key={`test-${index + 1}`}>
                    <TableCell>
                      <TextField
                        style={{ width: 20 }}
                        fullWidth
                        value={order.test_sequence}
                        onChange={(e) =>
                          this.handleFormTestDetailsChange(
                            e,
                            "test_sequence",
                            index
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        style={{ width: 20 }}
                        value={order.test_id}
                        onChange={(e) =>
                          this.handleFormTestDetailsChange(e, "test_id", index)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Autocomplete
                        freeSolo
                        style={{ width: 250 }}
                        disableClearable
                        inputValue={order.test_name}
                        onChange={(e, value) => {
                          this.handleAutoCompleteChange(
                            value,
                            "test_name",
                            index
                          );
                        }}
                        options={this.state.availableTestDetails.map(
                          (option) => option.item_name
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            onChange={(e) =>
                              this.handleFormTestDetailsChange(
                                e,
                                "test_name",
                                index
                              )
                            }
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={order.pickup_comments}
                        onChange={(e) =>
                          this.handleFormTestDetailsChange(
                            e,
                            "pickup_comments",
                            index
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        style={{ width: 30 }}
                        type="number"
                        value={order.quantity}
                        onChange={(e) =>
                          this.handleFormTestDetailsChange(e, "quantity", index)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={order.test_description}
                        onChange={(e) =>
                          this.handleFormTestDetailsChange(
                            e,
                            "test_description",
                            index
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={order.test_tube}
                        onChange={(e) =>
                          this.handleFormTestDetailsChange(
                            e,
                            "test_tube",
                            index
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={order.unit_price}
                        onChange={(e) =>
                          this.handleFormTestDetailsChange(
                            e,
                            "unit_price",
                            index
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={order.comments}
                        onChange={(e) =>
                          this.handleFormTestDetailsChange(e, "comments", index)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        edge="end"
                        color="inherit"
                        style={{ width: 20 }}
                        size="small"
                        onClick={() => {
                          const { data, updatedData } = this.state;
                          const dataCopy = JSON.parse(JSON.stringify(data));
                          (data.test_details || []).splice(index, 1);
                          updatedData.test_details = data.test_details;
                          this.setState({ data, updatedData }, () =>
                            this.subAmount(index, dataCopy)
                          );
                        }}
                      >
                        <Clear />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            const { data, updatedData } = this.state;
            if (!data.test_details) {
              data.test_details = [];
            }
            (data.test_details || []).push({
              quantity: 1,
              test_description: "",
              test_name: "",
              test_tube: "",
              unit_price: "",
              isAdd: true,
            });
            updatedData.test_details = data.test_details;
            this.setState({ data, updatedData }, () => this.updateAmount());
          }}
        >
          Add
        </Button>
        <h2> Barcodes </h2>
        <TableContainer component={Paper} className="mb-30">
          <Table size="small" aria-label="order table">
            <TableHead>
              <TableRow>
                <TableCell> Barcode</TableCell>
                <TableCell> Barcode Type</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data.barcodes || []).length <= 0 && (
                <TableRow>
                  <TableCell component="th" scope="row">
                    {" "}
                    No Barcode found.{" "}
                  </TableCell>
                </TableRow>
              )}
              {(data.barcodes || []).map((barcode, index) => {
                return (
                  <TableRow key={`test-${index + 1}`}>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={barcode.barcode}
                        onChange={(e) => this.handleFormBarcodeChange(e, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={barcode.barcodeType}
                        onChange={(e) =>
                          this.handleFormBarcodeTypeChange(e, index)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        edge="end"
                        color="inherit"
                        style={{ width: 20 }}
                        size="small"
                        onClick={() => {
                          const { data, updatedData } = this.state;
                          (data.barcodes || []).splice(index, 1);
                          updatedData.barcodes = data.barcodes;
                          this.setState({ data, updatedData });
                        }}
                      >
                        <Clear />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            const { data, updatedData } = this.state;
            let barcodesArray = data.barcodes || [];
            barcodesArray.push({ barcode: "", barcodeType: "" });
            this.setState({
              data: { ...data, barcodes: barcodesArray },
              updatedData: { ...updatedData, barcodes: barcodesArray },
            });
          }}
        >
          Add
        </Button>
        <h2> Package Details </h2>
        <Grid container spacing={3}>
          <Grid item xs={isMobile ? 6 : 3}>
            <TextField
              disabled
              fullWidth
              label="Package Name"
              value={data.packageName}
              onChange={(e) => this.handleFormChange(e, "packageName")}
            />
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <TextField
              disabled
              fullWidth
              label="Package Description"
              value={data.packageDescription}
              onChange={(e) => this.handleFormChange(e, "packageDescription")}
            />
          </Grid>
          <Grid item xs={isMobile ? 6 : 5}>
            <TextField
              disabled
              fullWidth
              label="comments"
              value={data.comments}
              onChange={(e) => this.handleFormChange(e, "comments")}
            />
          </Grid>
        </Grid>

        {data.consumablesList &&
          data.consumablesList.length > 0 &&
          user.userType === 1 && (
            <div>
              <h2> Consumables </h2>
              <TableContainer component={Paper} className="mb-30">
                <Table size="small" aria-label="order table">
                  <TableHead>
                    <TableRow>
                      <TableCell> Name </TableCell>
                      <TableCell> Qty </TableCell>
                      <TableCell> Bill Price </TableCell>
                      <TableCell> Bill Price GST </TableCell>
                      <TableCell> Landing Price </TableCell>
                      <TableCell> Landing Price GST </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(data.consumablesList || []).length <= 0 && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {" "}
                          No consumables found.{" "}
                        </TableCell>
                      </TableRow>
                    )}
                    {(data.consumablesList || []).map((order, index) => {
                      return (
                        <TableRow key={`consumable-${index + 1}`}>
                          <TableCell> {order.name} </TableCell>
                          <TableCell> {order.quantity} </TableCell>
                          <TableCell> {order.billingPrice} </TableCell>
                          <TableCell> {order.billingPriceGST} </TableCell>
                          <TableCell> {order.landingPrice} </TableCell>
                          <TableCell> {order.landingPriceGST} </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
      </React.Fragment>
    );
  };

  renderErrorPopup = () => {
    const { isUploadError, uploadError } = this.state;
    return (
      <Dialog
        disableBackdropClick
        open={isUploadError}
        onClose={() => {
          this.setState({ isUploadError: false });
        }}
      >
        <DialogTitle id="clinic-popup">{"Upload Errors"}</DialogTitle>
        <DialogContent style={{ width: "600px", height: "400px" }}>
          {(uploadError || []).map((err, index) => {
            return (
              <Typography
                key={`err-${index + 1}`}
                variant="body2"
                style={{ color: "red" }}
                gutterBottom
              >
                {" "}
                {err}{" "}
              </Typography>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.setState({ isUploadError: false });
            }}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderPopup = () => {
    const { isOpen, data } = this.state;
    return (
      <Dialog
        disableBackdropClick
        open={isOpen}
        onClose={() => {
          this.setState({ isOpen: false });
        }}
      >
        <DialogTitle id="clinic-popup">
          {data.isEdit ? "Edit Phlebo" : "Create New Phlebo"}
        </DialogTitle>
        <DialogContent style={{ width: "600px", height: "400px" }}>
          {this.renderFormFields(data)}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.validateData}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button
            onClick={() => {
              this.setState({ isOpen: false });
              this.resetPopup();
            }}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderAssignPopup = () => {
    const { isOrderAssign, data, phleboId, assignUpload } = this.state;
    const { plebos } = this.props;

    const selectedtype = [...ORDER_CONSTANTS, ...PAST_TABS].find((el) => {
      return el.id === this.state.type;
    });
    const isPartner = getUser().userType === 2;

    return (
      <Dialog
        disableBackdropClick
        open={isOrderAssign}
        onClose={() => {
          this.setState({ isOrderAssign: false });
        }}
      >
        <DialogTitle id="clinic-popup">Assign Phlebo</DialogTitle>
        <DialogContent style={{ width: "600px", height: "400px" }}>
          <div style={{ marginBottom: "20px", fontSize: "13px" }}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <label> Name </label>
                <TextField
                  fullWidth
                  size="small"
                  style={{ fontSize: "13px" }}
                  value={assignUpload.name}
                  onChange={(e) => {
                    const { assignUpload } = this.state;
                    assignUpload.name = e.target.value;
                    this.setState({ assignUpload });
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <label> Email </label>
                <TextField
                  fullWidth
                  size="small"
                  style={{ fontSize: "13px" }}
                  value={assignUpload.email}
                  onChange={(e) => {
                    const { assignUpload } = this.state;
                    assignUpload.email = e.target.value;
                    this.setState({ assignUpload });
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <label> Purpose </label>
                <TextField
                  fullWidth
                  size="small"
                  style={{ fontSize: "13px" }}
                  value={assignUpload.purpose}
                  onChange={(e) => {
                    const { assignUpload } = this.state;
                    assignUpload.purpose = e.target.value;
                    this.setState({ assignUpload });
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  edge="end"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    this.uploadAssignLink.click();
                  }}
                >
                  <PublishOutlined /> Upload
                </IconButton>
                <input
                  type="file"
                  style={{ display: "none" }}
                  className="inputFileUpload"
                  onChange={(e) => {
                    this.uploadAssignedOrders(e);
                  }}
                  ref={(ref) => {
                    this.uploadAssignLink = ref;
                  }}
                />
              </Grid>
            </Grid>
          </div>
          {Object.keys(selectedtype || {}).length > 0 &&
            selectedtype.hasAssign &&
            this.state.selectedOrders.length > 0 &&
            !isPartner && (
              <React.Fragment>
                <div className="search-clinic search-orders pr mb-30">
                  <span className="fa fa-search" />
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    placeholder="Search Phlebo…"
                    value={this.state.searchPhelbo}
                    onChange={(e) => {
                      this.setState({ searchPhelbo: e.target.value }, () => { this.getPlebos();});
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                         {this.state.searchPhelbo ? <IconButton
                             onClick={() => {
                              this.setState({ searchPhelbo: "" }, () => {
                                this.getPlebos();
                              });
                            }}
                          >
                          <Close/>
                          </IconButton> : null }
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <TableContainer component={Paper} className="mb-30">
                  <Table size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>Image</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Phone number</TableCell>
                        <TableCell>Address</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(plebos || []).length <= 0 && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {" "}
                            No plebos found.{" "}
                          </TableCell>
                        </TableRow>
                      )}
                      {(plebos || []).map((plebo) => (
                        <TableRow key={plebo.phleboId}>
                          <TableCell>
                            <Checkbox
                              size="small"
                              color="primary"
                              checked={phleboId === plebo.phleboId}
                              onClick={() => {
                                this.setState({
                                  phleboId: plebo.phleboId,
                                  firstName: plebo.firstName,
                                  lastName: plebo.lastName,
                                });
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <a href={plebo.imageUrl} download>
                              <Avatar
                                alt="img"
                                size="small"
                                src={plebo.imageUrl}
                              />
                            </a>
                          </TableCell>
                          <TableCell>
                            {plebo.firstName} {plebo.lastName}
                          </TableCell>
                          <TableCell>{plebo.mobileNumber}</TableCell>
                          <TableCell>
                            {plebo.addressField1} {plebo.address2}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <div className="dateFilters">
                      <div className="dateLabel">Slot From</div>
                      <DatePicker
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        placeholderText="Select a date"
                        selected={this.state.slotFrom}
                        onChange={(date) => {
                          this.setState({ slotFrom: new Date(date).getTime() });
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="dateFilters">
                      <div className="dateLabel">Slot To</div>
                      <DatePicker
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        placeholderText="Select a date"
                        selected={this.state.slotTo}
                        onChange={(date) => {
                          this.setState({ slotTo: new Date(date).getTime() });
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div>
                      <label>Override Auto Assignment?</label>
                      <Switch
                        color="primary"
                        name="isActive"
                        size="small"
                        checked={this.state.isOverride}
                        onChange={(e) => {
                          this.setState({ isOverride: e.target.checked });
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}

          <Grid container spacing={2}>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    checked={this.state.version === 1}
                    onChange={(e) => {
                      this.setState({ version: 1 });
                    }}
                  />
                }
                label="Radius Based"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    checked={this.state.version === 2}
                    onChange={(e) => {
                      this.setState({ version: 2 });
                    }}
                  />
                }
                label="Area Based"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    checked={this.state.version === 3}
                    onChange={(e) => {
                      this.setState({ version: 3 });
                    }}
                  />
                }
                label="Zipcode Based"
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    checked={this.state.version === 4}
                    onChange={(e) => {
                      this.setState({ version: 4, sub_version: 0 });
                    }}
                  />
                }
                label="Manual"
              />
            </Grid>
            {this.state.version !== 4 && (
              <React.Fragment>
                <Grid item xs={5}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={this.state.sub_version === 1}
                        onChange={(e) => {
                          this.setState({ sub_version: 1 });
                        }}
                      />
                    }
                    label="Get Recommendations"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={this.state.sub_version === 2}
                        onChange={(e) => {
                          this.setState({ sub_version: 2 });
                        }}
                      />
                    }
                    label="Get New Slots"
                  />
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {Object.keys(selectedtype || {}).length > 0 &&
            selectedtype.hasAssign &&
            this.state.selectedOrders.length > 0 &&
            !isPartner && (
              <Button
                onClick={this.assignOrder}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            )}
          <Button
            onClick={() => {
              this.setState({
                isOrderAssign: false,
                phleboId: "",
                firstName: "",
                lastName: "",
              });
              this.getPlebosForFilter();
            }}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderAssignDoctor = () => {
    const { isDoctorAssign, data, doctorId, assignUpload, doctors } = this.state;
    const { plebos } = this.props;

    const selectedtype = [...ORDER_CONSTANTS, ...PAST_TABS].find((el) => {
      return el.id === this.state.type;
    });
    const isPartner = getUser().userType === 2;

    return (
      <Dialog
        disableBackdropClick
        open={isDoctorAssign}
        onClose={() => {
          this.setState({ isDoctorAssign: false });
        }}
      >
        <DialogTitle id="clinic-popup">Assign Doctor</DialogTitle>
        <DialogContent style={{ width: "600px", height: "400px" }}>
          {Object.keys(selectedtype || {}).length > 0 &&
            selectedtype.hasAssign &&
            this.state.selectedOrders.length > 0 &&
            !isPartner && (
              <React.Fragment>
                <div className="search-clinic search-orders pr mb-30">
                  <span className="fa fa-search" />
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    placeholder="Search Doctor…"
                    value={this.state.searchDoctor}
                    onChange={(e) => {
                      this.setState({ searchDoctor: e.target.value }, () => { this.getAllDoctors();});
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                         {this.state.searchDoctor ? <IconButton
                             onClick={() => {
                              this.setState({ searchDoctor: "" }, () => {
                                this.getAllDoctors();
                              });
                            }}
                          >
                          <Close/>
                          </IconButton> : null }
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <TableContainer component={Paper} className="mb-30">
                  <Table size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>Image</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Phone number</TableCell>
                        <TableCell>Qualification</TableCell>
                        <TableCell>Specialization</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(doctors || []).length <= 0 && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {" "}
                            No plebos found.{" "}
                          </TableCell>
                        </TableRow>
                      )}
                      {(doctors || []).map((doctor) => (
                        <TableRow key={doctor.doctorId}>
                          <TableCell>
                            <Checkbox
                              size="small"
                              color="primary"
                              checked={doctorId === doctor.doctorId}
                              onClick={() => {
                                this.setState({
                                 doctorId: doctor.doctorId,
                                  firstName: doctor.firstName,
                                  lastName: doctor.lastName,
                                });
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <a href={doctor.imageUrl} download>
                              <Avatar
                                alt="img"
                                size="small"
                                src={doctor.imageUrl}
                              />
                            </a>
                          </TableCell>
                          <TableCell>
                            {doctor.firstName} {doctor.lastName}
                          </TableCell>
                          <TableCell>{doctor.contactNumber}</TableCell>
                          <TableCell>
                            {doctor.qualification} 
                          </TableCell>
                          <TableCell>
                            {doctor.specialization} 
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </React.Fragment>
            )}
        </DialogContent>
        <DialogActions>
              <Button
                onClick={this.assignDoctor}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
          <Button
            onClick={() => {
              this.setState({
                isDoctorAssign: false,
                phleboId: "",
                firstName: "",
                lastName: "",
              });
              this.getPlebosForFilter();
            }}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderVitals = (isPartner) => {
    const { vitals } = this.state;
    const { videoToken } = this.state;
    const { data } = this.state;

  
    return (
      <React.Fragment>
        <Grid container spacing={2} style={{textAlign:'center'}}>
            <Grid item xs={3}>
              <Typography><span style={{fontWeight:'bold'}}>Phlebo: </span>{data.firstName +
                      " " +
                      data.lastName +
                      "--" +
                      data.mobileNumber}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography ><span style={{fontWeight:'bold'}}>Order No: </span>{data.order_seq}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography ><span style={{fontWeight:'bold'}}>Custormer Name: </span>{data.customerName}</Typography>
            </Grid>
            </Grid>
        <Grid container spacing={2} style={{marginTop:'1em'}}>
          <Grid item xs={12} md={6} lg={4}>
            <div style={{ padding: 5 }}>
              <Card className="p-5">
                {vitals.customer_Name ? <Grid item xs={12} >
                  <Grid container spacing={2} > 
                  <Grid item xs={8} textAlign='center'><Typography variant={'h6'} weight='bold' sx={{ textAlign: 'center' }}>Vitals</Typography></Grid>
                  <Grid item xs={4} style={{textAlign:'right'}}>
                    <IconButton onClick={() => { this.handleRefreshVitals() }}><Refresh /></IconButton>
                  </Grid>
                  </Grid>

                  <FormControl fullWidth disabled>
                    <FormHelperText id="standard-weight-helper-text">Blood Pressure(Reading-1)</FormHelperText>
                    <OutlinedInput
                      id="standard-adornment-weight"
                      value={`${vitals.bp1_Systolic}/${vitals.bp1_Diastolic}`}
                      startAdornment={<InputAdornment position="start"><img src={BloodPressure} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                      endAdornment={<InputAdornment position="end">mmHg</InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />

                  </FormControl>
                  <FormControl fullWidth disabled>
                    <FormHelperText id="standard-weight-helper-text">Blood Pressure(Reading-2)</FormHelperText>
                    <OutlinedInput

                      id="standard-adornment-weight"
                      value={`${vitals.bp2_Systolic}/${vitals.bp2_Diastolic}`}
                      startAdornment={<InputAdornment position="start"><img src={BloodPressure} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                      endAdornment={<InputAdornment position="end">mmHg</InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />

                  </FormControl>
                  <FormControl fullWidth disabled>
                    <FormHelperText id="standard-weight-helper-text">Blood Pressure(Reading-3)</FormHelperText>
                    <OutlinedInput

                      id="standard-adornment-weight"
                      value={`${vitals.bp3_Systolic}/${vitals.bp3_Diastolic}`}
                      startAdornment={<InputAdornment position="start"><img src={BloodPressure} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                      endAdornment={<InputAdornment position="end">mmHg</InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />

                  </FormControl>
                  <FormControl fullWidth disabled>
                    <FormHelperText id="standard-weight-helper-text">Height</FormHelperText>
                    <OutlinedInput

                      id="standard-adornment-weight"
                      value={vitals.height}
                      startAdornment={<InputAdornment position="start"><img src={Height} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                      endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth disabled>
                    <FormHelperText id="standard-weight-helper-text">Weight</FormHelperText>
                    <OutlinedInput

                      id="standard-adornment-weight"
                      value={vitals.weight}
                      startAdornment={<InputAdornment position="start"><img src={Weight} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                      endAdornment={<InputAdornment position="end">kgs</InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth disabled>
                    <FormHelperText id="standard-weight-helper-text">Chest Expiration</FormHelperText>
                    <OutlinedInput
                      id="standard-adornment-weight"
                      value={vitals.chest_Expiration}
                      startAdornment={<InputAdornment position="start"><img src={Heart} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                      endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />

                  </FormControl>

                  <FormControl fullWidth disabled>
                    <FormHelperText id="standard-weight-helper-text">Chest Inspiration</FormHelperText>
                    <OutlinedInput

                      id="standard-adornment-weight"
                      value={vitals.chest_Inspiration}
                      startAdornment={<InputAdornment position="start"><img src={Heart} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                      endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />

                  </FormControl>
                  <FormControl fullWidth disabled >
                    <FormHelperText id="standard-weight-helper-text">Abdomen Girth</FormHelperText>
                    <OutlinedInput

                      id="standard-adornment-weight"
                      value={vitals.abdomen_Girth}
                      startAdornment={<InputAdornment position="start"><img src={Abdomen} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                      endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth disabled >
                    <FormHelperText id="standard-weight-helper-text">Hip</FormHelperText>
                    <OutlinedInput

                      id="standard-adornment-weight"
                      value={vitals.hip}
                      startAdornment={<InputAdornment position="start"><img src={Waist} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                      endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth disabled >
                    <FormHelperText id="standard-weight-helper-text">Waist</FormHelperText>
                    <OutlinedInput

                      id="standard-adornment-weight"
                      value={vitals.waist}
                      startAdornment={<InputAdornment position="start"><img src={Waist} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                      endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth disabled >
                    <FormHelperText id="standard-weight-helper-text">Hip Waist Ratio</FormHelperText>
                    <OutlinedInput

                      id="standard-adornment-weight"
                      value={vitals.hipWaistRatio}
                      startAdornment={<InputAdornment position="start"><img src={Waist} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                      endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth disabled>
                    <FormHelperText id="standard-weight-helper-text">Pulse</FormHelperText>
                    <OutlinedInput

                      id="standard-adornment-weight"
                      value={vitals.pulse}
                      startAdornment={<InputAdornment position="start"><img src={Pulse} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                      endAdornment={<InputAdornment position="end">bpm</InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                </Grid> : <Grid item xs={12} container spacing={1}>
                  <Grid item xs={8}>
                  <Typography variant='h6' weight='bold'>Vitals not submitted</Typography>
                  </Grid>
                  <Grid item xs={4} style={{textAlign:'right'}}>
                    <IconButton onClick={() => { this.handleRefreshVitals() }}><Refresh /></IconButton>
                  </Grid>
                  </Grid>}
              </Card>
            </div>
          </Grid>
         {!isPartner && <Grid item xs={12} md={8} lg={8}>
            <Agora fileData={this.callBackFunction} fileSent={this.callBackFuntion} joinStatus={this.callBackJoinStatus} tempToken={videoToken.token} cname={data.order_seq} />
          </Grid>} 
        </Grid>
      </React.Fragment>
    )
  }

  renderReports = () => {
    const { labReport, addReport } = this.state;
    const { orderData, labReports } = this.props;
    const users = getUser();

    return (
      <React.Fragment>
        <h3>
          {" "}
          Lab Reports
          <Button
            variant="contained"
            style={{ marginLeft: "20px" }}
            color="primary"
            onClick={() => {
              this.setState({ addReport: !this.state.addReport });
            }}
          >
            {" "}
            Add Report{" "}
          </Button>{" "}
        </h3>
        {addReport && (
          <div className="mb-20">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <label> Name<span style={{color:'red',fontSize:'15px',paddingLeft: '5px'}}>*</span> </label>
                <TextField
                  fullWidth
                  required
                  size="small"
                  value={labReport.name}
                  onChange={(e) => {
                    const { labReport } = this.state;
                    labReport.name = e.target.value;
                    this.setState({ labReport });
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <label> Image<span style={{color:'red',fontSize:'15px',paddingLeft: '5px'}}>*</span></label>
                <input
                  type="file"
                  className="inputFileUpload"
                  accept='.pdf'
                  onChange={(e) => {
                    this.uploadImage(e.target.files, true);
                  }}
                  ref={(ref) => {
                    this.input = ref;
                  }}
                />
                {labReport.url && labReport.url.length > 0 && (
                  <a
                    href={labReport.url}
                    style={{ color: "blue", fontSize: "15px" }}
                  >
                    Reports Uploaded
                  </a>
                )}
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.addNewReport}
                >
                  {" "}
                  Save{" "}
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    this.setState({ addReport: false, labReport: {} });
                  }}
                >
                  {" "}
                  Cancel{" "}
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
        {(labReports || []).length > 0 && (
          <TableContainer component={Paper} className="mb-30">
            <Table size="small" aria-label="order table">
              <TableHead>
                <TableRow>
                  <TableCell> Image </TableCell>
                  <TableCell> Name </TableCell>
                  <TableCell> Created </TableCell>
                  <TableCell> Action </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(labReports || []).map((lab, index) => {
                  return (
                    <TableRow key={`lab-${index + 1}`}>
                      <TableCell>
                        <a
                          href={users && users.userType === 1 ? lab.url : lab.pre_url}
                          style={{ color: "blue", fontSize: "10px" }}
                        >
                          <Avatar size="small" src={lab.url} alt="icon" />
                        </a>
                      </TableCell>
                      <TableCell> {lab.name} </TableCell>
                      <TableCell>
                        {" "}{moment(lab.created).format("Do MMM, YYYY h:mm A")}{" "}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          edge="end"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            this.setState({
                              isReportDelete: !this.state.isReportDelete,
                              deleteObj: { ...lab },
                            });
                          }}
                        >
                          <Clear />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </React.Fragment>
    );
  };

  renderLogDetails = (isPartner) => {
    const { orderData } = this.props;
    const type1 = FEEDBACK_TYPES.filter((el) => {
      return el.id === orderData.type1;
    });
    const type2 = FEEDBACK_TYPES.filter((el) => {
      return el.id === orderData.type2;
    });
    const type3 = FEEDBACK_TYPES.filter((el) => {
      return el.id === orderData.type3;
    });
    const payMode = PAYMENT_MODE.filter(
      (el) => el.id === orderData.finalPaymentMode
    );
    const cancelCode = ORDER_CANCEL_CODE.filter(
      (el) => el.id === orderData.orderCancelCode
    );
    const orderDetails = [
      orderData.tripStartTime,
      orderData.reachedCustomerLocationTime,
      orderData.sampleCollectedTime,
      orderData.phleboDepartedFromCustomerTime,
      orderData.labAcceptedTime,
      orderData.labRejectedTime,
    ];
    const orderDetailsLength = orderDetails.filter((elm) => elm).length;

    const orderObject = [
      {
        orderName: "Start Time",
        orderTime: orderData.tripStartTime
          ? moment(orderData.tripStartTime).format("Do MMM, YYYY h:mm A")
          : "",
      },
      {
        orderName: "Reached Time",
        orderTime: orderData.reachedCustomerLocationTime
          ? moment(orderData.reachedCustomerLocationTime).format(
            "Do MMM, YYYY h:mm A"
          )
          : "",
      },
      {
        orderName: "Sample Collected",
        orderTime: orderData.sampleCollectedTime
          ? moment(orderData.sampleCollectedTime).format("Do MMM, YYYY h:mm A")
          : "",
      },
      {
        orderName: "Phlebo Depart Time",
        orderTime: orderData.phleboDepartedFromCustomerTime
          ? moment(orderData.phleboDepartedFromCustomerTime).format(
            "Do MMM, YYYY h:mm A"
          )
          : "",
      },
      {
        orderName: "Lab Accept Time",
        orderTime: orderData.labAcceptedTime
          ? moment(orderData.labAcceptedTime).format("Do MMM, YYYY h:mm A")
          : "",
      },
      {
        orderName: "Lab Reject Time",
        orderTime: orderData.labRejectedTime
          ? moment(orderData.labRejectedTime).format("Do MMM, YYYY h:mm A")
          : "",
      },
    ];
    return (
      <div>
        <div
          style={{ borderBottom: "3px dashed #535050" }}
          className="section-divider"
        >
          <Grid
            style={{ marginLeft: "-68px", marginTop: "-46px" }}
            container
            spacing={2}
          >
            <div className="container-fluid">
              <br />
              <br />
              <ul className="list-unstyled multi-steps">
                {orderObject.map((order, x) => (
                  <>
                    <li
                      className={
                        orderDetailsLength !== 0
                          ? x + 1 == orderDetailsLength
                            ? "is-active"
                            : ""
                          : x == 0 && "is-active"
                      }
                    >
                      {order.orderName}
                      <p style={{ color: "#033d71", fontSize: "11px" }}>
                        {order.orderTime}
                      </p>
                    </li>
                  </>
                ))}
              </ul>
            </div>
          </Grid>
        </div>

        <div className="section-divider">
          <h3>Payment</h3>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <span className="logs-title"> Payment Amount: </span>
              <span> {orderData.finalPaymentAmount} </span>
            </Grid>
            <Grid item xs={4}>
              <span className="logs-title"> Payment Mode: </span>
              {payMode.length > 0 ? payMode[0].name : "-"}
            </Grid>
            <Grid item xs={4}>
              <span className="logs-title"> Payment Comments: </span>
              <span> {orderData.paymentPagecomments || "-"} </span>
            </Grid>
            <Grid item xs={4}>
              <span className="logs-title log-inline-title">
                {" "}
                Payment Proof:{" "}
              </span>
              <span className="log-inline-img">
                <Grid container xs={12}>
                  {orderData.finalPaymentProof &&
                    [orderData.finalPaymentProof].map((el, index) => (
                      <Grid
                        item
                        style={{ width: 100, position: "relative" }}
                        xs={3}
                      >
                        <Avatar
                          style={{ cursor: "pointer" }}
                          onClick={() => this.setState({ proofImages: [el] })}
                          src={el}
                          size="small"
                        />{" "}
                        <div
                          style={{
                            position: "absolute",
                            left: 45,
                            top: 0,
                            cursor: "pointer",
                          }}
                        >
                          X
                        </div>
                      </Grid>
                    ))}
                  <Grid item xs={4} style={{ marginLeft: 5, marginRight: 5 }}>
                    <input
                      style={{ fontSize: "unset", margin: 10 }}
                      type="file"
                      name="finalPaymentProof"
                      accept="image/*"
                      onChange={(e) => {
                        this.uploadProofImage(
                          e.target.files,
                          "finalPaymentProof",
                          1
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </span>
            </Grid>
          </Grid>
        </div>

        <div className="section-divider">
          <h3>TRF</h3>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <span className="logs-title log-inline-title">
                {" "}
                TRF Form Proof:{" "}
              </span>
              <span className="log-inline-img">
                <Grid container xs={12}>
                  {orderData.trfFormProof &&
                    [orderData.trfFormProof].map((el, index) => (
                      <Grid
                        item
                        style={{ width: 100, position: "relative" }}
                        xs={3}
                      >
                        <Avatar
                          style={{ cursor: "pointer" }}
                          onClick={() => this.setState({ proofImages: [el] })}
                          src={el}
                          size="small"
                        />{" "}
                        <div
                          style={{
                            position: "absolute",
                            left: 45,
                            top: 0,
                            cursor: "pointer",
                          }}
                          onClick={() => this.setState({proofImages:[]})}
                        >
                          X
                        </div>
                      </Grid>
                    ))}
                  <Grid item xs={4} style={{ marginLeft: 5, marginRight: 5 }}>
                    <input
                      style={{ fontSize: "unset", margin: 10 }}
                      type="file"
                      name="trfFormProof"
                      accept="image/*"
                      onChange={(e) => {
                        this.uploadProofImage(
                          e.target.files,
                          "trfFormProof",
                          4
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </span>
            </Grid>

            <Grid item xs={4}>
              <span className="logs-title log-inline-title"> Lab Proof: </span>
              <span className="log-inline-img">
                <Grid container xs={12}>
                  {orderData.labProof &&
                    [orderData.labProof].map((el, index) => (
                      <Grid
                        item
                        style={{ width: 100, position: "relative" }}
                        xs={3}
                      >
                        <Avatar
                          style={{ cursor: "pointer" }}
                          onClick={() => this.setState({ proofImages: [el] })}
                          src={el}
                          size="small"
                        />{" "}
                        <div
                          style={{
                            position: "absolute",
                            left: 45,
                            top: 0,
                            cursor: "pointer",
                          }}
                        >
                          X
                        </div>
                      </Grid>
                    ))}
                  <Grid item xs={4} style={{ marginLeft: 5, marginRight: 5 }}>
                    <input
                      style={{ fontSize: "unset", margin: 10 }}
                      type="file"
                      name="labProof"
                      accept="image/*"
                      onChange={(e) => {
                        this.uploadProofImage(e.target.files, "labProof", 2);
                      }}
                    />
                  </Grid>
                </Grid>
              </span>
            </Grid>
            <Grid item xs={4}>
              <span className="logs-title log-inline-title"> Customer Signature: </span>
              <span className="log-inline-img">
                <Grid container xs={12}>
                  {orderData.customerSignature &&
                    [orderData.customerSignature].map((el, index) => (
                      <Grid
                        item
                        style={{ width: 100, position: "relative" }}
                        xs={3}
                      >
                        <Avatar
                          style={{ cursor: "pointer" }}
                          onClick={() => this.setState({ proofImages: [el] })}
                          src={el}
                          size="small"
                        />{" "}
                        <div
                          style={{
                            position: "absolute",
                            left: 45,
                            top: 0,
                            cursor: "pointer",
                          }}
                        >
                          X
                        </div>
                      </Grid>
                    ))}
                  <Grid item xs={3} style={{ marginLeft: 5, marginRight: 5 }}>
                    <input
                      style={{ fontSize: "unset", margin: 10 }}
                      type="file"
                      name="customerSignature"
                      accept="image/*"
                      onChange={(e) => {
                        this.uploadProofImage(e.target.files, "customerSignature", 7);
                      }}
                    />
                  </Grid>
                </Grid>
              </span>
            </Grid>
            <Grid item xs={4}>
              <span className="logs-title"> Lab Comments: </span>
              <span> {orderData.labComments} </span>
            </Grid>
          </Grid>
        </div>

        <div className="section-divider">
          <h3>Cancellation</h3>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <span className="logs-title"> Cancel Code: </span>
              {cancelCode.length > 0
                ? cancelCode[0].name.replace(/_/g, " ")
                : "-"}
            </Grid>
            <Grid item xs={4}>
              <span className="logs-title"> Cancel Comments:</span>
              <span> {orderData.orderCancelComments} </span>
            </Grid>
            <Grid item xs={4}>
              <span className="logs-title"> Executive Comments:</span>
              <span> {orderData.exeComments ? orderData.exeComments : orderData.errorMessage} </span>
            </Grid>
            <Grid item xs={4}>
              <span className="logs-title log-inline-title">
                {" "}
                Audio Proof:{" "}
              </span>
              <span className="log-inline-img">
                {orderData.orderCancelProof ? (
                  <a
                    href={orderData.orderCancelAudioProof}
                    download="Order_Cancel_Audio_Proof.mp3"
                  >
                    {" "}
                    <Avatar
                      src={orderData.orderCancelAudioProof}
                      size="small"
                    />{" "}
                  </a>
                ) : (
                  "-"
                )}
                <input
                  style={{ fontSize: "unset", margin: 10 }}
                  type="file"
                  name="orderCancelAudioProof"
                  accept="audio/*"
                  onChange={(e) => {
                    this.uploadProofImage(
                      e.target.files,
                      "orderCancelAudioProof",
                      5
                    );
                  }}
                />
              </span>
            </Grid>
            <Grid item xs={4}>
              <span className="logs-title log-inline-title">
                {" "}
                Video Proof:{" "}
              </span>
              <span className="log-inline-img">
                {" "}
                {orderData.orderCancelVideoProof ? (
                  <a
                    href={orderData.orderCancelVideoProof}
                    download="Order_Cancel_Video_Proof.mp4"
                  >
                    {" "}
                    <Avatar
                      src={orderData.orderCancelVideoProof}
                      size="small"
                    />{" "}
                  </a>
                ) : (
                  "-"
                )}
                <input
                  style={{ fontSize: "unset", margin: 10 }}
                  type="file"
                  name="orderCancelVideoProof"
                  accept="video/*"
                  onChange={(e) => {
                    this.uploadProofImage(
                      e.target.files,
                      "orderCancelVideoProof",
                      6
                    );
                  }}
                />
              </span>
            </Grid>
            <Grid item xs={4}>
              <span className="logs-title log-inline-title">
                {" "}
                Cancellation Proof:{" "}
              </span>
              <span className="log-inline-img">
                <Grid container xs={12}>
                  {orderData.orderCancelProof &&
                    [orderData.orderCancelProof].map((el, index) => (
                      <Grid
                        item
                        style={{ width: 100, position: "relative" }}
                        xs={3}
                      >
                        <Avatar
                          style={{ cursor: "pointer" }}
                          onClick={() => this.setState({ proofImages: [el] })}
                          src={el}
                          size="small"
                        />{" "}
                        <div
                          style={{
                            position: "absolute",
                            left: 45,
                            top: 0,
                            cursor: "pointer",
                          }}
                        >
                          X
                        </div>
                      </Grid>
                    ))}
                  <Grid item xs={4} style={{ marginLeft: 5, marginRight: 5 }}>
                    <input
                      style={{ fontSize: "unset", margin: 10 }}
                      type="file"
                      name="orderCancelProof"
                      accept="image/*"
                      onChange={(e) => {
                        this.uploadProofImage(
                          e.target.files,
                          "orderCancelProof",
                          3
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </span>
            </Grid>
          </Grid>
        </div>

        <div className="section-divider">
          <h3>Customer Feedback</h3>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <span className="logs-title"> Process Experience: </span>
              {type1.length > 0 ? type1[0].name : "-"}
            </Grid>
            <Grid item xs={4}>
              <span className="logs-title">
                {" "}
                Sample Collection Experience:{" "}
              </span>
              {type2.length > 0 ? type2[0].name : "-"}
            </Grid>
            <Grid item xs={4}>
              <span className="logs-title">
                {" "}
                Hygiene / Technician Experience:{" "}
              </span>
              {type3.length > 0 ? type3[0].name : "-"}
            </Grid>
          </Grid>
        </div>

        <div>
          {!isPartner && (
            <Grid container spacing={3}>
              <Grid item xs={1}>
                <Button
                  onClick={this.updateOrder}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    );
  };

  renderBasicDetails = (isKYC, isPartner) => {
    return (
      <React.Fragment>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {this.renderFormFields(isPartner)}
          </Grid>
        </Grid>
        {!isPartner && (
          <Grid container spacing={3}>
            <Grid item xs={1}>
              <Button
                onClick={this.updateOrder}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        )}
      </React.Fragment>
    );
  };

  renderLabReportDeletePopup = () => {
    const { isReportDelete, deleteObj } = this.state;
    return (
      <Dialog
        disableBackdropClick
        open={isReportDelete}
        onClose={() => {
          this.setState({ isReportDelete: false });
        }}
      >
        <DialogTitle id="clinic-popup">Confirm Report Delete</DialogTitle>
        <DialogContent>
          {" "}
          Are you sure you want to delete {deleteObj.name} ?{" "}
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            onClick={() => {
              this.deleteReport(deleteObj);
            }}
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
          <Button
            style={{ borderColor: "#00C9AA", color: "#00C9AA" }}
            onClick={() => {
              this.setState({ isReportDelete: false, deleteObj: null });
            }}
            variant="outlined"
            color="primary"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderIsNewConfirmationPopup = () => {
    const { isNewConfirm, newOrderObj, isAllNew } = this.state;
    if (isAllNew) {
      return (
        <Dialog
          disableBackdropClick
          open={isAllNew}
          onClose={() => {
            this.setState({ isAllNew: false });
          }}
        >
          <DialogTitle id="clinic-popup">Confirm New Order for All</DialogTitle>
          <DialogContent>
            {" "}
            Are you sure you want to mark all orders as new?{" "}
          </DialogContent>
          <DialogActions>
            <Button
              style={{ backgroundColor: "#00C9AA" }}
              onClick={() => {
                this.acceptRejectOrder(4, newOrderObj, "all");
              }}
              variant="contained"
              color="primary"
            >
              Yes
            </Button>
            <Button
              style={{ borderColor: "#00C9AA", color: "#00C9AA" }}
              onClick={() => {
                this.setState({ isAllNew: false });
              }}
              variant="outlined"
              color="primary"
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return (
      <Dialog
        disableBackdropClick
        open={isNewConfirm}
        onClose={() => {
          this.setState({ isNewConfirm: false });
        }}
      >
        <DialogTitle id="clinic-popup">Confirm New Order</DialogTitle>
        <DialogContent>
          <div className="mb-20">
            {" "}
            Are you sure you want to mark the order {
              newOrderObj.orderSequence
            }{" "}
            new ?{" "}
          </div>
          <div className="mb-10"> If yes, Please select a date </div>
          <div>
            <DatePicker
              dateFormat="MMMM d, yyyy"
              placeholderText="Select a date"
              selected={this.state.newOrderDate}
              onChange={(date) => {
                this.setState({ newOrderDate: new Date(date).getTime() });
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            onClick={() => {
              this.acceptRejectOrder(4, newOrderObj);
            }}
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
          <Button
            style={{ borderColor: "#00C9AA", color: "#00C9AA" }}
            onClick={() => {
              this.setState({
                isNewConfirm: false,
                newOrderObj: null,
                newOrderDate: new Date().getTime(),
              });
            }}
            variant="outlined"
            color="primary"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderDeletePopup = () => {
    const { isDeleteOpen, deleteObj } = this.state;
    return (
      <Dialog
        disableBackdropClick
        open={isDeleteOpen}
        onClose={() => {
          this.setState({ isDeleteOpen: false });
        }}
      >
        <DialogTitle id="clinic-popup">Confirm Unassign Order</DialogTitle>
        <DialogContent>
          {" "}
          Are you sure you want to unassign order {deleteObj.orderSequence} for
          phlebo {deleteObj.phleboName} ?{" "}
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            onClick={() => {
              this.unassignOrder(deleteObj);
            }}
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
          <Button
            style={{ borderColor: "#00C9AA", color: "#00C9AA" }}
            onClick={() => {
              this.setState({ isDeleteOpen: false, deleteObj: null });
            }}
            variant="outlined"
            color="primary"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderUnassignDoctor = () => {
    const { unassignDocOpen, deleteObj } = this.state;
    return (
      <Dialog
        disableBackdropClick
        open={unassignDocOpen}
        onClose={() => {
          this.setState({ unassignDocOpen: false });
        }}
      >
        <DialogTitle id="clinic-popup">Confirm Unassign Order</DialogTitle>
        <DialogContent>
          {" "}
          Are you sure you want to unassign order {deleteObj.orderSequence} for
          doctor {deleteObj.doctorName} ?{" "}
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            onClick={() => {
              this.unassignDoctor(deleteObj);
            }}
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
          <Button
            style={{ borderColor: "#00C9AA", color: "#00C9AA" }}
            onClick={() => {
              this.setState({ unassignDocOpen: false, deleteObj: null });
            }}
            variant="outlined"
            color="primary"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  renderShowConfirmPopup = () => {
    const { isShowOpen, showObj } = this.state;
    return (
      <Dialog
        disableBackdropClick
        open={isShowOpen}
        onClose={() => {
          this.setState({ isShowOpen: false });
        }}
      >
        <DialogTitle id="clinic-popup">Confirm Update Order</DialogTitle>
        <DialogContent>
          {" "}
          Are you sure you want to update order - {showObj.orderSequence}
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            onClick={() => {
              this.showOrder(showObj.orderId);
            }}
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
          <Button
            style={{ borderColor: "#00C9AA", color: "#00C9AA" }}
            onClick={() => {
              this.setState({ isShowOpen: false, showObj: {} });
            }}
            variant="outlined"
            color="primary"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderRejectPopup = () => {
    const {
      isReject,
      rejectType,
      orderCancelProof,
      orderCancelVideoProof,
      orderCancelAudioProof,
    } = this.state;
    return (
      <Dialog
        disableBackdropClick
        open={isReject}
        onClose={() => {
          this.setState({
            isReject: false,
            rejectComments: null,
            isReschedule: false,
            slotFrom: new Date(),
            slotTo: new Date(),
            orderCancelProof: "",
            orderCancelVideoProof: "",
            orderCancelAudioProof: "",
          });
        }}
      >
        <DialogTitle id="clinic-popup">Reason for rejecting</DialogTitle>
        <DialogContent>
          <div className="mb-20">
            <label> Reason </label>
            <Select
              fullWidth
              value={rejectType}
              onChange={(e) => {
                this.setState({ rejectType: e.target.value });
              }}
            >
              {(REJECT_REASON || []).map((reason, index) => (
                <option key={`reason-${reason.value}`} value={reason.value}>
                  {" "}
                  {reason.name}{" "}
                </option>
              ))}
            </Select>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <label> Reject Reason </label>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                placeholder="Rejection Reason"
                value={this.state.rejectComments}
                onChange={(e) => {
                  this.setState({ rejectComments: e.target.value });
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <label>Order Cancel Proof</label>
              <input
                type="file" 
                className="inputFileUpload"
                onChange={(e) => {
                  this.uploadRejectionImage(e, "orderCancelProof");
                }}
                ref={(ref) => {
                  this.orderCancelProof = ref;
                }}
              />
              {orderCancelProof && orderCancelProof.length > 0 && (
                <a
                  href={orderCancelProof}
                  style={{ color: "blue", fontSize: "10px" }}
                >
                  <Avatar size="small" src={orderCancelProof} alt="icon" />
                </a>
              )}
            </Grid>

            <Grid item xs={6}>
              <label>Order Cancel Video Proof</label>
              <input
                type="file"
                className="inputFileUpload"
                onChange={(e) => {
                  this.uploadRejectionImage(e, "orderCancelVideoProof");
                }}
                ref={(ref) => {
                  this.orderCancelVideoProof = ref;
                }}
              />
              {orderCancelVideoProof && orderCancelVideoProof.length > 0 && (
                <a
                  href={orderCancelVideoProof}
                  style={{ color: "blue", fontSize: "10px" }}
                >
                  <Avatar size="small" src={orderCancelVideoProof} alt="icon" />
                </a>
              )}
            </Grid>

            <Grid item xs={6}>
              <label>Order Cancel Audio Proof</label>
              <input
                type="file" 
                className="inputFileUpload"
                onChange={(e) => {
                  this.uploadRejectionImage(e, "orderCancelAudioProof");
                }}
                ref={(ref) => {
                  this.orderCancelAudioProof = ref;
                }}
              />
              {orderCancelAudioProof && orderCancelAudioProof.length > 0 && (
                <a
                  href={orderCancelAudioProof}
                  style={{ color: "blue", fontSize: "10px" }}
                >
                  <Avatar size="small" src={orderCancelAudioProof} alt="icon" />
                </a>
              )}
            </Grid>

            <Grid item xs={6}>
              <div className="dateFilters">
                <div className="dateLabel">Slot From</div>
                <DatePicker
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  placeholderText="Select a date"
                  selected={this.state.slotFrom}
                  onChange={(date) => {
                    this.setState({ slotFrom: new Date(date).getTime() });
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="dateFilters">
                <div className="dateLabel">Slot To</div>
                <DatePicker
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  placeholderText="Select a date"
                  selected={this.state.slotTo}
                  onChange={(date) => {
                    this.setState({ slotTo: new Date(date).getTime() });
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <FormControlLabel
                label="Is Reschedule?"
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    checked={this.state.isReschedule}
                    onChange={(e) => {
                      this.setState({ isReschedule: e.target.checked });
                    }}
                  />
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            onClick={() => {
              this.acceptRejectOrder(3);
              this.setState({
                isReject: false,
                rejectComments: null,
                isReschedule: false,
                slotFrom: new Date(),
                slotTo: new Date(),
                orderCancelProof: "",
                orderCancelVideoProof: "",
                orderCancelAudioProof: "",
              });
            }}
            variant="contained"
            color="primary"
          >
            Reject
          </Button>
          <Button
            style={{ borderColor: "#00C9AA", color: "#00C9AA" }}
            onClick={() => {
              this.setState({
                isReject: false,
                rejectComments: null,
                slotFrom: new Date(),
                slotTo: new Date(),
                isReschedule: false,
                orderCancelProof: "",
                orderCancelVideoProof: "",
                orderCancelAudioProof: "",
              });
            }}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderAssignStatusData = () => {
    const { isAssignStatus, statusData } = this.state;

    return (
      <Dialog
        disableBackdropClick
        open={isAssignStatus}
        onClose={() => {
          this.setState({ isAssignStatus: false });
        }}
        style={{ minWidth: "800px" }}
      >
        <DialogTitle id="clinic-popup">
          Status
          <IconButton
            edge="end"
            color="inherit"
            size="small"
            style={{ float: "right", fontSize: "12px", color: "blue" }}
            onClick={() => {
              this.getAssignStatus();
            }}
          >
            <Avatar
              style={{ width: "20px", height: "20px", margin: "14px 0" }}
              src={StatusIcon}
              size="small"
              alt="status"
            />{" "}
            Refresh
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ width: "600px", height: "400px" }}>
          <TableContainer className="mb-30">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell> Name </TableCell>
                  <TableCell> Status/ Date </TableCell>
                  <TableCell> Purpose </TableCell>
                  <TableCell> Orders </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(statusData || []).length <= 0 && (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {" "}
                      No statuses found.{" "}
                    </TableCell>
                  </TableRow>
                )}
                {(statusData || []).map((st, index) => {
                  return (
                    <TableRow key={`status-${index + 1}`}>
                      <TableCell> {st.name} </TableCell>
                      <TableCell>
                        {" "}
                        <b>{st.status}</b>{" "}
                        <div style={{ fontSize: "12px" }}>
                          {" "}
                          {moment(st.created).format("DD-MM-YYYY h:mm A")}{" "}
                        </div>{" "}
                      </TableCell>
                      <TableCell> {st.purpose} </TableCell>
                      <TableCell>
                        <div> Pending: {st.pendingOrders} </div>
                        <div> Processed: {st.processedOrders} </div>
                        <div> Accepted: {st.acceptedOrders} </div>
                        <div> Rejected: {st.rejectedOrders} </div>
                        <div> Total: {st.totalOrders} </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.setState({ isAssignStatus: false });
              this.resetPopup();
            }}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  getAllReports = () => {
    const { dispatch } = this.props;
    const data = {
      ts: new Date(this.state.dateFilter).getTime(),
      ed: new Date(this.state.endDateFilter).getTime(),
      isDashboard: true,
      city: this.state.city ? this.state.city : "Bangalore",
    };
    if (this.state.lgp) {
      data["lgp"] = this.state.lgp;
    }
    if (this.state.timings) {
      data["slot"] = Array.prototype.map
        .call(this.state.timings, function (item) {
          return item.id;
        })
        .join(",");
    }
    dispatch({
      type: GET_CLIENT_ORDER_DETAILS,
      data: data,
      onSuccess: (res) => {
        this.setState({ allReports: res.list });
      },
    });
    this.getReportsOrders();
  };
  getReportsOrders = () => {
    let orders = ORDER_CONSTANTS;
    for (let i = 0; i <= ORDER_CONSTANTS.length; i++) {
      for (let j = 0; j <= orders.length; j++) {
        if (
          orders[i] &&
          orders[i].name === "NEW" &&
          this.state.allReports[j] &&
          this.state.allReports[j].name === "NEW"
        ) {
          orders[i]["count"] = this.state.allReports[j].count;
        }
        if (
          orders[i] &&
          orders[i].name === "ACCEPTED" &&
          this.state.allReports[j] &&
          this.state.allReports[j].name === "ACCEPTED"
        ) {
          orders[i]["count"] = this.state.allReports[j].count;
        }
        if (
          orders[i] &&
          orders[i].name === "REJECTED" &&
          this.state.allReports[j] &&
          this.state.allReports[j].name === "REJECTED"
        ) {
          orders[i]["count"] = this.state.allReports[j].count;
        }
        if (
          orders[i] &&
          orders[i].name === "ASSIGNED" &&
          this.state.allReports[j] &&
          this.state.allReports[j].name === "ASSIGNED/SCHEDULED"
        ) {
          orders[i]["count"] = this.state.allReports[j].count;
        }
        if (
          orders[i] &&
          orders[i].name === "PENDING LAB HANDOVER" &&
          this.state.allReports[j] &&
          this.state.allReports[j].name === "PENDING LAB HANDOVER"
        ) {
          orders[i]["count"] = this.state.allReports[j].count;
        }
        if (
          orders[i] &&
          orders[i].name === "COMPLETED" &&
          this.state.allReports[j] &&
          this.state.allReports[j].name === "COMPLETED"
        ) {
          orders[i]["count"] = this.state.allReports[j].count;
        }
        if (
          orders[i] &&
          orders[i].name === "IN REVIEW" &&
          this.state.allReports[j] &&
          this.state.allReports[j].name === "IN REVIEW"
        ) {
          orders[i]["count"] = this.state.allReports[j].count;
        }
        if (
          orders[i] &&
          orders[i].name === "PARTNER CANCELLED" &&
          this.state.allReports[j] &&
          this.state.allReports[j].name === "PARTNER CANCELLED"
        ) {
          orders[i]["count"] = this.state.allReports[j].count;
        }
        if (
          orders[i] &&
          orders[i].name === "CANCELLED BY CUSTOMER" &&
          this.state.allReports[j] &&
          this.state.allReports[j].name === "CANCELLED"
        ) {
          orders[i]["count"] = this.state.allReports[j].count;
        }
        if (
          orders[i] &&
          orders[i].name === "CANCELLED BY CUSTOMER DUE TO PHLEBO" &&
          this.state.allReports[j] &&
          this.state.allReports[j].name === "CANCELLED DUE TO PHLEBO MISTAKE"
        ) {
          orders[i]["count"] = this.state.allReports[j].count;
        }
        if (
          orders[i] &&
          orders[i].name === "RESCHEDULE BY CUSTOMER" &&
          this.state.allReports[j] &&
          this.state.allReports[j].name === "RESCHEDULE"
        ) {
          orders[i]["count"] = this.state.allReports[j].count;
        }
        if (
          orders[i] &&
          orders[i].name === "RESCHEDULE DUE TO PHLEBO MISTAKE" &&
          this.state.allReports[j] &&
          this.state.allReports[j].name === "RESCHEDULE DUE TO PHLEBO MISTAKE"
        ) {
          orders[i]["count"] = this.state.allReports[j].count;
        }
        if (
          orders[i] &&
          orders[i].name === "ZIPCODE NOT SERVICEABLE" &&
          this.state.allReports[j] &&
          this.state.allReports[j].name === "ZIPCODE NOT SERVICEABLE"
        ) {
          orders[i]["count"] = this.state.allReports[j].count;
        }
        if (
          orders[i] &&
          orders[i].name === "DUPLICATE" &&
          this.state.allReports[j] &&
          this.state.allReports[j].name === "DUPLICATE ORDER"
        ) {
          orders[i]["count"] = this.state.allReports[j].count;
        }
        if (
          orders[i] &&
          orders[i].name === "CR" &&
          this.state.allReports[j] &&
          this.state.allReports[j].name === "CANCELATION REQUEST BY PHLEBO"
        ) {
          orders[i]["count"] = this.state.allReports[j].count;
        }
      }
    }
    return orders;
  };

  handleTrackClick = (orderId) => {
    window.open(
      `http://13.232.143.219:9000/tracking/tracking.html?orderId=${orderId}`,
      "_blank"
    );
  };

  handlePhoneClick = (orderId, contact) => {
    const { dispatch } = this.props;
    const username = getUser().ozUsername || null;
    if (username) {
      window.open(
        `http://calite.ozonetel.com/c2capi.html?apiKey=28f3d06bcb23239b33699a4f3ea1f215&username=${username}&number=${contact}`,
        "_blank"
      );
    } else {
      dispatch({
        type: TOAST_ERROR,
        message: "Invalid Username.",
      });
    }
  };

  showOrder = (orderId) => {
    const { dispatch } = this.props;
    const dataParams = {
      orderId,
      isShow: true,
    };
    dispatch({
      type: UPDATE_ORDER,
      data: dataParams,
      onSuccess: () => {
        dispatch({
          type: TOAST_SUCCESS,
          message: "Success.",
        });
        this.setState({ isShowOpen: false });
      },
    });
  };

  handleLgp = (value) => {
    this.setState({ lgp: value ? value.lgpId : null }, () => {
      this.getOrders();
      this.getPlebosForFilter();
      this.getAllReports();
    });
  };

  handleTimings = (e) => {
    this.setState(
      {
        timings: e,
      },
      () => {
        this.getOrders();
        this.getPlebosForFilter();
        this.getAllReports();
      }
    );
  };

  handleVitals = () => {
    this.getVitalsByOrderId();
    this.getVideoTokenByOrderId();
  }
handleRefreshVitals = () =>{
  this.getVitalsByOrderId();
}
  handlePpmc = () => {
    this.getVitalsByOrderId();  
    setTimeout(() => {
      this.downloadVideoMer();
    }, 1000);
   
    setTimeout(() => {
      this.multipleVideos();
    }, 2000);

  }


  multipleVideos = () => {
   for(let i = 0; i < this.state.downloadMer.length; i++){
      this.state.downloadMer[i]["s_no"] = i+1;
    }
    this.setState({
      numbers:this.state.downloadMer,
    })
  }

  handleClickVitals = () =>{
    this.setState({openVitals:true});
    this.getVitalsByOrderId();
    this.getVideoTokenByOrderId();
  }

  handleClose = () => {
    const {join} = this.state;
    if(!join){
      this.setState({openVitals:false});
    }else{
      this.setState({openAlert:true})
      setTimeout(() =>{
        this.setState({openAlert:false})
      }, 5000)
    }
  };

  render() {
    const { classes } = this.props;
    const { cities, states, orders, isLoading, match, plebos } = this.props;
    const {vitals} = this.state;
    console.log(vitals);
    const users = getUser();
    const {
      isOpen,
      isOrderAssign,
      isDoctorAssign,
      data,
      kycUrl,
      kycDocumentType,
      selectedOrders,
      type,
      isDeleteOpen,
      unassignDocOpen,
      isReportDelete,
      isAssignStatus,
      city,
      isPast,
      isNewConfirm,
      isAllNew,
      isAcceptOpen,
      isRejectOpen,
      isMobile,
      isShowOpen,
      downloadMer,
    } = this.state;
    let isKYC = false;
    if (match.params && match.params.id) {
      isKYC = true;
    }
    const selectedtype = [...ORDER_CONSTANTS, ...PAST_TABS].find((el) => {
      return el.id === type;
    });
    const user = getUser();
    const isPartner = user.userType === 2;
    const assndStatus = ORDER_ASSIGNED_STATUS.filter(
      (el) => el.id === data.orderAssignedStatus
    );
    const userType = getUserType();
   

 

    return (
      <DocContainer>
        <div className="mr-20">
          {isLoading && <ActionLoader />}
          {!isKYC ? (
            <React.Fragment>
              <Grid item xs={12}>
                <h2 className="mt-0">Orders</h2>
              </Grid>
              <Grid container spacing={2} style={{ marginBottom: 5 }}>

                <Grid item xs={3} className="p-3">
                  <span className="fa fa-search" />
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    placeholder="ID…"
                    value={this.state.searchOrderSeq}
                    onChange={(e) => {
                      this.setState(
                        { searchOrderSeq: e.target.value },
                        () => {
                          this.getOrders();
                        }
                      );
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {this.state.searchOrderSeq ? <IconButton
                             onClick={() => {
                              this.setState({ searchOrderSeq: "" }, () => {
                                this.getOrders();
                              });
                            }}
                          >
                          <Close/>
                          </IconButton> : null }
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={2} className="p-3">
                  <span className="fa fa-search" />
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    placeholder="Generic"
                    value={this.state.searchText}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                         {this.state.searchText ? <IconButton
                             onClick={() => {
                              this.setState({ searchText: "" }, () => {
                                this.getOrders();
                              });
                            }}
                          >
                          <Close/>
                          </IconButton> : null }
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      this.setState({ searchText: e.target.value }, () => {
                        this.getOrders();
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={2} className="p-3">
                  <span className="fa fa-search" />
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    placeholder="Vendor number"
                    value={this.state.vendorNumber}
                    onChange={(e) => {
                      this.setState({ vendorNumber: e.target.value }, () => {
                        this.getOrders();
                      });
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {this.state.vendorNumber ? <IconButton
                            onClick={() => {
                              this.setState({ vendorNumber: "" }, () => {
                                this.getOrders();
                              });
                            }}
                          >
                          <Close/>
                          </IconButton> : null }
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={2} className="p-3">
                  <span className="fa fa-search" />
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    placeholder="Name…"
                    value={this.state.customerName}
                    onChange={(e) => {
                      this.setState({ customerName: e.target.value }, () => {
                        this.getOrders();
                      });
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {this.state.customerName ? <IconButton
                            onClick={() => {
                              this.setState({ customerName: "" }, () => {
                                this.getOrders();
                              });
                            }}
                          >
                          <Close/>
                          </IconButton> : null }
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={2} className="p-3">
                  <span className="fa fa-search" />
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    placeholder="Mobile"
                    value={this.state.customerContact}
                    onChange={(e) => {
                      this.setState(
                        { customerContact: e.target.value },
                        () => {
                          this.getOrders();
                        }
                      );
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {this.state.customerContact ? <IconButton
                            onClick={() => {
                              this.setState({ customerContact: "" }, () => {
                                this.getOrders();
                              });
                            }}
                          >
                          <Close/>
                          </IconButton> : null }
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <div className="ordersDate dateFilters">
                    <div className="dateLabel mb-10">Date</div>
                    <div
                      style={{ marginRight: "10px", display: "inline-block" }}
                    >
                      <DatePicker
                        placeholderText="Start Date"
                        selectsStart
                        startDate={new Date()}
                         selected={this.state.dateFilter}
                        onChange={(date) => {
                          localStorage.setItem(
                            "orderDateFilter",
                            new Date(date).getTime()
                          );
                          this.setState(
                            { dateFilter: date, endDateFilter: 0 },
                            () => {
                              this.getOrders();
                            }
                          );
                        }}
                      />
                    </div>
                    <div
                      style={{ marginRight: "10px", display: "inline-block" }}
                    >
                      <DatePicker
                        placeholderText="End Date"
                        selected={this.state.endDateFilter}
                        onChange={(date) => {
                          localStorage.setItem(
                            "orderEndDateFilter",
                            new Date(date).getTime()
                          );
                          this.setState({ endDateFilter: date }, () => {
                            this.getOrders();
                          });
                        }}
                        selectsEnd
                        startDate={this.state.endDateFilter}
                        endDate={
                          this.state.dateFilter
                            ? new Date(
                              new Date(this.state.dateFilter).getTime() +
                              31 * 24 * 60 * 60 * 1000
                            )
                            : new Date(
                              new Date().setFullYear(
                                new Date().getFullYear() + 20
                              )
                            )
                        }
                        minDate={this.state.dateFilter}
                        maxDate={
                          new Date(
                            new Date(this.state.dateFilter).getTime() +
                            31 * 24 * 60 * 60 * 1000
                          )
                        }
                      />
                    </div>
                    {this.state.dateFilter ? (
                      <span
                        onClick={() => {
                          this.setState(
                            { dateFilter: 0, endDateFilter: 0 },
                            () => {
                              this.getOrders();
                            }
                          );
                        }}
                      >
                        X
                      </span>
                    ) : null}
                    {this.state.dateFilter ? (
                      <div
                        className="download-link"
                        onClick={this.downloadClientOrder}
                      >
                        {" "}
                        Download Client Order{" "}
                      </div>
                    ) : null}
                  </div>
                </Grid>
                <Grid item xs={2} style={{ width: 130, marginRight: 5 }}>
                  <div className="dateLabel"> Filter City: </div>
                  <Select
                    size="small"
                    style={{ width: "130px" }} 
                    value={
                      this.state.city == "" ? "Bangalore" : this.state.city
                    }
                    disabled={this.state.cityFilterDisable}
                    onChange={(e) => {
                        this.setState(
                        {
                          city: e.target.value,
                        },
                        () => {
                          this.getOrders();
                          this.getPlebosForFilter();
                        }
                      );
                    }}
                  >
                    {(this.props.cities || []).map((city, index) => (
                      <option key={`city-${index}`} value={city}>
                        {" "}
                        {city}{" "}
                      </option>
                    ))}
                  </Select>
                  {this.state.city && (
                    <span
                      onClick={() => {
                        this.setState(
                          { city: "", filteredPhleboId: "" },
                          () => {
                            this.getOrders();
                            this.getPlebosForFilter();
                            this.getAllReports();
                          }
                        );
                      }}
                    >
                      X
                    </span>
                  )}
                </Grid>
                <Grid item style={{ width: 130, marginRight: 5 }}>
                  <div className="dateLabel"> Filter Phlebo: </div>
                  <PhloeboAutocomplete
                    getOptions={(val) => this.getPlebosForFilter(val)}
                    value={this.state.filteredPhleboId}
                    allOptions={plebos || []}
                    onSelect={(value, key) =>
                      this.setState(
                        {
                          filteredPhleboId: value,
                        },
                        () => {
                          this.getOrders();
                        }
                      )
                    }
                  />
                  
                </Grid>
                <Grid item style={{ width: 130, marginRight: 5 }}>
                  <div className="dateLabel"> LGP: </div>
                  <Autocomplete
                    id="combo-box-demo"
                    value={this.state.lgp}
                    style={{ width: "130px" }} 
                    onChange={(event, newValue) => {
                      this.handleLgp(newValue);
                    }}
                    options={this.props.lgps}
                    getOptionLabel={(option) => `${option.name}`}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>

                <Grid item style={{ width: 130, marginRight: 5 }}>
                  <div className="dateLabel"> Slots: </div>
                  <Autocomplete
                    multiple
                    id="combo-box-demo"
                    style={{ width: "130px" }} 
                    onChange={(event, newValue) => {
                      this.handleTimings(newValue);
                    }}
                    options={timings}
                    getOptionLabel={(option) => `${option.name}`}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
                {this.state.isReject && this.renderRejectPopup()}
              </Grid>
              {user.userType === 1 && (
                <div style={{ float: "right", width: "150px" }}>
                  <IconButton
                    edge="end"
                    color="inherit"
                    size="small"
                    onClick={this.downloadOrder}
                  >
                    <GetAppOutlined /> Orders
                  </IconButton>
                  <a
                    className="download-btn"
                    style={{ display: "none" }}
                    ref={(input) => (this.downloadBtn = input)}
                    href={this.state.downloadLink}
                    download
                  />

                  <IconButton
                    edge="end"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      this.uploadLink.click();
                    }}
                  >
                    <PublishOutlined /> Orders
                  </IconButton>

                  <input
                    type="file"
                    style={{ display: "none" }}
                    className="inputFileUpload"
                    onChange={(e) => {
                      this.uploadOrder(e);
                    }}
                    ref={(ref) => {
                      this.uploadLink = ref;
                    }}
                  />
                </div>
              )}
              <div style={{ float: "right", width: "150px" }}>
                <IconButton
                  edge="end"
                  color="inherit"
                  size="small"
                  onClick={this.downloadReports}
                >
                  <GetAppOutlined /> Reports
                </IconButton>
                <a
                  className="download-btn"
                  style={{ display: "none" }}
                  ref={(input) => (this.downloadReportBtn = input)}
                  href={this.state.downloadReportLink}
                  download
                />
              </div>
              <div>
                {this.getReportsOrders().map((order) => {
                  return (
                    <React.Fragment>
                      <Chip
                        clickable
                        key={order.id}
                        style={{ marginRight: "10px", marginBottom: "10px" }}
                        label={`${order.name}${this.state.allReports.length !== 0
                          ? order.count === undefined
                            ? order.name !== "API"
                              ? "(0)"
                              : ""
                            : "(" + order.count + ")"
                          : "(0)"
                          }`}
                        color={
                          (order.id === type &&
                            !isPast &&
                            !this.props.typeValue) ||
                            (order.id === 12 &&
                              isPast &&
                              !this.props.typeValue) ||
                            order.id == this.props.typeValue
                            ? "primary"
                            : ""
                        }
                        onClick={() => {
                          this.props.dispatch({
                            type: SET_TYPE_VALUE,
                            data: "",
                          });
                          if ((this.state.searchOrderSeq || "").length <= 0) {
                            this.setState(
                              {
                                isPast: order.id === 12,
                                type: order.id,
                                selectedOrders: [],
                              },
                              () => {
                                this.getOrders();
                              }
                            );
                          }
                        }}
                      />
                      {order.pipe && (
                        <span style={{ fontSize: "30px", marginRight: "5px" }}>
                          |{" "}
                        </span>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
              <div style={{ marginTop: "10px" }}>
                {type === 12 || isPast
                  ? PAST_TABS.map((order) => {
                    return (
                      <React.Fragment>
                        <Chip
                          clickable
                          key={`sub-${order.id}`}
                          style={{
                            marginRight: "10px",
                            marginBottom: "10px",
                          }}
                          label={order.name}
                          color={order.id === type && isPast ? "primary" : ""}
                          onClick={() => {
                            if (
                              (this.state.searchOrderSeq || "").length <= 0
                            ) {
                              this.setState(
                                {
                                  isPast: true,
                                  type: order.id,
                                  selectedOrders: [],
                                },
                                () => {
                                  this.getOrders();
                                }
                              );
                            }
                          }}
                        />
                        {order.pipe && (
                          <span
                            style={{ fontSize: "30px", marginRight: "5px" }}
                          >
                            |{" "}
                          </span>
                        )}
                      </React.Fragment>
                    );
                  })
                  : null}
              </div>

              {this.state.isUploadError && this.renderErrorPopup()}

              {this.renderPagination()}

              {isAcceptOpen && this.renderAcceptPopup()}

              {isRejectOpen && this.renderRejectOrderPopup()}

              <div style={{ float: "left", marginTop: "-60px" }}>
                {
                  <Button
                    size="small"
                    onClick={() => {
                      this.setState({ addOrder: true });
                    }}
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "20px", marginBottom: "10px", backgroundColor: "#0D8DA3" }}
                  >
                    + Create Order
                  </Button>
                }
                {user.userType === 1 &&
                  type === 20 &&
                  (this.state.activeChip == "Open" ? (
                    <Button
                      size="small"
                      onClick={() => {
                        this.setState({ isAllNew: true });
                      }}
                      variant="contained"
                      color="primary"
                      style={{
                        marginRight: "20px",
                        marginBottom: "10px",
                        backgroundColor: "#0D8DA3",
                      }}
                    >
                      ✓ Mark all as New
                    </Button>
                  ) : null)}

                {type === 20 &&
                  userType == 1 &&
                  [
                    { id: 1, name: "Open" },
                    { id: 2, name: "Moved" },
                    { id: 3, name: "ALL" },
                  ].map((order) => {
                    return (
                      <React.Fragment>
                        <Chip
                          clickable
                          key={order.id}
                          style={{ marginRight: "10px", marginBottom: "10px" }}
                          label={order.name}
                          color={
                            order.name == this.state.activeChip ? "primary" : ""
                          }
                          onClick={(e) => {
                            this.setState(
                              { open: order.id, activeChip: order.name },
                              () => {
                                this.getOrders();
                              }
                            );
                          }}
                        />
                      </React.Fragment>
                    );
                  })}
                {selectedtype.hasAccept &&
                  this.state.selectedOrders.length > 0 &&
                  !isPartner && (
                    <React.Fragment>
                      <Button
                        style={{ marginRight: "20px", marginBottom: "10px", backgroundColor: "#0D8DA3" }}
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          this.acceptRejectOrder(2);
                        }}
                      >
                        Accept
                      </Button>
                      <Button
                        style={{ marginRight: "20px", marginBottom: "10px" }}
                        size="small"
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                          this.setState({ isReject: true });
                        }} 
                      >
                        Reject
                      </Button>
                    </React.Fragment>
                  )}
                {
                  user.userType === 1 && (
                    <React.Fragment>
                      <Button
                        style={{
                          marginLeft: "10px",
                          marginBottom: "10px",
                          display: "inline-block",
                          backgroundColor: "#0D8DA3"
                        }}
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          let start = "";
                          let end = "";
                          if (selectedOrders.length > 0) {
                            start =
                              (
                                orders.find((el) => {
                                  return el.orderId === selectedOrders[0];
                                }) || {}
                              ).pickupStart || "";
                            end =
                              (
                                orders.find((el) => {
                                  return el.orderId === selectedOrders[0];
                                }) || {}
                              ).pickupEnd || "";
                          }
                          this.setState({
                            slotFrom: new Date(start).getTime(),
                            slotTo: new Date(end).getTime(),
                            isOrderAssign: true,
                          });
                        }}
                      >
                        Assign Phlebo
                      </Button>
                      {this.state.type ===4 ? <Button
                        style={{
                          marginLeft: "10px",
                          marginBottom: "10px",
                          display: "inline-block",
                          backgroundColor: "#0D8DA3"
                        }}
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          let start = "";
                          let end = "";
                          if (selectedOrders.length > 0) {
                            start =
                              (
                                orders.find((el) => {
                                  return el.orderId === selectedOrders[0];
                                }) || {}
                              ).pickupStart || "";
                            end =
                              (
                                orders.find((el) => {
                                  return el.orderId === selectedOrders[0];
                                }) || {}
                              ).pickupEnd || "";
                          }
                          this.setState({
                            slotFrom: new Date(start).getTime(),
                            slotTo: new Date(end).getTime(),
                            isDoctorAssign: true,
                          });
                        }}
                      >
                        Assign Doctor
                      </Button> : null }
                      <Avatar
                        style={{
                          width: "20px",
                          height: "20px",
                          marginLeft: "10px",
                          display: "inline-block",
                        }}
                        src={StatusIcon}
                        size="small"
                        alt="status"
                        onClick={this.getAssignStatus}
                      />
                    </React.Fragment>
                  )}

                <IconButton
                  edge="end"
                  color="inherit"
                  size="small"
                  style={{ fontSize: "12px", marginBottom: "10px" }}
                  onClick={() => {
                    this.getOrders();
                  }}
                >
                  <Refresh /> Refresh
                </IconButton>
              </div>
              <div className="order-table">
                <TableContainer component={Paper} className="mb-30">
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        {this.state.type !== 70 ? <TableCell /> : null}
                        <TableCell />
                        <TableCell> Order # </TableCell>
                        <TableCell> LGP Name </TableCell>
                        <TableCell> Lab Name </TableCell>
                        <TableCell> Customer Detail </TableCell>
                        <TableCell> Pickup </TableCell>
                        <TableCell> Address </TableCell>
                        <TableCell> Payment </TableCell>
                        <TableCell> View </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(orders || []).length <= 0 && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {" "}
                            No order found.{" "}
                          </TableCell>
                        </TableRow>
                      )}
                      {(orders || []).map((order) => {
                        const mode = PAYMENT_MODE.filter(
                          (el) => el.id === order.paymentMode
                        );
                        const assignedStatus = ORDER_ASSIGNED_STATUS.filter(
                          (el) => el.id === order.orderAssignedStatus
                        );
                        const orderType = [
                          ...ORDER_CONSTANTS,
                          ...PAST_TABS,
                        ].find((el) => {
                          return el.id === order.type;
                        });
                        const orderTypee = ORDER_TYPE.filter((el) => {
                          return el.id === order.orderType;
                        });

                        const ele = (
                          <React.Fragment key={order.orderId}>
                            <TableRow>
                              {this.state.type !== 70 ? (
                                <TableCell>
                                  <Checkbox
                                    size="small"
                                    color="primary"
                                    checked={selectedOrders.includes(
                                      order.orderId
                                    )}
                                    onChange={(e) => {
                                      const { selectedOrders } = this.state;
                                      if (
                                        selectedOrders.includes(order.orderId)
                                      ) {
                                        selectedOrders.splice(
                                          selectedOrders.indexOf(order.orderId),
                                          1
                                        );
                                      } else {
                                        selectedOrders.push(order.orderId);
                                      }
                                      this.setState({ selectedOrders });
                                    }}
                                  />
                                </TableCell>
                              ) : null}
                                 <TableCell>
                                {order.orderPriority === 'Escalation' ? <div>
                                  <Star fontSize='small' color='primary'/><br/>
                                  <Star fontSize='small' color='primary'/><br/>
                                  <Star fontSize='small' color='primary'/>
                                </div> : null}
                                {order.orderPriority === 'VIP' ? <div>
                                  <Star fontSize='small' color='primary'/><br/>
                                  <Star fontSize='small' color='primary'/>
                                </div> : null}
                                {order.orderPriority === 'Age' ? <div>
                                  <Star fontSize='small' color='primary'/>
                                </div> : null}
                              </TableCell>

                              <TableCell>
                                <div>{order.orderSequence}</div>
                                <div>
                                  <span className="title-color">VON:</span>{" "}
                                  {order.vendorOrderNumber}{" "}
                                </div>
                                <div>
                                  <span className="title-color">Created:</span>{" "}
                                  {moment(order.createdTime).format(
                                    "D MMM, YY h:mm A"
                                  )}{" "}
                                </div>
                                {assignedStatus.length > 0 && (
                                   <>
                                   {order.errorMessage ? <Tooltip title={order.errorMessage} placement="top" >
                                   <div>
                                     <span className="title-color">
                                       Event:{" "}
                                     </span>{" "}
                                    <span className="status-box fs-10">
                                       {assignedStatus[0].name.replace(
                                         /_/g,
                                         " "
                                       )}{" "}
                                     </span>{" "}
                                   </div>
                                   </Tooltip>
                                   :  <div>
                                   <span className="title-color">
                                     Event:{" "}
                                   </span>{" "}
                                  <span className="status-box fs-10">
                                     {assignedStatus[0].name.replace(
                                       /_/g,
                                       " "
                                     )}{" "}
                                   </span>{" "}
                                 </div>
                                 }
                                   </>
                                )}
                                Type :{" "}
                                {orderTypee.length > 0
                                  ? orderTypee[0].name
                                  : "-"}
                              </TableCell>
                              <TableCell>{order.lgpName}</TableCell>
                              <TableCell>{order.labName}</TableCell>
                              <TableCell>
                                <div>
                                  {" "}
                                  <span className="title-color">
                                    Name:
                                  </span>{" "}
                                  {order.customerName}
                                </div>
                                <div>
                                  {" "}
                                  <span className="title-color">
                                    Contact:
                                  </span>{" "}
                                  {order.customerContact}
                                  <IconButton
                                    edge="end"
                                    color="inherit"
                                    style={{ width: 20 }}
                                    size="small"
                                    onClick={() =>
                                      this.handlePhoneClick(
                                        order.orderId,
                                        order.customerContact
                                      )
                                    }
                                  >
                                    <Phone />
                                  </IconButton>
                                </div>
                              </TableCell>
                              <TableCell>
                                <div>
                                  {moment(order.pickupStart).format(
                                    "D MMM, YY h:mm A"
                                  )}{" "}
                                  -
                                </div>
                                <div>
                                  {moment(order.pickupEnd).format(
                                    "D MMM, YY h:mm A"
                                  )}
                                </div>
                                {order.phleboName ? (
                                  <div>
                                    {" "}
                                    <br />
                                    Phlebo: {order.phleboName}
                                    {!isPartner && (
                                      <span
                                        className="cross-icon"
                                        onClick={() => {
                                          this.setState({
                                            deleteObj: { ...order },
                                            isDeleteOpen: true,
                                          });
                                        }}
                                      >
                                        {" "}
                                        X{" "}
                                      </span>
                                    )}
                                  </div>
                                ) : null}
                                <div>{order.mobileNumber}</div>
                                {order.doctorName ? (
                                  <div>
                                    {" "}
                                    <br />
                                    Doctor: {order.doctorName}
                                    {!isPartner && (
                                      <span
                                        className="cross-icon"
                                        onClick={() => {
                                          this.setState({
                                            deleteObj: { ...order },
                                            unassignDocOpen: true,
                                          });
                                        }}
                                      >
                                        {" "}
                                        X{" "}
                                      </span>
                                    )}
                                  </div>
                                ) : null}
                                <div>{order.doc_contactNumber}</div>
                              </TableCell>
                              <TableCell style={{ width: "200px" }}>
                                {order.address1} {order.address2}{" "}
                                {order.zipcode}
                              </TableCell>
                              <TableCell>
                                {mode && mode.length > 0 && (
                                  <div>
                                    {" "}
                                    <span className="title-color">
                                      Mode:
                                    </span>{" "}
                                    {mode[0].name}{" "}
                                  </div>
                                )}
                                {order.prepaidAmount && (
                                  <div>
                                    {" "}
                                    <span className="title-color">
                                      Prepaid:
                                    </span>{" "}
                                    {order.prepaidAmount}{" "}
                                  </div>
                                )}
                                {order.amountToCollect && (
                                  <div>
                                    {" "}
                                    <span className="title-color">
                                      To Collect:
                                    </span>{" "}
                                    {order.amountToCollect}
                                  </div>
                                )}
                              </TableCell>

                              <TableCell>
                                <IconButton
                                  edge="end"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    this.redirectToDetail(order);
                                  }}
                                >
                                  <Visibility />
                                </IconButton>

                                {this.state.type != 70 ? (
                                  <div
                                    style={{ cursor: "pointer", color: "blue" }}
                                    onClick={() => {
                                      this.setState({
                                        isNewConfirm: true,
                                        newOrderObj: { ...order },
                                      });
                                    }}
                                  >
                                    {userType === 1 ? "New" : ""}
                                  </div>
                                ) : null}
                                {this.state.type === 4 ? (
                                  <>
                                    <div
                                      style={{
                                        cursor: "pointer",
                                        color: "blue",
                                      }}
                                      onClick={() =>
                                        this.handleTrackClick(order.orderId)
                                      }
                                    >
                                      Track
                                    </div>
                                    <div
                                      style={{
                                        cursor: "pointer",
                                        color: "blue",
                                      }}
                                      onClick={() =>
                                        this.setState({
                                          isShowOpen: !this.state.isShowOpen,
                                          showObj: order,
                                        })
                                      }
                                    >
                                      Show
                                    </div>
                                  </>
                                ) : null}
                              </TableCell>
                            </TableRow>
                            <TableRow key={order.orderId}>
                              {this.state.type === 70 ? (
                                <React.Fragment>
                                  <TableCell>
                                    <div>
                                      PCT :{" "}
                                      {this.getPhleboRejectionReason(
                                        order.phleboCancelReason
                                      )}
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div>
                                      PCR : order.phleboComments
                                      {this.getPhleboRejectionReasonType(
                                        order.orderCancelCode
                                      )}
                                    </div>
                                  </TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell>
                                    PCC : {order.orderCancelComments}
                                  </TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell>
                                    <Button
                                      style={{ backgroundColor: "#3f51b5" }}
                                      variant="contained"
                                      color="primary"
                                      onClick={() => {
                                        this.setState({
                                          isAcceptOpen: true,
                                          acceptObj: order,
                                        });
                                      }}
                                    >
                                      Accept
                                    </Button>
                                    &nbsp;&nbsp;
                                    <Button
                                      style={{ backgroundColor: "#f50057" }}
                                      variant="contained"
                                      color="primary"
                                      onClick={() => {
                                        this.setState({
                                          isRejectOpen: true,
                                          acceptObj: order,
                                        });
                                      }}
                                    >
                                      Reject
                                    </Button>
                                  </TableCell>
                                </React.Fragment>
                              ) : (
                                ""
                              )}
                            </TableRow>
                          </React.Fragment>
                        );

                        let errorRow = null;
                        if (order.errorType) {
                          errorRow = (
                            <TableRow style={{ background: "#fac1c1" }}>
                              <TableCell colSpan={1} />
                              <TableCell colSpan={7} align="center">
                                {order.errorMessage}{" "}
                              </TableCell>
                              <TableCell colSpan={1} />
                            </TableRow>
                          );
                        }

                        return [ele, errorRow];
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              {this.renderPagination()}

              {isOpen && this.renderPopup()}

              {isOrderAssign && this.renderAssignPopup()}
              {isDoctorAssign && this.renderAssignDoctor()}

              {isDeleteOpen && this.renderDeletePopup()}
              {unassignDocOpen && this.renderUnassignDoctor()}
              {isShowOpen && this.renderShowConfirmPopup()}

              {isAssignStatus && this.renderAssignStatusData()}

              {(isNewConfirm || isAllNew) &&
                this.renderIsNewConfirmationPopup()}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid container spacing={3}>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => {
                    this.redirectToList();
                  }}
                >
                  <ArrowBackOutlined />
                </IconButton>
                <Grid item xs={isMobile ? 10 : 4}>
                  {" "}
                  <h2 className="mt-0">ORDER DETAILS</h2>{" "}
                </Grid>
                <Grid item xs={isMobile ? 12 : 3}>
                  <div
                    style={{
                      textAlign: isMobile ? "left" : "right",
                      fontSize: "13px",
                    }}
                  >
                    Phlebo:{" "}
                    {data.firstName +
                      " " +
                      data.lastName +
                      "--" +
                      data.mobileNumber}
                  </div>
                </Grid>
                <Grid item xs={isMobile ? 6 : 2}>
                  <div
                    style={{
                      textAlign: isMobile ? "left" : "right",
                      fontSize: "13px",
                    }}
                  >
                    Order No: {data.order_seq}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={isMobile ? 6 : 2}
                  style={{
                    textAlign: isMobile ? "left" : "right",
                    fontSize: "13px",
                  }}
                >
                  {Object.keys(selectedtype).length > 0 && (
                    <div className="mb-20">Status: {selectedtype.name}</div>
                  )}
                  {assndStatus.length > 0 && (
                    <div>
                      <span className="status-box">
                        {assndStatus[0].name.replace(/_/g, " ")}{" "}
                      </span>{" "}
                    </div>
                  )}
                </Grid>
               <Grid item xs={12} style={{display:'flex', flexDirection:'row', justifyContent:'right'}}>
                  <Button color={'primary'} variant={'contained'}onClick={this.handleClickVitals}>Vitals/VMER</Button>
               </Grid>
              </Grid>
              <Tabs
                scrollButtons={isMobile ? "on" : "off"}
                variant="scrollable"
                indicatorColor="primary"
                value={this.state.tab}
                onChange={(e, tab) => {
                  this.setState({ tab }, () => {
                    if (tab === 3) {
                      this.getReports();
                    }
                  });
                }}
              >
                <Tab label="Basic" value={1} />
                <Tab label="Logs" value={2} />
                <Tab label="Reports" value={3} />
                <Tab label="PPMC" value={4} onClick={() => { this.handlePpmc() }} />
                <Tab label="Life Cycle" value={5} />
                <Tab label="Download" value={6} />
                </Tabs>
              <TabPanel value={this.state.tab} index={1}>
                {this.renderBasicDetails(isKYC, isPartner)}
              </TabPanel>
              <TabPanel value={this.state.tab} index={2}>
                {this.renderLogDetails(isPartner)}
              </TabPanel>
              <TabPanel value={this.state.tab} index={3}>
                {this.renderReports()}

                {isReportDelete && this.renderLabReportDeletePopup()}
              </TabPanel>
              <TabPanel value={this.state.tab} index={4}>
                {Object.keys(this.state.data).length ? (
                  <div className="ppmc_images">
                    {this.state.data.ppmc_id ? null : <div style={{ textAlign: 'right' }}><Button variant='contained' style={{ backgroundColor: '#8CADF' }} >Generate Document</Button></div>}
                    <div>
                      <h1>PPMC ID</h1>
                      {this.state.data.ppmc_id.map((img) => (
                        <span>{<img src={img} alt="ppmc_id Image" />}</span>
                      ))}
                      <hr />
                    </div>

                    <div>
                      <h1>PPMC LIVE</h1>
                      {this.state.data.ppmc_live.map((img) => (
                        <span>{<img src={img} alt="ppmc_live Image" />}</span>
                      ))}
                      <hr />
                    </div>
                    <div>
                      <h1>PPMC MER</h1>
                      {this.state.data.ppmc_mer.map((pdflink) => (
                        <span>
                          {
                            <a
                              href={pdflink}
                              target="_blank"
                              style={{
                                background: "#00c9aa",
                                color: "#fff",
                                padding: "10px 20px",
                                borderRadius: "5px",
                                marginBottom: "20px",
                                fontSize: "16px",
                              }}
                            >
                              <Visibility style={{ marginBottom: "-4px" }} />{" "}
                              View Document
                            </a>
                          }
                        </span>
                      ))}
                      <hr style={{ marginTop: "25px" }} />
                    </div>
                    <div>
                      <h1>PPMC NAME CHANGE</h1>
                      {this.state.data.ppmc_namechange.map((img) => (
                        <span>
                          {<img src={img} alt="ppmc_namechange Image" />}
                        </span>
                      ))}
                      <hr />
                    </div>
                    <div>
                      <h1>PPMC V-MER</h1>
                      {this.state.numbers && this.state.numbers.map((item) => (
                            <a href={item.Vmer_Url} target="_blank" style={{ fontSize: '15px', border: '1px solid transparent', padding: '5px', backgroundColor: '#0D8DA3', color: '#ffff', borderRadius: '5px', marginRight: '10px' }}>Video {item.s_no}</a>
                      ))}
                      <hr />
                    </div> 
                    <div>
                      <h1>Vitals</h1>
                      <div style={{ padding: 5 }}>

              <Card className="p-5">
              
               
                {this.state.vitals.customer_name ? <Grid item xs={6} >
                  <Grid container spacing={2} > 
                  <Grid item xs={8} textAlign='center'><Typography variant={'h6'} weight='bold' sx={{ textAlign: 'center' }}>{this.state.vitals.customer_ame}Vitals</Typography></Grid>
                  <Grid item xs={4} style={{textAlign:'right'}}>
                    <IconButton onClick={() => { this.handleRefreshVitals() }}><Refresh /></IconButton>
                  </Grid>
                  </Grid>
                
                  <FormControl fullWidth disabled>
                    <FormHelperText id="standard-weight-helper-text">Blood Pressure(Reading-1)</FormHelperText>
                    <OutlinedInput

                      id="standard-adornment-weight"
                      value={`${this.state.vitals.bp1_Systolic}/${this.state.vitals.bp1_Diastolic}`}
                      startAdornment={<InputAdornment position="start"><img src={BloodPressure} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                      endAdornment={<InputAdornment position="end">mmHg</InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />

                  </FormControl>
                  <FormControl fullWidth disabled>
                    <FormHelperText id="standard-weight-helper-text">Blood Pressure(Reading-2)</FormHelperText>
                    <OutlinedInput

                      id="standard-adornment-weight"
                      value={`${this.state.vitals.bp2_Systolic}/${this.state.vitals.bp2_Diastolic}`}
                     startAdornment={<InputAdornment position="start"><img src={BloodPressure} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                      endAdornment={<InputAdornment position="end">mmHg</InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />

                  </FormControl>
                  <FormControl fullWidth disabled>
                    <FormHelperText id="standard-weight-helper-text">Blood Pressure(Reading-3)</FormHelperText>
                    <OutlinedInput

                      id="standard-adornment-weight"
                      value={`${this.state.vitals.bp3_Systolic}/${this.state.vitals.bp3_Diastolic}`}
                       startAdornment={<InputAdornment position="start"><img src={BloodPressure} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                      endAdornment={<InputAdornment position="end">mmHg</InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />

                  </FormControl>
                  <FormControl fullWidth disabled>
                    <FormHelperText id="standard-weight-helper-text">Height</FormHelperText>
                    <OutlinedInput

                      id="standard-adornment-weight"
                      value={this.state.vitals.height}
                      startAdornment={<InputAdornment position="start"><img src={Height} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                      endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth disabled>
                    <FormHelperText id="standard-weight-helper-text">Weight</FormHelperText>
                    <OutlinedInput

                      id="standard-adornment-weight"
                      value={this.state.vitals.weight}
                       startAdornment={<InputAdornment position="start"><img src={Weight} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                      endAdornment={<InputAdornment position="end">kgs</InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth disabled>
                    <FormHelperText id="standard-weight-helper-text">Chest Expiration</FormHelperText>
                    <OutlinedInput

                      id="standard-adornment-weight"
                      value={this.state.vitals.chest_Expiration}
                      startAdornment={<InputAdornment position="start"><img src={Heart} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                      endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />

                  </FormControl>

                  <FormControl fullWidth disabled>
                    <FormHelperText id="standard-weight-helper-text">Chest Inspiration</FormHelperText>
                    <OutlinedInput

                      id="standard-adornment-weight"
                      value={this.state.vitals.chest_Inspiration}
                       startAdornment={<InputAdornment position="start"><img src={Heart} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                      endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />

                  </FormControl>
                  <FormControl fullWidth disabled >
                    <FormHelperText id="standard-weight-helper-text">Abdomen Girth</FormHelperText>
                    <OutlinedInput

                      id="standard-adornment-weight"
                      value={this.state.vitals.abdomen_Girth}
                       startAdornment={<InputAdornment position="start"><img src={Abdomen} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                      endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth disabled >
                    <FormHelperText id="standard-weight-helper-text">Hip</FormHelperText>
                    <OutlinedInput

                      id="standard-adornment-weight"
                      value={this.state.vitals.hip}
                       startAdornment={<InputAdornment position="start"><img src={Waist} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                      endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth disabled >
                    <FormHelperText id="standard-weight-helper-text">Waist</FormHelperText>
                    <OutlinedInput

                      id="standard-adornment-weight"
                      value={this.state.vitals.waist}
                      startAdornment={<InputAdornment position="start"><img src={Waist} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                      endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth disabled >
                    <FormHelperText id="standard-weight-helper-text">Hip Waist Ratio</FormHelperText>
                    <OutlinedInput

                      id="standard-adornment-weight"
                      value={this.state.vitals.hipWaistRatio}
                      startAdornment={<InputAdornment position="start"><img src={Waist} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                      endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth disabled>
                    <FormHelperText id="standard-weight-helper-text">Pulse</FormHelperText>
                    <OutlinedInput
                      id="standard-adornment-weight"
                      value={this.state.vitals.pulse}
                      startAdornment={<InputAdornment position="start"><img src={Pulse} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                      endAdornment={<InputAdornment position="end">bpm</InputAdornment>}
                      aria-describedby="standard-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                  </FormControl>
                </Grid> : <Grid item xs={12} container spacing={1}>
                  <Grid item xs={8}>
                  <Typography variant='h6' weight='bold'>Vitals not submitted</Typography>
                  </Grid>
                  <Grid item xs={4} style={{textAlign:'right'}}>
                    <IconButton onClick={() => { this.handleRefreshVitals() }}><Refresh /></IconButton>
                  </Grid>
                  </Grid>}
              </Card>
            </div>
                    </div>
                  </div>
                ) : (
                  <h1>No this.state.data</h1>
                )}
              </TabPanel>
              <TabPanel value={this.state.tab} index={5}>
                <Lifecycle
                  orderId={this.state.data.orderId || ""}
                  orderSequence={this.state.data.orderSequence || ""}
                />
              </TabPanel>
              <TabPanel value={this.state.tab} index={6}>
                <Download
                  orderId={this.state.data.orderId || ""}
                  orderSequence={this.state.data.orderSequence || ""}
                />
              </TabPanel>
            </React.Fragment>
          )}

          {this.state.addOrder && (
            <CreateOrder
            isPartner={isPartner}
              city={this.state.city}
              cityFilterDisable={this.state.cityFilterDisable}
              closePopup={() =>
                this.setState({ addOrder: false }, () =>
                  this.getPlebosForFilter()
                )
              }
              saveNewOrder={(newOrder) => this.addNewOrder(newOrder)}
            />
          )}
        </div>
        <ImageSlider images={this.state.proofImages} />
        <Dialog
        fullScreen
        open={this.state.openVitals}
        onClose={this.handleClose}
      >
        {this.state.openAlert && <Alert severity="warning" autoHideDuration={6000}>Please end the call and try again</Alert>}
        <Box style={{display:'flex', flexDirection:'row', justifyContent:'left'}}>
        <Tooltip title='Close'><IconButton
              edge="start"
              color="inherit"
              onClick={this.handleClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
            </Tooltip>
            </Box>
        {this.renderVitals()}
        </Dialog>
      </DocContainer>
    );
  }
}

const mapStateToProps = ({
  orders,
  plebos,
  constants,
  lgp,
  typeValue,
}) => {
  return {
    isLoading: orders.isSubmitting,
    orders: orders.orders,
    orderData: orders.orderData,
    vitalsData: orders.vitalsData,
    hasMore: orders.hasMore,
    labReports: orders.labReports,
    typeValue: orders.typeValue,
    dateValue: orders.dateValue,
    cityValue: orders.cityValue,
    plebos: plebos.plebos,
    cities: constants.cities,
    doctorsList: lgp.doctorsList,
    lgps: lgp.plebos,
  };
};

export default withRouter(connect(mapStateToProps)(Orders)); withStyles(useStyles, { withTheme: true })(Orders);
