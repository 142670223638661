/* eslint-disable jsx-control-statements/jsx-use-if-tag */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import {
  AgoraVideoPlayer,
  createClient,
  createMicrophoneAndCameraTracks,
} from "agora-rtc-react";

import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import CallEndIcon from "@material-ui/icons/CallEnd";
import axios from 'axios';
import { Alert } from "@material-ui/lab";

import "./index.css";
import { Grid, Button, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions } from "@material-ui/core";
import ActionLoader from "Components/ActionLoader/index";

const config = {
  mode: "rtc",
  codec: "vp8",
};

const appId = "db030539178f4ec591667e10e7b6eb9b"; //ENTER APP ID HERE
const uid = "128881";
const Agora = (props) => {
  const { tempToken } = props;
  const { cname } = props;
  const cName = cname && cname.toString();
  const { channel = "" } = props;
  const [channelName, setChannelName] = useState(cName);
  const [inCall, setInCall] = useState(channel ? true : false);
  const [fileName, setFileName] = useState("");
  const [isFileSent, setIsFileSent] = useState(false);
  const [join, setJoin] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const callBackFunction = (fileData) => {
    setFileName(fileData);
    props.fileData(fileData);
  }

  const callBackJoinStatus = (joinStatus) => {
    setJoin(joinStatus);
    props.joinStatus(joinStatus)
  }

  const callBackOpenToast = (openToast) => {
    setOpenAlert(openToast);
  }


  return (
    <>
      {inCall ? (
        <VideoCall
          setInCall={setInCall}
          channelName={channelName}
          fileData={callBackFunction}
          fileSent={callBackFunction}
          tempToken={tempToken}
          joinStatus={callBackJoinStatus}
          openToast={callBackOpenToast}
        // setIsJoined={setIsJoined}
        />
      ) : (
        <ChannelForm setInCall={setInCall} setChannelName={setChannelName} openAlert={openAlert} />
      )}
    </>
  );
};

// the create methods in the wrapper return a hook
// the create method should be called outside the parent component
// this hook can be used the get the client/stream in any component
const useClient = createClient(config);
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

const VideoCall = (props) => {
  const { setInCall, channelName, tempToken, setIsJoined } = props;
  const [users, setUsers] = useState([]);
  const [start, setStart] = useState(false);

  // using the hook to get access to the client object
  const client = useClient();
  // ready is a state variable, which returns true when the local tracks are initialized, untill then tracks variable is null
  const { ready, tracks } = useMicrophoneAndCameraTracks();

  const [fileName, setFileName] = useState("");
  const [join, setJoin] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);


  const callBackFunction = (fileData) => {
    setFileName(fileData);
    props.fileData(fileData);
  }

  const callBackJoinStatus = (joinStatus) => {
    setJoin(joinStatus);
    props.joinStatus(joinStatus)
  }

  const callBackOpenToast = (openToast) => {
    setOpenAlert(openToast);
    props.openToast(openToast);
  }

  useEffect(() => {
    // function to initialise the SDK
    let init = async (name) => {
      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        if (mediaType === "video") {
          setUsers((prevUsers) => {
            return [...prevUsers, user];
          });
        }
        if (mediaType === "audio") {
          if (user && user.audioTrack) {
            user.audioTrack.play();
          }
        }
      });

      client.on("user-unpublished", (user, type) => {
        if (type === "audio") {
          if (user && user.audioTrack) {
            user.audioTrack.stop();
          }
        }
        if (type === "video") {
          setUsers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== user.uid);
          });
        }

      });

      client.on("user-left", (user) => {
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });
      await client.join(appId, name, tempToken, uid);
      if (tracks) await client.publish([tracks[0], tracks[1]]);
      props.joinStatus(true);
      setStart(true);
    };

    if (ready && tracks) {
      init(channelName);
    }



  }, [channelName, client, ready, tracks]);


  return (
    <div>
      {start && tracks && (
        <Videos
          ready={ready}
          setStart={setStart}
          setInCall={setInCall}
          fileData={callBackFunction}
          joinStatus={callBackJoinStatus}
          openToast={callBackOpenToast}
          users={users}
          tracks={tracks}
          tempToken={tempToken}
          channelName={channelName}
        />
      )}
    </div>
  );
};

const Videos = (props) => {
  const { users, ready, tracks, setInCall, setStart, tempToken, channelName, setIsJoined } = props;
  const [fileName, setFileName] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [join, setJoin] = useState(false);

  const callBackFunction = (fileData) => {
    setFileName(fileData);
    props.fileData(fileData);
  }

  const callBackJoinStatus = (joinStatus) => {
    setJoin(joinStatus);
    props.joinStatus(joinStatus)
  }

  const callBackOpenToast = (openToast) => {
    setOpenAlert(openToast);
    props.openToast(openToast);
  }

  return (
    <div id="agoraVideo">
      {ready && tracks && (
        <Controls
          tracks={tracks}
          setStart={setStart}
          setInCall={setInCall}
          fileData={callBackFunction}
          joinStatus={callBackJoinStatus}
          users={users}
          tempToken={tempToken}
          channelName={channelName}
          openToast={callBackOpenToast}
        />
      )}
      {/* AgoraVideoPlayer component takes in the video track to render the stream,
            you can pass in other props that get passed to the rendered div */}
      <AgoraVideoPlayer
        className={users.length > 0 ? "videoSmall" : "videoBig"}
        videoTrack={tracks[1]}
      />
      {users.length > 0 &&
        users.map((user) => {
          if (user.videoTrack) {
            return (
              <AgoraVideoPlayer
                className="videoBig"
                videoTrack={user.videoTrack}
                key={user.uid}
              />
            );
          } else return null;
        })}
    </div>
  );
};

export const Controls = (props) => {
  const client = useClient();
  const { tracks, setStart, setInCall, users, channelName, tempToken } = props;
  const [trackState, setTrackState] = useState({ video: true, audio: true });
  const [startRec, setStartRec] = useState(false);
  const [sid, setSid] = useState({});
  const [confirmStart, setConfirmStart] = useState(false);
  const [confirmStop, setConfirmStop] = useState(false);
  const [resourceId, setResourceId] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);

 
  const mute = async (type) => {
    if (type === "audio") {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === "video") {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState((ps) => {
        return { ...ps, video: !ps.video };
      });
    }
  };



  const leaveChannel = async () => {
    await client.leave();
    client.removeAllListeners();
    tracks[0].close();
    tracks[1].close();
    setStart(false);
    setInCall(false);
    props.joinStatus(false);
  };

  const acquireResourceId = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Basic ZTMyMmRkZTBjY2E0NDBkMzhiYThhMGZiNDM4MzA3Yzc6OGMxYjYwODhmZDViNGVlYmJjZmFjMWJlMmMyMTIyMTQ="
    }

    axios.post(`https://api.agora.io/v1/apps/${appId}/cloud_recording/acquire`, {
      cname: channelName,
      uid: '128881',
      clientRequest: {

      }
    }, { headers: headers }).then(function (response) {
      const dataJSON = response.data;
      setResourceId(dataJSON);

    })
  }
  const startRecording = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Basic ZTMyMmRkZTBjY2E0NDBkMzhiYThhMGZiNDM4MzA3Yzc6OGMxYjYwODhmZDViNGVlYmJjZmFjMWJlMmMyMTIyMTQ="
    }
    try {
    await axios.post(`https://api.agora.io/v1/apps/${appId}/cloud_recording/resourceid/${resourceId.resourceId}/mode/mix/start`, {
        uid: "128881",
        cname: channelName,
        clientRequest: {
          token: tempToken,
          recordingConfig: {
            streamTypes: 2,
            audioProfile: 1,
            channelType: 0,
            videoStreamType: 0,
            transcodingConfig: {
              height: 720,
              width: 1280,
              bitrate: 1130,
              fps: 15,
              mixedVideoLayout: 1,
              mixedVideoAudio: 2,
            },
            subscribeUidGroup: 0
          },
          recordingFileConfig: {
            avFileType: [
              "mp4", "hls"
            ]
          },
          storageConfig: {
            accessKey: process.env.REACT_APP_AWS_ACCESS_KEY,
            region: 14,
            bucket: "i2h-sandbox",
            secretKey: process.env.REACT_APP_AWS_SECRET_KEY,
            vendor: 1,
            fileNamePrefix: [
              "thinkhealth",
              "recordings"
            ]
          }
        }
      }, {
        headers: headers
      }).then(function (response) {
        const dataJSON = response.data;
        setSid(dataJSON);
        setStartRec(true);

      })
    } catch (error) {
      console.log(error);
    }
  }
  const stopRecording = async () => {
    setIsProcessing(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Basic ZTMyMmRkZTBjY2E0NDBkMzhiYThhMGZiNDM4MzA3Yzc6OGMxYjYwODhmZDViNGVlYmJjZmFjMWJlMmMyMTIyMTQ="
    }
    try {
     await axios.post(`https://api.agora.io/v1/apps/${appId}/cloud_recording/resourceid/${resourceId.resourceId}/sid/${sid.sid}/mode/mix/stop`, {
        cname: channelName,
        uid: "128881",
        clientRequest: {

        }
      }, {
        headers: headers
      }).then(function (response) {
        const dataJSON = response.data;
        const file = dataJSON && dataJSON.serverResponse && dataJSON.serverResponse.fileList[0] && dataJSON.serverResponse.fileList[0].fileName;
        props.fileData(file);
        setStartRec(false);
        setIsProcessing(false);
        leaveChannel();
        props.openToast(true)

      })
    } catch (error) {
      console.log(error);
    }

  }

  const handleConfirmOpen = () => {
    setConfirmStart(true);
    acquireResourceId();
  }
  const handleStartRecording = () => {
    setConfirmStart(false);
    startRecording();
  }
  const handleClose = () => {
    setConfirmStart(false);
  }
  const handleConfirmStop = () => {
    setConfirmStop(true);
  }

 
  const handleStopRecording = () => {
    setConfirmStop(false);
    stopRecording();
  }

  const handleCloseStop = () => {
    setConfirmStop(false);
  }


  return (
    <div className="control">
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <p className={trackState.audio ? "on" : ""} onClick={() => mute("audio")}>
            {trackState.audio ? <MicIcon /> : <MicOffIcon />}
          </p>
        </Grid>
        <Grid item xs={3}>
          <p className={trackState.video ? "on" : ""} onClick={() => mute("video")}>
            {trackState.video ? <VideocamIcon /> : <VideocamOffIcon />}
          </p>
        </Grid>
        {startRec ? null : <Grid item xs={3}>
          <p onClick={() => leaveChannel()}>
            <CallEndIcon />
          </p>
        </Grid>}
        <Grid item xs={startRec ? 6 : 3}>
          {!startRec ? 
          <Button 
          variant="contained" 
          color='primary' 
          style={{ marginTop: '2em' }} 
          onClick={handleConfirmOpen}>Start Rec</Button> : isProcessing ? <Button 
          disabled
          variant="contained" 
          style={{ marginTop: '2em' }} 
          color='error' >Stop Rec / End Call</Button> : 
          <Button 
          variant="contained" 
          color='error' 
          style={{ backgroundColor: '#b80b22', color: '#ffffff', width:'100%', marginTop: '2em' }}
           onClick={handleConfirmStop}>Stop Rec / End Call</Button>}
        </Grid>
      </Grid>
      <Dialog
        open={confirmStart}
        onClose={handleClose}
        maxWidth={'xs'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Start Recording"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to start the recording?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleStartRecording} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmStop}
        onClose={handleCloseStop}
        maxWidth={'xs'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Stop Recording"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to stop the recording and end the call?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseStop}>Cancel</Button>
          <Button onClick={handleStopRecording} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
{isProcessing && <ActionLoader/>}
    </div>
  );
};

const ChannelForm = (props) => {
  const { setInCall, setChannelName, openAlert } = props;



  return (
    <div>
      {openAlert && <Alert severity="success" autoHideDuration={6000}>Call Ended. Recording Saved Successfully</Alert>}
      <form className="join">
        {appId === "" && (
          <p style={{ color: "red" }}>
            Please enter your Agora App ID in App.tsx and refresh the page
          </p>
        )}
        <Button
          onClick={(e) => {
            e.preventDefault();
            setInCall(true);
          }}
        >
          Join
        </Button>
      </form>
    </div>
  );
};

export default Agora;
