import { call, put } from "redux-saga/effects";

import {
  postDataWithToken,
  getDataWithToken,
  deleteDataWithToken,
  GET_LAB_TEST_BY_LABID_URL,
  ADD_LAB_URL,
  GET_LABS_URL,
  GET_LAB_USERS_BY_LABID_URL,
  ADD_LAB_USERS_URL,
  EDIT_LAB_USERS_URL,
  DEL_LAB_USERS_URL,
  GET_LAB_BY_ID_URL,
  EDIT_LAB_URL,
  DEL_LAB_URL,
  DEL_LAB_TEST_URL,
  EDIT_LAB_TEST_URL,
  ADD_LAB_TEST_URL,
  putDataWithToken,
} from "Utils/api";

import {
  IS_SUBMITTING,
  LABS_LIST,
  LAB_USERS_LIST,
  LAB_TEST_LIST,
  TOAST_ERROR,
  TOAST_SUCCESS,
} from "Constants/actionConstants";

export function* getLabs(action) {
  try {
    const url =
      action.data && action.data.id ? GET_LAB_BY_ID_URL : GET_LABS_URL;

    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(getDataWithToken, url, action.data);
    yield put({
      type: LABS_LIST,
      data: data && data.labList ? data.labList : [],
      hasMore:
        data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false,
    });

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

// export function* addLab(action) {
//         try{
//             const url = (action.data && action.data.isEdit) ? EDIT_LAB_URL : ADD_LAB_URL;
//             yield put({ type : IS_SUBMITTING, isSubmitting: true });

//             const data = yield call(postDataWithToken, url, action.data);

//             yield put({ type : IS_SUBMITTING, isSubmitting: false });

//             yield put({
//                 type: TOAST_SUCCESS,
//                 message: data && data.message?data.message : "Data updated successfully!"
//             })

//             if (action.onSuccess) {
//                 action.onSuccess(data);
//             }
//         } catch(e) {
//             yield put({ type : IS_SUBMITTING, isSubmitting: false })
//             yield put({
//                 type: TOAST_ERROR,
//                 message: e && e.error?e.error:"Oops! Something went wrong"
//             })
//     }

// }

export function* deleteLab(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const url =
      action.data && action.data.labTestId ? DEL_LAB_TEST_URL : DEL_LAB_URL;

    const data = yield call(deleteDataWithToken, url, action.data);

    yield put({
      type: TOAST_SUCCESS,
      message:
        data && data.message ? data.message : "Data updated successfully!",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* addLab(action) {
  try {
    const url =
      action.data &&
      action.data.isEdit &&
      action.data.labId &&
      action.data.labTestId
        ? EDIT_LAB_TEST_URL
        : action.data && !action.data.isEdit && action.data.labId
        ? ADD_LAB_TEST_URL
        : action.data && action.data.isEdit
        ? EDIT_LAB_URL
        : ADD_LAB_URL;

    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(postDataWithToken, url, action.data);

    yield put({ type: IS_SUBMITTING, isSubmitting: false });

    yield put({
      type: TOAST_SUCCESS,
      message:
        data && data.message ? data.message : "Data updated successfully!",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* getLabTests(action) {
  try {
    const url = GET_LAB_TEST_BY_LABID_URL;

    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(getDataWithToken, url, action.data);

    yield put({
      type: LAB_TEST_LIST,
      data: data && data.labTestList ? data.labTestList : [],
      hasMore:
        data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false,
    });

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* deleteLabUsers(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const url = DEL_LAB_USERS_URL;

    const data = yield call(deleteDataWithToken, url, action.data);

    yield put({
      type: TOAST_SUCCESS,
      message:
        data && data.message ? data.message : "Data updated successfully!",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* addLabUsers(action) {
  try {
    const url =
      action.data && action.data.isEdit
        ? EDIT_LAB_USERS_URL
        : ADD_LAB_USERS_URL;

    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(
      action.data.isEdit ? putDataWithToken : postDataWithToken,
      url,
      action.data
    );

    yield put({ type: IS_SUBMITTING, isSubmitting: false });

    yield put({
      type: TOAST_SUCCESS,
      message:
        data && data.message ? data.message : "Data updated successfully!",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* getLabUsers(action) {
  try {
    const url = GET_LAB_USERS_BY_LABID_URL;

    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(getDataWithToken, url, action.data);

    yield put({
      type: LAB_USERS_LIST,
      data: data && data.list ? data.list : [],
      hasMore:
        data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false,
    });

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}
