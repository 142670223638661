import React, { Component } from "react";

import Container from "@material-ui/core/Container";
import Navbar from "./../../containers/Navbar";
import Sidebar from "./../../containers/Sidebar";
import Grid from "@material-ui/core/Grid";
import ListIcon from "@material-ui/icons/List";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { IconButton } from "@material-ui/core";
// import Paper from '@material-ui/core/Paper';

class DocContainer extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }
  handleOpenClose = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  componentDidMount = () => {
    if (window.location.pathname.includes("/trips") || window.location.pathname.includes("/video")) {
      this.setState({
        open: false,
      });
    } else {
      window.addEventListener("resize", this.resize.bind(this));
      this.resize();
    }
  };

  resize() {
    this.setState({ open: !(window.innerWidth <= 760) });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  render() {
    return (
      <div>
        <Sidebar
          handleOpenClose={this.handleOpenClose}
          open={this.state.open}
        />
        <Navbar />
        <Grid
          container
          style={{
            backgoundColor:
              this.props.transparent && this.props.transparent === true
                ? "grey"
                : "white",
          }}
        >
          {!this.state.open && (
            <div
              style={{
                transform: "translateY(50px)",
                paddingLeft: this.state.open ? 200 : 20,
              }}
            >
              <IconButton onClick={this.handleOpenClose}>
                <ListIcon fontSize="large" />
              </IconButton>
            </div>
          )}

          <Grid item xs={12} style={{ flexBasis: "98%" }}>
            <div
              style={{
                paddingTop: "70px",
                paddingLeft: this.state.open ? 200 : 20,
              }}
            >
              {this.props.children}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default DocContainer;
