import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow, Polyline } from "google-maps-react";
import "./map.css";
import icon1 from "./icon1.svg";
import icon2 from "./icon2.svg";
import socketIOClient from "socket.io-client";
import { getLgpId } from "Utils/storage";
import { ENDPOINT } from "Constants/constants";
import moment from "moment";

const mapStyles = {
  width: "100%",
  height: "100%",
};

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeRiders: [],
      riders: [],
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      infoData: {},
      sockKey: this.props.sockKey || "",
      center: this.props.latLang || { lat: 12.9716, lng: 77.5946 },
      zoom: 8,
      lgpId: getLgpId(),
      ridersPrevState: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState(
        {
          showingInfoWindow: false,
          sockKey: nextProps.sockKey || "",
          center: this.props.latLang,
          activeMarker: {},
          selectedPlace: {},
          zoom:this.props.zoom?this.props.zoom: 8,
          riders:this.props.phleboLocations
        },
      );
    }
  }

  listenToSocketIo = () => {
    const socket = socketIOClient(ENDPOINT);
    socket.on("connect", function() {
      console.log("connected");
      socket.emit('channel', JSON.stringify({key: "Connnection from UI"}));
    });
    socket.on("disconnect", function() {
      console.log("disconnected");
    });
    const $this = this;
    if (this.state.sockKey) {
      socket.on('Bangalore_1', function(data) {
        console.log('dsvdv')
        $this.createRidersArray(data);
      });
      socket.on("connect_error", (err) => {
        console.log(err); // prints the message associated with the error
      });
    }
  };

  componentDidMount() {
    this.listenToSocketIo();
  }

  createRidersArray = (data) => {
    if (!data) {
      return;
    }
    if (this.state.lgpId && data.lgpId !== this.state.lgpId) {
      return;
    }
    let parsedData = {};
    try {
      parsedData = JSON.parse(data);
    } catch (e) {
      console.error(e);
    }
    let obj = {};
    obj.pid = parsedData.pId;
    obj.pname = parsedData.pName;
    obj.pno = parsedData.pNo;
    obj.lat = parsedData.lat;
    obj.long = parsedData.long;
    obj.orderid = parsedData.orderId;
    console.log("data", obj);
    if (parsedData.isOrder) {
      obj.type = "ACTIVE";
      obj.custname = parsedData.cName;
      obj.custmobile = parsedData.cNo;
      obj.lgpname = parsedData.lgp;
      obj.labname = parsedData.lab;
      obj.address = parsedData.address;
    } else {
      obj.type = "IDLE";
    }
    const { riders } = this.state;
    let found = false;
    let ridersCopy = riders.slice();
    for (let i = 0; i < ridersCopy.length; i++) {
      if (ridersCopy[i].pid === obj.pid) {
        ridersCopy[i] = obj;
        found = true;
        break;
      }
    }

    this.setState({
      ridersPrevState: {
        ...this.state.ridersPrevState,
        [obj.pid]: obj.type,
      },
    });

    if (
      obj.type === "IDLE" &&
      this.state.ridersPrevState[obj.pid] &&
      this.state.ridersPrevState[obj.pid] === obj.type
    ) {
      return;
    }

    if (!found) {
      ridersCopy.push(obj);
    }
    this.setState({ riders: ridersCopy }, () => {
      console.log("153", this.state.riders);
    });
  };

  displayMarkers = () => {
    return this.props.phleboLocations && this.props.phleboLocations.map((rider, index) => {
      return (
        <Marker
          icon={rider.order_id? icon1: icon2 }
      
          name={rider.phlebo_id}
          title={rider.custname}
          key={index}
          label={{text:`_________________${rider.pname}`,
          fontSize: "13px",
          color:'blue',
          fontStyle:'bold',
          fontWeight: '1000',
          backgroundColor:'white'
        }}
          id={index}
          position={{
            lat: rider.latitude,
            lng: rider.longitude,
          }}
          onClick={(props, marker, e) =>
            this.onMarkerClick(props, marker, e, rider)
          }
          labelClass={'map-marker-icon'}
          labelAnchor={new window.google.maps.Point(35, 27)}
        />
      
       
      );
    });
  };
  onMarkerClick = (props, marker, e, store) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      infoData: store,
    });
  };
  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  render() {
    return (
      <div className="map-box map-box-ct">
        <Map
          google={this.props.google}
          zoom={this.state.zoom}
          center={this.state.center}
          style={mapStyles}
          initialCenter={this.state.center}
          onClick={this.onMapClicked}
        >
          {this.displayMarkers()}

          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
          >
            <div>
              {this.state.infoData && this.state.infoData.lgpname && (
                <p>
                  <b>Lab Code:</b> {this.state.infoData.lgpname}
                </p>
              )}
              {this.state.infoData && this.state.infoData.labname && (
                <p>
                  <b>Lab Name:</b> {this.state.infoData.labname}
                </p>
              )}
              {this.state.infoData && this.state.infoData.address && (
                <p>
                  <b>Address:</b> {this.state.infoData.address}
                </p>
              )}
              {this.state.infoData && this.state.infoData.custname && (
                <p>
                  <b>Customer Name:</b> {this.state.infoData.custname}
                </p>
              )}
              {this.state.infoData && this.state.infoData.pid && (
                <p>
                  <b>P Id:</b> {this.state.infoData.pid}
                </p>
              )}
              {this.state.infoData && this.state.infoData.pno && (
                <p>
                  <b>P No:</b> {this.state.infoData.pno}
                </p>
              )}
              {this.state.infoData && this.state.infoData.custmobile && (
                <p>
                  <b>Customer Phone Number:</b> {this.state.infoData.custmobile}
                </p>
              )}
              {this.state.infoData && this.state.infoData.pname && (
                <p>
                  <b>P Name:</b> {this.state.infoData.pname}
                </p>
              )}
                 {this.state.infoData && this.state.infoData.time && (
                <p>
                  <b>Time:</b> {moment(this.state.infoData.time).format("Do MMM, YYYY h:mm A")}
                </p>
              )}
             {this.state.infoData && this.state.infoData.city && (
                <p>
                  <b>City:</b> {this.state.infoData.city}
                </p>
              )}
            </div>
          </InfoWindow>
        </Map>
      </div>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_WRAPPER_API,
})(MapContainer);
