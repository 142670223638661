/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { Component } from "react";
import { connect } from "react-redux";
import "./users.css";

import DocContainer from "./../../containers/DocContainer";
import ActionLoader from "../../components/ActionLoader";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  Button,
  Grid,
  TextField,
  IconButton,
  Checkbox,
} from "@material-ui/core";

import {
  ADD_ROLES,
  GET_ROLES,
  DELETE_ROLES,
} from "../../constants/actionConstants";
import { Edit, Clear } from "@material-ui/icons";
import { PERMISSIONS } from "../../constants/commonConstants";

class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      searchText: "",
      isAddRole: false,
      isRoleError: false,
      roleData: {
        name: "",
        permissions: JSON.parse(JSON.stringify(PERMISSIONS)),
      },
    };
  }

  UNSAFE_componentWillMount() {
    this.getRoles();
  }

  resetPopup = () => {
    let { roleData } = this.state;
    roleData = {
      name: "",
      permissions: PERMISSIONS,
    };
    this.setState({
      roleData,
      isRoleError: false,
    });
  };

  addRoles = (dataSet) => {
    const { dispatch, match } = this.props;
    const roles = {
      ...this.state.roleData,
      permissions: [],
    };

    roles.permissions = Object.values(
      JSON.parse(JSON.stringify(this.state.roleData.permissions)) || {}
    ).map((el) => {
      return { name: el.key, ...el.permissions };
    });

    dispatch({
      type: ADD_ROLES,
      data: roles,
      onSuccess: () => {
        this.setState({ isAddRole: false });
        this.resetPopup();
        this.getRoles();
      },
    });
  };

  getRoles = (id) => {
    const { dispatch } = this.props;
    let data = {
      count: 10,
      offset: this.state.offset,
    };
    if (id) {
      data = { roleId: id };
    }
    if (this.state.searchText && this.state.searchText.length > 0) {
      data["text"] = this.state.searchText;
    }

    dispatch({
      type: GET_ROLES,
      data,
    });
  };

  deleteRoles = (id) => {
    const { dispatch } = this.props;
    dispatch({
      type: DELETE_ROLES,
      data: { roleId: id },
      onSuccess: () => {
        this.setState({ isDeleteRoleOpen: false, deleteRoleObj: null });
        this.getRoles();
      },
    });
  };

  handleRoleFormChange = (e, key) => {
    const { roleData } = this.state;
    roleData[key] = e.target.value;
    this.setState({ roleData });
  };

  handleCheckboxChange = (menu, permission, value) => {
    const { roleData } = this.state;
    roleData["permissions"][menu]["permissions"][permission] = value;
    this.setState({ roleData });
  };

  validateRoleData = () => {
    const { roleData } = this.state;
    if (roleData.name === "") {
      this.setState({ isRoleError: true });
    } else {
      this.setState({ isRoleError: false });
      this.addRoles();
    }
  };

  renderPagination = () => {
    return (
      <div className="mt-30">
        <Grid container spacing={5}>
          <Grid item xs={9} />
          <Grid item xs={3}>
            <Button
              style={{ marginRight: "20px" }}
              size="medium"
              color="primary"
              variant="outlined"
              disabled={this.state.offset === 0}
              onClick={() => {
                this.setState({ offset: this.state.offset - 10 }, () => {
                  this.getRoles();
                });
              }}
            >
              {"< Previous"}
            </Button>
            <Button
              size="medium"
              color="primary"
              variant="outlined"
              disabled={!this.props.hasMore}
              onClick={() => {
                this.setState({ offset: this.state.offset + 10 }, () => {
                  this.getRoles();
                });
              }}
            >
              {"Next >"}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  };

  formatRole = (data = []) => {
    let permissions = JSON.parse(JSON.stringify(PERMISSIONS));
    const updatedPermissions = data.permissions || [];
    Object.keys(permissions).forEach((key) => {
      let res = updatedPermissions.find((el) => el.name === key);
      if (res) {
        delete res.name;
        permissions[key] = {
          ...permissions[key],
          permissions: res,
        };
      }
    });
    const roleData = {
      ...data,
      permissions,
    };
    return roleData;
  };

  renderRolesPopup = () => {
    const { isAddRole, roleData, isRoleError } = this.state;
    return (
      <Dialog
        disableBackdropClick
        maxWidth="lg"
        fullWidth={true}
        open={isAddRole}
        onClose={() => {
          this.setState({ isAddRole: false });
        }}
      >
        <DialogTitle id="clinic-popup">
          {roleData.isEdit ? "Edit Role" : "Create New Role"}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Name"
                value={roleData.name}
                onChange={(e) => this.handleRoleFormChange(e, "name")}
                error={isRoleError && roleData.name === ""}
                helperText={
                  isRoleError && roleData.name === ""
                    ? "Please enter Name"
                    : " "
                }
              />
            </Grid>
            {Object.keys(roleData.permissions || {}).map((el, i) => (
              <Grid
                container
                key={i}
                style={{ borderBottom: "1px solid #a09f9f", marginBottom: 5 }}
              >
                <Grid item xs={2}>
                  {roleData.permissions[el]["name"]}
                </Grid>
                <Grid item xs={10}>
                  <Grid container key={i}>
                    {Object.keys(
                      roleData.permissions[el]["permissions"] || {}
                    ).map((val) => (
                      <Grid item xs={3}>
                        <Checkbox
                          checked={
                            roleData.permissions[el]["permissions"][val] ||
                            false
                          }
                          onChange={(e) =>
                            this.handleCheckboxChange(el, val, e.target.checked)
                          }
                        />{" "}
                        {val}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            onClick={this.validateRoleData}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button
            style={{ borderColor: "#00C9AA", color: "#00C9AA" }}
            onClick={() => {
              this.setState({ isAddRole: false });
              this.resetPopup();
            }}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderRoleDeletePopup = () => {
    const { isDeleteRoleOpen, data, deleteRoleObj } = this.state;
    return (
      <Dialog
        disableBackdropClick
        open={isDeleteRoleOpen}
        onClose={() => {
          this.setState({ isDeleteRoleOpen: false });
        }}
      >
        <DialogTitle id="clinic-popup">Confirm Delete</DialogTitle>
        <DialogContent>
          {" "}
          Are you sure you want to delete {deleteRoleObj.name} ?{" "}
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            onClick={() => {
              this.deleteRoles(deleteRoleObj.roleId);
            }}
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
          <Button
            style={{ borderColor: "#00C9AA", color: "#00C9AA" }}
            onClick={() => {
              this.setState({ isDeleteRoleOpen: false, deleteRoleObj: null });
            }}
            variant="outlined"
            color="primary"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderRolesTable = () => {
    const { roles } = this.props;
    const {
      isDeleteRoleOpen,
    } = this.state;
    return (
      <>
      <TableContainer component={Paper} className="mt-30">
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(roles || []).length <= 0 && (
              <TableRow>
                <TableCell component="th" scope="row">
                  {" "}
                  No Roles found.{" "}
                </TableCell>
              </TableRow>
            )}
            {(roles || []).map((role, index) => (
              <TableRow key={`kyc-${role.roleId}-${index}`}>
                <TableCell>{role.name}</TableCell>
                <TableCell>
                  <IconButton
                    edge="end"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      this.setState({
                        isAddRole: true,
                        roleData: { ...this.formatRole(role), isEdit: true },
                      });
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => {
                      this.setState({
                        isDeleteRoleOpen: true,
                        deleteRoleObj: role,
                      });
                    }}
                  >
                    <Clear />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isDeleteRoleOpen && this.renderRoleDeletePopup()}
      </>
    );
  };

  renderRolesTab = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
            <h2 className="mt-0">Roles and Permissions</h2>
          </Grid>
        <Grid container spacing={2}>
          
          <Grid item xs={12}>
            <Button
              style={{ backgroundColor: "#0D8DA3" }}
              variant="contained"
              color="primary"
              onClick={() => {
                this.setState({ isAddRole: !this.state.isAddRole });
              }}
            >
              Add Roles and Permission
            </Button>
          </Grid>
        </Grid>
        {this.state.isAddRole && this.renderRolesPopup()}

        {/* {this.renderPagination()} */}

        {this.renderRolesTable()}

        {this.renderPagination()}
      </React.Fragment>
    );
  };

  render() {
    const { isLoading } = this.props;
    const { isDeleteRoleOpen } = this.state;

    return (
      <DocContainer>
        <div className="mr-20">
          {isLoading && <ActionLoader />}
          {this.renderRolesTab()}
          {isDeleteRoleOpen && this.renderRoleDeletePopup()}
        </div>
      </DocContainer>
    );
  }
}

const mapStateToProps = ({ roles }) => {
  return {
    isLoading: roles.isSubmitting,
    hasMore: roles.hasMore,
    roles: roles.roles,
  };
};

export default connect(mapStateToProps)(Roles);
