export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";
export const SIGN_IN_SUBMITTING = "SIGN_IN_SUBMITTING";
export const SET_CITY_AND_RESTRICT = "SET_CITY_AND_RESTRICT";
export const GET_TRIPS = 'GET_TRIPS'
export const SET_TRIPS = 'SET_TRIPS'

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const FORGOT_PASSWORD_SUBMITTING = "FORGOT_PASSWORD_SUBMITTING";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";

export const TOAST_ERROR = "TOAST_ERROR";
export const TOAST_SUCCESS = "TOAST_SUCCESS";
export const HIDE_TOAST = "HIDE_TOAST";
export const SET_SYSTEM_TIME = "SET_SYSTEM_TIME";
export const SET_DATE_VALUE = "SET_DATE_VALUE";
export const SET_CITY_VALUE = "SET_CITY_VALUE";

export const IS_SUBMITTING = "IS_SUBMITTING";
export const ADD_PHELBE_MOBILE_ERROR = "ADD_PHELBE_MOBILE_ERROR";

export const CITIES_LIST = "CITIES_LIST";
export const SEARCHED_LIST = "SEARCHED_LIST";

export const GET_CITIES = "GET_CITIES";
export const SEARCH_CITIES = "SEARCH_CITIES";
export const ADD_CITIES = "ADD_CITIES";

export const GET_CLINICS = "GET_CLINICS";
export const SEARCH_CLINICS = "SEARCH_CLINICS";
export const SEARCH_CLINIC_BY_CITY = "SEARCH_CLINIC_BY_CITY";
export const ADD_CLINICS = "ADD_CLINICS";

export const CLINICS_LIST = "CLINICS_LIST";
export const SEARCHED_CLINICS_LIST = "SEARCHED_CLINICS_LIST";
export const CLINICS_BY_CITY_LIST = "CLINICS_BY_CITY_LIST";

export const ALL_FEEDBACKS = "ALL_FEEDBACKS";

export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const UPLOAD_CMS_IMAGE_URL = "UPLOAD_CMS_IMAGE_URL";
export const UPLOADED_IMG = "UPLOADED_IMG";

export const GET_ALL_CONSTANTS = "GET_ALL_CONSTANTS";
export const ALL_CONSTANTS = "ALL_CONSTANTS";
export const GET_ALL_STATES = "GET_ALL_STATES";
export const ALL_STATES = "ALL_STATES";

export const GET_PROMOCODE = "GET_PROMOCODE";
export const SEARCH_PROMOCODE = "SEARCH_PROMOCODE";
export const ADD_PROMOCODE = "ADD_PROMOCODE";
export const PROMOCODE_LIST = "PROMOCODE_LIST";

export const PLEBO_LIST = "PLEBO_LIST";
export const LOCATION_LIST = "LOCATION_LIST";
export const GET_LOCATIONS = "GET_LOCATIONS";
export const PLEBO_TRACKING_LIST = 'PLEBO_TRACKING_LIST';
export const GET_PLEBOS = "GET_PLEBOS";
export const GET_PLEBOS_TRACKING = "GET_PLEBOS_TRACKING";
export const ADD_PLEBOS = "ADD_PLEBOS";
export const DELETE_PLEBOS = "DELETE_PLEBOS";

export const GET_ALL_TRIPS = "GET_ALL_TRIPS";
export const GET_ALL_TRIPS_BY_NUM = "GET_ALL_TRIPS_BY_NUM";
export const GET_ALL_REVIEW_ORDERS = "GET_ALL_REVIEW_ORDERS";
export const ASSIGN_PLEBOS_ROUTE = "ASSIGN_PLEBOS_ROUTE";
export const SUFFLE_ORDERS = "SUFFLE_ORDERS";
export const CREATE_ROUTE = "CREATE_ROUTE";
export const GET_LIFECYCLE = "GET_LIFECYCLE";
export const GET_COMPRESSED = "GET_COMPRESSED";


export const GET_ROUTES = "GET_ROUTES";
export const ADD_ROUTES = "ADD_ROUTES";
export const DELETE_ROUTES = "DELETE_ROUTES";
export const SEARCH_ROUTES = "SEARCH_ROUTES";

export const ROUTE_LIST = "ROUTE_LIST";
export const RUNNER_LIST = "RUNNER_LIST";

export const GET_RUNNERS = "GET_RUNNERS";
export const ADD_RUNNERS = "ADD_RUNNERS";
export const DELETE_RUNNERS = "DELETE_RUNNERS";

export const ROLES_LIST = "ROLES_LIST";
export const ADD_ROLES = "ADD_ROLES";
export const GET_ROLES = "GET_ROLES";
export const DELETE_ROLES = "DELETE_ROLES";

export const USERS_LIST = "USERS_LIST";
export const ADD_USERS = "ADD_USERS";
export const GET_USERS = "GET_USERS";
export const DELETE_USERS = "DELETE_USERS";

export const GET_DROPDOWN_USERS = "GET_DROPDOWN_USERS";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_TECH_USERS = "GET_TECH_USERS";

export const DEPT_LIST = "DEPT_LIST";
export const ADD_DEPT = "ADD_DEPT";
export const GET_DEPT = "GET_DEPT";
export const DELETE_DEPT = "DELETE_DEPT";

export const GET_PLEBOS_KYC = "GET_PLEBOS_KYC";
export const ADD_PLEBOS_KYC = "ADD_PLEBOS_KYC";
export const DELETE_PLEBOS_KYC = "DELETE_PLEBOS_KYC";
export const PLEBO_KYC_LIST = "PLEBO_KYC_LIST";

export const GET_RUNNERS_KYC = "GET_RUNNERS_KYC";
export const ADD_RUNNERS_KYC = "ADD_RUNNERS_KYC";
export const DELETE_RUNNERS_KYC = "DELETE_RUNNERS_KYC";
export const RUNNER_KYC_LIST = "RUNNER_KYC_LIST";

export const GET_LPGS = "GET_LPGS";
export const ADD_LGPS = "ADD_LGPS";
export const DELETE_LGPS = "DELETE_LGPS";
export const LGP_LIST = "LGP_LIST";

export const ADD_MER_QUESTIONS = "ADD_MER_QUESTIONS";
export const GET_MER_QUESTIONS = "GET_MER_QUESTIONS";
export const GET_MER_ORDERS = "GET_MER_ORDERS";
export const UPDATE_MER_ORDERS = "UPDATE_MER_ORDERS";
export const GET_MER_ORDERS_DASHBOARD = "GET_MER_ORDERS_DASHBOARD";


export const ADD_DOCTORS = "ADD_DOCTORS";
export const GET_ALL_DOCTORS = "GET_ALL_DOCTORS";
export const UPDATE_DOCTORS = "UPDATE_DOCTOrS";
export const GET_DOCTORS = "GET_DOCTORS";
export const DELETE_DOCTORS = "DELETE_DOCTROS";

export const GET_DOCTOR_ORDERS = "GET_DOCTOR_ORDERS";

export const GET_CONTRACT = "GET_CONTRACT";

export const CONTRACT_DATA = "CONTRACT_DATA";
export const ADD_CONTRACT = "ADD_CONTRACT";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

export const GET_ALL_ORDERS = "GET_ALL_ORDERS";
export const ALL_ORDERS = "ALL_ORDERS";

export const ORDER_DATA = "ORDER_DATA";
export const VITALS_DATA = "VITALS_DATA";
export const VIDEO_TOKEN = "VIDEO_TOKEN";
export const VIDEO_MER_TOKEN = "VIDEO_MER_TOKEN";
export const VIDEO_MER = "VIDEO_MER";
export const DOWNLOAD_MER = "DOWNLOAD_MER";
export const ASSIGN_ORDER = "ASSIGN_ORDER";
export const GET_ORDER_DATA = "GET_ORDER_DATA";
export const GET_VITALS_DATA = "GET_VITALS_DATA";
export const GET_VIDEO_TOKEN = "GET_VIDEO_TOKEN";
export const GET_VIDEO_MER_TOKEN = "GET_VIDEO_MER_TOKEN";
export const GET_VIDEO_MER = "GET_VIDEO_MER";
export const DOWNLOAD_VIDEO_MER = "DOWNLOAD_VIDEO_MER";
export const GET_ORDER_DOWNLOAD_LINK = "GET_ORDER_DOWNLOAD_LINK";
export const UPLOAD_ORDERS = "UPLOAD_ORDERS";
export const GET_ORDER_DATA_BY_BARCODE = "GET_ORDER_DATA_BY_BARCODE";
export const ASSIGN_DOCTOR = "ASSIGN_DOCTOR";
export const UNASSIGN_DOCTOR = "UNASSIGN_DOCTOR";

export const GENERATE_PRESIGNED_URL = "GENERATE_PRESIGNED_URL";


export const UNASSIGN_ORDER = "UNASSIGN_ORDER";
export const ACCEPTREJECT_ORDER = "ACCEPTREJECT_ORDER";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const CREATE_ORDER = "CREATE_ORDER";
export const CREATE_VMER_VITALS = "CREATE_VMER_VITALS";
export const DELETE_LABREPORT = "DELETE_LABREPORT";

export const LAB_REPORTS_DATA = "LAB_REPORTS_DATA";
export const GET_ALL_LAB_REPORTS = "GET_ALL_LAB_REPORTS";
export const ADD_LAB_REPORTS = "ADD_LAB_REPORTS";

export const UPLOAD_ASSIGNED_ORDERS = "UPLOAD_ASSIGNED_ORDERS";
export const GET_UPLOAD_ASSIGN_STATUS = "GET_UPLOAD_ASSIGN_STATUS";
export const ATTENDANCE_LIST = "ATTENDANCE_LIST";
export const GET_ATTENDANCE_LIST = "GET_ATTENDANCE_LIST";
export const ADD_ATTENDANCE_LIST = "ADD_ATTENDANCE_LIST";
export const ROUTE_PLANS = "ROUTE_PLANS";
export const GET_ROUTE_PLANS = "GET_ROUTE_PLANS";
export const ROUTE_PLAN_COUNT = "ROUTE_PLAN_COUNT";
export const GET_ROUTE_PLAN_COUNT = "GET_ROUTE_PLAN_COUNT";
export const DOWNLOAD_REPORTS = "DOWNLOAD_REPORTS";

//labtest
export const LAB_TEST_LIST = "LAB_TEST_LIST";

export const GET_LAB_TEST_BY_LABID = "GET_LAB_TEST_BY_LABID";
export const ADD_LAB_TEST = "ADD_LAB_TEST";
export const DELETE_LAB_TEST = "DELETE_LAB_TEST";

export const GET_LAB_USERS_BY_LABID = "GET_LAB_USERS_BY_LABID";
export const ADD_LAB_USERS = "ADD_LAB_USERS";
export const DELETE_LAB_USERS = "DELETE_LAB_USERS";
export const LAB_USERS_LIST = "LAB_USERS_LIST";

//labs
export const LABS_LIST = "LABS_LIST";

export const GET_LABS = "GET_LABS";
export const ADD_LAB = "ADD_LAB";
export const DELETE_LAB = "DELETE_LAB";

//indentory
export const INVENTORY_LIST = "INVENTORY_LIST";

export const GET_INVENTORY = "GET_INVENTORY";
export const ADD_INVENTORY = "ADD_INVENTORY";
export const DELETE_INVENTORY = "DELETE_INVENTORY";

// export const GET_PHLEBO_POLYGONS = 'GET_PHLEBO_POLYGONS';
export const UPLOAD_VIDEO = "UPLOAD_VIDEO";
export const UPLOAD_AUDIO = "UPLOAD_AUDIO";
export const UPLOAD_REPORTS = "UPLOAD_REPORTS";

export const GET_DOWNLOAD_CLIENT_ORDER = "GET_DOWNLOAD_CLIENT_ORDER";

export const GET_PHLEBO_MAPPING_DATA = "GET_PHLEBO_MAPPING_DATA";
export const UPDATE_PHLEBO_AREA = "UPDATE_PHLEBO_AREA";
export const REMOVE_PHLEBO_MAPPING = "REMOVE_PHLEBO_MAPPING";
export const PHLEBO_MAPPING_DATA = "PHLEBO_MAPPING_DATA";

export const REPORT_LIST = "REPORT_LIST";
export const GET_REPORT_DELAY = "GET_REPORT_DELAY";
export const UPDATE_REPORT_STATUS = "UPDATE_REPORT_STATUS";
export const UPDATE_TICKET = "UPDATE_TICKET";

export const GET_REPORT_SLOTS = "GET_REPORT_SLOTS";
export const REORT_SLOTS_LIST = "REORT_SLOTS_LIST";
export const TICKET_REPORT_LIST = "TICKET_REPORT_LIST";

export const DOCTORS_LIST_DATA = "DOCTORS_LIST_DATA";
export const GET_DOCTOR_LIST = "GET_DOCTOR_LIST";

export const GET_LGP_LAB_LIST = "GET_LGP_LAB_LIST";
export const UPLOAD_PROOFS = "UPLOAD_PROOFS";
export const UPLOAD_PPMC_MER = "UPLOAD_PPMC_MER";


//zones
export const GET_ALL_ZONES = "GET_ALL_ZONES";
export const ALL_ZONES = "ALL_ZONES";

export const GET_ZONES = "GET_ZONES";
export const ADD_ZONES = "ADD_ZONES";
export const DELETE_ZONES = "DELETE_ZONES";
export const UPDATE_ZONES = "UPDATE_ZONES";
export const REMOVE_PHLEBO = "REMOVE_PHLEBO";
export const GET_UNPLANNED_ZIPCODES = "GET_UNPLANNED_ZIPCODES";
export const GET_ALL_ZIPCODES = "GET_ALL_ZIPCODES";
export const GET_UNMAPPED_PHLEBOS = "GET_UNMAPPED_PHLEBOS";
export const GET_PLEBOS_ATTENDANCE = "GET_PLEBOS_ATTENDANCE";
export const GET_PLEBOS_FIRST_SLOTS = "GET_PLEBOS_FIRST_SLOTS";
export const GET_PLEBOS_PROOF_SYNC = "GET_PLEBOS_PROOF_SYNC";
export const GET_PLEBOS_LOCATION_STATUS = "GET_PLEBOS_LOCATION_STATUS";
export const UPDATE_PLEBOS_ATTENDANCE = "UPDATE_PLEBOS_ATTENDANCE";
//rosters
export const GET_ALL_ROSTERS = "GET_ALL_ROSTERS";
export const ALL_ROSTERS = "ALL_ROSTERS";

export const GET_ROSTER_CONFIRM = "GET_ROSTER_CONFIRM";
export const UPDATE_ROSTER_CONFIRM = "UPDATE_ROSTER_CONFIRM";
export const GET_ROSTERS_INSIGHTS = "GET_ROSTERS_INSIGHTS";
export const GET_ROSTERS_WORK_STATUS = "GET_ROSTERS_WORK_STATUS";

export const GET_ROSTERS = "GET_ROSTERS";
export const ADD_ROSTERS = "ADD_ROSTERS";
export const DELETE_ROSTERS = "DELETE_ROSTERS";
export const UPDATE_ROSTERS = "UPDATE_ROSTERS";
export const GET_ALL_ROSTER_MAPPING = "GET_ALL_ROSTER_MAPPING";

export const GET_ROSTER_DETAILS_BY_ID = "GET_ROSTER_DETAILS_BY_ID";

export const CREATE_AUTOASSIGN = "CREATE_AUTOASSIGN";
export const GET_AUTO_ROUTE_PLANS = "GET_AUTO_ROUTE_PLANS";
export const DOWNLOAD_EXECUTION_REPORT = "DOWNLOAD_EXECUTION_REPORT";
export const DOWNLOAD_ROUTES_DATA_URL = "DOWNLOAD_ROUTES_DATA_URL";
export const GET_DAILY_REPORTS = "GET_DAILY_REPORTS";


export const GET_CLIENT_UPLOAD_REPORTS = 'GET_CLIENT_UPLOAD_REPORTS';
export const GET_CLIENT_ORDER_DOWNLOAD_LINK = "GET_CLIENT_ORDER_DOWNLOAD_LINK";
export const CLIENT_UPLOAD_ORDERS = "CLIENT_UPLOAD_ORDERS";
export const GET_CLIENT_ORDER_DETAILS = "GET_CLIENT_ORDER_DETAILS";

export const AUTO_ASSIGN_ORDER = "AUTO_ASSIGN_ORDER";
export const SET_TYPE_VALUE = "SET_TYPE_VALUE";
export const GET_ALL_SLOT_BOOKINGS = "GET_ALL_SLOT_BOOKINGS";
export const CREATE_SLOTS = "CREATE_SLOTS";
export const SLOTS_STATUS = "SLOTS_STATUS";
export const GET_ALL_SLOTS_STATUS = "GET_ALL_SLOTS_STATUS";
export const UPDATE_CONFIG_SLOTS = "UPDATE_CONFIG_SLOTS";

export const GET_BO_ATTENDANCE = "GET_BO_ATTENDANCE";
export const SUBMIT_BO_ATTENDANCE = "SUBMIT_BO_ATTENDANCE";
export const GET_DOCTOR_ORDERS_COUNT = "GET_DOCTOR_ORDERS_COUNT";
export const GET_PRESIGNEDURL = "GET_PRESIGNEDURL";