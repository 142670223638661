
import React from 'react';
const { compose, withProps, withStateHandlers } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polygon
} = require("react-google-maps");
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");
const { DrawingManager } = require("react-google-maps/lib/components/drawing/DrawingManager");



const GoogleMapContainer = compose(
  withProps({
    googleMapURL: process.env.REACT_APP_GOOGLE_MAP_URL,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `1000px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
    center: { lat: 25.03, lng: 121.6 }
  }),
  withStateHandlers(() => ({
    isOpen: false,
  }), {
    onToggleOpen: ({ isOpen }) => () => ({
      isOpen: !isOpen,
    })
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const polygonDetails = props.areaDetails.slice();
  let polygonsArray = [];
  for (let i = 0; i < polygonDetails.length; i++) {
    let locations = polygonDetails[i].location.slice();
    locations.pop();
    let polygons = [];
    for (let j = 0; j < locations.length; j++) {
      var obj = {};
      obj.lat = locations[j][1];
      obj.lng = locations[j][0];
      polygons.push(obj)
    }
    let polygonObj = {
      polygons: polygons,
      areaName: polygonDetails[i].area
    }
    polygonsArray.push(polygonObj);
  }
  debugger;
  return (
    <GoogleMap
      defaultZoom={11}
      defaultCenter={{ lat: 12.9716, lng: 77.5946 }}
      defaultOptions={{ styles: {} }}
    >
      {
        polygonsArray.map(item => {
          return (
            <Polygon
              path={item.polygons}
              //key={1}
              onClick={() => {
                alert(item.areaName);
              }}
              options={{
                fillColor: "#000",
                fillOpacity: 0.4,
                strokeColor: "#000",
                strokeOpacity: 1,
                strokeWeight: 1
              }}
            />
          )
        })
      }
      <DrawingManager
        defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
        onPolygonComplete={props.onPolygonComplete}
        defaultOptions={{
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
              google.maps.drawing.OverlayType.CIRCLE,
              google.maps.drawing.OverlayType.POLYGON,
              google.maps.drawing.OverlayType.POLYLINE,
              google.maps.drawing.OverlayType.RECTANGLE,
            ],
          },
          circleOptions: {
            fillColor: `#ffff00`,
            fillOpacity: 1,
            strokeWeight: 5,
            clickable: false,
            editable: true,
            zIndex: 1,
          },
        }}
      />
    </GoogleMap>
  )
}
);



export default GoogleMapContainer;