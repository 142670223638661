/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getUser } from "Utils/storage";

import DocContainer from "./../../containers/DocContainer";
import ActionLoader from "../../components/ActionLoader";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { ArrowDropDownOutlined, Refresh } from "@material-ui/icons";
import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ClearIcon from '@material-ui/icons/Clear';

import moment from "moment";

import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Typography,
  FormLabel,
  TextField,
  IconButton
} from "@material-ui/core";
import {
  GET_CLIENT_ORDER_DOWNLOAD_LINK,
  CLIENT_UPLOAD_ORDERS,
  GET_CLIENT_UPLOAD_REPORTS,
  GET_CLIENT_ORDER_DETAILS,
  GET_ALL_CONSTANTS,
  TOAST_ERROR
} from "./../../constants/actionConstants";
import { DropzoneDialog } from "material-ui-dropzone";
import { DatePicker } from "rsuite";
import { Link } from "react-router-dom";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Select, { components } from "react-select";
import { GET_ALL_ORDERS } from "Constants/actionConstants";
import { SET_TYPE_VALUE } from "Constants/actionConstants";
import { SET_DATE_VALUE } from "Constants/actionConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  paper: {
    width: "90%",
    padding: 10,
  },
}));

const multiSelectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    fontSize: 12,
  }),
  option: (base) => ({
    ...base,
    fontSize: 12,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#9F9F9F",
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    color: "#fff",
    backgroundColor: "#6FC5C4",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#6FC5C4",
      color: "#fff",
    },
  }),
};


const ClientUpload = (props) => {
  const classes = useStyles();
  const { cities } = props;
  const user = getUser();
  const [citiesSelected, setCitiesSelected] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState([]);

  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(new Date());
  const [uploadDate, setUploadDate] = useState(new Date());
  const [allReports, setAllReports] = useState([]);
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [allTypes, setAllTypes] = useState([]);
  const [details, setDetails] = useState([]);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel, id) => (event, isExpanded) => {
    const { dispatch } = props;
    dispatch({
      type: GET_CLIENT_ORDER_DETAILS,
      data: {
        ts: date.getTime(),
        uploadId: id,
      },
      onSuccess: (res) => {
        setDetails(res.list || []);
        setExpanded(isExpanded ? panel : false);
      },
    });
  };

  const refreshOrderDetails = (id) => {
    const { dispatch } = props;
    dispatch({
      type: GET_CLIENT_ORDER_DETAILS,
      data: {
        ts: date.getTime(),
        uploadId: id,
      },
      onSuccess: (res) => {
        setDetails(res.list || []);
      },
    });
  }

  useEffect(() => {
    const types = [];
    if(user.upload_orders) {
      types.push({
        value: 'upload_orders',
        label: 'Upload Orders'
      })
    }
    if(user.same_day_orders) {
      types.push({
        value: 'same_day_orders',
        label: 'Same Day Orders'
      })
    }
    if(user.upload_and_assign) {
      types.push({
        value: 'upload_and_assign',
        label: 'Upload And Assign'
      })
    }
    setAllTypes(types);
  }, [])

  useEffect(() => {
    getAllConstants();
  }, []);

  useEffect(() => {
    setAllCities(
      cities.map((city) => {
        return { value: city, label: city };
      })
    );
  }, [cities]);

  useEffect(() => {
    getAllReports();
  }, [date]);


  const getAllConstants = () => {
    const { dispatch } = props;
    dispatch({
      type: GET_ALL_CONSTANTS,
    });
  };

  const downloadFormat = (i,type) => {
    const { dispatch } = props;
    if(i){
      dispatch({
        type: GET_CLIENT_ORDER_DOWNLOAD_LINK,
        data: {
          uploadId:i,
          type:type
        },
        onSuccess: (res) => {
          if (res.link) {
            const element = document.createElement("a");
            element.setAttribute("href", res.link);
            element.setAttribute("download", true);
            element.click();
          }
        },
      });
    } else{
    dispatch({
      type: GET_CLIENT_ORDER_DOWNLOAD_LINK,
      data: {},
      onSuccess: (res) => {
        if (res.link) {
          const element = document.createElement("a");
          element.setAttribute("href", res.link);
          element.setAttribute("download", true);
          element.click();
        }
      },
    });
  }
  };

  const getAllReports = () => {
    setIsLoading(true);
    const { dispatch } = props;
    const data = {
      count: 100,
      offset: 0,
      ts: date.getTime(),
    };
    dispatch({
      type: GET_CLIENT_UPLOAD_REPORTS,
      data,
      onSuccess: (res) => {
        setIsLoading(false);
        setAllReports(res.list || []);
      },
      onFailure: (data) => {
        setIsLoading(false);
      }
    });
  };

  const handleOpen = () => {
    const { dispatch } = props;
    if(!title) {
      dispatch({
        type: TOAST_ERROR,
        message: "Please enter Title.",
      });
      return;
    }
    setOpen(true);
  };

  const handleSave = (files) => {
    const { dispatch } = props;
    setIsLoading(true);
    const data = { title };

    if(!title) {
      dispatch({
        type: TOAST_ERROR,
        message: "Please enter Title.",
      });
    }

    if(citiesSelected) {
      data.city = (citiesSelected || []).join(',');
    }
    if(type) {
      data.type = type;
    }
    if(uploadDate){
      data["month"] = moment(uploadDate).month() + 1;
      data["date"] = moment(uploadDate).date();
      data["year"] = moment(uploadDate).year();
    }
    dispatch({
      type: CLIENT_UPLOAD_ORDERS,
      data,
      file: files,
      onSuccess: (data) => {
        setIsLoading(false);
        setTitle("");
        if (data && data.message && data.status === 400) {
          setUploadError(data.data && data.data.list ? data.data.list : []);
        } else {
          if (uploadError.length > 0) {
            setUploadError([]);
          }
          getAllReports();
        }
      },
      onFailure: (data) => {
        setIsLoading(false);
      }
    });
    setIsLoading(false);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownOutlined />
      </components.DropdownIndicator>
    );
  };

  const getOrders = (x,order) => {
    const { dispatch } = props;
    let data = {
      count: 10,
      isPast: false,
      offset: 0,
      type:x,
      month:order.month,
      date:order.date,
      year:order.year,
      lgpId:order.lgpId
    };
   
    dispatch({
      type: GET_ALL_ORDERS,
      isPartner:true,
      data
    });

    dispatch({
      type: SET_TYPE_VALUE,
      data:x
    });
    const month = order.month
    const date = order.date
    const year = order.year

    dispatch({
      type: SET_DATE_VALUE,
      data:`${month}/${date}/${year}`
    });
  };

  return (
    <DocContainer>
      {isLoading && <ActionLoader />}
      <Card style={{ border: "none", boxShadow: "none" }}>
        <CardHeader title="Upload" />
        <CardContent>
          <div className="mb-20">
            <Typography
              style={{ display: "inline", color: "#636363" }}
              component="span"
              variant="body2"
              color="text.primary"
            >
              You can download the document format template here
            </Typography>
          </div>
          <div className="mb-20">
            <Button
              variant="outlined"
              onClick={() => downloadFormat()}
              color="primary"
            >
              Download Format
            </Button>
          </div>
          <div
            style={{
              borderTop: "1px solid #808080",
              marginTop: 20,
              marginBottom: 10,
            }}
          />
          <div className="mt-20">
          <Grid container spacing={1} style={{ marginBottom: 15 }}>
          <Grid item xs={2} style={{ marginTop: 15 }}>
          <FormLabel component="legend" className="text-label">
                Title*
              </FormLabel>
              <TextField
                id="outlined-basic"
                placeholder="Document Title"
                variant="outlined"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={2} style={{ marginTop: 15 }}>
            <FormLabel component="legend" className="text-label">
                Select Date
              </FormLabel>
              <DatePicker value={uploadDate} onOk={(date) => setUploadDate(date)} />
              </Grid>
            <Grid item xs={2} style={{ marginTop: 15 }}>
              <FormLabel component="legend" className="text-label">
                Select City
              </FormLabel>
              <Select
                options={allCities}
                isClearable={true}
                placeholder={"City"}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                }}
                value={allCities.filter((key) =>
                  citiesSelected.includes(key.value)
                )}
                isMulti
                onChange={(val) => {
                  setCitiesSelected(val.map((a) => a.value));
                }}
                styles={multiSelectStyles}
              />
            </Grid>
            <Grid item xs={2} style={{ marginTop: 15 }}>
              <FormLabel component="legend" className="text-label">
                Select Options
              </FormLabel>
              <Select
                options={allTypes}
                isClearable={true}
                placeholder={"Options"}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                }}
                value={
                  allTypes[allTypes.findIndex((key) => key.value === type)]
                }
                onChange={(val) => {
                  setType(val ? val.value : null);
                }}
                styles={multiSelectStyles}
              />
            </Grid>
            <Grid item xs={2} style={{ marginTop: 40 }}>
            <Button
              style={{ backgroundColor: "#00C9AA" }}
              color="primary"
              variant="contained"
              disabled={!title ? true : false}
              onClick={() => handleOpen()}
            >
              Upload document
            </Button>
            </Grid>
            </Grid>
          </div>
         
          {uploadError && uploadError.length > 0 ? (
            <>
             <FormLabel component="legend" className="text-label">
             Errors 
             <IconButton
                edge="end"
                color="inherit"
                size="small"
                style={{ fontSize: "12px" }}
                onClick={() => {
                  setUploadError([]);
                }}
              >
                <ClearIcon/>
              </IconButton>
            </FormLabel>
            <div className="mb-20">
              {uploadError.map((el, index) => (
               <li style={{fontSize:'16px'}}>
                  {el}
                </li>
              ))}
            </div>
            </>
          ) : null}

          <DropzoneDialog
            open={open}
            onSave={handleSave}
            showPreviews={true}
            maxFileSize={5000000}
            onClose={handleClose}
          />
          <div
            style={{
              borderTop: "1px solid #808080",
              marginTop: 20,
              marginBottom: 10,
            }}
          />
          <div className="mt-20 flex" style={{alignItems: 'center'}}>
            <div style={{ marginTop: 10, marginRight: 20 }}>
              <FormLabel component="legend" className="text-label">
                Select Date
              </FormLabel>
              <DatePicker value={date} onOk={(date) => setDate(date)} />
            </div>
            <div
              style={{
                display: "inline-block",
                verticalAlign: "top",
                marginRight: "20px",
                marginTop: "20px",
              }}
            >
              <IconButton
                edge="end"
                color="inherit"
                size="small"
                style={{ fontSize: "12px" }}
                onClick={() => {
                  getAllReports();
                }}
              >
                <Refresh /> Refresh
              </IconButton>
            </div>
          </div>
          <div className="mt-20">
            <div className="tableView" style={{ height: "auto" }}>
              <table className="styled-table">
                <thead className="tableHead">
                  <tr className="border_bottom">
                    <th style={{ width: "8%", paddingRight: 0 }}>S.no</th>
                    <th style={{ width: "13%", paddingRight: 0 }}>Title</th>
                    <th style={{ width: "11%", paddingRight: 0 }}>Date</th>
                    <th style={{ width: "9%", paddingRight: 0 }}>City</th>
                    <th style={{ width: "8%", paddingRight: 0 }}>Orders</th>
                    <th style={{ width: "14%", paddingRight: 0 }}>Upload Type</th>
                    <th style={{ width: "16%", paddingRight: 0 }}>Upload By</th>
                    <th style={{ width: "18%", paddingRight: 0 }}>Upload Date</th>
                    <th style={{ width: "0%", paddingRight: 0 }}></th>
                    <th style={{ width: "0%", paddingRight: 0 }}></th>
                  </tr>
                </thead>
                <tbody className="tableBody">
                  {(allReports || []).map((el, i) => (
                    <tr className="row">
                      <td
                        colSpan="8"
                        style={{ padding: 0 }}
                        className="cell"
                        data-title="S.no"
                      >
                        <Accordion
                          expanded={expanded === `RT_${i}`}
                          onChange={handleChange(`RT_${i}`, el.uploadId)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            style={{paddingLeft: 0, paddingRight: 0}}
                            id="panel1bh-header"
                          >
                            <td
                              style={{ width: "14%", paddingRight: 0 }}
                              className="cell"
                              data-title="S.no"
                            >
                              {i + 1}
                            </td>
                            <td
                              style={{ width: "26%", paddingRight: 0 }}
                              className="cell"
                              data-title="Created By"
                            >
                              {el.title}
                            </td>
                            <td
                              style={{ width: "28%", paddingRight: 0 }}
                              className="cell"
                              data-title="Status"
                            >
                              {`${el.date}-${el.month}-${el.year}`}
                            </td>
                            <td
                              style={{ width: "30%", paddingRight: 0 }}
                              className="cell"
                              data-title="Status"
                            >
                              {el.city}
                            </td>
                            <td
                              style={{ width: "16%", paddingRight: 0 }}
                              className="cell"
                              data-title="Status"
                            >
                              {el.count}
                            </td>
                            <td
                              style={{ width: "35%", paddingRight: 0 }}
                              className="cell"
                              data-title="Status"
                            >
                              {el.uploadType}
                            </td>
                            <td
                              style={{ width: "18%" , paddingRight: 0}}
                              className="cell"
                              data-title="Status"
                            >
                              {el.username}
                            </td>
                            <td
                              style={{ width: "18%", paddingRight: 0 }}
                              className="cell"
                              data-title="Status"
                            >
                              {moment(el.createdDate).format("Do MMM, YYYY h:mm A")}
                            </td>
                            <td
                              style={{ width: "18%", paddingRight: 0 }}
                              className="cell"
                              data-title="Status"
                            >
                              <IconButton
                                  edge="end"
                                  color="inherit"
                                  size="small"
                                  style={{ fontSize: "12px"}}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    refreshOrderDetails(el.uploadId);
                                  }}
                                >
                                  <Refresh /> Refresh
                                </IconButton>
                            </td>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={1}>
                              <Grid container item xs={6} spacing={1}>
                                <Paper className={classes.paper}>
                                  <Grid container item xs={16} spacing={1}>
                                    <Grid
                                      style={{
                                        borderBottom: "1px solid #8e8e93",
                                      }}
                                      item
                                      xs={2}
                                      spacing={2}
                                    >
                                      <Typography>S.No</Typography>
                                    </Grid>
                                    <Grid
                                      style={{
                                        borderBottom: "1px solid #8e8e93",
                                      }}
                                      item
                                      xs={3}
                                      spacing={2}
                                    >
                                      Name
                                    </Grid>
                                    <Grid
                                      style={{
                                        textAlign: "center",
                                        borderBottom: "1px solid #8e8e93",
                                        paddingLeft:"94px"
                                      }}
                                      item
                                      xs={3}
                                      spacing={3}
                                    >
                                      Count
                                    </Grid>
                                    <Grid
                                      style={{
                                        textAlign: "center",
                                        borderBottom: "1px solid #8e8e93",
                                      }}
                                      item
                                      xs={4}
                                      spacing={3}
                                    >
                                      Actions
                                    </Grid>
                                    {details.slice(0, 8).map((el1, i) => (
                                      <>
                                        <Grid item xs={2} spacing={3}>
                                          <Typography>{i + 1}</Typography>
                                        </Grid>
                                        <Grid item xs={5} spacing={3}>
                                          <Typography>{el1.name}</Typography>
                                        </Grid>
                                        <Grid
                                          style={{ textAlign: "center" }}
                                          item
                                          xs={2}
                                          spacing={3}
                                        >
                                          {el1.count}
                                        </Grid>
                                        <Grid
                                          style={{ textAlign: "center" }}
                                          item
                                          xs={2}
                                          spacing={3}
                                        >
                                          <GetAppIcon onClick={() => downloadFormat(el.uploadId,el1.type)} style={{cursor:'pointer'}}/>
                                          <Link onClick={() => getOrders(el1.type,el)} to="/orders"><VisibilityIcon   style={{cursor:'pointer'}}/></Link>
                                          
                                        </Grid>
                                      </>
                                    ))}
                                  </Grid>
                                </Paper>
                              </Grid>

                              <Grid container item xs={6} spacing={1}>
                                <Paper className={classes.paper}>
                                  <Grid container item xs={18} spacing={1}>
                                    <Grid
                                      style={{
                                        borderBottom: "1px solid #8e8e93",
                                      }}
                                      item
                                      xs={2}
                                      spacing={3}
                                    >
                                      <Typography>S.No</Typography>
                                    </Grid>
                                    <Grid
                                      style={{
                                        borderBottom: "1px solid #8e8e93",
                                      }}
                                      item
                                      xs={4}
                                      spacing={3}
                                    >
                                      Name
                                    </Grid>
                                    <Grid
                                      style={{
                                        textAlign: "center",
                                        borderBottom: "1px solid #8e8e93",
                                      }}
                                      item
                                      xs={3}
                                      spacing={3}
                                    >
                                      Count
                                    </Grid>
                                    <Grid
                                      style={{
                                        textAlign: "center",
                                        borderBottom: "1px solid #8e8e93",
                                      }}
                                      item
                                      xs={3}
                                      spacing={3}
                                    >
                                      Actions
                                    </Grid>
                                    {details.slice(8, 20).map((el2, i) => (
                                      <>
                                        <Grid item xs={2} spacing={3}>
                                          <Typography>{i + 1 + 8}</Typography>
                                        </Grid>
                                        <Grid item xs={4} spacing={3}>
                                          {el2.name}
                                        </Grid>
                                        <Grid
                                          style={{ textAlign: "center" }}
                                          item
                                          xs={3}
                                          spacing={3}
                                        >
                                          {el2.count}
                                        </Grid>
                                        <Grid
                                          style={{ textAlign: "center" }}
                                          item
                                          xs={3}
                                          spacing={3}
                                        >
                                          <GetAppIcon onClick={() => downloadFormat(el.uploadId,el2.type)} style={{cursor:'pointer'}}/>
                                          <Link onClick={() => getOrders(el2.type,el)} to="/orders" ><VisibilityIcon  style={{cursor:'pointer'}}/></Link>
                                        </Grid>
                                      </>
                                    ))}
                                  </Grid>
                                </Paper>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </td>
                    </tr>
                  ))}
                  {allReports.length === 0 && (
                    <tr className="row">
                      <td className="cell" data-title="S.no">
                        No Reports
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </CardContent>
      </Card>
    </DocContainer>
  );
};

const mapStateToProps = ({ constants,orders }) => {
  return {
    cities: constants.cities,
    typeValueNumb:orders.typeValue
  };
};

export default connect(mapStateToProps)(ClientUpload);
