import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import App from "./App";

import Signin from "Pages/Signin";

import Dashboard from "Pages/Dashboard";
import Plebo from "Pages/Plebo";
import PleboAdd from "Pages/Plebo/pleboadd";
import AddDoctor from "Pages/Doctors/addDoctors";
import TRoutes from "Pages/TRoutes";
import Runner from "Pages/Runner";
import LGP from "Pages/LGP";
import Orders from "Pages/Orders";
import Settings from "Pages/Settings";
import Reports from "Pages/Reports";
import DoctorSettings from "Pages/DoctorSettings/index";
import ManageTower from "Pages/ManageTower";
import Inventory from "Pages/Inventory";
import Labs from "Pages/Labs";
import Zone from "Pages/Zone";
import RosterPlan from "Pages/RosterPlan";
import CreateRosterPlan from "Pages/RosterPlan/create";

import AutoAssign from "Pages/AutoAssign";
import ExecutionReports from "Pages/ExecutionReports";

import ClientUpload from "Pages/ClientUpload";
import OrderStatistics from "Pages/OrderStatistics";
import PhleboAttendance from "Pages/PhleboAttendance";
import Users from "Pages/Users";
import Department from "Pages/Department";
import Roles from "Pages/Roles";
import Trips from "Pages/Trips/Trips";
import Track from "Pages/Tracking";
import SlotBooking from "Pages/SlotBooking";
import Doctors from "Pages/Doctors";
import VMer from "Pages/VMer";
import DoctorOrders from "Pages/DoctorOrders/index";

export default class Routes extends Component {
  render() {
    return (
      <App>
        <Switch>
          <Route exact path="/" component={Signin} />
          <Route exact path="/phlebo/:id?" component={Plebo} />
          <Route exact path="/phleboAdd/:id?" component={PleboAdd} />
          <Route exact path="/routes" component={TRoutes} />
          <Route exact path="/runner/:id?" component={Runner} />
          <Route exact path="/lgp/:id?" component={LGP} />
          <Route exact path="/orders/:id?/:status?" component={Orders} />
          <Route exact path="/thc/phlebo/vmer/:id?/:orderId?/:token?" component={VMer} />
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/reports" component={Reports} />
          <Route exact path="/inventory" component={Inventory} />
          <Route exact path="/labs/:labId?" component={Labs} />
          <Route exact path="/Track" component={Track} />
          <Route exact path="/phlebo-area" component={ManageTower} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/zone/:zoneId?" component={Zone} />
          <Route exact path="/rosterplan" component={RosterPlan} />
          <Route exact path="/rosterplan/update" component={CreateRosterPlan} />
          <Route exact path="/autoassign" component={AutoAssign} />
          <Route exact path="/excecutionreports" component={ExecutionReports} />
          <Route exact path="/clientupload" component={ClientUpload} />
          <Route exact path="/statistics" component={OrderStatistics} />
          <Route exact path="/attendance" component={PhleboAttendance} />
          <Route exact path="/users" component={Users} />
          <Route exact path="/department" component={Department} />
          <Route exact path="/roles" component={Roles} />
          <Route exact path="/trips" component={Trips} />
          <Route exact path="/slotbooking" component={SlotBooking} />
          <Route exact path="/doctors/:id?" component={Doctors} />
          <Route exact path="/addDoctor/:id?" component={AddDoctor} />
          <Route exact path="/doctororders/:id?/:status?" component={DoctorOrders} />
          <Route exact path="/doctorsettings" component={DoctorSettings} />
          
        </Switch>
      </App>
    );
  }
}
