/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DocContainer from "./../../containers/DocContainer";
import ActionLoader from "../../components/ActionLoader";
import {
  Grid,
  Button,
  FormLabel,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { getUser } from "Utils/storage";

import {
  Refresh,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
} from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Select, { components } from "react-select";
import DatePicker from "../../components/Datepicker/DatePicker";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@material-ui/core";

import {
  GET_ALL_CONSTANTS,
  DOWNLOAD_EXECUTION_REPORT,
  GET_DAILY_REPORTS,
  GET_LPGS,
  GET_PLEBOS,
} from "./../../constants/actionConstants";

import "./index.css";


const selectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    fontSize: 12,
    border: "none",
    boxShadow:
      "0 4px 8px 0 rgb(255 255 255), 0 6px 8px 0 rgb(117 109 109 / 30%)",
  }),
  option: (base) => ({
    ...base,
    fontSize: 12,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#9F9F9F",
  }),
};


const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownOutlined />
    </components.DropdownIndicator>
  );
};

const slots = [
  { name: "5 - 6", id: 1 },
  { name: "6 - 7", id: 2 },
  { name: "7 - 8", id: 3 },
  { name: "8 - 9", id: 4 },
  { name: "9 - 10", id: 5 },
  { name: "10 - 11", id: 6 },
  { name: "11 - 12", id: 7 },
  { name: "12 +", id: 8 },
];

const AutoAssign = (props) => {
  const { cities, lgps, plebos } = props;
  const [isLoading, setIsLoading] = useState(false);

  const [date, setDate] = useState(new Date());
  const [report, setReport] = useState("1");
  const [custom, setCustom] = useState({
    isAvailableHeadCount: true,
    isTotalOrdersReceivedFromClient: true,
    isTotalOrdersAssigned: true,
    isCanceledByCustomer: true,
    isPostponedByCustomer: true,
    isCanceledByPartner: true,
    isDuplicate: true,
    isZipcodeNonService: true,
    isCompleted: true,
    isAddon: true,
    isResample: true,
    isRejectedByI2H: true,
    isClientWise: true,
    isCityWise: false,
    isOrderTypeWise: true,
  });

  const user = getUser();

  const [allCities, setAllCities] = useState([]);
  const [city, setCity] = useState(null);

  const [allLgps, setAllLgps] = useState([]);
  const [lgp, setLgp] = useState("");

  const [allPhlebos, setAllPhlebos] = useState([]);
  const [phlebos, setPhlebos] = useState("");

  const [reports, setDailyReports] = useState([]);

  const [sort, setSort] = useState({
    dir: "asc",
    column: "name",
  });

  const [customOptions, setCustomOptions] = useState({
    row1: [
      {
        key: "isAvailableHeadCount",
        label: "Available HeadCount?",
      },
    ],
    row2: [
      {
        key: "isTotalOrdersReceivedFromClient",
        label: "Total Orders Received From Client?",
      },
      {
        key: "isTotalOrdersAssigned",
        label: "Total Orders Assigned?",
      },
      {
        key: "isCanceledByCustomer",
        label: "Canceled By Customer?",
      },
      {
        key: "isPostponedByCustomer",
        label: "Postponed By Customer?",
      },
      {
        key: "isCanceledByPartner",
        label: "Canceled By Partner?",
      },
      {
        key: "isDuplicate",
        label: "Duplicate?",
      },
      {
        key: "isZipcodeNonService",
        label: "Zipcode Non Service?",
      },
      {
        key: "isRejectedByI2H",
        label: "Rejected By I2H?",
      },
      {
        key: "isCompleted",
        label: "Completed?",
      },
    ],
    row3: [
      {
        key: "isAddon",
        label: "Addon?",
      },
      {
        key: "isResample",
        label: "Resample?",
      },
    ],
    row4: [
      {
        key: "isClientWise",
        label: "Client Wise?",
      },
      {
        key: "isCityWise",
        label: "City Wise?",
      },
    ],
    row5: [
      {
        key: "isOrderTypeWise",
        label: "Order Type Wise?",
      },
    ],
  });

  const [timings, setTimings] = useState(null);

  useEffect(() => {
    getAllReports();
  }, [city, phlebos, lgp, date, timings]);

  useEffect(() => {
    if ([1, 2, 3].includes(parseInt(report))) {
      getAllReports();
    }
  }, [report]);

  useEffect(() => {
    getAllConstants();
    if (user.userType === 1) {
      getLGPs();
      getPlebos();
    }
  }, []);

  const getSortedData = (data) => {
    if (sort.colType === "percentage") {
      if (sort.dir === "asc") {
        return data.sort((a, b) =>
          parseFloat(a[sort.column]) > parseFloat(b[sort.column]) ? 1 : -1
        );
      } else {
        return data.sort((a, b) =>
          parseFloat(a[sort.column]) < parseFloat(b[sort.column]) ? 1 : -1
        );
      }
    } else {
      if (sort.dir === "asc") {
        return data.sort((a, b) => (a[sort.column] > b[sort.column] ? 1 : -1));
      } else {
        return data.sort((a, b) => (a[sort.column] < b[sort.column] ? 1 : -1));
      }
    }
  };

  const getAllConstants = () => {
    const { dispatch } = props;
    dispatch({
      type: GET_ALL_CONSTANTS,
    });
  };

  const getLGPs = () => {
    const { dispatch } = props;
    dispatch({
      type: GET_LPGS,
      data: {
        count: 200,
        offset: 0,
      },
    });
  };

  const getPlebos = () => {
    const { dispatch } = props;
    let data = {
      count: 200,
      offset: 0,
    };
    data["text"] = city;
    dispatch({
      type: GET_PLEBOS,
      data,
    });
  };

  const getAllReports = () => {
    setIsLoading(true);
    const { dispatch } = props;

    const data = {
      type: report,
      lgpId: lgp === null ? undefined : lgp,
      phleboId: phlebos === null ? undefined : phlebos,
      city: city === null ? undefined : city,
    };

    if (date) {
      data["month"] = moment(date).month() + 1;
      data["date"] = moment(date).date();
      data["year"] = moment(date).year();
    }

    if (timings) {
      data["slot"] = Array.prototype.map
        .call(timings, function(item) {
          return item.id;
        })
        .join(",");
    }

    dispatch({
      type: GET_DAILY_REPORTS,
      data,
      onSuccess: (res) => {
        setIsLoading(false);
        setDailyReports(res.list || []);
      },
    });
  };

  useEffect(() => {
    setAllCities(
      cities.map((city) => {
        return { value: city, label: city };
      })
    );
  }, [cities]);

  useEffect(() => {
    setAllLgps(
      lgps.map((lgp) => {
        return { value: lgp.lgpId, label: lgp.name };
      })
    );
  }, [lgps]);

  useEffect(() => {
    setAllPhlebos(
      plebos.map((plebo) => {
        return { value: plebo.phleboId, label: plebo.firstName };
      })
    );
  }, [plebos]);

  const downloadReport = () => {
    setIsLoading(true);
    const { dispatch } = props;

    let data = {
      ts: date.getTime(),
      type: report,
      lgpId: lgp === null ? undefined : lgp,
      phleboId: phlebos === null ? undefined : phlebos,
      city: city === null ? undefined : city,
    };

    if (date) {
      data["month"] = moment(date).month() + 1;
      data["date"] = moment(date).date();
      data["year"] = moment(date).year();
    }

    if (timings) {
      data["slot"] = Array.prototype.map
        .call(timings, function(item) {
          return item.id;
        })
        .join(",");
    }

    if (data.type == 6) {
      data = {
        ...data,
        ...custom,
      };
    }
    dispatch({
      type: DOWNLOAD_EXECUTION_REPORT,
      data,
      onSuccess: (res) => {
        if (res && res.link) {
          const element = document.createElement("a");
          element.setAttribute("href", res.link);
          element.setAttribute("download", true);
          element.click();
        }
        setIsLoading(false);
      },
    });
  };

  const handleCheckboxChange = (e) => {
    if (e.target.name === "isClientWise" || e.target.name === "isCityWise") {
      setCustom({
        ...custom,
        isClientWise:
          e.target.name === "isClientWise" && e.target.checked ? true : false,
        isCityWise:
          e.target.name === "isCityWise" && e.target.checked ? true : false,
      });
    } else {
      setCustom({
        ...custom,
        [e.target.name]: e.target.checked,
      });
    }
  };

  const getTotal = (key) => {
    if (reports && reports.length > 0) {
      return (reports || [])
        .map((item) => item[key]?parseInt(item[key]):0)
        .reduce((prev, next) => prev + next);
    }
    return 0;
  };

  function convertToFixed(num, fixed) {
    var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
    if (num.toString().match(re) && num.toString().match(re).length > 0) {
      return num.toString().match(re)[0];
    }
    return 0.00;
  }

  const getAverage = (key) => {
    if (reports && reports.length > 0) {
      const totalAssigned = getTotal("assigned");
      const totalCbc = getTotal("cbc");
      const totalRbc = getTotal("rbc");
      const totalCbp = getTotal("cbp");
      const totalRbp = getTotal("rbp");

      switch (key) {
        case "completed%":
          return convertToFixed(
            parseFloat(
              (getTotal("completed") + totalCbc + totalRbc) / totalAssigned
            ) * 100,
            2
          );
        case "cbc%":
          return convertToFixed(parseFloat(totalCbc / totalAssigned) * 100, 2);
        case "rbc%":
          return convertToFixed(parseFloat(totalRbc / totalAssigned) * 100, 2);
        case "cbp%":
          return convertToFixed(parseFloat(totalCbp / totalAssigned) * 100, 2);
        case "rbp%":
          return convertToFixed(parseFloat(totalRbp / totalAssigned) * 100, 2);
        default:
          const total = (reports || [])
            .map((item) => parseFloat(item[key]))
            .reduce((prev, next) => prev + next);
          return parseFloat(total / reports.length).toFixed(2);
      }
    }
    return 0;
  };

  const handleTimings = (value) => {
    setTimings(value);
  };

  const renderSortIcon = (key) => {
    if (sort && sort.column === key) {
      if (sort.dir === "asc") {
        return <ArrowDropUpOutlined />;
      } else {
        return <ArrowDropDownOutlined />;
      }
    }
  };

  const renderHeader = (title, key, type = "integer") => {
    return (
      <IconButton
        onClick={() =>
          setSort({
            dir: sort.column === key && sort.dir === "asc" ? "desc" : "asc",
            colType: type,
            column: key,
          })
        }
        edge="end"
        color="inherit"
        size="small"
        style={{ fontSize: "12px", marginBottom: "10px" }}
      >
        {title} {renderSortIcon(key)}
      </IconButton>
    );
  };

  return (
    <DocContainer transparent={true}>
      <div className="mr-20 pd-t-20">
        {isLoading && <ActionLoader />}
        <React.Fragment>
          <Grid container spacing={1}>
            <Grid item xs={2} style={{ zIndex: 50, position: "relative" }}>
              <FormLabel component="legend" className="text-label">
                Select City
              </FormLabel>
              <Select
                options={allCities}
                styles={selectStyles}
                isClearable={true}
                placeholder={"City"}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                }}
                value={
                  allCities[allCities.findIndex((key) => key.value === city)]
                }
                onChange={(val) => {
                  setCity(val ? val.value : null);
                }}
              />
            </Grid>
            {user.userType === 1 ? (
              <>
                <Grid item xs={2} style={{ zIndex: 50, position: "relative" }}>
                  <FormLabel component="legend" className="text-label">
                    Select Lgp
                  </FormLabel>
                  <Select
                    options={allLgps}
                    styles={selectStyles}
                    isClearable={true}
                    placeholder={"LGP"}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator,
                    }}
                    value={
                      allLgps[allLgps.findIndex((key) => key.value === lgp)]
                    }
                    onChange={(val) => {
                      setLgp(val ? val.value : null);
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormLabel component="legend" className="text-label">
                    Select Phlebo
                  </FormLabel>
                  <Select
                    options={allPhlebos}
                    styles={selectStyles}
                    isClearable={true}
                    placeholder={"Phlebo"}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator,
                    }}
                    value={
                      allPhlebos[
                        allPhlebos.findIndex((key) => key.value === phlebos)
                      ]
                    }
                    onChange={(val) => {
                      setPhlebos(val ? val.value : null);
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormLabel component="legend" className="text-label">
                    Select Slots
                  </FormLabel>
                  <Autocomplete
                    multiple
                    id="combo-box-demo"
                    onChange={(event, newValue) => {
                      handleTimings(newValue);
                    }}
                    options={slots}
                    getOptionLabel={(option) => `${option.name}`}
                    style={{ minWidth: 180, marginTop: 15 }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              style={{
                marginTop: 10,
                marginBottom: 10,
                borderTop: "1px solid grey",
                borderBottom: "1px solid grey",
                display: "flex",
              }}
            >
              <div style={{ marginTop: 10 }}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Select Report</FormLabel>
                  <RadioGroup
                    onChange={(e) => setReport(e.target.value)}
                    value={report}
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" />}
                      label="City Wise"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio color="primary" />}
                      label="Client Wise"
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio color="primary" />}
                      label="Phlebo Wise"
                    />
                    <FormControlLabel
                      value="4"
                      control={<Radio color="primary" />}
                      label="Client Report"
                    />
                    <FormControlLabel
                      value="5"
                      control={<Radio color="primary" />}
                      label="Defaulters List"
                    />
                    <FormControlLabel
                      value="6"
                      control={<Radio color="primary" />}
                      label="Custom Report"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div style={{ marginTop: 30, marginLeft: 30 }}>
                <IconButton
                  onClick={() => getAllReports()}
                  edge="end"
                  color="inherit"
                  size="small"
                  style={{ fontSize: "12px", marginBottom: "10px" }}
                >
                  <Refresh /> Refresh
                </IconButton>
              </div>
            </Grid>
            {parseInt(report) === 6 ? (
              <>
                <Grid item xs={12}>
                  <FormLabel component="options">Select Options</FormLabel>
                </Grid>
                {Object.keys(customOptions).map((elem, i) => (
                  <Grid item xs={i === 0 || i === 1 ? 3 : 2} key={`GRID_${i}`}>
                    <FormControl component="fieldset">
                      <FormGroup
                        aria-label="position"
                        row
                        style={{ display: "grid" }}
                      >
                        {customOptions[elem].map((el, index) => (
                          <FormControlLabel
                            value={el.key}
                            key={index}
                            control={
                              <Checkbox
                                checked={custom[el.key]}
                                onChange={handleCheckboxChange}
                                name={el.key}
                                color="primary"
                              />
                            }
                            label={el.label}
                            labelPlacement="end"
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                ))}
              </>
            ) : null}

            <Grid item xs={8} style={{ paddingLeft: 20, paddingRight: 20 }}>
              <DatePicker
                endDate={90}
                selectDate={date}
                getSelectedDay={(date) => setDate(date)}
                color={"#81CD0A"}
              />
            </Grid>

            <Grid
              item
              xs={3}
              style={{
                paddingLeft: 20,
                paddingTop: 30,
                paddingRight: 20,
                margin: "auto",
                right: 0,
              }}
            >
              <Button
                onClick={() => downloadReport()}
                variant="contained"
                color="success"
                style={{ backgroundColor: "#59caaa", color: "#fff" }}
              >
                Download
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TableContainer component={Paper} className="mb-30">
                <Table size="small" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{renderHeader("Name", "name")}</TableCell>
                      <TableCell>
                        {renderHeader("Received", "received")}
                      </TableCell>
                      <TableCell>
                        {renderHeader("Assigned", "assigned")}
                      </TableCell>
                      <TableCell>
                        {renderHeader("Pending", "pending")}
                      </TableCell>
                      <TableCell>
                        {renderHeader("Completed", "completed")}
                      </TableCell>
                      <TableCell>
                        {renderHeader(
                          "Completed %",
                          "completed%",
                          "percentage"
                        )}
                      </TableCell>
                      <TableCell>
                        {renderHeader("I2H Ota%", "i2h_ota%", "percentage")}
                      </TableCell>
                      <TableCell>
                        {renderHeader(
                          "Client Ota%",
                          "client_ota%",
                          "percentage"
                        )}
                      </TableCell>
                      <TableCell>{renderHeader("CBC", "cbc")}</TableCell>
                      <TableCell>
                        {renderHeader("CBC %", "cbc%", "percentage")}
                      </TableCell>
                      <TableCell>{renderHeader("RBC", "rbc")}</TableCell>
                      <TableCell>
                        {renderHeader("RBC %", "rbc%", "percentage")}
                      </TableCell>
                      <TableCell>{renderHeader("CBP", "cbp")}</TableCell>
                      <TableCell>
                        {renderHeader("CBP %", "cbp%", "percentage")}
                      </TableCell>
                      <TableCell>{renderHeader("RBP", "rbp")}</TableCell>
                      <TableCell>
                        {renderHeader("RBP %", "rbp%", "percentage")}
                      </TableCell>
                      <TableCell>{renderHeader("New", "new")}</TableCell>
                      <TableCell>
                        {renderHeader("Rejected", "rejected")}
                      </TableCell>
                      <TableCell>{renderHeader("PC", "pc")}</TableCell>
                      <TableCell>{renderHeader("Review", "review")}</TableCell>
                      <TableCell>{renderHeader("ZNC", "znc")}</TableCell>
                      <TableCell>{renderHeader("DO", "do")}</TableCell>
                      <TableCell>{renderHeader("CR", "cr")}</TableCell>
                      <TableCell>{renderHeader("CLD", "cld")}</TableCell>
                      <TableCell>{renderHeader("CLD-PR", "cld-pr")}</TableCell>
                      <TableCell>{renderHeader("CLD-CR", "cld-cr")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(reports || []).length <= 0 && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {" "}
                          No Data found.{" "}
                        </TableCell>
                      </TableRow>
                    )}
                    {getSortedData(reports || []).map((repts, i) => (
                      <TableRow id={i}>
                        <TableCell>{repts.name}</TableCell>
                        <TableCell>{repts.received}</TableCell>
                        <TableCell>{repts.assigned}</TableCell>
                        <TableCell>{repts.pending}</TableCell>
                        <TableCell>{repts.completed}</TableCell>
                        <TableCell>{repts["completed%"]}</TableCell>
                        <TableCell>{repts["i2h_ota%"]}</TableCell>
                        <TableCell>{repts["client_ota%"]}</TableCell>
                        <TableCell>{repts["cbc"]}</TableCell>
                        <TableCell>{repts["cbc%"]}</TableCell>
                        <TableCell>{repts["rbc"]}</TableCell>
                        <TableCell>{repts["rbc%"]}</TableCell>
                        <TableCell>{repts["cbp"]}</TableCell>
                        <TableCell>{repts["cbp%"]}</TableCell>
                        <TableCell>{repts["rbp"]}</TableCell>
                        <TableCell>{repts["rbp%"]}</TableCell>
                        <TableCell>{repts["new"]}</TableCell>
                        <TableCell>{repts["rejected"]}</TableCell>
                        <TableCell>{repts["pc"]}</TableCell>
                        <TableCell>{repts["review"]}</TableCell>
                        <TableCell>{repts["znc"]}</TableCell>
                        <TableCell>{repts["do"]}</TableCell>
                        <TableCell>{repts["cr"]}</TableCell>
                        <TableCell>{repts["cld"]}</TableCell>
                        <TableCell>{repts["cld-pr"]}</TableCell>
                        <TableCell>{repts["cld-cr"]}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell>{""}</TableCell>
                      <TableCell>{getTotal("received")}</TableCell>
                      <TableCell>{getTotal("assigned")}</TableCell>
                      <TableCell>{getTotal("pending")}</TableCell>
                      <TableCell>{getTotal("completed")}</TableCell>
                      <TableCell>{getAverage("completed%")}</TableCell>
                      <TableCell>{getAverage("i2h_ota%")}</TableCell>
                      <TableCell>{getAverage("client_ota%")}</TableCell>
                      <TableCell>{getTotal("cbc")}</TableCell>
                      <TableCell>{getAverage("cbc%")}</TableCell>
                      <TableCell>{getTotal("rbc")}</TableCell>
                      <TableCell>{getAverage("rbc%")}</TableCell>
                      <TableCell>{getTotal("cbp")}</TableCell>
                      <TableCell>{getAverage("cbp%")}</TableCell>
                      <TableCell>{getTotal("rbp")}</TableCell>
                      <TableCell>{getAverage("rbp%")}</TableCell>
                      <TableCell>{getTotal("new")}</TableCell>
                      <TableCell>{getTotal("rejected")}</TableCell>
                      <TableCell>{getTotal("pc")}</TableCell>
                      <TableCell>{getTotal("review")}</TableCell>
                      <TableCell>{getTotal("znc")}</TableCell>
                      <TableCell>{getTotal("do")}</TableCell>
                      <TableCell>{getTotal("cr")}</TableCell>
                      <TableCell>{getTotal("cld") || 0}</TableCell>
                      <TableCell>{getTotal("cld-pr") || 0}</TableCell>
                      <TableCell>{getTotal("cld-cr") || 0}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </React.Fragment>
      </div>
    </DocContainer>
  );
};

const mapStateToProps = ({ constants, lgp, plebos, orders }) => {
  return {
    cities: constants.cities,
    lgps: lgp.plebos,
    plebos: plebos.plebos,
    cityValue: orders.cityValue,
  };
};

export default withRouter(connect(mapStateToProps)(AutoAssign));
