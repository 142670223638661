/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './clinics.css';
import AddDoctor from '../Doctors/addDoctors';

import DocContainer from './../../containers/DocContainer';
import ActionLoader from '../../components/ActionLoader';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  Paper,
  Button,
  Grid,
  TextField,
  IconButton,
  Switch,
  Avatar,
  MenuItem,
  InputAdornment,
} from '@material-ui/core';

import {
  GET_ALL_DOCTORS,
  ADD_DOCTORS,
  UPLOAD_IMAGE,
  GET_ALL_STATES,
  GET_ALL_CONSTANTS,
  DELETE_DOCTORS,
  UPDATE_DOCTORS,
} from './../../constants/actionConstants';

import {
  Edit,
  Clear,
  ArrowBackOutlined,
} from '@material-ui/icons';


import Geocode from 'react-geocode';

import  { registerLocale } from 'react-datepicker';
import el from 'date-fns/locale/el';
registerLocale('el', el);
import CloseIcon from '@material-ui/icons/Close';

Geocode.setApiKey(process.env.REACT_APP_GEO_CODE_API);





class Doctors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      searchText: '',
      isError: false,
      isDoctorOpen: false,
      kycUrl: '',
      kycDocumentType: '',
      tab: 1,
      formTab: 1,
      doctors: [],
      data: {
        doctorId: '',
        firstName: '',
        lastName: '',
        contactNumber: '',
        alternateContactNumber: '',
        emailId: '',
        qualification: '',
        specialization: '',
        experience: '',
        certificates: [],
        imageUrl: '',
        address1: '',
        address2: '',
        landmark: '',
        city: '',
        state: '',
        zipcode: '',
        doctorType: 0,
        accepts_vmer: 0,
        accepts_consultation: 0,
        username: '',
        password: '',
        languages_known: [],
        is_Active: true,
        workingTime: [{
          day: '',
          working_hours_start: '',
          working_hours_end: '',
          working_hours_start1: '',
          working_hours_end1: '',
        }],
        kyc_documents: [],
      },
      viewImages: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.getAllDoctors();
    this.getAllConstants();
    this.getAllStates();
  }

  resetPopup = () => {
    let { data } = this.state;
    data = {
      firstName: '',
      lastName: '',
      contactNumber: '',
      alternateContactNumber: '',
      emailId: '',
      qualification: '',
      specialization: '',
      experience: '',
      certificates: [],
      imageUrl: '',
      address1: '',
      address2: '',
      landmark: '',
      city: '',
      state: '',
      zipcode: '',
      doctorType: 0,
      accepts_vmer: 0,
      accepts_consultation: 0,
      username: '',
      password: '',
      languages_known: [],
      is_Active: true,
      workingTime: [{
        day: '',
        working_hours_start: '',
        working_hours_end: '',
        working_hours_start1: '',
        working_hours_end1: '',
      }],
      kyc_documents: [],
    };
    this.setState({ data, isError: false });
  };

  getAllConstants = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_ALL_CONSTANTS,
    });
  };

  getAllStates = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_ALL_STATES,
    });
  };


  generateLatLong = () => {
    const { data } = this.state;
    const address = `${data.address1} ${data.address2 || ''} ${data.city} ${data.state
      } ${data.zipcode}`;
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        data.latitude = lat;
        data.longitude = lng;
        this.setState({ data });
      },
      (error) => {
        console.error(error);
      }
    );
  };


  addEditDoctor = (doctorId) => {
    const { history } = this.props;
    if (doctorId) {
      history.push(`/addDoctor/${doctorId}`);
    } else {
      history.push(`/addDoctor`);
    }
  };

  redirectToDetail = (doctor) => {
    const { match, history } = this.props;
    this.setState(
      {
        offset: 0,
        data: {
          ...doctor,
          isEdit: true,
        },
      }
    );
    history.push(`/doctors/${doctor.doctorId}`,  doctor );
  };

  redirectToList = () => {
    const { match, history } = this.props;
    this.setState({ offset: 0 }, () => {
      this.getAllDoctors();
    });
    this.resetPopup();
    history.push(`/doctors`);
  };

  getAllDoctors = (id) => {
    const { dispatch } = this.props;
    let data = {
      count: 10,
      offset: this.state.offset,
      text: ''
    };
    if (id) {
      data = { doctorId: id };
    }

    if (this.state.searchText && this.state.searchText.length > 0) {
      data['text'] = this.state.searchText;
    }
    dispatch({
      type: GET_ALL_DOCTORS,
      data,
      onSuccess: ((data) => {
        this.setState({ doctors: data.doctors })
      })
    })
  };

  addDoctors = (dataSet) => {
    const { dispatch, match } = this.props;
    const data = dataSet ? dataSet : this.state.data;

    if (
      data &&
      data.servicing_zipcodes &&
      typeof data.servicing_zipcodes === 'string'
    ) {
      data.servicing_zipcodes = (data.servicing_zipcodes || '').split(',');
    }

    dispatch({
      type: ADD_DOCTORS,
      data,
      onSuccess: () => {
        this.setState({ isDoctorOpen: false });
        if (!match.params.id) {
          this.resetPopup();
          this.getAllDoctors();
        }
      },
    });
  };

  updateDoctors = (data) => {
    const { dispatch, match, errorMessage } = this.props;

    dispatch({
      type: UPDATE_DOCTORS,
      data,
      onSuccess: (result) => {
          const { data } = this.state;
          data['doctorId'] = result.doctorId;
          this.setState({ data });
          this.getAllDoctors();
      },
    })

    
  };


  deleteDoctors = (id) => {
    const { dispatch } = this.props;
    dispatch({
      type: DELETE_DOCTORS,
      data: { doctorId: id },
      onSuccess: () => {
        this.getAllDoctors();
        this.setState({ isDeleteOpen: false, deleteObj: null });
      },
    });
  };



  uploadImage = (file, fields, isAdd) => {
    const { dispatch } = this.props;
    dispatch({
      type: UPLOAD_IMAGE,
      file,
      params: { type: 15 },
      onSuccess: (res) => {
        if (res && res.imageUrl) {
          const { data } = this.state;
          data[fields] = [...data[fields], res.imageUrl];
          this.setState({ data });
        }
      },
    });
  };

  handleFormChange = (e, key) => {
    const { data } = this.state;
    data[key] = e.target.value;
    this.setState({ data });
  };




  validateData = (currentTab, nextTab) => {
    const { data } = this.state;
    if (nextTab) {
      this.setState({ formTab: nextTab });
    }

    
  };

  

  renderPagination = () => {
    return (
      <div className='mt-30'>
        <Grid container spacing={5}>
          <Grid item xs={9} />
          <Grid item xs={3}>
            <Button
              style={{ marginRight: '20px' }}
              size='medium'
              color='primary'
              variant='outlined'
              disabled={this.state.offset === 0}
              onClick={() => {
                this.setState({ offset: this.state.offset - 10 }, () => {
                  this.getAllDoctors();
                });
              }}
            >
              {'< Previous'}
            </Button>
            <Button
              size='medium'
              color='primary'
              variant='outlined'
              disabled={this.state.data.length < 5}
              onClick={() => {
                this.setState({ offset: this.state.offset + 10 }, () => {

                  this.getAllDoctors();

                });
              }}
            >
              {'Next >'}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  };

  renderDeletePopup = () => {
    const { isDeleteOpen, data, deleteObj } = this.state;
    return (
      <Dialog
        disableBackdropClick
        open={isDeleteOpen}
        onClose={() => {
          this.setState({ isDeleteOpen: false });
        }}
      >
        <DialogTitle id='clinic-popup'>Confirm Delete</DialogTitle>
        <DialogContent>
          {' '}
          Are you sure you want to delete {deleteObj.firstName}{' '}
          {deleteObj.lastName} ?{' '}
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: '#00C9AA' }}
            onClick={() => {
              this.deleteDoctors(deleteObj.doctorId);
            }}
            variant='contained'
            color='primary'
          >
            Yes
          </Button>
          <Button
            style={{ borderColor: '#00C9AA', color: '#00C9AA' }}
            onClick={() => {
              this.setState({ isDeleteOpen: false, deleteObj: null });
            }}
            variant='outlined'
            color='primary'
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };



  render() {
    const { cities, states, isLoading, match } = this.props;
    const { doctors } = this.state;
    const {
      isDeleteOpen,
      isOpen,
      isDoctorOpen,
      data,
    } = this.state;
    let isKYC = false;
    if (match.params && match.params.id) {
      isKYC = true;
    }

    return (
      <DocContainer>
        <div className='mr-20'>
          {isLoading && <ActionLoader />}
          {!isKYC ? (
            <React.Fragment>
              <Grid item xs={12}>
                <h2 className='mt-0'>Doctors</h2>
              </Grid>
              <Grid container spacing={2} style={{marginBottom:'1em'}}>

                <Grid item xs={4}>
                  <span className='fa fa-search' />
                  <TextField
                    fullWidth
                    size='small'
                    variant='outlined'
                    placeholder='Search…'
                    value={this.state.searchText}
                    onChange={(e) => {
                      this.setState({ searchText: e.target.value }, () => {
                        this.getAllDoctors();
                      });
                      
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                         {this.state.searchText ? <IconButton
                             onClick={() => {
                              this.setState({ searchText: "" }, () => {
                                this.getAllDoctors();
                              });
                            }}
                          >
                          <CloseIcon/>
                          </IconButton> : null }
                        </InputAdornment>
                      ),
                    }}
                  />

                </Grid>
                <Grid item xs={4}>
                  <Button variant='contained' color='primary' onClick={() =>{this.getAllDoctors()}}>Search</Button>
                </Grid>
                <Grid item xs={3} style={{textAlign:'right'}} >
                  <Button
                    style={{ backgroundColor: '#0D8DA3' }}
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      this.addEditDoctor();
                    }}
                  >
                    Add Doctor
                  </Button>
                </Grid>
              </Grid>



              <TableContainer component={Paper} className="mb-30">
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Image</TableCell>
                      <TableCell> Name</TableCell>
                      <TableCell> Specialization </TableCell>
                      <TableCell> Experience </TableCell>
                      <TableCell> City</TableCell>
                      <TableCell> Mobile </TableCell>
                      <TableCell>Working Status</TableCell>
                      <TableCell>Doctor Type</TableCell>
                      <TableCell>Is Active</TableCell>
                      <TableCell> Action </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {doctors.length <= 0 && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {" "}
                          No Doctors found.{" "}
                        </TableCell>
                      </TableRow>
                    )}
                    {doctors.map((doctor) => (
                      <React.Fragment key={doctor.doctorId}>
                        <TableRow>
                          <TableCell>
                            <a href={doctor.imgUrl} download>
                              <Avatar
                                alt="img"
                                size="small"
                                src={doctor.imageUrl}
                              />
                            </a>
                          </TableCell>
                          <TableCell>{doctor.firstName} {doctor.lastName}</TableCell>
                          <TableCell>{doctor.specialization}</TableCell>
                          <TableCell>{doctor.experience}</TableCell>
                          <TableCell>{doctor.city}</TableCell>
                          <TableCell>{doctor.contactNumber}</TableCell>
                          <TableCell><Select
                            style={{ width: 100 }}
                            value={doctor.working_status || ''}
                            onChange={(e) => {
                              const data = { doctorId: doctor.doctorId };
                              data.working_status = e.target.value;
                              data.isEditWorkingStatus = true;
                              data.isEdit = true;
                              this.updateDoctors(data);
                            }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={'Working'}>Working</MenuItem>
                            <MenuItem value={'Resigned'}>Resigned</MenuItem>
                            <MenuItem value={'Terminated'}>Terminated</MenuItem>
                            <MenuItem value={'Abscond'}>Abscond</MenuItem>
                            <MenuItem value={'On-Hold'}>On-Hold</MenuItem>
                          </Select></TableCell>
                          <TableCell>{doctor.doctorType === "1" ? "THC Employee" : doctor.doctorType === "2" ? "On-Call Employee" : doctor.doctorType === "3" ? "On-Call Vendor" : null}</TableCell>
                          <TableCell> <Switch
                            size='small'
                            color='primary'
                            name='is_Active'
                            checked={doctor.is_Active}
                            onChange={(e) => {
                              const data = { ...doctor };
                              data.is_Active = e.target.checked;
                              data.isEdit = true;
                              this.updateDoctors(data);
                            }}
                          /></TableCell>
                          <TableCell>
                            <IconButton
                              edge="end"
                              color="inherit"
                              style={{ marginRight: 10 }}
                              size="small"
                              onClick={() => {
                                this.redirectToDetail(doctor);
                              }}
                            >
                              <Edit />
                            </IconButton>
                            <IconButton
                              edge='end'
                              color='inherit'
                              size='small'
                              onClick={() => {
                                this.setState({
                                  isDeleteOpen: true,
                                  deleteObj: doctor,
                                });
                              }}
                            >
                              <Clear />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {this.renderPagination()}

              {isDoctorOpen && this.renderPopup()}

              {isDeleteOpen && this.renderDeletePopup()}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid container spacing={3}>
                <IconButton
                  edge='end'
                  color='inherit'
                  onClick={() => {
                    this.redirectToList();
                  }}
                >
                  <ArrowBackOutlined />
                </IconButton>
                <Grid item xs={4}>
                  {' '}
                  <h2 className='mt-0'>DOCTOR DETAILS</h2>{' '}
                </Grid>
              </Grid>
              <AddDoctor doctorId={match.params.id} isView={true} redirectToList={this.redirectToList} />
            </React.Fragment>
          )}
        </div>
      </DocContainer>
    );
  }
}

const mapStateToProps = ({ plebos, constants, lgp }) => {
  return {
    isLoading: plebos.isSubmitting,
    plebos: plebos.plebos,
    hasMore: plebos.hasMore,
    plebosKYC: plebos.plebosKYC,
    attendance: plebos.attendance,
    lgps: lgp.plebos,

    cities: constants.cities,
    states: constants.states,
  };
};

export default withRouter(connect(mapStateToProps)(Doctors));
