import { call, put } from 'redux-saga/effects';

import { postDataWithToken, getDataWithToken, putDataWithToken, deleteDataWithToken, GET_DOCTOR_BY_ID_URL, GET_ALL_DOCTOR_ORDERS_URL, ADD_DOCTOR_URL, GET_ALL_DOCTOR_URL, UPDATE_DOCTOR_URL, DELETE_DOCTOR_URL, GET_DOCTOR_ORDERS_COUNT_URL } from 'Utils/api';

import { GET_ALL_DOCTORS, IS_SUBMITTING, TOAST_ERROR, TOAST_SUCCESS } from 'Constants/actionConstants';

export function* getAllDoctors(action) {
    try {
        const url = action.data && action.data.doctorId ? GET_DOCTOR_BY_ID_URL :GET_ALL_DOCTOR_URL;
        yield put({ type: IS_SUBMITTING, isSubmitting: true });

        const data = yield call(getDataWithToken, url, action.data);

        //   yield put({
        //     type: GET_ALL_DOCTORS,
        //     data: data && data.list ? data.list : [],
        //     // hasMore:
        //     //   data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false
        //   });

        if (action.onSuccess) {
            action.onSuccess(data || null);
        }

        yield put({ type: IS_SUBMITTING, isSubmitting: false });
    } catch (e) {
        yield put({ type: IS_SUBMITTING, isSubmitting: false });
        yield put({
            type: TOAST_ERROR,
            message: e && e.error ? e.error : "Oops! Something went wrong"
        });
    }
}

export function* addDoctors(action) {
    try {
        const url = ADD_DOCTOR_URL;
        yield put({ type: IS_SUBMITTING, isSubmitting: true });
        const data = yield call(
            action.data && action.data.doctorId
                ? putDataWithToken
                : postDataWithToken,
            url,
            action.data
        );
        yield put({ type: IS_SUBMITTING, isSubmitting: false });

        yield put({
            type: TOAST_SUCCESS,
            message:
                data && data.message ? data.message : "Data updated successfully!"
        });

        if (action.onSuccess) {
            action.onSuccess(data);
        }
    } catch (e) {
        yield put({ type: IS_SUBMITTING, isSubmitting: false });
        yield put({
            type: TOAST_ERROR,
            message: e && e.error ? e.error : "Oops! Something went wrong"
        });
    }
}

export function* updateDoctors(action) {
    try {
        const url = UPDATE_DOCTOR_URL;
        yield put({ type: IS_SUBMITTING, isSubmitting: true });
        const data = yield call( putDataWithToken,
            url,
            action.data
        );
        yield put({ type: IS_SUBMITTING, isSubmitting: false });

        yield put({
            type: TOAST_SUCCESS,
            message:
                data && data.message ? data.message : "Data updated successfully!"
        });

        if (action.onSuccess) {
            action.onSuccess(data);
        }
    } catch (e) {
        yield put({ type: IS_SUBMITTING, isSubmitting: false });
        yield put({
            type: TOAST_ERROR,
            message: e && e.error ? e.error : "Oops! Something went wrong"
        });
    }
}

export function* deleteDoctors(action) {
    try{
        yield put({ type : IS_SUBMITTING, isSubmitting: true });

        const data = yield call(deleteDataWithToken, DELETE_DOCTOR_URL, action.data);

        yield put({
            type: TOAST_SUCCESS,
            message: data && data.message?data.message : "Data deleted successfully!"
        })

        if (action.onSuccess) {
            action.onSuccess(data);
        }

        yield put({ type : IS_SUBMITTING, isSubmitting: false });
    } catch(e) {
        yield put({ type : IS_SUBMITTING, isSubmitting: false })
        yield put({
            type: TOAST_ERROR,
            message: e && e.error?e.error:"Oops! Something went wrong"
        })
    }
}


export function* getDoctorOrders(action) {
    try {
        const url = GET_ALL_DOCTOR_ORDERS_URL;
        yield put({ type: IS_SUBMITTING, isSubmitting: true });

        const data = yield call(getDataWithToken, url, action.data);
    

        if (action.onSuccess) {
            action.onSuccess(data || null);
        }

        yield put({ type: IS_SUBMITTING, isSubmitting: false });
    } catch (e) {
        yield put({ type: IS_SUBMITTING, isSubmitting: false });
        yield put({
            type: TOAST_ERROR,
            message: e && e.error ? e.error : "Oops! Something went wrong"
        });
    }
}

export function* getDoctorOrdersCount(action) {
    try {
      yield put({ type: IS_SUBMITTING, isSubmitting: true });
      const data = yield call(
        getDataWithToken,
        GET_DOCTOR_ORDERS_COUNT_URL,
        action.data
      );
      if (action.onSuccess) {
        action.onSuccess(data);
      }
  
      yield put({ type: IS_SUBMITTING, isSubmitting: false });
    } catch (e) {
      yield put({ type: IS_SUBMITTING, isSubmitting: false });
      yield put({
        type: TOAST_ERROR,
        message: e && e.error ? e.error : "Oops! Something went wrong",
      });
    }
  }
