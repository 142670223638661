import { SET_CITY_VALUE, VIDEO_TOKEN, VIDEO_MER, GENERATE_PRESIGNED_URL, CREATE_VMER_VITALS } from 'Constants/actionConstants';
import {
  IS_SUBMITTING,
  LAB_REPORTS_DATA,
  ROUTE_PLANS,
  ROUTE_PLAN_COUNT,
  SET_TYPE_VALUE,
  ALL_ORDERS,
  ORDER_DATA,
  VITALS_DATA,
  SET_DATE_VALUE,
  DOWNLOAD_MER,
} from 'Constants/actionConstants';

const orders = (state = {

  isSubmitting: false,
  orders: [],
  vitalsData: [],
  videoToken: {},
  videoMer: {},
  downloadMer:{},
  hasMore: false,
  orderData: [],
  labReports: [],
  routePlans: [],
  routeCount: [],
  typeValue: '',
  dateValue: '',
  cityValue: ''
}, action) => {

  switch (action.type) {

    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };

    case ALL_ORDERS:
      return {
        ...state,
        orders: action.data,
        hasMore: action.hasMore
      };

    case ORDER_DATA:
      return {
        ...state,
        orderData: action.data,
      };

    case VITALS_DATA:
      return {
        ...state,
        vitalsData: action.data,
      };

    case VIDEO_TOKEN:
      return {
        ...state,
        videoToken: action.data,
      };
    case VIDEO_MER:
      return {
        ...state,
        videoMer: action.data,
      };
      case GENERATE_PRESIGNED_URL:
        return{
          ...state,
          presignedUrl:action.data,
        };

      case DOWNLOAD_MER:
        return {
          ...state,
          downloadMer: action.data,
        };

    case LAB_REPORTS_DATA:
      return {
        ...state,
        labReports: action.data,
      };

    case ROUTE_PLANS:
      return {
        ...state,
        routePlans: action.data,
        hasMore: action.hasMore
      };

    case ROUTE_PLAN_COUNT:
      return {
        ...state,
        routeCount: action.data,
      };

    case SET_TYPE_VALUE:
      return {
        ...state,
        typeValue: action.data,
      };

    case SET_DATE_VALUE:
      return {
        ...state,
        dateValue: action.data,
      };
    case SET_CITY_VALUE:
      return {
        ...state,
        cityValue: action.data,
      };
      case CREATE_VMER_VITALS:
        return {
          ...state,
          vmerVitals:action.data,
        };

    default:
      return state;
  }

}

export default orders;
