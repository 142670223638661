import React, { Component } from 'react';
import DocContainer from 'Containers/DocContainer/index';
import { Typography, Grid } from '@material-ui/core';
import { GET_ALL_DOCTORS } from 'Constants/actionConstants';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUser } from 'Utils/storage';
import './index.css';

class DoctorSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {}
        }

    }

    UNSAFE_componentWillMount() {
        this.getDoctorsById();
    }

    getDoctorsById = (id) => {
        const user = getUser();
        const { dispatch } = this.props;
        let data = {
            doctorId: user.doctorId,
        };

        dispatch({
            type: GET_ALL_DOCTORS,
            data,
            onSuccess: (doctorData) => {
                this.setState({
                    data: {
                        ...this.state.data,
                        ...doctorData
                    }
                })
            }
        })
    };
    render() {
        const { data } = this.state;
        const timing = data.workingTime;
        const certificates = data.certificates;
        const kyc = data.kyc_documents;
        return (
            <DocContainer>
                <React.Fragment>
                    <Grid item xs={12}>
                        <Typography variant='h5'>My Profile - {data.doctorEmployeeId}</Typography>
                    </Grid>
                    <Grid container spacing={1} style={{ marginTop: '2em' }}>
                        <Grid item xs={12}>
                            <Typography variant='h6' color={'secondary'}>Basic Details</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{ fontSize: '16px' }} ><span style={{ fontWeight: 'bold' }}>Name: </span>{data.firstName +
                                " " +
                                data.lastName}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{ fontSize: '16px' }}  ><span style={{ fontWeight: 'bold' }}>Mobile Number: </span>{data.contactNumber}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{ fontSize: '16px' }}  ><span style={{ fontWeight: 'bold' }}>Email ID: </span>{data.emailId}</Typography>
                        </Grid>
                        <Grid item xs={3}></Grid>
                        <Grid container spacing={1} style={{ marginTop: '2em' }}>
                            <Grid item xs={3}>
                                <Typography style={{ fontSize: '16px' }} ><span style={{ fontWeight: 'bold' }}>Qualification: </span>{data.qualification}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography style={{ fontSize: '16px' }} ><span style={{ fontWeight: 'bold' }}>Specialization: </span>{data.specialization}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography style={{ fontSize: '16px' }}  ><span style={{ fontWeight: 'bold' }}>Experience: </span>{data.experience}</Typography>
                            </Grid>
                            <Grid item xs={3}></Grid>
                        </Grid>
                        <Grid container spacing={1} style={{marginTop:'2em'}}>
                            <Grid item xs={3}>
                            <Typography style={{ fontSize: '16px' }} ><span style={{ fontWeight: 'bold' }}>Address: </span>{data.address1 + "," + data.address2}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography style={{ fontSize: '16px' }}  ><span style={{ fontWeight: 'bold' }}>City: </span>{data.city}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography style={{ fontSize: '16px' }}  ><span style={{ fontWeight: 'bold' }}>State: </span>{data.state}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography style={{ fontSize: '16px' }}  ><span style={{ fontWeight: 'bold' }}>Zipcode: </span>{data.zipcode}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{marginTop:'2em'}}>
                            <Typography variant={'h6'} color={'secondary'}>Timing</Typography>
                        </Grid>
                        {timing && timing.map((item) => (
                            <Grid container spacing={1}>

                                <Grid item xs={2}>
                                    <Typography style={{ fontSize: '16px' }} ><span style={{ fontWeight: 'bold' }}>Day: </span>{item.day}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{ fontSize: '16px' }} ><span style={{ fontWeight: 'bold' }}>From: </span>{item.working_hours_start}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{ fontSize: '16px' }}  ><span style={{ fontWeight: 'bold' }}>To: </span>{item.working_hours_end}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{ fontSize: '16px' }} ><span style={{ fontWeight: 'bold' }}>From: </span>{item.working_hours_start1}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{ fontSize: '16px' }}  ><span style={{ fontWeight: 'bold' }}>To: </span>{item.working_hours_end1}</Typography>
                                </Grid>
                                <Grid item xs={2}></Grid>
                            </Grid>
                        ))}
                       <Grid item xs={12} style={{marginTop:'2em'}}>
                            <Typography variant={'h6'} color={'secondary'}>Certificates</Typography>
                        </Grid>
                       {certificates && certificates.map((img) => (
                        <Grid container spacing={1} style={{display:'flex', flexDirection:'row', justifyContent:'left'}}>
                            <Grid item xs={6}>
                                <img src={img} style={{width:'100px', height:'100px'}}></img>
                            </Grid>
                        </Grid>
                       ))} 
                       <Grid item xs={12} style={{marginTop:'2em'}}>
                            <Typography variant={'h6'} color={'secondary'}>KYC Documents</Typography>
                        </Grid>
                       {kyc && kyc.map((img) => (
                        <Grid container spacing={1} style={{display:'flex', flexDirection:'row', justifyContent:'left'}}>
                            <Grid item xs={6}>
                                <img src={img} style={{width:'100px', height:'100px'}}></img>
                            </Grid>
                        </Grid>
                       ))} 
                    </Grid>
                    
                   
                </React.Fragment>
            </DocContainer>
        )
    }
}

const mapStateToProps = ({ plebos, constants, lgp }) => {
    return {
        plebos: plebos.plebos,
        isLoading: plebos.isSubmitting,
        cities: constants.cities,
        states: constants.states,
        salaries: constants.salaries,
    };
};

export default withRouter(connect(mapStateToProps)(DoctorSettings));