/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ActionLoader from "../../components/ActionLoader";
import {
  Grid,
  Button,
  makeStyles,
  TextField,
  Select
} from "@material-ui/core";

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";

import {
  GET_ALL_CONSTANTS,
  ADD_MER_QUESTIONS
} from "../../constants/actionConstants";

import "./index.css";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "275px",
    marginTop: 18,
    height: "38px",
    border: "none"
  },
  section: {
    padding: 10
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const questionTypes = [
  "text",
  "description",
  "radio",
  "mcq",
  "dropdown",
  "calendar",
  "img",
  "paragraph"
];

const questionTypesLabel = {
  text: "Text",
  description: "Description",
  radio: "Single choice",
  mcq: "Multiple choice",
  dropdown: "Dropdown",
  calendar: "Calender",
  img: "Image",
  paragraph: "Paragraph"
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const MERForm = props => {
  const {  setIsAdd, lgpId, merData = null } = props;
  const classes = useStyles();
  const [isLoading] = useState(false);

  const [sections, setSections] = useState([]);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    getAllConstants();
  }, []);

  useEffect(() => {
    setFormData(merData);
    if (merData && merData.mer) {
      setSections(merData.mer);
    }
  }, [merData]);

  const getAllConstants = () => {
    const { dispatch } = props;
    dispatch({
      type: GET_ALL_CONSTANTS
    });
  };

  const handleSaveQuestions = () => {
    const { dispatch } = props;
    let payload = { mer: sections, lgpId };
    if (formData && formData.name) {
      payload.name = formData.name;
    }
    if (formData && formData.formId) {
      payload.formId = formData.formId;
    }
    dispatch({
      type: ADD_MER_QUESTIONS,
      data: payload,
      onSuccess: () => {
        setIsAdd(false);
      }
    });
  };

  const handleNewSection = () => {
    const sectionsCopy = JSON.parse(JSON.stringify(sections));
    sectionsCopy.push({
      title: "",
      description: "",
      sequence: sectionsCopy.length + 1,
      questions: []
    });
    setSections(sectionsCopy);
  };

  const handleDeleteSection = index => {
    const sectionsCopy = JSON.parse(JSON.stringify(sections));
    sectionsCopy.splice(index, 1);
    setSections(sectionsCopy);
  };

  const handleDeleteQuestion = (sectionIndex, qIndex) => {
    const sectionsCopy = JSON.parse(JSON.stringify(sections));
    sectionsCopy[sectionIndex]["questions"].splice(qIndex, 1);
    setSections(sectionsCopy);
  };

  const handleNewQuestion = sectionIndex => {
    const sectionsCopy = JSON.parse(JSON.stringify(sections));
    if (!sectionsCopy[sectionIndex]["questions"]) {
      sectionsCopy[sectionIndex]["questions"] = [];
    }
    sectionsCopy[sectionIndex]["questions"].push({
      name: "",
      type: "text",
      options: [],
      value: "",
      sequence: sectionsCopy[sectionIndex]["questions"].length + 1,
      isMandatory: false
    });
    setSections(sectionsCopy);
  };

  const handleSectionChange = (sectionIndex, key, value) => {
    const sectionsCopy = JSON.parse(JSON.stringify(sections));
    sectionsCopy[sectionIndex][key] = value;
    setSections(sectionsCopy);
  };

  const handleChangeQuestion = (sectionIndex, questionIndex, key, value) => {
    const sectionsCopy = JSON.parse(JSON.stringify(sections));
    sectionsCopy[sectionIndex]["questions"][questionIndex][key] = value;
    setSections(sectionsCopy);
  };

  const handleChangeQuestionOption = (
    sectionIndex,
    questionIndex,
    oIndex,
    value
  ) => {
    const sectionsCopy = JSON.parse(JSON.stringify(sections));
    sectionsCopy[sectionIndex]["questions"][questionIndex]["options"][oIndex][
      "name"
    ] = value;
    setSections(sectionsCopy);
  };

  const handleDeleteQuestionOption = (
    sectionIndex,
    questionIndex,
    optionIndex
  ) => {
    const sectionsCopy = JSON.parse(JSON.stringify(sections));
    sectionsCopy[sectionIndex]["questions"][questionIndex]["options"].splice(
      optionIndex,
      1
    );
    setSections(sectionsCopy);
  };

  const handleAddQuestionOption = (sectionIndex, qIndex) => {
    const sectionsCopy = JSON.parse(JSON.stringify(sections));
    if (!sectionsCopy[sectionIndex]["questions"][qIndex]["options"]) {
      sectionsCopy[sectionIndex]["questions"][qIndex]["options"] = [];
    }
    sectionsCopy[sectionIndex]["questions"][qIndex]["options"].push({
      name: "",
      isSelect: false
    });
    setSections(sectionsCopy);
  };

  const getQuestionSection = (question, sIndex, qIndex) => {
    if (["radio", "mcq", "dropdown"].includes(question.type)) {
      return renderAddOption(question, sIndex, qIndex);
    } else {
      return null;
    }
  };

  const renderAddOption = (question, sIndex, qIndex) => {
    return (
      <Grid container item xs={8}>
        <Grid
          item
          xs={12}
          style={{ marginTop: 10, marginBottom: 5 }}
          className="align-left mb-10"
        >
          <Button
            style={{ marginRight: "20px" }}
            size="medium"
            color="primary"
            variant="outlined"
            onClick={() => handleAddQuestionOption(sIndex, qIndex)}
          >
            {"Add New Option"}
          </Button>
        </Grid>
        {question &&
          question.options &&
          question.options.map((option, oIndex) => (
            <Grid
              item
              xs={12}
              style={{ marginTop: 10 }}
              className="align-left mb-10"
            >
              <span>Option {oIndex + 1}</span>
              <div style={{ display: "flex" }}>
                <TextField
                  fullWidth
                  value={option.name}
                  label={`Option ${oIndex + 1}`}
                  onChange={e =>
                    handleChangeQuestionOption(
                      sIndex,
                      qIndex,
                      oIndex,
                      e.target.value
                    )
                  }
                />
                <Button
                  style={{ marginLeft: 10 }}
                  size="medium"
                  color="primary"
                  variant="outlined"
                  onClick={() =>
                    handleDeleteQuestionOption(sIndex, qIndex, oIndex)
                  }
                >
                  {"Delete"}
                </Button>
              </div>
            </Grid>
          ))}
      </Grid>
    );
  };

  return (
    <div>
      {isLoading && <ActionLoader />}
      <React.Fragment>
        <Grid container spacing={1}>
          <Grid item xs={6} className="align-left">
            <TextField
              fullWidth
              value={(formData && formData.name) || ""}
              style={{ marginBottom: 10 }}
              label="Form Title"
              onChange={e => {
                setFormData({
                  ...formData,
                  name: e.target.value
                });
              }}
            />
          </Grid>
          <Grid item xs={6} className="align-right">
            <Button
              style={{ marginRight: "20px" }}
              size="medium"
              color="primary"
              variant="outlined"
              onClick={() => setIsAdd(false)}
            >
              {"Back"}
            </Button>
            <Button
              style={{ marginRight: "20px" }}
              size="medium"
              color="primary"
              variant="outlined"
              onClick={() => handleNewSection()}
            >
              {"Add New Section"}
            </Button>
          </Grid>
          {sections.map((section, sIndex) => (
            <Grid item xs={12} className="align-right">
              <Paper elevation={3} className={classes.section}>
                <Grid item xs={12} className="align-right">
                  <Button
                    style={{ marginRight: "20px" }}
                    size="medium"
                    color="primary"
                    variant="outlined"
                    onClick={() => handleDeleteSection(sIndex)}
                  >
                    {"Delete Section"}
                  </Button>
                  <Button
                    style={{ marginRight: "20px" }}
                    size="medium"
                    color="primary"
                    variant="outlined"
                    onClick={() => handleNewQuestion(sIndex)}
                  >
                    {"Add New Question"}
                  </Button>
                </Grid>
                <Grid container item xs={6}>
                  <Grid item xs={12} className="align-left mb-10">
                    <TextField
                      fullWidth
                      value={section.title || ""}
                      style={{ marginTop: 10 }}
                      label="Section Title"
                      onChange={e =>
                        handleSectionChange(sIndex, "title", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} className="align-left">
                    <TextField
                      fullWidth
                      value={section.description || ""}
                      style={{ marginTop: 10 }}
                      label="Section Description"
                      onChange={e =>
                        handleSectionChange(
                          sIndex,
                          "description",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                </Grid>
                {section.questions && section.questions.length > 0 ? (
                  <>
                    <Grid
                      item
                      container
                      xs={12}
                      style={{ marginTop: 20 }}
                      className="align-left mb-10 mt-10"
                    >
                      <Grid item xs={8} className="align-left mb-10">
                        <span>Questions</span>
                      </Grid>
                    </Grid>
                    {section.questions.map((question, qIndex) => (
                      <Paper
                        elevation={3}
                        className={classes.section}
                        style={{ marginTop: 10 }}
                      >
                        <Grid
                          container
                          item
                          xs={12}
                          className="align-left mb-10 mt-10"
                        >
                          <Grid
                            container
                            item
                            xs={12}
                            className="align-left mb-10"
                          >
                            <Grid item xs={8} className="align-left mb-10">
                              <span>Question {qIndex + 1}</span>
                            </Grid>
                            <Grid item xs={4} className="align-right mb-10">
                              <Button
                                style={{ marginRight: "20px" }}
                                size="medium"
                                color="primary"
                                variant="outlined"
                                onClick={() =>
                                  handleDeleteQuestion(sIndex, qIndex)
                                }
                              >
                                {"Delete Question"}
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid item xs={8} className="align-left mb-10">
                            <TextField
                              fullWidth
                              style={{ marginTop: 10 }}
                              label="Question"
                              value={question.name || ""}
                              onChange={e =>
                                handleChangeQuestion(
                                  sIndex,
                                  qIndex,
                                  "name",
                                  e.target.value
                                )
                              }
                            />
                          </Grid>
                          <Grid item xs={2} className="align-left mb-10">
                            <FormControl className={classes.formControl}>
                              <InputLabel id="demo-simple-select-label">
                                Type
                              </InputLabel>
                              <Select
                                value={question.type}
                                onChange={event =>
                                  handleChangeQuestion(
                                    sIndex,
                                    qIndex,
                                    "type",
                                    event.target.value
                                  )
                                }
                              >
                                {questionTypes.map((el, i) => (
                                  <MenuItem key={i} value={el}>
                                    {capitalizeFirstLetter(
                                      questionTypesLabel[el]
                                    )}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={2} className="align-left mb-10">
                            <FormControlLabel
                              style={{ marginLeft: 10 }}
                              control={
                                <Switch
                                  checked={question.isMandatory}
                                  onChange={event =>
                                    handleChangeQuestion(
                                      sIndex,
                                      qIndex,
                                      "isMandatory",
                                      event.target.checked
                                    )
                                  }
                                  name="mandatory"
                                />
                              }
                              label="Is Mandatory"
                            />
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            className="align-left mb-10"
                          >
                            {getQuestionSection(question, sIndex, qIndex)}
                          </Grid>
                        </Grid>
                      </Paper>
                    ))}
                  </>
                ) : null}
              </Paper>
            </Grid>
          ))}
        </Grid>
        {sections.length > 0 && (
          <Grid item xs={12} className="align-right">
            <Button
              style={{ marginTop: 20 }}
              size="medium"
              color="primary"
              variant="outlined"
              onClick={() => {
                setSections([]);
                setIsAdd(false);
              }}
            >
              {"Cancel"}
            </Button>
            <Button
              style={{ marginTop: 20, marginLeft: 10 }}
              size="medium"
              color="primary"
              variant="outlined"
              onClick={() => handleSaveQuestions()}
            >
              {"Save"}
            </Button>
          </Grid>
        )}
      </React.Fragment>
    </div>
  );
};

const mapStateToProps = ({ constants, zones }) => {
  return {
    cities: constants.cities,
    zones: zones.zones
  };
};

export default withRouter(connect(mapStateToProps)(MERForm));
