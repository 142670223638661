/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DocContainer from "./../../containers/DocContainer";
import ActionLoader from "../../components/ActionLoader";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Select, { components } from "react-select";
import { ArrowDropDownOutlined } from "@material-ui/icons";
import DatePicker from "../../components/Datepicker/DatePicker";
import { getUser } from "Utils/storage";
import { Refresh } from "@material-ui/icons";
import { GET_ALL_ORDERS } from "Constants/actionConstants";
import { SET_TYPE_VALUE } from "Constants/actionConstants";
import { SET_DATE_VALUE } from "Constants/actionConstants";

import "./index.css";
import {
  Card,
  IconButton,
  CardHeader,
  CardContent,
  Typography,
  FormLabel,
  Grid,
} from "@material-ui/core";
import {
  GET_ALL_CONSTANTS,
  GET_CLIENT_ORDER_DETAILS,
  GET_LPGS,
  GET_PLEBOS,
} from "./../../constants/actionConstants";
import Paper from "@material-ui/core/Paper";
import { SET_CITY_VALUE } from "Constants/actionConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  paper: {
    width: "90%",
    padding: 10,
  },
  rootPhlebo: {
    display: "flex",
    height: "auto",
    border: "none",
    marginTop: 3,
    boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.1)",
    borderRadius: "12px",
  },
}));

const OrderStatistics = (props) => {
  const { cities, lgps, plebos } = props;
  const user = getUser();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const [date, setDate] = useState(new Date());
  const [allReports, setAllReports] = useState([]);

  const [allCities, setAllCities] = useState([]);
  const [city, setCity] = useState("Bangalore");

  const [allLgps, setAllLgps] = useState([]);
  const [lgp, setLgp] = useState("");

  const [allPhlebos, setAllPhlebos] = useState([]);
  const [phlebos, setPhlebos] = useState("");

  const [totalOrders, setTotalOrders] = useState([]);
  const [topCities, setTopCities] = useState([]);
  const [orderSummary, setOrderSummary] = useState([]);
  const [totalLgp, setTotalLgp] = useState([]);
  useEffect(() => {
    getAllConstants();
    if (user.userType === 1) {
      getLGPs();
      getPlebos();
    }
  }, []);

  useEffect(() => {
    setAllCities(
      cities.map((city) => {
        return { value: city, label: city };
      })
    );
  }, [cities]);

  useEffect(() => {
    setAllLgps(
      lgps.map((lgp) => {
        return { value: lgp.lgpId, label: lgp.name };
      })
    );
  }, [lgps]);

  useEffect(() => {
    setAllPhlebos(
      plebos.map((plebo) => {
        return { value: plebo.phleboId, label: plebo.firstName };
      })
    );
  }, [plebos]);

  useEffect(() => {
    getPlebos();
    getAllReports();
  }, [city,phlebos,lgp,date]);

  const getAllConstants = () => {
    const { dispatch } = props;
    dispatch({
      type: GET_ALL_CONSTANTS,
    });
  };

  const getAllReports = () => {
    setIsLoading(true);
    const { dispatch } = props;
    dispatch({
      type: GET_CLIENT_ORDER_DETAILS,
      data: {
        ts: date.getTime(),
        isDashboard: true,
        lgp: lgp === null?undefined:lgp,
        phlebos: phlebos === null?undefined:phlebos,
        city: city === null?undefined:city,
      },
      onSuccess: (res) => {
        setIsLoading(false);
        setAllReports(res.list || []);
        setTotalOrders(res.list1 || []);
        setTopCities(res.list2 || []);
        setOrderSummary(res.list3 || []);
        setTotalLgp(res.list4 || []);
      },
    });
  };

  const getLGPs = () => {
    const { dispatch } = props;
    dispatch({
      type: GET_LPGS,
      data: {
        count: 200,
        offset: 0,
      },
    });
  };

  const getPlebos = () => {
    const { dispatch } = props;
    let data = {
      count: 200,
      offset: 0,
    };
    data["text"] = city;
    dispatch({
      type: GET_PLEBOS,
      data,
    });
  };

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      fontSize: 12,
      border: "none",
      boxShadow:
        "0 4px 8px 0 rgb(255 255 255), 0 6px 8px 0 rgb(117 109 109 / 30%)",
    }),
    option: (base) => ({
      ...base,
      fontSize: 12,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#9F9F9F",
    }),
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownOutlined />
      </components.DropdownIndicator>
    );
  };

  const handleClickOrders = () => {
    props.history.push('/orders')
    // getOrders(x)
  }

  const getOrders = (x) => {
    const { dispatch } = props;
    let data = {
      count: 10,
      isPast: false,
      offset: 0,
      type:x,
      month:date.getMonth()+1,
      date:date.getDate(),
      year:date.getFullYear(),
      lgpId:lgp?lgp:undefined,
      city:city?city:undefined,
      isPartner:false
    };
   
    dispatch({
      type: GET_ALL_ORDERS,
      isPartner: getUser().userType === 2,
      data,
    });

    dispatch({
      type: SET_TYPE_VALUE,
      data:x
    });
    const month = date.getMonth()+1
    const date2 = date.getDate()
    const year = date.getFullYear()

    dispatch({
      type: SET_DATE_VALUE,
      data:`${month}/${date2}/${year}`
    });

    dispatch({
      type: SET_CITY_VALUE,
      data:city
    });
  };

  return (
    <DocContainer>
      {isLoading && <ActionLoader />}
      <Card style={{ border: "none", boxShadow: "none", overflow: "unset" }}>
        <CardHeader title="Statistics" />
        <CardContent>
       
          <Grid container spacing={3} style={{ marginBottom: 15 ,zIndex: 50, position: 'relative'}}>
           
            <Grid item xs={4} style={{ marginTop: 15, marginBottom: 15 ,zIndex: 50, position: 'relative' }}>
            
              <FormLabel component="legend" className="text-label">
                Select City
              </FormLabel>
              <Select
                options={allCities}
                styles={selectStyles}
                isClearable={true}
                placeholder={"City"}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                }}
                value={
                  allCities[allCities.findIndex((key) => key.value === city)]
                }
                onChange={(val) => {
                  console.log(val);
                  setCity(val ? val.value : null);
                  getPlebos();
                }}
              />
            </Grid>
            {user.userType === 1 ? (
              <>
                <Grid item xs={4} style={{ marginTop: 15, marginBottom: 15 ,zIndex: 50, position: 'relative' }}>
                  <FormLabel component="legend" className="text-label">
                    Select Lgp
                  </FormLabel>
                  <Select
                    options={allLgps}
                    styles={selectStyles}
                    isClearable={true}
                    placeholder={"LGP"}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator,
                    }}
                    value={
                      allLgps[allLgps.findIndex((key) => key.value === lgp)]
                    }
                    onChange={(val) => {
                      setLgp(val ? val.value : null);
                    }}
                  />
                </Grid>
                <Grid item xs={4} style={{ marginTop: 15 }}>
                  <FormLabel component="legend" className="text-label">
                    Select Phlebo
                  </FormLabel>
                  <Select
                    options={allPhlebos}
                    styles={selectStyles}
                    isClearable={true}
                    placeholder={"Phlebo"}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator,
                    }}
                    value={
                      allPhlebos[
                        allPhlebos.findIndex((key) => key.value === phlebos)
                      ]
                    }
                    onChange={(val) => {
                      setPhlebos(val ? val.value : null);
                    }}
                  />
                </Grid>
              </>
            ) : null}
          {/* <Grid container spacing={1}> */}
            <Grid item xs={8} style={{ paddingLeft: 20, paddingRight: 20 }}>
              <DatePicker
                endDate={90}
                selectDate={date}
                getSelectedDay={(date) => setDate(date)}
                color={"#81CD0A"}
              />
            {/* </Grid> */}
          </Grid>
          </Grid>
          <IconButton
                edge="end"
                color="inherit"
                size="small"
                style={{ fontSize: "12px" }}
                onClick={() => {
                  getAllReports();
                }}
              >
                <Refresh /> Refresh
              </IconButton>

          <Grid container spacing={1}>
            <Grid item xs={4} style={{ marginTop: 15 }}>
              <div className="headerView">
                <Typography
                  variant="button"
                  display="block"
                  gutterBottom
                  className={`phlebo-header`}
                >
                  Total Orders
                </Typography>
              </div>
              <Paper component="form" className={classes.rootPhlebo}>
                <div className="phlebo-block1" style={{minHeight:'300px'}}>
                  <div className="tableView">
                    <table className="styled-table">
                      <thead className="tableHead">
                        <tr className="border_bottom">
                          <th>Status</th>
                          <th>Count</th>
                        </tr>
                      </thead>
                      <tbody className="tableBody">
                        {totalOrders.map((el, i) => (
                          <tr className="row" key={`UMP_${i}`}>
                            <td  className="cell" data-title="Phlebo">
                              {el.name}
                            </td>
                            <td className="cell" data-title="Phone number">
                              {el.count}
                            </td>
                          </tr>
                        ))}
                        {!totalOrders.length ? (
                          <tr className="row">
                            <td className="cell" data-title="S.no">
                              No Orders
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={4} style={{ marginTop: 15 }}>
              <div className="headerView">
                <Typography
                  variant="button"
                  display="block"
                  gutterBottom
                  className={`phlebo-header`}
                >
                  Top Cities
                </Typography>
              </div>
              <Paper component="form" className={classes.rootPhlebo}>
                <div className="phlebo-block1" style={{minHeight:'300px'}}>
                  <div className="tableView">
                    <table className="styled-table">
                      <thead className="tableHead">
                        <tr className="border_bottom">
                          <th>City name</th>
                          <th>Count</th>
                        </tr>
                      </thead>
                      <tbody className="tableBody">
                        {topCities.map((el, i) => (
                          <tr className="row" key={`UMP_${i}`}>
                            <td className="cell" data-title="Phlebo">
                              {el.name}
                            </td>
                            <td className="cell" data-title="Phone number">
                              {el.count}
                            </td>
                          </tr>
                        ))}
                        {!topCities.length ? (
                          <tr className="row">
                            <td className="cell" data-title="S.no">
                              No Cities
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={4} style={{ marginTop: 15 }}>
              <div className="headerView">
                <Typography
                  variant="button"
                  display="block"
                  gutterBottom
                  className={`phlebo-header`}
                >
                  Order Summary
                </Typography>
              </div>
              <Paper component="form" className={classes.rootPhlebo}>
                <div className="phlebo-block1" style={{minHeight:'300px'}}>
                  <div className="tableView">
                    <table className="styled-table">
                      <thead className="tableHead">
                        <tr className="border_bottom">
                          <th>Status</th>
                          <th>Count</th>
                        </tr>
                      </thead>
                      <tbody className="tableBody">
                        {orderSummary.map((el, i) => (
                          <tr className="row" key={`UMP_${i}`}>
                            <td className="cell" data-title="Phlebo">
                              {el.name}
                            </td>
                            <td className="cell" data-title="Phone number">
                              {el.count}
                            </td>
                          </tr>
                        ))}
                        {!orderSummary.length ? (
                          <tr className="row">
                            <td className="cell" data-title="S.no">
                              No Orders
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Paper>
            </Grid>
            {user.userType === 1 ? (
              <Grid item xs={4} style={{ marginTop: 15 }}>
                <div className="headerView">
                  <Typography
                    variant="button"
                    display="block"
                    gutterBottom
                    className={`phlebo-header`}
                  >
                    LGP Summary
                  </Typography>
                </div>
                <Paper component="form" className={classes.rootPhlebo}>
                  <div className="phlebo-block1" style={{minHeight:'300px'}}>
                    <div className="tableView">
                      <table className="styled-table">
                        <thead className="tableHead">
                          <tr className="border_bottom">
                            <th>Status</th>
                            <th>Count</th>
                          </tr>
                        </thead>
                        <tbody className="tableBody">
                          {totalLgp.map((el, i) => (
                            <tr className="row" key={`UMP_${i}`}>
                              <td className="cell" data-title="Phlebo">
                                {el.name}
                              </td>
                              <td className="cell" data-title="Phone number">
                                {el.count}
                              </td>
                            </tr>
                          ))}
                          {!totalLgp.length ? (
                            <tr className="row">
                              <td className="cell" data-title="S.no">
                                No Orders
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Paper>
              </Grid>
            ) : null}
            <Grid item xs={4} style={{ marginTop: 15 }}>
              <div className="headerView">
                <Typography
                  variant="button"
                  display="block"
                  gutterBottom
                  className={`phlebo-header`}
                >
                  Orders
                </Typography>
              </div>
              <Paper component="form" className={classes.rootPhlebo}>
                <div className="phlebo-block1" style={{minHeight:'300px'}}>
                  <div className="tableView">
                    <table className="styled-table">
                      <thead className="tableHead">
                        <tr className="border_bottom">
                          <th>Status</th>
                          <th>Count</th>
                        </tr>
                      </thead>
                      <tbody className="tableBody">
                        {allReports.slice(0, 8).map((el, i) => (
                          <tr className="row" key={`UMP_${i}`}>
                            <td style={{cursor:'pointer'}} onClick = {()=>handleClickOrders()} className="cell" data-title="Phlebo">
                              <span onClick = {()=>getOrders(el.type)}>{el.name}</span>
                            </td>
                            <td className="cell" data-title="Phone number">
                              {el.count}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Paper>
            </Grid>
            {allReports.slice(8, 20).length > 0 ? (
              <Grid item xs={4} style={{ marginTop: 70 }}>
                <Paper component="form" className={classes.rootPhlebo}>
                  <div className="phlebo-block1" >
                    <div className="tableView">
                      <table className="styled-table">
                        <thead className="tableHead">
                          <tr className="border_bottom">
                            <th>Status</th>
                            <th>Count</th>
                          </tr>
                        </thead>
                        <tbody className="tableBody">
                          {allReports.slice(8, 20).map((el, i) => (
                            <tr className="row" key={`UMP_${i}`}>
                              <td style={{cursor:'pointer'}} onClick = {()=>handleClickOrders()} className="cell" data-title="Phlebo">
                              <span onClick = {()=>getOrders(el.type)}>{el.name}</span>
                              </td>
                              <td className="cell" data-title="Phone number">
                                {el.count}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Paper>
              </Grid>
            ) : null}
          </Grid>
        </CardContent>
      </Card>
    </DocContainer>
  );
};

const mapStateToProps = ({ constants, lgp, plebos, orders }) => {
  return {
    cities: constants.cities,
    lgps: lgp.plebos,
    plebos: plebos.plebos,
    cityValue: orders.cityValue
  };
};

export default withRouter(connect(mapStateToProps)(OrderStatistics));
