import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './clinics.css';

import DocContainer from './../../containers/DocContainer';
import ActionLoader from "../../components/ActionLoader";

import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	Dialog, DialogTitle, DialogContent,  DialogActions,
	Select,
	Paper,
	Button,
	Grid,
	IconButton,
	Typography,
	Switch,
	Avatar,
	Box,
} from '@material-ui/core';

import {
	GET_PLEBOS,
	ADD_PLEBOS,
	DELETE_PLEBOS,

	GET_PLEBOS_KYC,
	ADD_PLEBOS_KYC,
	DELETE_PLEBOS_KYC,

	UPLOAD_IMAGE,
	GET_ALL_STATES,
	GET_ALL_CONSTANTS,

	GET_LPGS,

	GET_ATTENDANCE_LIST,
	ADD_ATTENDANCE_LIST,
} from './../../constants/actionConstants';


import { Edit, Clear, Visibility } from '@material-ui/icons';


import DatePicker, { registerLocale } from "react-datepicker";
import el from "date-fns/locale/el";
registerLocale("el", el);


class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			offset: 0,
			searchText: '',
			isError: false,
			isPleboOpen: false,
			kycUrl: '',
			kycDocumentType: '',
			tab: 1,
			data: {
				firstName: '',
				lastName: '',
				mobileNumber: '',
				alternateMobileNumber: '',
				emailId: '',
				imageUrl: '',
				isActive: false,
				username: '',
				password: '',
				isInHouse: false,
				is_dedicated: false,
				dedicatedLgpId: '',
				address1: "address 1",
				address2: "address 2",
				landmark: "landmark",
				city: "",
				state: "",
				zipcode: "zipcode",
				latitude: 12.23,
				longitude: 12.23
			},
			startTime: '',
			endTime: '',
			comments: '',
			leaveType: '',
		};
	}

	UNSAFE_componentWillMount() {
		this.getPlebos();
		this.getAllConstants();
		this.getAllStates();
		this.getLGPs();
	}

	resetPopup = () => {
		let {data} = this.state;
		data={
				firstName: '',
				lastName: '',
				mobileNumber: '',
				alternateMobileNumber: '',
				emailId: '',
				imageUrl: '',
				isActive: false,
				username: '',
				password: '',
				isInHouse: false,
				is_dedicated: false,
				dedicatedLgpId: '',
				address1: '',
				address2: '',
				landmark: '',
				city: '',
				state: '',
				zipcode: '',
				latitude: null,
				longitude: null
			};
		this.setState({ data, isError: false });
	}

	getAllConstants = () => {
		const { dispatch } = this.props;
		dispatch({
			type: GET_ALL_CONSTANTS
		});
	}

	getAllStates = () => {
		const { dispatch } = this.props;
		dispatch({
			type: GET_ALL_STATES
		});
	}

	getAttendance = () => {
		const { dispatch, match } = this.props;
		dispatch({
			type: GET_ATTENDANCE_LIST,
			data: {
				count: 10,
				userType: 'PHLEBO',
				userId: match.params.id,
				offset: this.state.offset,
			}
		});
	}

	addAttendances = () => {
		const { dispatch, match } = this.props;
		dispatch({
			type: ADD_ATTENDANCE_LIST,
			data: {
				userId: match.params.id,
				userType: "PHLEBO",
				status: "ABSENT",
				leaveType: this.state.leaveType,
				startTime: new Date(this.state.startTime).getTime(),
				endTime: new Date(this.state.endTime).getTime(),
				comments: this.state.comments,
			},
			onSuccess: ((data) => {
				this.setState({ startTime: '', endTime: '', comments: '', isAttendAdd: false, leaveType: '' });
				this.getAttendance();
			})
		});
	}

	redirectToDetail = (plebo) => {
		const {  history } = this.props;
		this.setState({ 
			offset: 0, 
			data: {
				...plebo, 
				isEdit: true
			} 
		}, () => {
			this.getPlebosKYC();
		})
		history.push(`/phlebo/${plebo.phleboId}`, { state: plebo });
	}

	redirectToList = () => {
		const {  history } = this.props;
		this.setState({  offset: 0 }, () => {
			this.getPlebos();
		})
		this.resetPopup();
		history.push(`/phlebo`);
	}

	getPlebos = (id) => {
		const { dispatch } = this.props;
		let data = {
			count: 10,
			offset: this.state.offset
		};
		if (id) {
			data = { phleboId: id };
		}
		if (this.state.searchText && this.state.searchText.length > 0) {
			data['text'] = this.state.searchText;
		}

		dispatch({
			type: GET_PLEBOS,
			data,
		});
	}

	addPlebos = (dataSet) => {
		const { dispatch, match } = this.props;
		dispatch({
			type: ADD_PLEBOS,
			data: dataSet ? dataSet : this.state.data,
			onSuccess:(() => {
				this.setState({ isPleboOpen: false });
				if (!match.params.id) {
					this.resetPopup();
					this.getPlebos();
				}
			})
		});
	}

	deletePlebos = (id) => {
		const { dispatch } = this.props;
		dispatch({
			type: DELETE_PLEBOS,
			data: {phleboId: id},
			onSuccess: (() => {
				this.getPlebos();
				this.setState({isDeleteOpen:false, deleteObj: null })
			})
		});
	}

	getPlebosKYC = () => {
		const { dispatch, match } = this.props;
		let data = {
			count: 10,
			offset: this.state.offset,
			phleboId: match.params.id
		};

		dispatch({
			type: GET_PLEBOS_KYC,
			data,
		});
	}

	addPlebosKYC = () => {
		const { dispatch, match } = this.props;
		const { kycUrl, kycDocumentType } = this.state;
		dispatch({
			type: ADD_PLEBOS_KYC,
			data: {
				url: kycUrl, 
				phleboId: match.params.id,
				documentType: kycDocumentType, 
			},
			onSuccess:(() => {
				this.setState({ kycUrl: '', kycDocumentType: '' });
				this.getPlebosKYC();
			})
		});
	}

	deletePlebosKYC = (id) => {
		const { dispatch, match } = this.props;
		dispatch({
			type: DELETE_PLEBOS_KYC,
			data: {documentId: id, phleboId: match.params.id},
			onSuccess: (() => {
				this.getPlebosKYC();
			})
		});
	}

	getLGPs = () => {
		const { dispatch } = this.props;
		dispatch({
			type: GET_LPGS,
			data: {
				count: 200,
				offset: 0
			}
		});
	}

	uploadImage = (file, isAdd) => {
		const { dispatch } = this.props;
		dispatch({
			type: UPLOAD_IMAGE,
			file,
			onSuccess: ((res) => {
				if (res && res.imageUrl) {
					if (isAdd) {
						const { data } = this.state;
						data.imageUrl = res.imageUrl;
						this.setState({ data });
					} else {
						this.setState({ kycUrl: res.imageUrl });
					}
				}
			})
		})
	}

	handleFormChange = (e, key) => {
		const { data } = this.state;
		data[key] = e.target.value;
		this.setState({ data });
	}

	validateData = () => {
		const {data} = this.state;
		if (data.firstName==='' || data.lastName==='' || data.mobileNumber === '' || data.addressField1==='') {
			this.setState({ isError: true });
		} else {
			this.setState({ isError: false });
			this.addPlebos();
		}
	}

	renderPagination = (isKYC) => {
		return (
	    	<div className="mb-30"> 
		    	<Grid container spacing={5}>
		    		<Grid item xs={9} />
		    		<Grid item xs={3}>
					    <Button 
					    	style={{ marginRight: '20px' }} 
					    	size="medium" 
					    	color="primary" 
					    	variant="outlined" 
					    	disabled={this.state.offset===0}
					    	onClick={() => { 
					    		this.setState({ offset: this.state.offset - 10  }, () => {
									if (isKYC) {
										this.getPlebosKYC();
									} else {
										this.getPlebos();
									}
					    		}) 
					    	}}
					    > 
					    	{'< Previous'} 
					    </Button>
					    <Button 
					    	size="medium" 
					    	color="primary" 
					    	variant="outlined" 
					    	disabled={!this.props.hasMore}
					    	onClick={() => { 
					    		this.setState({ offset: this.state.offset + 10  }, () => {
					    			if (isKYC) {
										this.getPlebosKYC();
									} else {
										this.getPlebos();
									}
					    		}) 
					    	}}
					    > 
					    	{'Next >'} 
					    </Button>
		    		</Grid>
		    	</Grid>
	    	</div>
		)
	}

	renderDeletePopup = () => {
		const { isDeleteOpen, data, deleteObj } = this.state
		return (
			<Dialog disableBackdropClick open={isDeleteOpen} onClose={() => { this.setState({ isDeleteOpen: false }) }}>
		        <DialogTitle id="clinic-popup">Confirm Delete</DialogTitle>
		        <DialogContent > Are you sure you want to delete {deleteObj.firstName} {deleteObj.lastName} ? </DialogContent>
		        <DialogActions>
			        <Button style={{ backgroundColor:"#00C9AA" }} onClick={() => {this.deletePlebos(deleteObj.phleboId)}} variant="contained" color="primary">Yes</Button>
			        <Button style={{ borderColor:"#00C9AA", color:"#00C9AA" }} onClick={() => { this.setState({isDeleteOpen:false, deleteObj: null }) }} variant="outlined" color="primary">No</Button>
		        </DialogActions>
		    </Dialog>
		)
	}

	render() {
		const { cities, isLoading, lgps, plebos } = this.props;
		const { data } = this.state;
		
	    return (
	       <DocContainer>
	       		<div className="mr-20">
	       			{isLoading && <ActionLoader />}
					<Grid container spacing={3}>
						<Grid item xs={1}>
						  <h2 className="mt-0">Dashboard</h2>
						</Grid>
						<Grid item xs={2} />
						<Grid item xs={2}>
							<div className="dateFilters">
								<div className="dateLabel mb-10">From :</div>
								<DatePicker 
									selected={this.state.dateFilterFrom}
									onChange={(date) => { 
										this.setState({ dateFilterFrom: date })
									}}
								/>
							</div>
						</Grid>
						<Grid item xs={2}>
							<div className="dateFilters">
								<div className="dateLabel mb-10">To :</div>
								<DatePicker 
									selected={this.state.dateFilterTo}
									onChange={(date) => { 
										this.setState({ dateFilterTo: date })
									}}
								/>
							</div>
						</Grid>
						<Grid item xs={2}>
							<div className="dateLabel"> LGP </div>
							<Select fullWidth 
								value={this.state.lgpId}
								onChange={(e) => { this.handleFormChange(e, 'lgpId') }}
							>
								{(lgps || []).map((lgp, index) => (
									<option key={`lgp-${lgp.lgpId}`} value={lgp.lgpId}> {lgp.name} </option>
								))}
							</Select>
						</Grid>
						<Grid item>
							<div className="dateLabel"> Filter City: </div>
							<Select fullWidth 
								value={data.city} 
								onChange={(e) => { this.handleFormChange(e, 'city') }}
							>
								{(this.props.cities || []).map((city, index) => (
									<option key={`city-${index}`} value={city}> {city} </option>
								))}
							</Select>
						</Grid>
					</Grid>

					{this.renderPagination()}

					<TableContainer component={Paper} className="mb-30">
					<Table size="small" aria-label="simple table">
					<TableHead>
					  <TableRow>
					    <TableCell>Image</TableCell>
					    <TableCell>Name</TableCell>
						<TableCell>Username</TableCell>
					    <TableCell>City</TableCell>
					    <TableCell>Address</TableCell>
						<TableCell>Action</TableCell>
					  </TableRow>
					</TableHead>
					<TableBody>
						{((plebos || []).length <= 0) &&
							<TableRow>
					      		<TableCell component="th" scope="row"> No phlebos found. </TableCell>
					      	</TableRow>
						}
					  {(plebos || []).map(plebo => (
					    <TableRow key={plebo.phleboId}>
					      <TableCell>
					      	<a href={plebo.imageUrl} download>
					      		<Avatar alt='img' size="small" src={plebo.imageUrl} />
					      	</a>
					      </TableCell>
					      <TableCell>{plebo.firstName} {plebo.lastName}</TableCell>
					      <TableCell>{plebo.mobileNumber}</TableCell>
					      <TableCell>{plebo.username}</TableCell>
					      <TableCell>{plebo.city}</TableCell>
					      <TableCell>{plebo.address1} {plebo.address2}</TableCell>
					      <TableCell>
					      	<Switch size="small"
								color="primary"
								name="isActive"
								checked={plebo.isActive}
								onChange={(e) => {
									const data = {...plebo};
									data.isActive = e.target.checked;
									data.isEdit = true;
									this.addPlebos(data);
								}}
							/>
					      </TableCell>
					      <TableCell>
					      	<div style={{ width: '75px' }}>
					      	<IconButton edge="end" color="inherit" size="small" onClick={() => { this.redirectToDetail(plebo) }}>
								<Visibility />
							</IconButton>
							<IconButton edge="end" color="inherit" size="small" onClick={() => { this.setState({ isPleboOpen: true, data: {...plebo, isEdit: true} }) }}>
								<Edit />
							</IconButton>
							<IconButton edge="end" color="inherit" size="small" onClick={() => { this.setState({ isDeleteOpen: true, deleteObj: plebo }) }}>
								<Clear />
							</IconButton>
							</div>
					      </TableCell>
					    </TableRow>
					  ))}
					</TableBody>
					</Table>
					</TableContainer>

					{this.renderPagination()}
	       		</div>
	       </DocContainer>
	    );
	}
}

const mapStateToProps = ({plebos, constants, lgp}) => {
  return {
  	isLoading: plebos.isSubmitting,
    plebos: plebos.plebos,
    hasMore: plebos.hasMore,
    plebosKYC: plebos.plebosKYC,
    attendance: plebos.attendance,
    lgps: lgp.plebos,

    cities: constants.cities,
	states: constants.states,
  }
};

export default withRouter(connect(mapStateToProps)(Dashboard));
