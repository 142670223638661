import {
  	IS_SUBMITTING,
    ALL_ROSTERS
} from 'Constants/actionConstants';


const rosters = (state={
  
  isSubmitting: false,
  rosters: [],
  hasMore: false,
}, action)=> {

  switch (action.type) {
    
    case IS_SUBMITTING:
      	return {
	        ...state,
	        isSubmitting: action.isSubmitting,
	    };

    case ALL_ROSTERS:
      return {
        ...state,
        rosters: action.data,
        hasMore: action.hasMore
      };

    default:
      return state;
  }

}

export default rosters;
