import {
  LABS_LIST,
  LAB_TEST_LIST,
  LAB_USERS_LIST,
  IS_SUBMITTING,
} from "Constants/actionConstants";

const labs = (
  state = {
    isSubmitting: false,
    labs: [],
    labTests: [],
  },
  action
) => {
  switch (action.type) {
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };

    case LABS_LIST:
      return {
        ...state,
        labs: action.data,
        hasMore: action.hasMore,
      };
    case LAB_TEST_LIST:
      return {
        ...state,
        labTests: action.data,
        hasMore: action.hasMore,
      };
    case LAB_USERS_LIST:
      return {
        ...state,
        labUsers: action.data,
        hasMore: action.hasMore,
      };

    default:
      return state;
  }
};

export default labs;
