import { 
  REPORT_LIST,
  REORT_SLOTS_LIST,
  TICKET_REPORT_LIST,

  IS_SUBMITTING,
} from 'Constants/actionConstants';


const reports = (state={
  
  isSubmitting: false,
  reports: [],
  reportSlots: [],
  count: '',
  ticketCount: '',

}, action)=> {

  switch (action.type) {
    
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };

    case REPORT_LIST:
      return {
        ...state,
        reports: action.data,
        hasMore: action.hasMore,
        count: action.count || '',
      };

    case TICKET_REPORT_LIST:
      return {
        ...state,
        ticketReports: action.data,
        hasMore: action.hasMore,
        ticketCount: action.count || '',
      };

    case REORT_SLOTS_LIST:
      return {
        ...state,
        reportSlots: action.data,
      };
      
    default:
      return state;
  }

}

export default reports;
