/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import CheckIcon from "@material-ui/icons/Check";
import StepConnector from "@material-ui/core/StepConnector";
import { getUser } from "Utils/storage";
import moment from "moment";
import "./clinics.css";

import { GET_LIFECYCLE } from "./../../constants/actionConstants";
import { Tooltip } from "@material-ui/core";
import { Typography } from "@material-ui/core";


const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor:"#0d8da3",
       },
  },
  completed: {
    "& $line": {
      backgroundColor:"#0d8da3",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor:"#65bd2f",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
     backgroundColor:"#65bd2f",
     },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <CheckIcon fontSize='large'/>,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[3]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const BgColorTooltip = withStyles({
  tooltip: {
    color: "#000000",
    backgroundColor: "#c9c9c9"
  }
})(Tooltip);

const Lifecycle = (props) => {
  const classes = useStyles();
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    if (props.orderId) {
      getLifecycle();
    }
  }, []);

  const getLifecycle = (id) => {
    const data = {};
    const { dispatch } = props;

    data["order_seq"] = props.orderSequence;
    data["orderId"] = props.orderId;

    dispatch({
      type: GET_LIFECYCLE,
      isPartner: getUser().userType === 2,
      data,
      onSuccess: (data) => {
        let multipleArr = Object.values(data);
        let combinedData = multipleArr.reduce((r, e) => (r.push(...e), r), [])
        setSteps(combinedData);
      },
    });
  };


  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={steps.length}
        connector={<ColorlibConnector />}
        style={{ backgroundColor: "transparent", flexWrap: "wrap" }}
      >{steps.map((label) => (
        
        <Step  key={label.ts} style={{ marginBottom: 50 }}>
          <BgColorTooltip arrow title={<>
          {label.OrderCreatedTime && <Typography>Order Created Time: {moment(label.OrderCreatedTime).format("D MMM, YY h:mm A")}</Typography>}
          {label.lgpName && <Typography>LGP Name: {label.lgpName}</Typography>}
          {label.firstName && <Typography>Phlebo Name: {label.firstName} {label.lastName}</Typography>}
          {label.assignedBy && <Typography>Assigned By: {label.assignedBy}</Typography>}
          {label.assignedTime && <Typography>Assigned Time: {moment(label.assignedTime).format("D MMM, YY h:mm A")}</Typography>}
          {label.orderRejectedBy && <Typography>Order Rejected By: {label.orderRejectedBy}</Typography>}
          {label.orderRejectedTime && <Typography>Order Rejected Time: {moment(label.orderRejectedTime).format("D MMM, YY h:mm A")}</Typography>}
          {label.rejectComments &&  <Typography>Comments: {label.rejectComments}</Typography>}
          {label.earliest_pickup_date && <Typography>Slot Time: {moment(label.earliest_pickup_date).format("D MMM, YY h:mm A")} - {moment(label.latest_pickup_date).format("D MMM, YY h:mm A")}</Typography>}
          {label.reachedCustomerLocationTime && <Typography>Reached Location Time: {moment(label.reachedCustomerLocationTime).format("D MMM, YY h:mm A")}</Typography>}
          {label.tripStartTime && <Typography>Trip Started At: {moment(label.tripStartTime).format("D MMM, YY h:mm A")}</Typography>}
          {label.sampleCollectedTime && <Typography>Sample Collected Time: {moment(label.sampleCollectedTime).format("D MMM, YY h:mm A")}</Typography>}
          {label.updatedBy && <Typography>Updated By: {label.updatedBy}</Typography>}
          {label.date && <Typography>Updated At: {label.date}/{label.month}/{label.year}</Typography>}
          {label.labAcceptedTime && <Typography>Lab Accepted Time: {moment(label.labAcceptedTime).format("D MMM, YY h:mm A")}</Typography>}
          {label.orderAcceptedBy && <Typography>Order Accepted By: {label.orderAcceptedBy}</Typography>}
          {label.orderAcceptedTime && <Typography>Order Accepted Time: {moment(label.orderAcceptedTime).format("D MMM, YY h:mm A")}</Typography>}
          {label.phleboCanceledTime && <Typography>Phlebo Cancelled Time: {moment(label.phleboCanceledTime).format("D MMM, YY h:mm A")}</Typography>}
          {label.phleboComments && <Typography>Phlebo Comments: {label.phleboComments}</Typography>}
          {label.cancelActionBy && <Typography>Cancelled By: {label.cancelActionBy}</Typography>}
          {label.exeComments && <Typography>Executive Comments: {label.exeComments}</Typography>}
          {label.payment_mode && <Typography>Payment Mode: {label.payment_mode}</Typography>}
          {label.prepaid_amount && <Typography>Prepaid Amount: {label.prepaid_amount}</Typography>}
          {label.amount_to_collect && <Typography>Amount to Collect: {label.amount_to_collect}</Typography>}
          {label.ship_to_address1 && <Typography>Address 1: {label.ship_to_address1}</Typography>}
          {label.ship_to_address2 && <Typography>Address 2: {label.ship_to_address2}</Typography>}
          {label.ship_to_city && <Typography>City: {label.ship_to_city}</Typography>}
          {label.ship_to_state && <Typography>State: {label.ship_to_state}</Typography>}
          {label.ship_to_zip_code && <Typography>Zipcode: {label.ship_to_zip_code}</Typography>}
          {label.landmark && <Typography>Landmark: {label.landmark}</Typography>}
          {label.earliest_pickup_date && <Typography>Earliest Pickup Date: {label.earliest_pickup_date}</Typography>}
          {label.month && <Typography>Month: {label.month}</Typography>}
          {label.date && <Typography>Date: {label.date}</Typography>}
          {label.year && <Typography>Year: {label.year}</Typography>}
          {label.slot_type && <Typography>Slot Type: {label.slot_type}</Typography>}
          {label.latest_pickup_date && <Typography>Latest Pickup Date: {label.latest_pickup_date}</Typography>}
          {label.test_details && <Typography>Test Details: {label.test_details}</Typography>}
          {label.customer_age && <Typography>Customer Age: {label.customer_age}</Typography>}
          {label.customer_gender && <Typography>Customer Gender: {label.customer_gender}</Typography>}
          {label.customer_name && <Typography>Customer Name: {label.customer_name}</Typography>}
          {label.ship_to_name && <Typography>Client Name: {label.ship_to_name}</Typography>}
          {label.customer_contact && <Typography>Contact Number: {label.customer_contact}</Typography>}
          {label.customer_email && <Typography>Customer Email: {label.customer_email}</Typography>}
          {label.preferred_lab_name && <Typography>Lab Name: {label.preferred_lab_name}</Typography>}
          {label.isCardio && <Typography>Is Cardio: {label.isCardio === true ? "True" : "False"}</Typography>}
          {label.ship_to_latitude && <Typography>Latitude: {label.ship_to_latitude}</Typography>}
          {label.ship_to_longitude && <Typography>Longitude: {label.ship_to_longitude}</Typography>}
          {label.orderPriority && <Typography>Order Priority: {label.orderPriority}</Typography>}
          {label.event && <Typography>Event: {label.event}</Typography>}
          {label.name && <Typography>Report Name:{label.name}</Typography>}
          {label.uploadedBy && <Typography>Uploaded By:{label.uploadedBy}</Typography>}
          {label.created && <Typography>Report Uploaded Time: {moment(label.created).format("D MMM, YY h:mm A")}</Typography>}

          </>}>
        <StepLabel StepIconComponent={ColorlibStepIcon}>
              <Typography>{label.event}</Typography>
            </StepLabel>
            </BgColorTooltip>
          </Step>
         
        ))}
          
      </Stepper>
    </div>
  );
};

const mapStateToProps = ({ orders }) => {
  return {
    isLoading: orders.isSubmitting,
  };
};

export default withRouter(connect(mapStateToProps)(Lifecycle));
