/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUser } from "Utils/storage";
import DocContainer from "./../../containers/DocContainer";
import ActionLoader from "../../components/ActionLoader";
import {
  Grid,
  Button,
  FormLabel,
  IconButton,
  TextField,
  Paper,
  Box,
  Chip,
  Tabs,
  Tab,
  Typography,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
} from "@material-ui/core";
import Select, { components } from "react-select";
import { ArrowDropDownOutlined, Refresh, CheckCircle } from "@material-ui/icons";
import { DatePicker } from "rsuite";

import {
  GET_ALL_CONSTANTS,
  GET_ALL_SLOT_BOOKINGS,
  CREATE_SLOTS,
  SLOTS_STATUS,
  GET_ALL_SLOTS_STATUS,
  GET_ALL_ZIPCODES,
} from "../../constants/actionConstants";
import moment from "moment";

import "./index.css";




const multiSelectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    fontSize: 12,
  }),
  option: (base) => ({
    ...base,
    fontSize: 12,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#9F9F9F",
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    color: "#fff",
    backgroundColor: "#6FC5C4",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#6FC5C4",
      color: "#fff",
    },
  }),
};

const dateTimeSlot = [
  "5-6",
  "6-7",
  "7-8",
  "8-9",
  "9-10",
  "10-11",
  "11-12",
  "12-13",
  "13-14",
  "14-18",
];


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

const SlotBooking = (props) => {
  const { cities } = props;
  const cityDetail = [
    {
      value: "BLR",
      label: "BLR-Bangalore"
    },
    {
      value: "MAA",
      label: "MAA-Chennai"
    },
    {
      value: "DEL",
      label: "DEL-Delhi"
    },
    {
      value: "BOM",
      label: "BOM-Mumbai"
    },
    {
      value: "NAG",
      label: "NAG-Nagpur"
    },
    {
      value: "VTZ",
      label: "VTZ-Vizag"
    },
    {
      value: "TRZ",
      label: "TRZ-Trichy"
    },
    {
      value: "IXM",
      label: "IXM-Madurai"
    },
    {
      value: "CCU",
      label: "CCU-Kolkata"
    },
    {
      value: "STV",
      label: "STV-Surat"
    },
    {
      value: "JAI",
      label: "JAI-Jaipur"
    },
    {
      value: "PNQ",
      label: "PNQ-Pune"
    },
    {
      value: "COK",
      label: "COK-Kochi"
    },
    {
      value: "SXV",
      label: "SXV-Salem"
    },
    {
      value: "LKO",
      label: "LKO-Lucknow"
    },
    {
      value: "KAN",
      label: "KAN-Kanchi"
    },
    {
      value: "AMD",
      label: "AMD-Ahmedabad"
    },
    {
      value: "HYD",
      label: "HYD-Hyderabad"
    },
    {
      value: "PAT",
      label: "PAT-Patna"
    },
    {
      value: "CJB",
      label: "CJB-Coimbatore"
    },
    {
      value: "TIR",
      label: "TIR-Tirupati"
    },
    {
      value: "IDR",
      label: "IDR-Indore"
    },
    {
      value: "RPR",
      label: "RPR-Raipur"
    },
    {
      value: "DED",
      label: "DED-Dehradun"
    },
    {
      value: "IXJ",
      label: "IXJ-Jammu"
    },
    {
      value: "KNU",
      label: "KNU-Kanpur"
    },
    {
      value: "BBI",
      label: "BBI-Bhubaneshwar"
    },
    {
      value: "IXC",
      label: "IXC-Chandigarh"
    },
    {
      value: "GAU",
      label: "GAU-Guwahati"
    },
    {
      value: "VNS",
      label: "VNS-Varanasi"
    },
    {
      value: "JDH",
      label: "JDH-Jodhpur"
    },
    {
      value: "REW",
      label: "REW-Reewa"
    },
    {
      value: "EDE",
      label: "EDE-Erode"
    },
    {
      value: "KGI",
      label: "KGI-Krishnagiri"
    },
    {
      value: "TRV",
      label: "TRV-Tiruvandram"
    },
    {
      value: "MYQ",
      label: "MYQ-Mysore"
    },
    {
      value: "BHO",
      label: "BHO-Bhopal"
    },
    {
      value: "GOI",
      label: "GOI-GOA"
    },
    {
      value: "PNY",
      label: "PNY-Pondicherry"
    },
    {
      value: "IXR",
      label: "IXR-Ranchi"
    },
    {
      value: "BDQ",
      label: "BDQ-Vadodara"
    },
    {
      value: "QNF",
      label: "QNF-Faridabad"
    },
    {
      value: "GGN",
      label: "GGN-Gurgaon"
    },
  ]
  const [isLoading, setIsLoading] = useState(false);
  const [allCities, setAllCities] = useState([]);
  const [citiesSelected, setCitiesSelected] = useState(null);
  const [allCityId, setAllCityId] = useState([]);
  const [cityidSelected, setCityidSelected] = useState(null);
  const [allZipcodes, setAllZipcodes] = useState([]);
  const [zipcodeSelected, setZipcodeSelected] = useState(null);

  const [slotBookings, setSlotBookings] = useState([]);

  const [date, setDate] = useState(new Date());
  const [pincode, setPincode] = useState(null);
  const [status, setStatus] = useState("");
  const [allStatus, setAllStatus] = useState([]);

  const [tab, setTab] = useState(1);

  const [slotSummary, setSlotSummary] = useState({
    hold: 0,
    booked: 0,
    available: 0,
  });

  const citysId = ["AMD", "BLR", "LKO", "MAA", "DEL", "HYD", "CCU", "BOM", "PNQ", "STV", "JAI"]

  useEffect(() => {
    getAllConstants();
    getAllSlotStatus();
  }, []);

  useEffect(() => {
    setAllCities(
      cities.map((city) => {
        return { value: city, label: city };
      })
    );
    setAllCityId(
      citysId.map((id) => {
        return { value: id, label: id };
      })
    );
  }, [cities]);

  useEffect(() => {
    if (citiesSelected) {
      getAllZipcodes(citiesSelected)
    } else {
      setSlotBookings([]);
    }
  }, [date, citiesSelected, pincode]);

  const handleSubmit = () => {
    if (!date || !zipcodeSelected || !citiesSelected) {
      return;
    }
    getAllSlotBookings();
  };

  const handleCreateSlots = () => {
    createSlots();
  }

  const handleSearchSlotHistory = () => {
    getAllSlotStatus();
  }

  const handleClear = () => {
    setCitiesSelected(null);
    setCityidSelected(null);
    setZipcodeSelected(null);
    setIsLoading(false);
  };

  const getAllSlotBookings = () => {
    setIsLoading(true);
    const { dispatch } = props;
    let data = {
      count: 100,
      offset: 0,
      city: citiesSelected,
      pincode:zipcodeSelected
    };

    if (date) {
      data["month"] = moment(date).month() + 1;
      data["date"] = moment(date).date();
      data["year"] = moment(date).year();
    }

    dispatch({
      type: GET_ALL_SLOT_BOOKINGS,
      data,
      onSuccess: (res) => {
        setIsLoading(false);
        const formatted = formatSlotBookings(res.slots || []);
        setSlotBookings(formatted.slots);
        setSlotSummary(formatted.summary);
      },
      onFailure: () => {
        setIsLoading(false);
      },
    });
  };


  const createSlots = () => {
    setIsLoading(true);
    const { dispatch } = props;

    let data = {
      city: citiesSelected,
      cityId: cityidSelected,
    };



    if (date) {
      data["month"] = moment(date).month() + 1;
      data["date"] = moment(date).date();
      data["year"] = moment(date).year();
    }

    dispatch({
      type: CREATE_SLOTS,
      data,
      onSuccess: (res) => {
        setIsLoading(false);
      },
      onFailure: () => {
        setIsLoading(false);
      },

    });
    setTimeout(() => {
      slotStatus();
      getAllSlotStatus();
    }, 3000);

  };


  const slotStatus = () => {
    setIsLoading(true);
    const { dispatch } = props;

    let data = {
      city: citiesSelected,
      pincode: zipcodeSelected,
    };



    if (date) {
      data["month"] = moment(date).month() + 1;
      // data["date"] = moment(date).date();
      data["year"] = moment(date).year();
    }

    dispatch({
      type: SLOTS_STATUS,
      data,
      onSuccess: (res) => {
        setIsLoading(false);
        setStatus(res);
      },
      onFailure: () => {
        setIsLoading(false);
      },

    });
  };

  const getAllSlotStatus = () => {
    const { dispatch } = props;

    let data = {
      count: 100,
      offset: 0,
    };



    if (date) {
      data["month"] = moment(date).month() + 1;
      data["year"] = moment(date).year();
    }

    dispatch({
      type: GET_ALL_SLOTS_STATUS,
      data,
      onSuccess: (res) => {
        setIsLoading(false);
        setAllStatus(res.list);
      },
      onFailure: () => {
        setIsLoading(false);
      },

    });
  };


  const formatSlotBookings = (data) => {
    const slotData = JSON.parse(JSON.stringify(data));
    let formatedSlotData = {};
    let summary = {
      hold: 0,
      booked: 0,
      available: 0,
    };
    (slotData || []).map((el) => {
      if (el) {
        const hr = new Date(el.slot_from).getHours();
        let dt = "";
        if (hr >= 14) {
          dt = "14-18";
        } else {
          dt = `${hr}-${hr + 1}`;
        }

        if (!formatedSlotData[dt]) {
          formatedSlotData[dt] = {
            hold: 0,
            booked: 0,
            available: 0,
          };
        }

        if (el.is_hold) {
          summary["hold"] = summary["hold"] + 1;
          formatedSlotData[dt]["hold"] = formatedSlotData[dt]["hold"] + 1;
        } else if (el.is_booked) {
          summary["booked"] = summary["booked"] + 1;
          formatedSlotData[dt]["booked"] = formatedSlotData[dt]["booked"] + 1;
        } else {
          summary["available"] = summary["available"] + 1;
          formatedSlotData[dt]["available"] =
            formatedSlotData[dt]["available"] + 1;
        }
      }
    });
    return { slots: formatedSlotData, summary };
  };

  const getAllConstants = () => {
    const { dispatch } = props;
    dispatch({
      type: GET_ALL_CONSTANTS,
    });
  };

  const getAllZipcodes = (citiesSelected) => {
    const { dispatch } = props;
    const data = {
      city:citiesSelected,
      count: 100,
      offset: 0,
    };
    dispatch({
      type: GET_ALL_ZIPCODES,
      data,
      onSuccess: (res) => {
        const zipcodes = res.routes;
        setAllZipcodes(
          zipcodes.map((pincode) => {
            return { value: pincode.zipcode, label: pincode.zipcode };
          })
        );
      },
    });
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownOutlined />
      </components.DropdownIndicator>
    );
  };

  



  const user = getUser();

  const handleStatus = () => {
    slotStatus();
  }


  const AvailableSlot = () => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <div>
              <FormLabel component="legend" className="text-label">
                Select Date
              </FormLabel>
              <DatePicker style={{ width: '100%' }} value={date} onOk={(date) => setDate(date)} />
            </div>
          </Grid>
          <Grid item xs={4}>
            <FormLabel component="legend" className="text-label">
              Select City
            </FormLabel>
            <Select
              options={allCities}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator,
              }}
              isClearable={true}
              value={
                citiesSelected &&
                allCities.find((el) => el.value === citiesSelected)
              }
              onChange={(val) => {
                setCitiesSelected(val ? val.value : null);
              }}
              placeholder={"City"}
              styles={multiSelectStyles}
              style={{ width: "80%" }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormLabel component="legend" className="text-label">
              Select Pincode
            </FormLabel>
            <Select
              options={allZipcodes}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator,
              }}
              isClearable={true}
              value={
                zipcodeSelected &&
                allZipcodes.find((el) => el.value === zipcodeSelected)
              }
              onChange={(val) => {
                setZipcodeSelected(val ? val.value : null);
              }}
              placeholder={"Pincode"}
              styles={multiSelectStyles}
              style={{ width: "80%" }}
            />
          </Grid>
          {/* <Grid item xs={4}>
         
            <TextField
              style={{ marginTop: 15 }}
              fullWidth
              value={pincode || ""}
              label="Pincode"
              onChange={(e) => {
                if (isNaN(e.target.value)) {
                  return;
                }
                setPincode(e.target.value);
              }}
            />
          </Grid> */}
        </Grid>
        <Grid item xs={12} style={{ margin: 20, textAlign: 'center' }}>
          <div className="search-clinic pr">
            <Button
              style={{ marginRight: "20px" }}
              size="medium"
              color="primary"
              variant="outlined"
              onClick={() => handleSubmit()}
            >
              {"Search"}
            </Button>
            <Button
              style={{ marginRight: "20px" }}
              size="medium"
              color="primary"
              variant="outlined"
              onClick={() => handleClear()}
            >
              {"Clear All"}
            </Button>
          </div>
        </Grid>
        <div style={{ borderTop: "1px solid #808080", marginTop: 10 }} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div style={{ margin: 10, display: "flex" }}>
              <div
                style={{
                  background: "#fff",
                  padding: 10,
                  paddingTop: 10,
                  marginRight: 10,
                }}
              >
                <div className="slot available">
                  Available - {slotSummary.available || 0}
                </div>
              </div>
              <div
                style={{
                  background: "#fff",
                  padding: 10,
                  paddingTop: 10,
                  marginRight: 10,
                }}
              >
                <div className="slot booked">
                  Booked - {slotSummary.booked || 0}
                </div>
              </div>
              <div
                style={{
                  background: "#fff",
                  padding: 10,
                  paddingTop: 10,
                }}
              >
                <div className="slot hold">
                  Hold - {slotSummary.hold || 0}
                </div>
              </div>
            </div>
          </Grid>
          {/* <Paper> */}
          {/* <Grid item xs={12}> */}
          <div className="tableView disable-scrollbars">
            <table className="styled-table">
              <thead className="tableHead">
                <tr className="border_bottom">
                  {dateTimeSlot.map((el, index) => (
                    <th sty key={index}>
                      {el}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="tableBody">
                {Object.keys(slotBookings).length > 0 && (
                  <tr className="row" style={{ verticalAlign: "top" }}>
                    {(dateTimeSlot || []).map((el, i) => (
                      <td
                        className="cell"
                        data-title="S.no"
                        style={{ width: 80, padding: 10 }}
                      >
                        <div
                          style={{
                            background: "#fff",
                            padding: 10,
                            paddingTop: 10,
                          }}
                        >
                          <div className="slot available">
                            Available -{" "}
                            {(slotBookings[el] &&
                              slotBookings[el]["available"]) ||
                              0}
                          </div>
                        </div>
                        <div
                          style={{
                            background: "#fff",
                            padding: 10,
                            paddingTop: 0,
                          }}
                        >
                          <div className="slot booked">
                            Booked -{" "}
                            {(slotBookings[el] &&
                              slotBookings[el]["booked"]) ||
                              0}
                          </div>
                        </div>
                        <div
                          style={{
                            background: "#fff",
                            padding: 10,
                            paddingTop: 0,
                          }}
                        >
                          <div className="slot hold">
                            Hold -{" "}
                            {(slotBookings[el] &&
                              slotBookings[el]["hold"]) ||
                              0}
                          </div>
                        </div>
                      </td>
                    ))}
                  </tr>
                )}
                {Object.keys(slotBookings).length === 0 && (
                  <tr className="row">
                    <td className="cell" data-title="S.no">
                      Bookings
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* </Grid> */}
          {/* </Paper> */}
        </Grid>
      </>
    )
  }

  const CreateSlot = () => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <div>
              <FormLabel component="legend" className="text-label">
                Select Date
              </FormLabel>
              <DatePicker style={{ width: '100%' }} value={date} onOk={(date) => setDate(date)} />
            </div>
          </Grid>
          <Grid item xs={3}>
            <FormLabel component="legend" className="text-label">
              Select City
            </FormLabel>
            <Select
              options={allCities}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator,
              }}
              isClearable={true}
              value={
                citiesSelected &&
                allCities.find((el) => el.value === citiesSelected)
              }
              onChange={(val) => {
                setCitiesSelected(val ? val.value : null);
              }}
              placeholder={"City"}
              styles={multiSelectStyles}
              style={{ width: "80%" }}
            />
          </Grid>

          <Grid item xs={6} style={{marginTop:'23px'}}>
          <div className="search-clinic pr">
          <Button
              style={{  marginRight: "20px" }}
              variant='outlined'
              size="medium"
              color="primary"
              onClick={() => handleSearchSlotHistory()}>
              {"Search"}
            </Button>
            {citiesSelected && <Button
              style={{marginRight: "20px" }}
              color='primary'
              variant='contained'
              onClick={() => handleCreateSlots()}>
              {"Create Slots"}
            </Button>}
            {status && <Chip icon={<Refresh style={{color:'#ffffff'}}/>} label={status.status} onClick={handleStatus} style={{backgroundColor:status.status === 'Pending'? '#ff9800': '#2e7d32', color:"#ffffff"}} />}
          </div>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "25px", textAlign: 'center' }}>
       {allStatus && <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              
              <TableRow>
                <TableCell align="center">City</TableCell>
                <TableCell align="center">Date of Creation</TableCell>
                <TableCell align="center">Period (From & To)</TableCell>
                <TableCell align="center">Created By</TableCell>
                <TableCell align="center">Start Time</TableCell>
                <TableCell align="center">End Time</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">{" "}</TableCell>
              </TableRow>
            
            </TableHead>
            <TableBody>
              {allStatus === null ? <>Slots not Found</> : <>{allStatus.map((item) => (
              <TableRow
                // key={key}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="center">
                  {item.city}
                </TableCell>
                <TableCell align="center">
                  {moment(item.createdTime).format("D MMM, YY h:mm A")}{" "}
                </TableCell>
                <TableCell align="center">{item.fromDate ? <>{item.fromDate}/{item.month}/{item.year} - {item.toDate}/{item.month}/{item.year}</> : "-"}</TableCell>
                <TableCell align="center">{item.userName}</TableCell>
                <TableCell align="center"> 
                {moment(item.createdTime).format("D MMM, YY h:mm A")}{" "}
                </TableCell>
                <TableCell align="center"> 
                {moment(item.lut).format("D MMM, YY h:mm A")}{" "}
                </TableCell>
                <TableCell align="center">{item.status}</TableCell>
                {item.status === 'Pending' ? <TableCell align="center">
                 <IconButton onClick={() =>(getAllSlotStatus())}><Refresh color="primary"/></IconButton> 
                </TableCell> : <TableCell align="center"><CheckCircle style={{color:"green"}}/></TableCell>}
              </TableRow>
              ))}</>}
            </TableBody>
          </Table>
        </TableContainer> }
        </Grid>
      </>)
  }
  return (
    <DocContainer transparent={true}>
      <div className="mr-20 pd-t-20">
        {isLoading && <ActionLoader />}
        <React.Fragment>
          <Tabs
            variant="scrollable"
            indicatorColor="primary"
            value={tab}
            onChange={(e, tab) => {
              setTab(tab);
            }}
          >
            <Tab label="Available Slots" value={1} />
            {user.username === "nithin" ? <Tab label="Create Slots" value={2} /> : null}
          </Tabs>
          <TabPanel value={tab} index={1}>
            {AvailableSlot()}
          </TabPanel>
          {user.username === "nithin" ? <TabPanel value={tab} index={2}>
            {CreateSlot()}
          </TabPanel> : null}
        </React.Fragment>
      </div>
    </DocContainer>
  );
};

const mapStateToProps = ({ constants, zones }) => {
  return {
    cities: constants.cities,
    zones: zones.zones,
  };
};

export default withRouter(connect(mapStateToProps)(SlotBooking));
