import { DOCTOR_ORDER_CONSTANTS, PAYMENT_MODE, ORDER_ASSIGNED_STATUS, ORDER_PRIORITY, PAST_TABS, ORDER_TYPE, ORDER_EXECUTION_TYPE, ORDER_CONSTANTS } from "Constants/commonConstants";
import DocContainer from "Containers/DocContainer/index";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    Paper,
    Button,
    Grid,
    TextField,
    IconButton,
    InputAdornment,
    Typography,
    Avatar,
    Tabs,
    Tab,
    Box,
    OutlinedInput,
    FormControl,
    FormHelperText,
    Card,
    Tooltip,
    Chip,
} from "@material-ui/core";

import BloodPressure from "./VitalsIcons/blood-pressure.png";
import Pulse from "./VitalsIcons/pulse.png";
import Height from "./VitalsIcons/height.png";
import Weight from "./VitalsIcons/weight-scale.png";
import Abdomen from "./VitalsIcons/stomach.png";
import Heart from "./VitalsIcons/heart.png";
import Waist from "./VitalsIcons/waist.png";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { getUser } from "Utils/storage";
import Lifecycle from "./LifeCycle";
import Agora from "Components/Agora/index";
import { Alert } from "@material-ui/lab";
import {
    Clear,
    Visibility,
    ArrowBackOutlined,
    Refresh,
    Phone,
    Star,
    Close
} from "@material-ui/icons";
import { GET_DOCTOR_ORDERS, GET_VIDEO_MER,  DOWNLOAD_VIDEO_MER, UPLOAD_IMAGE, UPLOAD_PPMC_MER, GET_VIDEO_TOKEN, GET_ALL_CONSTANTS, SET_TYPE_VALUE, GET_ORDER_DATA, GET_LGP_LAB_LIST, GET_VITALS_DATA, GET_DOCTOR_ORDERS_COUNT } from "Constants/actionConstants";
import moment from "moment";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

class DoctorOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            city: 'Bangalore',
            dateFilter: localStorage.getItem("orderDateFilter")
                ? JSON.parse(localStorage.getItem("orderDateFilter"))
                : new Date().getTime(),
            type: DOCTOR_ORDER_CONSTANTS[0].id,
            offset: 0,
            orders: [],
            allReports: [],
            isPast: false,
            selectedOrders: [],
            isMobile: false,
            data: {},
            vitals: {},
            openVitals: false,
            openAlert: false,
            videoToken: {},
            fileName: "",
            tab: 1,
            cityFilterDisable: false,
        }
    }

    componentDidMount = () => {
        const { match } = this.props;
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.getAllConstants();
        if (match.params && match.params.id) {
            this.getOrderById({
                orderId: match.params.id,
                orderStatus: match.params.status,
            });
        } else {
            if (!this.props.typeValue) {
                this.getDoctorOrders();
            } else {
                this.setState({ type: this.props.typeValue });
            }
        }
        const { fileName } = this.state;
        if (fileName == "") {
            return null;
        } else {
            this.getVideoMerByFileName();
        }

    }

    resize() {
        this.setState({ isMobile: window.innerWidth <= 760 });
    }


    getAllConstants = () => {
        const { dispatch } = this.props;
        dispatch({
            type: GET_ALL_CONSTANTS,
        });
    };


    getAllReports = () => {
        const { dispatch } = this.props;
        let data = {}
        if (this.state.dateFilter) {
            data["month"] = moment(this.state.dateFilter).month() + 1;
            data["date"] = moment(this.state.dateFilter).date();
            data["year"] = moment(this.state.dateFilter).year();
        }
        dispatch({
            type: GET_DOCTOR_ORDERS_COUNT,
            data: data,
            onSuccess: (res) => {
                this.setState({ allReports: res.list });
            },
        });
        this.getReportsOrders();
    };

    getReportsOrders = () => {
        let orders = DOCTOR_ORDER_CONSTANTS;
        for (let i = 0; i <= DOCTOR_ORDER_CONSTANTS.length; i++) {
            for (let j = 0; j <= orders.length; j++) {
                if (
                    orders[i] &&
                    orders[i].name === "ASSIGNED" &&
                    this.state.allReports[j] &&
                    this.state.allReports[j].orderStatus === "ASSIGNED"
                ) {
                    orders[i]["count"] = this.state.allReports[j].count;
                }
                if (
                    orders[i] &&
                    orders[i].name === "COMPLETED" &&
                    this.state.allReports[j] &&
                    this.state.allReports[j].orderStatus === "COMPLETED"
                ) {
                    orders[i]["count"] = this.state.allReports[j].count;
                }

            }
        }


        return orders;
    };

    callBackFunction = (fileData) => {
        if (fileData) {
            const { dispatch } = this.props;
            const { data } = this.state;

            dispatch({
                type: GET_VIDEO_MER,
                data: {
                    fileName: fileData,
                    orderId: data.order_seq,
                },
                onSuccess: (data) => {
                    this.setState(
                        {
                            videoMer: data,
                        });
                }
            });
        } else {
            return null;
        }

        this.setState({ fileName: fileData, });
    }

    callBackJoinStatus = (joinStatus) => {
        this.setState({ join: joinStatus });

    }

    uploadImage = (file, name, type) => {
        const { dispatch, match, orderData } = this.props;
        const { data } = this.state;
        dispatch({
            type: UPLOAD_IMAGE,
            file,
            onSuccess: (res) => {
                if (res && res.imageUrl) {
                    dispatch({
                        type: UPLOAD_PPMC_MER,
                        data: { type, orderId: match.params.id, url: [res.imageUrl] },
                    });
                }
            },
        });
    };
    getVideoMerByFileName = () => {
        const { dispatch } = this.props;
        const { fileName } = this.state;
        const { data } = this.state;
        dispatch({
            type: GET_VIDEO_MER,
            data: {
                fileName: fileName,
                orderId: data.order_seq,
            },
            onSuccess: (data) => {
                this.setState(
                    {
                        videoMer: data,
                    });
            }
        });
    };


    getVideoTokenByOrderId = () => {
        const { dispatch } = this.props;
        const { data } = this.state;

        dispatch({
            type: GET_VIDEO_TOKEN,
            data: {
                channelName: data.order_seq,
            },
            onSuccess: (data) => {
                this.setState(
                    {
                        videoToken: data,
                    });
            }
        });
    };

    getVitalsByOrderId = () => {
        const { dispatch } = this.props;
        const { match } = this.props;
        const { vitals } = this.state;

        dispatch({
            type: GET_VITALS_DATA,
            data: {
                orderId: match.params.id,
            },
            onSuccess: (data) => {
                this.setState(
                    {
                        vitals: data,
                    });
            }
        });
    };


    getDoctorOrders = () => {
        const { dispatch } = this.props;
        const { offset } = this.state;
        let data = {
            count: 3,
            offset,
        };

        if (this.state.dateFilter) {
            data["month"] = moment(this.state.dateFilter).month() + 1;
            data["day"] = moment(this.state.dateFilter).date();
            data["year"] = moment(this.state.dateFilter).year();
        }
        if (this.state.type) {
            data["type"] = this.state.type;
        }

        dispatch({
            type: GET_DOCTOR_ORDERS,
            data,
            onSuccess: (res) => {
                this.setState({ orders: res.list })
            },
        });
        this.getAllReports();
    }

    getAllLabList = (lgpId, labId) => {
        const { dispatch } = this.props;
        dispatch({
            type: GET_LGP_LAB_LIST,
            data: {
                lgpId,
                labId,
            },
            isPartner: getUser().userType === 2,
            onSuccess: (data) => {
                this.setState({
                    availableTestDetails: data.list,
                });
            },
        });
    };

    getOrderById = (order, isRedirect) => {
        const { dispatch } = this.props;

        dispatch({
            type: GET_ORDER_DATA,
            data: {
                orderId: order.orderId,
                type: order.orderStatus,
                isPast: this.state.isPast,
            },
            isPartner: getUser().userType === 2,
            onSuccess: (data) => {
                this.setState(
                    {
                        data,
                        updatedData: {},
                    },
                    () => {
                        if (isRedirect) {
                            this.props.history.push(
                                `/doctororders/${order.orderId}/${order.orderStatus}`
                            );
                        }
                    }
                );
            },
        });
    };

    redirectToDetail = (order) => {
        const { match, history } = this.props;
        this.setState(
            {
                offset: 0,
            },
            () => {
                this.getOrderById(order, true);
            }
        );
    };

    resetPopup = () => {
        this.setState({ data: {}, updatedData: {}, isError: false });
    };


    redirectToList = () => {
        const { match, history } = this.props;
        this.setState({ offset: 0 }, () => {
            this.getDoctorOrders();
        });
        this.resetPopup();
        history.goBack();
        this.setState({ tab: 1 })
    };

    renderVitals = (isPartner) => {
        const { vitals } = this.state;
        const { videoToken } = this.state;
        const { data } = this.state;


        return (
            <React.Fragment>
                <Grid container spacing={2} style={{ textAlign: 'center' }}>
                    <Grid item xs={3}>
                        <Typography><span style={{ fontWeight: 'bold' }}>Phlebo: </span>{data.firstName +
                            " " +
                            data.lastName +
                            "-" +
                            data.mobileNumber}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography ><span style={{ fontWeight: 'bold' }}>Order No: </span>{data.order_seq}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography ><span style={{ fontWeight: 'bold' }}>Custormer Name: </span>{data.customerName}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: '1em' }}>
                    <Grid item xs={12} md={6} lg={4}>
                        <div style={{ padding: 5 }}>
                            <Card className="p-5">
                                {vitals.customer_name ? <Grid item xs={12} >
                                    <Grid container spacing={2} >
                                        <Grid item xs={8} textAlign='center'><Typography variant={'h6'} weight='bold' sx={{ textAlign: 'center' }}>Vitals</Typography></Grid>
                                        <Grid item xs={4} style={{ textAlign: 'right' }}>
                                            <IconButton onClick={() => { this.handleRefreshVitals() }}><Refresh /></IconButton>
                                        </Grid>
                                    </Grid>

                                    <FormControl fullWidth disabled>
                                        <FormHelperText id="standard-weight-helper-text">Blood Pressure(Reading-1)</FormHelperText>
                                        <OutlinedInput
                                            id="standard-adornment-weight"
                                            value={`${vitals.bp1_Systolic}/${vitals.bp1_Diastolic}`}
                                            startAdornment={<InputAdornment position="start"><img src={BloodPressure} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                            endAdornment={<InputAdornment position="end">mmHg</InputAdornment>}
                                            aria-describedby="standard-weight-helper-text"
                                            variant="outlined"
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />

                                    </FormControl>
                                    <FormControl fullWidth disabled>
                                        <FormHelperText id="standard-weight-helper-text">Blood Pressure(Reading-2)</FormHelperText>
                                        <OutlinedInput

                                            id="standard-adornment-weight"
                                            value={`${vitals.bp2_Systolic}/${vitals.bp2_Diastolic}`}
                                            startAdornment={<InputAdornment position="start"><img src={BloodPressure} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                            endAdornment={<InputAdornment position="end">mmHg</InputAdornment>}
                                            aria-describedby="standard-weight-helper-text"
                                            variant="outlined"
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />

                                    </FormControl>
                                    <FormControl fullWidth disabled>
                                        <FormHelperText id="standard-weight-helper-text">Blood Pressure(Reading-3)</FormHelperText>
                                        <OutlinedInput

                                            id="standard-adornment-weight"
                                            value={`${vitals.bp3_Systolic}/${vitals.bp3_Diastolic}`}
                                            startAdornment={<InputAdornment position="start"><img src={BloodPressure} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                            endAdornment={<InputAdornment position="end">mmHg</InputAdornment>}
                                            aria-describedby="standard-weight-helper-text"
                                            variant="outlined"
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />

                                    </FormControl>
                                    <FormControl fullWidth disabled>
                                        <FormHelperText id="standard-weight-helper-text">Height</FormHelperText>
                                        <OutlinedInput

                                            id="standard-adornment-weight"
                                            value={vitals.height}
                                            startAdornment={<InputAdornment position="start"><img src={Height} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                            endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                                            aria-describedby="standard-weight-helper-text"
                                            variant="outlined"
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth disabled>
                                        <FormHelperText id="standard-weight-helper-text">Weight</FormHelperText>
                                        <OutlinedInput

                                            id="standard-adornment-weight"
                                            value={vitals.weight}
                                            startAdornment={<InputAdornment position="start"><img src={Weight} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                            endAdornment={<InputAdornment position="end">kgs</InputAdornment>}
                                            aria-describedby="standard-weight-helper-text"
                                            variant="outlined"
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth disabled>
                                        <FormHelperText id="standard-weight-helper-text">Chest Expiration</FormHelperText>
                                        <OutlinedInput
                                            id="standard-adornment-weight"
                                            value={vitals.chest_Expiration}
                                            startAdornment={<InputAdornment position="start"><img src={Heart} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                            endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                                            aria-describedby="standard-weight-helper-text"
                                            variant="outlined"
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />

                                    </FormControl>

                                    <FormControl fullWidth disabled>
                                        <FormHelperText id="standard-weight-helper-text">Chest Inspiration</FormHelperText>
                                        <OutlinedInput

                                            id="standard-adornment-weight"
                                            value={vitals.chest_Inspiration}
                                            startAdornment={<InputAdornment position="start"><img src={Heart} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                            endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                                            aria-describedby="standard-weight-helper-text"
                                            variant="outlined"
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />

                                    </FormControl>
                                    <FormControl fullWidth disabled >
                                        <FormHelperText id="standard-weight-helper-text">Abdomen Girth</FormHelperText>
                                        <OutlinedInput

                                            id="standard-adornment-weight"
                                            value={vitals.abdomen_Girth}
                                            startAdornment={<InputAdornment position="start"><img src={Abdomen} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                            endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                                            aria-describedby="standard-weight-helper-text"
                                            variant="outlined"
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth disabled >
                                        <FormHelperText id="standard-weight-helper-text">Hip</FormHelperText>
                                        <OutlinedInput

                                            id="standard-adornment-weight"
                                            value={vitals.hip}
                                            startAdornment={<InputAdornment position="start"><img src={Waist} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                            endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                                            aria-describedby="standard-weight-helper-text"
                                            variant="outlined"
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth disabled >
                                        <FormHelperText id="standard-weight-helper-text">Waist</FormHelperText>
                                        <OutlinedInput

                                            id="standard-adornment-weight"
                                            value={vitals.waist}
                                            startAdornment={<InputAdornment position="start"><img src={Waist} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                            endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                                            aria-describedby="standard-weight-helper-text"
                                            variant="outlined"
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth disabled >
                                        <FormHelperText id="standard-weight-helper-text">Hip Waist Ratio</FormHelperText>
                                        <OutlinedInput

                                            id="standard-adornment-weight"
                                            value={vitals.hipWaistRatio}
                                            startAdornment={<InputAdornment position="start"><img src={Waist} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                            endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                                            aria-describedby="standard-weight-helper-text"
                                            variant="outlined"
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth disabled>
                                        <FormHelperText id="standard-weight-helper-text">Pulse</FormHelperText>
                                        <OutlinedInput

                                            id="standard-adornment-weight"
                                            value={vitals.pulse}
                                            startAdornment={<InputAdornment position="start"><img src={Pulse} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                            endAdornment={<InputAdornment position="end">bpm</InputAdornment>}
                                            aria-describedby="standard-weight-helper-text"
                                            variant="outlined"
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                    </FormControl>
                                </Grid> : <Grid item xs={12} container spacing={1}>
                                    <Grid item xs={8}>
                                        <Typography variant='h6' weight='bold'>Vitals not submitted</Typography>
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                                        <IconButton onClick={() => { this.handleRefreshVitals() }}><Refresh /></IconButton>
                                    </Grid>
                                </Grid>}
                            </Card>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8}>
                        <Agora fileData={this.callBackFunction} fileSent={this.callBackFuntion} joinStatus={this.callBackJoinStatus} tempToken={videoToken.token} cname={data.order_seq} />
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }

    renderFormFields = () => {
        const { isError } = this.state;
        const { data, isMobile } = this.state;
        const user = getUser();
        const mode = PAYMENT_MODE.filter((el) => el.id === data.paymentMode);
        const orderType = ORDER_TYPE.filter((el) => {
            return el.id === data.order_type;
        });
        const orderExecType = ORDER_EXECUTION_TYPE.filter((el) => {
            return el.id === data.order_execution_type;
        });
        const assignedStatus = ORDER_ASSIGNED_STATUS.filter(
            (el) => el.id === data.orderAssignedStatus
        );

        if (Object.keys(data).length === 0) return;

        return (
            <React.Fragment>
                <Grid container spacing={1}>
                    <Grid item xs={isMobile ? 6 : 3}>
                        <div className="mb-20">Order No: {data.order_seq}</div>
                        <div className="mb-20">
                            Vendor Order No: {data.vendor_order_number}
                        </div>
                    </Grid>
                    <Grid item xs={isMobile ? 6 : 2}>
                        <div className="mb-20"> Visit Type: {data.visit_type} </div>
                        <div className="mb-20">
                            {" "}
                            Is Dedicated: {data.is_dedicated ? "Yes" : "No"}{" "}
                        </div>
                    </Grid>
                    <Grid item xs={isMobile ? 6 : 4}>
                        <div className="mb-20">
                            Order Type: {orderType.length > 0 ? orderType[0].name : "-"}
                        </div>
                        <div className="mb-20">
                            Order Execution Type:{" "}
                            {orderExecType.length > 0 ? orderExecType[0].name : "-"}
                        </div>
                    </Grid>
                    <Grid item xs={isMobile ? 6 : 3}>
                        <div className="mb-20">
                            Created: {moment(data.createdTime).format("D MMM, YY h:mm A")}
                        </div>
                        {assignedStatus.length > 0 && (
                            <div className="mb-20">
                                <span className="status-box">
                                    {assignedStatus[0].name.replace(/_/g, " ")}{" "}
                                </span>{" "}
                            </div>
                        )}
                    </Grid>
                </Grid>
                {data.orderPriority && <> <h2 style={user.isShowDoctors ? { color: "Blue" } : null}>
                    {" "}
                    Order Priority{" "}
                </h2> <Grid container spacing={2}>
                        <Grid item xs={4}>


                            <Typography>
                                {data.orderPriority}
                            </Typography>

                        </Grid>

                    </Grid></>}
                <h2 style={user.isShowDoctors ? { color: "Blue" } : null}>
                    {" "}
                    Customer Details{" "}
                </h2>
                <Grid container spacing={1}>
                    <Grid item xs={isMobile ? 6 : 3}>
                        <Typography><span style={{ fontWeight: 'bold' }}>Customer Name: </span>{data.customer_name}</Typography>
                    </Grid>
                    <Grid item xs={isMobile ? 6 : 3}>
                        <Typography><span style={{ fontWeight: 'bold' }}>Customer Contact: </span>{data.customer_contact}</Typography>
                    </Grid>
                    <Grid item xs={isMobile ? 6 : 3}>
                        <Typography><span style={{ fontWeight: 'bold' }}>Customer Email: </span>{data.customer_email}</Typography>

                    </Grid>
                    <Grid item xs={isMobile ? 6 : 1}>
                        <Typography><span style={{ fontWeight: 'bold' }}>Gender: </span>{data.gender}</Typography>
                    </Grid>
                    <Grid item xs={isMobile ? 6 : 1}>
                        <Typography><span style={{ fontWeight: 'bold' }}>Age: </span>{data.age}</Typography>
                    </Grid>
                </Grid>


                <h2> Address Details </h2>
                <Grid container spacing={3}>
                    <Grid item xs={isMobile ? 6 : 3}>
                        <Typography><span style={{ fontWeight: 'bold' }}>Lab Name: </span>{data.labName}</Typography>
                    </Grid>
                    <Grid item xs={isMobile ? 6 : 3}>
                        <Typography><span style={{ fontWeight: 'bold' }}>LGP Name: </span>{data.lgpName}</Typography>
                    </Grid>
                    <Grid item xs={isMobile ? 6 : 3}>
                        <Typography><span style={{ fontWeight: 'bold' }}>Earliest Pickup: </span>{moment(data.earliest_pickup_date).format("Do MMM, YYYY h:mm A")}{" "}</Typography>
                    </Grid>
                    <Grid item xs={isMobile ? 6 : 3}>
                        <Typography><span style={{ fontWeight: 'bold' }}>Latest Pickup: </span>{moment(data.latest_pickup_date).format("Do MMM, YYYY h:mm A")}{" "}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={isMobile ? 6 : 3}>
                        <Typography><span style={{ fontWeight: 'bold' }}>Address 1: </span>{data.ship_to_address1}</Typography>
                    </Grid>
                    <Grid item xs={isMobile ? 6 : 3}>
                        <Typography><span style={{ fontWeight: 'bold' }}>Address 2: </span>{data.ship_to_address2}</Typography>
                    </Grid>
                    <Grid item xs={isMobile ? 6 : 3}>
                        <Typography><span style={{ fontWeight: 'bold' }}>Landmark: </span>{data.landmark}</Typography>
                    </Grid>
                    <Grid item xs={isMobile ? 6 : 3}>
                        <Typography><span style={{ fontWeight: 'bold' }}>City: </span>{data.ship_to_city}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={isMobile ? 6 : 3}>
                        <Typography><span style={{ fontWeight: 'bold' }}>State: </span>{data.ship_to_state}</Typography>
                    </Grid>
                    <Grid item xs={isMobile ? 6 : 3}>
                        <Typography><span style={{ fontWeight: 'bold' }}>Zipcode: </span>{data.ship_to_zip_code}</Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={isMobile ? 6 : 3}>
                        <Typography><span style={{ fontWeight: 'bold' }}>Latitude: </span>{data.ship_to_latitude}</Typography>
                    </Grid>
                    <Grid item xs={isMobile ? 6 : 3}>
                        <Typography><span style={{ fontWeight: 'bold' }}>Longitude: </span>{data.ship_to_longitude}</Typography>
                    </Grid>
                </Grid>
                <h2> Test Details </h2>
                <TableContainer component={Paper} className="mb-30">
                    <Table size="small" aria-label="order table">
                        <TableHead>
                            <TableRow>
                                <TableCell> Seq # </TableCell>
                                <TableCell> ID </TableCell>
                                <TableCell> Name </TableCell>
                                <TableCell> Pickup Comments </TableCell>
                                <TableCell> Qty </TableCell>
                                <TableCell> Description </TableCell>
                                <TableCell> Tube </TableCell>
                                <TableCell> Price </TableCell>
                                <TableCell> Comments </TableCell>
                                {/*<TableCell />*/}
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(data.test_details || []).length <= 0 && (
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {" "}
                                        No order found.{" "}
                                    </TableCell>
                                </TableRow>
                            )}
                            {(data.test_details || []).map((order, index) => {
                                return (
                                    <TableRow key={`test-${index + 1}`}>
                                        <TableCell>
                                            <Typography>{order.test_sequence}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{order.test_id}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{order.test_name}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{order.pickup_comments}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{order.quantity}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{order.test_description}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{order.test_tube}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{order.unit_price}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{order.comments}</Typography>
                                        </TableCell>

                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <h2> Barcodes </h2>
                <TableContainer component={Paper} className="mb-30">
                    <Table size="small" aria-label="order table">
                        <TableHead>
                            <TableRow>
                                <TableCell> Barcode</TableCell>
                                <TableCell> Barcode Type</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(data.barcodes || []).length <= 0 && (
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {" "}
                                        No Barcode found.{" "}
                                    </TableCell>
                                </TableRow>
                            )}
                            {(data.barcodes || []).map((barcode, index) => {
                                return (
                                    <TableRow key={`test-${index + 1}`}>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                // label="Prepaid Amount"
                                                value={barcode.barcode}
                                                onChange={(e) => this.handleFormBarcodeChange(e, index)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                // label="Prepaid Amount"
                                                value={barcode.barcodeType}
                                                onChange={(e) =>
                                                    this.handleFormBarcodeTypeChange(e, index)
                                                }
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                edge="end"
                                                color="inherit"
                                                style={{ width: 20 }}
                                                size="small"
                                                onClick={() => {
                                                    const { data, updatedData } = this.state;
                                                    (data.barcodes || []).splice(index, 1);
                                                    updatedData.barcodes = data.barcodes;
                                                    this.setState({ data, updatedData });
                                                }}
                                            >
                                                <Clear />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <h2> Package Details </h2>
                <Grid container spacing={3}>
                    <Grid item xs={isMobile ? 6 : 3}>
                        <Typography>{data.packageName}</Typography>
                    </Grid>
                    <Grid item xs={isMobile ? 6 : 3}>
                        <Typography>{data.packageDescription}</Typography>
                    </Grid>
                    <Grid item xs={isMobile ? 6 : 5}>
                        <Typography>{data.comments}</Typography>
                    </Grid>
                </Grid>

                {data.consumablesList &&
                    data.consumablesList.length > 0 &&
                    user.userType === 1 && (
                        <div>
                            <h2> Consumables </h2>
                            <TableContainer component={Paper} className="mb-30">
                                <Table size="small" aria-label="order table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell> Name </TableCell>
                                            <TableCell> Qty </TableCell>
                                            <TableCell> Bill Price </TableCell>
                                            <TableCell> Bill Price GST </TableCell>
                                            <TableCell> Landing Price </TableCell>
                                            <TableCell> Landing Price GST </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(data.consumablesList || []).length <= 0 && (
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {" "}
                                                    No consumables found.{" "}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {(data.consumablesList || []).map((order, index) => {
                                            return (
                                                <TableRow key={`consumable-${index + 1}`}>
                                                    <TableCell> {order.name} </TableCell>
                                                    <TableCell> {order.quantity} </TableCell>
                                                    <TableCell> {order.billingPrice} </TableCell>
                                                    <TableCell> {order.billingPriceGST} </TableCell>
                                                    <TableCell> {order.landingPrice} </TableCell>
                                                    <TableCell> {order.landingPriceGST} </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>

                    )}
            </React.Fragment>
        );
    };

    renderBasicDetails = (isKYC, isPartner) => {
        return (
            <React.Fragment>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {this.renderFormFields()}
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    renderReports = () => {
        const { labReport, addReport } = this.state;
        const { orderData, labReports } = this.props;

        return (
            <React.Fragment>
                <h3>
                    {" "}
                    Lab Reports
                    <Button
                        variant="contained"
                        style={{ marginLeft: "20px" }}
                        color="primary"
                        onClick={() => {
                            this.setState({ addReport: !this.state.addReport });
                        }}
                    >
                        {" "}
                        Add Report{" "}
                    </Button>{" "}
                </h3>
                {addReport && (
                    <div className="mb-20">
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <label> Name </label>
                                <TextField
                                    fullWidth
                                    size="small"
                                    value={labReport.name}
                                    onChange={(e) => {
                                        const { labReport } = this.state;
                                        labReport.name = e.target.value;
                                        this.setState({ labReport });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <label> Image </label>
                                <input
                                    type="file"
                                    className="inputFileUpload"
                                    onChange={(e) => {
                                        this.uploadImage(e.target.files, true);
                                    }}
                                    ref={(ref) => {
                                        this.input = ref;
                                    }}
                                />
                                {labReport.url && labReport.url.length > 0 && (
                                    <a
                                        href={labReport.url}
                                        style={{ color: "blue", fontSize: "15px" }}
                                    >
                                        Reports Uploaded
                                    </a>
                                )}
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.addNewReport}
                                >
                                    {" "}
                                    Save{" "}
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    style={{ marginLeft: "20px" }}
                                    onClick={() => {
                                        this.setState({ addReport: false, labReport: {} });
                                    }}
                                >
                                    {" "}
                                    Cancel{" "}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                )}
                {(labReports || []).length > 0 && (
                    <TableContainer component={Paper} className="mb-30">
                        <Table size="small" aria-label="order table">
                            <TableHead>
                                <TableRow>
                                    <TableCell> Image </TableCell>
                                    <TableCell> Name </TableCell>
                                    <TableCell> Created </TableCell>
                                    <TableCell> Action </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(labReports || []).map((lab, index) => {
                                    return (
                                        <TableRow key={`lab-${index + 1}`}>
                                            <TableCell>
                                                <a
                                                    href={lab.url}
                                                    style={{ color: "blue", fontSize: "10px" }}
                                                >
                                                    <Avatar size="small" src={lab.url} alt="icon" />
                                                </a>
                                            </TableCell>
                                            <TableCell> {lab.name} </TableCell>
                                            <TableCell>
                                                {" "}
                                                {moment(lab.created).format("Do MMM, YYYY h:mm A")}{" "}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    edge="end"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => {
                                                        this.setState({
                                                            isReportDelete: !this.state.isReportDelete,
                                                            deleteObj: { ...lab },
                                                        });
                                                    }}
                                                >
                                                    <Clear />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </React.Fragment>
        );
    };

    downloadVideoMer = () => {
        const { dispatch } = this.props;
        const { data } = this.state;

        dispatch({
            type: DOWNLOAD_VIDEO_MER,
            data: {
                orderId: data.order_seq,
            },
            onSuccess: (data) => {
                this.setState(
                    {
                        downloadMer: data.list,
                    });
            }
        });

    }

    renderPagination = (isKYC) => {
        return (
            <div className="mb-30">
                <Grid container spacing={5}>
                    <Grid item xs={9} />
                    <Grid item xs={3}>
                        <Button
                            style={{ marginRight: "20px" }}
                            size="medium"
                            color="primary"
                            variant="outlined"
                            disabled={this.state.offset === 0}
                            onClick={() => {
                                this.setState({ offset: this.state.offset - 10 }, () => {
                                    this.getDoctorOrders();
                                });
                            }}
                        >
                            {"< Previous"}
                        </Button>
                        <Button
                            size="medium"
                            color="primary"
                            variant="outlined"
                            disabled={!this.props.hasMore}
                            onClick={() => {
                                this.setState({ offset: this.state.offset + 10 }, () => {
                                    this.getDoctorOrders();
                                });
                            }}
                        >
                            {"Next >"}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    };

    multipleVideos = () => {
        for (let i = 0; i < this.state.downloadMer && this.state.downloadMer.length; i++) {
            this.state.downloadMer[i]["s_no"] = i + 1;
        }
        this.setState({
            numbers: this.state.downloadMer,
        })
    }

    handleVitals = () => {
        this.getVitalsByOrderId();
        this.getVideoTokenByOrderId();
    }
    handleRefreshVitals = () => {
        this.getVitalsByOrderId();
    }

    handleClickVitals = () => {
        this.setState({ openVitals: true });
        this.getVitalsByOrderId();
        this.getVideoTokenByOrderId();
    }

    handleClose = () => {
        const { join } = this.state;
        if (!join) {
            this.setState({ openVitals: false });
        } else {
            this.setState({ openAlert: true })
            setTimeout(() => {
                this.setState({ openAlert: false })
            }, 5000)
        }
    };
    handlePpmc = () => {
        this.getVitalsByOrderId();
        setTimeout(() => {
            this.downloadVideoMer();
        }, 1000);

        setTimeout(() => {
            this.multipleVideos();
        }, 2000);

    }

    render() {
        const { orders, type, selectedOrders, isMobile, data, city, isPast } = this.state;
        const { match, history } = this.props;
        let isKYC = false;
        if (match.params && match.params.id) {
            isKYC = true;
        }
        const selectedtype = [...ORDER_CONSTANTS, ...PAST_TABS].find((el) => {
            return el.id === type;
        });
        const assndStatus = ORDER_ASSIGNED_STATUS.filter(
            (el) => el.id === data.orderAssignedStatus
        );
        return (
            <DocContainer>
                {!isKYC ? (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Typography variant={'h5'} style={{ fontWeight: 'bold' }}>My Orders</Typography>
                        </Grid>
                        <Grid item xs={12} container spacing={1} style={{ marginTop: '1em' }}>
                            <Grid item xs={4}>
                                <label> Date </label>
                                <DatePicker
                                    placeholderText="Date"
                                    selectsStart
                                    startDate={new Date()}
                                    // endDate={new Date((new Date()).setFullYear((new Date()).getFullYear() + 20))}
                                    selected={this.state.dateFilter}
                                    onChange={(date) => {
                                        localStorage.setItem(
                                            "orderDateFilter",
                                            new Date(date).getTime()
                                        );
                                        this.setState(
                                            { dateFilter: date, endDateFilter: "" },
                                            () => {
                                                this.getDoctorOrders();
                                            }
                                        );
                                    }}
                                    isClearable={true}
                                />
                            </Grid>
                        </Grid>
                        <div>
                            <Grid item xs={12} style={{ marginTop: '1em' }}>
                                {this.getReportsOrders().map((order) => {
                                    return (
                                        <React.Fragment>
                                            <Chip
                                                clickable
                                                key={order.id}
                                                style={{ marginRight: "10px", marginBottom: "10px" }}
                                                label={`${order.name}${this.state.allReports.length !== 0
                                                    ? order.count === undefined
                                                        ? order.name !== "API"
                                                            ? "(0)"
                                                            : ""
                                                        : "(" + order.count + ")"
                                                    : "(0)"
                                                    }`}
                                                color={
                                                    (order.id === type &&
                                                        !this.props.typeValue) ||
                                                        order.id == this.props.typeValue
                                                        ? "primary"
                                                        : ""
                                                }
                                                onClick={() => {
                                                    this.props.dispatch({
                                                        type: SET_TYPE_VALUE,
                                                        data: "",
                                                    });
                                                    this.setState(
                                                        {
                                                            type: order.id,
                                                        },
                                                        () => {
                                                            this.getDoctorOrders();
                                                        }
                                                    );

                                                }}
                                            />
                                            {order.pipe && (
                                                <span style={{ fontSize: "30px", marginRight: "5px" }}>
                                                    |{" "}
                                                </span>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </Grid>
                        </div>
                        <Grid item xs={12} style={{ marginTop: '1em' }}>
                            <TableContainer component={Paper} className="mb-30">
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            {this.state.type !== 70 ? <TableCell /> : null}
                                            <TableCell> Order # </TableCell>
                                            <TableCell> LGP Name </TableCell>
                                            <TableCell> Lab Name </TableCell>
                                            <TableCell> Customer Detail </TableCell>
                                            <TableCell> Pickup </TableCell>
                                            <TableCell> Address </TableCell>
                                            <TableCell> View </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(orders || []).length <= 0 && (
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {" "}
                                                    No order found.{" "}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {(orders || []).map((order) => {
                                            const mode = PAYMENT_MODE.filter(
                                                (el) => el.id === order.paymentMode
                                            );
                                            const assignedStatus = ORDER_ASSIGNED_STATUS.filter(
                                                (el) => el.id === order.orderAssignedStatus
                                            );
                                            const orderType = [
                                                ...DOCTOR_ORDER_CONSTANTS,
                                                ...PAST_TABS,
                                            ].find((el) => {
                                                return el.id === order.type;
                                            });
                                            const orderTypee = ORDER_TYPE.filter((el) => {
                                                return el.id === order.order_type;
                                            });

                                            const ele = (
                                                <React.Fragment key={order.orderId}>
                                                    <TableRow>
                                                        <TableCell>
                                                            {order.orderPriority === 'Escalation' ? <div>
                                                                <Star fontSize='small' color='primary' /><br />
                                                                <Star fontSize='small' color='primary' /><br />
                                                                <Star fontSize='small' color='primary' />
                                                            </div> : null}
                                                            {order.orderPriority === 'VIP' ? <div>
                                                                <Star fontSize='small' color='primary' /><br />
                                                                <Star fontSize='small' color='primary' />
                                                            </div> : null}
                                                            {order.orderPriority === 'Age' ? <div>
                                                                <Star fontSize='small' color='primary' />
                                                            </div> : null}
                                                        </TableCell>

                                                        <TableCell>
                                                            <div>{order.order_seq}</div>
                                                            <div>
                                                                <span className="title-color">VON:</span>{" "}
                                                                {order.vendor_order_number}{" "}
                                                            </div>
                                                            <div>
                                                                <span className="title-color">Created:</span>{" "}
                                                                {moment(order.createdTime).format(
                                                                    "D MMM, YY h:mm A"
                                                                )}{" "}
                                                            </div>
                                                            {assignedStatus.length > 0 && (
                                                                <div>
                                                                    <span className="title-color">
                                                                        Event:{" "}
                                                                    </span>{" "}
                                                                    <span className="status-box fs-10">
                                                                        {assignedStatus[0].name.replace(
                                                                            /_/g,
                                                                            " "
                                                                        )}{" "}
                                                                    </span>{" "}
                                                                </div>
                                                            )}
                                                            Type :{" "}
                                                            {orderTypee.length > 0
                                                                ? orderTypee[0].name
                                                                : "-"}
                                                        </TableCell>
                                                        <TableCell>{order.bill_to_name}</TableCell>
                                                        <TableCell>{order.preferred_lab_name}</TableCell>
                                                        <TableCell>
                                                            <div>
                                                                {" "}
                                                                <span className="title-color">
                                                                    Name:
                                                                </span>{" "}
                                                                {order.customer_name}
                                                            </div>
                                                            <div>
                                                                {" "}
                                                                <span className="title-color">
                                                                    Contact:
                                                                </span>{" "}
                                                                {order.customer_contact}
                                                                <IconButton
                                                                    edge="end"
                                                                    color="inherit"
                                                                    style={{ width: 20 }}
                                                                    size="small"
                                                                    onClick={() =>
                                                                        this.handlePhoneClick(
                                                                            order.orderId,
                                                                            order.customer_contact
                                                                        )
                                                                    }
                                                                >
                                                                    <Phone />
                                                                </IconButton>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div>
                                                                {moment(order.a_earliest_pickup_date).format(
                                                                    "D MMM, YY h:mm A"
                                                                )}{" "}
                                                                -
                                                            </div>
                                                            <div>
                                                                {moment(order.a_latest_pickup_date).format(
                                                                    "D MMM, YY h:mm A"
                                                                )}
                                                            </div>
                                                            {order.firstName ? (
                                                                <div>
                                                                    {" "}
                                                                    <br />
                                                                    Phlebo: {order.firstName + "" + order.lastName}
                                                                </div>
                                                            ) : null}
                                                            <div>{order.mobileNumber}</div>
                                                        </TableCell>
                                                        <TableCell style={{ width: "200px" }}>
                                                            {order.ship_to_address1} {order.ship_to_address2}{" "}
                                                            {order.ship_to_zip_code}
                                                        </TableCell>
                                                        <TableCell>
                                                            <IconButton
                                                                edge="end"
                                                                color="inherit"
                                                                size="small"
                                                                onClick={() => {
                                                                    this.redirectToDetail(order);
                                                                }}
                                                            >
                                                                <Visibility />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            );


                                            return [ele];
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        {this.renderPagination()}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Grid container spacing={3}>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={() => {
                                    this.redirectToList();
                                }}
                            >
                                <ArrowBackOutlined />
                            </IconButton>
                            <Grid item xs={isMobile ? 10 : 3}>
                                {" "}
                                <h2 className="mt-0">ORDER DETAILS</h2>{" "}
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 4}>
                                <div
                                    style={{
                                        textAlign: isMobile ? "left" : "right",
                                        fontSize: "13px",
                                    }}
                                >
                                    Phlebo:{" "}
                                    {data.firstName +
                                        " " +
                                        data.lastName +
                                        "-" +
                                        data.mobileNumber}
                                </div>
                            </Grid>
                            <Grid item xs={isMobile ? 6 : 2}>
                                <div
                                    style={{
                                        textAlign: isMobile ? "left" : "right",
                                        fontSize: "13px",
                                    }}
                                >
                                    Order No: {data.order_seq}
                                </div>
                            </Grid>
                            <Grid
                                item
                                xs={isMobile ? 6 : 2}
                                style={{
                                    textAlign: isMobile ? "left" : "right",
                                    fontSize: "13px",
                                }}
                            >
                                {Object.keys(selectedtype).length > 0 && (
                                    <div className="mb-20">Status: {selectedtype.name}</div>
                                )}
                                {assndStatus.length > 0 && (
                                    <div>
                                        <span className="status-box">
                                            {assndStatus[0].name.replace(/_/g, " ")}{" "}
                                        </span>{" "}
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                        <Tabs
                            scrollButtons={isMobile ? "on" : "off"}
                            variant="scrollable"
                            indicatorColor="primary"
                            value={this.state.tab}
                            onChange={(e, tab) => {
                                this.setState({ tab });
                            }}
                        >
                            <Tab label="Basic" value={1} />
                            <Tab label="PPMC" value={2} onClick={() => { this.handlePpmc() }} />
                            <Tab label="Life Cycle" value={3} />
                            <Button value={4} variant={'text'} onClick={this.handleClickVitals}>Vitals/VMER</Button>
                        </Tabs>

                        <TabPanel value={this.state.tab} index={1}>
                            {this.renderBasicDetails(isKYC)}
                        </TabPanel>
                        <TabPanel value={this.state.tab} index={2}>
                            {Object.keys(this.state.data).length ? (
                                <div className="ppmc_images">
                                    {this.state.data.ppmc_id ? null : <div style={{ textAlign: 'right' }}><Button variant='contained' style={{ backgroundColor: '#8CADF' }} >Generate Document</Button></div>}
                                    <div>
                                        <h1>PPMC ID</h1>
                                        {this.state.data.ppmc_id.map((img) => (
                                            <span>{<img src={img} alt="ppmc_id Image" />}</span>
                                        ))}
                                        <hr />
                                    </div>

                                    <div>
                                        <h1>PPMC LIVE</h1>
                                        {this.state.data.ppmc_live.map((img) => (
                                            <span>{<img src={img} alt="ppmc_live Image" />}</span>
                                        ))}
                                        <hr />
                                    </div>
                                    <div>
                                        <Grid container spacing={1}>
                                            <Grid item xs={3}>
                                                <h1>PPMC MER</h1>
                                            </Grid>
                                            <Grid item xs={3} style={{ marginTop: '2em' }}>
                                                <input
                                                    style={{ fontSize: "unset", margin: 10 }}
                                                    type="file"
                                                    onChange={(e) => {
                                                        this.uploadImage(
                                                            e.target.files
                                                        );
                                                    }}
                                                />
                                            </Grid>

                                        </Grid>
                                        {this.state.data.ppmc_mer.map((pdflink) => (
                                            <span>
                                                {
                                                    <a
                                                        href={pdflink}
                                                        target="_blank"
                                                        style={{
                                                            background: "#00c9aa",
                                                            color: "#fff",
                                                            padding: "10px 20px",
                                                            borderRadius: "5px",
                                                            marginBottom: "20px",
                                                            fontSize: "16px",
                                                        }}
                                                    >
                                                        <Visibility style={{ marginBottom: "-4px" }} />{" "}
                                                        View Document
                                                    </a>
                                                }
                                            </span>
                                        ))}
                                        <hr style={{ marginTop: "25px" }} />
                                    </div>
                                    <div>
                                        <h1>PPMC NAME CHANGE</h1>
                                        {this.state.data.ppmc_namechange.map((img) => (
                                            <span>
                                                {<img src={img} alt="ppmc_namechange Image" />}
                                            </span>
                                        ))}
                                        <hr />
                                    </div>
                                    <div>
                                        <h1>PPMC V-MER</h1>
                                         {this.state.numbers && this.state.numbers.map((item) => (
                                            <a href={item.Vmer_Url} target="_blank" style={{ fontSize: '15px', border: '1px solid transparent', padding: '5px', backgroundColor: '#0D8DA3', color: '#ffff', borderRadius: '5px', marginRight: '10px' }}>Video {item.s_no}</a>
                                        ))}
                                         <hr />
                                    </div>
                                    <div>
                                        <h1>Vitals</h1>
                                        <div style={{ padding: 5 }}>

                                            <Card className="p-5">


                                                {this.state.vitals.customer_name ? <Grid item xs={6} >
                                                    <Grid container spacing={2} >
                                                        <Grid item xs={8} textAlign='center'><Typography variant={'h6'} weight='bold' sx={{ textAlign: 'center' }}>{this.state.vitals.customer_Name}Vitals</Typography></Grid>
                                                        <Grid item xs={4} style={{ textAlign: 'right' }}>
                                                            <IconButton onClick={() => { this.handleRefreshVitals() }}><Refresh /></IconButton>
                                                        </Grid>
                                                    </Grid>
                                                    {/* <Grid item xs={12} textAlign='center'><Typography sx={{textAlign:'center'}}>{vitals.policy_Number}</Typography></Grid>  */}

                                                    <FormControl fullWidth disabled>
                                                        <FormHelperText id="standard-weight-helper-text">Blood Pressure(Reading-1)</FormHelperText>
                                                        <OutlinedInput

                                                            id="standard-adornment-weight"
                                                            value={`${this.state.vitals.bp1_Systolic}/${this.state.vitals.bp1_Diastolic}`}
                                                            // onChange={handleChange('weight')}
                                                            startAdornment={<InputAdornment position="start"><img src={BloodPressure} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                                            endAdornment={<InputAdornment position="end">mmHg</InputAdornment>}
                                                            aria-describedby="standard-weight-helper-text"
                                                            variant="outlined"
                                                            inputProps={{
                                                                'aria-label': 'weight',
                                                            }}
                                                        />

                                                    </FormControl>
                                                    <FormControl fullWidth disabled>
                                                        <FormHelperText id="standard-weight-helper-text">Blood Pressure(Reading-2)</FormHelperText>
                                                        <OutlinedInput

                                                            id="standard-adornment-weight"
                                                            value={`${this.state.vitals.bp2_Systolic}/${this.state.vitals.bp2_Diastolic}`}
                                                            // onChange={handleChange('weight')}
                                                            startAdornment={<InputAdornment position="start"><img src={BloodPressure} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                                            endAdornment={<InputAdornment position="end">mmHg</InputAdornment>}
                                                            aria-describedby="standard-weight-helper-text"
                                                            variant="outlined"
                                                            inputProps={{
                                                                'aria-label': 'weight',
                                                            }}
                                                        />

                                                    </FormControl>
                                                    <FormControl fullWidth disabled>
                                                        <FormHelperText id="standard-weight-helper-text">Blood Pressure(Reading-3)</FormHelperText>
                                                        <OutlinedInput

                                                            id="standard-adornment-weight"
                                                            value={`${this.state.vitals.bp3_Systolic}/${this.state.vitals.bp3_Diastolic}`}
                                                            // onChange={handleChange('weight')}
                                                            startAdornment={<InputAdornment position="start"><img src={BloodPressure} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                                            endAdornment={<InputAdornment position="end">mmHg</InputAdornment>}
                                                            aria-describedby="standard-weight-helper-text"
                                                            variant="outlined"
                                                            inputProps={{
                                                                'aria-label': 'weight',
                                                            }}
                                                        />

                                                    </FormControl>
                                                    <FormControl fullWidth disabled>
                                                        <FormHelperText id="standard-weight-helper-text">Height</FormHelperText>
                                                        <OutlinedInput

                                                            id="standard-adornment-weight"
                                                            value={this.state.vitals.height}
                                                            // onChange={handleChange('weight')}
                                                            startAdornment={<InputAdornment position="start"><img src={Height} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                                            endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                                                            aria-describedby="standard-weight-helper-text"
                                                            variant="outlined"
                                                            inputProps={{
                                                                'aria-label': 'weight',
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormControl fullWidth disabled>
                                                        <FormHelperText id="standard-weight-helper-text">Weight</FormHelperText>
                                                        <OutlinedInput

                                                            id="standard-adornment-weight"
                                                            value={this.state.vitals.weight}
                                                            // onChange={handleChange('weight')}
                                                            startAdornment={<InputAdornment position="start"><img src={Weight} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                                            endAdornment={<InputAdornment position="end">kgs</InputAdornment>}
                                                            aria-describedby="standard-weight-helper-text"
                                                            variant="outlined"
                                                            inputProps={{
                                                                'aria-label': 'weight',
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormControl fullWidth disabled>
                                                        <FormHelperText id="standard-weight-helper-text">Chest Expiration</FormHelperText>
                                                        <OutlinedInput

                                                            id="standard-adornment-weight"
                                                            value={this.state.vitals.chest_Expiration}
                                                            // onChange={handleChange('weight')}
                                                            startAdornment={<InputAdornment position="start"><img src={Heart} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                                            endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                                                            aria-describedby="standard-weight-helper-text"
                                                            variant="outlined"
                                                            inputProps={{
                                                                'aria-label': 'weight',
                                                            }}
                                                        />

                                                    </FormControl>

                                                    <FormControl fullWidth disabled>
                                                        <FormHelperText id="standard-weight-helper-text">Chest Inspiration</FormHelperText>
                                                        <OutlinedInput

                                                            id="standard-adornment-weight"
                                                            value={this.state.vitals.chest_Inspiration}
                                                            // onChange={handleChange('weight')}
                                                            startAdornment={<InputAdornment position="start"><img src={Heart} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                                            endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                                                            aria-describedby="standard-weight-helper-text"
                                                            variant="outlined"
                                                            inputProps={{
                                                                'aria-label': 'weight',
                                                            }}
                                                        />

                                                    </FormControl>
                                                    <FormControl fullWidth disabled >
                                                        <FormHelperText id="standard-weight-helper-text">Abdomen Girth</FormHelperText>
                                                        <OutlinedInput
                                                            id="standard-adornment-weight"
                                                            value={this.state.vitals.abdomen_Girth}
                                                            // onChange={handleChange('weight')}
                                                            startAdornment={<InputAdornment position="start"><img src={Abdomen} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                                            endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                                                            aria-describedby="standard-weight-helper-text"
                                                            variant="outlined"
                                                            inputProps={{
                                                                'aria-label': 'weight',
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormControl fullWidth disabled >
                                                        <FormHelperText id="standard-weight-helper-text">Hip</FormHelperText>
                                                        <OutlinedInput

                                                            id="standard-adornment-weight"
                                                            value={this.state.vitals.hip}
                                                            // onChange={handleChange('weight')}
                                                            startAdornment={<InputAdornment position="start"><img src={Waist} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                                            endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                                                            aria-describedby="standard-weight-helper-text"
                                                            variant="outlined"
                                                            inputProps={{
                                                                'aria-label': 'weight',
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormControl fullWidth disabled >
                                                        <FormHelperText id="standard-weight-helper-text">Waist</FormHelperText>
                                                        <OutlinedInput

                                                            id="standard-adornment-weight"
                                                            value={this.state.vitals.waist}
                                                            // onChange={handleChange('weight')}
                                                            startAdornment={<InputAdornment position="start"><img src={Waist} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                                            endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                                                            aria-describedby="standard-weight-helper-text"
                                                            variant="outlined"
                                                            inputProps={{
                                                                'aria-label': 'weight',
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormControl fullWidth disabled >
                                                        <FormHelperText id="standard-weight-helper-text">Hip Waist Ratio</FormHelperText>
                                                        <OutlinedInput

                                                            id="standard-adornment-weight"
                                                            value={this.state.vitals.hipWaistRatio}
                                                            // onChange={handleChange('weight')}
                                                            startAdornment={<InputAdornment position="start"><img src={Waist} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                                            endAdornment={<InputAdornment position="end">cms</InputAdornment>}
                                                            aria-describedby="standard-weight-helper-text"
                                                            variant="outlined"
                                                            inputProps={{
                                                                'aria-label': 'weight',
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormControl fullWidth disabled>
                                                        <FormHelperText id="standard-weight-helper-text">Pulse</FormHelperText>
                                                        <OutlinedInput
                                                            id="standard-adornment-weight"
                                                            value={this.state.vitals.pulse}
                                                            startAdornment={<InputAdornment position="start"><img src={Pulse} style={{ width: '20px', height: '20px' }} /></InputAdornment>}
                                                            endAdornment={<InputAdornment position="end">bpm</InputAdornment>}
                                                            aria-describedby="standard-weight-helper-text"
                                                            variant="outlined"
                                                            inputProps={{
                                                                'aria-label': 'weight',
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid> : <Grid item xs={12} container spacing={1}>
                                                    <Grid item xs={8}>
                                                        <Typography variant='h6' weight='bold'>Vitals not submitted</Typography>
                                                    </Grid>
                                                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                                                        <IconButton onClick={() => { this.handleRefreshVitals() }}><Refresh /></IconButton>
                                                    </Grid>
                                                </Grid>}
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <h1>No this.state.data</h1>
                            )}
                        </TabPanel>
                        <TabPanel value={this.state.tab} index={3}>
                            <Lifecycle
                                orderId={this.state.data.orderId || ""}
                                orderSequence={this.state.data.orderSequence || ""}
                            />
                        </TabPanel>
                        {/* <TabPanel value={this.state.tab} index={7}>
                      {this.renderVitals(isPartner)}
                    </TabPanel> */}
                    </React.Fragment>
                )}
                <Dialog
                    fullScreen
                    open={this.state.openVitals}
                    onClose={this.handleClose}
                >
                    {this.state.openAlert && <Alert severity="warning" autoHideDuration={6000}>Please end the call and try again</Alert>}
                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left' }}>
                        <Tooltip title='Close'><IconButton
                            edge="start"
                            color="inherit"
                            onClick={this.handleClose}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                        </Tooltip>
                    </Box>
                    {this.renderVitals()}
                </Dialog>
            </DocContainer>
        )
    }
}
const mapStateToProps = ({ constants, typeValue, orders }) => {
    return {
        cities: constants.cities,
        typeValue: orders.typeValue,
        hasMore: orders.hasMore,
    };
};
export default withRouter(connect(mapStateToProps)(DoctorOrders));