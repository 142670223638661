/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React from "react";
import { connect } from "react-redux";
import "./customSidebar.css";

import { getUser } from "Utils/storage";
import {
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  Drawer,
  Hidden,
  IconButton,
} from "@material-ui/core";
import { MENU_LIST } from "./../../constants/commonConstants";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import { Link } from "react-router-dom";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuList: MENU_LIST,
      expanded: localStorage.getItem("expanded") || "",
    };
  }

  componentDidMount() {
    const user = getUser();
    const isPartner = user.userType === 2;
    const isDoctor = user.userType === 5;
    const userMenus = user.tabs || null;
    if (isPartner) {
      const menuList = MENU_LIST.filter((menu) => {
        return (!userMenus || userMenus.includes(menu.key)) && menu.isPartner;
      });
      this.setState({ menuList });
    } else if(isDoctor) {
      const menuList = MENU_LIST.filter((menu) => {
        return (!userMenus || userMenus.includes(menu.key)) && menu.isDoctor;
      });
      this.setState({ menuList });
    } else if (user.userType === 4 || user.userType === 3) {
      const menuList = MENU_LIST.filter((menu) => {
        return menu.key === "orderscanner";
      });
      this.setState({ menuList });
    } else {
      const menuList = MENU_LIST.filter((menu) => {
        return (
          (!userMenus || userMenus.includes(menu.key)) &&
          ((!menu.isPartner && !menu.isDoctor) || menu.isCommon)
        );
      });
      this.setState({ menuList });
    }
  }

  render() {
    return (
      <div className="lifespanSidebar">
        <Drawer
          variant="persistent"
          className="phlebo-sidebar"
          open={this.props.open}
        >
          <div
            onClick={this.props.handleOpenClose}
            style={{
              paddingLeft: "120px",
              cursor: "pointer",
              paddingTop: "20px",
            }}
          >
            <CloseIcon fontSize="large" />
          </div>
          <List style={{ paddingBottom: "44px" }}>
            {this.state.menuList.map((menu, index) => {
              return (
                <>
                  {menu.child && menu.child.length > 0 ? (
                    <>
                      <ListItem
                        onClick={() => {
                          this.setState(
                            {
                              expanded: this.state.expanded ? "" : menu.name,
                            },
                            () =>
                              localStorage.setItem(
                                "expanded",
                                this.state.expanded
                              )
                          );
                        }}
                        button
                      >
                        {menu.img && (
                          <img
                            src={`/images/${menu.img}.png`}
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                            alt="icon"
                          />
                        )}
                        <ListItemText primary={menu.name} />
                        {this.state.expanded === menu.name ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItem>
                      
                      {this.state.expanded === menu.name &&
                        menu.child.map((childmenu, cIndex) => (
                         
                          <ListItem
                            style={{ paddingLeft: 40 }}
                            component={Link}
                            to={childmenu.route}
                            button
                            selected={
                              window.location.pathname === childmenu.route
                            }
                          >
                            {childmenu.img && (
                              <img
                                src={`/images/${childmenu.img}.png`}
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "10px",
                                }}
                                alt="icon"
                              />
                            )}
                            <ListItemText primary={childmenu.name} />
                          </ListItem>
                        ))}
                    </>
                  ) : (
                   
                    <ListItem
                      component={Link}
                      to={menu.route}
                      button
                      selected={window.location.pathname === menu.route}
                    >
                      {menu.img && (
                        <img
                          src={`/images/${menu.img}.png`}
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "10px",
                          }}
                          alt="icon"
                        />
                      )}
                      <ListItemText primary={menu.name} />
                    </ListItem>
                  )}
                </>
              );
            })}
          </List>
        </Drawer>
      </div>
    );
  }
}

export default connect()(Sidebar);
