import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './clinics.css';

import DocContainer from './../../containers/DocContainer';
import ActionLoader from "../../components/ActionLoader";

import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	Dialog, DialogTitle, DialogContent, DialogActions,
	Paper,
	Button,
	Grid,
	TextField,
	IconButton,
	Typography,
	Box,
} from '@material-ui/core';

import {
	GET_INVENTORY,
	ADD_INVENTORY,

} from '../../constants/actionConstants';
import { Edit, Clear} from '@material-ui/icons';



class Inventory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			offset: 0,
			searchText: '',
			isError: false,
			isModalOpen: false,

			tab: 1,
			data: {
				name: "",
				landingPrice: "",
				landingPriceGST: "",
				billingPrice: "",
				billingPriceGST: "",
				date: ""
			}
		};
	}

	UNSAFE_componentWillMount() {
		this.getInventory();

	}

	resetPopup = () => {
		let { data } = this.state;
		data = {
			name: "",
			landingPrice: "",
			landingPriceGST: "",
			billingPrice: "",
			billingPriceGST: "",
			date: ""
		}
		this.setState({ data, isError: false });
	}



	redirectToList = () => {
		const { match, history } = this.props;
		this.setState({ offset: 0 }, () => {
			this.getInventory();
		})
		this.resetPopup();
		history.push(`/iventory`);
	}

	getInventory = (id) => {
		const { dispatch } = this.props;
		let data = {
			count: 10,
			offset: this.state.offset
		};
		if (id) {
			data = { labId: id };
		}
		if (this.state.searchText && this.state.searchText.length > 0) {
			data['text'] = this.state.searchText;
		}

		dispatch({
			type: GET_INVENTORY,
			data,
		});

	}

	addInventory = (dataSet) => {
		const { dispatch, match } = this.props;
		dispatch({
			type: ADD_INVENTORY,
			data: dataSet ? dataSet : { ...this.state.data, },
			onSuccess: (() => {
				this.setState({ isModalOpen: false });
				if (!match.params.id) {
					this.resetPopup();
					this.getInventory();
				}
			})
		});
	}

	deleteInventory = (id) => {
		alert('api call')
		this.setState({ isDeleteOpen: false, deleteObj: null })
	}




	handleFormChange = (e, key) => {
		const { data } = this.state;
		data[key] = e.target.value;
		this.setState({ data });
	}

	validateData = () => {
		const { data } = this.state;
		if (data.name === '' || data.landingPrice === '' || data.landingPriceGST === '' || data.billingPrice === '' || data.billingPriceGST === ''
			|| data.date === '') {
			this.setState({ isError: true });
		} else {
			this.addInventory();
			this.setState({ isError: false });

		}
	}

	renderPagination = () => {
		return (
			<div className="mt-30">
				<Grid container spacing={5}>
					<Grid item xs={9} />
					<Grid item xs={3}>
						<Button
							style={{ marginRight: '20px' }}
							size="medium"
							color="primary"
							variant="outlined"
							disabled={this.state.offset === 0}
							onClick={() => {
								this.setState({ offset: this.state.offset - 10 }, () => {

									this.getInventory();

								})
							}}
						>
							{'< Previous'}
						</Button>
						<Button
							size="medium"
							color="primary"
							variant="outlined"
							disabled={!this.props.hasMore}
							onClick={() => {
								this.setState({ offset: this.state.offset + 10 }, () => {

									this.getInventory();

								})
							}}
						>
							{'Next >'}
						</Button>
					</Grid>
				</Grid>
			</div>
		)
	}

	renderFormFields = (data) => {
		const { isError } = this.state;
		return (
			<React.Fragment>
				<Grid container spacing={3}>
					<Grid item xs={6}>
						<TextField fullWidth label="Name"
							value={data.name}
							onChange={(e) => this.handleFormChange(e, 'name')}
							error={isError && data.name === ""}
							helperText={(isError && data.name === "") ? 'Please enter a name' : ' '}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField fullWidth label="Landing Price"
							value={data.landingPrice}
							onChange={(e) => this.handleFormChange(e, 'landingPrice')}
							error={isError && data.landingPrice === ""}
							helperText={(isError && data.landingPrice === "") ? 'Please enter a landing price' : ' '}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={3}>
					<Grid item xs={6}>
						<TextField fullWidth label="Landing Price GST"
							value={data.landingPriceGST}
							onChange={(e) => this.handleFormChange(e, 'landingPriceGST')}
							error={isError && data.landingPriceGST === ""}
							helperText={(isError && data.landingPriceGST === "") ? 'Please enter a landing price gst' : ' '}
						/>
					</Grid>

					<Grid item xs={6}>
						<TextField fullWidth label="Billing Price"
							value={data.billingPrice}
							onChange={(e) => this.handleFormChange(e, 'billingPrice')}
							error={isError && data.billingPrice === ""}
							helperText={(isError && data.billingPrice === "") ? 'Please enter a billing price' : ' '}
						/>
					</Grid>

				</Grid>

				<Grid container spacing={3}>
					<Grid item xs={6}>
						<TextField fullWidth label="Billing Price GST"
							value={data.billingPriceGST}
							onChange={(e) => this.handleFormChange(e, 'billingPriceGST')}
							error={isError && data.billingPriceGST === ""}
							helperText={(isError && data.billingPriceGST === "") ? 'Please enter a billing price GST' : ' '}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField fullWidth label="Date"
							value={data.date}
							onChange={(e) => this.handleFormChange(e, 'date')}
							error={isError && data.date === ""}
							helperText={(isError && data.date === "") ? 'Please enter a date' : ' '}
						/>
					</Grid>
				</Grid>




			</React.Fragment>
		)
	}

	renderPopup = () => {
		const { isModalOpen, data } = this.state
		return (
			<Dialog disableBackdropClick open={isModalOpen} onClose={() => { this.setState({ isModalOpen: false }) }}>
				<DialogTitle id="clinic-popup">{data.isEdit ? 'Edit Inventory' : 'Add New Inventory'}</DialogTitle>
				<DialogContent style={{ width: '600px', height: '260px' }}>
					{this.renderFormFields(data)}
				</DialogContent>
				<DialogActions>
					<Button style={{ backgroundColor: "#00C9AA" }} onClick={this.validateData} variant="contained" color="primary">Save</Button>
					<Button style={{ borderColor: "#00C9AA", color: "#00C9AA" }} onClick={() => { this.setState({ isModalOpen: false }); this.resetPopup() }} variant="outlined" color="primary">Close</Button>
				</DialogActions>
			</Dialog>
		)
	}

	renderDeletePopup = () => {
		const { isDeleteOpen, data, deleteObj } = this.state
		return (
			<Dialog disableBackdropClick open={isDeleteOpen} onClose={() => { this.setState({ isDeleteOpen: false }) }}>
				<DialogTitle id="clinic-popup">Confirm Delete</DialogTitle>
				<DialogContent > Are you sure you want to delete {deleteObj.name} ? </DialogContent>
				<DialogActions>
					<Button style={{ backgroundColor: "#00C9AA" }} onClick={() => { this.deleteInventory(deleteObj.inventoryId) }} variant="contained" color="primary">Yes</Button>
					<Button style={{ borderColor: "#00C9AA", color: "#00C9AA" }} onClick={() => { this.setState({ isDeleteOpen: false, deleteObj: null }) }} variant="outlined" color="primary">No</Button>
				</DialogActions>
			</Dialog>
		)
	}

	renderBasicDetails = () => {
		return (
			<React.Fragment>
				<Grid container spacing={3}>
					<Grid item xs={7}>
						{this.renderFormFields(this.state.data)}
					</Grid>
				</Grid>
				<Grid container spacing={3}>
					<Grid item xs={1}>
						<Button style={{ backgroundColor: "#00C9AA" }} onClick={this.validateData} variant="contained" color="primary">Save</Button>
					</Grid>
				</Grid>
			</React.Fragment>
		)
	}

	render() {
		const { inventory, isLoading, match } = this.props;
		const { isDeleteOpen, isOpen, isModalOpen, } = this.state;
		return (
			<DocContainer>
				<div className="mr-20">
					{isLoading && <ActionLoader />}
					<React.Fragment>
						<Grid item xs={2}>
							<h2 className="mt-0">Inventory</h2>
						</Grid>
						<Grid container spacing={2}>

							<Grid item xs={4}>
							<span className="fa fa-search" />
									<TextField
									fullWidth
										size="small"
										variant="outlined"
										placeholder="Search…"
										value={this.state.searchText}
										onChange={(e) => { this.setState({ searchText: e.target.value }) }}
										onKeyPress={(e) => {
											if ((e.which == 13 || e.keyCode == 13)) {
												this.getInventory();
											}
										}}
									/>
									{this.state.searchText.length > 0 &&
										<span className="cancel-icon"
											onClick={() => { this.setState({ searchText: '' }); this.getInventory(); }}> x </span>
									}
							</Grid>
							<Grid item xs={4}>
							<Button style={{ backgroundColor: "#0D8DA3" }} variant="contained" color="primary" onClick={() => { this.setState({ isModalOpen: !this.state.isModalOpen }) }}>
									Add Inventory
								</Button>
							</Grid>
						</Grid>

						{/* {this.renderPagination() */}

						<TableContainer component={Paper} className="mt-30">
							<Table size="small" aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>Name</TableCell>
										<TableCell>Landing Price</TableCell>
										<TableCell>Landing Price GST</TableCell>
										<TableCell>Billing Price</TableCell>
										<TableCell>Billing Price GST</TableCell>
										<TableCell>Branch Name</TableCell>
										<TableCell>Date</TableCell>
										<TableCell>Action</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{((inventory || []).length <= 0) &&
										<TableRow>
											<TableCell component="th" scope="row"> No Inventory found. </TableCell>
										</TableRow>
									}
									{(inventory || []).map(obj => (
										<TableRow key={obj.inventoryId}>

											<TableCell>{obj.name}</TableCell>
											<TableCell>{obj.landingPrice}</TableCell>
											<TableCell>{obj.lpGST}</TableCell>
											<TableCell>{obj.billingPrice}</TableCell>
											<TableCell>{obj.bpGST}</TableCell>
											<TableCell>{obj.date}</TableCell>



											<TableCell>
												<div style={{ width: '75px' }}>
													<IconButton edge="end" color="inherit" size="small" onClick={() => { this.setState({ isModalOpen: true, data: { ...obj, isEdit: true } }) }}>
														<Edit />
													</IconButton>
													<IconButton edge="end" color="inherit" size="small" onClick={() => { this.setState({ isDeleteOpen: true, deleteObj: obj }) }}>
														<Clear />
													</IconButton>
												</div>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>

						{this.renderPagination()}

						{isModalOpen && this.renderPopup()}

						{isDeleteOpen && this.renderDeletePopup()}
					</React.Fragment>



				</div>
			</DocContainer>
		);
	}
}

const mapStateToProps = ({ inventory }) => {
	return {
		isLoading: inventory.isSubmitting,
		inventory: inventory.inventory,
		hasMore: inventory.hasMore
	}
};

export default withRouter(connect(mapStateToProps)(Inventory));
