import { call, put } from "redux-saga/effects";

import {
  getDataWithToken,
  putDataWithToken,
  postDataWithToken,
  postDataWithPhleboToken,
  deleteDataWithToken,
  uploadFile,
  GET_ALL_ORDERS_URL,
  SEARCH_ORDER_URL,
  GET_ORDER_BY_ID_URL,
  ASSIGN_ORDER_URL,
  ASSIGN_DOCTOR_URL,
  AUTO_ASSIGN_ORDER_URL,
  UNASSIGN_PHLEBO_URL,
  UNASSIGN_DOCTOR_URL,
  ORDER_ACCEPT_REJECT_URL,
  UPDATE_ORDER_URL,
  CREATE_ORDER_URL,
  GET_LGP_ORDERS_URL,
  GET_LGP_ORDERS_ID_URL,
  DOWNLOAD_ORDER_URL,
  UPLOAD_ORDER_URL,
  DELETE_LABREPORT_URL,
  GET_ALL_REPORTS_URL,
  ADD_REPORTS_URL,
  UPLOAD_ASSIGNED_ORDERS_URL,
  GET_STATUS_URL,
  GET_ROUTE_PLAN_URL,
  GET_ROUTE_PLAN_DETAILS_URL,
  GET_DOWNLOAD_REPORTS_URL,
  DOWNLOAD_CLIENT_ORDER_URL,
  DOWNLOAD_CLIENTS_ORDER_URL,
  UPLOAD_CLIENT_ORDER_URL,
  UPLOAD_PROOFS,
  GET_CLIENTS_ORDER_DETAILS_URL,
  GET_PLEBOS_ATTENDANCE_URL,
  GET_PLEBOS_FIRST_SLOTS_URL,
  GET_PLEBOS_LOCATION_STATUS_URL,
  GET_PLEBOS_PROOF_SYNC_URL,
  UPDATE_PLEBOS_ATTENDANCE_URL,
  GET_ALL_TRIPS_URL,
  GET_ALL_TRIPS_BY_NUM_URL,
  GET_ALL_REVIEW_ORDERS_URL,
  SUFFLE_ORDERS_URL,
  CREATE_ROUTE_URL,
  ASSIGN_PLEBOS_ROUTE_URL,
  GET_LIFECYCLE_URL,
  GET_COMPRESSED_URL,
  GET_VITALS_URL,
  GET_VIDEO_TOKEN_URL,
  GET_VIDEO_MER_URL,
  DOWNLOAD_VIDEO_MER_URL,
  GET_GENERATE_PRESIGNED_URL,
  CREATE_VMER_VITALS_URL,
  UPLOAD_PPMC_MER,
  GET_PRESIGNED_URL,
} from "Utils/api";

import {
  ALL_ORDERS,
  ORDER_DATA,
  VITALS_DATA,
  VIDEO_TOKEN,
  VIDEO_MER,
  DOWNLOAD_MER,
  LAB_REPORTS_DATA,
  ROUTE_PLANS,
  ROUTE_PLAN_COUNT,
  // ORDER_DOWNLOAD_LINK,
  IS_SUBMITTING,
  TOAST_ERROR,
  TOAST_SUCCESS,
  GENERATE_PRESIGNED_URL,
} from "Constants/actionConstants";

export function* getAllOrders(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const URL =
      action.data &&
      (action.data.orderSeq ||
        action.data.customerName ||
        action.data.customerContact ||
        action.data.vendorId)
        ? SEARCH_ORDER_URL
        : action.isPartner
        ? GET_LGP_ORDERS_URL
        : GET_ALL_ORDERS_URL;

    const data = yield call(getDataWithToken, URL, action.data);
    yield put({
      type: ALL_ORDERS,
      data: data && data.list ? data.list : [],
      hasMore:
        data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false,
    });
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* getOrderData(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const URL = action.isPartner ? GET_LGP_ORDERS_ID_URL : GET_ORDER_BY_ID_URL;
    const data = yield call(getDataWithToken, URL, action.data);
    yield put({
      type: ORDER_DATA,
      data: data ? data : {},
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* getVitalsData(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const URL = GET_VITALS_URL;
    const data = yield call(getDataWithToken, URL, action.data);
    yield put({
      type: VITALS_DATA,
      data: data ? data : {},
    });
    if (action.onSuccess) {
      
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* getVideoToken(action){
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const URL = GET_VIDEO_TOKEN_URL;
    const data = yield call(getDataWithToken, URL, action.data);
    yield put({
      type: VIDEO_TOKEN,
      data: data ? data : {},
    });
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }

}

export function* generatePresignedUrl(action){
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const URL = GET_GENERATE_PRESIGNED_URL;
    const data = yield call(getDataWithToken, URL, action.data);
    yield put({
      type: GENERATE_PRESIGNED_URL,
      data: data ? data : {},
    });
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }

}

export function* getVideoMer(action){
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const URL = GET_VIDEO_MER_URL;
    const data = yield call(getDataWithToken, URL, action.data);
    yield put({
      type: VIDEO_MER,
      data: data ? data : {},
    });
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }

}

export function* downloadVideoMer(action){
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const URL = DOWNLOAD_VIDEO_MER_URL;
    const data = yield call(getDataWithToken, URL, action.data);
    yield put({
      type: DOWNLOAD_MER,
      data: data ? data : {},
    });
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }

}

export function* autoAssignOrderData(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(putDataWithToken, AUTO_ASSIGN_ORDER_URL, action.data);

    yield put({
      type: TOAST_SUCCESS,
      message:
        data && data.message ? data.message : "Data updated successfully!",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    if(action.onFailure) {
      action.onFailure({});
    }
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* assignOrderData(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(putDataWithToken, ASSIGN_ORDER_URL, action.data);

    yield put({
      type: TOAST_SUCCESS,
      message:
        data && data.message ? data.message : "Data updated successfully!",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* unassignOrder(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(putDataWithToken, UNASSIGN_PHLEBO_URL, action.data);

    yield put({
      type: TOAST_SUCCESS,
      message:
        data && data.message ? data.message : "Data updated successfully!",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* acceptRejectOrder(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(
      putDataWithToken,
      ORDER_ACCEPT_REJECT_URL,
      action.data
    );

    yield put({
      type: TOAST_SUCCESS,
      message:
        data && data.message ? data.message : "Data updated successfully!",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* updateOrder(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(putDataWithToken, UPDATE_ORDER_URL, action.data);

    yield put({
      type: TOAST_SUCCESS,
      message:
        data && data.message ? data.message : "Data updated successfully!",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* createNewOrder(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(postDataWithToken, CREATE_ORDER_URL, action.data);

    yield put({
      type: TOAST_SUCCESS,
      message:
        data && data.message ? data.message : "Data updated successfully!",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* createVmerVitals(action){
  try{
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(postDataWithPhleboToken, CREATE_VMER_VITALS_URL, action.data);
 

    if (action.onSuccess) {
      action.onSuccess(data);
    }
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e){
  }

}

export function* getOrderDownloadLink(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(getDataWithToken, DOWNLOAD_ORDER_URL, action.data);
  

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* uploadOrders(action) {
  try {
    yield put({ type: "SHOW_LOADER" });

    const data = yield call(uploadFile, UPLOAD_ORDER_URL, action.file);

    yield put({
      type: data.status === 400 ? TOAST_ERROR : TOAST_SUCCESS,
      message: data && data.message ? data.message : "Uploaded Successfully",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: "HIDE_LOADER" });
  } catch (e) {
    yield put({ type: "HIDE_LOADER" });

    yield put({
      type: TOAST_ERROR,
      message: e && e.message ? e.message : "Oops! Something Went Wrong",
    });
  }
}

export function* deleteLabReport(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(
      deleteDataWithToken,
      DELETE_LABREPORT_URL,
      action.data
    );

    yield put({
      type: TOAST_SUCCESS,
      message:
        data && data.message ? data.message : "Data updated successfully!",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* getAllLabReports(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(getDataWithToken, GET_ALL_REPORTS_URL, action.data);
    yield put({
      type: LAB_REPORTS_DATA,
      data: data && data.list ? data.list : [],
      hasMore:
        data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false,
    });

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* addLabReports(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(postDataWithToken, ADD_REPORTS_URL, action.data);

    yield put({
      type: TOAST_SUCCESS,
      message:
        data && data.message ? data.message : "Data updated successfully!",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* uploadAssignedOrders(action) {
  try {
    yield put({ type: "SHOW_LOADER" });

    const data = yield call(
      uploadFile,
      UPLOAD_ASSIGNED_ORDERS_URL,
      action.file,
      action.data
    );

    yield put({
      type: data.status === 400 ? TOAST_ERROR : TOAST_SUCCESS,
      message: data && data.message ? data.message : "Uploaded Successfully",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: "HIDE_LOADER" });
  } catch (e) {
    yield put({ type: "HIDE_LOADER" });

    yield put({
      type: TOAST_ERROR,
      message: e && e.message ? e.message : "Oops! Something Went Wrong",
    });
  }
}

export function* getUploadAssignStatus(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(getDataWithToken, GET_STATUS_URL, action.data);
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* getRoutePlan(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(getDataWithToken, GET_ROUTE_PLAN_URL, action.data);
    yield put({
      type: ROUTE_PLANS,
      data: data && data.list ? data.list : [],
      hasMore:
        data && data.areMoreItemsAvailable ? data.areMoreItemsAvailable : false,
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* getRoutePlanCount(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(
      getDataWithToken,
      GET_ROUTE_PLAN_DETAILS_URL,
      action.data
    );
    yield put({
      type: ROUTE_PLAN_COUNT,
      data: data || {},
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* getDownloadReportsLink(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(
      getDataWithToken,
      GET_DOWNLOAD_REPORTS_URL,
      action.data
    );

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* getDownloadClientOrder(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(
      getDataWithToken,
      DOWNLOAD_CLIENT_ORDER_URL,
      action.data
    );

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* uploadProofs(action) {
  try {
    yield put({ type: "SHOW_LOADER" });

    const data = yield call(putDataWithToken, UPLOAD_PROOFS, action.data);

    yield put({
      type: data.status === 400 ? TOAST_ERROR : TOAST_SUCCESS,
      message: data && data.message ? data.message : "Uploaded Successfully",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: "HIDE_LOADER" });
  } catch (e) {
    yield put({ type: "HIDE_LOADER" });

    yield put({
      type: TOAST_ERROR,
      message: e && e.message ? e.message : "Oops! Something Went Wrong",
    });
  }
}

export function* uploadPpmcMer(action) {
  try {
    yield put({ type: "SHOW_LOADER" });

    const data = yield call(putDataWithToken, UPLOAD_PPMC_MER, action.data);

    yield put({
      type: data.status === 400 ? TOAST_ERROR : TOAST_SUCCESS,
      message: data && data.message ? data.message : "Uploaded Successfully",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: "HIDE_LOADER" });
  } catch (e) {
    yield put({ type: "HIDE_LOADER" });

    yield put({
      type: TOAST_ERROR,
      message: e && e.message ? e.message : "Oops! Something Went Wrong",
    });
  }
}

export function* getClientOrderDownloadLink(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(
      getDataWithToken,
      DOWNLOAD_CLIENTS_ORDER_URL,
      action.data
    );
    // yield put({
    //     type: ORDER_DOWNLOAD_LINK,
    //     data: data ? data : {},
    // });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    if(action.onFailure) {
      action.onFailure({});
    }
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* uploadClientOrders(action) {
  try {
    yield put({ type: "SHOW_LOADER" });

    const data = yield call(
      uploadFile,
      UPLOAD_CLIENT_ORDER_URL,
      action.file,
      action.data
    );

    yield put({
      type: data.status === 400 ? TOAST_ERROR : TOAST_SUCCESS,
      message: data && data.message ? data.message : "Uploaded Successfully",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: "HIDE_LOADER" });
  } catch (e) {
    yield put({ type: "HIDE_LOADER" });

    yield put({
      type: TOAST_ERROR,
      message: e && e.message ? e.message : "Oops! Something Went Wrong",
    });
  }
}

export function* getClientOrdersDetails(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(
      getDataWithToken,
      GET_CLIENTS_ORDER_DETAILS_URL,
      action.data
    );
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* getPhlebosProofSync(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(
      getDataWithToken,
      GET_PLEBOS_PROOF_SYNC_URL,
      action.data
    );
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    if (action.onFailure) {
      action.onFailure({});
    }
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* getPhlebosLocationStatus(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(
      getDataWithToken,
      GET_PLEBOS_LOCATION_STATUS_URL,
      action.data
    );
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    if (action.onFailure) {
      action.onFailure({});
    }
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* getPhlebosFirstSlot(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(
      getDataWithToken,
      GET_PLEBOS_FIRST_SLOTS_URL,
      action.data
    );
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    if (action.onFailure) {
      action.onFailure({});
    }
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* getPhlebosAttendance(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(
      getDataWithToken,
      GET_PLEBOS_ATTENDANCE_URL,
      action.data
    );
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    if (action.onFailure) {
      action.onFailure({});
    }
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* updatePhlebosAttendance(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(
      postDataWithToken,
      UPDATE_PLEBOS_ATTENDANCE_URL,
      action.data
    );
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    if (action.onFailure) {
      action.onFailure({});
    }
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
    if (action.onFailure) {
      action.onFailure([]);
    }
  }
}

export function* getAllTrips(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(
      getDataWithToken,
      GET_ALL_TRIPS_URL,
      action.data
    );
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    if (action.onFailure) {
      action.onFailure({});
    }
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* getAllTripsByNum(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(
      getDataWithToken,
      GET_ALL_TRIPS_BY_NUM_URL,
      action.data
    );
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    if (action.onFailure) {
      action.onFailure({});
    }
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}


export function* getAllReviewOrders(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(
      getDataWithToken,
      GET_ALL_REVIEW_ORDERS_URL,
      action.data
    );
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    if (action.onFailure) {
      action.onFailure({});
    }
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* assignPlebosRoute(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    
    const data = yield call(
      postDataWithToken,
      ASSIGN_PLEBOS_ROUTE_URL,
      action.data
    );
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    if (action.onFailure) {
      action.onFailure({});
    }
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}


export function* shuffleOrders(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(
      postDataWithToken,
      SUFFLE_ORDERS_URL,
      action.data
    );
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    if (action.onFailure) {
      action.onFailure({});
    }
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}


export function* createRoute(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(
      postDataWithToken,
      CREATE_ROUTE_URL,
      action.data
    );
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    if (action.onFailure) {
      action.onFailure({});
    }
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* getLifecycle(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(
      getDataWithToken,
      GET_LIFECYCLE_URL,
      action.data
    );
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    if (action.onFailure) {
      action.onFailure({});
    }
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* getCompressed(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(
      getDataWithToken,
      GET_COMPRESSED_URL,
      action.data
    );
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    if (action.onFailure) {
      action.onFailure({});
    }
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* assignDoctor(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(putDataWithToken, ASSIGN_DOCTOR_URL, action.data);

    yield put({
      type: TOAST_SUCCESS,
      message:
        data && data.message ? data.message : "Data updated successfully!",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* unassignDoctor(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(putDataWithToken, UNASSIGN_DOCTOR_URL, action.data);

    yield put({
      type: TOAST_SUCCESS,
      message:
        data && data.message ? data.message : "Data updated successfully!",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* getPresignedUrl(action){
    try {
      yield put({ type: IS_SUBMITTING, isSubmitting: true });
      const data = yield call(
        getDataWithToken,
        GET_PRESIGNED_URL,
        action.data
      );
      if (action.onSuccess) {
        action.onSuccess(data);
      }
  
      yield put({ type: IS_SUBMITTING, isSubmitting: false });
    } catch (e) {
      if (action.onFailure) {
        action.onFailure({});
      }
      yield put({ type: IS_SUBMITTING, isSubmitting: false });
      yield put({
        type: TOAST_ERROR,
        message: e && e.error ? e.error : "Oops! Something went wrong",
      });
    }
 }