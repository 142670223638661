import { call, put } from "redux-saga/effects";

import {
  getDataWithToken,
  putDataWithToken,
  postDataWithToken,
  deleteDataWithToken,
  uploadFile,
  CREATE_ROUTE_PLAN_AUTO_URL,
  GET_AUTO_ROUTE_PLANS_URL,
  GET_ALL_SLOT_BOOKINGS_URL,
  CREATE_SLOTS_URL,
  UPDATE_CONFIG_SLOTS_URL,
  GET_SLOTS_STATUS_URL,
  GET_ALL_SLOTS_STATUS_URL,
  GET_BO_ATTENDANCE_URL,
  SUBMIT_BO_ATTENDANCE_URL,
  GET_ALL_USERS_URL,
} from "Utils/api";

import {
  IS_SUBMITTING,
  TOAST_ERROR,
  TOAST_SUCCESS,
} from "Constants/actionConstants";

export function* createAutoRoutePlan(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(
      postDataWithToken,
      CREATE_ROUTE_PLAN_AUTO_URL,
      action.data
    );

    yield put({
      type: TOAST_SUCCESS,
      message: data && data.message ? data.message : "Created successfully!",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      noHide: true,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
    if (action.onFailure) {
      action.onFailure({});
    }
  }
}

export function* getAllAutoRoutePlan(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const URL = GET_AUTO_ROUTE_PLANS_URL;

    const data = yield call(getDataWithToken, URL, action.data);
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      noHide: true,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* getAllSlotBookings(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const URL = GET_ALL_SLOT_BOOKINGS_URL;

    const data = yield call(getDataWithToken, URL, action.data);
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      noHide: true,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* createSlots(action){
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const URL = CREATE_SLOTS_URL;

    const data = yield call(getDataWithToken, URL, action.data);
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      noHide: true,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* slotStatus(action){
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const URL = GET_SLOTS_STATUS_URL;

    const data = yield call(getDataWithToken, URL, action.data);
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      noHide: true,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  } 
}

export function* getAllSlotStatus(action){
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const URL = GET_ALL_SLOTS_STATUS_URL;

    const data = yield call(getDataWithToken, URL, action.data);
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      noHide: true,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  } 
}

export function* getTechUsers(action) {
  try {
    const url = GET_ALL_USERS_URL;
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(getDataWithToken, url, action.data);
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* updateConfigSlot(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(postDataWithToken, UPDATE_CONFIG_SLOTS_URL, action.data);

    yield put({
      type: TOAST_SUCCESS,
      message: data && data.message ? data.message : "Updated successfully!",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      noHide: true,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
    if (action.onFailure) {
      action.onFailure({});
    }
  }
}

export function* getBoAttendance(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const URL = GET_BO_ATTENDANCE_URL;

    const data = yield call(getDataWithToken, URL, action.data);
    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      noHide: true,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
  }
}

export function* submitBoAttendance(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(postDataWithToken, SUBMIT_BO_ATTENDANCE_URL, action.data);

    yield put({
      type: TOAST_SUCCESS,
      message: data && data.message ? data.message : "Updated successfully!",
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      noHide: true,
      message: e && e.error ? e.error : "Oops! Something went wrong",
    });
    if (action.onFailure) {
      action.onFailure({});
    }
  }
}