import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
  TOAST_HIDE_TIME
} from 'Constants/commonConstants';
import Toast from 'Components/Toast';
import 'Styles/app.scss';
import "react-datepicker/dist/react-datepicker.css";

import { 
  HIDE_TOAST
} from 'Constants/actionConstants';


class App extends Component {

  closeToast = () => {
    const { dispatch } = this.props;
    dispatch({
      type: HIDE_TOAST
    });
  }

  render() {
    const { dispatch } = this.props;
    const { toast } = this.props;
    this.timer = null;
    if(toast.type && toast.message) {
      if(this.timer) {
        clearTimeout(this.timer);
      }
      if(!toast.noHide) {
        this.timer = setTimeout(()=> {
          dispatch({
            type: HIDE_TOAST
          });
        }, TOAST_HIDE_TIME*1000);
      }
    }
    return (
      <div className="rootDiv pr">
        <Toast type={toast.type} showClose={toast.noHide} onClose={this.closeToast} message={toast.message}/>
        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = ({common}) => {
  return {
    toast: common.toast
  }
};

export default connect(mapStateToProps)(App);
