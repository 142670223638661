import { call, put } from 'redux-saga/effects';

import {
  uploadFile,
  getDataWithToken,
  postDataWithToken,
  UPLOAD_IMAGE_URL,
  MULTIPLE_IMAGE_UPLOAD_URL,
  UPLOAD_CMS_IMAGE_URL,
  GENERIC_CONSTANTS_URL,
  GET_ALL_STATES_URL,
  VIDEO_UPLOAD_URL,
  AUDIO_UPLOAD_URL,
  REPORTS_UPLOAD_URL,
} from 'Utils/api';

import {
  UPLOADED_IMG,
  ALL_CONSTANTS,
  ALL_STATES,
  IS_SUBMITTING,
  TOAST_ERROR,
  TOAST_SUCCESS,
} from 'Constants/actionConstants';

export function* uploadCmsImage(action) {
  try {
    const url = UPLOAD_CMS_IMAGE_URL;
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(uploadFile, url, action.file, action.data);
    yield put({ type: IS_SUBMITTING, isSubmitting: false });

    yield put({
      type: UPLOADED_IMG,
      data: data && data.url ? data.url : null,
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : 'Oops! Something went wrong',
    });
  }
}

export function* uploadImage(action) {
  try {
    const url = UPLOAD_IMAGE_URL; //action.file && action.file.length > 1 ? MULTIPLE_IMAGE_UPLOAD_URL : UPLOAD_IMAGE_URL;
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(uploadFile, url, action.file, action.params || {});
    yield put({ type: IS_SUBMITTING, isSubmitting: false });

    yield put({
      type: UPLOADED_IMG,
      data: data && data.url ? data.url : null,
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : 'Oops! Something went wrong',
    });
  }
}

export function* uploadVideo(action) {
  try {
    const url = VIDEO_UPLOAD_URL;
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(uploadFile, url, action.file);
    yield put({ type: IS_SUBMITTING, isSubmitting: false });

    yield put({
      type: UPLOADED_IMG,
      data: data && data.url ? data.url : null,
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : 'Oops! Something went wrong',
    });
  }
}

export function* uploadAudio(action) {
  try {
    const url = AUDIO_UPLOAD_URL;
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(uploadFile, url, action.file);
    yield put({ type: IS_SUBMITTING, isSubmitting: false });

    yield put({
      type: UPLOADED_IMG,
      data: data && data.url ? data.url : null,
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : 'Oops! Something went wrong',
    });
  }
}

export function* uploadReports(action) {
  try {
    const url = REPORTS_UPLOAD_URL;
    yield put({ type: IS_SUBMITTING, isSubmitting: true });
    const data = yield call(uploadFile, url, action.file);
    yield put({ type: IS_SUBMITTING, isSubmitting: false });

    yield put({
      type: UPLOADED_IMG,
      data: data && data.url ? data.url : null,
    });

    if (action.onSuccess) {
      action.onSuccess(data);
    }
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : 'Oops! Something went wrong',
    });
  }
}

export function* getAllConstants(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(
      getDataWithToken,
      GENERIC_CONSTANTS_URL,
      action.data
    );

    const constants =
      data && data.genericConstants ? data.genericConstants : [];

    const cities = constants.filter((obj) => {
      return obj.key === 'CITIES';
    });

    const salaries = constants.filter((obj) => {
        return obj.key === 'SALARY';
      });


    yield put({
      type: ALL_CONSTANTS,
      data: {
        cities: cities.length > 0 ? cities[0].value : [],
        salaries: salaries.length > 0 ? JSON.parse(salaries[0].value) : {},
      },
    });

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : 'Oops! Something went wrong',
    });
  }
}

export function* getAllStates(action) {
  try {
    yield put({ type: IS_SUBMITTING, isSubmitting: true });

    const data = yield call(getDataWithToken, GET_ALL_STATES_URL, action.data);

    yield put({
      type: ALL_STATES,
      data: data && data.list ? data.list : [],
    });

    yield put({ type: IS_SUBMITTING, isSubmitting: false });
  } catch (e) {
    yield put({ type: IS_SUBMITTING, isSubmitting: false });
    yield put({
      type: TOAST_ERROR,
      message: e && e.error ? e.error : 'Oops! Something went wrong',
    });
  }
}
