import { call, put } from "redux-saga/effects";

import { GET_VIDEO_TOKEN_URL } from "Utils/api";
import { VIDEO_MER_TOKEN } from "Constants/actionConstants";

export function* getVideoMerToken(action){
    try {
      yield put({ type: IS_SUBMITTING, isSubmitting: true });
      const URL = GET_VIDEO_TOKEN_URL;
      const data = yield call(getDataWithToken, URL, action.data);
      yield put({
        type: VIDEO_MER_TOKEN,
        data: data ? data : {},
      });
      if (action.onSuccess) {
        action.onSuccess(data);
      }
  
      yield put({ type: IS_SUBMITTING, isSubmitting: false });
    } catch (e) {
      yield put({ type: IS_SUBMITTING, isSubmitting: false });
      yield put({
        type: TOAST_ERROR,
        message: e && e.error ? e.error : "Oops! Something went wrong",
      });
    }
  
  }