import { 
  LGP_LIST,
  PLEBO_KYC_LIST,
  CONTRACT_DATA,
  DOCTORS_LIST_DATA,
  IS_SUBMITTING,
} from 'Constants/actionConstants';


const lgp = (state={
  
  isSubmitting: false,
  plebosKYC: [],
  plebos: [],
  contract:{}

}, action)=> {

  switch (action.type) {
    
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };

    case LGP_LIST:
      return {
        ...state,
        plebos: action.data,
        hasMore: action.hasMore,
      };

    case PLEBO_KYC_LIST:
      return {
        ...state,
        plebosKYC: action.data,
        hasMore: action.hasMore,
      };
      
    case CONTRACT_DATA:
      return {
        ...state,
        contract: action.data,
        hasMore: action.hasMore,
      };

    case DOCTORS_LIST_DATA:
      return {
        ...state,
        doctorsList: action.data,
      };

    default:
      return state;
  }

}

export default lgp;
