import React from "react";
import { Map, GoogleApiWrapper, Marker, Polyline } from "google-maps-react";

const MapContainer = ({ google, locations = [] }) => {
  return (
    <Map
      google={google}
      containerStyle={{
        width: "25%",
        height: "60%",
      }}
      center={locations[0]}
      initialCenter={locations[0]}
      zoom={locations.length === 1 ? 18 : 13}
    >
      {locations.map((coords, i) => (
        <Marker
          position={coords}
          label={{ text: `${i + 1}`, color: "white" }}
        />
      ))}
      <Polyline
        path={locations}
        geodesic={true}
        options={{
          strokeColor: "#21A3F7",
          strokeOpacity: 1,
          strokeWeight: 5,
        }}
      />
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_WRAPPER_API,
})(MapContainer);
