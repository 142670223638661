import React, { Component } from 'react';
import { connect } from 'react-redux';
import './clinics.css';

import DocContainer from './../../containers/DocContainer';
import ActionLoader from "../../components/ActionLoader";

import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	Dialog, DialogTitle, DialogContent, DialogActions,
	Select,
	Paper,
	Button,
	Grid,
	TextField,
	IconButton,
	Typography,
	Switch,
	Avatar,
	Tabs,
	Tab,
	Box,
	FormLabel,
	MenuItem,
	InputAdornment,
} from '@material-ui/core';

import {
	GET_RUNNERS,
	ADD_RUNNERS,
	DELETE_RUNNERS,

	GET_RUNNERS_KYC,
	ADD_RUNNERS_KYC,
	DELETE_RUNNERS_KYC,

	UPLOAD_IMAGE,
	GET_ALL_STATES,
	GET_ALL_CONSTANTS,
} from './../../constants/actionConstants';
import { Edit, Clear, Visibility, ArrowBackOutlined } from '@material-ui/icons';
import { RUNNER_TYPE } from 'Constants/commonConstants';




function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`nav-tabpanel-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</Typography>
	);
}


class Runner extends Component {
	constructor(props) {
		super(props);
		this.state = {
			offset: 0,
			searchText: '',
			isError: false,
			isOpen: false,
			kycUrl: '',
			kycDocumentType: '',
			tab: 1,
			city:'',
			status:'Working',
			data: {
				runnerId:'',
				isIDAutoGenerate: true,
				firstName: '',
				lastName: '',
				mobileNumber: '',
				alternateMobileNumber: '',
				emailId: '',
				imageUrl: '',
				isActive: true,
				username: '',
				password: '',
				isInHouse: true,
				isDedicated: false,
				address1: '',
				address2: '',
				city: '',
				state: '',
				zipcode: '',
				runnerType: 0,
				vendorName: '',
				working_status: '',
			}
		};
	}

	UNSAFE_componentWillMount() {
		this.getRunner();
		this.getAllConstants();
		this.getAllStates();
	}

	resetPopup = () => {
		let { data } = this.state;
		data = {
			firstName: '',
			lastName: '',
			mobileNumber: '',
			alternateMobileNumber: '',
			emailId: '',
			imageUrl: '',
			isActive: true,
			username: '',
			password: '',
			isInHouse: true,
			isDedicated: false,
			address1: '',
			address2: '',
			city: '',
			state: '',
			zipcode: '',
		};
		this.setState({ data, isError: false });
	}

	redirectToDetail = (runner) => {
		const { history } = this.props;
		this.setState({
			offset: 0,
			data: {
				...runner,
				isEdit: true
			}
		}, () => {
			this.getRunnerKYC();
		})
		history.push(`/runner/${runner.runnerId}`, { state: runner });
	}

	redirectToList = () => {
		const { history } = this.props;
		this.setState({ offset: 0 }, () => {
			this.getRunner();
		})
		this.resetPopup();
		history.push(`/runner`);
	}

	getAllConstants = () => {
		const { dispatch } = this.props;
		dispatch({
			type: GET_ALL_CONSTANTS
		});
	}

	getAllStates = () => {
		const { dispatch } = this.props;
		dispatch({
			type: GET_ALL_STATES
		});
	}

	getRunner = (id) => {
		const { dispatch } = this.props;
		let data = {
			count: 10,
			offset: this.state.offset
		};
		if (id) {
			data = { runnerId: id };
		}
		if (this.state.searchText && this.state.searchText.length > 0) {
			data['text'] = this.state.searchText;
		}
		if (this.state.city && this.state.city.length > 0) {
			data['city'] = this.state.city;
		}
		if (this.state.status && this.state.status.length > 0) {
			data['status'] = this.state.status;
		}

		dispatch({
			type: GET_RUNNERS,
			data,
		});
	}

	addRunner = (dataSet) => {
		const { dispatch, match } = this.props;
		dispatch({
			type: ADD_RUNNERS,
			data: dataSet ? dataSet : this.state.data,
			onSuccess: (() => {
				this.setState({ isOpen: false });
				if (!match.params.id) {
					this.resetPopup();
					this.getRunner();
				}
			})
		});
	}

	deleteRunner = (id) => {
		const { dispatch } = this.props;
		dispatch({
			type: DELETE_RUNNERS,
			data: { runnerId: id },
			onSuccess: (() => {
				this.setState({ isDeleteOpen: false, deleteObj: null });
				this.getRunner();
			})
		});
	}

	getRunnerKYC = () => {
		const { dispatch, match } = this.props;
		let data = {
			count: 10,
			offset: this.state.offset,
			runnerId: match.params.id
		};

		dispatch({
			type: GET_RUNNERS_KYC,
			data,
		});
	}

	addRunnerKYC = () => {
		const { dispatch, match } = this.props;
		const { kycUrl, kycDocumentType } = this.state;
		dispatch({
			type: ADD_RUNNERS_KYC,
			data: { url: kycUrl, documentType: kycDocumentType, runnerId: match.params.id },
			onSuccess: (() => {
				this.getRunnerKYC();
			})
		});
	}

	deleteRunnerKYC = (id) => {
		const { dispatch, match } = this.props;
		dispatch({
			type: DELETE_RUNNERS_KYC,
			data: { documentId: id, runnerId: match.params.id },
			onSuccess: (() => {
				this.getRunnerKYC();
			})
		});
	}

	uploadImage = (file, isAdd) => {
		const { dispatch } = this.props;
		dispatch({
			type: UPLOAD_IMAGE,
			file,
			params: { type: 16 },
			onSuccess: ((res) => {
				if (res && res.imageUrl) {
					if (isAdd) {
						const { data } = this.state;
						data.imageUrl = res.imageUrl;
						this.setState({ data });
					} else {
						this.setState({ kycUrl: res.imageUrl });
					}
				}
			})
		})
	}

	handleFormChange = (e, key) => {
		const { data } = this.state;
		data[key] = e.target.value;
		this.setState({ data });
	}

	validateData = () => {
		const { data } = this.state;
		if (data.firstName === '' || data.lastName === '' || data.mobileNumber === '' || data.address1 === '' || data.username === '' || data.password === '' || data.city === '' || data.state === '') {
			this.setState({ isError: true });
		} else {
			this.setState({ isError: false });
			this.addRunner();
		}
	}

	renderPagination = (isKYC) => {
		return (
			<div className="mt-30">
				<Grid container spacing={5}>
					<Grid item xs={9} />
					<Grid item xs={3}>
						<Button
							style={{ marginRight: '20px' }}
							size="medium"
							color="primary"
							variant="outlined"
							disabled={this.state.offset === 0}
							onClick={() => {
								this.setState({ offset: this.state.offset - 10 }, () => {
									if (isKYC) {
										this.getRunnerKYC();
									} else {
										this.getRunner();
									}
								})
							}}
						>
							{'< Previous'}
						</Button>
						<Button
							size="medium"
							color="primary"
							variant="outlined"
							disabled={!this.props.hasMore}
							onClick={() => {
								this.setState({ offset: this.state.offset + 10 }, () => {
									if (isKYC) {
										this.getRunnerKYC();
									} else {
										this.getRunner();
									}
								})
							}}
						>
							{'Next >'}
						</Button>
					</Grid>
				</Grid>
			</div>
		)
	}

	renderFormFields = (data) => {
		const { isError } = this.state;
		return (
			<React.Fragment>
				<Grid container spacing={3}>
					<Grid item xs={6}>
						<TextField fullWidth label="First Name"
							value={data.firstName}
							onChange={(e) => this.handleFormChange(e, 'firstName')}
							error={isError && data.firstName === ""}
							helperText={(isError && data.firstName === "") ? 'Please enter a first name' : ' '}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField fullWidth label="Last Name"
							value={data.lastName}
							onChange={(e) => this.handleFormChange(e, 'lastName')}
							error={isError && data.lastName === ""}
							helperText={(isError && data.lastName === "") ? 'Please enter a last name' : ' '}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={3}>
					<Grid item xs={6}>
						<TextField fullWidth label="Phone number"
							value={data.mobileNumber}
							error={isError && data.mobileNumber === ""}
							helperText={(isError && data.mobileNumber === "") ? 'Please enter phone no.' : ' '}
							onChange={(e) => this.handleFormChange(e, 'mobileNumber')}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField fullWidth label="Alternate Number"
							value={data.alternateMobileNumber}
							onChange={(e) => this.handleFormChange(e, 'alternateMobileNumber')}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={3}>
					<Grid item xs={6}>
						<TextField
							fullWidth
							value={data.emailId}
							label="Email ID"
							onChange={(e) => this.handleFormChange(e, 'emailId')}
						// error={isError && data.emailId===""}
						// helperText={(isError && data.emailId === "") ? 'Please enter address' : ' '}
						/>
					</Grid>
					<Grid item xs={6}>
						<input type="file"
							className="inputFileUpload"
							onChange={(e) => { this.uploadImage(e.target.files, true) }}
							ref={(ref) => { this.input = ref; }}
						/>
						{
							data.imageUrl && data.imageUrl.length > 0 &&
							<a href={data.imageUrl} style={{ color: 'blue', fontSize: '10px' }}>
								<Avatar size="small" src={data.imageUrl} alt="icon" />
							</a>
						}
					</Grid>
				</Grid>

				<Grid container spacing={3}>
					<Grid item xs={6}>
						<TextField
							fullWidth
							value={data.username}
							label="Username"
							onChange={(e) => this.handleFormChange(e, 'username')}
							error={isError && data.username === ""}
							helperText={(isError && data.username === "") ? 'Please enter username' : ' '}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							type="password"
							label="Password"
							value={data.password}
							onChange={(e) => this.handleFormChange(e, 'password')}
							error={isError && data.password === ""}
							helperText={(isError && data.password === "") ? 'Please enter password' : ' '}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={3}>
					<Grid item xs={6}>
						<TextField
							fullWidth
							label="Adress 1"
							value={data.address1}
							onChange={(e) => this.handleFormChange(e, 'address1')}
							error={isError && data.address1 === ""}
							helperText={(isError && data.address1 === "") ? 'Please enter address' : ' '}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							label="Address 2"
							value={data.address2}
							onChange={(e) => this.handleFormChange(e, 'address2')}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={3}>
					<Grid item xs={6}>
						<label> City </label>
						<Select fullWidth
							value={data.city}
							onChange={(e) => { this.handleFormChange(e, 'city') }}
						>
							{(this.props.cities || []).map((city, index) => (
								<option key={`city-${index}`} value={city}> {city} </option>
							))}
						</Select>
						{(isError && data.city === "") ? <div style={{ color: 'red', letterSpacing: '1px', fontSize: '10px' }}>Please Select City</div> : ''}
					</Grid>
					<Grid item xs={6}>
						<label> State </label>
						<Select fullWidth
							value={data.state}
							onChange={(e) => { this.handleFormChange(e, 'state') }}
						>
							{(this.props.states || []).map((state, index) => (
								<option key={`state-${state.id}`} value={state.name}> {state.name} </option>
							))}
						</Select>
						{(isError && data.state === "") ? <div style={{ color: 'red', letterSpacing: '1px', fontSize: '10px' }}>Please Select State</div> : ''}
					</Grid>
				</Grid>

				<Grid container spacing={3}>
					<Grid item xs={6}>
						<TextField
							fullWidth
							InputProps={{ inputProps: { minLength: 6, maxLength: 6 } }}
							label="Zipcode"
							value={data.zipcode}
							onChange={(e) => this.handleFormChange(e, 'zipcode')}
						/>
					</Grid>

					<Grid item xs={6}>
						<h2 className='mt-0 boxed-select'>
							<FormLabel component='legend' className='text-label'>
								Runner Type<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
							</FormLabel>
							<Select
								fullWidth
								value={data.runnerType || ""}
								error={isError && data.state === ''}
								onChange={(e) => {
									this.handleFormChange(e, "runnerType");
									this.setState({ runnerTypeNum: e.target.value })
								}}
							>
								{(RUNNER_TYPE || []).map((status, index) => (
									<option key={`mode-${status.id}`} value={status.id}>
										{" "}
										{status.name}{" "}
									</option>
								))}
							</Select>
							<p style={{ fontSize: '10px', color: 'red' }}>{isError && data.state === '' && 'Please Select Doctor Type.'}</p>
						</h2>
					</Grid>
					{this.state.runnerTypeNum === 3 ? <Grid item xs={6}>
						<h2 className='mt-0'>
							<FormLabel component='legend' className='text-label'>
								Vendor Name
							</FormLabel>
							<TextField
								fullWidth
								variant='outlined'
								value={data.vendorName}
								onChange={(e) => this.handleFormChange(e, 'vendorName')}
							/>
						</h2>
					</Grid> : null}

					<Grid item xs={6}>
						<label>Is Active?</label>
						<Switch color="primary" name="isActive" size="small"
							checked={data.isActive}
							onChange={(e) => {
								const { data } = this.state;
								data.isActive = e.target.checked;
								this.setState({ data });
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<h2 className='mt-0 boxed-select'>
							<FormLabel component='legend' className='text-label'>
								Working Status<span style={{ color: 'red', fontSize: '15px', paddingLeft: '5px' }}>*</span>
							</FormLabel>
							<Select
								style={{ width: 100 }}
								value={data.working_status || ''}
								onChange={(e) => {
									this.handleFormChange(e, "working_status");
								}}
								error={isError && data.working_status === ''}
							>
								<MenuItem value=''>
									<em>None</em>
								</MenuItem>
								<MenuItem value={'Working'}>Working</MenuItem>
								<MenuItem value={'Resigned'}>Resigned</MenuItem>
								<MenuItem value={'Terminated'}>Terminated</MenuItem>
								<MenuItem value={'Abscond'}>Abscond</MenuItem>
								<MenuItem value={'On-Hold'}>On-Hold</MenuItem>
							</Select>
							<p style={{ fontSize: '10px', color: 'red' }}>{isError && data.working_status === '' && 'Please select working status.'}</p>

						</h2>
					</Grid>
				</Grid>

				<Grid container spacing={3}>
					<Grid item xs={6}>
						<label>Is In House?</label>
						<Switch color="primary" name="isInHouse" size="small"
							checked={data.isInHouse}
							onChange={(e) => {
								const { data } = this.state;
								data.isInHouse = e.target.checked;
								this.setState({ data });
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<label>Is Dedicated?</label>
						<Switch color="primary" name="isDedicated" size="small"
							checked={data.isDedicated}
							onChange={(e) => {
								const { data } = this.state;
								data.isDedicated = e.target.checked;
								this.setState({ data });
							}}
						/>
					</Grid>
				</Grid>
			</React.Fragment>
		)
	}

	renderPopup = () => {
		const { isOpen, data } = this.state
		return (
			<Dialog disableBackdropClick open={isOpen} onClose={() => { this.setState({ isOpen: false }) }}>
				<DialogTitle id="clinic-popup">{data.isEdit ? 'Edit Runner' : 'Create New Runner'}</DialogTitle>
				<DialogContent style={{ width: '600px', height: '400px' }}>
					{this.renderFormFields(data)}
				</DialogContent>
				<DialogActions>
					<Button style={{ backgroundColor: "#00C9AA" }} onClick={this.validateData} variant="contained" color="primary">Save</Button>
					<Button style={{ borderColor: "#00C9AA", color: "#00C9AA" }} onClick={() => { this.setState({ isOpen: false }); this.resetPopup() }} variant="outlined" color="primary">Close</Button>
				</DialogActions>
			</Dialog>
		)
	}

	renderDeletePopup = () => {
		const { isDeleteOpen, data, deleteObj } = this.state
		return (
			<Dialog disableBackdropClick open={isDeleteOpen} onClose={() => { this.setState({ isDeleteOpen: false }) }}>
				<DialogTitle id="clinic-popup">Confirm Delete</DialogTitle>
				<DialogContent > Are you sure you want to delete {deleteObj.name} ? </DialogContent>
				<DialogActions>
					<Button style={{ backgroundColor: "#00C9AA" }} onClick={() => { this.deleteRunner(deleteObj.runnerId) }} variant="contained" color="primary">Yes</Button>
					<Button style={{ borderColor: "#00C9AA", color: "#00C9AA" }} onClick={() => { this.setState({ isDeleteOpen: false, deleteObj: null }) }} variant="outlined" color="primary">No</Button>
				</DialogActions>
			</Dialog>
		)
	}

	renderKYCTable = () => {
		const { runnerKYC } = this.props;
		return (
			<TableContainer component={Paper} className="mb-30">
				<Table size="small" aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Type</TableCell>
							<TableCell>Link</TableCell>
							<TableCell>Action</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{((runnerKYC || []).length <= 0) &&
							<TableRow>
								<TableCell component="th" scope="row"> No Documents found. </TableCell>
							</TableRow>
						}
						{(runnerKYC || []).map((runner, index) => (
							<TableRow key={`kyc-${runner.documentId}-${index}`}>
								<TableCell>{runner.documentType}</TableCell>
								<TableCell>
									<a href={runner.url} download style={{ fontSize: '12px', color: 'blue' }}>
										<Avatar alt="img" size="small" src={runner.url} />
									</a>
								</TableCell>
								<TableCell>
									<IconButton edge="end" color="inherit" onClick={() => { this.deleteRunnerKYC(runner.documentId) }}>
										<Clear />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		)
	}

	renderKYCTab = (isKYC) => {
		return (
			<React.Fragment>
				<Grid container spacing={3}>
					<Grid item xs={4}>
						<h2 className="mt-0">KYC DOCUMENTS</h2>
					</Grid>
					<Grid item>
						<Button style={{ backgroundColor: "#00C9AA" }} variant="contained" color="primary" onClick={() => { this.setState({ isAddDoc: !this.state.isAddDoc }) }}>
							Add Document
						</Button>
					</Grid>
				</Grid>
				{this.state.isAddDoc &&
					<Grid container spacing={3}>
						<Grid item xs={4}>
							<TextField
								fullWidth
								label="Type"
								value={this.state.kycDocumentType}
								onChange={(e) => { this.setState({ 'kycDocumentType': e.target.value }) }}
							/>
						</Grid>
						<Grid item xs={1} />
						<Grid item xs={4}>
							<input type="file"
								className="inputFileUpload"
								onChange={(e) => { this.uploadImage(e.target.files) }}
								ref={(ref) => { this.input = ref; }}
							/>
							{
								this.state.kycUrl && this.state.kycUrl.length > 0 &&
								<a href={this.state.kycUrl} style={{ color: 'blue', fontSize: '10px' }}>
									<Avatar src={this.state.kycUrl} alt="img" size="small" />
								</a>
							}
						</Grid>
						<Grid item xs={3}>
							<Button style={{ backgroundColor: "#00C9AA" }} onClick={this.addRunnerKYC} variant="contained" color="primary">Save</Button>
							<Button style={{ borderColor: "#00C9AA", color: "#00C9AA" }} onClick={() => { this.setState({ kycUrl: '', kycDocumentType: '', isAddDoc: false }); }} variant="outlined" color="primary">Close</Button>
						</Grid>
					</Grid>
				}

				{this.renderKYCTable()}

				{this.renderPagination(isKYC)}
			</React.Fragment>
		)
	}

	renderBasicDetails = (isKYC) => {
		return (
			<React.Fragment>
				<Grid container spacing={3}>
					<Grid item xs={7}>
						{this.renderFormFields(this.state.data)}
					</Grid>
				</Grid>
				<Grid container spacing={3}>
					<Grid item xs={1}>
						<Button style={{ backgroundColor: "#00C9AA" }} onClick={this.validateData} variant="contained" color="primary">Save</Button>
					</Grid>
				</Grid>
			</React.Fragment>
		)
	}

	render() {
		const { runner, isLoading, match } = this.props;
		const { isOpen, data, kycUrl, kycDocumentType, isDeleteOpen } = this.state;
		let isKYC = false;
		if (match.params && match.params.id) {
			isKYC = true;
		}

		return (
			<DocContainer>
				<div className="mr-20">
					{isLoading && <ActionLoader />}
					{!isKYC ?
						<React.Fragment>
							<Grid item xs={12}>
								<h2 className="mt-0">Runner</h2>
							</Grid>
							<Grid container spacing={3}>

								<Grid item xs={3}>
									<span className="fa fa-search" />
									<TextField
									fullWidth
										size="small"
										variant="outlined"
										placeholder="Search…"
										value={this.state.searchText}
										onChange={(e) => {
											this.setState({ searchText: e.target.value }, () => {
												this.getRunner();
											});
											
										  }}
										  InputProps={{
											endAdornment: (
											  <InputAdornment position="end">
											   {this.state.searchText ? <IconButton
												   onClick={() => {
													this.setState({ searchText: "" }, () => {
														this.getRunner();
													});
												  }}
												>
												<Clear/>
												</IconButton> : null }
											  </InputAdornment>
											),
										  }}
										
									/>
									{this.state.searchText.length > 0 &&
										<span className="cancel-icon"
											onClick={() => { this.setState({ searchText: '' }, () => { this.getRunner(); }) }}> x </span>
									}
								</Grid>
								<Grid item xs={3} style={{ width: 130 }}>
									<div
										className="cityLabel"
										style={{
											display: "inline-block",
											verticalAlign: "top",
											marginRight: "10px",
											padding: "8px 0",
											fontSize: "15px",
										}}
									>
										{" "}
										City:{" "}
									</div>
									<Select
										size="small"
										style={{ width: "130px" }} //fullWidth
										value={this.state.city}
										label="City"
										onChange={(e) => {
											this.setState(
												{
													city: e.target.value,
												},
												() => {
													this.getRunner();
												}
											);
										}}
									>
										<MenuItem value="">
											<em>None</em>
										</MenuItem>
										{(this.props.cities || []).map((city, index) => (
											<MenuItem key={`city-${index}`} value={city}>
												{" "}
												{city}{" "}
											</MenuItem>
										))}
									</Select>
									{this.state.city && (
										<span
											onClick={() => {
												this.setState(
													{ city: "",
													offset: 0, },
													() => {
														this.getRunner();
													}
												);
											}}
											style={{ cursor: 'pointer' }}
										>
											X
										</span>
									)}
								</Grid>
								<Grid item xs={3} style={{ width: 130 }}>
									<div
										className="cityLabel"
										style={{
											display: "inline-block",
											verticalAlign: "top",
											marginRight: "10px",
											padding: "8px 0",
											fontSize: "15px",
										}}
									>
										{" "}
										Working Status:{" "}
									</div>
									<Select
										style={{ width: 100 }}
										value={this.state.status}
										onChange={(e) => {
											this.setState(
												{
													status: e.target.value,
													offset: 0,
												},
												() => {
													this.getRunner();
												}
											);
										}}
									>
										<MenuItem value="">
											<em>None</em>
										</MenuItem>
										<MenuItem value={'Working'}>Working</MenuItem>
										<MenuItem value={'Resigned'}>Resigned</MenuItem>
										<MenuItem value={'Terminated'}>Terminated</MenuItem>
										<MenuItem value={'Abscond'}>Abscond</MenuItem>
										<MenuItem value={'On-Hold'}>On-Hold</MenuItem>
									</Select>
									{this.state.status && (
										<span
											onClick={() => {
												this.setState(
													{ status: "" },
													() => {
														this.getRunner();
													}
												);
											}}
											style={{ cursor: 'pointer' }}
										>
											X
										</span>
									)}
								</Grid>
								<Grid item xs={3}>
									<Button style={{ backgroundColor: "#0D8DA3" }} variant="contained" color="primary" onClick={() => { this.setState({ isOpen: !this.state.isOpen }) }}>
										Add Runner
									</Button>
								</Grid>
							</Grid>

							<TableContainer component={Paper} className="mt-30">
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell>Image</TableCell>
											<TableCell>Name</TableCell>
											<TableCell>Phone number</TableCell>
											<TableCell>Username</TableCell>
											<TableCell>City</TableCell>
											<TableCell>Address</TableCell>
											<TableCell>Working Status</TableCell>
											<TableCell>Is Active?</TableCell>	
											<TableCell>Action</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{((runner || []).length <= 0) &&
											<TableRow>
												<TableCell component="th" scope="row"> No runner found. </TableCell>
											</TableRow>
										}
										{(runner || []).map(run => (
											<TableRow key={run.runnerId}>
												<TableCell>
													<a href={run.imageUrl} download>
														<Avatar src={run.imageUrl} alt="img" size="small" />
													</a>
												</TableCell>
												<TableCell>{run.firstName} {run.lastName}</TableCell>
												<TableCell>{run.mobileNumber}</TableCell>
												<TableCell>{run.username}</TableCell>
												<TableCell>{run.city}</TableCell>
												<TableCell>{run.address1} {run.address2}</TableCell>
												<TableCell>
													<Select
														style={{ width: 100 }}
														value={run.working_status || ""}
														onChange={(e) => {
															const data = {...run};
															data.working_status = e.target.value;
															data.isEdit = true;
															this.addRunner(data);
														}}
													>
														<MenuItem value="">
															<em>None</em>
														</MenuItem>
														<MenuItem value={'Working'}>Working</MenuItem>
														<MenuItem value={'Resigned'}>Resigned</MenuItem>
														<MenuItem value={'Terminated'}>Terminated</MenuItem>
														<MenuItem value={'Abscond'}>Abscond</MenuItem>
														<MenuItem value={'On-Hold'}>On-Hold</MenuItem>
													</Select>
												</TableCell>
												<TableCell>
													<Switch size="small"
														color="primary"
														name="isActive"
														checked={run.isActive}
														onChange={(e) => {
															const data = { ...run };
															data.isActive = e.target.checked;
															data.isEdit = true;
															this.addRunner(data);
														}}
													/>
												</TableCell>
												<TableCell>
													<div style={{ width: '75px' }}>
														<IconButton edge="end" color="inherit" size="small" onClick={() => { this.redirectToDetail(run) }}>
															<Visibility />
														</IconButton>
														<IconButton edge="end" color="inherit" size="small" onClick={() => { this.setState({ isOpen: true, data: { ...run, isEdit: true } }) }}>
															<Edit />
														</IconButton>
														<IconButton edge="end" color="inherit" size="small" onClick={() => { this.setState({ isDeleteOpen: true, deleteObj: run }); }}>
															<Clear />
														</IconButton>
													</div>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>

							{this.renderPagination()}

							{isOpen && this.renderPopup()}

							{isDeleteOpen && this.renderDeletePopup()}
						</React.Fragment>
						:
						<React.Fragment>
							<Grid container spacing={3}>
								<IconButton edge="end" color="inherit" onClick={() => { this.redirectToList() }}>
									<ArrowBackOutlined />
								</IconButton>
								<Grid item xs={4}> <h2 className="mt-0">RUNNER DETAILS</h2> </Grid>
							</Grid>
							<Tabs value={this.state.tab} onChange={(e, tab) => this.setState({ tab })}>
								<Tab label='Basics' value={1} />
								<Tab label='KYC Documents' value={2} />
							</Tabs>
							<TabPanel value={this.state.tab} index={1}>
								{this.renderBasicDetails(isKYC)}
							</TabPanel>
							<TabPanel value={this.state.tab} index={2}>
								{this.renderKYCTab(isKYC)}
							</TabPanel>
						</React.Fragment>
					}
				</div>
			</DocContainer>
		);
	}
}

const mapStateToProps = ({ runner, constants }) => {
	return {
		isLoading: runner.isSubmitting,
		runner: runner.runner,
		hasMore: runner.hasMore,
		runnerKYC: runner.runnerKYC,

		cities: constants.cities,
		states: constants.states,
	}
};

export default connect(mapStateToProps)(Runner);
