/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./clinics.css";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  Paper,
  Button,
  Grid,
  TextField,
  IconButton,
  MenuItem,
} from "@material-ui/core";

import {
  ADD_LAB_USERS,
  DELETE_LAB_USERS,
  GET_LAB_USERS_BY_LABID,
} from "../../constants/actionConstants";
import { Edit, Clear } from "@material-ui/icons";

class LabUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      searchText: "",
      isError: false,
      isModalOpen: false,

      data: {
        name: "",
        username: "",
        password: "",
        branchName: "",
        pincode: "",
        address: "",
        city: "",
      },
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.labId) {
      this.getLabUsers();
    }
  }

  validateData = () => {
    const { data } = this.state;
    if (
      data.name === "" ||
      data.username === "" ||
      data.password === "" ||
      data.pincode === "" ||
      data.branchName === "" ||
      data.address === "" ||
      data.city === ""
    ) {
      this.setState({ isError: true });
    } else {
      this.addLabUser();
      this.setState({ isError: false });
    }
  };

  addLabUser = () => {
    const { match } = this.props;
    const { dispatch } = this.props;

    dispatch({
      type: ADD_LAB_USERS,
      data: { ...this.state.data, labId: this.props.labId },
      onSuccess: () => {
        this.setState({ isModalOpen: false });

        this.resetPopup();
        this.getLabUsers();
      },
    });
  };

  getLabUsers = () => {
    const { dispatch, match } = this.props;
    let data = {
      count: 10,
      offset: this.state.offset,
      labId: match.params.labId,
    };

    dispatch({
      type: GET_LAB_USERS_BY_LABID,
      data,
    });
  };

  renderPagination = (isLabTest) => {
    return (
      <div className="mb-30">
        <Grid container spacing={5}>
          <Grid item xs={9} />
          <Grid item xs={3}>
            <Button
              style={{ marginRight: "20px" }}
              size="medium"
              color="primary"
              variant="outlined"
              disabled={this.state.offset === 0}
              onClick={() => {
                this.setState({ offset: this.state.offset - 10 }, () => {
                  this.getLabUsers();
                });
              }}
            >
              {"< Previous"}
            </Button>
            <Button
              size="medium"
              color="primary"
              variant="outlined"
              disabled={!this.props.hasMore}
              onClick={() => {
                this.setState({ offset: this.state.offset + 10 }, () => {
                  this.getLabUsers();
                });
              }}
            >
              {"Next >"}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  };

  renderPopup = () => {
    const { isModalOpen, data } = this.state;
    return (
      <Dialog
        disableBackdropClick
        open={isModalOpen}
        size="md"
        fullWidth
        onClose={() => {
          this.setState({ isModalOpen: false });
        }}
      >
        <DialogTitle id="clinic-popup">
          {data.isEdit ? "Edit Lab User" : "Add New Lab User"}
        </DialogTitle>
        <DialogContent>{this.renderFormFields({ ...data })}</DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            onClick={this.validateData}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button
            style={{ borderColor: "#00C9AA", color: "#00C9AA" }}
            onClick={() => {
              this.setState({ isModalOpen: false });
              this.resetPopup();
            }}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  resetPopup = () => {
    let { data } = this.state;
    data = {
      //	labId: "",
      name: "",
      description: "",
      mrp: "",
      sellingPrice: "",
    };
    this.setState({ data, isError: false });
  };

  handleFormChange = (e, key) => {
    const { data } = this.state;
    data[key] = e.target.value;
    this.setState({ data });
  };
  renderFormFields = (data) => {
    const { isError } = this.state;
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Name * "
              value={data.name}
              onChange={(e) => this.handleFormChange(e, "name")}
              error={isError && data.name === ""}
              helperText={
                isError && data.name === "" ? "Please enter a Name" : " "
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Username * "
              value={data.username}
              onChange={(e) => this.handleFormChange(e, "username")}
              error={isError && data.username === ""}
              helperText={
                isError && data.username === ""
                  ? "Please enter a username"
                  : " "
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          {!data.isEdit && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Password * "
                value={data.password}
                onChange={(e) => this.handleFormChange(e, "password")}
                error={isError && data.password === ""}
                helperText={
                  isError && data.password === ""
                    ? "Please enter a password"
                    : " "
                }
              />
            </Grid>
          )}

          <Grid item xs={6}>
            <label> City * </label>
            <Select
              fullWidth
              value={data.city}
              onChange={(e) => {
                this.handleFormChange(e, "city");
              }}
            >
              {(this.props.cities || []).map((city, index) => (
                <MenuItem key={city} value={city}>
                  {city}
                </MenuItem>
              ))}
            </Select>
            {isError && data.city === "" ? (
              <div
                style={{ color: "red", letterSpacing: "1px", fontSize: "10px" }}
              >
                Please Select City
              </div>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Pincode * "
              value={data.pincode}
              onChange={(e) => this.handleFormChange(e, "pincode")}
              error={isError && data.pincode === ""}
              helperText={
                isError && data.pincode === "" ? "Please enter a pincode" : " "
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Branch Name * "
              value={data.branchName}
              onChange={(e) => this.handleFormChange(e, "branchName")}
              error={isError && data.branchName === ""}
              helperText={
                isError && data.branchName === ""
                  ? "Please enter a Branch Name"
                  : " "
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Address"
              value={data.address}
              onChange={(e) => this.handleFormChange(e, "address")}
              error={isError && data.address === ""}
              helperText={
                isError && data.address === "" ? "Please enter a Address" : " "
              }
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
  deleteLabTest = (id) => {
    const { dispatch } = this.props;
    dispatch({
      type: DELETE_LAB_USERS,
      data: { labTestId: id },
      onSuccess: () => {
        this.getLabUsers();
        this.setState({ isDeleteOpen: false, deleteObj: null });
      },
    });
  };
  renderDeletePopup = () => {
    const { isDeleteOpen, data, deleteObj } = this.state;
    return (
      <Dialog
        disableBackdropClick
        open={isDeleteOpen}
        onClose={() => {
          this.setState({ isDeleteOpen: false });
        }}
      >
        <DialogTitle id="clinic-popup">Confirm Delete</DialogTitle>
        <DialogContent>
          {" "}
          Are you sure you want to delete {deleteObj.name} ?{" "}
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#00C9AA" }}
            onClick={() => {
              this.deleteLabTest(deleteObj.labTestId);
            }}
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
          <Button
            style={{ borderColor: "#00C9AA", color: "#00C9AA" }}
            onClick={() => {
              this.setState({ isDeleteOpen: false, deleteObj: null });
            }}
            variant="outlined"
            color="primary"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  renderLabUsersTable = (labUsers) => {
    return (
      <TableContainer component={Paper} className="mb-30">
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>City</TableCell>
              <TableCell>Pincode</TableCell>
              <TableCell>Branch Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(labUsers || []).length <= 0 && (
              <TableRow>
                <TableCell component="th" scope="row">
                  {" "}
                  No Lab Users found.{" "}
                </TableCell>
              </TableRow>
            )}
            {(labUsers || []).map((obj, index) => (
              <TableRow key={`labtest-${obj.labTestId}-${index}`}>
                <TableCell>{obj.name}</TableCell>
                <TableCell>{obj.username}</TableCell>
                <TableCell>{obj.city}</TableCell>
                <TableCell>{obj.pincode}</TableCell>
                <TableCell>{obj.branchName}</TableCell>
                <TableCell>{obj.address}</TableCell>

                <TableCell>
                  <div style={{ width: "75px" }}>
                    <IconButton
                      edge="end"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        this.setState({
                          isModalOpen: true,
                          data: { ...obj, isEdit: true },
                        });
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      edge="end"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        this.setState({ isDeleteOpen: true, deleteObj: obj });
                      }}
                    >
                      <Clear />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <h2 className="mt-0">LAB USERS</h2>
          </Grid>
          <Grid item>
            <Button
              style={{ backgroundColor: "#00C9AA" }}
              variant="contained"
              color="primary"
              onClick={() => {
                this.setState({ isModalOpen: !this.state.isModalOpen });
              }}
            >
              Add LAB User
            </Button>
          </Grid>
        </Grid>

        {this.renderPagination(true)}
        {this.state.isModalOpen && this.renderPopup()}
        {this.state.isDeleteOpen && this.renderDeletePopup()}

        {this.renderLabUsersTable(this.props.labUsers)}

        {this.renderPagination(true)}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ labs, constants }) => {
  return {
    isLoading: labs.isSubmitting,
    hasMore: labs.hasMore,
    labUsers: labs.labUsers,
    cities: constants.cities,
    states: constants.states,
  };
};

export default withRouter(connect(mapStateToProps)(LabUsers));
