import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./index.css";
import DatePicker, { registerLocale } from "react-datepicker";
import { Refresh } from "@material-ui/icons";

import { IconButton, Select, MenuItem, Grid } from "@material-ui/core";
import moment from "moment";

import {
  GET_ALL_CONSTANTS,
  GET_ALL_ROSTER_MAPPING,
} from "./../../constants/actionConstants";
import ActionLoader from "../../components/ActionLoader";

const RosterMapping = (props) => {
  const [state, setState] = useState({
    right: false,
  });
  const [loading, setLoading] = useState(false);
  const [rosterData, setRosterData] = useState([]);
  const [dateFilter, setDateFilter] = useState(props.date?new Date(props.date):new Date());
  const [city, setCity] = useState(props.city || "");

  useEffect(() => {
    if (state.right) {
      getAllConstants();
    }
  }, []);

  useEffect(() => {
    if (state.right && city) {
      getAllRostersMapping();
    }
  }, [dateFilter, city, state.right]);

  useEffect(() => {
    if(props.date) {
      setDateFilter(new Date(props.date));
    }
    if(props.city) {
      setCity(props.city)
    }

  }, [props.date, props.city])

  const getAllConstants = () => {
    const { dispatch } = props;
    dispatch({
      type: GET_ALL_CONSTANTS,
    });
  };

  const getAllRostersMapping = () => {
    setLoading(true);
    const { dispatch } = props;
    const data = {};

    if (city) {
      data.city = city;
    }

    if (dateFilter) {
      data.date = moment(dateFilter).date();
      data.month = 1 + moment(dateFilter).month();
      data.year = moment(dateFilter).year();
    }

    dispatch({
      type: GET_ALL_ROSTER_MAPPING,
      data,
      onSuccess: (data) => {
        setRosterData(data);
        setLoading(false);
      },
      onFailure: (data) => {
        setRosterData([]);
        setLoading(false);
      },
    });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div className="jqbox_overlay">
      <React.Fragment>
        <span onClick={toggleDrawer("right", true)} className="labelText">
          Roster
        </span>
        <Drawer
          className="drawer"
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          <React.Fragment>
            <div style={{ margin: 20 }}>
              <div>
              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                }}
              >
                <div className="ordersDate dateFilters">
                  <span className="dateLabel mb-10 mr-10">Date: </span>
                  <DatePicker
                    selected={dateFilter}
                    onChange={(date) => {
                      setDateFilter(date);
                    }}
                  />
                </div>
              </div>
              <div
                  className="dateLabel"
                  style={{
                    display: "inline-block",
                    verticalAlign: "top",
                    marginRight: "10px",
                    padding: "8px 0",
                  }}
                >
                  {" "}
                  Filter City:{" "}
                </div>
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "top",
                    minWidth: "120px",
                  }}
                >
                  <Select
                    size="small"
                    fullWidth
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                  >
                    {(props.cities || []).map((city, index) => (
                      <MenuItem key={`city-${index}`} value={city}>
                        {city}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                  marginRight: "20px",
                  float: 'right'
                }}
              >
                <IconButton
                  edge="end"
                  color="inherit"
                  size="small"
                  style={{ fontSize: "12px" }}
                  onClick={() => setState({ ...state, right: false })}
                >
                  X Close
                </IconButton>
              </div>
              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                  marginRight: "20px",
                  float: 'right'
                }}
              >
                <IconButton
                  edge="end"
                  color="inherit"
                  size="small"
                  style={{ fontSize: "12px" }}
                  onClick={() => getAllRostersMapping()}
                >
                  <Refresh /> Refresh
                </IconButton>
              </div>
              </div>
              <div style={{ borderTop: "1px solid #808080" }} />
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div className="tableViewDrawer">
                    <table className="styled-table">
                      <thead className="tableHead">
                        <tr className="border_bottom">
                          <th>S.no</th>
                          <th>Zone</th>
                          <th>Count</th>
                          <th>
                          <thead className="tableHead">
                          <tr style={{textAlign: 'center',border: 0}}>
                              <th colSpan="3">Phlebos</th>
                            </tr>
                            <tr style={{textAlign: 'center',border: 0}}>
                              <th style={{width: '75%', textAlign: 'left'}}>Name</th>
                              <th>Roster</th>
                              <th>Attendance</th>
                            </tr>
                            </thead>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="tableBody">
                        {(rosterData || []).map((el, i) => (
                          <tr className="row border_bottom" style={{borderBottom: '1px solid #9fa2b4'}}>
                            <td className="cell" data-title="S.no">
                              {i + 1}
                            </td>
                            <td className="cell" data-title="Date">
                              {el.name}
                            </td>
                            <td className="cell" data-title="Date">
                              {el.count || 0}
                            </td>
                            <td className="cell" data-title="Pan India">
                              {(el.list || []).map((chelem) => (
                                <tr className="border_bottom">
                                  <td className="cell" data-title="S.no" style={{width: '75%'}}>
                                    {chelem.name}
                                  </td>
                                  <td className="cell" data-title="S.no" style={{width: '5%'}}>
                                    {chelem.roster}
                                  </td>
                                  <td className="cell" data-title="S.no">
                                    {chelem.attendance}
                                  </td>
                                </tr>
                              ))}
                            </td>
                          </tr>
                        ))}
                        {rosterData.length === 0 && (
                          <tr className="row">
                            <td className="cell" data-title="S.no">
                              No Plans
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Grid>
              </Grid>
            </div>
          </React.Fragment>
          {loading && <ActionLoader />}
        </Drawer>
      </React.Fragment>
    </div>
  );
};

const mapStateToProps = ({ constants }) => {
  return {
    cities: constants.cities,
  };
};

export default withRouter(connect(mapStateToProps)(RosterMapping));
