import { getToken, deleteToken, getUserType, getPhleboToken } from 'Utils/storage';

//export const baseUrl = 'http://13.233.203.27:9000';
 export const baseUrl = 'https://service.thinkhealthcare.in';

export const SIGN_IN_URL = '/write/v1/superAdmin/login';
export const SIGN_OUT_URL = '/write/v1/superAdmin/logout';
export const FORGOT_PASSWORD_URL = '/api/0.1/write/forgot/password';
export const CHANGE_PASSWORD_URL = '/write/v1/superAdmin/changePassword';

export const LGP_SIGN_IN_URL = '/write/v1/lgp/login';
export const LGP_SIGN_OUT_URL = '/write/v1/lgp/logout';
export const LGP_CHANGE_PASSWORD_URL = '/write/v1/lgp/change/password';

export const LAB_SIGN_IN_URL = '/write/v1/lab/user/login';

export const DOCTOR_SIGN_IN_URL = '/write/v1/doctor/login';

export const GET_CLINICS_URL = '/read/v1/clinic/getAll';
export const SEARCH_CLINICS_URL = '/read/v1/clinic/search';
export const GET_CLINIC_BY_CITY_URL = '/read/v1/clinic/getByCity';
export const ADD_CLINIC_URL = '/write/v1/clinic/add';
export const EDIT_CLINIC_URL = '/write/v1/clinic/edit';

export const GENERIC_CONSTANTS_URL = '/read/v1/genericConstants/getAll';
export const GET_ALL_STATES_URL = '/read/v1/genericConstants/states/getAll';

export const GET_PROMOCODE_URL = '/read/v1/promocodes/getAll';
export const EDIT_PROMOCODE_URL = '/write/v1/promocodes/edit';
export const SEARCH_PROMOCODE_URL = '/read/v1/promocodes/search';
export const ADD_PROMOCODE_URL = '/write/v1/promocodes/add';

export const UPLOAD_IMAGE_URL = '/write/v1/image/upload';
export const UPLOAD_CMS_IMAGE_URL = '/write/v1/image/upload/cms/logs';
export const MULTIPLE_IMAGE_UPLOAD_URL = '/write/v1/imageupload/upload/multiple';

export const VIDEO_UPLOAD_URL = '/write/v1/image/upload/video/proof';
export const AUDIO_UPLOAD_URL = '/write/v1/image/upload/audio/proof';
export const REPORTS_UPLOAD_URL = '/write/v1/image/upload/reports';

//export const ADD_PLEBO_URL = '/write/v1/phlebo/add';
export const ADD_PLEBO_URL = '/write/v2/phlebo/add';
export const GET_PLEBO_URL = '/read/v1/phlebo/getAll';
export const GET_PLEBO_TRACKING_URL = '/read/v1/cms/orders/tracking/last/known/location';
// export const GET_PLEBO_BY_ID_URL = '/read/v1/phlebo/getById';
export const GET_PLEBO_BY_ID_URL = '/read/v2/phlebo/getById';
export const GET_LOCATION_URL = '/read/v1/cms/orders/tracking/live/location';
export const EDIT_PLEBO_URL = '/write/v1/phlebo/edit';
export const DEL_PLEBO_URL = '/write/v1/phlebo/delete';

export const ADD_DOCTOR_URL = '/write/v1/doctor/add';
export const GET_ALL_DOCTOR_URL = '/read/v1/doctor/getAll';
export const GET_DOCTOR_BY_ID_URL = '/read/v1/doctor/getById';
export const UPDATE_DOCTOR_URL = '/write/v1/doctor/update';
export const DELETE_DOCTOR_URL = '/write/v1/doctor/deleteById';

export const GET_ALL_DOCTOR_ORDERS_URL = '/read/v1/orders/docGetAll';


export const ADD_LAB_URL = '/write/v1/lab/add';
export const GET_LABS_URL = '/read/v1/lab/getAll';
export const GET_LAB_BY_ID_URL = '/read/v1/lab/getById';
export const EDIT_LAB_URL = '/write/v1/lab/edit';
export const DEL_LAB_URL = '/write/v1/lab/delete';

export const ADD_MER_QUESTIONS_URL = '/write/v1/mer/create';
export const GET_MER_QUESTIONS_URL = '/read/v1/mer/create';
export const GET_MER_ORDERS_URL = '/read/v1/oc/orders/getAll';
export const UPDATE_MER_ORDERS_URL = '/write/v1/oc/orders/update';
export const GET_MER_ORDERS_DASHBOARD_URL = '/read/v1/oc/orders/dashboard/getAll';


export const GET_LAB_TEST_BY_LABID_URL = '/read/v1/labTest/getAll';
export const ADD_LAB_TEST_URL = '/write/v1/labTest/add';

export const EDIT_LAB_TEST_URL = '/write/v1/labTest/edit';
export const DEL_LAB_TEST_URL = '/write/v1/labTest/delete';

export const GET_LAB_USERS_BY_LABID_URL = '/read/v1/lab/user/getAll';
export const ADD_LAB_USERS_URL = '/write/v1/lab/user/add';
export const EDIT_LAB_USERS_URL = '/write/v1/lab/user/edit';
export const DEL_LAB_USERS_URL = '/write/v1/lab/user/delete';

export const GET_CONTRACT_BY_LGPID_URL='/read/v1/chargeContract/getAll'
export const ADD_CONTRACT_URL='/write/v1/chargeContract/add'
export const EDIT_CONTRACT_URL='/write/v1/chargeContract/edit'

export const ADD_ROUTE_URL = '/write/v1/route/add';
export const GET_ALL_ROUTE_URL = '/read/v1/route/getAll';
export const GET_ROUTE_BY_ID_URL = '/read/v1/route/getById';
export const EDIT_ROUTE_URL = '/read/v1/route/edit';
export const DELETE_ROUTE_URL = '/read/v1/route/delete';
export const SEARCH_ROUTE_URL = '/read/v1/route/search';


export const GET_ALL_RUNNERS_URL = '/read/v1/runner/getAll';
export const GET_ALL_RUNNERS_BY_ID_URL = '/read/v1/runner/getById';
export const ADD_RUNNER_URL = '/write/v1/runner/add';
export const EDIT_RUNNER_URL = '/write/v1/runner/edit';
export const DEL_RUNNER_URL = '/write/v1/runner/delete';

export const GET_ALL_ROLES_URL = '/read/v1/roles/getAll';
export const GET_ALL_ROLES_BY_ID_URL = '/read/v1/roles/getById';
export const ADD_ROLES_URL = '/write/v1/roles/add';
export const EDIT_ROLES_URL = '/write/v1/roles/edit';
export const DEL_ROLES_URL = '/write/v1/roles/delete';

export const GET_DROPDOWN_USERS_URL = '/read/v1/appuser/getUsers';
export const GET_ALL_USERS_URL = '/read/v1/appuser/getAll';
export const GET_USERS_OPTIONS_URL = '/read/v1/appuser/getUsers';
export const GET_ALL_USERS_BY_ID_URL = '/read/v1/appuser/getById';
export const ADD_USERS_URL = '/write/v1/appuser/add';
export const EDIT_USERS_URL = '/write/v1/appuser/edit';
export const DEL_USERS_URL = '/write/v1/appuser/delete';

export const GET_ALL_DEPT_URL = '/read/v1/department/getAll';
export const GET_ALL_DEPT_BY_ID_URL = '/read/v1/department/getById';
export const ADD_DEPT_URL = '/write/v1/department/add';
export const EDIT_DEPT_URL = '/write/v1/department/edit';
export const DEL_DEPT_URL = '/write/v1/department/delete';

export const GET_PLEBO_KYC_URL = '/read/v1/phlebo/getKycDocuments';
export const POST_PlEBO_KYC_URL = '/write/v1/phlebo/uploadDocument';
export const DELETE_PLEBO_KYC_URL = '/write/v1/phlebo/deleteKycDocumentById';

export const GET_RUNNER_KYC_URL = '/read/v1/runner/getKycDocuments';
export const POST_RUNNER_KYC_URL = '/write/v1/runner/uploadDocument';
export const DELETE_RUNNER_KYC_URL = '/write/v1/runner/deleteKycDocumentById';

export const GET_LGP_URL = '/read/v1/lgp/getAll';
export const GET_LGP_BY_ID_URL = '/read/v1/lgp/getById';
export const POST_LGP_URL = '/write/v1/lgp/add';
export const EDIT_LGP_URL = '/write/v1/lgp/edit';
export const DELETE_LGP_URL = '/write/v1/lgp/delete';

export const GET_ALL_ORDERS_URL = '/read/v1/cms/orders/getAll';
export const SEARCH_ORDER_URL = '/read/v1/cms/orders/search';
export const GET_ORDER_BY_ID_URL = '/read/v1/cms/orders/get';
export const ASSIGN_ORDER_URL = '/write/v1/cms/orders/assign';
export const UNASSIGN_PHLEBO_URL = '/write/v1/cms/orders/unassign';
export const ORDER_ACCEPT_REJECT_URL = '/write/v1/cms/orders/acceptReject';
export const UPDATE_ORDER_URL = '/write/v1/cms/orders/update';
export const CREATE_ORDER_URL = '/write/v1/cms/orders/add';
export const CREATE_VMER_VITALS_URL = '/write/v1/orders/offline/edit';

export const ASSIGN_DOCTOR_URL = '/write/v1/cms/orders/doctor/assign';
export const UNASSIGN_DOCTOR_URL = '/write/v1/cms/orders/doctor/unassign';

export const GET_LGP_ORDERS_URL = '/read/v1/cms/lgp/orders/getAll';
export const GET_LGP_ORDERS_ID_URL = '/read/v1/cms/lgp/orders/get';

export const DOWNLOAD_ORDER_URL = '/read/v1/cms/orders/new/get';
export const UPLOAD_ORDER_URL = '/write/v1/cms/orders/upload';

export const DELETE_LABREPORT_URL = '/write/v1/cms/orders/report/delete';
export const GET_ALL_REPORTS_URL = '/read/v1/cms/orders/report/getAll';
export const ADD_REPORTS_URL = '/write/v1/cms/orders/report/add';

export const UPLOAD_ASSIGNED_ORDERS_URL = '/write/v1/cms/orders/bulk/assign';

export const GET_STATUS_URL = '/read/v1/cms/orders/bulk/status';

export const ADD_ATTENDANCE_URL = '/write/v1/attendance/mark';
export const GET_ATTENDANCE_URL = '/read/v1/attendance/getAllAttendanceByUser';

export const GET_ROUTE_PLAN_URL = '/read/v1/cms/route/plan';
export const GET_ROUTE_PLAN_DETAILS_URL = '/read/v1/cms/route/plan/details';

export const GET_DOWNLOAD_REPORTS_URL = '/read/v1/cms/orders/report/concile';

export const DOWNLOAD_CLIENT_ORDER_URL = '/read/v1/cms/orders/dump';


export const UPDATE_PHLEBO_AREA_URL = '/write/v1/phlebo/area/map';
export const GET_PHLEBO_AREA_URL = '/read/v1/phlebo/area/map';
export const REMOVE_PHLEBO_MAPPING_URL = '/write/v1/phlebo/area/map';

export const GET_REPORT_PROOF_URL = '/read/v1/cms/orders/tracking/proof';
export const GET_REPORT_DELAY_URL = '/read/v1/cms/orders/tracking';
export const UPDATE_REPORT_STATUS_URL = '/write/v1/cms/orders/tracking';
export const GET_TICKET_REPORT_URL = '/read/v1/cms/orders/tracking/tickets';
export const UPDATE_TICKET_URL = '/write/v1/cms/orders/tracking/tickets';
export const GET_TICKET_TIME_SLOTS_URL = '/read/v1/cms/orders/tracking/tickets/slots';

export const GET_FIRST_SLOT_URL = '/read/v1/cms/orders/tracking/first/slot';
export const GET_DOCTORS_URL = '/read/v1/cms/doctors/list';

export const GET_LGP_LAB_LIST_URL = '/read/v1/lgp/lab/list';

export const ORDER_CANCEL_ACTION = '/write/v1/cms/orders/orderCancelActionByExe';
export const UPLOAD_PROOFS = '/write/v1/cms/logs/proof/upload';

export const GET_ALL_ZONES_URL = '/read/v1/cms/zones/getAll';
export const GET_UNMAPPED_PHLEBOS_URL = '/read/v1/cms/zones/get/unplanned/phlebos';
export const GET_UNPLANNED_ZIPCODES_URL = '/read/v1/cms/zones/get/unplanned/zipcodes';
export const GET_ALL_ZIPCODES_URL = '/read/v1/route/getAll';

export const ADD_ZONES_URL = '/write/v1/cms/zones/add';
export const UPDATE_ZONES_URL = '/write/v1/cms/zones/edit';
export const REMOVE_PHLEBO_URL = '/write/v1/cms/zones/remove/phlebo';
export const GET_ALL_ROSTERS_URL = '/read/v1/cms/roster/getAll';
export const ADD_ROSTERS_URL = '/write/v1/cms/roster/add';
export const UPDATE_ROSTERS_URL = '/write/v1/cms/roster/edit';
export const GET_PLEBOS_ATTENDANCE_URL = "/read/v1/phlebo/getAttendanceByCity";
export const UPDATE_PLEBOS_ATTENDANCE_URL = "/write/v1/phlebo/attendance/update";
export const GET_PLEBOS_FIRST_SLOTS_URL = "/read/v1/phlebo/first/slot/start/getAll";
export const GET_PLEBOS_LOCATION_STATUS_URL = "/read/v1/phlebo/location/sync/server/getAll"
export const GET_PLEBOS_PROOF_SYNC_URL = "/read/v1/phlebo/prrof/sync/server/getAll"

export const GET_ALL_TRIPS_URL = "/read/v1/cms/trips/routes/getAll";
export const GET_ALL_TRIPS_BY_NUM_URL = "/read/v1/cms/trips/routes/get";
export const GET_ALL_REVIEW_ORDERS_URL = "/read/v1/cms/trips/review/orders/getAll";
export const ASSIGN_PLEBOS_ROUTE_URL = "/write/v1/cms/trips/assign/phlebo/to/route";
export const SUFFLE_ORDERS_URL = "/write/v1/cms/trips/assign/shuffle/orders";
export const CREATE_ROUTE_URL = "/write/v1/cms/trips/create/new/route";
export const GET_LIFECYCLE_URL = "/read/v1/order/lifecycle/getAll";
export const GET_COMPRESSED_URL = "/read/v1/mer/compress/ver1";


export const GET_ROSTERS_DETAILS_BY_ID_URL = '/read/v1/cms/roster/get';
export const GET_ROSTERS_CONFIRM_URL = "/read/v1/cms/roster/get/confirmation/by/date";
export const UPDATE_ROSTERS_CONFIRM_URL = "/write/v1/cms/roster/confirm";
export const GET_ROSTERS_INSIGHTS_URL = "/read/v1/cms/roster/get/insigntsV1/by/date";
export const GET_ROSTERS_WORK_STATUS_URL = "/read/v1/cms/roster/get/details/by/date";
export const GET_ALL_ROSTER_MAPPING_URL = '/read/v1/phlebo/get/order/zone/phlebo/roster/mapping';

export const CREATE_ROUTE_PLAN_AUTO_URL = "/write/v1/cms/route/plan/create";
export const GET_AUTO_ROUTE_PLANS_URL = "/read/v1/cms/route/plan/getAll";
export const DOWNLOAD_EXECUTION_REPORT_URL = "/read/v1/mis/daily/reports";
export const DOWNLOAD_ROUTE_URL = "/read/v1/route/dump";
export const GET_DAILY_REPORTS_URL = "/read/v1/mis/daily/reports/dashboard";


export const GET_CLIENT_UPLOAD_REPORTS_URL = "/read/v1/cms/order/upload/getAll";
export const DOWNLOAD_CLIENTS_ORDER_URL = '/read/v1/cms/download/order/template';
export const UPLOAD_CLIENT_ORDER_URL = '/write/v1/cms/upload/orders';
export const GET_CLIENTS_ORDER_DETAILS_URL = '/read/v1/cms/order/execution/summary';
export const AUTO_ASSIGN_ORDER_URL = '/write/v2/cms/orders/assign';
export const GET_DOCTOR_ORDERS_COUNT_URL = '/read/v1/orders/doctor/count';

export const TRIPS_URL = '/read/v1/cms/orders/tracking/trips/stops/geolocation'
export const GET_ALL_SLOT_BOOKINGS_URL = '/read/v1/cms/orders/slots';
export const UPDATE_CONFIG_SLOTS_URL = '/write/v1/cms/slots/create';

export const CREATE_SLOTS_URL = '/write/v1/cms/slots/create';
export const GET_SLOTS_STATUS_URL = '/read/v1/cms/slots/status';
export const GET_ALL_SLOTS_STATUS_URL = '/read/v1/cms/slots/status/getAll';

export const GET_BO_ATTENDANCE_URL = '/read/v1/cms/bo/attendance';
export const SUBMIT_BO_ATTENDANCE_URL = '/write/v1/cms/bo/attendance';

export const GET_VIDEO_TOKEN_URL = '/read/v1/agora/token/get';
export const GET_VIDEO_MER_URL = '/read/v1/image/view/video/get';
export const DOWNLOAD_VIDEO_MER_URL = '/read/v1/agora/vmer/get';
export const UPLOAD_PPMC_MER = '/write/v1/cms/ppmc/proof/upload';

export const GET_GENERATE_PRESIGNED_URL = '/read/v1/orders/create/presign';

export const GET_PRESIGNED_URL = '/write/v1/image/upload/labReport';

export const GET_VITALS_URL = '/read/v1/orders/vitals/get';

const jsonToUrlParams = (json)=> {
    return Object.keys(json).map(function(k) {
        if(typeof json[k] != 'undefined' && json[k] !== '') {
            return encodeURIComponent(k) + '=' + encodeURIComponent(json[k])
        }
        return "";
    }).filter((item)=> {
        return item;
    }).join('&')
}

export const postDataWithoutToken = (url, data)=>{
    return new Promise((resolve, reject)=> {
        fetch(`${baseUrl}${url}`, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(data)
        }).then((response)=> {
            if(response.status === 401) {
                deleteToken();
                location.href="/";
            }
            else if(response.status >= 400) {
                response.json().then((data)=>{
                    data.status = response.status;
                    reject(data);
                })
                return;
            }
            response.json().then((data)=>{
                data.status = response.status;
                resolve(data);
            });
        }).catch((e)=> {
            reject(e);
        });
    });
};

export const getDataWithToken = (url, params={})=> {
    return new Promise((resolve, reject)=> {
        fetch(`${baseUrl}${url}?${jsonToUrlParams(params)}`, {
            headers:{
                token:getToken(),
                deviceTypeId: 4,
                userType: getUserType(),
            }
        }).then((response)=> {
            if(response.status === 401) {
                deleteToken();
                location.href="/";
            }
            else if(response.status >= 400) {
                response.json().then((data)=>{
                    reject(data);
                })
                return;
            }
            response.json().then((data)=>{
                resolve(data);
            });
        }).catch((e)=> {

            reject(e);
        });
    });
};
export const deleteDataWithToken = (url, params={})=> {
    return new Promise((resolve, reject)=> {
        fetch(`${baseUrl}${url}?${jsonToUrlParams(params)}`, {
            headers:{token:getToken(), deviceTypeId: 4,
                userType: getUserType()},
            method: 'DELETE'
        }).then((response)=> {
            if(response.status === 401) {
                deleteToken();
                location.href="/";
            }
            else if(response.status >= 400) {
                response.json().then((data)=>{
                    reject(data);
                })
                return;
            }
            response.json().then((data)=>{
                resolve(data);
            });
        }).catch((e)=> {

            reject(e);
        });
    });
};

export const putDataWithToken = (url, data)=>{
    return new Promise((resolve, reject)=> {
        fetch(`${baseUrl}${url}`, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              token:getToken(),
              deviceTypeId: 4,
              userType: getUserType(),
            },
			method: 'PUT',
			body: JSON.stringify(data)
        }).then((response)=> {
            if(response.status === 401) {
                deleteToken();
                location.href="/";
            }
            else if(response.status >= 400) {
                response.json().then((data)=>{
                    reject(data);
                })
                return;
            }
            response.json().then((data)=>{
                resolve(data);
            });
        }).catch((e)=> {
            reject(e);
        });
    });
};

export const postDataWithToken = (url, data)=>{
    return new Promise((resolve, reject)=> {
        fetch(`${baseUrl}${url}`, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              token:getToken(),
              deviceTypeId: 4,
              userType: getUserType(),
            },
			method: 'POST',
			body: JSON.stringify(data)
        }).then((response)=> {
            if(response.status === 401) {
                deleteToken();
                location.href="/";
            }
            else if(response.status >= 400) {
                response.json().then((data)=>{
                    reject(data);
                })
                return;
            }
            response.json().then((data)=>{
                resolve(data);
            });
        }).catch((e)=> {
            reject(e);
        });
    });
};

export const postDataWithPhleboToken = (url, data)=>{
    return new Promise((resolve, reject)=> {
        fetch(`${baseUrl}${url}`, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              token:getPhleboToken(),
              deviceTypeId: 4,
              userType: getUserType(),
            },
			method: 'POST',
			body: JSON.stringify(data)
        }).then((response)=> {
            if(response.status === 401) {
                deleteToken();
                location.href="/";
            }
            else if(response.status >= 400) {
                response.json().then((data)=>{
                    reject(data);
                })
                return;
            }
            response.json().then((data)=>{
                resolve(data);
            });
        }).catch((e)=> {
            reject(e);
        });
    });
};


export const uploadFile = (url, file, params= {})=>{
    let form = new FormData();
    for(var i=0 ; i<file.length; i++) {
        form.append(i===0 ? "image":`image${i}`, file[i]);
    }
    Object.keys(params).forEach((key)=> {
        form.append([key], params[key])
    });
    return new Promise((resolve, reject)=> {
        fetch(`${baseUrl}${url}`, {
            headers: {
              'Accept': 'application/json',
              token: getToken(),
              deviceTypeId: 4,
              userType: getUserType(),
            },
			method: 'POST',
            body: form,
        }).then((response)=> {
            if(response.status === 401) {
                deleteToken();
                location.href="/";
            }
            else if(response.status >= 400) {
                reject(response);
                return;
            }
            response.json().then((data)=>{
                resolve(data);
            });
        }).catch((e)=> {
            reject(e);
        });
    });
};
